import { useSelector } from "react-redux";
import useMobileClientsObserver from "./common/useMobileClientObserver";

export const useUserService = () => {
  const mobileClients = useSelector((state) => state.userService.mobileClients);
  const mobileClientObserver = useMobileClientsObserver();

  return {
    mobileClients,
  }
}