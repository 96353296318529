import { useState, useEffect, useRef, useCallback } from 'react';

const usePlainVideoStream = (lastConfig, onRquestResult, refresh) => {
  const [config, setConfig] = useState();
  const [videoStream, setVideoStream] = useState(null);
  const [message, setMessage] = useState(null);

  const pcRef = useRef(null);
  const restartTimeoutRef = useRef(null);
  const sessionUrlRef = useRef('');
  const offerDataRef = useRef('');
  const queuedCandidatesRef = useRef([]);
  const iceServerRequestSentRef = useRef(false);

  const unquoteCredential = (v) => JSON.parse(`"${v}"`);

  const handleRquestResult = (result, method) => {
    // if ([401, 404, 500].includes(result.status)) {
    //   setConfig(null);
    //   setTimeout(() => iceServerRequestSentRef.current = false, 300);
    // }

    onRquestResult?.(result, method);
  };

  const linkToIceServers = (links) => (
    links !== null ? links.split(', ').map((link) => {
      const m = link.match(/^<(.+?)>; rel="ice-server"(; username="(.*?)"; credential="(.*?)"; credential-type="password")?/i);
      const ret = {
        urls: [m[1]],
      };

      if (m[3] !== undefined) {
        ret.username = unquoteCredential(m[3]);
        ret.credential = unquoteCredential(m[4]);
        ret.credentialType = 'password';
      }

      return ret;
    }) : []
  );

  const parseOffer = (offer) => {
    const ret = {
      iceUfrag: '',
      icePwd: '',
      medias: [],
    };

    for (const line of offer.split('\r\n')) {
      if (line.startsWith('m=')) {
        ret.medias.push(line.slice('m='.length));
      } else if (ret.iceUfrag === '' && line.startsWith('a=ice-ufrag:')) {
        ret.iceUfrag = line.slice('a=ice-ufrag:'.length);
      } else if (ret.icePwd === '' && line.startsWith('a=ice-pwd:')) {
        ret.icePwd = line.slice('a=ice-pwd:'.length);
      }
    }

    return ret;
  };

  const enableStereoOpus = (section) => {
    let opusPayloadFormat = '';
    let lines = section.split('\r\n');

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith('a=rtpmap:') && lines[i].toLowerCase().includes('opus/')) {
        opusPayloadFormat = lines[i].slice('a=rtpmap:'.length).split(' ')[0];
        break;
      }
    }

    if (opusPayloadFormat === '') {
      return section;
    }

    for (let i = 0; i < lines.length; i++) {
      if (lines[i].startsWith(`a=fmtp:${opusPayloadFormat} `)) {
        if (!lines[i].includes('stereo')) {
          lines[i] += ';stereo=1';
        }
        if (!lines[i].includes('sprop-stereo')) {
          lines[i] += ';sprop-stereo=1';
        }
      }
    }

    return lines.join('\r\n');
  };

  const editOffer = (offer) => {
    const sections = offer.sdp.split('m=');

    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (section.startsWith('audio')) {
        sections[i] = enableStereoOpus(section);
      }
    }

    offer.sdp = sections.join('m=');
  };

  const generateSdpFragment = (od, candidates) => {
    const candidatesByMedia = {};
    for (const candidate of candidates) {
      const mid = candidate.sdpMLineIndex;
      if (candidatesByMedia[mid] === undefined) {
        candidatesByMedia[mid] = [];
      }
      candidatesByMedia[mid].push(candidate);
    }

    let frag = `a=ice-ufrag:${od.iceUfrag}\r\n`
      + `a=ice-pwd:${od.icePwd}\r\n`;

    let mid = 0;

    for (const media of od.medias) {
      if (candidatesByMedia[mid] !== undefined) {
        frag += `m=${media}\r\n`
          + `a=mid:${mid}\r\n`;

        for (const candidate of candidatesByMedia[mid]) {
          frag += `a=${candidate.candidate}\r\n`;
        }
      }
      mid++;
    }

    return frag;
  };

  const onError = (err) => {
    console.log('❌ Plain video error', err)
    iceServerRequestSentRef.current = false;
    setConfig(null);

    if(refresh)
      setTimeout(refresh, 5000);

    if (restartTimeoutRef.current === null) {
      setMessage(`${err}, retrying in some seconds`);

      if (pcRef.current !== null) {
        pcRef.current.close();
        pcRef.current = null;
      }

      if (sessionUrlRef.current) {
        // fetch(sessionUrlRef.current, {
        //   method: 'DELETE',
        //   headers: { 'Authorization': `Basic ${config?.credential}` }
        // });
      }

      sessionUrlRef.current = '';
      queuedCandidatesRef.current = [];
    }
  };

  const sendLocalCandidates = useCallback((candidates) => {
    fetch(sessionUrlRef.current, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${config?.token}`,
        'Content-Type': 'application/trickle-ice-sdpfrag',
        'If-Match': '*',
      },
      body: generateSdpFragment(offerDataRef.current, candidates),
    })
      .then((res) => {
        handleRquestResult(res, 'PATCH');

        switch (res.status) {
          case 204:
            break;
          case 401:
            throw new Error('unauthorized')
          case 404:
            throw new Error('stream not found')
          default:
            throw new Error(`bad status code ${res.status}`);
        }
      })
      .catch((err) => {
        onError(err.toString());
      });
  }, [config]);

  const onLocalCandidate = (evt) => {
    if (restartTimeoutRef.current !== null) {
      return;
    }

    if (evt.candidate !== null) {
      if (sessionUrlRef.current === '') {
        queuedCandidatesRef.current.push(evt.candidate);
      } else {
        sendLocalCandidates([evt.candidate]);
      }
    }
  };

  const onRemoteAnswer = (sdp) => {
    if (restartTimeoutRef.current !== null) {
      return;
    }

    pcRef.current?.setRemoteDescription(new RTCSessionDescription({
      type: 'answer',
      sdp,
    }));

    if (queuedCandidatesRef.current.length !== 0) {
      sendLocalCandidates(queuedCandidatesRef.current);
      queuedCandidatesRef.current = [];
    }
  };

  const sendOffer = useCallback((offer) => {
    fetch(new URL(`${config.channel_id}?gateway=${config.gateway}`, config?.server_url) + window.location.search, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${config?.token}`,
        'Content-Type': 'application/sdp',
      },
      body: offer.sdp,
    })
      .then((res) => {
        handleRquestResult(res, 'POST');

        switch (res.status) {
          case 201:
            break;
          case 401:
            throw new Error('unauthorized')
          case 404:
            throw new Error('stream not found');
          default:
            throw new Error(`bad status code ${res.status}`);
        }

        sessionUrlRef.current = new URL(config?.server_url + res.headers.get('location')).toString();
        return res.text();
      })
      .then((sdp) => {
        setTimeout(() => {
          onRemoteAnswer(sdp);
        }, 1000);
      })
      .catch((err) => {
        onError(err.toString());
      });
  }, [config]);

  const createOffer = () => {
    pcRef.current.createOffer()
      .then((offer) => {
        editOffer(offer);
        offerDataRef.current = parseOffer(offer.sdp);
        pcRef.current.setLocalDescription(offer);
        sendOffer(offer);
      });
  };

  const onConnectionState = () => {
    if (restartTimeoutRef.current !== null) {
      return;
    }

    if (pcRef.current.iceConnectionState === 'disconnected') {
      onError('peer connection disconnected');
    }
  };

  const onTrack = (evt) => {
    setMessage('');
    setVideoStream(evt.streams[0]);
  };

  const requestICEServers = () => {
    fetch(new URL(`${config.channel_id}`, config?.server_url) + window.location.search, {
      method: 'OPTIONS',
      headers: { 'Authorization': `Bearer ${config?.token}` }
    })
      .then((res) => {
        if(res.status !== 204) {
          throw new Error(`bad status code ${res.status}`);
        }

        pcRef.current = new RTCPeerConnection({
          iceServers: linkToIceServers(res.headers.get('Link')),
          sdpSemantics: 'unified-plan',
        });

        const direction = 'sendrecv';
        pcRef.current.addTransceiver('video', { direction });
        pcRef.current.addTransceiver('audio', { direction });

        pcRef.current.onicecandidate = (evt) => onLocalCandidate(evt);
        pcRef.current.oniceconnectionstatechange = () => onConnectionState();
        pcRef.current.ontrack = (evt) => onTrack(evt);

        createOffer();
        handleRquestResult(res, 'OPTIONS');
      })
      .catch((err) => {
        onError(err.toString());
      });
  };

  useEffect(() => {
    if (
      config?.server_url &&
      config?.channel_id &&
      config?.token
    ) {
      console.log('⚠️ config changed in plain video', { config, iceSent: iceServerRequestSentRef.current })
      if (!iceServerRequestSentRef.current) {
        pcRef.current?.close();
        iceServerRequestSentRef.current = true;
        
        setTimeout(() => {
          requestICEServers();
        })
      }
      else {
        console.log('📺💡 Ice server request already sent');
      }
    } else {
      setVideoStream(null);
      iceServerRequestSentRef.current = false;
    }

    return () => {
      if (pcRef.current) {
        setTimeout(() => {
            pcRef.current?.close();
            pcRef.current = null;
        }, 5000);
      }

      clearTimeout(restartTimeoutRef.current);
      restartTimeoutRef.current = null;
    };
  }, [config]);

  useEffect(() => {
    pcRef.current?.close();
    pcRef.current = null;
    setVideoStream(null);
    sessionUrlRef.current = '';
    offerDataRef.current = '';
    queuedCandidatesRef.current = [];
    setMessage('');
    iceServerRequestSentRef.current = false;
    
    setConfig(lastConfig);
  }, [lastConfig]);

  return {
    videoStream,
    message,
  };
};

export default usePlainVideoStream;
