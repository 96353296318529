import { getCoordinateGeocoding } from 'src/services/mission/common/missionUtils';
import './MobileExtraDetails.sass'
import { MdDevices } from 'react-icons/md';
import { useEffect, useState } from 'react';
import useMobileDeviceInfo from 'src/services/user/common/useMobileDeviceInfo';

export default function MobileExtraDetails({ entity }) {
  const [address, setAddress] = useState('Looking for address...');
  const deviceInfo = useMobileDeviceInfo(entity?.id);

  useEffect(() => {
    if(entity.location)
      getCoordinateGeocoding(entity.location)?.then(geocode => {
          const cityPlace = geocode?.features?.find(feature => feature?.place_type?.includes('place'))?.place_name;
          const locality = geocode?.features?.find(feature => feature?.place_type?.includes('locality'))?.place_name;
          const poi = geocode?.features?.find(feature => feature?.place_type?.includes('poi'))?.place_name;
          const result = poi || locality || cityPlace;
          
          setAddress(result);
      })?.catch(() => setAddress(null));
  }, [entity]);

  return (
    <div className="mobile-extra-details">
      <div className="entity-avatar">
        {deviceInfo?.user?.avatar ? (
          <img src={deviceInfo.user.avatar} alt={deviceInfo.user.displayName} />
        ) : (
          <MdDevices className='icon' />
        )}
      </div>
      <div className="detail-list">
        <div className="detail-item">
          <div className="detail-label">Estimated Address</div>
          <div className="detail-value">{address || '-'}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Coordinates</div>
          <div className="detail-value">{entity?.location ? `${entity.location.lat}, ${entity.location.long}` : '-'}</div>
        </div>
      </div>
    </div>
  )
}
