import './Tabs.sass'

import React, { useState } from 'react'

function Tabs({items, defaultIndex, onChange, renderAll = false}) {
  const [activeTabIndex, setActiveTabIndex] = useState(defaultIndex ?? 0);

  const changeActiveTab = (index) => {
    setActiveTabIndex(index);
    onChange?.(items[index]);
  }

  return (
    <div className="tabs">
      <div className="tabs-labels">
        {items.filter((item) => !item.hidden).map((item, index) => (
          <div key={item.title} className={"item " + (index === activeTabIndex ? 'active' : '')} onClick={() => changeActiveTab(index)}>{item.title}</div>
        ))}
      </div>
      <div className="tabs-panels">
        {items.filter((item, index) => (renderAll ? true : index === activeTabIndex) && !item.hidden).map((item, index) => (
          <div key={item.title} className={"item " + (!renderAll || index === activeTabIndex ? 'active' : '')}>{item.content}</div>
        ))}
      </div>
    </div>
  )
}

export default Tabs