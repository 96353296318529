import { useEffect, useState, useRef } from 'react'
import './OnboardingProgress.sass';
import stepOneImage from 'src/assets/img/device_onboarding_new_1.png';
import stepTwoImage from 'src/assets/img/device_onboarding_new_2.png';
import stepThreeImage from 'src/assets/img/device_onboarding_new_3.png';
import stepFourImage from 'src/assets/img/device_onboarding_new_4.png';
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import { useDispatch, useSelector } from "react-redux";
import { GenerateOnboardPassKey, getList } from "../../OnboardDeviceAppSlice";
import CountdownTimer from "src/ui/CountdownTimer/CountdownTimer";
import Button from "src/ui/Button/Button";
import LabeledButton from "src/ui/LabeledButton/LabeledButton";
import { HubEventName } from "src/helper/HubConnection";

function OnboardingProgress({ onComplete, onStepChange, onCancel }) {
  const [currentStep, setCurrentStep] = useState(1);
  const serverMessageHandlerIds = useRef([]);
  const { hub: frontendHub } = useFrontendHub();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState();
  const [expireTime, setExpireTime] = useState(0);
  const [groups, setGroups] = useState([]);
  const [resetCounter, setResetCounter] = useState(false);

  const passkeyState = useSelector((state) => state.onboard.passkey);

  useEffect(() => {
    console.log(passkeyState);
    if (passkeyState.status === "success") {
      setPhoneNumber(passkeyState?.data?.mobilePhone);
      setExpireTime(passkeyState?.data?.expireTime);
      setGroups([...passkeyState?.meta]);
      setResetCounter((currentValue) => {
        return !currentValue;
      });
    }
  }, [passkeyState]);

  const changeCurrentStep = (step) => {
    setCurrentStep(step);
    onStepChange?.(step);
  };

  const tryAnotherCode = () => {
    dispatch(GenerateOnboardPassKey({ groups }, groups));
  };

  const serverMessageHandlers = [
    {
      name: HubEventName.ONBOARDING_STARTED,
      handler: (data) => {
        const deviceId = data.userId;
        changeCurrentStep(2);

        serverMessageHandlerIds.current.push(
          frontendHub.subscribeServerMessages([
            {
              identity: deviceId,
              name: HubEventName.CONNECTED,
              handler: () => {
                changeCurrentStep(4);
              },
            },
          ])
        );
      },
    },
    {
      name: HubEventName.ONBOARDING_COMPLETED,
      handler: (data) => {
        changeCurrentStep(3);
        dispatch(getList());
      },
    },
  ];

  useEffect(() => {
    frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);

    serverMessageHandlerIds.current = frontendHub?.subscribeServerMessages(
      serverMessageHandlers,
      "onboarding"
    );

    return () => {
      frontendHub?.unsubscribeServerMessages(serverMessageHandlerIds.current);
    };
  }, []);

  const steps = [
    {
      number: 1,
      image: stepOneImage,
      title: "Connect to DJI Controller",
    },
    {
      number: 2,
      image: stepTwoImage,
      title: "Connect to Onboard SDK...",
    },
    {
      number: 3,
      image: stepThreeImage,
      title: "Cloud Detecting...",
    },
    {
      number: 4,
      image: stepFourImage,
      title: "Congratulation! Your drone registered successfully!",
    },
  ];

  return (
    <div className="onboarding-progress">
      <div className="onboarding-progress-description">
        Please wait for the device registration process to be done
        automatically.
      </div>
      <div className="progress">
        <div className="steps">
          {steps.map((step) => (
            <div className="step-container" key={step.number}>
              <div
                className={`step step-${step.number} ${step.number < currentStep ? "completed" : ""
                  } ${step.number <= currentStep ? "active" : ""}`}
              >
                <div className="step-image">
                  <img src={step.image} alt={`step ${step.title}`} />
                </div>
                <div className="step-title">{step.title}</div>
              </div>
              <div
                className={`step-line ${currentStep > step.number ? "active" : ""
                  }`}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <div className="onboarding-progress-footer">
        {currentStep === 1 ? (
          <>
            <div>
              <span>
                Your code is <strong>{passkeyState?.data?.smsCode}</strong> which
                expires in:{" "}
              </span>
              <CountdownTimer
                seconds={expireTime}
                reset={resetCounter}
                onFinish={onCancel}
                className="otp-countdown"
              />
            </div>
            {/* <div>
              <span>Didn't got the code? </span>
              <LabeledButton
                className="otp-resend-button"
                title="click to resend"
                link={true}
                onClick={tryAnotherCode}
              />
            </div> */}
            <Button value="Cancel" onClick={onCancel} width="25%" />
          </>
        ) : (
          <Button
            value="Done"
            onClick={onComplete}
            width="25%"
            disabled={currentStep < 4}
          />
        )}
      </div>
    </div>
  );
}

export default OnboardingProgress