import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const GroupManagementSlice = createSlice({
  name: "group",
  initialState: {
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    assignMembers: { status: "" },
    assignOwners: { status: "" },
    membership: { status: "" },
    ownership: { status: "" },
    model: {},
    list: [],
    roles: [],
    permissions: [],
    assignPermissions: { status: "" },
    rolePermissions: [],
    name: { status: "" },
    search: { status: "" },
    details: { status: "" },
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    roles(state, action) {
      const data = action.payload;
      return { ...state, roles: data };
    },
    permissions(state, action) {
      const data = action.payload;
      return { ...state, permissions: data };
    },
    assignPermissions(state, action) {
      const data = action.payload;
      return { ...state, assignPermissions: data };
    },
    setModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
    rolePermissions(state, action) {
      const data = action.payload;
      return { ...state, rolePermissions: { ...data } };
    },
    name(state, action) {
      const data = action.payload;
      return { ...state, name: { ...data } };
    },
    assignMembers(state, action) {
      const data = action.payload;
      return { ...state, assignMembers: { ...data } };
    },
    membership(state, action) {
      const data = action.payload;
      return { ...state, membership: { ...data } };
    },
    assignOwners(state, action) {
      const data = action.payload;
      return { ...state, assignOwners: { ...data } };
    },
    ownership(state, action) {
      const data = action.payload;
      return { ...state, ownership: { ...data } };
    },
    search(state, action) {
      const data = action.payload;
      return { ...state, search: { ...data } };
    },
    details(state, action) {
      const data = action.payload;
      return { ...state, details: { ...data } };
    },
  },
});
export const {
  list,
  roles,
  permissions,
  create,
  update,
  remove,
  setModel,
  assignPermissions,
  rolePermissions,
  name,
  membership,
  assignMembers,
  assignOwners,
  ownership,
  search,
  details,
} = GroupManagementSlice.actions;
export default GroupManagementSlice.reducer;

export const getList = () =>
  apiCallBegan({
    url: "/groups",
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const createGroup = (data) =>
  apiCallBegan({
    url: "/groups",
    method: "post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const removeGroups = (data) =>
  apiCallBegan({
    url: "/groups",
    method: "delete",
    data,
    onSuccess: remove.type,
    onError: remove.type,
  });

export const updateGroup = (data, id) =>
  apiCallBegan({
    url: "/groups/" + id,
    method: "patch",
    data,
    onSuccess: update.type,
    onError: update.type,
  });

export const checkName = (nameToCheck) =>
  apiCallBegan({
    url: "/groups/check?Name=" + nameToCheck,
    method: "Get",
    onSpin: name.type,
    onSuccess: name.type,
    onError: name.type,
  });

export const assignMemberships = (data, id) =>
  apiCallBegan({
    url: "/groups/" + id + "/membership",
    method: "post",
    data,
    onSuccess: assignMembers.type,
    onError: assignMembers.type,
  });

export const getMemberships = (id) =>
  apiCallBegan({
    url: "/groups/membership/" + id,
    method: "Get",
    onSuccess: membership.type,
    onError: membership.type,
  });

export const assignOwnerships = (data, id) =>
  apiCallBegan({
    url: "/groups/" + id + "/ownership",
    method: "post",
    data,
    onSuccess: assignOwners.type,
    onError: assignOwners.type,
  });

export const getOwnerships = (id) =>
  apiCallBegan({
    url: "/groups/ownership/" + id,
    method: "Get",
    onSuccess: ownership.type,
    onError: ownership.type,
  });

export const searchGroups = (query) =>
  apiCallBegan({
    url: "/groups?q=" + query,
    method: "Get",
    onSuccess: search.type,
    onError: search.type,
  });

export const getGroupsDetails = (selectedGroups) =>
  apiCallBegan({
    url: `/groups/info/details?groups=${selectedGroups}`,
    method: "Get",
    onSuccess: details.type,
    onError: details.type,
  });