import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../ui/Button/Button";
import { SummaryIcon } from "../../../../../ui/Icons/Icons";
import KeyValue from "../../../../../ui/KeyValue/KeyValue";
import { setOperation } from "../../../AdminOperationManager/OperationStepSlice";
import { create, createFreshUser, setUserModel } from "../UserManagementSlice";
import "./CreateUserSummary.sass";

function CreateUserSummary({ onConfirm }) {
  const user = useSelector((state) => state.user);
  console.log(user.model);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.create.status === "success") {
      dispatch(create({ status: "" }));
      dispatch(setUserModel({ AutoGeneratedPassword: false }));
      onConfirm();
    } else if (user.create.status === "validation") {
      dispatch(setOperation("create_user"));
    }
  }, [user.create]);

  const editUserStep = () => {
    dispatch(setOperation("create_user"));
  };
  const confirmUserCreation = () => {
    dispatch(
      createFreshUser({
        ...user.model,
        Roles: user.model.Roles !== undefined ? [...user.model.Roles] : [],
      })
    );
  };
  return (
    <div className="create-user-summary">
      <p className="user-summary-description">
        Please check and confirm your information
      </p>
      <div className="user-summary-information">
        {Object.keys(user.model).map(
          (key, index) =>
            typeof user.model[key] === "string" && (
              <KeyValue
                key={index}
                attr={key}
                value={user.model[key].toString()}
                keyWidth="35%"
                valueWidth="65%"
              />
            )
        )}
      </div>
      <div className="user-summary-footer">
        <p className="user-summary-description">
          You can change this information after in user management.
        </p>
        <SummaryIcon />
        <div className="user-summary-buttons">
          <Button
            value="Edit"
            width="33%"
            isSelectable={true}
            onClick={editUserStep}
          />
          <Button
            value="Confirm"
            color="#2699fb"
            width="33%"
            onClick={confirmUserCreation}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateUserSummary;
