import "./LabeledIcon.sass";

function LabeledIcon({ color, textColor, icon, label }) {
  return (
    <div className="labeledIcon">
      {icon !== undefined && (
        <span style={{ marginRight: ".5em", color }}>{icon}</span>
      )}
      {label !== undefined && <span style={{ color: textColor }}>{label}</span>}
    </div>
  );
}

export default LabeledIcon;
