import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import React from "react";
import { ApiProvider } from "../components/ApiProvider/ApiProvider";
import { useLoginRequest } from "../helper/AuthConfigProvider";

function IsAuthenticated({ children }) {
  const { loginRequest } = useLoginRequest();
  return (
    <ApiProvider>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        {children}
      </MsalAuthenticationTemplate>
    </ApiProvider>
  );
}

export default IsAuthenticated;
