import './ToggleCameraModeSimple.sass'
import { useOnboardHub } from "src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider";
import { PayloadIndexes } from "src/helper/HubConnection";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCamera, FaVideo } from 'react-icons/fa';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';
import { cameraStats } from 'src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice';

function ToggleCameraModeSimple({ className, onToggleChange }) {
  const cameraStatsState = useSelector((state) => state.flight.cameraStats);
  const workMode = useSelector((state) => state.flight.cameraStats?.workMode);
  const [checked, setChecked] = useState();
  const { hub, groups, deviceId, joinCompleted } = useOnboardHub();
  const { activeCameraPayload } = useDeviceInfo();
  const dispatch = useDispatch();

  useEffect(() => {
    if (activeCameraPayload?.model?.toUpperCase() === 'M30T' && !!workMode !== checked) {
      dispatch(cameraStats({
        ...cameraStatsState,
        workMode: checked ? 1 : 0,
      }));
    }
    else setChecked(workMode === 1);
  }, [workMode]);

  const toggleCheck = () => {
    if (joinCompleted)
      hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
        cameraMode: {
          payloadIndex: activeCameraPayload?.payloadIndex,
          workMode: !checked ? 1 : 0,
        }
      });

    if (activeCameraPayload?.model?.toUpperCase() === 'M30T')
      setChecked(!workMode);

    dispatch(cameraStats({
      ...cameraStatsState,
      workMode: !checked ? 1 : 0,
    }));
  };

  useEffect(() => {
    onToggleChange(checked);
  }, [checked]);

  if (activeCameraPayload?.workModes?.length <= 1) {
    if (activeCameraPayload?.workModes.photo) onToggleChange(false);
    else if (activeCameraPayload?.workModes.video) onToggleChange(true);

    return null
  };

  return (
    <div className={"toggle-camera-mode-simple " + (className || '')}>
      <div className="content" onClick={toggleCheck}>
        {!checked ? (
          <FaCamera />
        ) : (
          <FaVideo />
        )}
      </div>
    </div>
  )
}

export default ToggleCameraModeSimple