import { createSlice, current } from "@reduxjs/toolkit";
import { apiCallBegan } from "src/redux/action/api";
import { MissionStatus } from "./common/missionConstants";

const MissionServiceSlice = createSlice({
  name: "missionService",
  initialState: {
    operationUnits: null,
    missions: [],
    createMissionProgress: null,
    runMissionRequest: null,
    issueMissionResult: null,
    executeMissionResult: null,
  },
  reducers: {
    setOperationUnits(state, action) {
      const data = action.payload;
      return { ...state, operationUnits: data };
    },
    setMissions(state, action) {
      const data = action.payload;
      return { ...state, missions: data };
    },
    addNewMission(state, action) {
      const data = action.payload;
      state.missions = state.missions.filter((item) => item.id !== data.id);

      state.missions.push({
        ...data,
        status: data.status || MissionStatus.IDLE,
        statusDescription: data.statusDescription || "Mission is idle",
        name: data.name || "Mission " + new Date().toUTCString(),
        updateTimestamp: new Date().getTime(),
        deviceId: data.deviceId || null,
        serialNumber: data.serialNumber || null,
        userId: data.userId || null,
        selfInitiated: data.selfInitiated || true,
      });
      return state;
    },
    setMissionInfo(state, action) {
      const data = action.payload;
      const {id, deviceId, serialNumber} = data;
      const index = state.missions.findIndex((item) => 
        (id && item.id == id) ||
        (deviceId && item.deviceId == deviceId) ||
        (serialNumber && item.serialNumber == serialNumber)
      );

      if(index !== -1)
        state.missions[index] = { 
          ...state.missions[index], 
          ...data,
          updateTimestamp: new Date().getTime(),
        };
      else if(
        id ||
        deviceId ||
        serialNumber
      )
        state.missions.push({
          id,
          ...data,
          updateTimestamp: new Date().getTime(),
        });

      return state;
    },
    removeMission(state, action) {
      const {id, deviceId, serialNumber} = action.payload;
      const index = state.missions.findIndex((item) => item.id === id || item.deviceId === deviceId || item.serialNumber === serialNumber);

      if(index !== -1)
        state.missions.splice(index, 1);

      return state;
    },
    setCreateMissionProgress(state, action) {
      const data = action.payload;
      return { ...state, createMissionProgress: data };
    },
    setCreateMissionResult(state, action) {
      const data = action.payload;
      return { ...state, createMissionResult: data };
    },
    setRunMissionRequest(state, action) {
      const data = action.payload;
      return { ...state, runMissionRequest: data };
    },
    setIssueMissionResult(state, action) {
      const data = action.payload;
      return { ...state, issueMissionResult: data };
    },
    setExecuteMissionResult(state, action) {
      const data = action.payload;
      return { ...state, executeMissionResult: data };
    },
    setMissionPendingRequest(state, action) {
      const { missionId, deviceId, requestType, requestStatus, timeout, failureMessage } = action.payload;
      const missionIndex = state.missions.findIndex((item) => item.id === missionId || item.deviceId === deviceId);
    
      if (missionIndex !== -1) {
        const pendingRequests = state.missions[missionIndex].pendingRequests || [];
        const updatedPendingRequests = pendingRequests.filter((item) => item.requestType !== requestType);
    
        updatedPendingRequests.push({
          requestType,
          requestStatus,
          timeout,
          failureMessage,
          startTimestamp: Date.now(),
        });
  
        state.missions[missionIndex] = {
          ...state.missions[missionIndex],
          pendingRequests: updatedPendingRequests,
        };

        console.log('🔥 setPending', requestType, state.missions[missionIndex]);
      } else {
        console.warn(`Mission with ID ${missionId} not found.`);
      }
    },    
    removeMissionPendingRequest(state, action) {
      const { missionId, deviceId, requestType } = action.payload;
      const missionIndex = state.missions.findIndex((item) => item.id === missionId || item.deviceId === deviceId);
    
      if (missionIndex !== -1) {
        const currentPendingRequests = state.missions[missionIndex].pendingRequests;
        console.log('removeMissionPendingRequest', missionId, requestType, missionIndex, currentPendingRequests && current(currentPendingRequests));
    
        // Filter out the requestType to be removed
        const updatedPendingRequests = (currentPendingRequests || []).filter(item => {
          return Array.isArray(requestType) ?
            !requestType.includes(item.requestType) :
            item.requestType !== requestType;
        });
    
        console.log('removeMissionPendingRequest [after]', requestType, updatedPendingRequests);
        
        state.missions[missionIndex] = {
          ...state.missions[missionIndex],
          pendingRequests: updatedPendingRequests
        };
      } else {
        console.warn(`Mission with ID ${missionId} not found.`);
      }
    },    
  }
});

export const {
  setOperationUnits,
  setMissions,
  addNewMission,
  setMissionInfo,
  removeMission,
  setCreateMissionProgress,
  setCreateMissionResult,
  setRunMissionRequest,
  setIssueMissionResult,
  setExecuteMissionResult,
  setMissionPendingRequest,
  removeMissionPendingRequest
} = MissionServiceSlice.actions;
export default MissionServiceSlice.reducer;

export const sendCreateMission = (unitId, data) =>
  apiCallBegan({
    url: `security-app/units/${unitId}/missions`,
    method: "Post",
    data,
    onSuccess: setCreateMissionProgress.type,
    onError: setCreateMissionProgress.type,
  });