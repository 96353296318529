import { useDispatch, useSelector, useStore } from "react-redux";
import { deviceTypes } from "src/helper/constants";
import { getCoordinatesDistanceAndTime } from "src/helper/waypointMarkupUtils";
import { getDeviceInfo, getDeviceModelInfo, getDeviceTelemetries, getSubDeviceTelemetries } from "src/services/device/common/deviceUtils";
import { createKmz, kmzInputData, kmzWaypointData } from "./missionUtils";
import { generateId } from "src/helper/utils";
import { useEffect, useRef, useState } from "react";
import { Optional } from 'utility-types';
import { addNewMission, removeMission, removeMissionPendingRequest, sendCreateMission, setCreateMissionProgress, setCreateMissionResult, setMissionInfo, setMissionPendingRequest } from "../MissionServiceSlice";
import { MissionPendingRequestStatus, MissionPendingRequestType, MissionStatus, MissionType } from "./missionConstants";
import useCurrentUserId from "src/helper/useCurrentUserId";

type MissionWaypointData = Optional<kmzWaypointData, 'actions' | 'speed' | 'latitude' >;

export interface MissionDataType {
  name?: string;
  unitId: string;
  deviceId: string;
  droneEnumValue?: number;
  droneSubEnumValue?: number;
  payloadEnumValue?: number;
  payloadSubEnumValue?: number;
  altitude: number;
  relativeAltitude: number;
  rthAltitude: number;
  rcLostAction: number;
  speed: number;
  waypoints: MissionWaypointData[];
  missionType: typeof MissionType[keyof typeof MissionType];
}

interface Waypoint {
  id: string;
  status: typeof MissionStatus[keyof typeof MissionStatus];
  latitude: number;
  longitude: number;
  altitude: number;
  speed: number;
  duration: number;
  distance: number;
  remainingDuration?: number;
  remainingDistance?: number;
  actions: Record<string, any>;
}

interface Mission {
  id: null | string;
  name: string;
  missionType: typeof MissionType[keyof typeof MissionType];
  status: typeof MissionStatus[keyof typeof MissionStatus];
  statusDescription: string;
  flightId: string;
  controllerId: string;
  deviceId?: string;
  dockId?: string;
  deviceType?: typeof deviceTypes[keyof typeof deviceTypes];
  serialNumber?: string;
  userId: string;
  unitId: string;
  updateTimestamp: number;
  startLatitude: number;
  startLongitude: number;
  duration: number;
  distance: number;
  speed: number;
  altitude: number;
  waypoints: Waypoint[];
  flightDetails: {
    id: string | null;
    fingerprint: string | null;
    url: string | null;
    kmz?: string | null;
  };
  deviceDetails: {
    droneEnumValue: number | null;
    droneSubEnumValue: number | null;
    deviceModelInfo: Record<string, any> | null;
    [key: string]: any;
  };
  missionDetails: {
    [key: string]: any;
  };
}

export const prepareMissionWaypoints = (deviceId: string, waypoints: MissionWaypointData[], speed: number = 5, altitude: number = 30) => {
  const telemetries = getDeviceTelemetries(deviceId);
  const subDeviceTelemetries = getSubDeviceTelemetries(deviceId);
  const device = getDeviceInfo(deviceId);

  // add the start waypoint and data
  let currentGpsCoords;

  if(device?.type === deviceTypes.DRONE)
    currentGpsCoords = {latitude: parseFloat(Number(telemetries?.gps?.lat).toFixed(7)), longitude: parseFloat(Number(telemetries?.gps?.long).toFixed(7))};
  else if(device?.type === deviceTypes.DOCK)
    currentGpsCoords = {latitude: (subDeviceTelemetries || telemetries)?.latitude, longitude: (subDeviceTelemetries || telemetries)?.longitude};

  const finalWaypoints = [
    currentGpsCoords,
    ...waypoints
  ].map(item => ({
    ...item,
    altitude: item?.altitude || altitude,
    speed: item?.speed || speed,
    actions: {}
  }));

  return finalWaypoints;
}

export default function useCreateMission() {
  const store = useStore();
  const dispatch = useDispatch();
  const userId = useCurrentUserId();
  const registerOnServerRetries = useRef(0);
  const inProgressDevices = useRef<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastMissionData, setLastMissionData] = useState<Mission | null>(null);
  const createMissionProgress = useSelector(state => state.missionService.createMissionProgress);

  const createMission = (missionData: MissionDataType) => {
    let droneEnumValue = missionData?.droneEnumValue;
    let droneSubEnumValue = missionData?.droneSubEnumValue;
    let waypoints = missionData?.waypoints;
    let deviceModelInfo;

    if(inProgressDevices.current.includes(missionData?.deviceId)) {
      console.error("Mission creation is already in progress for this device.");
      return;
    }
    inProgressDevices.current.push(missionData?.deviceId);

    const device = store.getState().deviceService.devices.find(item => item.id === missionData?.deviceId);
    const telemetries = getDeviceTelemetries(missionData?.deviceId);

    // get device model numbers
    if(device?.type === deviceTypes.DRONE) {
      droneEnumValue = droneEnumValue ?? (Number(telemetries?.droneInfo?.modelNumber) || 67);
      droneSubEnumValue = 1;
      deviceModelInfo = getDeviceModelInfo(droneEnumValue) || { droneEnumValue };
    }
    else if(device?.type === deviceTypes.DOCK) {
      const deviceModelKey = telemetries?.sub_device?.device_model_key;
      deviceModelInfo = getDeviceModelInfo(deviceModelKey);
      droneEnumValue = deviceModelInfo?.modelNumber || 80;
      droneSubEnumValue = deviceModelInfo?.subModelNumber || 0;
    }

    // Prepare waypoints
    const finalWaypoints = prepareMissionWaypoints(missionData?.deviceId, waypoints, missionData?.speed, missionData?.altitude);

    // Get total distance and time
    const {
      distance,
      time
    } = getCoordinatesDistanceAndTime(finalWaypoints.map(item => ({
      lat: item.latitude,
      long: item.longitude
    })), missionData?.speed);

    const kmzData: kmzInputData = {
      droneEnumValue,
      droneSubEnumValue,
      payloadEnumValue: missionData?.payloadEnumValue,
      payloadSubEnumValue: missionData?.payloadSubEnumValue,
      speed: missionData?.speed,
      altitude: missionData?.altitude,
      waypoints: finalWaypoints
    };

    createKmz(kmzData).then((content) => {
      const flightId = generateId();

      const reqData: any = {
        name: missionData.name || "Mission " + new Date().toUTCString(),
        missionType: MissionType.DFR,
        status: MissionStatus.PREPARING,
        statusDescription: "Registering on the server...",
        flightId,
        controllerId: userId,
        deviceType: device?.type,
        serialNumber: device?.serialNumber,
        userId: userId,
        unitId: missionData?.unitId,
        startLatitude: finalWaypoints[0]?.latitude,
        startLongitude: finalWaypoints[0]?.longitude,
        duration: Math.round(time) || 0,
        distance: Math.round(distance) || 0,
        speed: missionData?.speed,
        altitude: missionData?.altitude,
        waypoints: [],
        kmz: content,
        deviceDetails: { // dynamic structure
          droneEnumValue: droneEnumValue || null,
          droneSubEnumValue: droneSubEnumValue || 0,
          deviceModelInfo: deviceModelInfo || {}
        },
        missionDetails: {} // mission type specific config params
      };

      const newMissionData: Mission = {
        id: null,
        name: reqData.name,
        missionType: MissionType.DFR,
        status: MissionStatus.PREPARING,
        statusDescription: "Registering on the server...",
        flightId,
        controllerId: userId,
        deviceId: device?.id,
        deviceType: device?.type,
        serialNumber: device?.serialNumber,
        userId: userId,
        unitId: missionData?.unitId,
        updateTimestamp: Date.now(),
        startLatitude: reqData.startLatitude,
        startLongitude: reqData.startLongitude,
        duration: reqData.duration,
        distance: reqData.distance,
        speed: reqData.speed,
        altitude: reqData.altitude,
        waypoints: finalWaypoints?.map((item, index) => ({
          id: generateId(),
          status: index ? MissionStatus.PREPARING : MissionStatus.COMPLETED,
          latitude: item.latitude,
          longitude: item.longitude,
          altitude: item.altitude,
          speed: item.speed,
          duration: reqData.duration,
          distance: reqData.distance,
          actions: {}
        })),
        flightDetails: {
          id: flightId,
          fingerprint: null,
          url: null,
          kmz: content,
        },
        deviceDetails: { // dynamic structure
          droneEnumValue: reqData.droneEnumValue || null,
          droneSubEnumValue: reqData.droneSubEnumValue || 0,
          deviceModelInfo: reqData.deviceModelInfo || {}
        },
        missionDetails: { // mission type specific config params
          securityTakeoffHeight: missionData.relativeAltitude,
          rthAltitude: missionData.rthAltitude,
          rcLostAction: missionData.rcLostAction,
        }
      };

      if(device?.type === deviceTypes.DRONE) {
        reqData.deviceId = device?.id;
      }
      else if(device?.type === deviceTypes.DOCK) {
        reqData.dockId = device?.id;
        newMissionData.dockId = device?.id;
      }

      dispatch(addNewMission(newMissionData));
      setLoading(true);

      dispatch(setMissionPendingRequest({
        deviceId: device?.id,
        requestType: MissionPendingRequestType.REGISTER_ON_SERVER,
        requestStatus: MissionPendingRequestStatus.PENDING,
      }));

      reqData.kmz = content;
      reqData.flightId = flightId;

      setLastMissionData(newMissionData);
      dispatch(sendCreateMission(missionData?.unitId, reqData));

      inProgressDevices.current = inProgressDevices.current.filter(item => item !== missionData?.deviceId);
      return reqData;
    });
  }

  useEffect(() => {
    const data = lastMissionData as Mission;

    if (createMissionProgress?.status === 'success') {
      data.id = createMissionProgress?.data?.missionId;
      data.statusDescription = "Register on server success!";

      if(createMissionProgress?.data?.flight)
        data.flightDetails = {
          ...data.flightDetails,
          id: createMissionProgress?.data.flight.id,
          fingerprint: createMissionProgress?.data.flight.fingerprint,
          url: createMissionProgress?.data.flight.url,
        };

      dispatch(removeMissionPendingRequest({
        deviceId: data?.deviceId,
        requestType: MissionPendingRequestType.REGISTER_ON_SERVER,
      }));

      dispatch(setMissionInfo(data));
      dispatch(setCreateMissionResult(data));
    }
    else if (createMissionProgress?.status === 'error') {
      if(registerOnServerRetries.current < 2) {
        data.statusDescription = "Register on server failed. Retrying...";
        dispatch(setMissionInfo(data));

        registerOnServerRetries.current++;
        dispatch(sendCreateMission(lastMissionData?.unitId, lastMissionData));
      }
      else {
        dispatch(setCreateMissionResult(false));

        dispatch(removeMission({id: data?.id}));
        dispatch(removeMissionPendingRequest({
          deviceId: data?.deviceId,
          requestType: MissionPendingRequestType.REGISTER_ON_SERVER,
        }));
      }
    }

    setLoading(false);
  }, [createMissionProgress])

  useEffect(() => {
    //dispatch(setCreateMissionProgress(null));
  }, []);

  return {
    createMission,
    loading,
  }
}
