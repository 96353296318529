import "./DeviceOnboarding.sass";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OperationHandler from "src/ui/OperationHandler/OperationHandler";
import { MdCloudQueue, MdOutlineWest } from "react-icons/md";
import EntityName from "src/ui/EntityName/EntityName";
import SelectGroupForm from "./SelectGroupForm/SelectGroupForm";
import CreateNewGroup from "./CreateNewGroup/CreateNewGroup";
import GroupMembershipApp from "src/ui/GroupApp/GroupMembershipApp/GroupMembershipApp";
import { GenerateOnboardPassKey } from "../OnboardDeviceAppSlice";
import OnboardingProgress from "./OnboardingProgress/OnboardingProgress";
import { setOperation } from "src/components/Dashboard/Components/ApplicationOperationManager/AppOperationStepSlice";

function DeviceOnboarding({ onCloseByUser }) {
  const groupApp = useSelector((state) => state.groupApp);
  const [operationHandlerOpen, setOperationHandlerOpen] = useState(true);
  const [currentFeatureId, setCurrentFeatureId] = useState("onboarding");
  const [lockGroupSelection, setLockGroupSelection] = useState(false);
  const passkeyState = useSelector((state) => state.onboard.passkey);
  const dispatch = useDispatch();

  const toggleOperationHandlerOff = () => {
    onCloseByUser?.();
  };
  const toggleOperationHandlerOpen = () => {
    setOperationHandlerOpen(false);
  };

  const selectNewGroupFeature = (id) => {
    setCurrentFeatureId(id);
  };

  const groupCreateComplete = () => {
    selectNewGroupFeature("onboarding");
    dispatch(setOperation("select_groups"));
  };

  const [ids, setIds] = useState([]);

  const selectGroupFinished = (groups) => {
    setIds(groups.map((item) => item.id));
  };

  useEffect(() => {
    if (ids.length > 0) {
      dispatch(GenerateOnboardPassKey({ groups: ids }, ids));
    }
  }, [ids]);

  const onDeviceOnboardingComplete = () => {
    toggleOperationHandlerOpen();
  };

  useEffect(() => {
    if (passkeyState.status === "success") {
      dispatch(setOperation("onboarding_progress"));
    }
  }, [passkeyState]);

  const groupFeatures = [
    {
      id: "onboarding",
      title: 'Connect your drone to the cloud',
      description: "First, consider a group for the device and send the code to the Android application to perform the steps of registering the device.",
      icon: <MdCloudQueue />,
      steps: [
        {
          name: "select_groups",
          title: "Assign Groups",
          active: true,
          visible: true,
          locked: false,
          fullHeight: true,
          closable: false,
          deactivate: true,
          passiveMode: true,
          tags: ["groupApp/update", "onboard/passkey"],
          activity: (
            <SelectGroupForm
              onNewGroup={() => {
                selectNewGroupFeature("create_group");
              }}
              onSelectConfirm={selectGroupFinished}
            />
          ),
        },
        {
          name: "onboarding_progress",
          title: "Onboard Device",
          active: false,
          visible: true,
          locked: true,
          fullHeight: true,
          closable: false,
          deactivate: true,
          tags: ["onboard/passkey"],
          activity: (
            <OnboardingProgress
              groups={ids}
              onComplete={onDeviceOnboardingComplete}
              onCancel={() => {
                dispatch(setOperation("select_groups"));
              }}
              onStepChange={(step) => {
                if (step > 1) setLockGroupSelection(true);
              }}
            />
          ),
        },
      ],
    },
    {
      id: "create_group",
      title: "Create new group",
      description:
        "Please select your group and then click for generate code.  ",
      icon: <MdOutlineWest
        onClick={groupCreateComplete}
        style={{ cursor: "pointer" }}
      />,
      steps: [
        {
          name: "create_group",
          active: true,
          visible: true,
          locked: false,
          closable: false,
          noBorder: true,
          fullHeight: true,
          tags: ["groupApp/create"],
          activity: <CreateNewGroup onConfirm={groupCreateComplete} />,
        },
      ],
    },
  ];

  const [currentFeature, setCurrentFeature] = useState(groupFeatures[0]);

  useEffect(() => {
    setCurrentFeature(groupFeatures.find((f) => f.id === currentFeatureId));
  }, [currentFeatureId]);

  useEffect(() => {
    dispatch(setOperation("select_groups"));
  }, []);

  return (
    <>
      <OperationHandler
        selectedFeature={currentFeature}
        toggle={toggleOperationHandlerOff}
        isOpen={operationHandlerOpen}
        close={toggleOperationHandlerOpen}
        onStepChange={() => { }}
      />
    </>
  );
}

export default DeviceOnboarding;
