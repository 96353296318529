import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "../../helper/ThemeProvider";
import DesktopSize from "../DesktopSize/DesktopSize";
import Loading from "../Loading/Loading";
import TabletSize from "../TabletSize/TabletSize";
import Toast from "../Toast/Toast";
import "./PanelItem.sass";

const PanelItem = ({ panel }) => {
  const loading = useSelector((state) => state.loading);
  const [active, setActive] = useState(false);
  const { currentContext } = useTheme();
  useEffect(() => {}, [loading]);
  useEffect(() => {
    setActive(panel.active);
  }, [panel.active]);

  return (
    <>
      <TabletSize>
        <div
          className={`panel-item ${
            active
              ? "panel-item-active"
              : panel.passed
              ? "panel-item-passed"
              : "panel-item-passive"
          }`}
        >
          <div
            className="panel-item-title"
            style={{
              fontWeight: `${
                active ? "normal" : panel.passed ? "medium" : "normal"
              }`,
              color: `${
                active
                  ? currentContext.theme.color
                  : panel.passed
                  ? "#455060"
                  : "#8b9695"
              }`,
            }}
          >
            {panel.title}
          </div>
          {active && (
            <div className="panel-item-content">
              {loading.status === true && panel.tags.includes(loading.name) && (
                <Loading />
              )}
              {panel.content}
              <Toast />
            </div>
          )}
        </div>
      </TabletSize>

      <DesktopSize>
        <div
          className={`panel-item ${
            active
              ? "panel-item-active"
              : panel.passed
              ? "panel-item-passed"
              : "panel-item-passive"
          }`}
          style={{
            borderLeft:
              "6px solid " +
              `${
                active
                  ? panel.borderColor
                    ? panel.borderColor
                    : currentContext.theme.color
                  : panel.passed
                  ? panel.borderColor
                    ? panel.borderColor
                    : currentContext.theme.color
                  : "#c1c1c1"
              }`,
          }}
        >
          <div
            className="panel-item-title"
            style={{
              fontWeight: `${
                active ? "normal" : panel.passed ? "medium" : "normal"
              }`,
              color: `${
                active
                  ? currentContext.theme.color
                  : panel.passed
                  ? "#455060"
                  : "#8b9695"
              }`,
            }}
          >
            {panel.title}
          </div>
          {active && (
            <div className="panel-item-content">
              {loading.status === true && panel.tags.includes(loading.name) && (
                <Loading />
              )}
              {panel.content}
              <Toast />
            </div>
          )}
        </div>
      </DesktopSize>
    </>
  );
};

export default PanelItem;
