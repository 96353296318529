import { cameraExposureModes } from "src/helper/constants";
import { useDeviceInfo } from "src/helper/DeviceInfoProvider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CameraTelemetry from "../../../../components/Dashboard/Components/CameraTelemetry/CameraTelemetry";
import "./CameraShutter.sass";
import CameraShutterSelector from "./CameraShutterSelector/CameraShutterSelector";

function CameraShutter() {
  const flight = useSelector((state) => state.flight);
  const exposureMode = useSelector((state) => state.flight.camera?.exposureMode);
  const [currValue, setCurrValue] = useState("Auto");
  const { activeCameraPayload } = useDeviceInfo();

  useEffect(() => {
    const currentShutterSpeed = activeCameraPayload?.settings?.shutterSpeed?.values?.[flight.camera?.shutterSpeed];
    const shutter = currentShutterSpeed ?? "-";

    setCurrValue(shutter);
  }, [activeCameraPayload, flight.camera]);

  if (!activeCameraPayload?.settings?.shutterSpeed) return null;

  return (
    <CameraTelemetry
      name="shutter"
      title="Shutter"
      value={currValue}
      selector={<CameraShutterSelector />}
      disabled={exposureMode === cameraExposureModes.PROGRAM_AUTO}
    />
  );
}

export default CameraShutter;
