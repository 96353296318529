import "./ApplicationOperationManagerStatic.sass";
import PropTypes from "prop-types";
import TabletSize from "../../../../ui/TabletSize/TabletSize";
import DesktopSize from "../../../../ui/DesktopSize/DesktopSize";
import { useEffect, useState } from "react";
import ApplicationsOperationStep from "../../../../ui/ApplicationsOperationStep/ApplicationsOperationStep";
import ApplicationOperationStepMenuItem from "../../../../ui/ApplicationOperationStepMenuItem/ApplicationOperationStepMenuItem";
import LabeledIcon from "../../../../ui/LabeledIcon/LabeledIcon";
import { useHistory, useLocation } from "react-router-dom";

function ApplicationOperationManagerStatic({ selectedFeature }) {
  const [steps, setSteps] = useState(
    selectedFeature?.steps && [...selectedFeature.steps]
  );
  const [feature, setFeature] = useState(selectedFeature);
  const [activeStep, setActiveStep] = useState();
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setFeature(selectedFeature);
    if (
      activeStep === undefined &&
      selectedFeature?.steps !== undefined &&
      selectedFeature.steps.length > 0
    ) {
      setActiveStep({ ...selectedFeature.steps.find((step) => step.active) });
    }
  }, [selectedFeature]);

  const stepActivator = (name) => {
    const step = steps.find((step) => step.name === name);
    if(!step) return;

    if(step.path)
      history.push(step.path);
    else
      setActiveStep(step);
  };

  useEffect(() => {
    setSteps([
      ...selectedFeature.steps.map((step) => ({
        ...step,
        active: step.name === activeStep?.name,
      })),
    ]);
  }, [activeStep]);

  useEffect(() => {
    const matchedStep = steps.find(item => location.pathname === item.path);
    if(matchedStep) setActiveStep(matchedStep);
  }, [location]);

  return (
    <div className="application-operation-manager-static">
      <TabletSize>
        <ul className="application-operation-manager-static-steps">
          {steps &&
            steps.map(
              (step, index) =>
                step.visible && (
                  <ApplicationsOperationStep
                    icon={step.icon}
                    isActive={step.active}
                    name={step.name}
                    toggleActive={stepActivator}
                    title={step.title}
                    isLocked={step.locked}
                    tags={step.tags}
                    controls={step.controls}
                    actions={step.actions}
                    key={index}
                  >
                    {step.activity}
                  </ApplicationsOperationStep>
                )
            )}
        </ul>
      </TabletSize>
      <DesktopSize>
        <div className="application-operation-manager-static-desktop">
          <ul className="application-operation-manager-static-desktop-menu">
            {steps &&
              steps.map(
                (step, index) =>
                  step.visible && (
                    <ApplicationOperationStepMenuItem
                      key={index}
                      isActive={step.active}
                      isLocked={step.isLocked}
                      name={step.name}
                      title={step.title}
                      toggleActive={stepActivator}
                      icon={step.icon}
                    />
                  )
              )}
          </ul>
          <div className="application-operation-manager-static-desktop-area">
            {(activeStep?.controls !== undefined ||
              activeStep?.actions !== undefined) && (
              <div className="application-operation-manager-static-desktop-control-area">
                {activeStep?.controls !== undefined && (
                  <ul className="application-operation-step-controls">
                    {activeStep.controls.map((control, index) => (
                      <li
                        className="application-operation-step-control"
                        key={index}
                        onClick={() => {
                          control.action();
                        }}
                      >
                        {control.component}
                      </li>
                    ))}
                  </ul>
                )}
                {activeStep?.actions !== undefined && (
                  <ul className="application-operation-step-actions">
                    {activeStep.actions.map((action, index) => (
                      <li
                        className="application-operation-step-action"
                        key={index}
                      >
                        {action.component}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            {activeStep?.activity}
          </div>
        </div>
      </DesktopSize>
    </div>
  );
}

ApplicationOperationManagerStatic.propTypes = {
  feature: PropTypes.object.isRequired,
};
ApplicationOperationManagerStatic.defaultProps = {
  feature: { title: "", description: "", color: "#2699fb" },
};

export default ApplicationOperationManagerStatic;
