import './AccordionItem.sass'

import React, {useEffect, useState} from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useSpring, animated, config } from "react-spring";
import useMeasure from "react-use-measure";

function AccordionItem({title, isOpen, onToggle, children}) {
  const [isOpenLocal, setIsOpenLocal] = useState(false);
  const [measureRef, { height: contentHeight }] = useMeasure();

  const contentStyles = useSpring({
    from: {
      height: 0
    },
    to: {
      height: isOpenLocal ? contentHeight : 0
    }
  });

  useEffect(() => {
    setIsOpenLocal(isOpen ?? false);
  }, [isOpen])

  const toggleContent = () => {
    setIsOpenLocal(curr => {
      const newValue = !curr;
      onToggle?.(newValue);
      return newValue;
    })
  }

  return (
    <div className={"accordion-item " + (isOpenLocal ? 'active' : '')}>
      <div className="accordion-item-button" onClick={toggleContent}>
        <div className="item-title">{title}</div>
        <div className="item-indicator">
          {isOpenLocal ? (
            <BsChevronUp />
          ) : (
            <BsChevronDown />
          )}
        </div>
      </div>
      <animated.div className="accordion-item-content" style={{ overflow: "hidden", ...contentStyles }}>
        <div ref={measureRef}>{children}</div>
      </animated.div>
    </div>
  )
}

export default AccordionItem