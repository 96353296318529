import "./TimeComponent.sass";
import {
  WiTime1,
  WiTime2,
  WiTime3,
  WiTime4,
  WiTime5,
  WiTime6,
  WiTime7,
  WiTime8,
  WiTime9,
  WiTime10,
  WiTime11,
  WiTime12,
} from "react-icons/wi";

function TimeComponent({ time, hour }) {
  return (
    <div className="time-component">
      {/* {() => {
          switch (hour) {
            case 1:
              return <WiTime1 />;
            case 2:
              return <WiTime2 />;
            case 3:
              return <WiTime3 />;
            case 4:
              return <WiTime4 />;
            case 5:
              return <WiTime5 />;
            case 6:
              return <WiTime6 />;
            case 7:
              return <WiTime7 />;
            case 8:
              return <WiTime8 />;
            case 9:
              return <WiTime9 />;
            case 10:
              return <WiTime10 />;
            case 11:
              return <WiTime11 />;
            default:
              return <WiTime12 />;
          }
        }} */}
      {(() => {
        switch (hour) {
          case 1:
            return <WiTime1 />;
          case 2:
            return <WiTime2 />;
          case 3:
            return <WiTime3 />;
          case 4:
            return <WiTime4 />;
          case 5:
            return <WiTime5 />;
          case 6:
            return <WiTime6 />;
          case 7:
            return <WiTime7 />;
          case 8:
            return <WiTime8 />;
          case 9:
            return <WiTime9 />;
          case 10:
            return <WiTime10 />;
          case 11:
            return <WiTime11 />;
          default:
            return <WiTime12 />;
        }
      })()}
      <span>{time}</span>
    </div>
  );
}

export default TimeComponent;
