import { useCameraSource } from "../../../../../helper/CameraSourceProvider";
import { usePayloadSource } from "../../../../../helper/PayloadSourceProvider";
import CameraTelemetries from "../../../../../ui/Drone/CameraTelemetries/CameraTelemetries";
import DroneActions from "../../../../../ui/Drone/DroneActions/DroneActions";
import SwitchCamera from "../../../../../ui/Drone/SwitchCamera/SwitchCamera";
import ThermalWideToggle from "../../../../../ui/Drone/ThermalWideToggle/ThermalWideToggle";
import ZoomWideToggle from "../../../../../ui/Drone/ZoomWideToggle/ZoomWideToggle";
import "./FlightActionBar.sass";

function FlightActionBar() {
  const { isMainCamera } = useCameraSource();
  const { isPayloadDisconnected } = usePayloadSource();
  return (
    <div className="flight-action-bar">
      {/* <DroneActions /> */}
      <div className="flight-action-bar-container">
        {isMainCamera && <CameraTelemetries />}
        {/* {!isPayloadDisconnected && <SwitchCamera />}
        {isMainCamera && <ZoomWideToggle />}
        {isMainCamera && <ThermalWideToggle />} */}
      </div>
    </div>
  );
}

export default FlightActionBar;
