export const MapShipTypeProperties = {
  "Anti-Pollution": { color: "#FF6347", size: 1.0 }, // Tomato
  "Anti-Pollution Vessel": { color: "#FF6347", size: 1.0 }, // Tomato
  "Beacon, Cardinal E": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Cardinal N": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Cardinal S": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Cardinal W": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Isolated danger": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Port Hand": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Preferred Channel Port hand": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Preferred Channel Starboard hand": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Safe Water": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Special Mark": { color: "#FFD700", size: 0.8 }, // Gold
  "Beacon, Starboard Hand": { color: "#FFD700", size: 0.8 }, // Gold
  "Cardinal Mark E": { color: "#FFD700", size: 0.8 }, // Gold
  "Cardinal Mark N": { color: "#FFD700", size: 0.8 }, // Gold
  "Cardinal Mark S": { color: "#FFD700", size: 0.8 }, // Gold
  "Cardinal Mark W": { color: "#FFD700", size: 0.8 }, // Gold
  "Cargo": { color: "#1E90FF", size: 1.2 }, // DodgerBlue
  "Cargo - Hazard A (Major)": { color: "#1E90FF", size: 1.3 }, // DodgerBlue
  "Cargo - Hazard B": { color: "#1E90FF", size: 1.3 }, // DodgerBlue
  "Cargo - Hazard C (Minor)": { color: "#1E90FF", size: 1.3 }, // DodgerBlue
  "Cargo - Hazard D (Recognizable)": { color: "#1E90FF", size: 1.3 }, // DodgerBlue
  "Cargo: Hazardous category A": { color: "#1E90FF", size: 1.3 }, // DodgerBlue
  "Cargo: Hazardous category B": { color: "#1E90FF", size: 1.3 }, // DodgerBlue
  "Cargo: Hazardous category C": { color: "#1E90FF", size: 1.3 }, // DodgerBlue
  "Cargo: Hazardous category D": { color: "#1E90FF", size: 1.3 }, // DodgerBlue
  "Dive Ops": { color: "#00CED1", size: 1.0 }, // DarkTurquoise
  "Dive Vessel": { color: "#00CED1", size: 1.0 }, // DarkTurquoise
  "Dredger": { color: "#8B0000", size: 1.1 }, // DarkRed
  "Fishing": { color: "#FFDAB9", size: 1.0 }, // PeachPuff
  "High Speed Craft": { color: "#DC143C", size: 1.2 }, // Crimson
  "HSC": { color: "#DC143C", size: 1.2 }, // Crimson
  "Isolated Danger": { color: "#FF4500", size: 0.9 }, // OrangeRed
  "Law Enforce": { color: "#2F4F4F", size: 1.0 }, // DarkSlateGray
  "Law Enforcement": { color: "#2F4F4F", size: 1.0 }, // DarkSlateGray
  "Leading Light Front": { color: "#FFA500", size: 0.9 }, // Orange
  "Leading Light Rear": { color: "#FFA500", size: 0.9 }, // Orange
  "Light Vessel": { color: "#ADFF2F", size: 0.8 }, // GreenYellow
  "Light, without Sectors": { color: "#ADFF2F", size: 0.8 }, // GreenYellow
  "Light, with Sectors": { color: "#ADFF2F", size: 0.8 }, // GreenYellow
  "Local Vessel": { color: "#FF69B4", size: 0.9 }, // HotPink
  "Manned VTS": { color: "#4B0082", size: 1.0 }, // Indigo
  "Medical Trans": { color: "#FF1493", size: 1.0 }, // DeepPink
  "Military Ops": { color: "#008080", size: 1.3 }, // Teal
  "Navigation Aid": { color: "#2E8B57", size: 0.9 }, // SeaGreen
  "OffShore Structure": { color: "#708090", size: 1.4 }, // SlateGray
  "Other": { color: "#A9A9A9", size: 0.8 }, // DarkGray
  "Passenger": { color: "#FF4500", size: 1.4 }, // OrangeRed
  "Pilot Vessel": { color: "#B22222", size: 1.0 }, // FireBrick
  "Pleasure Craft": { color: "#FF69B4", size: 0.9 }, // HotPink
  "Port Hand Mark": { color: "#8A2BE2", size: 0.9 }, // BlueViolet
  "Port Tender": { color: "#8A2BE2", size: 0.9 }, // BlueViolet
  "Preferred Channel Port Hand": { color: "#7B68EE", size: 0.9 }, // MediumSlateBlue
  "Preferred Channel Starboard Hand": { color: "#7B68EE", size: 0.9 }, // MediumSlateBlue
  "RACON": { color: "#20B2AA", size: 1.0 }, // LightSeaGreen
  "Reference Point": { color: "#CD5C5C", size: 0.8 }, // IndianRed
  "Reserved": { color: "#808080", size: 0.8 }, // Gray
  "Safe Water": { color: "#00FA9A", size: 0.8 }, // MediumSpringGreen
  "Sailing Vessel": { color: "#7FFFD4", size: 0.9 }, // Aquamarine
  "SAR": { color: "#FF6347", size: 1.0 }, // Tomato
  "SAR Aircraft": { color: "#FF6347", size: 1.0 }, // Tomato
  "Search and Rescue Aircraft": { color: "#FF6347", size: 1.0 }, // Tomato
  "Search and Rescue Vessel": { color: "#FF6347", size: 1.0 }, // Tomato
  "Spare": { color: "#A9A9A9", size: 0.8 }, // DarkGray
  "Special Craft": { color: "#DAA520", size: 1.0 }, // GoldenRod
  "Starboard Hand Mark": { color: "#4682B4", size: 0.9 }, // SteelBlue
  "Tanker": { color: "#1E90FF", size: 1.5 }, // DodgerBlue
  "Tanker - Hazard A (Major)": { color: "#1E90FF", size: 1.5 }, // DodgerBlue
  "Tanker - Hazard B": { color: "#1E90FF", size: 1.5 }, // DodgerBlue
  "Tanker - Hazard C (Minor)": { color: "#1E90FF", size: 1.5 }, // DodgerBlue
  "Tanker - Hazard D (Recognizable)": { color: "#1E90FF", size: 1.5 }, // DodgerBlue
  "Tanker: Hazardous category A": { color: "#1E90FF", size: 1.5 }, // DodgerBlue
  "Tanker: Hazardous category B": { color: "#1E90FF", size: 1.5 }, // DodgerBlue
  "Tanker: Hazardous category C": { color: "#1E90FF", size: 1.5 }, // DodgerBlue
  "Tanker: Hazardous category D": { color: "#1E90FF", size: 1.5 }, // DodgerBlue
  "Tug": { color: "#556B2F", size: 1.0 }, // DarkOliveGreen
  "Unspecified": { color: "#A9A9A9", size: 0.8 }, // DarkGray
  "WIG": { color: "#FF00FF", size: 1.2 }, // Magenta
  "Wing In Grnd": { color: "#FF00FF", size: 1.2 }, // Magenta
  "none": { color: "#FFFFFF", size: 0.8 } // White
};
