import { createSlice } from "@reduxjs/toolkit";
const LoadingSlice = createSlice({
  name: "loading",
  initialState: {},
  reducers: {
    loading(state, action) {
      const data = action.payload;
      return { ...state, ...data };
    },
  },
});
export const { loading } = LoadingSlice.actions;
export default LoadingSlice.reducer;
