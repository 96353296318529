import "./CreateCustomer.sass";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activityFields } from "../../../../../helper/constants";
import useDebounce from "../../../../../helper/use-debounce";
import Form from "../../../../../ui/Form/Form";
import InputBox from "../../../../../ui/InputBox/InputBox";
import MultiSelect from "../../../../../ui/MultiSelect/MultiSelect";
import PhoneSelector from "../../../../../ui/PhoneSelector/PhoneSelector";
import Select from "../../../../../ui/Select/Select";
import { checkName, create, createCustomer } from "../CustomerManagementSlice";
import { validateEmail } from "../../../../../helper/utils";
import KeyValueForm from "../../../../../ui/KeyValueForm/KeyValueForm";
import { useSettings } from "src/helper/SettingsProvider";

function CreateCustomer({ onOpen, onConfirm }) {
  const [icons, setIcons] = useState({ BusinessName: "default" });
  const [validate, setValidate] = useState(false);
  const [alerts, setAlerts] = useState({});
  const countries = [
    { id: 1, name: "Australia", value: "Australia" },
    { id: 2, name: "United Kingdom", value: "UnitedKingdom" },
  ];
  const customer = useSelector((state) => state.customer);
  const [data, setData] = useState(customer.model);
  const phone = useSelector((state) => state.phone);
  const [BusinessName, setBusinessName] = useState("");
  const [required] = useState([
    "BusinessName",
    "ActivityFields",
    "EmailAddress",
    "Region",
  ]);
  const [details, setDetails] = useState({});
  const onKeyValueChange = (data) => {
    setDetails(data);
  };
  const DebouncedBusinessName = useDebounce(BusinessName, 1000);
  const dispatch = useDispatch();
  const settings = useSettings();

  useEffect(() => {
    if (DebouncedBusinessName) {
      dispatch(checkName(BusinessName));
    }
  }, [DebouncedBusinessName]);

  useEffect(() => {
    onOpen();
  }, [data]);

  useEffect(() => {
    if (phone.value === "") {
      const { [phone.name]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    } else {
      setData({ ...data, [phone.name]: phone.prefix + phone.value });
    }
  }, [phone]);

  useEffect(() => {
    if (customer.name.status === "forbidden") {
      setAlerts({
        ...alerts,
        BusinessName: {
          alert: true,
          type: "error",
          message: customer.name.message.error,
        },
      });
    } else if (customer.name.status === "validation") {
      let validations = {};
      customer.name.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
    setIcons({ ...icons, BusinessName: customer.name.status });
    setValidate(customer.name.status === "success");
  }, [customer.name]);

  useEffect(() => {
    if (customer.create.status === "validation") {
      let validations = {};
      customer.create.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    } else if (customer.create.status === "success") {
      onConfirm();
    }
  }, [customer.create]);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    setAlerts({ ...alerts, [key]: { alert: false } });

    if (key === "EmailAddress") {
      setValidate(validateEmail(value));
    }
    if (key === "ActivityFields") {
      value =
        event.target.value.toString() !== ""
          ? event.target.value.split(",")
          : [];
    }

    if (key === "BusinessName") {
      setIcons({ ...icons, BusinessName: "default" });
      setBusinessName(value);
      setValidate(false);
    }

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(create({ status: "" }));
    dispatch(createCustomer({ ...data, details: Object.entries(details).map((item) => ({ key: item[0], value: item[1] })) }));
  };;

  return (
    <div className="create-customer-form">
      <Form
        submit={{
          title: "Create",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        className="create-customer-container"
        validate={validate}
      >
        <div className="create-customer-section">
          <InputBox
            title="Business Name"
            name="BusinessName"
            isRequired={required.includes("BusinessName")}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.BusinessName}
            value={data.BusinessName}
            index={0}
            background="white"
            width="48%"
            icon={icons.BusinessName}
          />

          <InputBox
            title="Business Number"
            name="BusinessNumber"
            isRequired={required.includes("BusinessNumber")}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.BusinessNumber}
            value={data.BusinessNumber}
            index={1}
            background="white"
            width="48%"
          />
        </div>

        <div className="create-customer-section">
          <InputBox
            title="Email Address"
            name="EmailAddress"
            isRequired={required.includes("EmailAddress")}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.EmailAddress}
            value={data.EmailAddress}
            index={2}
            icon={icons.EmailAddress}
            background="white"
            type="email"
            width="100%"
          />
        </div>

        <MultiSelect
          title="Fields of activity"
          name="ActivityFields"
          items={activityFields}
          isRequired={true}
          onChange={changeHandler}
          status={alerts.ActivityFields}
          value={data.ActivityFields}
          index={2}
          placeholder="Select Your Fields of activity"
        />
        <div className="create-customer-section">
          <Select
            isRequired={required.includes("Region")}
            title="Region"
            name="Region"
            items={countries}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.Region}
            value={data.Region}
            index={5}
            background="white"
            width="48%"
          />
          <PhoneSelector
            title="Phone number"
            name="PhoneNumber"
            status={alerts.PhoneNumber}
            countries={settings.countries}
            currentValue={data.PhoneValue}
            currentPrefix={data.PhonePrefix}
            index={5}
            width="48%"
            onChange={changeHandler}
          />
        </div>

        <InputBox
          isRequired={required.includes("PostalAddress")}
          title="Address"
          name="PostalAddress"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.PostalAddress}
          value={data.PostalAddress}
          index={4}
          background="white"
          width="100%"
        />
        <KeyValueForm
          description="You can add optional details for this customer up to 10 fields."
          onDataChange={onKeyValueChange}
          details={details}
        />
      </Form>
    </div>
  );
}

export default CreateCustomer;
