import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "src/ui/Avatar/Avatar";
import Button from "../../../../../ui/Button/Button";
import GroupMemberItem from "../../../../../ui/GroupMemberItem/GroupMemberItem";
import SearchableList from "../../../../../ui/SearchableList/SearchableList";
import SVGAvatar from "../../../../../ui/SVGAvatar/SVGAvatar";
import {
  optionalSearch,
  search,
} from "../../UserManagement/UserManagementSlice";
import { assignMemberships, getMemberships } from "../GroupManagementSlice";
import "./GroupMembership.sass";

function GroupMembership() {
  const table = useSelector((state) => state.table);
  const searchResult = useSelector((state) => state.user.search.data);
  const membership = useSelector((state) => state.group.membership);
  const [selected, setSelected] = useState([]);
  const [members, setMembers] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (membership.data !== undefined) {
      setSelected([
        ...membership.data.members.map((item) => ({
          id: item.userId,
          title: item.displayName,
          subtitle: item.emailAddress,
          owner: false,
          member: true,
          icon: item.avatar ? (
            <Avatar
              image={item.avatar}
              isCovered={true}
              width="32px"
              height="32px"
            />
          ) : (
            <SVGAvatar />
          ),
        })),
      ]);
    }
  }, [membership]);
  useEffect(() => {
    if (searchResult?.users !== undefined) {
      setData([
        ...searchResult.users.map((item) => ({
          id: item.id,
          title: item.displayName,
          subtitle: item.emailAddress,
          active: false,
        })),
        ...data.filter(
          (x) => !searchResult.users.map((item) => item.id).includes(x.id)
        ),
      ]);
    }
  }, [searchResult?.users]);

  const onSelectChange = (item) => {
    if (!selected.map((x) => x.id).includes(item.id))
      setSelected([
        ...selected.filter((x) => x.id !== item.id),
        { ...item, owner: false, member: true },
      ]);
    else setSelected([...selected.filter((x) => x.id !== item.id)]);

    setData([
      ...data.map((s) =>
        s.id !== item.id ? { ...s } : { ...s, active: !s.active }
      ),
    ]);
  };

  const typeChangeHandler = (item) => {
    switch (item.type) {
      case "Remove":
        setSelected([...selected.filter((x) => x.id !== item.member.id)]);
        // setData([...data.filter((x) => x.id !== item.member.id), item.member]);
        setData([
          ...data.map((s) =>
            s.id !== item.member.id ? { ...s } : { ...s, active: false }
          ),
        ]);
        break;
      case "Member":
        setSelected([
          ...selected.map((s) =>
            s.id !== item.member.id
              ? { ...s }
              : { ...s, owner: false, member: true }
          ),
        ]);
        break;
      case "Owner":
        setSelected([
          ...selected.map((s) =>
            s.id !== item.member.id
              ? { ...s }
              : { ...s, member: false, owner: true }
          ),
        ]);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setMembers(selected.filter((s) => s.member === true).map((z) => z.id));
  }, [selected]);

  useEffect(() => {
    dispatch(search({ status: "" }));
  });

  useEffect(() => {
    dispatch(getMemberships(table.row.id));
  }, [table.row]);

  const submitHandler = () => {
    if (table.row !== undefined)
      dispatch(assignMemberships({ members }, table.row.id));
  };

  return (
    <div className="group-membership-form">
      <SearchableList
        onDataChange={onSelectChange}
        minLength={1}
        tag="user/search"
        feed={data}
        title="Search for user(s)"
        searchCaption="Selected user(s)"
        searchHandler={optionalSearch}
        moreOptions={true}
      />
      <p>
        Members <span>(total {members.length} member selected)</span>
      </p>
      <div className="group-membership-items-container">
        <ul className="group-membership-items">
          {selected
            .filter((s) => s.member === true)
            .map((item, index) => (
              <li className="group-membership-item" key={index}>
                <GroupMemberItem
                  member={item}
                  types={["Member", "Remove"]}
                  onTypeChange={typeChangeHandler}
                  defaultMembership="Member"
                />
              </li>
            ))}
        </ul>
      </div>
      <Button
        value="Confirm"
        color="#2699fb"
        width="30%"
        onClick={submitHandler}
        className="confirm-button"
      />
    </div>
  );
}

export default GroupMembership;
