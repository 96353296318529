import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../../../../../ui/Form/Form";
import InputBox from "../../../../../../ui/InputBox/InputBox";
import KeyValueFormNew from "../../../../../../ui/KeyValueFormNew/KeyValueFormNew";
import { setOperation } from "../../../../AdminOperationManager/OperationStepSlice";
import { updateContainer } from "../../S3BucketSlice";

import "./EditContainer.sass";

function EditContainer() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [validate, setValidate] = useState(true);
  const [alerts, setAlerts] = useState({});
  const storage = useSelector((state) => state.s3Storage);
  const container = useSelector((state) => state.s3Bucket);
  const [currentStorage, setCurrentStorage] = useState({});
  const [currentContainer, setCurrentContainer] = useState({});
  const [tagErrors, setTagErrors] = useState([]);

  useEffect(() => {
    setCurrentStorage(storage.model);
  }, [storage.model]);

  useEffect(() => {
    setData({ Description: container.model.description });
    setCurrentContainer(container.model);
  }, [container.model]);

  useEffect(() => {
    dispatch(setOperation("edit_container"));
  }, []);

  useEffect(() => {
    if (container.update?.status === "success") {
    }
    if (container.update?.status === "validation") {
      setTagErrors(
        container.update.errors
          .filter((error) =>
            error["propertyName"].includes("Properties.Metadata")
          )
          .map((item) => parseInt(item["propertyName"].match(/\d+/)))
      );
    }
  }, [container.update]);

  const onKeyValueChange = (tags) => {
    setData({ ...data, properties: { metaData: { ...tags } } });
    setTagErrors([]);
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };
  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAlerts({ ...alerts, [key]: { alert: false } });
    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(
      updateContainer({ ...data }, currentStorage.id, currentContainer.id)
    );
  };

  return (
    <div className="edit-container-form">
      <Form
        submit={{
          title: "Confirm",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={[]}
        className="edit-container-container"
        validate={validate}
      >
        <InputBox
          isRequired={false}
          title="Bucket Description"
          name="description"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Description}
          value={data.Description}
          index={1}
          background="white"
          width="100%"
        />
      </Form>
    </div>
  );
}

export default EditContainer;
