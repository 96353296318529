import { useEffect, useState } from "react";
import { usePrivileges } from "src/helper/AccountStateProvider";
import Application from "../../../../../ui/Application/Application";
import TabBar from "../../../../../ui/TabBar/TabBar";
import "./DeviceHome.sass";
import emptyTab from "../../../../../assets/img/device-empty.svg";
import onboardSdk from "../../../../../assets/img/onboard-app.svg";
import { useTheme } from "src/helper/ThemeProvider";
import { deviceApps } from "src/helper/constants";
import RecentDevices from "../../../Components/RecentDevices/RecentDevices";
import { Heading } from "@radix-ui/themes";

function DeviceHome() {
  const { privileges } = usePrivileges();
  const { currentContext } = useTheme();

  const [permissions, setPermissions] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [apps, setApps] = useState([]);

  const activeTabs = [
    {
      id: "recent_devices",
      title: "Devices",
      content: <RecentDevices />
    },
    { id: "recent_components", title: "Component" },
  ];

  useEffect(() => {
    if (privileges.applications !== undefined) {
      if (
        privileges.applications.filter((item) => item.id === "device")[0] !==
        undefined
      )
        setPermissions([
          ...privileges.applications.filter((item) => item.id === "device")[0]
            ?.privileges,
        ]);

      setApps([
        ...deviceApps.filter((app) =>
          privileges.applications.map((a) => a.id).includes(app.id) || app.id === 'camera-observer' || app.id === 'dock'
        ),
      ]);
    }
  }, [privileges.applications]);

  useEffect(() => {
    setTabs(
      [...activeTabs.filter((tab) => permissions.includes(tab.id))].map(
        (tab, index) => ({ ...tab, index: index, active: index === 0 })
      )
    );
  }, [permissions]);

  return (
    <div className="device-home-container">
      <div className="device-home-header">
        <Heading
          className="device-home-header-title"
          style={{ borderBottom: "2px solid" + currentContext.theme.color }}
          size="3"
        >
          Device Application
        </Heading>
        <ul className="device-home-header-content">
          {apps?.map((app, index) => (
            <li key={index}>
              <Application
                key={index}
                name={app.name}
                link={app.link}
                image={app.image}
                color={app.color}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="device-home-body">
        {tabs.length > 0 && (
          <TabBar
            title="Quick Access"
            tabs={tabs}
            theme={currentContext.theme.color}
            empty={emptyTab}
            separator={false}
          />
        )}
      </div>
    </div>
  );
}

export default DeviceHome;
