import "./SearchableList.sass";
import { useEffect, useState } from "react";
import { toColor } from "../../helper/utils";
import { FiMoreVertical, FiSearch } from "react-icons/fi";
import useDebounce from "../../helper/use-debounce";
import { useDispatch, useSelector } from "react-redux";
import EntityName from "../EntityName/EntityName";
import { SearchLoading } from "../Icons/Icons";
import { useTheme } from "../../helper/ThemeProvider";
import CircleAvatar from "../CircleAvatar/CircleAvatar";
import CheckBox from "../CheckBox/CheckBox";
import OutSideDetector from "../OutsideDetector/OutSideDetector";
import classNames from "classnames";

function SearchableList({
  title,
  minLength,
  tag,
  feed,
  searchHandler,
  onDataChange,
  moreOptions,
}) {
  const { currentContext } = useTheme();
  const [ready, setReady] = useState(false);
  const [searchIn, setSearchIn] = useState([]);
  const [toggleMore, setToggleMore] = useState(false);
  const loading = useSelector((state) => state.loading);
  const [SearchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(SearchTerm, 500);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (debouncedSearchTerm && SearchTerm.length > minLength) {
      if (searchIn.length === 0) dispatch(searchHandler(SearchTerm));
      else dispatch(searchHandler(SearchTerm, searchIn[0]));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setReady(feed.length > 0);
    setData([
      ...feed.map((item) => {
        return {
          ...item,
          icon: (
            <CircleAvatar
              name={item.title.substring(0, 2).toUpperCase()}
              fontSize=".9em"
              size="2.25"
              color={`#${toColor(item.title)}`}
            />
          ),
        };
      }),
    ]);

  }, [feed]);

  const addSelected = (item) => {
    onDataChange({ ...item, action: "Remove" });
  };

  const changeHandler = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchInHandler = (event) => {
    console.log(event.target.name, event.target.checked);
    if (event.target.checked) setSearchIn([...searchIn, event.target.name]);
    else setSearchIn([...searchIn.filter((s) => s !== event.target.name)]);
  };

  return (
    <div className="searchable-list">
      <div className="searchable-list-container">
        <div className="searchable-list-header">
          <p className="searchable-list-title">{title}</p>
          <div className="searchable-list-search">
            <div className="searchable-list-search-input">
              <input
                onInput={changeHandler}
                placeholder="Search..."
                type="text"
              />
              {moreOptions ? (
                <FiMoreVertical
                  size="1.5em"
                  color="#8b9695"
                  cursor="pointer"
                  onClick={() => {
                    setToggleMore(!toggleMore);
                  }}
                />
              ) : (
                <span
                  style={{
                    height: "2em",
                    marginRight: ".5em",
                    borderRight: "1.5px solid #8b9695",
                  }}
                ></span>
              )}

              <FiSearch
                size="1.5em"
                color="#8b9695"
                cursor="pointer"
                onClick={() => {
                  if (SearchTerm.length > minLength)
                    if (searchIn.length === 0)
                      dispatch(searchHandler(SearchTerm));
                    else dispatch(searchHandler(SearchTerm, searchIn[0]));
                }}
              />

              {toggleMore && (
                <OutSideDetector
                  className="searchable-list-search-more-options"
                  detect={() => {
                    setToggleMore(false);
                  }}
                >
                  <small>Search in</small>
                  <CheckBox
                    info="Guests"
                    name="guests"
                    text="Guests"
                    onChecked={searchInHandler}
                    checked={searchIn.find((x) => x === "guests") !== undefined}
                  />
                </OutSideDetector>
              )}
            </div>
            {searchIn.length > 0 && (
              <ul className="searchable-list-search-options">
                Search in :
                {searchIn.map((item, index) => (
                  <li
                    style={{
                      background: currentContext.theme.color,
                      color: "white",
                    }}
                    className="searchable-list-search-option"
                    key={index}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}

            <div className="searchable-list-result-container">
              {loading.status === true && loading.name === tag && (
                <SearchLoading color={currentContext.theme.color} />
              )}
              {ready && (
                <ul>
                  {data.map((item, index) => (
                    <li
                      className={classNames("search-result-item", {"active": item.active})}
                      key={index}
                      onClick={() => {
                        addSelected(item);
                      }}
                    >
                      <EntityName item={item} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchableList;
