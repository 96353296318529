import { useState } from "react";
import { useTheme } from "../../helper/ThemeProvider";
import "./EditableInputTextButton.sass";

function EditableInputTextButton({ input, submit, onSubmit, forceToFill }) {
  const [value, setValue] = useState("");
  const onValueChange = (event) => {
    setValue(event.target.value);
  };
  const onConfirm = () => {
    onSubmit(value);
  };
  const { currentContext } = useTheme();
  return (
    <div className="editable-input-text-button">
      <input
        type="text"
        placeholder={input.placeholder}
        value={value && value}
        onChange={onValueChange}
      />
      <button
        style={{ background: currentContext.theme.color }}
        onClick={onConfirm}
        disabled={value === "" && forceToFill}
      >
        {submit.title}
      </button>
    </div>
  );
}

EditableInputTextButton.defaultProps = {
  onSubmit: (value) => {},
};

export default EditableInputTextButton;
