import useDeviceConnection from 'src/services/device/common/useDeviceConnection';
import useCreateMission, { prepareMissionWaypoints } from './useCreateMission';
import { useIssueMission } from './useIssueMission';
import useMissionInfo from './useMissionInfo'
import { MissionPendingRequestStatus, MissionPendingRequestType, MissionStatus, onboardWaypointAction } from './missionConstants';
import { dockConnectionManager, frontendConnectionManager, onboardConnectionManager } from 'src/helper/HubConnectionManager';
import { getCoordinatesDistanceAndTime } from 'src/helper/waypointMarkupUtils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { deviceTypes } from 'src/helper/constants';
import useCloudLiveFlight from './useCloudLiveFlight';
import useDialog from 'src/helper/useDialog';
import { removeMission, setCreateMissionProgress, setMissionInfo, setMissionPendingRequest } from '../MissionServiceSlice';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import { createKmz, terminateRemoteCloudMission } from './missionUtils';
import { useDispatch, useSelector } from 'react-redux';
import { generateId } from 'src/helper/utils';
import { useFrontendHub } from 'src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider';
import { djiCloudCustomMethod } from 'src/services/common/constants';
import rfdc from 'rfdc';
import { getDeviceConnectionGroups, getDeviceInfo, getDeviceTelemetries } from 'src/services/device/common/deviceUtils';
import { setLiveFlight } from 'src/services/flight/FlightServiceSlice';
import useEnvSettings from 'src/helper/useEnvSettings';
import useMissionProgress from './useMissionProgress';

export default function useMissionController(queryFunction, parentDeviceId) {
  const dialog = useDialog();
  const dispatch = useDispatch();
  const clone = rfdc();
  const missionDetails = useMissionInfo(queryFunction);
  const deviceId = missionDetails?.deviceId || parentDeviceId;
  const { createMission, createMissionResult, loading: createMissionLoading } = useCreateMission();
  const { sendIssueMission, issueResult } = useIssueMission();
  const deviceInfo = useDeviceInfo(deviceId);
  const liveFlight = useCloudLiveFlight();
  const connection = useDeviceConnection(deviceId);
  const { hub: frontendHub, group: frontendGroup } = useFrontendHub();
  const frontendOid = useSelector((state) => state.api.state?.oid);
  const onboardHandlerIds = useRef([]);
  const dockHandlerIds = useRef([]);
  const progress = useMissionProgress(missionDetails?.id);

  useEffect(() => {
    return () => {
      onboardConnectionManager.unsubscribeGroupMessages(onboardHandlerIds.current);
      // dockConnectionManager.unsubscribeGroupMessages(dockHandlerIds.current);

      const handlersStorageId = 'mission_controller_dockHandlerIds_' + deviceId;
      localStorage.setItem(handlersStorageId, JSON.stringify(dockHandlerIds.current));
    }
  }, []);

  const createMissionHandler = (...params) => {
    const deviceInfo = getDeviceInfo(deviceId);
    const deviceTelemetries = getDeviceTelemetries(deviceId);

    // check device battery percent
    if(
      deviceInfo.type === deviceTypes.DOCK && 
      deviceTelemetries?.drone_charge_state?.capacity_percent < 35
    ) {
      dialog.fire({
        title: <b>Low Battery</b>,
        text: "Drone battery is low, please charge the drone before starting the mission.",
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });

      return;
    }

    // check dock mode code
    if(
      deviceInfo.type === deviceTypes.DOCK && 
      deviceTelemetries?.mode_code !== 0
    ) {
      dialog.fire({
        title: <b>Drone Takeoff</b>,
        text: "The dock is not ready to start takeoff at the moment or malfunctioned.",
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });

      return;
    }
    
    createMission(...params);
  }

  const flyToPoint = (reqInfo) => {
    if(!reqInfo || !reqInfo.missionId || !missionDetails) return;

    const waypoints = prepareMissionWaypoints(missionDetails?.deviceId, reqInfo?.waypoints, reqInfo?.speed, reqInfo?.altitude);

    const {
      distance,
      time
    } = getCoordinatesDistanceAndTime(waypoints.map(item => ({
      lat: item.latitude,
      long: item.longitude
    })), missionDetails?.speed);

    let missionData = {
      ...missionDetails,
      status: MissionStatus.PREPARING,
      statusDescription: "Fly to point request sent...",
      waypoints,
      duration: Math.round(time),
      distance: Math.round(distance),
      speed: reqInfo.speed,
    }

    if(missionDetails.deviceType === deviceTypes.DRONE) {
      const kmzData = {
        droneEnumValue: missionDetails?.deviceDetails?.droneEnumValue,
        droneSubEnumValue: missionDetails?.deviceDetails?.droneSubEnumValue,
        payloadEnumValue: missionDetails?.deviceDetails?.payloadEnumValue,
        payloadSubEnumValue: missionDetails?.deviceDetails?.payloadSubEnumValue,
        speed: reqInfo.speed,
        altitude: reqInfo.altitude,
        waypoints,
      };

      createKmz(kmzData).then((content) => {
        try {
          missionData.flightDetails = clone(missionData.flightDetails);

          if(missionData.flightDetails)
            missionData.flightDetails.kmz = content;
  
          sendIssueMission(missionDetails.deviceId, missionData);
        } catch (error) {
          console.error('❌ Error creating kmz', error, {missionData});
        }
      });
    }
    else if(missionDetails.deviceType === deviceTypes.DOCK) {
      dispatch(setMissionPendingRequest({
        missionId: missionDetails?.id,
        requestType: MissionPendingRequestType.CLOUD_FLY_TO_POINT,
        requestStatus: MissionPendingRequestStatus.PENDING
      }));

      liveFlight.sendFlyToPoint(missionDetails?.deviceId, {
        max_speed: reqInfo.speed,
        duration: Math.round(time),
        distance: Math.round(distance),
        points: [{
          latitude: reqInfo.latitude,
          longitude: reqInfo.longitude,
          height: reqInfo.altitude,
        }],
      });
    }

    const finalMissionData = {
      ...missionData,
      waypoints: [
        ...missionDetails.waypoints,
        ...waypoints.slice(1),
      ]
    };

    dispatch(setMissionInfo(finalMissionData));
    frontendHub.sendToGroup(frontendOid, frontendGroup?.send?.broadcast, {
      bid: generateId(),
      method: 'mission_update',
      data: finalMissionData,
    });
  }

  const finishMission = () => {
    if(missionDetails.deviceType === deviceTypes.DRONE) {
      sendOnboardMissionAction(onboardWaypointAction.MISSION_TERMINATE);
    }

    if(missionDetails.deviceType === deviceTypes.DOCK) {
      terminateRemoteCloudMission(deviceId);
    }

    frontendHub.sendToGroup(frontendOid, frontendGroup?.send?.broadcast, {
      bid: generateId(),
      method: 'mission_finish',
      data: { 
        missionId: missionDetails.id,
        flightId: missionDetails.flightId,
      },
    });

    dispatch(removeMission({ id: missionDetails.id }));
  }

  const sendOnboardMissionAction = (action) => {
    onboardConnectionManager?.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
      waypoint: {
        actionId: action,
        missionId: missionDetails.id,
      },
    });
  }

  const stopMission = () => {
    if(missionDetails?.deviceType === deviceTypes.DOCK)
      liveFlight.sendStopFlyto(missionDetails?.deviceId);
    else {
      sendOnboardMissionAction(onboardWaypointAction.STOP);
    }
  }

  const startMission = () => {
    sendOnboardMissionAction(onboardWaypointAction.START);
  }

  const pauseMission = () => {
    sendOnboardMissionAction(onboardWaypointAction.PAUSE);
  }

  const resumeMission = () => {
    dispatch(setMissionPendingRequest({
      missionId: missionDetails?.id,
      requestType: MissionPendingRequestType.ONBOARD_RESUME_MISSION,
      requestStatus: MissionPendingRequestStatus.PENDING,
    }));
        
    sendOnboardMissionAction(onboardWaypointAction.RESUME);
  }

  const rth = () => {
    if(deviceInfo?.type === deviceTypes.DOCK)
      liveFlight.sendReturnHome(missionDetails?.deviceId);
    else
      onboardConnectionManager.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
        flightControl: {
          actionId: 9,
          altitude: 80,
        }
      });
  }

  const cancelRth = () => {
    if(deviceInfo?.type === deviceTypes.DOCK)
      liveFlight.sendCancelReturnHome(deviceId);
    else
      onboardConnectionManager?.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
        flightControl: {
          actionId: 10,
        }
      });
  }

  const cancelLanding = () => {
    onboardConnectionManager?.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
      flightControl: {
        actionId: 7,
      }
    });
  }

  const startDrc = () => {
    liveFlight.sendStartDrcChannel(missionDetails?.deviceId, {});
  }

  const stopDrc = () => {
    liveFlight.sendStopDrcChannel(missionDetails?.deviceId, {});
  }

  return missionDetails?.deviceType === deviceTypes.DRONE ? {
    details: missionDetails,
    progress,
    sendIssueMission,
    issueMissionResult: issueResult,
    createMission: createMissionHandler,
    createMissionResult,
    createMissionLoading,
    stopMission,
    pauseMission,
    resumeMission,
    startMission,
    flyToPoint,
    finishMission,
    rth,
    cancelRth,
    cancelLanding,
  } : {
    details: missionDetails,
    progress,
    sendIssueMission,
    issueMissionResult: issueResult,
    createMission: createMissionHandler,
    createMissionResult,
    createMissionLoading,
    stopMission,
    flyToPoint,
    rth,
    cancelRth,
    liveFlight,
    startDrc: startDrc,
    stopDrc: stopDrc,
  };
}
