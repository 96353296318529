import './CameraIsoSelector.sass'
import { useState } from 'react';
import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { PayloadIndexes } from 'src/helper/HubConnection';
import VerticalValueSlider from '../../VerticalValueSlider/VerticalValueSlider';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';

function CameraIsoSelector() {
  const [iso, setIso] = useState(0);
  const { hub, groups, deviceId } = useOnboardHub();
  const flight = useSelector((state) => state.flight);
  const { activeCameraPayload } = useDeviceInfo();

  useEffect(() => {
    if (flight.camera?.iso) {
      setIso(flight.camera?.iso);
    }
  }, [flight.camera]);

  const onIsoChange = (value, index) => {
    setIso(value);
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      camSetting: {
        payloadIndex: activeCameraPayload?.payloadIndex,
        iso: value,
      }
    });
  };

  return (
    <div className="camera-iso-selector">
      <VerticalValueSlider
        values={activeCameraPayload?.settings?.iso?.values || []}
        currValue={iso}
        onValueChange={onIsoChange}
      />
    </div>
  )
}

export default CameraIsoSelector