import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setContext } from "../../../../redux/ducks/appContextSlice";
import TabBar from "../../../../ui/TabBar/TabBar";
import { Heading } from "@radix-ui/themes";
import "./ApplicationAreaContainer.sass";

function ApplicationAreaContainer({
  title,
  tabs,
  tabsTitle,
  emptyTab,
  content,
  color,
  name,
  highlight
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setContext({ theme: { color, name, highlight } }));
  });
  return (
    <div className="application-area-container">
      <div className="application-area-header">
        <Heading
          size="4"
          className="application-area-header-title"
          style={{ borderBottom: "2px solid" + color }}
        >
          {title}
        </Heading>
        <div className="application-area-header-content">{content}</div>
      </div>
      <div className="application-area-body">
        {tabs.length > 0 && (
          <TabBar
            title={tabsTitle}
            tabs={tabs}
            theme={color}
            empty={emptyTab}
            separator={false}
          />
        )}
      </div>
    </div>
  );
}

export default ApplicationAreaContainer;
