import { useState } from "react";
import WorkspaceSelector from "../../ui/WorkspaceSelector/WorkspaceSelector";
import AppMenu from "./AppMenu/AppMenu";
import "./AppNavbar.sass";
import AppNavigation from "./AppNavigation/AppNavigation";
import TabletSize from "../../ui/TabletSize/TabletSize";

function AppNavbar() {
  
  const [open, setOpen] = useState(false);
  const toggleOn = (event) => {
    setOpen(true);
  };
  const toggleOff = (event) => {
    setOpen(false);
  };
  return (
    <div className="app-navbar">
      {/* <TabletSize>
        <WorkspaceSelector />
      </TabletSize> */}

      {open && <AppNavigation isOpen={open} toggle={toggleOff} />}
      <AppMenu onClick={toggleOn} />
    </div>
  );
}

export default AppNavbar;
