import './WaypointCommandPanel.sass';
import CollapsablePanel from '../CollapsablePanel/CollapsablePanel';
import { RiFoggyLine, RiRainyLine } from 'react-icons/ri';
import { MdHeight, MdOutlineLocationOn, MdOutlineSatelliteAlt, MdOutlineUploadFile } from 'react-icons/md';
import { TbCameraQuestion, TbNetwork, TbTemperature } from 'react-icons/tb';
import { FiBatteryCharging, FiWind } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'src/helper/AccountStateProvider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFrontendHub } from 'src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider';
import DockHubProvider, { useDockHub } from 'src/components/Dashboard/Components/DockHubProvider/DockHubProvider';
import VideoStreamView from 'src/ui/Drone/VideoStreamView/VideoStreamView';
import useOnboardStreamingInfo from '../common/useOnboardStreamingInfo';
import { useVideoStream } from 'src/helper/useVideoStream';
import { DeviceOnlineStatus } from 'src/helper/useOnboardDeviceList';
import { IoCloudOfflineOutline } from 'react-icons/io5';
import DeviceMap from 'src/components/DeviceMap/DeviceMap';
import { dockConnectionManager } from 'src/helper/HubConnectionManager';
import { StageElement } from '../MissionTracking';
import Form, { FormDirection } from 'src/hci/common/Form/Form';
import TextInput from 'src/hci/common/TextInput/TextInput';
import FormItem from 'src/hci/common/FormItem/FormItem';
import Button from 'src/hci/common/Button/Button';

function WaypointCommandPanel({ deviceId }) {
  const { account } = useAccount();
  const formRef = useRef();
  const dispatch = useDispatch();
  const serverMessageHandlerIds = useRef([]);
  const groupMessageHandlerIds = useRef([]);
  const deviceInfo = useSelector((state) => state.deviceService.devices?.find(item => item.id === deviceId));
  const mapClickCoordinates = useSelector(state => state.deviceMap.lastClickCoordinates);
  const mapClickCoordinatesString = useMemo(() => {
    return mapClickCoordinates ? `${mapClickCoordinates.lat},${mapClickCoordinates.lng}` : ''
  }, [mapClickCoordinates]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if(!selectedDevice)
      return;

    const formData = new FormData(e.target);
    const dataObj = Object.fromEntries(formData.entries());

    // createWaypointMission({
    //   altitude: Number(dataObj.altitute || 30),
    //   speed: Number(dataObj.speed || 10),
    //   latitude: parseFloat(dataObj.lat || 0),
    //   longitude: parseFloat(dataObj.long || 0),
    // });
  }

  return (
    <div className="waypoint-command-panel">
      <CollapsablePanel title="DFR Wapoint">
        <div className="waypoint-form">
          <Form direction={FormDirection.ROW} ref={formRef} onSubmit={handleFormSubmit}>
            <FormItem label="LAT" className="half-width">
              <TextInput name="lat" postfix='(x.y)' defaultValue={mapClickCoordinates?.lat} />
            </FormItem>
            <FormItem label="LONG" className="half-width">
              <TextInput name="long" postfix='(x.y)' defaultValue={mapClickCoordinates?.lng} />
            </FormItem>
            <FormItem label="ALT" className="half-width">
              <TextInput name="altitute" postfix='m' />
            </FormItem>
            <FormItem label="SPEED" className="half-width">
              <TextInput name="speed" postfix='m/s' />
            </FormItem>
            <Button onClick={() => {
              formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
            }}>Go to location</Button>
          </Form>
        </div>
      </CollapsablePanel>
    </div>
  )
}

export default WaypointCommandPanel