import React from 'react'
import './PublicLiveStream.sass'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PublicLiveStreamHeader from './PublicLiveStreamHeader/PublicLiveStreamHeader';
import PublicLiveStreamVideo from './PublicLiveStreamVideo/PublicLiveStreamVideo';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import useDialog from 'src/helper/useDialog';
import Loading from 'src/ui/Loading/Loading';

const STREAM_VIEWER_ID_LOCAL_STORAGE_PREFIX = 'live-stream-viewer-id-';

function PublicLiveStream() {
  const [viewerToken, setViewerToken] = useState();
  const [viewerId, setViewerId] = useState();
  const [viewerInfo, setViewerInfo] = useState();
  const [isLoading, setIsLoading] = useState();
  const apiPrefix = useSelector(state => state.settings.apiPrefix);
  const sharedApiPrefix = useMemo(() => apiPrefix?.replace('api/', 'api/shared/'), [apiPrefix]);
  const routeMatch = useRouteMatch("/live-stream/:deviceId");
  const deviceId = useMemo(() => routeMatch.params?.deviceId, [routeMatch]);
  const dialog = useDialog();

  const handleError = (data) => {
    const errorMessage = data?.error || data?.message || (typeof data === 'string' ? data : 'There was an error when preparing the stream');

    dialog.fire({
      title: <b>Live Stream</b>,
      text: errorMessage,
      icon: 'error',
    });
  }

  const getPrevViewerId = () => {
    const prevInfo = JSON.parse(localStorage.getItem(STREAM_VIEWER_ID_LOCAL_STORAGE_PREFIX + deviceId));

    if (prevInfo) {
      const prevTimestamp = new Date(prevInfo.expirationTime).getTime();

      if (prevTimestamp > (new Date()).getTime()) {
        setViewerId(prevInfo.viewerId);
        return prevInfo.viewerId;
      }
    }

    return false;
  }

  const getViewerId = async (token) => {
    const prevId = getPrevViewerId();
    if (prevId) return prevId;

    // get new viewer ID if no valid one found in local storage
    try {
      const result = await axios.request({
        baseURL: sharedApiPrefix,
        url: 'devices/app/onboard/join/session',
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
      });

      const id = result?.data?.viewerId;
      if (!id) return false;

      localStorage.setItem(STREAM_VIEWER_ID_LOCAL_STORAGE_PREFIX + deviceId, JSON.stringify(result.data));
      setViewerId(id);
      return id;
    } catch (err) {
      handleError(err.response.data || err);
    }
  };

  const getViewerInfo = async (id, token) => {
    try {
      const result = await axios.request({
        baseURL: sharedApiPrefix,
        url: 'devices/app/onboard/live/video-streaming',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
        params: { viewerId: id },
      });

      if (!result) return false;
      setViewerInfo(result.data);
      return result.data;
    } catch (err) {
      localStorage.removeItem(STREAM_VIEWER_ID_LOCAL_STORAGE_PREFIX + deviceId);
      setViewerId(undefined);
      handleError(err.response.data || err);
    }
  }

  const leaveStream = async (id, token) => {
    try {
      const result = await axios.request({
        baseURL: sharedApiPrefix,
        url: 'devices/app/onboard/leave/session',
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        params: { viewerId: id },
      });

      return result;
    } catch (err) {
      handleError(err.response.data || err);
    }
  }

  const joinStream = async () => {
    if (!viewerToken) return;
    setIsLoading(true);

    const id = await getViewerId(viewerToken);
    if (!id) {
      setIsLoading(false);
      return;
    }

    const info = await getViewerInfo(id, viewerToken);
    if (!info) {
      setIsLoading(false);
      return;
    }

    setViewerInfo(info);
    setIsLoading(false);
  }

  const handleLeaveStreamButtonClick = async () => {
    if (!viewerToken || !viewerId || !deviceId) return;

    const result = await leaveStream(viewerId, viewerToken);
    if (!result) return;

    localStorage.removeItem(STREAM_VIEWER_ID_LOCAL_STORAGE_PREFIX + deviceId);
    setViewerId(undefined);
    setViewerInfo(undefined);
  }

  useEffect(() => {
    if (!deviceId) return;

    const params = new URL(window.location).searchParams;
    const token = params.get('token');

    setViewerToken(token);
    // if (token && apiPrefix && getPrevViewerId()) setTimeout(() => joinStream(), 1000);
  }, [deviceId, apiPrefix])

  return (
    <div className="public-live-stream">
      <PublicLiveStreamHeader joined={viewerId} onJoinClick={joinStream} onLeaveClick={handleLeaveStreamButtonClick} />
      {viewerInfo && (
        <PublicLiveStreamVideo streamConfig={({
          ...viewerInfo.fpvChannel ? { fpv: viewerInfo.fpvChannel } : {},
          ...viewerInfo.mainChannel ? { main: viewerInfo.mainChannel } : {},
        })} />
      )}
      {/* {isLoading && <Loading dark={true} />} */}
    </div>
  )
}

export default PublicLiveStream