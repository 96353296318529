import "./FlightController.sass";
import FlightCameraManager from "./FlightCameraManager/FlightCameraManager";
import FlightControlBar from "./FlightControlBar/FlightControlBar";
import FlightStatusBar from "./FlightStatusBar/FlightStatusBar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  altitude,
  batteries,
  camera,
  cameraStats,
  compass,
  connected,
  devicePayloads,
  droneMode,
  enlargeMap,
  fullscreen,
  gimbal,
  gps,
  heading,
  hmsError,
  mapLimitedView,
  modemInfo,
  notification,
  obstacleSensors,
  resetFlightInfo,
  velocity,
  videoStreaming,
} from "./FlightControllerSlice";
import { useAccount } from "../../../../helper/AccountStateProvider";
import { CameraSourceProvider } from "../../../../helper/CameraSourceProvider";
import { useDeviceId } from "../../../../helper/SerialNumberProvider";
import { onboardConnectionManager } from "src/helper/HubConnectionManager";

import OnboardHubProvider from "src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider";
import { HubEventName, HubName } from "src/helper/HubConnection";
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import { PayloadSourceProvider } from "src/helper/PayloadSourceProvider";
import FlightActionBar from "./FlightActionBar/FlightActionBar";
import { FLIGHT_MAP_FULLSCREEN_URL_PARAM } from "src/ui/Drone/Map/MapToolbar/MapToolbar";
import CameraViewSelector from "src/ui/Drone/CameraViewSelector/CameraViewSelector";
import CameraSourceToggle from "src/ui/Drone/CameraSourceToggle/CameraSourceToggle";
import PrimaryFlightDisplay from "src/ui/Drone/PrimaryFlightDisplay/PrimaryFlightDisplay";
import MainVideo from "src/ui/Drone/MainVideo/MainVideo";
import VideoSwitchView from "src/ui/Drone/VideoSwitchView/VideoSwitchView";
import { useVideoStream } from "src/helper/useVideoStream";
import LaserRangeDetails from "src/ui/Drone/LaserRangeDetails/LaserRangeDetails";
import LaserRangeToggle from "src/ui/Drone/LaserRangeToggle/LaserRangeToggle";
import { DeviceInfoProvider } from "src/helper/DeviceInfoProvider";
import WaypointController, { WaypointLocalState } from "src/ui/Drone/WaypointController/WaypointController";
import ShareStream from "src/ui/Drone/ShareStream/ShareStream";
import { getDeviceDetails } from "../DeviceApp/OnboardDeviceApp/OnboardDeviceAppSlice";
import classNames from "classnames";

function FlightController({ integratedMode = false }) {
  const dispatch = useDispatch();
  const { account } = useAccount();
  const { deviceId } = useDeviceId();
  const serverMessageHandlerIds = useRef([]);
  const groupMessageHandlerIds = useRef([]);
  const [wsConnected, setWsConnected] = useState(false);
  const { hub: frontendHub, checkTelemetries } = useFrontendHub();
  const droneDisplayMode = useSelector((state) => state.flight.droneMode.displayMode);
  const mapLimitedViewEnabled = useSelector((state) => state.flight.mapLimitedView);
  const waypointPanelEnabled = useSelector((state) => state.flight.waypoint.showForm || state.flight.waypoint.state !== WaypointLocalState.IDLE || droneDisplayMode === 14);
  const fullscreen = useSelector((state) => state.flight.fullscreen);

  const videoStreamConfig = useSelector((state) => state.flight?.videoStreaming);
  const { mediaStream: fpvMediaStream } = useVideoStream(videoStreamConfig?.['fpvViewerConfig']);
  const { mediaStream: mainMediaStream } = useVideoStream(videoStreamConfig?.['mainViewerConfig']);

  const permissionGroups = useSelector(
    (state) => state.websocket.permissionGroups[deviceId]
  );

  const serverMessageHandlers = [
    {
      identity: deviceId,
      name: HubEventName.CONNECTIVITY_STATUS,
      platform: HubName.ONBOARD,
      handler: (data) => {
        const message = JSON.parse(data?.message);
        if (message?.videoStreaming) {
          dispatch(videoStreaming(message?.videoStreaming));
          dispatch(connected(true));
          dispatch(
            notification({
              message: "Device Connected",
              status: "normal",
            })
          );
          setWsConnected(true);
        }
      },
    },
    {
      identity: deviceId,
      name: HubEventName.CONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        dispatch(
          notification({
            message: "Checking Device Connectivity...",
            status: "warning",
          })
        );
      },
    },
    {
      identity: deviceId,
      name: HubEventName.DISCONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        setWsConnected(false);
        dispatch(connected(false));
        dispatch(
          notification({
            message: "Device Disconnected!",
            status: "error",
          })
        );
        dispatch(resetFlightInfo());
      },
    },
  ];

  useEffect(() => {
    frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);

    serverMessageHandlerIds.current = frontendHub.subscribeServerMessages(
      serverMessageHandlers,
      "flightController"
    );

    if (deviceId)
      dispatch(getDeviceDetails(deviceId));

    return () => {
      frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);

      onboardConnectionManager.unsubscribeGroupMessages(
        groupMessageHandlerIds.current
      );
    };
  }, [deviceId]);

  const flightDataActions = [
    ['camSetting', data => dispatch(camera(data))],
    ['batteryPercent', data => dispatch(batteries(data))],
    ['facing', data => dispatch(compass(data))],
    ['heading', data => dispatch(heading(data))],
    ['gps', data => dispatch(gps(data))],
    ['altitudeInfo', data => dispatch(altitude(data))],
    ['velocity', data => dispatch(velocity(data))],
    ['cameraStat', data => dispatch(cameraStats(data))],
    ['flightControl', data => dispatch(droneMode({ displayMode: data?.displayMode, flightStatus: data?.flightStatus }))],
    ['gimbalAngles', data => dispatch(gimbal(data))],
    ['payloadInfo', data => dispatch(devicePayloads(data))],
    ['hms', data => dispatch(hmsError(data))],
    ['modemInfo', data => dispatch(modemInfo(data))],
  ];

  const subscribeGroups = () => {
    const groupMessageHandlers = [
      {
        identity: deviceId,
        name: [
          permissionGroups?.join?.lowFreqChannel,
          permissionGroups?.join?.highFreqChannel,
        ],
        handler: (data) => {
          flightDataActions.forEach(([key, handler]) => {
            if (key in data) handler(data[key]);
          });
        },
      },
    ];

    onboardConnectionManager.unsubscribeGroupMessages(
      groupMessageHandlerIds.current
    );

    groupMessageHandlerIds.current =
      onboardConnectionManager.subscribeGroupMessages(
        groupMessageHandlers,
        "flightController"
      );
  };

  useEffect(() => {
    const params = new URL(window.location).searchParams;

    if (params.get(FLIGHT_MAP_FULLSCREEN_URL_PARAM)) {
      dispatch(fullscreen(true));
      dispatch(enlargeMap(true));
      dispatch(mapLimitedView(true));
    }

    // setTimeout(() => dispatch(gps({
    //   lat: 52.134353,
    //   long: 4.7522473
    // })), 4000)

    dispatch(resetFlightInfo());
  }, []);

  return (
    <OnboardHubProvider
      candidateGroups={['highFreqChannel', 'lowFreqChannel', ...(!integratedMode ? ['onDemandChannel'] : [])]}
      onRequestForConnection={() => {
        dispatch(
          notification({
            message: "Checking Device Connectivity...",
            status: "warning",
          })
        );
      }}
      onJoinComplete={() => {
        dispatch(
          notification({
            message: "Device Connected",
            status: "normal",
          })
        );
        subscribeGroups();
        checkTelemetries(account.user.localAccountId, deviceId);
      }}
      autoGroupLeave={!integratedMode}
    >
      <DeviceInfoProvider>
        <CameraSourceProvider>
          <div className={classNames("flight-controller", { 'fullscreen': integratedMode && fullscreen })}>
            <FlightStatusBar showHms={false} />
            <div className="flight-controller-divider">
              <div className="flight-controller-main">
                <div className="flight-controller-stage-container">
                  {(wsConnected) && (
                    <>
                      <div className="flight-controller-stage">
                        <PayloadSourceProvider>
                          <FlightActionBar />
                        </PayloadSourceProvider>
                        <FlightCameraManager />
                      </div>
                      <LaserRangeToggle />
                      {/* <ShareStream /> */}
                      <CameraViewSelector />
                      <LaserRangeDetails />
                      <FlightControlBar hideMap={integratedMode} />
                      <PrimaryFlightDisplay />
                      {!mapLimitedViewEnabled && (
                        <VideoSwitchView mainStream={mainMediaStream} fpvStream={fpvMediaStream} />
                      )}
                    </>
                  )}
                </div>
                {(wsConnected) && !mapLimitedViewEnabled && (
                  <MainVideo mainStream={mainMediaStream} fpvStream={fpvMediaStream} />
                )}
              </div>
              {!integratedMode && (
                <div className={classNames("flight-controller-sidebar", {'collapsed': !waypointPanelEnabled})}>
                  <WaypointController />
                </div>
              )}
            </div>
          </div>
        </CameraSourceProvider>
      </DeviceInfoProvider>
    </OnboardHubProvider>
  );
}

export default FlightController;
