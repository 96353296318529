import PropTypes from "prop-types";
import "./AdjustmentShortcutController.sass";
import AdjustmentOverlay from "../AdjustmentOverlay/AdjustmentOverlay";
import React, { useEffect, useState, useRef } from "react";

function AdjustmentShortcutController({
  minValue,
  maxValue,
  defaultValue,
  title,
  onValueChange,
  increaseKey,
  decreaseKey,
  valueDisplayRatio = 1
}) {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if(onValueChange) onValueChange(value);
  }, [onValueChange, value]);

  const getChangeAmount = (value, range) => {
    let steps;

    if(value < (range * .3)) steps = 100;
    else if(value < (range * .5)) steps = 50;
    else steps = 10;

    return range / steps;
  }

  const handleKeyboard = (event) => {
    const range = maxValue - minValue;

    if(event.code === increaseKey || event.code === decreaseKey) {
      event.preventDefault();
      
      setValue(current => {
        const change = getChangeAmount(current, range);

        if(event.code === increaseKey) {
          console.log(Math.min(maxValue, current + change));
          return Math.min(maxValue, current + change);
        }
        else {
          console.log(Math.max(minValue, current - change));
          return Math.max(minValue, current - change);
        }
      });
    }
  }

  useEffect(function setKeyboardEventListeners() {
    document.addEventListener('keydown', handleKeyboard);

    return () => {
      document.removeEventListener('keydown', handleKeyboard);
    }
  }, []);

  return (
    <>
      <AdjustmentOverlay 
        min={minValue} 
        max={maxValue} 
        value={value}
        valueRender={value => Math.ceil(value * valueDisplayRatio)}
        title={title}
      />
    </>
  );
}

AdjustmentOverlay.prototype = {
  title: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
  valueDisplayRatio: PropTypes.number
};

export default AdjustmentShortcutController;
