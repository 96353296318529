import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const AgreementSlice = createSlice({
  name: "agreement",
  initialState: { content: { status: "" }, sign: { status: "" } },
  reducers: {
    content(state, action) {
      const data = action.payload;
      return { ...state, content: { ...data } };
    },
    sign(state, action) {
      const data = action.payload;
      return { ...state, sign: { ...data } };
    },
  },
});
export const { content, sign } = AgreementSlice.actions;
export default AgreementSlice.reducer;

export const getAgreementContent = () =>
  apiCallBegan({
    url: "static/frontendContent/agreementText",
    method: "Get",
    onSuccess: content.type,
    onError: content.type,
  });

export const signAgreement = (data) =>
  apiCallBegan({
    url: "account/accept-agreement",
    method: "Patch",
    data,
    onSuccess: sign.type,
    onError: sign.type,
  });
