import classNames from 'classnames'
import './Alert.sass'

const AlertType = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
}

function Alert({ type = AlertType.INFO, message, ...props }) {
  return (
    <div className={classNames('ir-alert', type)} {...props}>
      {message}
    </div>
  )
}

export default Alert