import './DockDeviceViewer.sass';
import DeviceViewer from '../DeviceViewer/DeviceViewer';
import { RiFoggyLine, RiRainyLine } from 'react-icons/ri';
import { MdOutlineUploadFile } from 'react-icons/md';
import { TbNetwork, TbTemperature } from 'react-icons/tb';
import { FiWind } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'src/helper/AccountStateProvider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFrontendHub } from 'src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider';
import DockHubProvider, { useDockHub } from 'src/components/Dashboard/Components/DockHubProvider/DockHubProvider';
import { HubEventName, HubName } from 'src/helper/HubConnection';
import { dockConnectionManager, onboardConnectionManager } from 'src/helper/HubConnectionManager';
import { osdTelemetry, requestsTelemetry } from '../DockManagerAppSlice'
import { BsCloud, BsCloudRain, BsLightningCharge } from 'react-icons/bs';
import { IoCloudOffline, IoCloudOfflineOutline } from 'react-icons/io5';
import { DeviceOnlineStatus } from 'src/helper/useDockList';

function DockDeviceViewer({ dockData }) {
  const { account } = useAccount();
  const dispatch = useDispatch();
  const deviceId = useMemo(() => dockData?.id, [dockData]);
  const serverMessageHandlerIds = useRef([]);
  const groupMessageHandlerIds = useRef([]);
  const { hub: frontendHub, checkTelemetries } = useFrontendHub();
  const videoStreamConfig = useSelector((state) => state.flight?.videoStreaming);
  const lastRequest = useSelector((state) => state.dock?.requestsTelemetry?.[dockData?.serialNumber]);
  const deviceInfo = useSelector((state) => state.deviceService.devices?.find(item => item.id === deviceId));
  const lastOsd = useSelector((state) => state.deviceService.deviceTelemetries?.[deviceId]);

  const telemetries = [
    {
      name: 'Rain',
      value: `${lastOsd?.rainfall === 1 ? 'Raining' : 'No Rain'}`,
      icon: lastOsd?.rainfall ? <BsCloudRain /> : <BsCloud />
    },
    {
      name: 'Wind',
      value: `${lastOsd?.wind_speed ?? '-'} m/s`,
      icon: <FiWind />
    },
    {
      name: 'Temperature',
      value: `${lastOsd?.environment_temperature ?? '-'} °C`,
      icon: <TbTemperature />
    },
    {
      name: 'Upload Queue',
      value: `${lastOsd?.media_file_detail?.remain_upload ?? '-'}`,
      icon: <MdOutlineUploadFile />
    },
    {
      name: 'Voltage',
      value: `${lastOsd?.working_current ?? '-'} A`,
      icon: <BsLightningCharge />
    },
  ];

  return (
    <div className="dock-device-viewer">
      <DeviceViewer title="Dock" telemetries={telemetries}>
        <div className="stream-container">
          <video src="" />
          {deviceInfo?.onlineStatus !== DeviceOnlineStatus.ONLINE && (
            <div className="connection-unavailable">
                <IoCloudOfflineOutline className="icon" />
                <div className="description">Waiting for dock connection...</div>
            </div>
          )}
        </div>
      </DeviceViewer>
    </div>
  )
}

export default DockDeviceViewer