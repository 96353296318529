import "./OperationHandler.sass";
import { GrClose } from "react-icons/gr";
import SlideLeft from "../../ui/Animation/SlideLeft/SlideLeft";
import PropTypes from "prop-types";
import OperationStep from "../../ui/OperationStep/OperationStep";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "../../helper/ThemeProvider";

function OperationHandler({
  selectedFeature,
  options,
  isOpen,
  close,
  toggle,
  onStepChange,
  extra,
}) {
  const [steps, setSteps] = useState(
    selectedFeature.steps && [...selectedFeature.steps]
  );
  const [feature, setFeature] = useState(selectedFeature);
  const operation = useSelector((state) => state.operation);
  const { currentContext } = useTheme();

  useEffect(() => {
    setFeature(selectedFeature);
  }, [selectedFeature]);

  useEffect(() => {
    if (selectedFeature.steps !== undefined)
      setSteps([...selectedFeature.steps]);
  }, [selectedFeature.steps]);

  useEffect(() => {
    if (operation.step !== "") {
      setSteps([
        ...steps.map((step) => ({
          ...step,
          active: step.name === operation.step,
          locked:
            step.name === operation.step
              ? false
              : step.deactivate
              ? true
              : step.locked,
        })),
      ]);
    }
  }, [operation]);

  const stepActivator = (name) => {
    steps.map((step) => (step.active = step.name === name));
    setSteps([...steps]);
    onStepChange(name);
  };

  return (
    <SlideLeft
      className="operation-handler"
      style={{ background: currentContext.theme.secondColor }}
      isOpen={isOpen}
      toggle={toggle}
    >
      <div className="operation-handler-header">
        <div className="operation-handler-head">
          <div className={`operation-handler-info ${!feature.info ? 'operation-handler-extended-info' : null}`}>
            {feature.info || (
              <>
                <span
                  className="operation-handler-icon"
                >
                  {feature.icon}
                </span>
                <div className="operation-handler-info-part-2">
                  <div className="operation-handler-title-container">
                    <span className="operation-handler-title">{feature.title}</span>
                    <GrClose
                      color="#455060"
                      className="operation-handler-close"
                      onClick={close}
                    />
                  </div>
                  <p className="operation-handler-description">{feature.description}</p>
                </div>
              </>
            )}
          </div>
          {feature.info && (
            <GrClose
              color="#455060"
              className="operation-handler-close"
              onClick={close}
            />
          )}
        </div>
        {feature.info && (
          <p className="operation-handler-description">{feature.description}</p>
        )}
      </div>

      <div className="operation-handler-options">{options}</div>
      <ul className="operation-handler-steps">
        {steps &&
          steps.map(
            (step, index) =>
              step.visible && (
                <li
                  className={
                    "operation-handler-step " +
                    (step.active ? "operation-handler-step-active" : "") +
                    (step.fullHeight
                      ? " operation-handler-step-full-height"
                      : "")
                  }
                  key={index}
                >
                  <OperationStep
                    isActive={step.active || step.passiveMode}
                    name={step.name}
                    toggleActive={stepActivator}
                    title={step.title}
                    isLocked={step.locked}
                    tags={step.tags}
                    isClosable={step.closable}
                    noBorder={step.noBorder}
                    fullHeight={step.fullHeight}
                  >
                    {step.activity}
                  </OperationStep>
                </li>
              )
          )}
      </ul>
      {extra}
    </SlideLeft>
  );
}

OperationHandler.propTypes = {
  feature: PropTypes.object.isRequired,
};
OperationHandler.defaultProps = {
  feature: { title: "", description: "", color: "#2699fb" },
};

export default OperationHandler;
