import { MdBlock } from 'react-icons/md';
import { useSelector } from 'react-redux';
import Dialog from 'src/ui/Dialog/Dialog';
import './CameraBusyOverlay.sass'

function CameraBusyOverlay({ className }) {
  return (
    <div className="camera-busy-overlay">
      <Dialog noOperation={true} className="camera-busy-dialog" >
        <MdBlock className='icon' />
        <div>Camera busy, please wait...</div>
      </Dialog>
    </div>
  )
}

export default CameraBusyOverlay