import './CameraEvSelector.sass'
import { useState } from 'react';
import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { PayloadIndexes } from 'src/helper/HubConnection';
import VerticalValueSlider from '../../VerticalValueSlider/VerticalValueSlider';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';

function CameraEvSelector() {
  const [exposure, setExposure] = useState(0);
  const { hub, groups, deviceId } = useOnboardHub();
  const flight = useSelector((state) => state.flight);
  const { activeCameraPayload } = useDeviceInfo();
  const exposureValues = activeCameraPayload?.settings?.exposure?.values;

  useEffect(() => {
    if (flight.camera?.exposureVal) {
      setExposure(exposureValues?.[flight.camera?.exposureVal - 1]);
    }
  }, [flight.camera, exposureValues]);

  const onExposureChange = (value, index) => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      camSetting: {
        payloadIndex: activeCameraPayload?.payloadIndex,
        exposureVal: index + 1,
      }
    });
  };

  return (
    <div className="camera-ev-selector">
      <VerticalValueSlider
        values={exposureValues?.filter(Boolean)}
        currValue={exposure}
        onValueChange={onExposureChange}
      />
    </div>
  )
}

export default CameraEvSelector