import classNames from 'classnames';
import { useState } from 'react';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { HiOutlineCursorClick } from 'react-icons/hi';
import VideoStageItem from '../VideoStageItem/VideoStageItem';
import './ObserverVideoStage.sass';

function ObserverVideoStage({ onUserVideoClose, onEnlargeClick, enlargedApp, singleVideoView = false, itemList = [] }) {
  const [enlargedVideoId, setEnlargedVideoId] = useState();

  const handleVideoItemClick = (id) => {
    setEnlargedVideoId(curr => curr === id ? undefined : id);
  }

  return (
    <div className="observer-video-stage">
      {!singleVideoView && (
        <div className="video-stage-toolbar">
          <div className="section-title">Video Streams</div>
          <div className="tools">
            <div className="item" onClick={onEnlargeClick}>
              {enlargedApp ? (
                <FiMinimize2 className="icon" />
              ) : (
                <FiMaximize2 className="icon" />
              )}
            </div>
          </div>
        </div>
      )}
      <div className="video-items-container">
        <div className={classNames("video-items-list", { 'has-enlarged-item': enlargedVideoId })}>
          {itemList.map(item => (
            <VideoStageItem
              key={item.id}
              id={item.id}
              deviceId={item.deviceId}
              title={item.title}
              controllerRoute={item.controllerRoute}
              enlarged={item.id === enlargedVideoId}
              connected={item.isOnline}
              streamConfig={item.streamConfig}
              onCloseClick={onUserVideoClose}
              onClick={() => handleVideoItemClick(item.id)}
              hideTools={singleVideoView}
            />
          ))}
        </div>
        {(!itemList?.length && !singleVideoView) && (
          <div className="stage-status-message">
            <HiOutlineCursorClick className="icon" />
            <div className="message">Please select a device</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ObserverVideoStage