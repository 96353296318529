import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUnitList, unitList } from "../SecurityAppSlice";

function useUnitList() {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api);
  const requestState = useSelector((state) => state.securityApp);
  const [loading, setLoading] = useState(true);
  const [dataItems, setDataItems] = useState([]);

  useEffect(() => {
    if (api.token !== undefined && requestState?.list?.status !== "success")
      dispatch(getUnitList());
    return () => {
      dispatch(unitList({ status: "" }));
    };
  }, [api.token]);

  useEffect(() => {
    if (requestState.unitList.data !== undefined) {
      setDataItems(requestState.unitList.data.units);
      setLoading(false);
    }
  }, [requestState.unitList])

  return {
    loading,
    data: dataItems,
    refresh: () => dispatch(getUnitList()),
  };
}

export default useUnitList