import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const DeviceManagementSlice = createSlice({
  name: "device",
  initialState: {
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    updateDfr: { status: "" },
    model: {},
    list: [],
    groupAssignment: {},
    groups: { status: "" },
    name: { status: "" },
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    setModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    updateDfr(state, action) {
      const data = action.payload;
      return { ...state, updateDfr: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
    groupAssignment(state, action) {
      const data = action.payload;
      return { ...state, groupAssignment: { ...data } };
    },
    groups(state, action) {
      const data = action.payload;
      return { ...state, groups: { ...data } };
    },
    name(state, action) {
      const data = action.payload;
      return { ...state, name: { ...data } };
    },
  },
});
export const {
  list,
  create,
  update,
  updateDfr,
  remove,
  setModel,
  groupAssignment,
  groups,
  name,
} = DeviceManagementSlice.actions;
export default DeviceManagementSlice.reducer;

export const getList = () =>
  apiCallBegan({
    url: "/devices",
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const createDevice = (data) =>
  apiCallBegan({
    url: "/devices",
    method: "post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const removeDevices = (id) =>
  apiCallBegan({
    url: `/devices/${id}`,
    method: "delete",
    onSuccess: remove.type,
    onError: remove.type,
  });

export const updateDevice = (data, id) =>
  apiCallBegan({
    url: `/devices/${id}`,
    method: "patch",
    data,
    onSuccess: update.type,
    onError: update.type,
  });

export const assignGroups = (data, id) =>
  apiCallBegan({
    url: `/devices/${id}/groups-assignment`,
    method: "patch",
    data,
    onSuccess: groupAssignment.type,
    onError: groupAssignment.type,
  });

export const checkName = (nameToCheck) =>
  apiCallBegan({
    url: "/devices/check?DeviceName=" + nameToCheck,
    method: "Get",
    onSpin: name.type,
    onSuccess: name.type,
    onError: name.type,
  });

export const updateDeviceDfr = (data, id) =>
  apiCallBegan({
    url: `/devices/${id}/dfr`,
    method: "patch",
    data,
    onSuccess: updateDfr.type,
    onError: updateDfr.type,
  });
