import React from 'react'
import './NotificationItem.sass'
import { MdCheck, MdHighlightOff, MdInfoOutline, MdOutlineChatBubbleOutline, MdOutlineClose, MdPerson, MdWarningAmber } from "react-icons/md";
import { fromNowTimeString } from 'src/helper/utils';
import { menuItems } from 'src/helper/constants';
import { IoAlertCircle, IoCheckmarkCircleSharp, IoCloseCircle } from 'react-icons/io5';
import { IoMdInformationCircle } from 'react-icons/io';

export const NotificationType = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
}

export const NotificationIcon = {
  SUCCESS: {
    name: 'success',
    value: <IoCheckmarkCircleSharp />
  },
  WARNING: {
    name: 'warning',
    value: <IoAlertCircle />
  },
  ERROR: {
    name: 'error',
    value: <IoCloseCircle />
  },
  INFO: {
    name: 'info',
    value: <IoMdInformationCircle />
  },
  USER: {
    name: 'user',
    value: <MdPerson />
  },
  DEVICE: {
    name: 'device',
    value: menuItems.find(i => i.name === 'device').defaultImage
  },
  STORAGE: {
    name: 'storage',
    value: menuItems.find(i => i.name === 'storage').defaultImage
  },
  ADMIN: {
    name: 'admin',
    value: menuItems.find(i => i.name === 'admin').defaultImage
  }
}

const NotificationConfig = {
  SUCCESS: {
    iconColor: '#8bd186',
    messageColor: '',
    icon: NotificationIcon.SUCCESS,
  },
  WARNING: {
    iconColor: '#f5b542',
    messageColor: '',
    icon: NotificationIcon.WARNING,
  },
  ERROR: {
    iconColor: '#ff7c7c',
    messageColor: '',
    icon: NotificationIcon.ERROR,
  },
  INFO: {
    iconColor: '#1da1f2',
    messageColor: '',
    icon: NotificationIcon.INFO,
  },
}

function NotificationItem({
  title,
  message,
  time,
  type,
  icon,
  config,
  single,
  onDismiss,
  closeToast, // Injected by toastifyJS
}) {
  const descriptiveTimeDayLimit = 3;
  const dateObject = time ? new Date(time) : undefined;
  let itemConfig, itemTime, itemDate, itemIcon;

  // Prepare date and time string
  if (Math.ceil(Math.abs(Date.now() - dateObject.valueOf())) / 1000 < (1000 * 60 * 60 * 24 * descriptiveTimeDayLimit)) {
    itemTime = fromNowTimeString(time);
    itemDate = null;
  }
  else {
    itemTime = dateObject.toLocaleTimeString();
    itemDate = dateObject.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  // Create final config for notification
  if (typeof type === 'string' && NotificationConfig[type.toUpperCase()])
    itemConfig = NotificationConfig[type.toUpperCase()]

  if (config)
    itemConfig = { ...itemConfig, ...config };

  if (icon)
    itemConfig.icon = icon;

  // Get icon element
  if (React.isValidElement(itemConfig.icon.value))
    itemIcon = itemConfig.icon.value;
  else if (typeof itemConfig.icon === 'object' && itemConfig.icon.value)
    itemIcon = itemConfig.icon.value;
  else if (typeof itemConfig.icon === 'string' && NotificationIcon[itemConfig.icon.toUpperCase()])
    itemIcon = NotificationIcon[itemConfig.icon.toUpperCase()].value;

  return (
    <div className={"notification-item " + (single ? 'single' : '')}>
      <div className="content">
        <div className="icon" style={{ color: itemConfig.iconColor }}>
          {itemIcon || <MdOutlineChatBubbleOutline />}
        </div>
        <div className="info">
          <div className="title">{title || 'New Message'}</div>
          <div className="message" style={{ color: itemConfig.messageColor }}>{message || 'No details provided'}</div>
          <div className="details">
            <div className="item">{itemTime}</div>
            <div className="item">{itemDate}</div>
          </div>
        </div>
        {(closeToast || typeof onDismiss === 'function') && (
          <MdOutlineClose className="close-button" onClick={() => {
            onDismiss?.();
            closeToast?.();
          }} />
        )}
      </div>
    </div>
  )
}

export default NotificationItem