import { useState, useEffect, useRef } from 'react';

function CountdownTimer({ seconds, reset, onFinish, inverse, ...props }) {
  const [time, setTime] = useState(seconds);
  const [isMounted, setIsMounted] = useState(false);
  const interval = useRef();

  useEffect(() => {
    if (!inverse && !seconds) return;
    clearInterval(interval.current);
    setTime(seconds || 1);

    interval.current = setInterval(() => {
      setTime((time) => inverse ? time + 1 : time - 1);
    }, 1000);

    setIsMounted(true);
    return () => clearInterval(interval.current);
  }, [seconds, reset]);

  useEffect(() => {
    if (isMounted && time <= 0) {
      clearInterval(interval.current);
      onFinish?.();
    }
  }, [time, isMounted]);

  return (
    <span {...props}>{`${Math.floor(time / 60)}:${(time % 60)
      .toString()
      .padStart(2, "0")}`}</span>
  );
}

export default CountdownTimer;