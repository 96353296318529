import { useSettings } from 'src/helper/SettingsProvider';
import './StreamRegion.sass'
import { IoEarth } from 'react-icons/io5'
import { useSelector } from 'react-redux';

function StreamRegion() {
  const currVideoRegion = useSelector(state => state.flight.videoStreaming?.fpvViewerConfig?.region);
  const settings = useSettings();
  const region = settings.videoStreamRegions?.find(item => item.index === currVideoRegion);
  const regionAbbr = region?.abbreviation?.toUpperCase() || "N/A";

  return (
    <div className="stream-region-indicator">
      <IoEarth className="icon" />
      <div className="content">
        <div className="title">Region</div>
        <div className="value">{regionAbbr}</div>
      </div>
    </div>
  )
}

export default StreamRegion