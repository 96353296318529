import { deviceTypes } from "src/helper/constants";
import useDeviceTelemetries from "./useDeviceTelemetries";
import { useMemo } from "react";
import { getDeviceInfo, getDeviceModelInfo } from "./deviceUtils";

export default function useSubDevice (deviceId, deviceType) {
  const telemetries = (deviceType && deviceType !== deviceTypes.DOCK) ? 
  null : useDeviceTelemetries(deviceId);

  const subDevice = useMemo(() => {
    const subDeviceInfo = telemetries?.sub_device;
    const modelInfo = getDeviceModelInfo(subDeviceInfo?.device_model_key);
    const deviceInfo = getDeviceInfo(subDeviceInfo?.device_sn);

    return {
      ...(subDeviceInfo || {}),
      device: deviceInfo,
      modelInfo,
    };
  }, [telemetries]);

  const subDeviceTelemetries = useDeviceTelemetries(subDevice?.device_sn);

  return {
    ...subDevice,
    telemetries: subDeviceTelemetries,
  };
}