import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RadarCurrent = ({ className }) => {
  const compass = useSelector((state) => state.flight.compass);
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    setCurrent(compass.heading);
  }, [compass]);

  return (
    <svg
      className={className}
      width="2.2em"
      height="1.6em"
      viewBox="0 0 23.38 41.05"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g data-name="Layer 1">
        <g data-name="Layer 2">
          <g
            style={{
              isolation: "isolate",
            }}
          >
            <text
              transform={`translate(${ String(current).length === 1 ? '5' : (String(current).length === 3 ? '-8' : '0')  } 20.82)`}
              style={{
                fontSize: 26,
                fill: "#0fb",
                fontFamily: "Roboto-Medium,Roboto",
                fontWeight: 500,
                isolation: "isolate",
                textShadow: "1px 1px 2px rgba(0,0,0,0.8)"
              }}
            >
              {current}
            </text>
          </g>
          <path
            d="M11 26.69h2.54v14.36H11Z"
            style={{
              fill: "#178665",
            }}
          />
        </g>
      </g>
    </svg>
  );
};

export default RadarCurrent;
