import classNames from 'classnames';
import styles from './ScrollArea.module.sass'
import * as RadixScrollArea from '@radix-ui/react-scroll-area';

function Root({ className, ...props}: RadixScrollArea.ScrollAreaProps) {
  return <RadixScrollArea.Root dir="ltr" className={classNames(styles.root, className)} {...props} />
}

function Viewport(props: RadixScrollArea.ScrollAreaViewportProps) {
  return <RadixScrollArea.Viewport className={styles.viewport} {...props} />
}

function Scrollbar(props: RadixScrollArea.ScrollAreaScrollbarProps) {
  return <RadixScrollArea.Scrollbar className={styles.scrollbar} {...props} />
}

function Thumb(props: RadixScrollArea.ScrollAreaThumbProps) {
  return <RadixScrollArea.Thumb className={styles.thumb} {...props} />
}

function Corner(props: RadixScrollArea.ScrollAreaCornerProps) {
  return <RadixScrollArea.Corner className={styles.corner} {...props} />
}

interface ScrollAreaProps {
  children?: React.ReactNode,
  orientation?: 'vertical' | 'horizontal',
  scrollbarAlign?: 'start' | 'end',
}

export function ScrollArea({ children, scrollbarAlign = 'end', orientation = 'vertical', ...props }: ScrollAreaProps & RadixScrollArea.ScrollAreaProps) {
  return (
    <Root {...props}>
      <Viewport>
        { children }
      </Viewport>
      <Scrollbar orientation={orientation} style={scrollbarAlign === 'start' ? {left: '', right: '0'} : {}}>
        <Thumb />
      </Scrollbar>
    </Root>
  )
}