import { createSlice } from "@reduxjs/toolkit";

const LogServiceSlice = createSlice({
  name: "logService",
  initialState: {
    logs: {},
  },
  reducers: {
    addLog(state, action) {
      const data = action.payload;
      const group = data.group || 'general';

      state.logs[group] = state.logs[group] || [];
      state.logs[group].push(data);
      return state;
    }
  },
});

export const {
  addLog,
} = LogServiceSlice.actions;

export default LogServiceSlice.reducer;