import './MapDroneMarker.sass';
import classNames from 'classnames';
import GPSMarker from 'src/ui/DroneIcons/GPSMarker';

function MapDroneMarker({title, heading, className}) {
  return (
    <div className={classNames("map-drone-marker", className)} title="Drone">
      <div className="icon">
        <GPSMarker currHeading={heading} />
      </div>
      <div className="title">
        {title}
      </div>
    </div>
  )
}

export default MapDroneMarker