import { useEffect, useState } from "react";
import "./ContainerInformation.sass";
import { useSelector } from "react-redux";
import LabeledButton from "../../../../../../ui/LabeledButton/LabeledButton";
import { MdRefresh } from "react-icons/md";
import ApplicationComponentContent from "../../../../Components/ApplicationComponentContent/ApplicationComponentContent";
import LabeledInformationContainer from "../../../../Components/LabeledInformationContainer/LabeledInformationContainer";

function ContainerInformation() {
  const [information, setInformation] = useState({});
  const [data, setData] = useState({});
  const container = useSelector((state) => state.containerApp);

  const actions = [];
  const controls = [
    {
      name: "refresh_container_information",
      active: true,
      component: (
        <LabeledButton
          title="Refresh"
          icon={<MdRefresh style={{ marginLeft: "-0.15em" }} />}
        />
      ),
    },
  ];

  useEffect(() => {
    setData({ ...container?.model });
  }, [container.model]);

  useEffect(() => {
    setInformation({
      "Container Name": data.containerName,
      "Registered Date": data.creationDate,
      "Registered By": data.createdBy,
      "Physical Location": data.storageLocation,
    });
  }, [data]);

  return (
    <div className="container-information">
      <ApplicationComponentContent controls={controls} actions={actions}>
        <h3>Container Overview</h3>
        <LabeledInformationContainer information={information} />
        <div className="container-description">
          <span>Description</span>
          <p>{data?.description}</p>
        </div>
      </ApplicationComponentContent>
    </div>
  );
}

export default ContainerInformation;
