import classNames from 'classnames';
import { useVideoStream } from 'src/helper/useVideoStream';
import { useEffect } from 'react';
import { useState } from 'react';
import { TbPlugConnectedX } from 'react-icons/tb';
import VideoStreamView from 'src/ui/Drone/VideoStreamView/VideoStreamView';
import Loading from 'src/ui/Loading/Loading';
import './PublicLiveStreamVideo.sass';

function PublicLiveStreamVideo({ className, streamConfig }) {
  const [activeStream, setActiveStream] = useState();
  const [initialized, setInitialized] = useState();
  const { mediaStream } = useVideoStream(activeStream ? streamConfig?.[activeStream] : undefined);

  useEffect(() => {
    setActiveStream(streamConfig.fpv ? 'fpv' : streamConfig.main ? 'main' : undefined);
  }, [streamConfig]);

  const handleVideoRenderChange = (info) => {
    if (info) setInitialized(true);
  }

  const handleSwitchStremClick = (streamName) => {
    setActiveStream(streamName);
    setInitialized(false);
  }

  return (
    <div className={classNames("public-live-stream-video", className)}>
      <div className="video-container">
        <VideoStreamView
          stream={mediaStream}
          className="video-stream"
          onVideoRenderChange={handleVideoRenderChange}
        />
      </div>

      {false && (
        <div className="status-message">
          <TbPlugConnectedX className="icon" />
          <div className="message">Disconnected</div>
        </div>
      )}

      <div className="stream-switch">
        {['main', 'fpv'].map(item => !!streamConfig?.[item] && (
          <div
            className={classNames("switch-item", { 'active': item === activeStream })}
            onClick={() => handleSwitchStremClick(item)}
            key={item}
          >
            {item}
          </div>
        ))}
      </div>
      {!initialized && <Loading dark={true} />}
    </div>
  )
}

export default PublicLiveStreamVideo