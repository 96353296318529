import React, { useEffect } from 'react'
import { useSpring, animated } from "react-spring"
import './NotificationPanel.sass'
import NotificationItem from '../NotificationItem/NotificationItem';
import LabeledButton from 'src/ui/LabeledButton/LabeledButton'
import { useDispatch, useSelector } from 'react-redux';
import { MdInbox } from 'react-icons/md';
import { markAllAsSeen, removeAllNotifications, removeNotification } from '../NotificationSlice';

function NotificationPanel({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notification.notifications);
  const animateProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: isOpen ? 1 : 0 },
    onRest: () => {
      if (!isOpen) onClose?.();
    }
  });

  useEffect(() => {
    dispatch(markAllAsSeen());
  }, [dispatch]);

  return (
    <animated.div className="notification-panel" style={animateProps}>
      <div className="panel-header">
        <div className="panel-title">Notifications</div>
        {notifications?.length ? (
          <LabeledButton title="Dismiss All" className="action" onClick={() => {
            dispatch(removeAllNotifications());
          }} />
        ) : null}
      </div>
      {notifications.length ? (
        <div className="notifications-list">
          {notifications.map(item => (
            <NotificationItem {...item} key={item.id} onDismiss={() => {
              dispatch(removeNotification(item.id));
            }} />
          ))}
        </div>
      ) : (
        <div className="notifications-list-empty">
          <MdInbox className="sign" />
          <div className="message">No notifications found</div>
        </div>
      )}
    </animated.div>
  )
}

export default NotificationPanel