import { useTheme } from "../../helper/ThemeProvider";
import "./LabeledButton.sass";

function LabeledButton({
  className,
  icon,
  title,
  onClick,
  disabled,
  color,
  iconColor,
  link,
}) {
  const { currentContext } = useTheme();
  return (
    <button
      disabled={disabled}
      className={`${className ? className : ""} labeled-button`}
      onClick={onClick}
    >
      <div
        className="labeled-button-icon"
      >
        {icon}
      </div>
      <span
        style={{
          textDecoration: link ? "underline" : "none",
        }}
        className="labeled-button-title"
      >
        {title}
      </span>
    </button>
  );
}


export default LabeledButton;
