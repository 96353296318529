import classNames from 'classnames'
import './MessageArea.sass'

export const MessageAreaSize = {
  TINY: 'tiny',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
}

function MessageArea({ title, description, icon: Icon, className, size = MessageAreaSize.MEDIUM, ...props }) {
  return (
    <div className={classNames("message-area", size, className)} {...props}>
      <div className="content">
        {Icon ? (
          <div className="icon-container">
            <Icon className="icon" />
          </div>
        ) : null}
        <div className="info">
          {title ? <div className="title">{title}</div> : null}
          {description ? <div className="description">{description}</div> : null}
        </div>
      </div>
    </div>
  )
}

export default MessageArea