import Avatar from "../../../ui/Avatar/Avatar";
import "./ProfileInformation.sass";
import { MdPowerSettingsNew } from "react-icons/md";
import LinkButton from "../../../ui/LinkButton/LinkButton";
import {
  useAccount,
  useCurrentUser,
} from "../../../helper/AccountStateProvider";
import { FaUserCircle } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useTheme } from "src/helper/ThemeProvider";

function ProfileInformation({ onClose }) {
  const { currentUser } = useCurrentUser();
  const { account } = useAccount();
  const { currentContext } = useTheme();
  const history = useHistory();

  return (
    <div className="profile-information">
      <div className="profile-information-container">
        <div className="profile-information-head">
          <div className="company-name">Intuitive Robotics</div>
          <LinkButton title="View Company Profile" onClick={() => {
            history.push('/dashboard/profile/organizations');
            onClose?.();
          }} />
        </div>
        <div className="profile-information-main">
          {currentUser?.avatar ? (
            <Avatar
              image={currentUser?.avatar}
              isCovered={true}
              width="80px"
              height="80px"
            />
          ) : (
            <Avatar
              image={
                <FaUserCircle color={currentContext.theme.color} size="80px" />
              }
              width="80px"
              height="80px"
            />
          )}
          <div className="part-2">
            <div className="user-name">{currentUser?.profile?.displayName}</div>
            <div className="user-email">{currentUser?.profile?.email}</div>
            <Link to="/dashboard/profile" onClick={onClose}>
              Manage Your Profile
            </Link>
          </div>
        </div>
        <div className="profile-information-footer">
          <div className="sign-out-button">
            <MdPowerSettingsNew />
            <LinkButton
              onClick={() => {
                localStorage.removeItem("logged-in");
                account.instance.logout();
              }}
              title="Sign Out"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileInformation;
