import { createSlice } from "@reduxjs/toolkit"
import { generateId } from "src/helper/utils";

const initialState = {
  notifications: []
};

const NotificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: {
      reducer: (state, action) => {
        state.notifications.push(action.payload);
      },
      prepare: (options) => {
        return {
          payload: {
            id: generateId(),
            time: new Date().toISOString(),
            seen: false,
            ...options,
            ...(options.config?.icon?.name ? {
              config: {
                ...options.config,
                icon: options.config.icon.name
              }
            } : {}),
            ...(options.icon?.name ? {
              icon: options.icon.name
            } : {})
          }
        }
      }
    },
    removeNotification: (state, action) => {
      const targetIndex = state.notifications.findIndex(item => item.id === action.payload);

      if (targetIndex !== -1)
        state.notifications.splice(targetIndex, 1);
    },
    removeAllNotifications: (state) => {
      state.notifications.length = 0;
    },
    markAsSeen: (state, action) => {
      const targetIndex = state.notifications.findIndex(item => item.id === action.payload);

      if (targetIndex !== -1)
        state.notifications[targetIndex].seen = true;
    },
    markAllAsSeen: (state, action) => {
      state.notifications.forEach((item, index) => {
        if (!item.seen)
          state.notifications[index].seen = true;
      })
    },
  },
});

export const { addNotification, removeNotification, removeAllNotifications, markAsSeen, markAllAsSeen } = NotificationSlice.actions;
export default NotificationSlice.reducer;