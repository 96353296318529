import "./CamRecorder.sass";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useOnboardHub } from "src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider";
import { PayloadIndexes } from "src/helper/HubConnection";
import { useDeviceInfo } from "src/helper/DeviceInfoProvider";

function CamRecorder({ className, size, startRecording, stopRecording }) {
  const [recording, setRecording] = useState(false);
  const isRecording = useSelector(
    (state) => state.flight.cameraStats?.isRecording
  );
  const recordingProgress = useSelector((state) => state.flight?.isRecording);
  const recordTime = useSelector((state) => state.flight.recordTime);

  const { hub, groups, deviceId, joinCompleted } = useOnboardHub();
  const { activeCameraPayload } = useDeviceInfo();

  useEffect(() => {
    setRecording(isRecording);
    if (isRecording) {
      if (!recordingProgress) startRecording();
    } else {
      stopRecording();
    }
  }, [isRecording, recordingProgress]);

  const toggleCapture = () => {
    if (!recording) {
      if (joinCompleted) {
        hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
          startVideoRecording: {
            payloadIndex: activeCameraPayload?.payloadIndex,
          }
        });
      }
    } else {
      if (joinCompleted)
        hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
          stopVideoRecording: {
            payloadIndex: activeCameraPayload?.payloadIndex,
          }
        });
    }
  };

  return (
    <div className="cam-recorder">
      {!recording ? (
        <svg
          cursor="pointer"
          onClick={toggleCapture}
          className={className}
          width={`${size}em`}
          height={`${size}em`}
          viewBox="0 0 60 60"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <radialGradient
              cx="50%"
              cy="50%"
              fx="50%"
              fy="50%"
              r="50%"
              id="radialGradient-rvb"
            >
              <stop stopColor="#FF0000" offset="0%"></stop>
              <stop stopColor="#FF0000" offset="81.7376874%"></stop>
              <stop stopColor="#BABABA" offset="89.1778769%"></stop>
              <stop stopColor="#FFFFFF" offset="93.2076869%"></stop>
              <stop stopColor="#7C7C7C" offset="100%"></stop>
            </radialGradient>
          </defs>
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <circle
              id="record-video-button"
              fill="url(#radialGradient-rvb)"
              cx="30"
              cy="30"
              r="30"
            ></circle>
          </g>
        </svg>
      ) : (
        <svg
          cursor="pointer"
          onClick={toggleCapture}
          className="rotating"
          width={`${size}em`}
          height={`${size}em`}
          viewBox="0 0 60 60"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <radialGradient
              cx="50%"
              cy="50%"
              fx="50%"
              fy="50%"
              r="50%"
              id="radialGradient-svr"
            >
              <stop stopColor="#ff0000" offset="0%"></stop>
              <stop stopColor="#ff0000" offset="81.7376874%"></stop>
              <stop stopColor="#BABABA" offset="89.1778769%"></stop>
              <stop stopColor="#FFFFFF" offset="93.2076869%"></stop>
              <stop stopColor="#7C7C7C" offset="100%"></stop>
            </radialGradient>
          </defs>
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <path
              d="M30,60 C13.4314575,60 0,46.5685425 0,30 C0,13.4314575 13.4314575,0 30,0 C46.5685425,0 60,13.4314575 60,30 C60,46.5685425 46.5685425,60 30,60 Z M29.5737705,35.4918033 C32.289925,35.4918033 34.4918033,33.289925 34.4918033,30.5737705 C34.4918033,27.857616 32.289925,25.6557377 29.5737705,25.6557377 C26.857616,25.6557377 24.6557377,27.857616 24.6557377,30.5737705 C24.6557377,33.289925 26.857616,35.4918033 29.5737705,35.4918033 Z M29.7122849,19.9664173 C33.5149012,19.9664173 36.5975308,16.8837877 36.5975308,13.0811714 C36.5975308,9.27855511 33.5149012,6.19592551 29.7122849,6.19592551 C25.9096686,6.19592551 22.827039,9.27855511 22.827039,13.0811714 C22.827039,16.8837877 25.9096686,19.9664173 29.7122849,19.9664173 Z M29.7122849,53.9664173 C33.5149012,53.9664173 36.5975308,50.8837877 36.5975308,47.0811714 C36.5975308,43.2785551 33.5149012,40.1959255 29.7122849,40.1959255 C25.9096686,40.1959255 22.827039,43.2785551 22.827039,47.0811714 C22.827039,50.8837877 25.9096686,53.9664173 29.7122849,53.9664173 Z M46.7122849,36.9664173 C50.5149012,36.9664173 53.5975308,33.8837877 53.5975308,30.0811714 C53.5975308,26.2785551 50.5149012,23.1959255 46.7122849,23.1959255 C42.9096686,23.1959255 39.827039,26.2785551 39.827039,30.0811714 C39.827039,33.8837877 42.9096686,36.9664173 46.7122849,36.9664173 Z M12.7122849,36.9664173 C16.5149012,36.9664173 19.5975308,33.8837877 19.5975308,30.0811714 C19.5975308,26.2785551 16.5149012,23.1959255 12.7122849,23.1959255 C8.9096686,23.1959255 5.82703901,26.2785551 5.82703901,30.0811714 C5.82703901,33.8837877 8.9096686,36.9664173 12.7122849,36.9664173 Z"
              id="stop-record-video-button"
              fill="url(#radialGradient-svr)"
            ></path>
          </g>
        </svg>
      )}
      <span>{recordTime}</span>
    </div>
  );
}

export default CamRecorder;
