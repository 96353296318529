import DesktopSize from "../../ui/DesktopSize/DesktopSize";
import TabletSize from "../../ui/TabletSize/TabletSize";
import AppNavbar from "../AppNavbar/AppNavbar";
import AppBar from "../AppBar/AppBar";
import ApplicationsArea from "./ApplicationsArea/ApplicationsArea";
import "./Dashboard.sass";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAccountState } from "../../helper/AccountStateProvider";
import HeaderLoading from "src/ui/HeaderLoading/HeaderLoading";
import AppHeader from "src/hci/organism/AppHeader/AppHeader";
import StatusBar from "src/hci/organism/StatusBar/StatusBar";
import { AppThemeNames, useAppTheme } from "src/helper/AppThemeProvider";

function Dashboard() {
  const [openProfile, setOpenProfile] = useState(false);
  const [theme, setTheme] = useAppTheme();

  const history = useHistory();
  const { accountStatus } = useAccountState();
  useEffect(() => {
    if (
      accountStatus.mobile === false ||
      accountStatus.agreement === false ||
      accountStatus.account === false
    )
      history.push("/account");
  }, [accountStatus, history]);

  useEffect(() => {
    setTheme(AppThemeNames.DARK);
  }, []);

  const toggleProfileOn = (event) => {
    setOpenProfile(true);
  };
  const toggleProfileOff = (event) => {
    setOpenProfile(false);
  };
  return accountStatus.mobile === true &&
    accountStatus.agreement === true &&
    accountStatus.account === true ? (
    <div className="dashboard">
      <TabletSize>
        <AppBar onToggleProfileMenu={toggleProfileOn} background="#ffffff" />
        <div
          className="dashboard-mobile"
          style={{ overflow: openProfile ? "hidden" : "none" }}
        >
          <AppNavbar />
          <ApplicationsArea
            openProfile={openProfile}
            onProfileClose={toggleProfileOff}
          />
        </div>
      </TabletSize>

      <DesktopSize>
        <div className="dashboard-desktop">
          <div
            className="dashboard-desktop-container"
            style={{ overflow: openProfile ? "hidden" : "none" }}
          >
            <AppHeader
              onToggleProfileMenu={toggleProfileOn}
            />
            <ApplicationsArea
              openProfile={openProfile}
              onProfileClose={toggleProfileOff}
            />
            {/* <StatusBar /> */}
          </div>
        </div>
      </DesktopSize>
    </div>
  ) : (
    <HeaderLoading />
  );
}

export default Dashboard;
