import PropTypes from "prop-types";
import "./Button.sass";
import React from "react";
import { useTheme } from "../../helper/ThemeProvider";
function Button({
  value,
  disabled,
  onClick,
  width,
  isSelectable,
  icon,
  fontSize,
  className,
}) {
  const { currentContext } = useTheme();
  return (
    <>
      <button
        className={`button ${isSelectable ? "selectable" : ""} ${className || ''}`}
        disabled={disabled}
        onClick={onClick}
        style={{
          border: isSelectable
            ? "1px solid " + currentContext?.theme.color
            : "none",
          width: width,
          fontSize: fontSize,
        }}
      >
        {icon}
        {value}
      </button>
    </>
  );
}
Button.prototype = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  color: "#2699fb",
  fontSize: "1.1rem",
};

export default Button;
