import { createSlice } from "@reduxjs/toolkit";

const UiSlice = createSlice({
  name: "ui",
  initialState: {
    mainMenu: { open: false, pinned: true },
    headerBreadcrumb: [{ title: "Dashboard", link: "/dashboard" }],
    disableKeyboardEvents: false,
  },
  reducers: {
    mainMenu(state, action) {
      const data = action.payload;
      return { ...state, mainMenu: { ...state.mainMenu, ...data } };
    },
    toggleMainMenuOpen(state, action) {
      return { ...state, mainMenu: { ...state.mainMenu, open: !state.mainMenu.open } };
    },
    toggleMainMenuPin(state, action) {
      return { ...state, mainMenu: { ...state.mainMenu, pinned: !state.mainMenu.pinned, open: false } };
    },
    headerBreadcrumb(state, action) {
      const data = action.payload;
      return { ...state, headerBreadcrumb: data };
    },
    setDisableKeyboardEvents(state, action) {
      const data = action.payload;
      return { ...state, disableKeyboardEvents: data };
    }
  },
});
export const { mainMenu, toggleMainMenuOpen, toggleMainMenuPin, toggleMainMenuHidden, headerBreadcrumb, setDisableKeyboardEvents } = UiSlice.actions;
export default UiSlice.reducer;