import { useLandscape } from "../../helper/OrientationProvider";
import ViewPortResource from "../ViewPortResource/ViewPortResource";
import "./Landscape.sass";

function Landscape({ background, color, message, closable, children }) {
  const { landscape } = useLandscape();

  return (
    <>
      {!landscape ? (
        <div className="landscape show" style={{ background }}>
          <ViewPortResource
            color={color}
            message={message}
            closable={closable}
          />
        </div>
      ) : (
        children
      )}
    </>
  );
}
Landscape.defaultProps = {
  background: "black",
  color: "white",
};
export default Landscape;
