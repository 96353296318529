/* eslint-disable react-hooks/exhaustive-deps */
import { createRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getVerificationCode } from "./VerificationCodeSlice";
import Code from "../Code/Code";
import "./VerificationCode.sass";
import SmallAlert from "../SmallAlert/SmallAlert";

function VerificationCode(props) {
  const { total, status,type } = props;
  const [codes,setCodes] = useState(Array(total));
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  var result = "";

  useEffect(() => {
    content[index].props.input.current.focus();
    content[index].props.input.current.setSelectionRange(
      0,
      content[index].props.input.current.value.length
    );
  }, [index]);

  let content = [];
  const handleKeyDown = (event) => {
    if  (event.keyCode === 39 && index < total - 1) {
      setIndex(index + 1);
    } else if (event.keyCode === 37 && index > 0) {
      setIndex(index - 1);
    }
    else if (event.keyCode === 8 && index > 0 && event.target.value === "") {
      setIndex(index - 1);
    }
  };

  const onFocus = (event) => {
    if (event.target.value !== "") {
      event.target.setSelectionRange(0, event.target.value.length);
    }
  };

  const handleOnChange = (event) => {
    codes.splice(event.target.dataset.index,1,event.target.value)
    setCodes(codes);
    result = codes.toString().replaceAll(",", "");
    dispatch(getVerificationCode({ code: result }));
    if(event.target.value !== ""){  
      if (index < total - 1 ) {
        setIndex(index + 1);
      }
    }
    
  };
  const handleMouseDown=(event)=>{
    setIndex(parseInt(event.target.dataset.index));
  }
  const generateCodes = (codeCount) => {
    for (let i = 0; i < codeCount; i++) {
      let input = createRef();
      content.push(
        <Code
          type={type}
          key={i}
          index={i}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          onChange={handleOnChange}
          mouseDown={handleMouseDown}
          input={input}
        />
      );
    }
    return content;
  };
  return (
    <div className="verification-code">
      {generateCodes(total)}
      {status !== undefined && status.alert && (
        <SmallAlert
          alert={status.alert}
          alertType={status.type}
          message={status.message}
          hideIcon={true}
          ratio={1.5}
        />
      )}
    </div>
  );
}
export default VerificationCode;
