import classNames from 'classnames';
import { useVideoStream } from 'src/helper/useVideoStream';
import { useEffect } from 'react';
import { useState } from 'react';
import { BiJoystickButton } from 'react-icons/bi';
import { IoMdClose } from 'react-icons/io';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { TbPlugConnectedX } from 'react-icons/tb';
import { useHistory } from 'react-router-dom';
import VideoStreamView from 'src/ui/Drone/VideoStreamView/VideoStreamView';
import Loading from 'src/ui/Loading/Loading';
import { CAMERA_OBSERVER_FULLSCREEN_URL_PARAM } from '../CameraObserverApp';
import './VideoStageItem.sass';

function VideoStageItem({ className, id, deviceId, title, controllerRoute, streamConfig, enlarged, connected, onCloseClick, onClick, hideTools = false }) {
  const history = useHistory();
  const [activeStream, setActiveStream] = useState();
  const [initialized, setInitialized] = useState();
  const { mediaStream } = useVideoStream(activeStream ? streamConfig?.[activeStream] : undefined);

  useEffect(() => {
    setActiveStream(streamConfig.fpv ? 'fpv' : streamConfig.main ? 'main' : undefined);
  }, [streamConfig]);

  useEffect(() => {
    if (!connected) setInitialized(false);
  }, [connected]);

  const handleVideoRenderChange = (info) => {
    if (info) setInitialized(true);
  }

  const handleSwitchStremClick = (streamName) => {
    setActiveStream(streamName);
    setInitialized(false);
  }

  const handleOpenInNewWindow = () => {
    const currUrl = new URL(window.location);
    currUrl.searchParams.set(CAMERA_OBSERVER_FULLSCREEN_URL_PARAM, 1);
    currUrl.searchParams.set('device_id', deviceId);
    window.open(currUrl.toString(), '_blank', 'toolbar=0,location=0,menubar=0');
  }

  const handleGoToControllerConsole = () => {
    history.push(controllerRoute);
  }

  return (
    <div className={classNames("video-stage-item", className, { 'enlarged': enlarged })}>
      <div className="video-tools">
        <div className="video-title">{title || 'Untitled'}</div>
        {!hideTools && (
          <div className="actions">
            {!!controllerRoute && <BiJoystickButton onClick={handleGoToControllerConsole} title="Go to controller console" />}
            <MdOutlineOpenInNew onClick={handleOpenInNewWindow} title="Open in new window" />
            <IoMdClose onClick={() => enlarged ? onClick?.() : onCloseClick?.(id)} />
          </div>
        )}
      </div>
      <div className="video-container" onClick={onClick}>
        <VideoStreamView
          stream={mediaStream}
          className="video-stream"
          onVideoRenderChange={handleVideoRenderChange}
        />
      </div>

      {!connected && (
        <div className="status-message">
          <TbPlugConnectedX className="icon" />
          <div className="message">Disconnected</div>
        </div>
      )}

      <div className="stream-switch">
        {['main', 'fpv'].map(item => !!streamConfig?.[item] && (
          <div
            className={classNames("switch-item", { 'active': item === activeStream })}
            onClick={() => handleSwitchStremClick(item)}
          >
            {item}
          </div>
        ))}
      </div>
      {!!connected && !initialized && <Loading dark={true} />}
    </div>
  )
}

export default VideoStageItem