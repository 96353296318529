import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import "./OperationStep.sass";
import Toast from "../Toast/Toast";
import { useTheme } from "../../helper/ThemeProvider";

function OperationStep({
  name,
  title,
  icon,
  toggleActive,
  isActive,
  isLocked,
  children,
  isClosable,
  noBorder,
  tags,
  fullHeight,
}) {
  const [active, setActive] = useState(isActive);
  const [locked, setLocked] = useState(isLocked);
  const [closable, setClosable] = useState(isClosable);

  const loading = useSelector((state) => state.loading);
  const toast = useSelector((state) => state.toast);

  const { currentContext } = useTheme();

  const toggle = () => {
    if (!isLocked) {
      if (closable && toggleActive(name) !== false) setActive(!active);
    }
  };

  useEffect(() => {
    setClosable(isClosable);
  }, [isClosable]);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  useEffect(() => {
    setLocked(isLocked);
  }, [isLocked]);

  return (
    <div
      className={`operation-step operation-step${
        active ? "-active" : "-passive"
      } ` + (fullHeight ? 'operation-step-full-height' : '')}
      style={{
        borderLeft: `${
          noBorder
            ? "none"
            : active
            ? "4px solid " + currentContext.theme.color
            : "4px solid #e6e6e6"
        }`,
      }}
    >
      {icon || title ? (
        <div
          className={`operation-step-title operation-step-title${
            active ? "-active" : "-passive"
          }`}
          style={{
            color: `${active ? currentContext.theme.color : "#8b9695"}`,
          }}
          onClick={toggle}
        >
          {icon}
          <span>{title}</span>
        </div>
      ) : null}
      {active && !locked && (
        <div className="operation-step-content">
          {tags.includes(loading.name) && loading.status && <Loading />}
          {tags.includes(toast.name) && <Toast />}
          {children}
        </div>
      )}
    </div>
  );
}
OperationStep.defaultProps = {
  tags: [],
  isClosable: true,
};
export default OperationStep;
