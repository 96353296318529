import InputBox from "../../../../../ui/InputBox/InputBox";
import "./EditDock.sass";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import useDebounce from "../../../../../helper/use-debounce";
import { checkName, name, updateDock } from "../DockManagementSlice";
import { useSelector } from "react-redux";
import Select from "../../../../../ui/Select/Select";
import { dataStreamingRegions, measurementUnitItems } from "../../../../../helper/constants";
import Button from "../../../../../ui/Button/Button";
import { useSettings } from "src/helper/SettingsProvider";
import { getList as s3StorageGetList } from "../../S3Management/S3ManagementSlice";
import { getList as s3BucketGetList } from "../../S3Management/S3BucketSlice";
import Alert from "src/hci/common/Alert/Alert";

function EditDock({ onConfirm, onOpen }) {
  const [required] = useState(["dataStreamingRegion", "dockName"]);
  const [alerts, setAlerts] = useState({});
  const [data, setData] = useState({});
  const [DockName, setDockName] = useState("");
  const [oldDockName, setOldDockName] = useState("");
  const [icons, setIcons] = useState({ DockName: "default" });
  const DebouncedBusinessName = useDebounce(DockName, 1000);
  const [validate, setValidate] = useState(true);
  const [s3Storages, setS3Storages] = useState();
  const [s3Buckets, setS3Buckets] = useState();
  const [selectedS3Storage, setSelectedS3Storage] = useState();
  const dock = useSelector((state) => state.dock);
  const table = useSelector((state) => state.table);
  const settings = useSettings();
  const dispatch = useDispatch();
  const s3StoragesState = useSelector((state) => state.s3Storage);
  const s3BucketsState = useSelector((state) => state.s3Bucket);

  // const videoStreamingRegions = useMemo(() => {
  //   return settings.videoStreamRegions ? settings.videoStreamRegions.map(item => ({
  //     id: item.id,
  //     name: item.name,
  //     value: item.name
  //   })) : [];
  // }, [settings]);

  useEffect(() => {
    setData({
      dockName: table.row.dockName,
      //videoStreamingRegion: table.row.videoStreamingRegion,
      dataStreamingRegion: table.row.dataStreamingRegion,
      // unitOfMeasurement: table.row.unitOfMeasurement,
      storageId: table.row.defaultDockBucket?.storageId,
      defaultBucketId: table.row.defaultDockBucket?.Id || table.row.defaultDockBucket?.id,
    });

    setOldDockName(table.row.dockName);
    setAlerts({});
  }, [table.row]);

  useEffect(() => {
    console.log(data.dockName, DockName);
    if (DebouncedBusinessName && oldDockName !== DockName) {
      dispatch(name({ status: "" }));
      //dispatch(checkName(DockName));
    }
  }, [DebouncedBusinessName]);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAlerts({});

    if (key === "dockName") {
      setIcons({ ...icons, DockName: "default" });
      setDockName(value);
      setValidate(true);
    }
    if (key === "videoStreamingRegion" || key === "dataStreamingRegion" || key === "unitOfMeasurement" || key === "defaultBucketId") {
      setValidate(true);
    }

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };

  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const d3StorageChangeHandler = (event) => {
    setSelectedS3Storage(s3Storages?.find((item) => item.id === event.target.value) || null);
    changeHandler(event);
  };

  const submitHandler = () => {
    if (table.row !== undefined) dispatch(updateDock(data, table.row.id));
  };

  useEffect(() => {
    if (dock.name.status === "failed") {
      setAlerts({
        ...alerts,
        DockName: {
          alert: true,
          type: "error",
          message: dock.name.message,
        },
      });
    } else if (dock.name.status === "forbidden") {
      setAlerts({
        ...alerts,
        DockName: {
          alert: true,
          type: "error",
          message: dock.name.message.error,
        },
      });
    } else if (dock.name.status === "validation") {
      let validations = {};
      dock.name.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
    setIcons({ ...icons, DockName: dock.name.status });
    setValidate(
      // dock.name.status === "success" &&
      //data?.videoStreamingRegion !== undefined &&
      data?.dataStreamingRegion !== undefined
      // data?.unitOfMeasurement !== undefined
    );
  }, [dock.name]);

  useEffect(() => {
    if (dock.update.status === "validation") {
      let validations = {};
      dock.update.errors.forEach((item) => {
        validations[item.propertyName.replace(/\s+/g, "-").toLowerCase()] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    } else if (dock.update.status === "success") {
      onConfirm();
    }
  }, [dock.update]);

  useEffect(() => {
    onOpen?.();
  }, [data]);

  useEffect(() => {
    dispatch(s3StorageGetList());
  }, []);

  useEffect(() => {
    if (Array.isArray(s3StoragesState?.list?.data?.amazonStorages)) {
      setS3Storages(s3StoragesState.list.data.amazonStorages || []);
      setS3Buckets([]);

      console.log({data, s3StoragesState});

      if(data.storageId) {
        setSelectedS3Storage(s3StoragesState.list.data.amazonStorages.find((item) => item.id === data.storageId) || null);
      }
    }
    else {
      setS3Storages([]);
    }
  }, [s3StoragesState?.list]);

  useEffect(() => {
    if(selectedS3Storage && s3Storages) {
      dispatch(s3BucketGetList(selectedS3Storage.id));
    }
  }, [s3Storages, selectedS3Storage]);

  useEffect(() => {
    console.log('selectedS3Storage', selectedS3Storage);
    console.log('s3BucketsState', s3BucketsState);

    if (Array.isArray(s3BucketsState?.list?.data?.buckets)) {
      setS3Buckets(s3BucketsState.list.data.buckets || []);
    }
    else {
      setS3Buckets([]);
    }
  }, [s3BucketsState?.list, selectedS3Storage]);

  return (
    <div className="edit-dock">
      <InputBox
        title="Dock Name"
        name="dockName"
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.DockName}
        value={data.dockName}
        index={0}
        background="white"
        width="100%"
        icon={icons.DockName}
      />
      {/* <Select
        isRequired={required.includes("videoStreamingRegion")}
        title="Video Stream Region"
        name="videoStreamingRegion"
        items={videoStreamingRegions}
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.videoStreamingRegion}
        value={data.videoStreamingRegion}
        current={data.videoStreamingRegion}
        index={1}
        background="white"
        width="100%"
      /> */}
      <Select
        isRequired={required.includes("dataStreamingRegion")}
        title="Data Stream Region"
        name="dataStreamingRegion"
        items={dataStreamingRegions}
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.dataStreamingRegion}
        value={data.dataStreamingRegion}
        current={data.dataStreamingRegion}
        index={1}
        background="white"
        width="100%"
      />
      <Select 
        isRequired={required.includes("storageId")}
        title="S3 Storage"
        name="storageId"
        items={s3Storages?.map((item) => ({
          id: item.id,
          name: item.name,
          value: item.id,
        }))}
        onChange={d3StorageChangeHandler}
        onFocus={focusHandler}
        status={alerts.storageId}
        value={data.storageId}
        current={data.storageId}
        index={2}
        background="white"
        width="100%"
      />
      {selectedS3Storage ? (
        <>
          <Select 
            isRequired={required.includes("defaultBucketId")}
            title="S3 Bucket"
            name="defaultBucketId"
            items={s3Buckets?.map((item) => ({
              id: item.id,
              name: item.name,
              value: item.id,
            }))}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.defaultBucketId}
            value={data.defaultBucketId}
            current={data.defaultBucketId}
            index={3}
            background="white"
            width="100%"
          />
          <Alert 
            type="warning" 
            message="Please note that storage settings may take up to 20 minutes to apply. If you need the settings to take effect immediately, please reboot the dock." 
            style={{ marginBottom: "1em" }}
          />
        </>
      ) : null}
      <Button
        value="Update"
        color="#2699fb"
        width="30%"
        disabled={!validate}
        onClick={submitHandler}
      />
    </div>
  );
}

export default EditDock;
