function FocusCenter({ className, size }) {
  return (
    <svg
      className={className}
      width={`${size}em`}
      height={`${size}em`}
      viewBox="0 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeOpacity="0.7"
      >
        <g
          id="focus-center"
          transform="translate(-0.974050, -0.974050)"
          stroke="#FFFFFF"
          strokeWidth="0.15"
        >
          <path
            d="M6.21428571,1.1875 L8.78571429,3.8125"
            id="Path-3"
            transform="translate(7.500000, 2.500000) rotate(89.000000) translate(-7.500000, -2.500000) "
          ></path>
          <path
            d="M1.21428571,6.1875 L3.78571429,8.8125"
            id="Path-3"
            transform="translate(2.500000, 7.500000) rotate(89.000000) translate(-2.500000, -7.500000) "
          ></path>
          <path
            d="M1.21428571,1.1875 L3.78571429,3.8125"
            id="Path-3"
            transform="translate(2.500000, 2.500000) rotate(-1.000000) translate(-2.500000, -2.500000) "
          ></path>
          <path
            d="M6.21428571,6.1875 L8.78571429,8.8125"
            id="Path-3"
            transform="translate(7.500000, 7.500000) rotate(-1.000000) translate(-7.500000, -7.500000) "
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default FocusCenter;
