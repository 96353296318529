import { useTheme } from "../../helper/ThemeProvider";
import "./ApplicationAction.sass";

function ApplicationAction({ onClick, title, icon, disabled }) {
  const { currentContext } = useTheme();
  return (
    <div
      className="application-action"
      style={{ background: disabled ? "#cacaca" :currentContext.theme.color  }}
      onClick={onClick}
    >
      {icon}
      <button disabled={disabled}>{title}</button>
    </div>
  );
}

export default ApplicationAction;
