import "./EditUser.sass";
import EditableTextBox from "../../../../../ui/EditableTextBox/EditableTextBox";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../../../../../ui/Button/Button";
import { updateUser, update } from "../UserManagementSlice";

function EditUser({ onOpen, onConfirm }) {
  const table = useSelector((state) => state.table);
  const user = useSelector((state) => state.user);
  const [userObject, setUserObject] = useState({});
  const [changeDetector, setChangeDetector] = useState(false);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    emailAddress: {
      name: "emailAddress",
      title: "Username/Email",
      type: "text",
    },
    firstName: {
      name: "firstName",
      title: "First Name",
      type: "text",
    },
    lastName: {
      name: "lastName",
      title: "Last Name",
      type: "text",
    },
    displayName: {
      name: "displayName",
      title: "Display Name",
      type: "text",
    },
  });


  useEffect(() => {
    if (user.update.status === "success") {
      dispatch(update({ status: "" }));
      setChangeDetector(false);
      onConfirm();
    } else if (user.update.status === "validation") {
      console.log("validation fails");
    }
  }, [user.update]);

  useEffect(() => {
    const row = table.row;
    var temp = {};
    Object.entries(row).map((item) => {
      var key = item[0];
      var value = item[1];
      if (data[key] !== undefined)
        temp = {
          ...temp,
          [key]: {
            ...data[key],
            value:
              value !== null
                ? typeof value !== "object"
                  ? value
                  : value.props.label
                : "",
          },
        };
    });
    console.log(temp);
    setData({ ...temp });
  }, [table.row]);

  useEffect(() => {
    onOpen?.();
  }, [data]);

  useEffect(() => {
    setChangeDetector(false);
    Object.entries(userObject).forEach((item) => {
      if (
        Object.entries(data).filter((x) => {
          return x[0] === item[0];
        })[0][1].value !== item[1]
      ) {
        setChangeDetector(true);
        return;
      }
    });
  }, [userObject]);

  const changeHandler = (event) => {
    var key = event.target.name;
    var value = event.target.value;
    if (value.toString() !== "") {
      setUserObject({ ...userObject, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = userObject;
      setUserObject({ ...withoutSecond });
    }
  };

  const submitHandler = () => {
    if (table.row !== undefined) dispatch(updateUser(userObject, table.row.id));
  };

  return (
    <div className="edit-user-form">
      <div className="edit-user-container">
        <div className="edit-user-section">
          <EditableTextBox
            model={data.emailAddress}
            width="50%"
            onChange={changeHandler}
          />
        </div>

        <div className="edit-user-section">
          <EditableTextBox
            model={data.firstName}
            width="48%"
            onChange={changeHandler}
          />
          <EditableTextBox
            model={data.lastName}
            width="48%"
            onChange={changeHandler}
          />
        </div>
        <div className="edit-user-section">
          <EditableTextBox
            model={data.displayName}
            width="50%"
            onChange={changeHandler}
          />
        </div>
        {changeDetector && (
          <Button
            value="Save"
            color="#2699fb"
            width="30%"
            className="save-button"
            onClick={submitHandler}
          />
        )}
      </div>
    </div>
  );
}

export default EditUser;
