import { useCameraSource } from "../../../helper/CameraSourceProvider";
import ZoomControl from "../ZoomControl/ZoomControl";

import CameraActions from "./CameraActions/CameraActions";
import "./DroneCameraManager.sass";

function DroneCameraManager() {
  const { isMainCamera } = useCameraSource();
  return (
    isMainCamera && (
      <div className="drone-camera-manager">
        <CameraActions />
        <ZoomControl className="camera-zoom-control" />
      </div>
    )
  );
}

export default DroneCameraManager;
