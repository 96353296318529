import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';

const cameraTelemetriesContext = React.createContext({});

export const CameraTelemetriesContextProvider = ({ children }) => {
  const [activePanelName, setActivePanelName] = useState();

  return (
    <cameraTelemetriesContext.Provider value={{
      activePanelName,
      setActivePanelName
    }}>
      {children}
    </cameraTelemetriesContext.Provider>
  )
}

export const useCameraTelemetriesContext = () => useContext(cameraTelemetriesContext);