import { useEffect, useState } from "react";
import { MdDeleteForever, MdRefresh } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import AdminContentAreaContainer from "../../AdminContentAreaContainer/AdminContentAreaContainer";
import "./GroupManagement.sass";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import { getList, removeGroups } from "./GroupManagementSlice";
import CreateGroup from "./CreateGroup/CreateGroup";
import { FaUserEdit } from "react-icons/fa";
import EditGroup from "./EditGroup/EditGroup";
import GroupMembership from "./GroupMembership/GroupMembership";
import GroupOwnership from "./GroupOwnership/GroupOwnership";
import EditGroupOptions from "./EditGroupOptions/EditGroupOptions";

function GroupManagement() {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api.state);
  const group = useSelector((state) => state.group);
  const { privileges } = usePrivileges();
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined) {
      if (
        privileges.admins.filter((item) => item.id === "groups")[0] !==
        undefined
      )
        setPermissions([
          ...privileges.admins.filter((item) => item.id === "groups")[0]
            ?.privileges,
        ]);
    }
  }, [privileges.admins]);

  const toggleOperationManagerOff = () => {
    setShowOperationManager(false);
  };
  const toggleManagerOperationOn = () => {
    setShowOperationManager(true);
    setOperationManagerHandler(true);
  };

  const removeUsersGenerator = (data) => {
    dispatch(removeGroups({ ids: data.map((i) => i.id) }));
  };

  const refreshGenerator = () => {
    dispatch(getList());
  };

  useEffect(() => {
    if (api !== undefined) {
      dispatch(getList());
    }
  }, [api]);

  useEffect(() => {
    if (group?.list?.data !== undefined) {
      const groups = [];
      group.list.data.groups.map((item) =>
        groups.push({ ...item, active: false })
      );
      setRows({
        ...rows,
        values: [...groups],
      });
    }
  }, [group?.list]);

  const histories = [
    { title: "Home", link: "/admin/home" },
    { title: "Groups", link: "/admin/groups" },
  ];
  const [features, setFeatures] = useState([]);
  const [options, setOptions] = useState([]);

  const dataOptions = [
    {
      title: "Delete Group",
      icon: <MdDeleteForever />,
      name: "delete_groups",
      active: true,
    },
    {
      title: "Edit Group",
      icon: <FaUserEdit />,
      name: "edit_group",
      active: true,
    },
  ];

  const userOptions = [
    {
      title: "Create Group",
      icon: <AiOutlineUsergroupAdd />,
      name: "create_group",
      active: true,
    },
    {
      title: "Refresh",
      icon: <MdRefresh />,
      name: "refresh_groups",
      active: true,
    },
    {
      title: "Delete Group(s)",
      icon: <MdDeleteForever />,
      name: "delete_groups",
      type: "dependent",
      active: false,
    },
    {
      title: "Edit Group",
      icon: <FaUserEdit />,
      name: "edit_group",
      type: "dependent",
      active: false,
    },
  ];

  const userFeatures = [
    {
      id: "create_group",
      title: "Create Group",
      description: "You can create a group ",
      icon: <AiOutlineUsergroupAdd />,
      color: "#2699fb",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "create_group",
          title: "Create",
          active: true,
          visible: true,
          locked: false,
          tags: ["group/create"],
          activity: (
            <CreateGroup
              onConfirm={toggleOperationManagerOff}
              onOpen={toggleManagerOperationOn}
            />
          ),
        },
      ],
    },
    {
      id: "delete_groups",
      title: "Delete Group(s)",
      confirm: "Are you sure for deleting selected group?",
      description: "You can delete a group under your company account",
      type: "dialog",
      actionGenerator: removeUsersGenerator,
    },
    {
      id: "refresh_groups",
      type: "no-op",
      actionGenerator: refreshGenerator,
    },
    {
      id: "edit_group",
      title: "Edit Group",
      description: "You can edit a group under your company account",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_group",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["group/update", "group/membership", "group/assignMembers", "group/ownership", "group/assignOwners"],
          activity: (
            <EditGroupOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
    {
      id: "manage_memberships",
      title: "Edit Group",
      description: "You can edit a group under your company account",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_group",
          title: "Group Information",
          active: false,
          visible: true,
          locked: false,
          tags: ["group/update"],
          activity: (
            <EditGroup
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
        {
          name: "manage_memberships",
          title: "Group Membership",
          active: true,
          visible: true,
          locked: false,
          tags: ["group/membership", "group/assignMembers"],
          activity: (
            <GroupMembership
              onConfirm={toggleOperationManagerOff}
              onOpen={toggleManagerOperationOn}
            />
          ),
        },
        {
          name: "manage_ownerships",
          title: "Group OwnerShips",
          active: false,
          visible: true,
          locked: false,
          tags: ["group/ownership", "group/assignOwners"],
          activity: (
            <GroupOwnership
              onConfirm={toggleOperationManagerOff}
              onOpen={toggleManagerOperationOn}
            />
          ),
        },
      ],
    },
    {
      id: "manage_ownerships",
      title: "Edit Group",
      description: "You can edit a group under your company account",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_group",
          title: "Group Information",
          active: false,
          visible: true,
          locked: false,
          tags: ["group/update"],
          activity: (
            <EditGroup
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
        {
          name: "manage_memberships",
          title: "Group Membership",
          active: false,
          visible: true,
          locked: false,
          tags: ["group/membership", "group/assignMembers"],
          activity: (
            <GroupMembership
              onConfirm={toggleOperationManagerOff}
              onOpen={toggleManagerOperationOn}
            />
          ),
        },
        {
          name: "manage_ownerships",
          title: "Group OwnerShips",
          active: true,
          visible: true,
          locked: false,
          tags: ["group/ownership", "group/assignOwners"],
          activity: (
            <GroupOwnership
              onConfirm={toggleOperationManagerOff}
              onOpen={toggleManagerOperationOn}
            />
          ),
        },
      ],
    },
  ];

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Name", value: "name", status: true },
      { title: "Description", value: "description", status: true },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    if (group.create.status === "success") {
      dispatch(getList());
    }
  }, [group.create]);

  useEffect(() => {
    if (group.update.status === "success") {
      dispatch(getList());
    }
  }, [group.update]);

  useEffect(() => {
    if (group.remove.status === "success") {
      dispatch(getList());
    }
  }, [group.remove]);

  useEffect(() => {
    setFeatures([
      ...userFeatures
        .filter((feature) => permissions.includes(feature.id))
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        })),
    ]);
    setOptions([
      ...userOptions.filter((option) => permissions.includes(option.name)),
    ]);
    setRows({
      ...rows,
      options: dataOptions.filter((option) =>
        permissions.includes(option.name)
      ),
    });
  }, [permissions]);

  const [currentFeature, setCurrentFeature] = useState();
  const [currentFeatureLabel, setCurrentFeatureLabel] = useState("edit_group");
  const onFeatureChanged = (name) => {
    if (["create_group", "refresh_groups", "delete_groups"].includes(name))
      return;
    setCurrentFeatureLabel(name);
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === name)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
  };
  const onRowChange = () => {
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === currentFeatureLabel)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
    toggleManagerOperationOn();
  };

  return (
    <AdminContentAreaContainer
      histories={histories}
      title="Group Management"
      data={rows}
      features={[...features]}
      operations={[...options]}
      table={{
        multiSelect: true,
        tags: ["group/list", "group/remove"],
        show: true,
      }}
      operationManager={showOperationManager}
      managerHandler={operationManagerHandler}
      onRowChange={onRowChange}
      currentFeature={currentFeature}
      onCurrentFeatureChanged={onFeatureChanged}
    />
  );
}

export default GroupManagement;
