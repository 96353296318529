import "./SecurityApp.sass";
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { generateId } from "src/helper/utils";
import classNames from "classnames";
import { useAccount, usePrivileges } from "src/helper/AccountStateProvider";
import { useRef } from "react";
import { HubEventName, HubName } from "src/helper/HubConnection";
import SideListPanel from "src/hci/organism/SideListPanel/SideListPanel";
import { Tabs } from "@radix-ui/themes";
import SideListItem from "src/hci/organism/SideListPanel/SideListItem/SideListItem";
import { RiFolderOpenLine, RiRouteFill } from "react-icons/ri";
import { FiBox, FiUsers } from "react-icons/fi";
import useDockList, { DeviceOnlineStatus } from "src/helper/useDockList";
import { getUnitList, selectedUnitId } from "./SecurityAppSlice";
import WebsocketDockHubProvider from "src/components/Dashboard/Components/WebsocketDockHubProvider/WebsocketDockHubProvider";
import DockHubProvider from "src/components/Dashboard/Components/DockHubProvider/DockHubProvider";
import { setIdentity } from "src/components/Dashboard/Components/WebsocketHandler/DockIdentitySlice";
import { dockConnectionManager } from "src/helper/HubConnectionManager";
import { headerBreadcrumb } from "src/store/UiSlice";
import { BiPlus } from "react-icons/bi";
import Dialog from "src/hci/common/Dialog/Dialog";
import DeviceMap, { DeviceMapMarkerTypes } from "src/components/DeviceMap/DeviceMap";
import UnitListCard from "./UnitListCard/UnitListCard";
import UnitMissionsSideList from "./UnitMissionsSideList/UnitMissionsSideList";
import AddUnit from "./AddUnit/AddUnit";
import { setContext } from "src/redux/ducks/appContextSlice";
import { PersonMarkerTypes } from "src/components/DeviceMap/MapPersonMarker/MapPersonMarker";
import useUnitList from "./common/useUnitList";
import { SelectGroupDialog } from "./SelectGroupDialog/SelectGroupDialog";
import MissionTracking from "./MissionTracking/MissionTracking";

function SecurityApp() {
  const { privileges } = usePrivileges();
  const dispatch = useDispatch();
  const {data: unitItems, loading: loadingUnits, refresh: refreshUnitList} = useUnitList();
  const [selectedListItemsArray, setSelectedListItemsArray] = useState([]);
  const selectedListItemId = useMemo(() => selectedListItemsArray[0], [selectedListItemsArray]);
  //const selectedListItemData = useMemo(() => docks?.find(item => item.id === selectedListItemId), [selectedListItemId, docks]);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const [currMissionTracking, setCurrMissionTracking] = useState();
  const activeMissionTrackingId = useSelector(state => state.securityApp.activeMissionTrackingId);
  const { hub: frontendHub } = useFrontendHub();

  const accessStatus = useMemo(() => ({
    groupOwnership: privileges?.applications?.find(item => item.id === "groups")?.privileges.some(item => item === "groups_app_ownership"),
    groupMembership: privileges?.applications?.find(item => item.id === "groups")?.privileges.some(item => item === "groups_app_membership"),
  }), [privileges]);

  const listItems = loadingUnits ? [] : unitItems.map(item => {
    const data = {
      ...item,
    };

    return {
      id: item.id,
      content: (
        <SideListItem data={data}>
          <UnitListCard data={data} />
        </SideListItem>
      )
    };
  });

  const panelActions = [
    {
      title: "Create Unit",
      content: <BiPlus />,
      handler: () => {
        setShowAddPanel(true);
      }
    }
  ];

  const handleListItemClick = id => {
    setSelectedListItemsArray([id]);
    dispatch(selectedUnitId(id));
  }

  const handleUnitAddSuccess = () => {
    setShowAddPanel(false);
    refreshUnitList();
  }

  const handleMissionListItemClick = (data) => {
    setCurrMissionTracking(data);
    console.log(data);
  }

  const handleMissionCreateSuccess = (missionData) => {
    setCurrMissionTracking(missionData);
  }

  useEffect(() => {
    dispatch(headerBreadcrumb([
      {title: "Dashboard", link: "/dashboard"},
      {title: "Security App", link: "/dashboard/security-app"}
    ]))

    dispatch(
      setContext({
        theme: { color: "#2699fb", name: "security", highlight: "#00b93811" },
      })
    );
  }, []);

  return (
    <div className="security-app">
      {showAddPanel ? (
        <AddUnit onCancel={() => setShowAddPanel(false)} onSuccess={handleUnitAddSuccess} />
      ) : (
        <SideListPanel
          panelTitle="Operation Units"
          listItems={listItems}
          actions={panelActions}
          activeIds={selectedListItemsArray}
          loading={loadingUnits}
          onListItemClick={handleListItemClick}
        />
      )}
      <div className="stage">
        {selectedListItemId ? (
          <Tabs.Root className="app-options security-app-tabs" defaultValue="mission" orientation="vertical">
            <Tabs.List className="tabs-list">
              <Tabs.Trigger value="mission">
                <RiRouteFill />
              </Tabs.Trigger>
            </Tabs.List>
            <div className="tabs-content">
              <Tabs.Content value="mission">
                {currMissionTracking ? (
                  <MissionTracking onClose={() => setCurrMissionTracking(null)} missionData={currMissionTracking} />
                ) : (
                  <UnitMissionsSideList 
                    id={selectedListItemId} 
                    onListItemClick={handleMissionListItemClick} 
                    onMissionCreateSuccess={handleMissionCreateSuccess} 
                  />
                )}
              </Tabs.Content>
            </div>
          </Tabs.Root>
        ) : null}
        {!activeMissionTrackingId && (
          <DeviceMap />
        )}
      </div>
    </div>
  );
}

export default SecurityApp;
