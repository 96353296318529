import { ScrollArea } from 'src/hci/common/ScrollArea/ScrollArea'
import './DeviceMediaList.sass'
import { MdFormatListBulleted, MdOutlineFileDownload, MdOutlineGridView, MdVideoFile } from 'react-icons/md'
import Loading from 'src/hci/common/Loading/Loading';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getS3BucketSessionCredentials, setS3BucketSessionCredentials } from 'src/services/device/DeviceServiceSlice';
import { S3Client, ListObjectsV2Command, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { FaFile, FaFileImage, FaFileVideo, FaFolder } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import moment from 'moment';
import prettyBytes from 'pretty-bytes';
import { RiArrowGoBackFill, RiFoldersLine } from 'react-icons/ri';
import { TbClock } from 'react-icons/tb';
import classNames from 'classnames';
import EmptyMediaList from './EmptyMediaList/EmptyMediaList';

const DEVICE_MEDIA_LIST_STATUS = {
  GETTING_CREDENTIALS: 'Getting Credentials',
  GETTING_MEDIA_LIST: 'Getting Media List',
  CREDENTIAL_FAILED: 'Failed to get credentials',
  MEDIA_LIST_FAILED: 'Failed to get media list',
  MEDIA_LIST_RECEIVED: 'Media List Received',
}

const DEVICE_MEDIA_LIST_STYLE = {
  THUMBNAIL: 'thumbnail-list',
  DETAILED: 'detailed-list',
}

function DeviceMediaList({ bucketId, bucketName, bucketRegion, onPanelHeaderLeftChange, onPanelHeaderToolsChange, path }) {
  const [isLoading, setIsLoading] = useState(true);
  const [mediaList, setMediaList] = useState([]);
  const [status, setStatus] = useState(DEVICE_MEDIA_LIST_STATUS.GETTING_CREDENTIALS);
  const [currDir, setCurrDir] = useState(path);
  const [message, setMessage] = useState();
  const [listStyle, setListStyle] = useState(DEVICE_MEDIA_LIST_STYLE.DETAILED);
  const dispatch = useDispatch();
  const s3CredentialsRequest = useSelector(state => state.deviceService.s3BucketSessionCredentialRequest);
  const s3Credentials = useSelector(state => state.deviceService.s3BucketSessionCredentials?.[bucketId]?.credential);
  const s3Client = useRef(null);

  const getMediaIconByFileName = (fileName) => {
    const ext = fileName.split('.').pop();

    if(!fileName?.includes('.')) return <FaFolder className="icon" />
    if(fileName === '..') return <RiArrowGoBackFill className="icon" />

    // videos
    if (['mp4', 'avi', 'mov', 'mkv'].includes(ext)) {
      return <FaFileVideo className="icon" />
    }
    
    // images
    if (['jpg', 'jpeg', 'png', 'gif'].includes(ext)) {
      return <FaFileImage className="icon" />
    }

    return <FaFile className="icon" />
  }

  const getFileName = (fileName) => {
    if(fileName === '..') return 'Return to parent folder';
    return fileName.split('/').pop() || fileName;
  }

  const getBucketCredentials = useCallback(() => {
    if(!s3Credentials || Date.now() - s3Credentials.requestTimestamp > 1800000) {
      console.log('📂 Getting S3 bucket session credentials');
      setIsLoading(true);
      dispatch(getS3BucketSessionCredentials(bucketId));

      return false;
    }

    return true;
  }, [bucketId, dispatch, s3Credentials]);

  const getFilesList = (dir = currDir) => {
    if(!s3Client.current || !getBucketCredentials()) return;
    
    setStatus(DEVICE_MEDIA_LIST_STATUS.GETTING_MEDIA_LIST);
    setIsLoading(true);
    
    dir = dir && !dir.endsWith('/') ? dir + '/' : dir;
    
    const command = new ListObjectsV2Command({
      Bucket: bucketName,
      Prefix: dir,
    });
    
    s3Client.current
      .send(command)
      .then((data) => {
        if(data.Contents) {
          const list = data.Contents.filter(item => item.Key !== dir);

          //sort by folder first and then by modification time
          list.sort((a, b) => {
            if(a.Key.endsWith('/') && !b.Key.endsWith('/')) return -1;
            if(!a.Key.endsWith('/') && b.Key.endsWith('/')) return 1;
            if(a.LastModified > b.LastModified) return -1;
            if(a.LastModified < b.LastModified) return 1;
            return 0;
          });

          if(dir && dir !== path && dir !== path + '/') {
            list.unshift({ Key: '..' });
          }

          setMediaList(list);
          setStatus(null);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('📂 Media list from s3 bucket error', error);
        setStatus(DEVICE_MEDIA_LIST_STATUS.MEDIA_LIST_FAILED);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const getObjectUrl = async (key, withDownalodHeader = false) => {
    if(!key || !s3Client.current) return;

    try {
      const command = new GetObjectCommand({
        Bucket: bucketName,
        Key: key,
        ...(withDownalodHeader ? { ResponseContentDisposition: `attachment; filename="${key.split('/').pop()}"` } : {}),
      });
  
      const url = await getSignedUrl(s3Client.current, command, { expiresIn: 3600 });
      return url;
    } catch (error) {
      console.error('📂 Error generating URL:', error);
      return null;
    }
  }

  const fetchMediaFile = async (key) => {
    console.log('📂 Download media file', key);
    if(!s3Client.current) return;

    try {
      const command = new GetObjectCommand({ Bucket: bucketName, Key: key });
      const response = await s3Client.current.send(command);
      
      const chunks = [];
      for await (const chunk of response.Body) {
        chunks.push(chunk);
      }
      const blob = new Blob(chunks);
      return blob;
    } catch (error) {
      console.error('📂 Error downloading file:', error);
    }
  }

  function downloadUrl(url, filename) {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
  
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  const fetchAndSaveMediaFile = async (key) => {
    if(!key) return;

    setMessage('Starting file download...');
    setIsLoading(true);

    const blob = await fetchMediaFile(key);

    setMessage(null);
    setIsLoading(false);
    
    if(blob)
      saveAs(blob, key.split('/').pop());
  }

  const openFileInNewTab = async (key) => {
    const url = await getObjectUrl(key);
    if (url) {
      window.open(url, '_blank');
    } else {
      console.error('📂 Failed to generate URL for key:', key);
    }
  }

  const downloadMediaFileByUrl = (key) => {
    if(!key) return;

    setMessage('Starting file download...');
    setIsLoading(true);

    getObjectUrl(key, true).then(url => {
      downloadUrl(url, key.split('/').pop());
      setTimeout(() => {
        setMessage(null);
        setIsLoading(false);
      }, 1000);
    });
  }

  const changeDir = (dir) => {
    setCurrDir(dir);
    getFilesList(dir);
  }

  const handleMediaItemClick = (key) => {
    console.log('📂 Media item clicked', key);

    if(key?.endsWith('/')) {
      changeDir(key);
    }
    else if(key === '..') {
      const dir = currDir.split('/').slice(0, -2).join('/');
      changeDir(dir);
    }
    else {
      openFileInNewTab(key);
    }
  }

  const handleListStyleChange = () => {
    setListStyle(curr => curr === DEVICE_MEDIA_LIST_STYLE.DETAILED ? DEVICE_MEDIA_LIST_STYLE.THUMBNAIL : DEVICE_MEDIA_LIST_STYLE.DETAILED);
  }

  useEffect(() => {
    if (!bucketId) return;
    getBucketCredentials();
  }, [bucketId]);

  useEffect(() => {
    if (!s3CredentialsRequest) return;

    if(s3CredentialsRequest.status === 'success') {
      dispatch(setS3BucketSessionCredentials({ bucketId, data: {
        ...s3CredentialsRequest.data,
        requestTimestamp: Date.now(),
      }}));
    }
    else {
      setStatus(DEVICE_MEDIA_LIST_STATUS.CREDENTIAL_FAILED);
      setIsLoading(false);
    }
  }, [s3CredentialsRequest]);

  useEffect(() => {
    if (!s3Credentials) return;
    console.log('📂 S3 credentials received', s3Credentials);

    // Get media list
    const clientConfig = { 
      region: bucketRegion,
      credentials: {
        accessKeyId: s3Credentials.access_key_id,
        secretAccessKey: s3Credentials.access_key_secret,
        sessionToken: s3Credentials.security_token,
      }
    }

    s3Client.current = new S3Client(clientConfig);
    
    setTimeout(() => {
      getFilesList();
    }, 500);
  }, [s3Credentials]);

  useEffect(() => {
    if(onPanelHeaderLeftChange) {
      onPanelHeaderLeftChange(
        <>
          {currDir ? (
            <div style={{ borderRadius: '1em', background: 'var(--ir-color-surface-2)', padding: '0.1em 0.9em', fontSize: '0.9em' }}>{currDir}</div>
          ) : null}
        </>
      );
    }

    if(onPanelHeaderToolsChange) {
      onPanelHeaderToolsChange(
        <>
          {listStyle === DEVICE_MEDIA_LIST_STYLE.THUMBNAIL ? 
            <MdFormatListBulleted onClick={handleListStyleChange} tilte="Detailed List" /> : 
            <MdOutlineGridView onClick={handleListStyleChange} title="Thumbnail List" />}
        </>
      );
    }
  }, [currDir, listStyle]);

  return (
    <div className="device-media-list">
      <div className="container">
        {isLoading ? (
          <Loading withBackground={false} message={message || (status ? status + '...' : null)} />
        ) : mediaList?.length ? (
          <ScrollArea className="media-list-scroll">
            <div className={classNames("media-list", listStyle)}>
              {mediaList.map((media, index) => (
                <div className="media-item" key={media.Key} onClick={() => handleMediaItemClick(media.Key)}>
                  <div className="thumbnail">
                    {getMediaIconByFileName(media.Key)}
                  </div>
                  <div className="info">
                    <div className="name">{getFileName(media.Key)}</div>
                    {media.Key !== '..' ? (
                      <>
                        <div className="time">
                          <TbClock className="icon" />
                          {moment(media.LastModified).format('MMM DD, YYYY HH:mm:ss')}
                        </div>
                        <div className="size">
                          <RiFoldersLine className="icon" />
                          {media.Size ? prettyBytes(media.Size) : 'Folder'}
                        </div>
                      </>
                    ) : null}
                  </div>
                  {media.Key !== '..' ? (
                    <div className="tools">
                      <MdOutlineFileDownload title="Download File" onClick={(e) => {
                        e.stopPropagation();
                        downloadMediaFileByUrl(media.Key);
                      }} />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </ScrollArea>
        ) : (
          <EmptyMediaList />
        )}
      </div>
    </div>
  )
}

export default DeviceMediaList