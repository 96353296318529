import "./LogoMini.sass";
import mini from "../../assets/img/logo-mini.svg";
import { Link } from "react-router-dom";

function LogoMini() {
  return (
    <Link className="logo-mini" to="/">
      <img src={mini} alt="IRobotics Logo" />
    </Link>
  );
}

export default LogoMini;
