import { useTheme } from "src/helper/ThemeProvider";
import "./Avatar.sass";

function Avatar({ image, width, height, isBordered, isCovered }) {
  const { currentContext } = useTheme();
  return isCovered ? (
    <div
      className={"avatar"}
      style={{
        backgroundImage: `url("${image}")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        border: `${isBordered ? `1px solid ${currentContext.theme.color}` : "none"
          }`,
      }}
    />
  ) : (
    <div
      className="avatar"
      style={{
        width: width,
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        border: `${isBordered ? `1px solid ${currentContext.theme.color}` : "none"
          }`,
      }}
    >
      {image}
    </div>
  );
}
Avatar.defaultProps = {
  width: "3em",
  height: "3em",
  isCovered: false,
};

export default Avatar;
