import "./ApplicationOperationManager.sass";
import { GrClose } from "react-icons/gr";
import SlideLeft from "../../../../ui/Animation/SlideLeft/SlideLeft";
import PropTypes from "prop-types";
import TabletSize from "../../../../ui/TabletSize/TabletSize";
import DesktopSize from "../../../../ui/DesktopSize/DesktopSize";
import { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import ApplicationsOperationStep from "../../../../ui/ApplicationsOperationStep/ApplicationsOperationStep";
import ApplicationOperationStepMenuItem from "../../../../ui/ApplicationOperationStepMenuItem/ApplicationOperationStepMenuItem";
import LabeledIcon from "../../../../ui/LabeledIcon/LabeledIcon";

function ApplicationOperationManager({
  selectedFeature,
  isOpen,
  close,
  toggle,
}) {
  const [steps, setSteps] = useState(
    selectedFeature?.steps && [...selectedFeature.steps]
  );
  const [feature, setFeature] = useState(selectedFeature);
  const [activeStep, setActiveStep] = useState();

  useEffect(() => {
    setFeature(selectedFeature);
    if (
      activeStep === undefined &&
      selectedFeature?.steps !== undefined &&
      selectedFeature.steps.length > 0
    ) {
      setActiveStep({ ...selectedFeature.steps.find((step) => step.active) });
    }
  }, [selectedFeature]);

  const stepActivator = (name) => {
    setActiveStep({ ...steps.find((step) => step.name === name) });
  };

  useEffect(() => {
    setSteps([
      ...selectedFeature.steps.map((step) => ({
        ...step,
        active: step.name === activeStep?.name,
      })),
    ]);
  }, [activeStep]);

  return (
    <SlideLeft
      className="application-operation-manager"
      isOpen={isOpen}
      toggle={toggle}
    >
      <>
        <div className="application-operation-manager-header">
          <div className="application-operation-manager-head">
            <div className="application-operation-manager-info">
              <span className="application-operation-manager-title">
                <LabeledIcon label={feature?.title} icon={feature?.indicator} />
              </span>
              <span className="application-operation-manager-subtitle">
                {feature?.subtitle}
              </span>
            </div>
            <div className="application-operation-manager-toolbar">
              <IoMdRefresh
                color="#455060"
                className="application-operation-manager-refresh"
                fontSize="1.65em"
              />
              <GrClose
                color="#455060"
                className="application-operation-manager-close"
                onClick={close}
                fontSize="1.5em"
              />
            </div>
          </div>

          <p className="application-operation-manager-description">
            {feature?.description}
          </p>
        </div>

        <TabletSize>
          <ul className="application-operation-manager-steps">
            {steps &&
              steps.map(
                (step, index) =>
                  step.visible && (
                      <ApplicationsOperationStep
                        icon={step.icon}
                        isActive={step.active}
                        name={step.name}
                        toggleActive={stepActivator}
                        title={step.title}
                        isLocked={step.locked}
                        tags={step.tags}
                        controls={step.controls}
                        actions={step.actions}
                        key={index}
                      >
                        {step.activity}
                      </ApplicationsOperationStep>
                  )
              )}
          </ul>
        </TabletSize>
        <DesktopSize>
          <div className="application-operation-manager-desktop">
            <ul className="application-operation-manager-desktop-menu">
              {steps &&
                steps.map(
                  (step, index) =>
                    step.visible && (
                        <ApplicationOperationStepMenuItem
                          isActive={step.active}
                          isLocked={step.isLocked}
                          name={step.name}
                          title={step.title}
                          toggleActive={stepActivator}
                          icon={step.icon}
                          key={index}
                        />
                    )
                )}
            </ul>
            <div className="application-operation-manager-desktop-area">
              {(activeStep?.controls !== undefined ||
                activeStep?.actions !== undefined) && (
                <div className="application-operation-manager-desktop-control-area">
                  {activeStep?.controls !== undefined && (
                    <ul className="application-operation-step-controls">
                      {activeStep.controls.map((control, index) => (
                        <li
                          className="application-operation-step-control"
                          key={index}
                          onClick={() => {
                            control.action();
                          }}
                        >
                          {control.component}
                        </li>
                      ))}
                    </ul>
                  )}
                  {activeStep?.actions !== undefined && (
                    <ul className="application-operation-step-actions">
                      {activeStep.actions.map((action, index) => (
                        <li
                          className="application-operation-step-action"
                          key={index}
                        >
                          {action.component}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}

              {activeStep?.activity}
            </div>
          </div>
        </DesktopSize>
      </>
    </SlideLeft>
  );
}

ApplicationOperationManager.propTypes = {
  feature: PropTypes.object.isRequired,
};
ApplicationOperationManager.defaultProps = {
  feature: { title: "", description: "", color: "#2699fb" },
};

export default ApplicationOperationManager;
