import classNames from 'classnames';
import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';
import { PayloadIndexes } from 'src/helper/HubConnection';
import React from 'react'
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CameraFocusIndicator from '../CameraFocusIndicator/CameraFocusIndicator';
import './CameraFocusBoundary.sass'

function CameraFocusBoundary({ className, width, height }) {
  const { hub, groups, deviceId } = useOnboardHub();
  const boundaryRef = useRef();
  const indicatorTimerRef = useRef();
  const focusTargetX = useSelector((state) => state.flight.camera?.focusTarget?.x);
  const focusTargetY = useSelector((state) => state.flight.camera?.focusTarget?.y);
  const [lastFocusPoint, setLastFocusTarget] = useState();
  const [showIndicator, setShowIndicator] = useState(false);
  const { activeCameraPayload } = useDeviceInfo();

  const scale = (x, oldMin, oldMax, newMin, newMax) => {
    const oldRange = oldMax - oldMin;
    const newRange = newMax - newMin;
    return newMin + (x - oldMin) * newRange / oldRange;
  };

  const sendFocusTarget = (point) => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      camSetting: {
        payloadIndex: activeCameraPayload?.payloadIndex,
        focusTarget: point,
      }
    });
  }

  const handleOnBoundaryClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const newPoint = {
      x: scale(x, 0, e.target?.offsetWidth, 0, 1),
      y: scale(y, 0, e.target?.offsetHeight, 0, 1),
    };

    sendFocusTarget(newPoint);
  }

  useEffect(() => {
    boundaryRef.current.style.width = `${width}px`;
    boundaryRef.current.style.height = `${height}px`;
  }, [width, height]);

  useEffect(() => {
    if (!focusTargetX || !focusTargetY) return;

    const newFocusPoint = {
      x: scale(focusTargetX, 0, 1, 0, 100),
      y: scale(focusTargetY, 0, 1, 0, 100),
    };

    setLastFocusTarget(newFocusPoint);
    setShowIndicator(true);

    clearTimeout(indicatorTimerRef.current);
    indicatorTimerRef.current = setTimeout(() => setShowIndicator(false), 1000);
  }, [focusTargetX, focusTargetY]);

  return (
    <div className={classNames('camera-focus-boundary', className)} onClick={handleOnBoundaryClick} ref={boundaryRef}>
      <CameraFocusIndicator
        active={showIndicator && lastFocusPoint}
        top={`${lastFocusPoint?.y}%`}
        left={`${lastFocusPoint?.x}%`}
      />
    </div>
  )
}

export default CameraFocusBoundary