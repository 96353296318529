import "./Storage.sass";
import Card from "src/ui/Card/Card";
import { Link } from "react-router-dom";
import { FaRegImages } from "react-icons/fa";
import { MdOutlineLocationOn } from "react-icons/md";

function Storage({ storage }) {
  return (
    <div className="storage-cart">
      <Card>
        <Link to={`/dashboard/storage-app/container/${storage.id}`}>
          <div className="content">
            <div className="image">
              <FaRegImages className="icon" />
            </div>
            <div className="main">
              <div className="info">
                <div className="name">{storage.name}</div>
                <div className="location">
                  <MdOutlineLocationOn className="icon" />
                  <div className="value">{storage.location}</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </Card>
    </div>
  )
}

export default Storage;
