import classNames from 'classnames';
import './SideListItem.sass'
import Card from 'src/hci/common/Card/Card';

function SideListItem({data, title, className, render, children}) {
  if(!data) return null;

  return (
    <div className={classNames("side-list-item", className)}>
      {children || (
        <Card className="default-layout">
          <div className="title">{title || data.title || data.name || data.model}</div>
          {data.isOnline !== undefined && (
            <div className={classNames("status", { 'status-online': data.isOnline })}>
              <div className="indicator"></div>
              <div className="value">{data.isOnline ? 'online' : 'offline'}</div>
            </div>
          )}
          {typeof render === 'function' ? render(data) : null}
        </Card>
      )}
    </div>
  )
}

export default SideListItem