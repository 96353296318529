import { useEffect, useState } from "react";
import SmallAlert from "../SmallAlert/SmallAlert";
import "./InputBox.sass";
import { useTheme } from "../../helper/ThemeProvider";
import { LoadingIcon, TickIcon } from "../Icons/Icons";
function InputBox({
  title,
  isRequired,
  placeholder,
  width,
  name,
  type,
  status,
  onFocus,
  onBlur,
  onChange,
  isReadOnly,
  value,
  index,
  icon,
  background,
  className,
  style
}) {
  const { currentContext } = useTheme();

  let icons = {
    pending: (
      <LoadingIcon
        size="1.5em"
        className="input-box-icon"
        color={currentContext.theme.color}
      />
    ),
    success: (
      <TickIcon className="input-box-icon" color={currentContext.theme.color} />
    ),
    default: undefined,
  };
  const [focused, setFocused] = useState(false);
  const [val, setVal] = useState("");
  const changeHandler = (event) => {
    setVal(event.target.value);
  };
  useEffect(() => {
    setVal(value !== undefined ? value : "");
  }, [value]);
  useEffect(() => {
    if (status?.alert === true) setFocused(false);
  }, [status]);
  const focusHandler = (event) => {
    setFocused(true);
    onFocus && onFocus(event);
  };
  const blurHandler = (event) => {
    setFocused(false);
    onBlur && onBlur(event);
  };
  return (
    <div className="input-box" style={{ width: width }}>
      {title !== undefined && (
        <label htmlFor="input">
          {title} {isRequired ? "*" : ""}
        </label>
      )}
      {icons[icon]}
      <input
        className={`${status?.alert === true ? "input-alert-" + status?.type : ""
          } ${className}`}
        id={title?.replace(/\s+/g, "-").toLowerCase()}
        name={name}
        type={type}
        placeholder={placeholder}
        style={{
          color:
            status?.alert === true ? "#ff7c7c" : '',
          borderColor: focused
            ? currentContext.theme.color
            : status?.alert === true
              ? "#ff7c7c"
              : "",
          ...style,
        }}
        onFocus={focusHandler}
        onBlur={blurHandler}
        onChange={changeHandler}
        readOnly={isReadOnly}
        onInput={onChange}
        value={val !== null ? val : ""}
        autoComplete="off"
        data-index={index}
      />
      {status !== undefined && (
        <SmallAlert
          alert={status.alert}
          alertType={status.type}
          message={status.message}
          ratio={1.25}
        />
      )}
    </div>
  );
}
InputBox.defaultProps = {
  icon: "default",
  background: "#f4f7fc",
  onFocus: () => { },
};
export default InputBox;
