import "./AddUserRoles.sass";
import Form from "../../../../../ui/Form/Form";
import { useEffect, useState } from "react";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { getRolesList } from "../../RoleManagement/RoleManagementSlice";
import { setOperation } from "../../../AdminOperationManager/OperationStepSlice";
import { setUserModel } from "../UserManagementSlice";
import Accordion from "src/ui/Accordion/Accordion";

function UserRolesList({ roles, onChecked }) {
  const [allChecked, setAllChecked] = useState(false);
  console.log('roles-list-component', roles);

  const getListItem = (role, index, onCheckedHandler = onChecked) => (
    <li className="role-item" key={index}>
      <CheckBox
        title={role.description}
        name={role.id}
        text={role.name}
        checked={role.check}
        onChecked={onCheckedHandler}
      />
    </li>
  );

  const checkAllHandler = (event) => {
    const checked = event.target.checked;

    roles?.forEach(item => onChecked({
      target: {
        name: item.id,
        checked: checked,
      }
    }))
  }

  useEffect(() => {
    roles?.find(i => !i.check) !== undefined ? setAllChecked(false) : setAllChecked(true);
  }, [roles]);

  return (
    <ul className="role-items">
      {getListItem({ name: 'Select All', check: allChecked }, 'all', checkAllHandler)}
      {roles?.map?.((role, index) => getListItem(role, index))}
    </ul>
  )
}

function AddUserRoles({ onOpen, onConfirm, allowEmpty = false }) {
  const [localRoles, setLocalRoles] = useState({
    application: [],
    administration: [],
    reader: [],
  });
  const api = useSelector((state) => state.api.state);
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const categorizeUserRoles = (roles) => {
    const application = !roles ? [] : roles.filter(role => role.category === 'application');
    const administration = !roles ? [] : roles.filter(role => role.category?.includes('admin') && (role.name?.includes('Admin') || role.name?.includes('admin')));
    const reader = !roles ? [] : roles.filter(role => role.category?.includes('admin') && (role.name?.includes('Reader') || role.name?.includes('reader')));

    return {
      application,
      administration,
      reader,
    };
  }

  const getCheckedItemsCount = (items) => items?.filter(i => i.check).length || 0;

  const checkboxCheckChangedHandler = (event) => {
    const targetName = event.target.name;
    const targetChecked = event.target.checked;

    setLocalRoles(currLocalRoles => {
      const newLocalRoles = {
        administration: currLocalRoles.administration.map((role) => ({
          ...role,
          check:
            role.id === targetName ? targetChecked : role.check,
        })),
        application: currLocalRoles.application.map((role) => ({
          ...role,
          check:
            role.id === targetName ? targetChecked : role.check,
        })),
        reader: currLocalRoles.reader.map((role) => ({
          ...role,
          check:
            role.id === targetName ? targetChecked : role.check,
        })),
      };

      return newLocalRoles;
    });
  };

  useEffect(() => {
    if (api !== undefined) dispatch(getRolesList());
  }, [api]);

  useEffect(() => {
    if (role.list.status === "success")
      if (role.list.data !== undefined) {
        const tempRoles = [
          ...role.list.data.roles.map((item) => ({
            ...item,
            check: false,
          })),
        ];
        setLocalRoles(categorizeUserRoles(tempRoles));
      }
  }, [role.list]);

  useEffect(() => {
    setData([
      ...Object.values(localRoles).flat()
        .filter((role) => role.check)
        .map((item) => ({ id: item.id, category: item.category })),
    ]);
  }, [localRoles]);

  useEffect(() => {
    onOpen();
    console.log(data);
  }, [data]);

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(setUserModel({ ...user.model, Roles: [...data] }));
    dispatch(setOperation("user_summary"));
  };

  return (
    <div className="manage-user-roles">
      <Form
        data={{}}
        required={[]}
        validate={allowEmpty || data.length > 0}
        submit={{
          title: "Confirm",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        className="manage-roles-container"
      >
        <p className="manage-roles-description">
          As an administrator, you can change permissions to let the right people access the data they need data while restricting others. you be able to assign/edit roles for each user.
        </p>
        <Accordion className="roles-accordion" items={[
          {
            title: `Applications (${getCheckedItemsCount(localRoles['application'])})`,
            content: <UserRolesList roles={localRoles['application']} onChecked={checkboxCheckChangedHandler} />,
            isOpen: true,
          },
          {
            title: `Administration (${getCheckedItemsCount(localRoles['administration'])})`,
            content: <UserRolesList roles={localRoles['administration']} onChecked={checkboxCheckChangedHandler} />,
          },
          {
            title: `Reader (${getCheckedItemsCount(localRoles['reader'])})`,
            content: <UserRolesList roles={localRoles['reader']} onChecked={checkboxCheckChangedHandler} />,
          }
        ]} />
      </Form>
    </div>
  );
}

export default AddUserRoles;
