import { useEffect, useState } from "react";
import { FaUserEdit, FaUserPlus } from "react-icons/fa";
import { MdDeleteForever, MdGroupAdd, MdRefresh } from "react-icons/md";
import { RiEditCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import AdminContentAreaContainer from "../../AdminContentAreaContainer/AdminContentAreaContainer";
import CreateCustomer from "./CreateCustomer/CreateCustomer";
import EditCustomer from "./EditCustomer/EditCustomer";
import CustomerDetails from "./CustomerDetails/CustomerDetails";
import "./CustomerManagement.sass";
import { getList, create, removeCustomers } from "./CustomerManagementSlice";
import EditCustomerOptions from "./EditCustomerOptions/EditCustomerOptions";

function CustomerManagement() {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api.state);
  const customer = useSelector((state) => state.customer);
  const { privileges } = usePrivileges();
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined) {
      if (
        privileges.admins.filter((item) => item.id === "customers")[0] !==
        undefined
      )
        setPermissions([
          ...privileges.admins.filter((item) => item.id === "customers")[0]
            ?.privileges,
        ]);
    }
  }, [privileges.admins]);

  const toggleOperationManagerOff = () => {
    setShowOperationManager(false);
  };
  const toggleManagerOperationOn = () => {
    setShowOperationManager(true);
    setOperationManagerHandler(true);
  };

  const removeCustomersGenerator = (data) => {
    dispatch(removeCustomers({ ids: data.map((i) => i.id) }));
  };
  const refreshGenerator = () => {
    dispatch(getList());
  };

  useEffect(() => {
    if (api !== undefined) dispatch(getList());
  }, [api]);

  useEffect(() => {
    if (customer.list.data !== undefined) {
      const customers = [];
      customer.list.data.customers?.map((item) =>
        customers.push({ ...item, active: false })
      );
      setRows({
        ...rows,
        values: [...customers],
      });
    }
  }, [customer.list]);

  useEffect(() => {
    if (customer.remove.status === "success") {
      dispatch(getList());
    }
  }, [customer.remove]);

  useEffect(() => {
    if (customer.create.status === "success") {
      dispatch(getList());
      dispatch(create({ status: "" }));
    }
  }, [customer.create]);

  useEffect(() => {
    if (customer.update.status === "success") {
      dispatch(getList());
    }
  }, [customer.update]);

  const histories = [
    { title: "Home", link: "/admin/home" },
    { title: "Customers", link: "/admin/customers" },
  ];

  const [features, setFeatures] = useState([]);
  const [options, setOptions] = useState([]);

  const dataOptions = [
    {
      title: "Delete Customer",
      icon: <MdDeleteForever />,
      name: "delete_customer",
      except: "account",
      type: "dependent",
      active: false,
    },
    {
      title: "Edit Customer",
      icon: <RiEditCircleLine />,
      name: "edit_customer",
      active: true,
    },
  ];
  const userFeatures = [
    {
      id: "create_customer",
      title: "Add Customer",
      description: "You can create a customer under your company account",
      icon: <FaUserPlus />,
      color: "#2699fb",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "create_customer",
          title: "Create Customer",
          active: true,
          visible: true,
          locked: true,
          tags: ["customer/create"],
          activity: (
            <CreateCustomer
              onConfirm={toggleOperationManagerOff}
              onOpen={toggleManagerOperationOn}
            />
          ),
        },
      ],
    },
    {
      id: "delete_customer",
      title: "Delete Customer",
      confirm: "Are you sure for deleting selected customer?",
      description: "You can delete a customer under your company account",
      type: "dialog",
      actionGenerator: removeCustomersGenerator,
    },
    {
      id: "list_customers",
      type: "no-op",
      actionGenerator: refreshGenerator,
    },
    {
      id: "edit_customer",
      title: "Edit Customer",
      description: "You can edit a customer under your company account",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_customer",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["customer/update"],
          activity: (
            <EditCustomerOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
    {
      id: "customer_details",
      title: "Edit Customer",
      description: "You can edit a customer under your company account",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_customer",
          title: "Edit Customer",
          active: false,
          visible: true,
          locked: true,
          tags: ["customer/update"],
          activity: (
            <EditCustomer
              onConfirm={toggleOperationManagerOff}
              onOpen={toggleManagerOperationOn}
            />
          ),
        },
        {
          name: "customer_details",
          title: "Optional Details",
          active: true,
          visible: true,
          locked: false,
          tags: ["customer/update"],
          activity: (
            <CustomerDetails
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
  ];
  const userOptions = [
    {
      title: "Add Customer",
      icon: <MdGroupAdd />,
      name: "create_customer",
      active: true,
    },
    {
      title: "Refresh",
      icon: <MdRefresh />,
      name: "list_customers",
      active: true,
    },
    {
      title: "Delete Customer(s)",
      icon: <MdDeleteForever />,
      name: "delete_customer",
      type: "dependent",
      except: "account",
      active: false,
    },
    {
      title: "Edit Customer",
      icon: <RiEditCircleLine />,
      name: "edit_customer",
      type: "dependent",
      singular: true,
      active: true,
    },
  ];

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Business Name", value: "businessName", status: true },
      { title: "Business Number", value: "businessNumber", status: false },
      { title: "Registration Date", value: "creationDate", status: false },
      { title: "Email", value: "emailAddress", status: true },
      { title: "Address", value: "postalAddress", status: false },
      { title: "Region", value: "region", status: false },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    setFeatures([
      ...userFeatures
        .filter((feature) => permissions.includes(feature.id))
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        })),
    ]);
    setOptions([
      ...userOptions.filter((option) => permissions.includes(option.name)),
    ]);
    setRows({
      ...rows,
      options: dataOptions.filter((option) =>
        permissions.includes(option.name)
      ),
    });
  }, [permissions]);

  const [currentFeature, setCurrentFeature] = useState();
  const [currentFeatureLabel, setCurrentFeatureLabel] =
    useState("edit_customer");
  const onFeatureChanged = (name) => {
    if (["create_customer", "list_customers", "delete_customer"].includes(name))
      return;
    setCurrentFeatureLabel(name);
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === name)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
  };
  const onRowChange = () => {
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === currentFeatureLabel)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
    toggleManagerOperationOn();
  };

  return (
    <AdminContentAreaContainer
      histories={histories}
      title="Customer Management"
      data={rows}
      features={[...features]}
      operations={[...options]}
      table={{
        multiSelect: true,
        tags: ["customer/list", "customer/remove"],
        show: true,
      }}
      operationManager={showOperationManager}
      managerHandler={operationManagerHandler}
      onRowChange={onRowChange}
      currentFeature={currentFeature}
      onCurrentFeatureChanged={onFeatureChanged}
    />
  );
}

export default CustomerManagement;
