import { useEffect, useState, useMemo } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import CircleAvatar from "src/ui/CircleAvatar/CircleAvatar";
import Form from "src/ui/Form/Form";
import { getUserGroups } from "src/ui/GroupApp/GroupAppSlice";
import LabeledButton from "src/ui/LabeledButton/LabeledButton";
import SearchableMultiSelect from "src/ui/SearchableMultiSelect/SearchableMultiSelect";
import "./SelectGroupForm.sass";
import { toColor } from "src/helper/utils";

function SelectGroupForm({ onNewGroup, onSelectConfirm, reloadTrigger }) {
  const [validate, setValidate] = useState(false);
  const groupApp = useSelector((state) => state.groupApp);
  const dispatch = useDispatch();
  const [entities, setEntities] = useState([]);
  const api = useSelector((state) => state.api);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selected, setSelected] = useState([]);

  const submitHandler = (event) => {
    event.preventDefault();
    onSelectConfirm(selected);
  };

  const selectionChangeHandler = (items) => {
    if (Array.isArray(items) && items.length > 0) {
      setValidate(true);
      setSelected(items);
    } else {
      setValidate(false);
      setSelected([]);
    }
  };

  const [items, setItems] = useState([]);

  const filteredItems = useMemo(() => {
    if (!searchKeyword) return items;
    return items.filter((item) =>
      item.name.toUpperCase().startsWith(searchKeyword.toUpperCase())
    );
  }, [items, searchKeyword]);

  useEffect(() => {
    if (api.token) dispatch(getUserGroups());
  }, [api, reloadTrigger]);

  const toggleSelect = (item) => {
    setItems([
      ...items.map((s) => ({
        ...s,
        select: s.name === item.name ? !s.select : s.select,
      })),
    ]);
  };

  useEffect(() => {
    if (groupApp.list?.data?.groups !== undefined) {
      setItems([
        ...groupApp.list.data.groups.map((item) => ({
          id: item.id,
          name: item.name,
          icon: (
            <CircleAvatar
              name={item.name.substring(0, 2).toUpperCase()}
              fontSize=".9em"
              size="2.25"
              color={`#${toColor(item.name)}`}
            />
          ),
        })),
      ]);
    }
  }, [groupApp.list?.data?.groups]);

  return (
    <div className="select-group-form">
      <div className="select-group-form-description">
        Please select your target groups:
      </div>
      <Form
        submit={{
          title: "Continue",
          width: "35%",
          action: submitHandler,
          className: "generate-code-button",
        }}
        data={{}}
        required={[]}
        validate={validate}
      >
        <LabeledButton
          icon={<FiPlusCircle />}
          title="Add New Group"
          onClick={onNewGroup}
        />
        <SearchableMultiSelect
          entity="Groups"
          entities={entities}
          items={filteredItems}
          state={groupApp?.groupName?.status}
          alert={alert.Name}
          loading={groupApp?.groupName?.status === "pending"}
          onSearch={(value) => setSearchKeyword(value)}
          onSelectionChange={selectionChangeHandler}
          inProgress={
            groupApp?.list?.status === "pending" ||
            groupApp.create.status === "pending"
          }
          onSelectItem={toggleSelect}
          alwaysShow={true}
        />
      </Form>
    </div>
  );
}

export default SelectGroupForm;
