import './AddDockDialog.sass';
import Dialog from 'src/hci/common/Dialog/Dialog';
import SelectGroupForm from '../../OnboardDeviceApp/DeviceOnboarding/SelectGroupForm/SelectGroupForm';
import { useRef, useState, useEffect } from 'react';
import CreateNewGroup from '../../OnboardDeviceApp/DeviceOnboarding/CreateNewGroup/CreateNewGroup';
import CountdownTimer from 'src/ui/CountdownTimer/CountdownTimer';
import LabeledButton from 'src/ui/LabeledButton/LabeledButton';
import classNames from 'classnames';
import Button from 'src/ui/Button/Button';
import { HubEventName } from 'src/helper/HubConnection';
import { GenerateDockPassKey, getList, passkey } from '../DockManagerAppSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useFrontendHub } from 'src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider';
import Loading from 'src/ui/Loading/Loading';

const OnboardingSteps = {
  SELECT_GROUP: 0,
  AUTHENTICATION: 1,
  REGISTRATION: 2,
  DONE: 4,
}

function AddDockDialog() {
  const serverMessageHandlerIds = useRef([]);
  const { hub: frontendHub } = useFrontendHub();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isNewGroupDialogActive, setIsNewGroupDialogActive] = useState(false);
  const [groupsChangeTimestamp, setGroupsChangeTimestamp] = useState(Date.now());
  const [currStep, setCurrStep] = useState(OnboardingSteps.SELECT_GROUP);
  const [phoneNumber, setPhoneNumber] = useState('+1422765876');
  const [expireTime, setExpireTime] = useState(0);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [resetCounter, setResetCounter] = useState(false);
  const passkeyState = useSelector((state) => state.dockManager.passkey);

  const handleCreateGroupResult = () => {
    setIsNewGroupDialogActive(false);
    setGroupsChangeTimestamp(Date.now());
  }

  const handleGenerateNewCode = (groupIds) => {
    const ids = groupIds || selectedGroups;
    dispatch(GenerateDockPassKey({ groups: ids }, ids));
    setLoading(true);
  }

  const handleCountdownFinish = () => {
    //setCurrStep(OnboardingSteps.SELECT_GROUP)
  }

  const serverMessageHandlers = [
    {
      name: HubEventName.ONBOARDING_STARTED,
      handler: (data) => {
        const deviceId = data.userId;
        setCurrStep(OnboardingSteps.AUTHENTICATION);

        serverMessageHandlerIds.current.push(
          frontendHub.subscribeServerMessages([
            {
              identity: deviceId,
              name: HubEventName.CONNECTED,
              handler: () => {
                setCurrStep(OnboardingSteps.REGISTRATION);
              },
            },
          ])
        );
      },
    },
    {
      name: HubEventName.ONBOARDING_COMPLETED,
      handler: (data) => {
        setCurrStep(OnboardingSteps.DONE);
        dispatch(getList());
      },
    },
  ];

  useEffect(() => {
    frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);

    serverMessageHandlerIds.current = frontendHub?.subscribeServerMessages(
      serverMessageHandlers,
      "onboarding"
    );

    dispatch(passkey({ status: "" }));

    return () => {
      frontendHub?.unsubscribeServerMessages(serverMessageHandlerIds.current);
    };
  }, []);

  useEffect(() => {
    setLoading(false);
    console.log('status', passkeyState?.status)

    if (passkeyState?.status === "success") {
      setPhoneNumber(passkeyState?.data?.mobilePhone);
      setExpireTime(passkeyState?.data?.expireTime);
      setResetCounter((currentValue) => {
        return !currentValue;
      });
    }
  }, [passkeyState]);

  return (
    <div className="add-dock-dialog">
      {currStep === OnboardingSteps.SELECT_GROUP && (
        <>
          <SelectGroupForm
            onNewGroup={() => {
              setIsNewGroupDialogActive(true);
            }}
            onSelectConfirm={(groups) => {
              const groupIds = groups.map((item) => item.id)
              setSelectedGroups(groupIds);
              setCurrStep(OnboardingSteps.AUTHENTICATION);
              handleGenerateNewCode(groupIds);
            }}
            reloadTrigger={groupsChangeTimestamp}
          />
          <Dialog
            title="Configure Group" 
            open={isNewGroupDialogActive}
            onOpenChange={setIsNewGroupDialogActive}
          >
            <Dialog.Body>
              <CreateNewGroup onConfirm={handleCreateGroupResult} />
            </Dialog.Body>
          </Dialog>
        </>
      )}
      {currStep !== OnboardingSteps.SELECT_GROUP && (
        <>
          <div className={classNames("onboarding-info", {"disabled": currStep !== OnboardingSteps.AUTHENTICATION})}>
            <div>
              <span>
                Please input following configuration whitin DJI Pilot application. Remaining time until expire:{" "}
              </span>
              <CountdownTimer
                seconds={expireTime}
                reset={resetCounter}
                onFinish={handleCountdownFinish}
                className="otp-countdown"
              />
            </div>
            {/* <div>
              <span>Didn't got the code? </span>
              <LabeledButton
                className="otp-resend-button"
                title="click to resend"
                link={true}
                onClick={handleGenerateNewCode}
              />
            </div> */}
            <div>
              <strong>MQTT Gateway Address:</strong><br/>
              <span className="info-value">{ passkeyState?.data?.mqttGatewayAddress || '-' }</span>
              <br/><br/>
              <strong>MQTT Account: </strong><br/>
              <span className="info-value">{ passkeyState?.data?.mqttAccount || '-' }</span>
              <br/><br/>
              <strong>MQTT Password: </strong><br/>
              <span className="info-value">{ passkeyState?.data?.mqttPassword || '-' }</span>
            </div>
          </div>
          <div className="step-title">
            {currStep === OnboardingSteps.AUTHENTICATION && "Waiting for configuration entry..."}
            {currStep === OnboardingSteps.REGISTRATION && "Registering..."}
          </div>
        </>
      )}
      {currStep === OnboardingSteps.DONE && (
        <Dialog.Close asChild>
          <Button
            value="Done"
            width="100px"
            className="done-button"
          />
        </Dialog.Close>
      )}
      { loading ? <Loading /> : null }
    </div>
  )
}

export default AddDockDialog