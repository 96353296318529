import { useEffect, useState } from "react";
import { FaUserEdit, FaUserPlus } from "react-icons/fa";
import { MdDeleteForever, MdRefresh } from "react-icons/md";
import { RiEditCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import AdminContentAreaContainer from "../../AdminContentAreaContainer/AdminContentAreaContainer";
import RoleInformation from "./RoleInformation/RoleInformation";
import RoleAssignment from "./RoleAssignment/RoleAssignment";
import "./RoleManagement.sass";
import {
  getPermissionsList,
  getRolesList,
  removeRoles,
} from "./RoleManagementSlice";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import ManageRolePermissions from "./ManageRolePermissions/ManageRolePermissions";
import EditRoleOptions from "./EditRoleOptions/EditRoleOptions";

function RoleManagement() {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api.state);
  const role = useSelector((state) => state.role);
  const { privileges } = usePrivileges();
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined) {
      if (
        privileges.admins.filter((item) => item.id === "roles")[0] !== undefined
      )
        setPermissions([
          ...privileges.admins.filter((item) => item.id === "roles")[0]
            ?.privileges,
        ]);
    }
  }, [privileges.admins]);

  const toggleOperationManagerOff = () => {
    setShowOperationManager(false);
  };
  const toggleManagerOperationOn = () => {
    setShowOperationManager(true);
    setOperationManagerHandler(true);
  };

  const removeUsersGenerator = (data) => {
    dispatch(removeRoles({ ids: data.map((i) => i.id) }));
  };

  const refreshGenerator = () => {
    dispatch(getRolesList());
  };

  useEffect(() => {
    if (api !== undefined) {
      dispatch(getRolesList());
      dispatch(getPermissionsList());
    }
  }, [api]);

  useEffect(() => {
    if (role.list.data !== undefined) {
      const roles = [];
      role.list.data.roles.map((item) =>
        roles.push({ ...item, active: false })
      );
      setRows({
        ...rows,
        values: [...roles],
      });
    }
  }, [role.list]);

  const histories = [
    { title: "Home", link: "/admin/home" },
    { title: "Roles", link: "/admin/roles" },
  ];
  const [features, setFeatures] = useState([]);
  const [options, setOptions] = useState([]);

  const dataOptions = [
    {
      title: "Delete Role",
      icon: <MdDeleteForever />,
      name: "delete_role",
      type: "dependent",
      active: false,
    },
  ];

  const userOptions = [
    {
      title: "Refresh",
      icon: <MdRefresh />,
      name: "refresh_roles",
      active: true,
    },
    {
      title: "Delete Role(s)",
      icon: <MdDeleteForever />,
      name: "delete_role",
      type: "dependent",
      except: "account",
      active: false,
    },
    {
      title: "Role Information",
      icon: <RiEditCircleLine />,
      name: "role_info",
      type: "dependent",
      singular: true,
      active: true,
    },
  ];

  const userFeatures = [
    {
      id: "create_role",
      title: "Add Role",
      description: "You can create a role ",
      icon: <FaUserPlus />,
      color: "#2699fb",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [],
    },
    {
      id: "delete_role",
      title: "Delete Role",
      confirm: "Are you sure for deleting selected role?",
      description: "You can delete a role under your company account",
      type: "dialog",
      actionGenerator: removeUsersGenerator,
    },
    {
      id: "refresh_roles",
      type: "no-op",
      actionGenerator: refreshGenerator,
    },
    {
      id: "role_info",
      title: "Role Information",
      description:
        "Who should be assigned this role? Assign the Global reader role to users who need to do the following: View admin features and settings in all admin centers that the Global admin can view, but can't edit any settings",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "role_info",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          tags: ["role/permissions", "role/rolePermissions", "role/assignPermissions",],
          activity: (
            <EditRoleOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
  ];

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Name", value: "name", status: true },
      { title: "Category", value: "category", status: true },
      { title: "Description", value: "description", status: true },
      { title: "IsBuiltin", value: "isBuiltin", status: true },
      { title: "IsEnabled", value: "isEnabled", status: true },
      { title: "IsFrequentlyUsed", value: "isFrequentlyUsed", status: false },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    setFeatures([
      ...userFeatures
        .filter((feature) => permissions.includes(feature.id))
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        })),
    ]);
    setOptions([
      ...userOptions.filter((option) => permissions.includes(option.name)),
    ]);
    setRows({
      ...rows,
      options: dataOptions.filter((option) =>
        permissions.includes(option.name)
      ),
    });
  }, [permissions]);

  const [currentFeature, setCurrentFeature] = useState();
  const [currentFeatureLabel, setCurrentFeatureLabel] = useState("role_info");
  const onFeatureChanged = (name) => {
    if (["refresh_roles", "delete_role"].includes(name)) return;
    setCurrentFeatureLabel(name);
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === name)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
  };
  const onRowChange = () => {
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === currentFeatureLabel)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
    toggleManagerOperationOn();
  };

  return (
    <AdminContentAreaContainer
      histories={histories}
      title="Role Management"
      data={rows}
      features={[...features]}
      operations={[...options]}
      table={{
        multiSelect: false,
        tags: ["role/list", "role/remove"],
        show: true,
      }}
      operationManager={showOperationManager}
      managerHandler={operationManagerHandler}
      onRowChange={onRowChange}
      currentFeature={currentFeature}
      onCurrentFeatureChanged={onFeatureChanged}
    />
  );
}

export default RoleManagement;
