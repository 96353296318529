import { useEffect, useState } from "react";
import { MdFileDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../../ui/Button/Button";
import "./ContainerBlobInformation.sass";
import { setOperation } from "../../../../../AdminCenter/AdminOperationManager/OperationStepSlice";
import Loading from "../../../../../../ui/Loading/Loading";

function ContainerBlobInformation() {
  const container = useSelector((state) => state.containerApp);
  const [currentBlob, setCurrentBlob] = useState();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(setOperation("file_information"));
  }, []);
  useEffect(() => {
    if (container.blob !== undefined) {
      setCurrentBlob(container.blob);
    }
    return () => {
      setIsLoading(true);
    };
  }, [container?.blob]);
  const contentLoaded = () => {
    setIsLoading(false);
  };
  return (
    <div className="container-blob-information">
      <div className="container-blob-information-header">
        <div className="container-blob-information-title">
          <h3>{currentBlob?.name}</h3>
          <div className="container-blob-header-info">
            <span>{currentBlob?.type}</span> <span> - {currentBlob?.size}</span>
          </div>
        </div>
        <a href={currentBlob?.link}>
          <Button value="Download" icon={<MdFileDownload />} width="100%" />
        </a>
      </div>
      <div className="container-blob-information-body">
        <div className="container-blob-information-content">
          {isLoading && <Loading />}
          {["jpg", "png", "gif"]
            .map((ext) => currentBlob?.name.includes(ext))
            .reduce((result, item) => result || item, false) ? (
            <img
              className="container-blob-content-img"
              src={currentBlob?.link}
              alt="Img"
              onLoad={contentLoaded}
            />
          ) : (
            ["mp4", "avi"]
              .map((ext) => currentBlob?.name.includes(ext))
              .reduce((result, item) => result || item, false) && (
              <video
                className="container-blob-content-video"
                src={currentBlob?.link}
                onLoadedData={contentLoaded}
                controls
              />
            )
          )}
        </div>
        <ul className="container-blob-information-properties">
          <li>
            <span className="container-blob-properties-key">Last Modified</span>
            <span className="container-blob-properties-value">
              {currentBlob?.date}
            </span>
          </li>
          <li>
            <span className="container-blob-properties-key">Type</span>
            <span className="container-blob-properties-value">
              {currentBlob?.type}
            </span>
          </li>
          <li>
            <span className="container-blob-properties-key">Size</span>
            <span className="container-blob-properties-value">
              {currentBlob?.size}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ContainerBlobInformation;
