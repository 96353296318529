import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../ui/Loading/Loading";
import OperationHandler from "../../../../ui/OperationHandler/OperationHandler";
import "./ApplicationComponentContent.sass";

function ApplicationComponentContent({
  feature,
  toggleOperation,
  controls,
  actions,
  tools,
  children,
  onCloseOperationManager,
  tags,
  onStepChange,
}) {
  const [allControls, setAllControls] = useState(controls);
  const [allActions, setAllActions] = useState(actions);
  const [allTools, setAllTools] = useState(tools);
  const [operationHandler, setOperationHandler] = useState(false);
  const [operationHandlerOpen, setOperationHandlerOpen] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState({});
  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    setSelectedFeature(feature);
  }, [feature]);

  useEffect(() => {
    if (toggleOperation) {
      setOperationHandler(toggleOperation);
      setOperationHandlerOpen(toggleOperation);
    } else {
      setOperationHandlerOpen(toggleOperation);
    }
  }, [toggleOperation]);

  useEffect(() => {
    setAllControls(controls.filter((control) => control.active));
  }, [controls]);

  useEffect(() => {
    setAllActions(actions);
  }, [actions]);

  useEffect(() => {
    setAllTools(tools);
  }, [tools]);

  const toggleOperationHandlerOff = () => {
    setOperationHandler(false);
    onCloseOperationManager();
  };

  const toggleOperationHandlerOpen = () => {
    setOperationHandlerOpen(false);
  };

  return (
    <div className="application-component-content">
      {(allControls.length > 0 || allActions.length > 0) && (
        <div className="application-component-content-header">
          {allControls.length > 0 && (
            <ul className="application-component-content-controls">
              {allControls.map((control, index) => (
                <li
                  className="application-component-content-control"
                  key={index}
                  onClick={control?.action}
                >
                  {control.component}
                </li>
              ))}
            </ul>
          )}
          {allActions.length > 0 && (
            <ul className="application-component-content-actions">
              {allActions.map(
                (action, index) =>
                  action.active && (
                    <li
                      className="application-component-content-action"
                      key={index}
                    >
                      {action.component}
                    </li>
                  )
              )}
            </ul>
          )}
        </div>
      )}

      {allTools.length > 0 && (
        <ul className="application-component-content-tools">
          {allTools.map((tool, index) => (
            <li className="application-component-content-tool" key={index}>
              {tool.component}
            </li>
          ))}
        </ul>
      )}
      <div className="application-component-content-body">
        {children}
        {tags?.includes(loading.name) && loading.status && <Loading />}

        {operationHandler && (
          <OperationHandler
            selectedFeature={selectedFeature}
            toggle={toggleOperationHandlerOff}
            isOpen={operationHandlerOpen}
            close={toggleOperationHandlerOpen}
            onStepChange={onStepChange}
          />
        )}
      </div>
    </div>
  );
}

ApplicationComponentContent.defaultProps = {
  feature: {},
  controls: [],
  actions: [],
  tools: [],
  onStepChange: () => {},
};

export default ApplicationComponentContent;
