import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const CustomerManagementSlice = createSlice({
  name: "customer",
  initialState: {
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    model: {},
    list: [],
    permissions: [],
    name: { status: "" },
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    permissions(state, action) {
      const data = action.payload;
      return { ...state, permissions: data };
    },
    setModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
    name(state, action) {
      const data = action.payload;
      return { ...state, name: { ...data } };
    },
  },
});
export const { list, permissions, create, update, remove, setModel, name } =
  CustomerManagementSlice.actions;
export default CustomerManagementSlice.reducer;

export const getList = () =>
  apiCallBegan({
    url: "/customers",
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const createCustomer = (data) =>
  apiCallBegan({
    url: "/customers",
    method: "post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const removeCustomers = (data) =>
  apiCallBegan({
    url: "/customers",
    method: "delete",
    data,
    onSuccess: remove.type,
    onError: remove.type,
  });

export const updateCustomer = (data, id) =>
  apiCallBegan({
    url: "/customers/" + id,
    method: "patch",
    data,
    onSuccess: update.type,
    onError: update.type,
  });

export const checkName = (nameToCheck) =>
  apiCallBegan({
    url: "/customers/check?BusinessName=" + nameToCheck,
    method: "Get",
    onSpin: name.type,
    onSuccess: name.type,
    onError: name.type,
  });
