import "./LinkButton.sass";

function LinkButton({ title, onClick }) {
  return (
    <div className="link-button" onClick={onClick}>
      <span>{title}</span>
    </div>
  );
}

export default LinkButton;
