import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RadarObstacle = ({ className, size }) => {
  const RADIUS = 16;
  const SENSOR_RANGE = [1.5, 40];
  const WARNING_RANGE = [5.1, 10];
  const BRAKE_RANGE = [0.1, 5];
  const OBSTACLE_COLOR = '#77b681';
  const WARNING_COLOR = '#d3cc57';
  const BREAK_COLOR = '#663026';
  const VISIBLE_ANGLE_RANGE = {
    right: [55, 125],
    back: [145, 215],
    left: [235, 305],
    front: [325, 35]
  };

  const currSensorData = useSelector((state) => state?.flight?.obstacleSensors);
  const [sliceData, setSliceData] = useState([]);
  const sensorData = {
    "back": 0,
    "backHealth": true,
    "down": 75.1584701538086,
    "downHealth": true,
    "front": 10,
    "frontHealth": true,
    "left": 0,
    "leftHealth": true,
    "right": 5,
    "rightHealth": true,
    "up": 100,
    "upHealth": true
  };

  const getPieAngle = (angle) => {
    if(angle >= 0 && angle <= 90) return 90 - angle;
    if(angle > 90 && angle <= 180) return -(angle - 90);
    if(angle > 180 && angle <= 270) return 180 + (270 - angle);
    if(angle > 270 && angle <= 360) return 90 + (360 - angle);
  };

  const getPie = (startAngle, endAngle, fillPercent) => {
    return {startAngle, endAngle, fillPercent: fillPercent ?? 100, fillOpacity: 100, fillColor: OBSTACLE_COLOR};
  }

  useEffect(() => {
    const newData = [];
    

    if(sensorData.rightHealth) {
      // conditions
    }

    Object.entries(VISIBLE_ANGLE_RANGE).forEach(([direction, range]) => {
      const sensorHealth = sensorData[direction + 'Health'];
      const x = 20;
      const y = 10;

      const stepsCount = 5;
      const rangeStart = VISIBLE_ANGLE_RANGE[direction][0];
      const rangeDiff = VISIBLE_ANGLE_RANGE[direction][1] - rangeStart
      const stepDiff = rangeDiff / stepsCount;

      const dirX = x;
      const dirY = y;
      const angle = getPieAngle(coordinateToAngle(dirX, dirY));

      for(let step = 0; step < stepsCount; step++) {
        const targetStart = rangeStart + (step * stepDiff);
        const targetAngle = targetStart + (stepDiff / 2);
        const angleDepth = 80 - ((Math.abs(angle - targetAngle) / rangeDiff) * 80);
        console.log({angle, targetStart, targetAngle, angleDepth, rangeDiff})
        newData.push(getPie(targetStart, targetStart + stepDiff, angleDepth));
      }
    });

    setSliceData(newData);
  }, []);

  return (
    <svg 
      className={className}
      width={`${size}em`}
      height={`${size}em`}
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      viewBox={`0 0 ${RADIUS*2} ${RADIUS*2}`}
    >
      <style>
        {`.obstacles-container {}`}
      </style>
      <defs>
        {sliceData?.map((item, index) => (
          <radialGradient id={"pie-gradient-" + index} gradientUnits="userSpaceOnUse">
            <stop offset={(100 - item.fillPercent) + '%'} style={{stopColor: 'transparent'}} />
            <stop offset={(100 - item.fillPercent) + '%'} style={{
              stopColor: item.fillColor || 'orange', 
              stopOpacity: (item.fillOpacity ?? 90) + '%'
            }} />
          </radialGradient>
        ))}
      </defs>
      <g className="obstacles-container">
        {sliceData.map((item, index) => (
          <path d={getD(RADIUS, item.startAngle, item.endAngle)} className="pie" fill={`url(#pie-gradient-${index})`} />
        ))}
      </g>
    </svg>
  );
};

const coordinateToAngle = (x, y) => {
  const angleRad = Math.atan2(y, x);
  return angleRad * (180 / Math.PI);
}

const getD = (radius, startAngle, endAngle) => {
  const isCircle = endAngle - startAngle === 360;

  if (isCircle) endAngle--;

  const start = polarToCartesian(radius, startAngle);
  const end = polarToCartesian(radius, endAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;
  const d = [
    "M", start.x, start.y,
    "A", radius, radius, 0, largeArcFlag, 1, end.x, end.y,
  ];

  if (isCircle) d.push("Z");
  else d.push("L", radius, radius, "L", start.x, start.y, "Z");

  return d.join(" ");
}

const round = (n) => {
  return Math.round(n * 10) / 10;
}

const polarToCartesian = (radius, angleInDegrees) => {
  var radians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
      x: round(radius + (radius * Math.cos(radians))),
      y: round(radius + (radius * Math.sin(radians)))
  };
}

window.coordinateToAngle = coordinateToAngle;
export default RadarObstacle;
