/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import KeyValue from "../../../../ui/KeyValue/KeyValue";
import Button from "../../../../ui/Button/Button";
import "./Summary.sass";
import { useDispatch, useSelector } from "react-redux";
import {
  help,
  stepIndex,
} from "../../../AccountManagement/AccountManagementSlice";
import {
  accountMode,
  confirm,
  confirmAccount,
  create,
} from "../../AccountManagementSlice";
import { CheckAccountState } from "../../../../redux/ducks/accountStateSlice";

const Summary = () => {
  const account = useSelector((state) => state.account);
  const { accountId, region, message } = account.result;
  const model = useSelector((state) => state.account.model);
  const dispatch = useDispatch();
  useEffect(() => {
    if (account.confirm.status === "success") {
      dispatch(stepIndex(4));
      dispatch(CheckAccountState());
      dispatch(help("lunch"));
    } else if (account.confirm.status === "notFounded") {
      setTimeout(() => {
        dispatch(create({ status: "" }));
        dispatch(accountMode("Create"));
        previousStep();
      }, 5000);
    }
  }, [account.confirm]);
  const previousStep = (event) => {
    dispatch(stepIndex(2));
    dispatch(help("information"));
  };
  const nextStep = (event) => {
    dispatch(confirmAccount({ accountId, region }));
    dispatch(confirm({ status: "" }));
  };

  return (
    <div className="account-summary">
      <div className="account-summary-info">
        {model !== undefined && (
          <>
            <p className="account-summary-title">{message}</p>
            <div className="account-summary-container">
              {Object.keys(model).map(
                (key, index) =>
                  key !== undefined && (
                    <KeyValue
                      key={index}
                      attr={key}
                      value={model[key].toString()}
                      keyWidth="35%"
                      valueWidth="65%"
                    />
                  )
              )}
            </div>
          </>
        )}
      </div>
      <div className="account-summary-confirm">
        <Button
          value="Modify"
          width="45%"
          isSelectable={true}
          onClick={previousStep}
        />
        <Button
          color="#2699fb"
          value="Confirm"
          width="45%"
          onClick={nextStep}
        />
      </div>
    </div>
  );
};

export default Summary;
