import "./EntityName.sass";
import { FcCheckmark } from "react-icons/fc";

function EntityName({ item }) {
  return (
    <div className="entity-name-item">
      <div className="entity-name-item-container">
        <div className="entity-name-content">
          {item.icon}
          <div className="entity-name-side">
            <span className="entity-name-title">{item.title}</span>
            <small>{item.subtitle}</small>
          </div>
        </div>
        {item.active && <FcCheckmark />}
      </div>
    </div>
  );
}

export default EntityName;
