import './DockDeviceViewer.sass';
import CollapsablePanel from '../CollapsablePanel/CollapsablePanel';
import { RiFoggyLine, RiRainyLine } from 'react-icons/ri';
import { MdOutlineUploadFile } from 'react-icons/md';
import { TbCameraQuestion, TbNetwork, TbTemperature } from 'react-icons/tb';
import { FiWind } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'src/helper/AccountStateProvider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFrontendHub } from 'src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider';
import DockHubProvider, { useDockHub } from 'src/components/Dashboard/Components/DockHubProvider/DockHubProvider';
import { HubEventName, HubName } from 'src/helper/HubConnection';
import { dockConnectionManager, onboardConnectionManager } from 'src/helper/HubConnectionManager';
import { BsCloud, BsCloudRain, BsLightningCharge } from 'react-icons/bs';
import { IoCloudOffline, IoCloudOfflineOutline } from 'react-icons/io5';
import { DeviceOnlineStatus } from 'src/helper/useDockList';
import { useDockVideoStream } from 'src/helper/useDockVideoStream';
import VideoStreamView from 'src/ui/Drone/VideoStreamView/VideoStreamView';
import { StageElement } from '../MissionTracking';
import classNames from 'classnames';

function DockDeviceViewer({ deviceId, onSwap, expanded = false }) {
  const { account } = useAccount();
  const dispatch = useDispatch();
  const serverMessageHandlerIds = useRef([]);
  const groupMessageHandlerIds = useRef([]);
  const deviceInfo = useSelector((state) => state.deviceService.devices?.find(item => item.id === deviceId));
  const lastOsd = useSelector((state) => state.deviceService.deviceTelemetries?.[deviceId]);
  const isOnline = useMemo(() => deviceInfo?.onlineStatus === DeviceOnlineStatus.ONLINE, [deviceInfo?.onlineStatus]);
  const cameraVideoStream = useDockVideoStream(deviceId);

  const handleSwap = () => {
    onSwap?.(StageElement.DOCK);
  }

  const telemetries = [
    {
      name: 'Rain',
      value: `${lastOsd?.rainfall === 1 ? 'Raining' : 'No Rain'}`,
      icon: lastOsd?.rainfall ? <BsCloudRain /> : <BsCloud />
    },
    {
      name: 'Wind',
      value: `${lastOsd?.wind_speed ?? '-'} m/s`,
      icon: <FiWind />
    },
    {
      name: 'Temperature',
      value: `${lastOsd?.environment_temperature ?? '-'} °C`,
      icon: <TbTemperature />
    },
    {
      name: 'Upload Queue',
      value: `${lastOsd?.media_file_detail?.remain_upload ?? '-'}`,
      icon: <MdOutlineUploadFile />
    },
    {
      name: 'Voltage',
      value: `${lastOsd?.working_current ?? '-'} A`,
      icon: <BsLightningCharge />
    },
  ];

  const connectionMessage = (!isOnline ?
    'No dock connection.' :
    'Waiting for stream...'
  );

  const connectionMessageIcon = (!isOnline ?
    <IoCloudOfflineOutline className="icon" /> :
    <TbCameraQuestion className="icon" />
  );

  return (
    <div className={classNames("dock-device-viewer", {'expanded': expanded})}>
      <CollapsablePanel title="Docking Station" telemetries={telemetries}  onSwap={handleSwap} expanded={expanded}>
        <div className="stream-container">
          <div className="connection-message">
              {connectionMessageIcon}
              <div className="description">{connectionMessage}</div>
          </div>
          <VideoStreamView stream={cameraVideoStream} className="video-view" />
        </div>
      </CollapsablePanel>
    </div>
  )
}

export default DockDeviceViewer