import "./ApplicationOperationStepMenuItem.sass";
import { useEffect, useState } from "react";
import LabeledIcon from "src/ui/LabeledIcon/LabeledIcon";

function ApplicationOperationStepMenuItem({
  isActive,
  isLocked,
  toggleActive,
  name,
  title,
  icon,
}) {
  const [active, setActive] = useState(isActive);
  const [locked, setLocked] = useState(isLocked);

  const toggle = () => {
    if (!locked) {
      setActive(true);
      toggleActive(name);
    }
  };
  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  useEffect(() => {
    setLocked(isLocked);
  }, [isLocked]);
  return (
    <li
      className={`application-operation-step-menu-item${active ? " application-operation-step-menu-item-active" : ""
        }`}
      onClick={toggle}
    >
      <LabeledIcon icon={icon} label={title} />
    </li>
  );
}

export default ApplicationOperationStepMenuItem;
