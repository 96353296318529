import React, { useEffect, useState } from "react";
import "./SmallAlert.sass";

const SmallAlert = (props) => {
  const { alert, alertType, message, hideIcon } = props;
  const [height, setHeight] = useState();
  useEffect(() => {
    alert&&setHeight(document.querySelector(".span-alert").clientHeight);
  }, [height,alert]);
  return (
    <>
      {alert === true && (
        <div className="small-alert">
          {hideIcon !== true && (
            <svg
              className="small-alert-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                id="alert-icon"
                className={"alert-icon-" + alertType}
                data-name="Path 35"
                d="M9,0a9,9,0,1,0,9,9A9.026,9.026,0,0,0,9,0Zm1.238,13.725H7.763V11.588H10.35v2.137ZM10.35,5.4l-.675,4.95H8.325L7.65,5.4V4.275h2.813V5.4Z"
                fill="#2699fb"
              />
            </svg>
          )}

          <p
            className={`span-alert ${alertType}`}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      )}
    </>
  );
};
SmallAlert.defaultProps = {
  ratio:1.5
};
export default SmallAlert;
