import { activeCameraPayloadSerial } from 'src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice';
import React from 'react'
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { devicePayloadSpecs, payloadTypes } from './constants';
import { generateId } from './utils';

const DeviceInfoContext = React.createContext();

export const DeviceInfoProvider = ({ children }) => {
  const dispatch = useDispatch();
  const devicePayloads = useSelector(state => state.flight?.devicePayloads, (a, b) => JSON.stringify(a) === JSON.stringify(b));
  const activeCameraSerial = useSelector(state => state.flight?.activeCameraPayloadSerial);
  const [deviceInfo, setDeviceInfo] = useState([]);
  const [payloadInfo, setPayloadInfo] = useState([]);

  useEffect(() => {
    const payloads = devicePayloads?.map(item => {
      const payloadSpec = devicePayloadSpecs.find(i => String(i.model).toUpperCase() === String(item.model).toUpperCase());

      return ({
        ...item,
        ...payloadSpec,
        supported: !!payloadSpec,
        serial: generateId(),
      });
    });

    //TODO Send notification about unsupported payloads

    const supportedPayloads = payloads?.filter(i => i.supported);
    setPayloadInfo(supportedPayloads);
  }, [devicePayloads]);

  const cameraPayloads = useMemo(() => payloadInfo?.filter(i => i.type === payloadTypes.CAMERA), [payloadInfo]);
  const activeCameraPayload = useMemo(() => cameraPayloads?.find(i => i.payloadIndex === 1), [cameraPayloads]);

  useEffect(() => {
    if (!activeCameraSerial && cameraPayloads?.length)
      dispatch(activeCameraPayloadSerial(cameraPayloads[0].serial));
  }, [payloadInfo]);

  const info = useMemo(() => ({
    payloads: payloadInfo,
    device: deviceInfo,
    cameraPayloads,
    activeCameraPayload,
  }), [payloadInfo, deviceInfo, cameraPayloads, activeCameraPayload]);

  return (
    <DeviceInfoContext.Provider value={info}>
      {children}
    </DeviceInfoContext.Provider>
  )
}

export const useDeviceInfo = () => useContext(DeviceInfoContext);