import { useEffect, useState } from "react";
import "./DroneComponent.sass";

function DroneComponent({ item }) {
  const [component, setComponent] = useState({});
  useEffect(() => {
    setComponent(item);
  }, [item]);
  return (
    <div className="drone-component">
      <span>{component.left}</span>
      <div className="drone-component-info">
        <small>{component.top}</small>
        <strong>{component.value}</strong>
      </div>
    </div>
  );
}

export default DroneComponent;
