import { useEffect, useState } from "react";
import { useTheme } from "../../helper/ThemeProvider";
import "./OptionSelector.sass";

function OptionSelector({ options, selectHandler }) {
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems([...options]);
  }, [options]);

  const { currentContext } = useTheme();

  const selectChangeHandler = (option) => {
    selectHandler(option);
    setItems([
      ...items.map((item) => ({
        ...item,
        active: item.value === option.value,
      })),
    ]);
  };
  return (
    <div className="option-selector">
      <ul className="option-selector-items">
        {items.map((option, index) => (
          <li
            className={`option-selector-item${
              option.active ? " option-selector-item-active" : ""
            }`}
            style={{
              background: `${
                option.active ? currentContext.theme.color : "white"
              }`,
              border: `solid 1px ${
                option.active ? "none" : currentContext.theme.color
              }8c`,
            }}
            key={index}
            onClick={() => {
              selectChangeHandler(option);
            }}
          >
            {option.title}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OptionSelector;
