import CameraAE from "./CameraAE/CameraAE";
import CameraEV from "./CameraEV/CameraEV";
import CameraFocus from "./CameraFocus/CameraFocus";
import CameraFocusMode from "./CameraFocusMode/CameraFocusMode";
import CameraIso from "./CameraIso/CameraIso";
import CameraMM from "./CameraMM/CameraMM";
import CameraMode from "./CameraMode/CameraMode";
import CameraShutter from "./CameraShutter/CameraShutter";
import "./CameraTelemetries.sass";
import { CameraTelemetriesContextProvider } from "./CameraTelemetriesContext";

function CameraTelemetries() {
  return (
    <CameraTelemetriesContextProvider>
      <div className="camera-telemetries">
        <CameraIso />
        <CameraShutter />
        <CameraEV />
        <CameraAE />
        <CameraMM />
        <CameraFocusMode />
        <CameraMode />
      </div>
    </CameraTelemetriesContextProvider>
  );
}

export default CameraTelemetries;
