import { useSelector } from "react-redux";
import useDeviceInfo from "./useDeviceInfo";
import { deviceTypes } from "src/helper/constants";

export default function useDeviceConnection (deviceId) {
  const deviceInfo = useDeviceInfo(deviceId);

  const deviceConnection = useSelector(state => {
    if (deviceInfo?.type === deviceTypes.DOCK) {
      return state.deviceService.dockConnections.find(connection => connection.deviceId === deviceId);
    }
    else if (deviceInfo?.type === deviceTypes.DRONE) {
      return state.deviceService.onboardConnections.find(connection => connection.deviceId === deviceId);
    }
    else return null;
  });

  return deviceConnection;
}