import { useOnboardHub } from "src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider";
import { useDeviceInfo } from "src/helper/DeviceInfoProvider";
import { PayloadIndexes } from "src/helper/HubConnection";
import { useState } from "react";
import ShutterAudio from "../../assets/audio/camera-shutter.mp3";

function Shutter({ className, size }) {
  const [capture, setCapture] = useState(true);
  const { hub, groups, deviceId, joinCompleted } = useOnboardHub();
  const { activeCameraPayload } = useDeviceInfo();

  const toggleCapture = () => {
    const audio = new Audio(ShutterAudio);
    setCapture(false);
    if (joinCompleted)
      hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
        takePicture: {
          payloadIndex: activeCameraPayload?.payloadIndex,
        }
      });

    setTimeout(() => {
      setCapture(true);
      audio.play();
    }, 750);
  };

  return (
    <svg
      cursor="pointer"
      onClick={toggleCapture}
      className={className}
      width={`${size}em`}
      height={`${size}em`}
      viewBox="0 0 60 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="shutter-base"
          transform="translate(-11.000000, -11.000000)"
          fill="#FFFFFF"
        >
          {capture ? (
            <path
              style={{ transition: "all .5s ease" }}
              d="M14.1607272,27.5822838 L38.3413928,41.5429937 L14.5954856,55.2526958 C12.3021924,51.0130383 11.0002006,46.1585943 11.0002006,41.0000538 C11.0002006,36.1769903 12.1383532,31.6197566 14.1607272,27.5822838 Z M15.7696058,57.2368863 L39.8110898,43.356533 L39.8110898,70.9769188 C29.7200542,70.5835795 20.9085713,65.205911 15.7696058,57.2368863 Z M41.9825345,70.9842746 L41.9825345,43.2225584 L66.2376501,57.2262519 C61.0646183,65.255528 52.1644014,70.6567155 41.9825395,70.9842744 Z M67.5205085,55.0368923 L43.6842823,41.2750445 L67.7405344,27.3861648 C69.8248141,31.4719394 71.0002006,36.0987335 71.0002006,41.0000538 C71.0002006,46.0712827 69.74191,50.8486221 67.5205085,55.0368923 Z M66.6144914,25.3742169 L42.2145853,39.4615052 L42.2145853,11.0241844 C52.5508047,11.4356887 61.5418785,17.0768046 66.6144914,25.3742169 Z M40.0431406,11.0150304 L40.0431406,39.5954798 L15.3925057,25.3634331 C20.5083923,17.0031543 29.6034184,11.3422056 40.0431406,11.0150304 Z"
              id="closed-shutter"
              transform="translate(41.000201, 40.999652) rotate(0) translate(-41.000201, -40.999652) "
            ></path>
          ) : (
            <path
              style={{ transition: "all .1s ease" }}
              d="M32.5119761,12.2176474 L32.5119797,41.8822195 L12.8726314,30.5434333 C16.1464709,21.7405753 23.4345234,14.8903758 32.5119761,12.2176474 Z M12.0572048,33.0774386 L39.8527314,49.1251979 L18.8606348,61.2449905 C13.978558,55.9090325 11,48.802356 11,41 C11,38.2576447 11.3679611,35.6012314 12.0572048,33.0774386 Z M20.7745825,63.1572575 L47.2251983,47.8859874 L47.225201,70.3532735 C45.2168711,70.7770879 43.1344675,71 41,71 C33.2071801,71 26.1083403,68.0287185 20.7745825,63.1572575 Z M49.3544551,69.8213411 L49.3544514,39.312007 L69.3443949,50.8532095 C66.1688262,59.9893735 58.700715,67.1168819 49.3544551,69.8213411 Z M70.125672,48.2192643 L44.1188633,33.2042221 L63.9933928,21.7296571 C68.3665581,26.942219 71,33.6635729 71,41 C71,43.488839 70.6969264,45.9068914 70.125672,48.2192643 Z M62.0850792,19.6594368 L34.746388,35.4434375 L34.7463851,11.6527371 C36.7634854,11.2250199 38.8554646,11 41,11 C49.219295,11 56.6665731,14.3053973 62.0850792,19.6594368 Z"
              id="opened-shutter"
              transform="translate(41.000201, 40.999652) rotate(30.000000) translate(-41.000201, -40.999652) "
            ></path>
          )}
        </g>
      </g>
    </svg>
  );
}

export default Shutter;
