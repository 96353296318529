import "./App.sass";
import Header from "../Header/Header";
import AccountManagement from "../AccountManagement/AccountManagement";
import Footer from "../Footer/Footer";
import { useMsal } from "@azure/msal-react";
import { BrowserRouter as Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import { EventType, InteractionType } from "@azure/msal-browser";
import React from "react";
import Dashboard from "../Dashboard/Dashboard";
import Portrait from "../../ui/Portrait/Portrait";
import { ThemeProvider } from "../../helper/ThemeProvider";
import IsAuthenticated from "../../helper/IsAuthenticated";
import { AccountStateProvider } from "../../helper/AccountStateProvider";
import AdminCenter from "../AdminCenter/AdminCenter";
import {
  useForgotPasswordRequest,
  useLoginRequest,
} from "../../helper/AuthConfigProvider";
import FrontendHubProvider from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import { SettingsProvider } from "src/helper/SettingsProvider";
import { AppThemeProvider } from "../../helper/AppThemeProvider";

function NewLanding() {
  return (
    <iframe
      src="static/landing/index.html"
      title="Intuitive Robotics"
      style={{ width: "100%", height: "100vh", border: "none" }}
    />
  );
}

function ServiceAgreement() {
  return (
    <iframe
      src="static/other/service_agreement/index.html"
      title="Intuitive Robotics Service Agreement"
      style={{ width: "100%", height: "100vh", border: "none" }}
    />
  );
}

export default function Pages() {
  const { instance } = useMsal();
  const { forgotPasswordRequest } = useForgotPasswordRequest();
  const { loginRequest } = useLoginRequest();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          (event.error.errorMessage.indexOf("AADB2C90118") > -1 ||
            event.error.errorMessage.indexOf("AADB2C90091") > -1)
        ) {
          if (event.interactionType === InteractionType.Redirect) {
            instance.loginRedirect(forgotPasswordRequest);
          } else if (event.interactionType === InteractionType.Popup) {
            instance.loginPopup(forgotPasswordRequest).catch((e) => {
              return;
            });
          }
        }
      } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        instance.loginRedirect(loginRequest);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, loginRequest]);

  return (
    <Switch>
      <Route path="/dashboard">
        <IsAuthenticated>
          <SettingsProvider>
            <ThemeProvider>
              <AccountStateProvider>
                <FrontendHubProvider>
                  <Dashboard />
                </FrontendHubProvider>
              </AccountStateProvider>
            </ThemeProvider>
          </SettingsProvider>
        </IsAuthenticated>
      </Route>
      <Route path="/account">
        <IsAuthenticated>
          <SettingsProvider>
            <ThemeProvider>
              <AccountStateProvider>
                <AccountManagement />
              </AccountStateProvider>
            </ThemeProvider>
          </SettingsProvider>
        </IsAuthenticated>
      </Route>
      <Route path="/admin">
        <IsAuthenticated>
          <SettingsProvider>
            <Portrait>
              <ThemeProvider>
                <AccountStateProvider>
                  <FrontendHubProvider>
                    <AdminCenter />
                  </FrontendHubProvider>
                </AccountStateProvider>
              </ThemeProvider>
            </Portrait>
          </SettingsProvider>
        </IsAuthenticated>
      </Route>
      <Route exact path="/service-agreement">
        <Portrait>
          <ServiceAgreement />
        </Portrait>
      </Route>
      <Route exact path="/">
        <Portrait>
          <NewLanding />
        </Portrait>
      </Route>
    </Switch>
  );
}
