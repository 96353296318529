import classNames from 'classnames'
import './CameraFocusIndicator.sass'

function CameraFocusIndicator({ className, left, top, width = null, height = null, color = null, active = false }) {
  return (
    <div 
      className={classNames('camera-focus-indicator', {'active': active})}
      style={{
        '--indicator-width': width,
        '--indicator-height': height,
        '--indicator-color': color,
        left,
        top,
      }}
    >
      <div className="top left"></div>
      <div className="top right"></div>
      <div className="bottom right"></div>
      <div className="bottom left"></div>
    </div>
  )
}

export default CameraFocusIndicator