import "./Loading.sass";
import spinner from "./../../assets/img/spinner.svg";
import classNames from "classnames";

function Loading({ dark }) {
  return (
    <div className={classNames("loading", { 'dark': dark })}>
      <img src={spinner} alt="Spinner" />
    </div>
  );
}

export default Loading;
