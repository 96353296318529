/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./PhoneSelector.sass";
import SmallAlert from "../SmallAlert/SmallAlert";
import down_icon from "./../../assets/img/arrow-down.svg";
import up_icon from "./../../assets/img/arrow-up.svg";
import { useDispatch } from "react-redux";
import { phoneNumber } from "./PhoneSelectorSlice";
import { isNumber } from "../../helper/utils";
import { useTheme } from "src/helper/ThemeProvider";

const PhoneSelector = ({
  width,
  currentValue,
  currentPrefix,
  name,
  title,
  status,
  countries,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { currentContext } = useTheme();
  const [loaded, setLoaded] = useState(false);
  const [select, setSelect] = useState({
    open: false,
    name,
    value: "",
    prefix: "",
  });

  useEffect(() => {
    if (currentValue !== undefined && currentPrefix !== undefined) {
      setLoaded(true);
      setSelect({
        ...select,
        value: currentValue ? currentValue : "",
        prefix: currentPrefix ? currentPrefix : "",
      });
    }
  }, [currentPrefix, currentValue]);

  const toggle = () => {
    setSelect({ ...select, open: !select.open });
  };

  const getText = (event) => {
    setSelect({ ...select, value: event.target.value });
  };

  const toggleSelect = (prefix) => {
    setSelect({ ...select, open: !select.open, prefix });
  };

  useEffect(() => {
    if (loaded) {
      console.log(loaded, select);
      const { open: v, ...withoutSecond } = select;
      dispatch(phoneNumber({ ...withoutSecond }));
    }
  }, [loaded, select]);

  return (
    <div
      className="phone-selector"
      style={{ width: width, color: currentContext.theme.color }}
    >
      <label htmlFor="title">{title}</label>
      <div
        className={`select-box-container ${select.open
          ? "open"
          : "" || (status !== undefined && status.alert === true)
            ? "select-box-container-" + status.type
            : ""
          }`}
      >
        <div className="select-box-selected" onClick={toggle}>
          <p className="selected-value">{select.prefix}</p>
          <img src={select.open ? up_icon : down_icon} alt="down" />
        </div>

        <input
          autoComplete="off"
          className="phone-selector-input"
          name={name}
          id={name}
          maxLength="11"
          onInput={onChange}
          onChange={getText}
          onKeyPress={isNumber}
          value={select.value ? select.value : ""}
          style={{ color: currentContext.theme.textColor }}
        />
      </div>
      {select.open && (
        <div
          className="phone-select-box"
          style={{ color: currentContext.theme.color }}
        >
          <ul className="phone-select-box-items">
            {countries.map((item) => (
              <li key={item.id} onClick={() => toggleSelect(item.prefix)}>
                <span className="phone-select-box-name">{item.prefix}</span>
                <span className="phone-select-box-value">{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {status !== undefined && status.alert && (
        <SmallAlert
          alert={status.alert}
          alertType={status.type}
          message={status.message}
        />
      )}
    </div>
  );
};
PhoneSelector.defaultProps = {
  width: "100%"
}
export default PhoneSelector;