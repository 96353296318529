import './VideoSwitchView.sass'

import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { cameraSource, mainVideoSource } from "src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";
import classNames from "classnames";
import VideoStreamView from '../VideoStreamView/VideoStreamView';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';
import { TbChevronDownRight } from 'react-icons/tb';

function VideoSwitchView({ mainStream, fpvStream }) {
  const dispatch = useDispatch();
  const mapOverlay = useSelector((state) => state.flight?.enlargeMap);
  const mainVideoSourceState = useSelector((state) => state.flight?.mainVideoSource);
  const { cameraPayloads, activeCameraPayload } = useDeviceInfo();
  const sourceTitle = useMemo(() => mainVideoSourceState === cameraSource.FPV ? (activeCameraPayload?.title || 'MAIN') : 'FPV', [activeCameraPayload?.title, mainVideoSourceState]);
  const [minimized, setMinimized] = useState();

  const handleContainerClick = () => {
    console.log('switch-video', mainVideoSourceState);
    dispatch(mainVideoSource(mainVideoSourceState === cameraSource.FPV ? cameraSource.MAIN : cameraSource.FPV));
  }

  const handleMinimizeToggleClick = (e) => {
    e.stopPropagation();
    setMinimized(curr => !curr);
  }

  if (!cameraPayloads?.length) return null;

  return (
    <div className={classNames('video-switch-view', { 'hidden': mapOverlay, 'minimized': minimized })} onClick={handleContainerClick}>
      <VideoStreamView stream={mainVideoSourceState === cameraSource.FPV ? mainStream : fpvStream} className="video-view" />
      <div className="source-title">{sourceTitle}</div>
      <div className="video-minimize-toggle" onClick={handleMinimizeToggleClick} title={minimized ? 'Show second video' : 'Hide second video'}>
        <TbChevronDownRight className="toggle-icon" />
        <div className="minimized-title">{sourceTitle}</div>
      </div>
    </div>
  )
}

export default VideoSwitchView