import { createSlice } from "@reduxjs/toolkit";
const ApiProviderSlice = createSlice({
  name: "api",
  initialState: { state: undefined, token: undefined },
  reducers: {
    setApiState(state, action) {
      const data = action.payload;
      return { ...state, state: { ...data } };
    },
    setApiToken(state, action) {
      const data = action.payload;
      return { ...state, token: { ...data } };
    },
  },
});

export const { setApiState, setApiToken } = ApiProviderSlice.actions;
export default ApiProviderSlice.reducer;
