import { MdOutlineKeyboardAlt } from 'react-icons/md';
import './ManualFlightKeyboardGuide.sass';
import { TbQuestionMark } from 'react-icons/tb';
import Dialog from 'src/hci/common/Dialog/Dialog';

function ManualFlightKeybaordGuide({...props}) {
  return (
    <Dialog
      {...props}
      title="Flight shortcuts"
      trigger={(
        <div><MdOutlineKeyboardAlt /></div>
      )}
      className="manual-flight-keyboard-guide-dialog"
    >
      <div className="manual-flight-keyboard-guide">
        <div className="shortcut-group">
          <div className="shortcut-items">
            <div className="item">
              <div className="description">Forward / Backward</div>
              <div className="keys">
                <kbd>W</kbd>
                <kbd>S</kbd>
              </div>
            </div>
            <div className="item">
              <div className="description">Yaw left / Yaw right</div>
              <div className="keys">
                <kbd>A</kbd>
                <kbd>D</kbd>
              </div>
            </div>
            <div className="item">
              <div className="description">Ascend / Descend</div>
              <div className="keys">
                <kbd>&uarr;</kbd>
                <kbd>&darr;</kbd>
              </div>
            </div>
            <div className="item">
              <div className="description">Roll left / Roll right</div>
              <div className="keys">
                <kbd>&larr;</kbd>
                <kbd>&rarr;</kbd>
              </div>
            </div>
            <div className="item">
              <div className="description">Adjust speed ratio</div>
              <div className="keys">
                <kbd>Z</kbd>
                <kbd>C</kbd>
              </div>
            </div>
            <div className="item"></div>
          </div>
        </div>
        <div className="shortcut-group">
          <div className="shortcut-items">
            <div className="item">
              <div className="description">Gimball up / Gimball down</div>
              <div className="keys">
                <kbd>Shift</kbd>
                <kbd>&uarr;</kbd>
                <kbd>&darr;</kbd>
              </div>
            </div>
            <div className="item">
              <div className="description">Gimball left / Gimball right</div>
              <div className="keys">
                <kbd>Shift</kbd>
                <kbd>&larr;</kbd>
                <kbd>&rarr;</kbd>
              </div>
            </div>
            <div className="item">
              <div className="description">Zoom 0ut / Zoom in</div>
              <div className="keys">
                <kbd>Q</kbd>
                <kbd>E</kbd>
              </div>
            </div>
            <div className="item">
              <div className="description">Reset gimball</div>
              <div className="keys">
                <kbd>R</kbd>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ManualFlightKeybaordGuide