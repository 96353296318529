import React, {useMemo} from 'react'
import { useSelector } from 'react-redux';
import './MainSpeed.sass'
import { MeasurementSystem, MeasurementType } from 'src/helper/constants';
import useMeasurement from 'src/helper/useMeasurement';
import convert from 'convert';

function MainSpeed() {
  const { convertValue, measurementSystem } = useMeasurement();
  const velocity = useSelector((state) => state.flight.velocity);
  const speed = useMemo(() => {
    return Math.sqrt(Math.pow(velocity.x, 2) + Math.pow(velocity.y, 2));
  }, [velocity.x, velocity.y])

  return (
    <div className="main-speed">
      <div className="ws-info">WS N/A</div>
      <div className="speed-info">
        <div className="value">{speed ? convertValue(speed, MeasurementType.SPEED, MeasurementSystem.METRIC)?.toFixed(1).padStart(4, '0') : "00.0"}</div>
        <div className="info-label">
          <span>SPD</span><br/>
          <span>{measurementSystem === MeasurementSystem.METRIC ? 'm/s' : 'mph' }</span>
        </div>
      </div>
    </div>
  )
}

export default MainSpeed