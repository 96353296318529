import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import './HmsStatusView.sass';
import useDeviceHms from 'src/services/device/common/useDeviceHms';
import { deviceTypes } from 'src/helper/constants';
import cloudHmsMessages from 'src/services/device/assets/cloudHmsMessages';
import { getDeviceTelemetries } from 'src/services/device/common/deviceUtils';
import useSubDevice from 'src/services/device/common/useSubDevice';
import { ScrollArea } from 'src/hci/common/ScrollArea/ScrollArea';
import psdkHmsMessages from 'src/services/device/assets/psdkHmsMessages';

function HmsStatusView({ deviceId, deviceType, subDevice = false }) {
  const subDeviceInfo = useSubDevice(subDevice ? deviceId : 0);
  const deviceHms = useDeviceHms(subDeviceInfo?.device_sn ? subDeviceInfo?.device_sn : deviceId);
  const newErrorTimestamp = useSelector((state) => state.flight.hms.newErrorTimestamp);
  const [showList, setShowList] = useState(false);

  const hasNewError = useMemo(() => {
    const currTime = Date.now() / 1000;
    return newErrorTimestamp && (currTime - newErrorTimestamp) < 5
  }, [newErrorTimestamp]);

  const colors = {
    IDLE: "gray",
    INFO: "#57e424",
    WARNIGN: "orange",
    ERROR: "red",
  };

  const getMessageString = useCallback((error) => {
    let errorInfo;
    let errorMessage;
    let inSky;

    if(deviceType === deviceTypes.DOCK) {
      inSky = !!error?.inSky;
    } else {
      inSky = getDeviceTelemetries(deviceId)?.flightControl?.flightStatus === 2;
    }

    const errorKey = (deviceType === deviceTypes.DOCK && !subDevice ? 'dock' : 'fpv') + '_tip_' + error.code;
    const errorKeySky = errorKey + '_in_the_sky';

    errorInfo = inSky && cloudHmsMessages[errorKeySky] ? cloudHmsMessages[errorKeySky] : cloudHmsMessages[errorKey];
    errorInfo = errorInfo ? errorInfo : inSky && cloudHmsMessages[errorKeySky.toLowerCase()] ? cloudHmsMessages[errorKeySky.toLowerCase()] : cloudHmsMessages[errorKey?.toLowerCase()];
    errorMessage = (errorInfo?.['en'] || errorInfo?.['zh'])?.replace?.('%index', parseInt(error.componentIndex) + 1);

    if(!errorMessage && deviceType === deviceTypes.DRONE) {
      errorInfo = psdkHmsMessages.find(item => item[0] === error.code || item[0] === String(error.code).toLowerCase());
      errorMessage = (inSky && errorInfo?.[2] ? errorInfo[2] : errorInfo?.[1])?.replace?.('%index', parseInt(error.componentIndex) + 1);
    }

    return errorMessage || 'This HMS message is not supported.';
  }, [deviceType]);

  const handleMessageBarClick = () => {
    setShowList(curr => !curr);
  }

  const lastErrorMessage = deviceHms?.errors?.[0] && getMessageString(deviceHms?.errors?.[0]);

  return (
    <div className="hms-status-view">
      {lastErrorMessage && (
        <div className={classNames("counter", { "new-error": hasNewError })}>{deviceHms?.errors?.length}</div>
      )}
      <div
        className={classNames("message", { 'marquee': !!lastErrorMessage })}
        style={{ background: lastErrorMessage ? '' : '#4b4b4b' }}
        onClick={handleMessageBarClick}
      >
        <div className="message-text-wrapper">
          <div
            className="message-text"
            style={{
              animation: lastErrorMessage ? '' : 'none',
              animationDuration: (lastErrorMessage?.length * 200) + 'ms',
            }}
          >
            {lastErrorMessage || 'HMS: No messages.'}
          </div>
        </div>
      </div>
      {showList && lastErrorMessage && (
        <div className="message-list">
          <ScrollArea className='message-list-content'>
            {deviceHms?.errors?.map((item, index) => (
              <div className="message-item" key={item.errorCode?.toString() + index.toString()}>{getMessageString(item)}</div>
            ))}
          </ScrollArea>
        </div>
      )}
    </div>
  );
}

export default HmsStatusView