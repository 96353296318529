import { Link } from "react-router-dom";
import "./AdminNavigationItem.sass";

function AdminNavigationItem({ color, navItem, navItemClicked }) {
  return (
    <>
      <Link
        to={navItem.link}
        className="admin-navigation-item"
        style={{ color: color }}
        onClick={navItemClicked}
      >
        {navItem.icon}
        <span>{navItem.title}</span>
      </Link>
    </>
  );
}

export default AdminNavigationItem;
