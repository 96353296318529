import React from "react";
import Bar from "../Bar/Bar";
import "./Stepper.sass";
const Stepper = (props) => {
  const { steps, currentStep, color, firstStep,  secondStep } = props;

  // const getBars = (steps) => {
  //   let content = [];
  //   for (let i = 0; i < steps; i++) {
  //     content.push(
  //       <Bar
  //         key={i}
  //         isActive={i == currentStep - 1}
  //         activeColor={color}
  //         passiveColor="#c9c9c9"
  //       />
  //     );
  //   }
  //   return content;
  // };

  return (
    <div className="stepper">
      {/* {getBars(steps)} */}
      <Bar isActive={firstStep} activeColor={color} passiveColor="#c9c9c9" />
      <Bar isActive={secondStep} activeColor={color} passiveColor="#c9c9c9" />
      <span className="stepper-number" style={{ color: color }}>
        {currentStep}/{steps}
      </span>
    </div>
  );
};

export default Stepper;
