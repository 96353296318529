import { createSlice } from "@reduxjs/toolkit";
const ToastSlice = createSlice({
  name: "toast",
  initialState: {},
  reducers: {
    toastMe(state, action) {
      const data = action.payload;
      return { ...state, ...data };
    },
  },
});
export const { toastMe } = ToastSlice.actions;
export default ToastSlice.reducer;
