import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './VerticalSlideMeter.sass'

function VerticalSlideMeter({ 
  min, 
  max, 
  steps, 
  current,
  currentLabel,
  currentValueFormatter,
  middleUpValue,
  middleUpLabel,
  bottomValue,
  bottomLabel,
  inverse=false,
  height=200, 
  itemHeight=60,
  subSteps=5,
}) {
  const [values, setValues] = useState([]);
  const slideItemsHeight = useMemo(() => steps * itemHeight, [steps, itemHeight]);

  const transformInput = useCallback((x) => {
    const minOutput = 0;
    return (x - max) / (min - max) * (slideItemsHeight - minOutput) + minOutput;
  }, [min, max, slideItemsHeight]);

  const formatCurrentValue = (value) => {
    const isNegative = value < 0;
    const absValue = Math.abs(value);

    if(!absValue)
      return "000.0";
    
    const fixedValue = absValue.toFixed(1);
    const newValue = fixedValue.padStart(5, "0")
    
    return isNegative && fixedValue < 0 ? '-' + newValue : newValue;
  };

  useEffect(() => {
    const valuesHolder = [];
    const stepValue = Math.floor((max - min) / steps);

    for(var curr= max; curr >= min; curr -= stepValue) {
      valuesHolder.push(curr);
    }

    setValues(valuesHolder);
  }, [min, max, steps, transformInput]);

  return (
    <div className={'vertical-slide-meter ' + (inverse ? 'inverse' : null)}>
      <div className="content-container">
        <div className="max-value-indicator">
          <SlideItem value={max} steps={1} />
        </div>
        <div className="slide-container" style={{ height: height + 'px' }}>
          <div className="slide-items-container" style={{ 
            top: Math.round(height / 2) + 'px',
            transform: `translateY(${-transformInput(current)}px)`
          }}>
            {values.map((item, i) => (
              <SlideItem value={item} size={itemHeight} steps={subSteps} key={i} />
            ))}
          </div>
        </div>
        <div className="min-value-indicator">
          <SlideItem value={min} steps={1} />
        </div>
        <div className="current-value-indicator">
          <div className="position-holder">
            {middleUpLabel && (
              <div className="upside-info">
                <div className="value">{middleUpValue}</div>
                <div className="info-label">{middleUpLabel}</div>
              </div>
            )}
            <div className="value-container">
              <div className="value">
                {currentValueFormatter ? currentValueFormatter(current) : formatCurrentValue(current)}
              </div>
              {currentLabel && (
                <div className="info-label">{currentLabel}</div>
              )}
            </div>
          </div>
        </div>
        {bottomLabel && (
          <div className="side-info-bottom">
            <div className="value">{bottomValue}</div>
            <div className="info-label">{bottomLabel}</div>
          </div>
        )}
      </div>
    </div>
  )
}

function SlideItem({ value, steps=5, size=100 }) {
  const dividerSize = useMemo(() => size / steps, [steps, size]);

  return (
    <div className="slide-item">
      <div className="value">{ value }</div>
      <div className="dividers" style={{ '--divider-item-size': dividerSize + 'px' }}>
        {[...Array(steps)].map((item, index) => (
          <div className={'divider-item ' + (index === 0 ? 'divider-item-main' : null)} key={index} />
        ))}
      </div>
    </div>
  )
}

export default VerticalSlideMeter