import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTheme } from "../../helper/ThemeProvider";
import "./BreadCrumb.sass";

function BreadCrumb({ histories }) {
  const { currentContext } = useTheme();
  return (
    <ul className="bread-crumb">
      {histories.map((history, index) => (
        <li className="bread-crumb-item" key={index}>
          <Link
            to={history.link}
            style={{
              fontWeight: index === histories.length - 1 && "500",
              color:
                index === histories.length - 1 && currentContext.theme.color,
            }}
          >
            {history.title}
          </Link>
          {index !== histories.length - 1 && <BiChevronRight />}
        </li>
      ))}
    </ul>
  );
}
BreadCrumb.defaultProps = {
  histories: [],
};
export default BreadCrumb;
