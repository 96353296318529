import { useEffect, useMemo, useState } from 'react';
import './AppGroupsSideList.sass';
import { useDispatch, useSelector } from 'react-redux';
import AppGroupsListCard from './AppGroupsListCard/AppGroupsListCard';
import SideListPanel from 'src/hci/organism/SideListPanel/SideListPanel';
import { currentGroup, getGroupsInformation } from 'src/ui/GroupApp/GroupAppSlice';
import SideListItem from 'src/hci/organism/SideListPanel/SideListItem/SideListItem';
import Dialog from 'src/hci/common/Dialog/Dialog';
import GroupMembershipApp from 'src/ui/GroupApp/GroupMembershipApp/GroupMembershipApp';

function AppGroupsSideList({ids}) {
  const dispatch = useDispatch();
  const [selectedListItemsArray, setSelectedListItemsArray] = useState([]);
  const [isGroupOptionsDialogActive, setIsGroupOptionsDialogActive] = useState();
  const selectedListItemId = useMemo(() => selectedListItemsArray[0], [selectedListItemsArray]);
  const [loading, setLoading] = useState(false);
  const groupsInfo = useSelector(state => state.groupApp.groupsInformation?.data?.groups);

  const listItems = !groupsInfo ? [] : groupsInfo.map(item => {
    const data = {
      ...item,
    };

    return {
      id: item.id,
      content: (
        <SideListItem data={data}>
          <AppGroupsListCard data={data} />
        </SideListItem>
      )
    };
  });

  const handleListItemClick = id => {
    setSelectedListItemsArray([id]);
    dispatch(currentGroup(groupsInfo.find(item => item.id === id)));
    setIsGroupOptionsDialogActive(true);
  }

  const handleGroupOptionsDialogClose = () => {
    setIsGroupOptionsDialogActive(false);
  }

  const refreshGroups = () => {
    if(!Array.isArray(ids) || !ids.length) return;

    setLoading(true);
    dispatch(getGroupsInformation(ids.toString()));
  }

  useEffect(() => {
    refreshGroups();
  }, [ids]);

  useEffect(() => {
    setLoading(false);
  }, [groupsInfo]);

  return (
    <div className="app-groups-side-list">
      <SideListPanel
        panelTitle="Dock Groups"
        listItems={listItems}
        activeIds={selectedListItemsArray}
        loading={loading}
        onListItemClick={handleListItemClick}
      />
      <Dialog
        title="Group Options" 
        open={isGroupOptionsDialogActive}
        onOpenChange={setIsGroupOptionsDialogActive}
      >
        <Dialog.Body>
          <GroupMembershipApp onComplete={handleGroupOptionsDialogClose} />
        </Dialog.Body>
      </Dialog>
    </div>
  )
}

export default AppGroupsSideList