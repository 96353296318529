import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { onboardConnectionManager } from 'src/helper/HubConnectionManager';
import { WaypointStateV3, MissionStatus, onboardWaypointAction, WaypointStateV2, OnboardWaypointEvent, MissionPendingRequestType, MissionPendingRequestStatus } from './missionConstants';
import { getMissionInfo } from './missionUtils';
import { removeMissionPendingRequest, setMissionInfo, setMissionPendingRequest } from '../MissionServiceSlice';
import { getDeviceConnectionGroups, getDeviceInfo, getDeviceModelInfo, getDeviceTelemetries } from 'src/services/device/common/deviceUtils';
import { getCoordinatesDistanceAndTime } from 'src/helper/waypointMarkupUtils';
import { addOrUpdatePath, removePath } from 'src/components/DeviceMap/DeviceMapSlice';
import { DeviceMapPathType } from 'src/components/DeviceMap/DeviceMap';
import { WaypointV2ErrorText, WaypointV3ErrorText } from 'src/helper/waypointErrors';
import { useTermianteMission } from './useTerminateMission';

export default function useOnboardMissionObserver() {
  const messageHandlerIds = useRef([]);
  const dispatch = useDispatch();
  const pendingRequest = useRef(null);
  const pendingRequestTimeoutId = useRef(null);
  const terminateMission = useTermianteMission();

  const generateMissionData = (deviceId, missionId, data) => {
    const device = getDeviceInfo(deviceId);
    const mission = getMissionInfo(item => item.id === missionId);

    console.log('🍭 mission state on generate', mission)

    const {
      distance: newDistance, 
      time: newTime
    } = getCoordinatesDistanceAndTime(data.detail?.points);

    console.log({
      distance: newDistance,
      newTime
    })
    
    const waypoints = mission?.waypoints || data.detail?.points?.map(item => ({
      latitude: item.lat,
      longitude: item.long,
      altitude: item.altitude,
      speed: item.speed || data.detail?.speed,
      actions: item.actions || {},
    }));

    return ({
      id: mission?.id,
      waypoints: mission?.waypoints || waypoints,
      // construct external mission info
      ...(mission ? {} : {
        deviceId: deviceId,
        flightId: null,
        name: "External mission " + new Date().toUTCString(),
        selfInitiated: false,
        waypoints,
        distance: newDistance,
        duration: newTime,
        altitude: data.detail?.altitude,
        speed: data.detail?.speed,
        startLatitude: data.detail?.points?.[0]?.lat,
        startLongitude: data.detail?.points?.[0]?.long,
        serialNumber: device?.serialNumber,
        finishAction: 'hover',
      })
    });
  }

  const setMapMissionPath = (deviceId, points) => {
    const mapCoords = points?.map(item => ([ item.long || item.longitude, item.lat || item.latitude ]));
    if(!Array.isArray(mapCoords) || mapCoords.length === 0)
      return;

    dispatch(addOrUpdatePath({
      deviceId,
      type: DeviceMapPathType.ACTIVE_MISSION_ROUTE,
      coords: mapCoords
    }));
  }

  const removeMapMissionPath = (deviceId) => {
    dispatch(removePath({
      deviceId,
      type: DeviceMapPathType.ACTIVE_MISSION_ROUTE
    }));
  }

  useEffect(() => {
    messageHandlerIds.current = onboardConnectionManager.subscribeGroupMessages([
      {
        identity: 'onboardMissionMessages/*',
        name: ['lowFreqChannel', 'onDemandChannel'],
        handler: (data, meta) => {
          const deviceId = meta?.fromUserId?.split('_')[0];
          const mission = getMissionInfo(item => item.deviceId === deviceId);
          const deviceTelemetries = getDeviceTelemetries(deviceId);
          const modelInfo = deviceTelemetries?.droneInfo?.model ? getDeviceModelInfo(deviceTelemetries.droneInfo.model) : null;
          let newMissionStatus, newMissionDescription;

          if(!mission)
            return;
  
          if(data?.action =='WaypointResult') {
            switch (data.actionId) {
              case onboardWaypointAction.UPLOAD:
                if(data.status) {
                  newMissionStatus = MissionStatus.PREPARING;
                  newMissionDescription = 'Fly task uploaded successfully.';

                  dispatch(setMissionPendingRequest({
                    missionId: mission?.id,
                    requestType: MissionPendingRequestType.ONBOARD_START,
                    requestStatus: MissionPendingRequestStatus.PENDING
                  }));
                }
                else {
                  newMissionStatus = MissionStatus.FAILED;
                  newMissionDescription = 'Fly task upload failed.';

                  const pendingRequests = mission?.pendingRequests || [];
                  if(pendingRequests.find(item => item.requestType === MissionPendingRequestType.ONBOARD_ENTER_MISSION)) {
                    terminateMission(mission?.id);
                  }
                }

                dispatch(removeMissionPendingRequest({
                  missionId: mission?.id,
                  requestType: [
                    MissionPendingRequestType.ONBOARD_UPLOAD,
                  ],
                }));

                break;
              case onboardWaypointAction.START:
                if(data.status) {
                  newMissionStatus = MissionStatus.PREPARING;

                  dispatch(setMissionPendingRequest({
                    missionId: mission?.id,
                    requestType: MissionPendingRequestType.ONBOARD_ENTER_OPERATION,
                    requestStatus: MissionPendingRequestStatus.PENDING,
                  }));
                } 
                else {
                  newMissionStatus = MissionStatus.FAILED;
                  newMissionDescription = 'Fly task start failed.';

                  const pendingRequests = mission?.pendingRequests || [];
                  if(pendingRequests.find(item => item.requestType === MissionPendingRequestType.ONBOARD_ENTER_MISSION)) {
                    terminateMission(mission?.id);
                  }
                }

                dispatch(removeMissionPendingRequest({
                  missionId: mission?.id,
                  requestType: [
                    MissionPendingRequestType.ONBOARD_START,
                  ],
                }));

                break;
              case onboardWaypointAction.STOP:
                if(data.status) {
                  pendingRequest.current = onboardWaypointAction.STOP;
                  removeMapMissionPath(deviceId);

                  newMissionStatus = MissionStatus.IDLE;
                  newMissionDescription = 'Fly task stopped.';
                  removeMapMissionPath(deviceId);
                }
                else {
                  newMissionStatus = MissionStatus.IDLE;
                  newMissionDescription = 'Fly task stop failed.';
                }
                break;
              case onboardWaypointAction.PAUSE:
                if(data.status) {
                  pendingRequest.current = onboardWaypointAction.PAUSE;
                }
                else{
                  newMissionDescription = 'Fly task pause failed.';
                }
                break;
              case onboardWaypointAction.RESUME:
                if(data.status) {
                  pendingRequest.current = onboardWaypointAction.RESUME;
                }
                else{
                  newMissionStatus = MissionStatus.IDLE;
                  newMissionDescription = 'Fly task resume failed.';
                }
                break;
              case onboardWaypointAction.MISSION_TERMINATE:
                if(data.status) {
                  newMissionStatus = MissionStatus.COMPLETED;
                  newMissionDescription = 'Fly task terminated.';
                  removeMapMissionPath(deviceId);
                }
                else {
                  newMissionDescription = 'Fly task termination failed.';
                }
                break;
            }

            // add error text to mission description
            if(data.statusCode) {
              const hexaStatusCode = '0x' + data.statusCode.toString(16);
              const errorText = modelInfo?.waypointVersion === '2.0.0' ? WaypointV2ErrorText[hexaStatusCode] : WaypointV3ErrorText[data.statusCode?.toString()];
              newMissionDescription = errorText ? `${newMissionDescription} (${errorText})` : newMissionDescription;

              console.log('❌ Waypoint error:', `version ${modelInfo?.waypointVersion}`, `code ${data.statusCode}, hexa ${hexaStatusCode}`, errorText);
            }
          }
  
          // fly task states tracking
          if(
            data?.waypointState
          ){
            clearTimeout(pendingRequestTimeoutId.current);
  
            switch(data.waypointState.waypointState){
              case WaypointStateV3.IDLE: 
              case WaypointStateV2.GROUND_STATION_NOT_START:
                if(pendingRequest.current === onboardWaypointAction.STOP) {
                  newMissionStatus = MissionStatus.IDLE;
                  newMissionDescription = 'Fly task stopped.';
                  removeMapMissionPath(deviceId);
                }
                else if(pendingRequest.current === onboardWaypointAction.PAUSE) {
                  newMissionStatus = MissionStatus.PAUSED;
                  newMissionDescription = 'Fly task paused.';
                }
                else if(pendingRequest.current === onboardWaypointAction.RESUME) {
                  newMissionStatus = MissionStatus.EXECUTING;
                  newMissionDescription = 'Fly task resumed.';

                  dispatch(removeMissionPendingRequest({
                    missionId: mission?.id,
                    requestType: MissionPendingRequestType.ONBOARD_RESUME_MISSION,
                  }));
                }
                else if(mission?.status == MissionStatus.EXECUTING) {
                  newMissionStatus = MissionStatus.IDLE;
                  newMissionDescription = 'Fly task completed.';
                  removeMapMissionPath(deviceId);
                }
                break;
              case WaypointStateV3.BREAK:
              case WaypointStateV2.PAUSE_STATE:
                newMissionStatus = MissionStatus.PAUSED;
                newMissionDescription = 'Fly task paused.';
                break;
              case WaypointStateV3.PREPARE:
              case WaypointStateV2.MISSION_PREPARED:
                newMissionStatus = MissionStatus.PREPARING;
                newMissionDescription = 'Preparing mission...';
                break;
              case WaypointStateV3.TRANS_MISSION:
              case WaypointStateV2.ENTER_MISSION:
                newMissionStatus = MissionStatus.PREPARING;
                newMissionDescription = 'Applying optimal altitude, please wait...';

                dispatch(removeMissionPendingRequest({
                  missionId: mission?.id,
                  requestType: [
                    MissionPendingRequestType.ONBOARD_PREPARATION,
                    MissionPendingRequestType.ONBOARD_ENTER_OPERATION,
                    MissionPendingRequestType.ONBOARD_ENTER_MISSION,
                  ],
                }));

                break;
              case WaypointStateV3.MISSION:
              case WaypointStateV2.EXECUTE_FLYING_ROUTE_MISSION:
                newMissionStatus = MissionStatus.EXECUTING;
                newMissionDescription = 'Fly task is now running...';

                dispatch(removeMissionPendingRequest({
                  missionId: mission?.id,
                  requestType: [
                    MissionPendingRequestType.ONBOARD_PREPARATION,
                    MissionPendingRequestType.ONBOARD_ENTER_OPERATION,
                    MissionPendingRequestType.ONBOARD_ENTER_MISSION,
                  ],
                }));

                break;
              case WaypointStateV3.RESUME:
              case WaypointStateV2.ENTER_MISSION_AFTER_ENDING_PAUSE:
                if(mission.status !== MissionStatus.EXECUTING) {
                  newMissionStatus = MissionStatus.EXECUTING;
                  newMissionDescription = 'Fly task resumed...';
                }
                break;
              case WaypointStateV3.RETURN_FIRSTPOINT:
                newMissionStatus = MissionStatus.RETURNING;
                newMissionDescription = 'Returning to the first point...';
                break;
            };

            switch(data.waypointEvent?.waypointEvent) {
              case OnboardWaypointEvent.MISSION_FINISHED_EVENT:
              case OnboardWaypointEvent.MISSION_STOP_EVENT:
                if(mission?.status == MissionStatus.EXECUTING) {
                  newMissionStatus = MissionStatus.IDLE;
                  newMissionDescription = 'Fly task completed.';
                  removeMapMissionPath(deviceId);
                }
            }
            
            pendingRequest.current = null;
          }

          if(newMissionStatus === undefined && newMissionDescription === undefined)
            return;

          const missionData = generateMissionData(deviceId, mission?.id, data);
          const finalMissionData = {
            ...missionData,
            ...(newMissionStatus ? {status: newMissionStatus} : {}),
            ...(newMissionDescription ? {statusDescription: newMissionDescription} : {}),
          };
  
          dispatch(setMissionInfo(finalMissionData));
          setMapMissionPath(deviceId, data.detail?.points);
        },
      },
    ], 'onboard-mission-observer');

    return () => {
      onboardConnectionManager.unsubscribeGroupMessages(messageHandlerIds.current);
    }
  }, []);

  return {};
}
