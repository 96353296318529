import classNames from 'classnames'
import { cameraSource } from 'src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice';
import { footToMeterRatio } from 'src/helper/constants';
import { useEffect } from 'react';
import { useState } from 'react'
import { useSelector } from 'react-redux';
import './LaserRangeMainDisplay.sass'

function LaserRangeMainDisplay({ className }) {
  const [currValue, setCurrValue] = useState();
  const enabled = useSelector((state) => state.flight?.laserRangeEnabled);
  const rangeInfo = useSelector((state) => state.flight?.camera?.rangingInfo);
  const mainVideoSource = useSelector((state) => state.flight?.mainVideoSource);

  useEffect(() => {
    let newValue = 'N/A';

    if (!rangeInfo?.exception || rangeInfo?.exception === 3)
      newValue = Number((rangeInfo?.dist / 10) * footToMeterRatio).toFixed() + ' ft';

    setCurrValue(newValue);
  }, [rangeInfo]);

  if (!enabled || mainVideoSource === cameraSource.FPV) return null;

  return (
    <div className={classNames('laser-range-main-display', className)}>
      RNG {currValue}
    </div>
  )
}

export default LaserRangeMainDisplay