import "./FlightControlBar.sass";
import Map from "../../../../../ui/Drone/Map/Map";
import Radar from "../../../../../ui/Drone/Radar/Radar";
import Joystick from "../../../../../ui/Joystick/Joystick";
import { JoyPY, JoyRoll } from "../../../../../ui/DroneIcons/DroneIcons";
import AdjustmentShortcutController from "../../../../../ui/AdjustmentShortcutManager/AdjustmentShortcutController"
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useCameraSource } from "../../../../../helper/CameraSourceProvider";
import { useOnboardHub } from "src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider";
import { PayloadIndexes } from "src/helper/HubConnection";
import MainAltitude from "src/ui/Drone/MainAltitude/MainAltitude";
import MainSpeed from "src/ui/Drone/MainSpeed/MainSpeed";
import { useDeviceInfo } from "src/helper/DeviceInfoProvider";
import GimbalReset from "src/ui/Drone/GimbalReset/GimbalReset";

function FlightControlBar({ hideMap }) {
  const Roll = useRef(0);
  const Pitch = useRef(0);
  const Yaw = useRef(0);

  const [pyOperate, setPyOperate] = useState(true);
  const [rOperate, setROperate] = useState(true);
  const [keyboardMoveRate, setKeyboardMoveRate] = useState(0.25);
  const baseRange = 45;
  const interval = 400;
  const { hub, groups, deviceId, joinCompleted } = useOnboardHub();

  const { isMainCamera, isFpvCamera } = useCameraSource();
  const pyTimer = useRef(null);
  const { activeCameraPayload } = useDeviceInfo();

  const joyRollHandler = (value) => {
    if (joinCompleted && value?.x !== undefined) {
      setROperate(value?.x === 0);
      Roll.current = baseRange * value?.x;
    }
  };

  const joyPyHandler = (value) => {
    if (value !== undefined) setPyOperate(value?.x === 0 && value?.y === 0);
    if (value?.y !== undefined) {
      Pitch.current = baseRange * -value?.y;
    }
    if (value?.x !== undefined) {
      Yaw.current = baseRange * value?.x;
    }
  };

  useEffect(() => {
    if (!pyOperate) {
      clearInterval(pyTimer.current);
      sendPY();
      pyTimer.current = setInterval(() => {
        sendPY();
      }, interval);
    } else {
      clearInterval(pyTimer.current);
    }
  }, [pyOperate]);

  const sendPY = () => {
    if (joinCompleted) {
      hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
        gimbalSpeed: {
          payloadIndex: activeCameraPayload?.payloadIndex,
          pitch: Pitch.current,
          roll: 0,
          yaw: Yaw.current,
        }
      });
    }
  };

  const sendRoll = () => {
    if (joinCompleted) {
      hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
        gimbalSpeed: {
          payloadIndex: activeCameraPayload?.payloadIndex,
          pitch: 0,
          roll: Roll.current,
          yaw: 0,
        }
      });
    }
  };

  const rTimer = useRef(null);
  useEffect(() => {
    if (!rOperate) {
      clearInterval(rTimer.current);
      sendRoll();
      rTimer.current = setInterval(() => {
        sendRoll();
      }, interval);
    } else {
      clearInterval(rTimer.current);
    }
  }, [rOperate]);

  return (
    <div className="flight-control-bar">
      {!hideMap ? <Map /> : null}
      <div className="flight-control-center-area">
        <div
          className="radar-joysticks"
          style={{ justifyContent: isMainCamera ? "space-between" : "center" }}
        >
          <AdjustmentShortcutController
            title="Keyboard Gimbal Move Speed"
            onValueChange={(value) => setKeyboardMoveRate(value)}
            increaseKey="Equal"
            decreaseKey="Minus"
            minValue={0.01}
            maxValue={1}
            defaultValue={0.2}
            valueDisplayRatio={100}
          />
          {isMainCamera && (
            <Joystick
              maxDistance="24"
              deadzone="2"
              isHorizontal={true}
              isVertical={false}
              plate={<JoyRoll size="8" />}
              onChange={joyRollHandler}
              keyboardMoveRate={keyboardMoveRate}
              rightKey="KeyD"
              leftKey="KeyA"
            />
          )}
          {!isFpvCamera && (
            <MainSpeed />
          )}
          <Radar />
          {!isFpvCamera && (
            <GimbalReset />
          )}
          {!isFpvCamera && (
            <MainAltitude />
          )}
          {isMainCamera && (
            <Joystick
              maxDistance="24"
              deadzone="2"
              isHorizontal={false}
              isVertical={false}
              plate={<JoyPY size="8" />}
              onChange={joyPyHandler}
              keyboardMoveRate={keyboardMoveRate}
              upKey="Up"
              downKey="Down"
              leftKey="Left"
              rightKey="Right"
            />
          )}
        </div>
        {/* <Telemetries /> */}
      </div>
    </div>
  );
}

export default FlightControlBar;
