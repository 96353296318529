import React from "react";
import "./Bar.sass";
const Bar = (props) => {
  const { isActive, activeColor, passiveColor } = props;
  return (
    <div
      className="bar"
      style={{ background: `${isActive ? activeColor : passiveColor}` }}
    ></div>
  );
};

export default Bar;
