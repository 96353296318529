import CameraTelemetry from 'src/components/Dashboard/Components/CameraTelemetry/CameraTelemetry';
import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { cameraExposureModes } from 'src/helper/constants';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';
import { PayloadIndexes } from 'src/helper/HubConnection';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CameraModeIcon from 'src/ui/DroneIcons/CameraModeIcon';
import './CameraMode.sass'

function CameraMode() {
  const { hub, groups, deviceId } = useOnboardHub();
  const exposureMode = useSelector((state) => state.flight.camera?.exposureMode);
  const camSource = useSelector((state) => state.flight.cameraStats?.camSource);
  const [autoEnabled, setAutoEnabled] = useState(true);
  const [isZoomState, setIsZoomState] = useState(false);
  const { activeCameraPayload } = useDeviceInfo();

  useEffect(() => {
    if (exposureMode)
      setAutoEnabled(exposureMode !== cameraExposureModes.EXPOSURE_MANUAL);
  }, [exposureMode]);

  useEffect(() => {
    if (camSource !== undefined)
      setIsZoomState(camSource === 2);
  }, [camSource]);

  const sendModeValue = (auto) => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      camSetting: {
        payloadIndex: activeCameraPayload?.payloadIndex,
        exposureMode: auto ? cameraExposureModes.PROGRAM_AUTO : cameraExposureModes.EXPOSURE_MANUAL,
      }
    });
  }

  const modeChange = () => {
    if (!isZoomState) return;

    setAutoEnabled(curr => {
      const newMode = !curr;
      sendModeValue(newMode);
      return newMode;
    });
  };

  return (
    <div className="camera-mode-settings">
      <CameraTelemetry
        name="mode"
        title=""
        value={<CameraModeIcon className="camera-icon" autoMode={autoEnabled} />}
        onClick={modeChange}
        disabled={!isZoomState}
      />
    </div>
  );
}

export default CameraMode