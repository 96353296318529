const HubResponseTypes = {
  MESSAGE: "message",
  SYSTEM: "system",
  ACKNOWLEDGE: "ack",
};

export const HubEventName = {
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  CONNECTIVITY_STATUS: "connectivityStatus",
  CHECK_DRONE_STATES: "check-drone-states",
  CHECK_DOCK_STATES: "check-dock-states",
  TELEMETRIES_STATUS: "telemetriesStatus",
  UPLOAD_TO_STORAGE: "upload-to-storage",
  UPLOAD_TO_STORAGE_COMPLETE: "upload-to-storage-complete",
  UPLOAD_TO_STORAGE_FAILS: "upload-to-storage-fails",
  ONBOARDING_STARTED: "onboardingStarted",
  ONBOARDING_COMPLETED: "onboardingCompleted",
  GET_ONBOARD_FILES: "get-onboard-files",
};

export const FileManagerIndexes = {
  GetFiles: 0,
  GetUploadResult: 1,
  GetThumbnail: 2,
};

export const PayloadIndexes = {
  Camera1: 1,
};

export const StorageIndexes = {
  BlobStorage: 0,
};

export const HubActions = {
  UPLOAD_FILES: "uploadFiles",
};
export const HubName = {
  ONBOARD: "onboard",
  FRONTEND: "frontend",
  MOBILE: "mobile",
  DOCK: "dock",
};

export const HubResponseSources = {
  SERVER: "server",
  GROUP: "group",
};

export default class HubConnection {
  instance;
  connectionInfo;
  onClose;
  retry = 0;
  maxRetry = 5;
  allRetries = 0;
  maxRetries = 5;
  url;
  forceClose = false;

  connect = (
    url,
    onReady,
    onConnect,
    onMessage,
    onDisconnect,
    onClose,
    onRetry,
    onRetryFails,
    onPoorConnection
  ) => {
    if (this.allRetries > this.maxRetries && onPoorConnection)
      onPoorConnection();

    this.instance = new WebSocket(url, "json.webpubsub.azure.v1");
    this.instance.addEventListener("open", () => {
      onReady(this.retry);
      this.retry = 0;
    });
    this.instance.addEventListener("error", (error) => {
      console.log('🚧 Websocket connection error', error);
    });
    this.instance.addEventListener("message", (event) => {
      const message = JSON.parse(event.data);
      if (message?.type === HubResponseTypes.SYSTEM) {
        if (message?.event === HubEventName.CONNECTED) {
          const messageParts = message?.userId.split("_");

          this.connectionInfo = {
            connectionId: message?.connectionId,
            userId: messageParts[0],
            hub: messageParts[1],
          };
          onConnect(message);
        } else if (message?.event === HubEventName.DISCONNECTED) {
          onDisconnect(message);
        }
      } else if (message?.type === HubResponseTypes.MESSAGE) {
        onMessage(message);
      }
    });
    this.instance.addEventListener("close", () => {
      if (onRetry) {
        if (this.retry < this.maxRetry) {
          if (!this.forceClose) {
            setTimeout(() => {
              this.connect(
                url,
                onReady,
                onConnect,
                onMessage,
                onDisconnect,
                onClose,
                onRetry,
                onRetryFails,
                onPoorConnection
              );
              this.retry++;
              this.allRetries++;
              onRetry(this.retry);
            }, 2000);
          }
        } else {
          onRetryFails();
        }
      } else {
        onClose();
      }
    });
  };

  constructor(
    url,
    onReady,
    onConnect,
    onMessage,
    onDisconnect,
    onClose,
    onRetry,
    onRetryFails,
    onPoorConnection
  ) {
    this.connect(
      url,
      onReady,
      onConnect,
      onMessage,
      onDisconnect,
      onClose,
      onRetry,
      onRetryFails,
      onPoorConnection
    );
  }

  handleMessage = (event) => {
    const message = JSON.parse(event.data);
    if (
      message?.type === "system" &&
      message.event === HubEventName.CONNECTED
    ) {
      this.connectionInfo = {
        connectionId: message?.connectionId,
        userId: message?.userId.split("_")[0],
      };
    }
  };

  sendMessage = (message) => {
    if (this.instance && this.instance.readyState === this.instance.OPEN)
      this.instance.send(
        JSON.stringify({
          ...message,
        })
      );
  };
}
