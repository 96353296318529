import './MobileCamera.sass';
import CollapsablePanel from '../../../common/CollapsablePanel/CollapsablePanel';
import { TbCameraQuestion } from 'react-icons/tb';
import { useEffect, useMemo, useState } from 'react';
import { IoCloudOfflineOutline } from 'react-icons/io5';
import { DeviceOnlineStatus } from 'src/helper/useDockList';
import VideoStreamView from 'src/ui/Drone/VideoStreamView/VideoStreamView';
import { StageElement } from '../../OperationDashboard';
import classNames from 'classnames';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import { useVideoStream } from 'src/helper/useVideoStream';
import useOnboardStreamingInfo from '../useOnboardStreamingInfo';
import useMobileDeviceInfo from 'src/services/user/common/useMobileDeviceInfo';
import { OrbitProgress } from 'react-loading-indicators';

function MobileCamera({ deviceId, onSwap, expanded = false }) {
  const viewerVideoStreamingConfig = useMobileDeviceInfo(deviceId)?.viewerVideoStreamingConfig;
  const [streamingInfo, setStreamingInfo] = useState(null);
  const { mediaStream } = useVideoStream(streamingInfo);

  const handleSwap = () => {
    onSwap?.(StageElement.MOBILE_DEVICE);
  }

  const connectionMessage = (!streamingInfo ?
    'No video provided.' :
    'Waiting for stream...'
  );

  const connectionMessageIcon = (!streamingInfo ?
    <IoCloudOfflineOutline className="icon" /> :
    <OrbitProgress color="#fff" size="small" text="" textColor="" />
  );

  useEffect(() => {
    setStreamingInfo(prev => {
      if(JSON.stringify(prev) === JSON.stringify(viewerVideoStreamingConfig))
        return prev;

      return viewerVideoStreamingConfig;
    })
  }, [deviceId, viewerVideoStreamingConfig]);

  return (
    <div className={classNames("mobile-camera", {'expanded': expanded})}>
      <CollapsablePanel title="Mobile Camera" expanded={expanded} onSwap={handleSwap}>
        <div className="stream-container">
          <div className="connection-message">
              {connectionMessageIcon}
              <div className="description">{connectionMessage}</div>
          </div>
          <VideoStreamView stream={viewerVideoStreamingConfig && mediaStream} className="video-view" />
        </div>
      </CollapsablePanel>
    </div>
  )
}

export default MobileCamera