import { MdOutlineMyLocation } from 'react-icons/md';
import './DeviceMapToolbar.sass';
import { IoEarth } from 'react-icons/io5';
import { RiZoomInLine } from 'react-icons/ri';
import classNames from 'classnames';
import { TbDeviceAirpodsCase, TbDrone } from 'react-icons/tb';

export default function DeviceMapToolbar({ extended, onRecenterClick, onDeviceZoomClick, onZoomOutClick, edgeSenseEnabled, markerInfo }) {
  const sourceId = markerInfo?.data?.sourceId;
  const subSourceId = markerInfo?.data?.subSourceId;
  const parentSourceId = markerInfo?.data?.parentSourceId;

  return (
    <div className={classNames("device-map-toolbar", { extended })}>
      {!!onRecenterClick && (
        <button 
          className={classNames("toolbar-item toggle", { active: edgeSenseEnabled })} 
          title="Recenter Map / Auto Recenter on Edges" 
          onClick={onRecenterClick}
        >
          <MdOutlineMyLocation className="icon" />
        </button>
      )}
      {(markerInfo?.data?.parentSourceId || markerInfo?.data?.subSourceId) ? <>
        <button 
          className={classNames("toolbar-item toggle", { active: !!parentSourceId })} 
          title="Zoom to Drone" 
          onClick={() => onDeviceZoomClick(subSourceId || sourceId)}
        >
          <TbDrone className="icon" />
        </button>
        <button 
          className={classNames("toolbar-item toggle", { active: !!subSourceId })} 
          title="Zoom to Dock" 
          onClick={() => onDeviceZoomClick(parentSourceId || sourceId)}
        >
          <TbDeviceAirpodsCase className="icon" />
        </button>
      </> : !!onDeviceZoomClick && (
        <button 
          className="toolbar-item" 
          title="Zoom to Selected Device" 
          onClick={onDeviceZoomClick}
        >
          <RiZoomInLine className="icon" />
        </button>
      )}
      {!!onZoomOutClick && (
        <button 
          className="toolbar-item" 
          title="Zoom Out to Show All Devices" 
          onClick={onZoomOutClick}
        >
          <IoEarth className="icon" />
        </button>
      )}
    </div>
  )
}