import "./UserProfileUpdatePhone.sass";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import OperationHandler from "src/ui/OperationHandler/OperationHandler";
import { MdOutlinePhone } from "react-icons/md";
import { setOperation } from "src/components/Dashboard/Components/ApplicationOperationManager/AppOperationStepSlice";
import EnterNumberForm from "./EnterNumberForm/EnterNumberForm";
import VerificationCodeForm from "./VerificationCodeForm/VerificationCodeForm";

function UserProfileUpdatePhone({ onCloseByUser }) {
  const [operationHandlerOpen, setOperationHandlerOpen] = useState(true);
  const dispatch = useDispatch();

  const toggleOperationHandlerOff = () => {
    onCloseByUser?.();
  };

  const toggleOperationHandlerOpen = () => {
    setOperationHandlerOpen(false);
  };

  const feature = {
    id: "change_phone_number",
    title: "Change Phone Number",
    icon: <MdOutlinePhone color="#2699fb" />,
    steps: [
      {
        name: "enter_number",
        title: "Enter Your Number",
        active: true,
        visible: true,
        locked: false,
        closable: false,
        deactivate: true,
        passiveMode: true,
        tags: ["user-verification/sendSMS"],
        activity: <EnterNumberForm />,
      },
      {
        name: "enter_verification_code",
        title: "Verification Code",
        active: false,
        visible: true,
        locked: true,
        fullHeight: false,
        closable: false,
        deactivate: true,
        tags: ["user-verification/sendSMS", "user-verification/verifySMS"],
        activity: <VerificationCodeForm onComplete={onCloseByUser} />,
      },
    ],
  };

  useEffect(() => {
    dispatch(setOperation("enter_number"));
  }, []);

  return (
    <div className="user-profile-update-phone">
      <OperationHandler
        selectedFeature={feature}
        toggle={toggleOperationHandlerOff}
        isOpen={operationHandlerOpen}
        close={toggleOperationHandlerOpen}
        onStepChange={() => { }}
      />
    </div>
  );
}

export default UserProfileUpdatePhone;
