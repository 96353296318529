import { FaMapMarker, FaMedal, FaUser } from 'react-icons/fa';
import './MapPersonMarker.sass';
import { BiSolidJoystick } from 'react-icons/bi';
import { IoEyeSharp } from 'react-icons/io5';
import classNames from 'classnames';

export const PersonMarkerTypes = {
  RC: {
    id: 'rc',
    title: 'RC',
    icon: <BiSolidJoystick />
  },
  VIEWER: {
    id: 'viewer',
    title: 'Viewer',
    icon: <IoEyeSharp />
  },
  LEAD: {
    id: 'lead',
    title: 'Lead',
    icon: <FaMedal />
  }
}

function MapPersonMarker({title, type, className}) {
  return (
    <div className={classNames("map-person-marker", className)} title={type?.title}>
      <div className="icon">
        <FaMapMarker className="location-icon" />
        <FaUser className="user-icon" />
        <div className="type-icon-container">
          {type?.icon}
        </div>
      </div>
      <div className="title">
        {title}
      </div>
    </div>
  )
}

export default MapPersonMarker