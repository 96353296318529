import "./App.sass";
import { ViewportProvider } from "../../helper/ViewPortProvider";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import React from "react";
import { OrientationProvider } from "../../helper/OrientationProvider";
import FrontendHubProvider from "../Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import withClearCache from "../../helper/ClearCache";
import {
  AuthConfigProvider,
  useAuthConfigs,
} from "../../helper/AuthConfigProvider";
import Pages from "./Pages";
import PublicLiveStream from "src/components/public/PublicLiveStream/PublicLiveStream";


// const ClearCacheComponent = withClearCache(Pages);
const App = () => {
  const { configs } = useAuthConfigs();
  const noAuthRequired = window.location.toString().indexOf('/live-stream') !== -1;

  return (
    <div className="App">
      <OrientationProvider>
        <ViewportProvider>
          {noAuthRequired ? (
            <PublicLiveStream />
          ) : (
            <MsalProvider instance={new PublicClientApplication(configs)}>
              <Pages />
            </MsalProvider>
          )}
        </ViewportProvider>
      </OrientationProvider>
    </div>
  );
};

export default App;
