import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../redux/action/api";

const GroupAppSlice = createSlice({
  name: "groupApp",
  initialState: {
    groupName: { status: "" },
    create: { status: "" },
    me: { status: "" },
    list: { status: "" },
    search: { status: "" },
    groupsInformation: { status: "" },
    update: { status: "" },
    assignMembers: { status: "" },
    assignOwners: { status: "" },
    currentGroup: {},
    selectedGroups: [],
  },
  reducers: {
    groupName(state, action) {
      const data = action.payload;
      return { ...state, groupName: { ...data } };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    me(state, action) {
      const data = action.payload;
      return { ...state, me: { ...data } };
    },
    list(state, action) {
      const data = action.payload;
      return { ...state, list: { ...data } };
    },
    search(state, action) {
      const data = action.payload;
      return { ...state, search: { ...data } };
    },
    groupsInformation(state, action) {
      const data = action.payload;
      return { ...state, groupsInformation: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    assignMembers(state, action) {
      const data = action.payload;
      return { ...state, assignMembers: { ...data } };
    },
    assignOwners(state, action) {
      const data = action.payload;
      return { ...state, assignOwners: { ...data } };
    },
    currentGroup(state, action) {
      const data = action.payload;
      return { ...state, currentGroup: { ...data } };
    },
    selectedGroups(state, action) {
      const data = action.payload;
      return { ...state, selectedGroups: [data] };
    },
  },
});
export const {
  groupName,
  create,
  me,
  list,
  search,
  groupsInformation,
  update,
  assignMembers,
  assignOwners,
  currentGroup,
  selectedGroups,
} = GroupAppSlice.actions;
export default GroupAppSlice.reducer;

export const checkGroupName = (groupNameToCheck) =>
  apiCallBegan({
    url: "/groups/check?Name=" + groupNameToCheck,
    method: "Get",
    onSpin: groupName.type,
    onSuccess: groupName.type,
    onError: groupName.type,
  });

export const createGroup = (data) =>
  apiCallBegan({
    url: "/groups/app",
    data,
    method: "Post",
    onSuccess: create.type,
    onError: create.type,
  });

export const myGroups = () =>
  apiCallBegan({
    url: "/groups/app/me",
    method: "Get",
    onSpin: me.type,
    onSuccess: me.type,
    onError: me.type,
  });

export const getUserGroups = () =>
  apiCallBegan({
    url: `/groups/app/me`,
    method: "Get",
    onSpin: list.type,
    onSuccess: list.type,
    onError: list.type,
  });

export const getUserGroupsByName = (name) =>
  apiCallBegan({
    url: `/groups/app/me?Name=${name}`,
    method: "Get",
    onSpin: list.type,
    onSuccess: list.type,
    onError: list.type,
  });

export const searchUsers = (query) =>
  apiCallBegan({
    url: "users/search/" + query,
    method: "Get",
    onSuccess: search.type,
    onError: search.type,
  });

export const optionalSearch = (query, entity) =>
  apiCallBegan({
    url: `${entity !== undefined ? "guests" : "users"}/search/${query}`,
    method: "Get",
    onSuccess: search.type,
    onError: search.type,
  });

export const getGroupsInformation = (query) =>
  apiCallBegan({
    url: `groups/app/info?groupIds=${query}`,
    method: "Get",
    onSuccess: groupsInformation.type,
    onError: groupsInformation.type,
  });

export const updateGroup = (data, id) =>
  apiCallBegan({
    url: "/groups/app/" + id,
    method: "patch",
    data,
    onSuccess: update.type,
    onError: update.type,
  });

export const assignMemberships = (data, id) =>
  apiCallBegan({
    url: "/groups/" + id + "/app/membership",
    method: "post",
    data,
    onSuccess: assignMembers.type,
    onError: assignMembers.type,
  });

export const assignOwnerships = (data, id) =>
  apiCallBegan({
    url: "/groups/" + id + "/app/ownership",
    method: "post",
    data,
    onSuccess: assignOwners.type,
    onError: assignOwners.type,
  });
