import "./EditGroup.sass";
import EditableTextBox from "../../../../../ui/EditableTextBox/EditableTextBox";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../../../../../ui/Button/Button";
import { updateGroup, update } from "../GroupManagementSlice";

function EditGroup({ onOpen, onConfirm }) {
  const table = useSelector((state) => state.table);
  const group = useSelector((state) => state.group);
  const [groupObject, setGroupObject] = useState({});
  const [changeDetector, setChangeDetector] = useState(false);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    name: {
      name: "name",
      title: "Name",
      type: "text",
    },
    description: {
      name: "description",
      title: "Description",
      type: "text",
    },
  });

  useEffect(() => {
    if (group.update.status === "success") {
      dispatch(update({ status: "" }));
      setChangeDetector(false);
      onConfirm();
    } else if (group.update.status === "validation") {
      console.log("validation fails");
    }
  }, [group.update]);

  useEffect(() => {
    const row = table.row;
    var temp = {};
    Object.entries(row).map((item) => {
      var key = item[0];
      var value = item[1];
      if (data[key] !== undefined)
        temp = {
          ...temp,
          [key]: { ...data[key], value: value !== null ? value : "" },
        };
    });
    setData({ ...temp });
  }, [table.row]);

  useEffect(() => {
    onOpen?.();
  }, [data]);

  useEffect(() => {
    setChangeDetector(false);
    Object.entries(groupObject).forEach((item) => {
      if (
        Object.entries(data).filter((x) => {
          return x[0] === item[0];
        })[0][1].value !== item[1]
      ) {
        setChangeDetector(true);
        return;
      }
    });
  }, [groupObject]);

  const changeHandler = (event) => {
    var key = event.target.name;
    var value = event.target.value;
    if (value.toString() !== "") {
      setGroupObject({ ...groupObject, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = groupObject;
      setGroupObject({ ...withoutSecond });
    }
  };

  const submitHandler = () => {
    if (table.row !== undefined)
      dispatch(updateGroup(groupObject, table.row.id));
  };

  return (
    <div className="edit-group-form">
      <div className="edit-group-container">
        <EditableTextBox model={data.name} onChange={changeHandler} />
        <EditableTextBox model={data.description} onChange={changeHandler} placeholder="enter description" />
        {changeDetector && (
          <Button
            value="Save"
            color="#2699fb"
            width="30%"
            onClick={submitHandler}
          />
        )}
      </div>
    </div>
  );
}

export default EditGroup;
