import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import './GimbalReset.sass';
import { MdSettingsBackupRestore } from 'react-icons/md';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';

function GimbalReset() {
  const { hub, groups, deviceId } = useOnboardHub();
  const { activeCameraPayload } = useDeviceInfo();

  const sendGimbalReset = () => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      gimbalReset: {
        payloadIndex: activeCameraPayload?.payloadIndex,
        status: true,
      }
    });
  };

  return !!activeCameraPayload && (
    <div className="gimbal-reset" onClick={sendGimbalReset}>
      <MdSettingsBackupRestore className="icon" title="Reset gimbal angle" />
    </div>
  )
}

export default GimbalReset