import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "src/redux/action/api";

const SecurityAppSlice = createSlice({
  name: "securityApp",
  initialState: {
    createUnit: { status: "" },
    unitList: [],
    selectedUnitId: null,
    selectedUnitMission: null,
    activeMissionTrackingId: null,
    roles: [],
    permissions: [],
  },
  reducers: {
    unitList(state, action) {
      const data = action.payload;
      return { ...state, unitList: data };
    },
    selectedUnitId(state, action) {
      const data = action.payload;
      return { ...state, selectedUnitId: data };
    },
    selectedUnitMission(state, action) {
      const data = action.payload;
      return { ...state, selectedUnitMission: data };
    },
    activeMissionTrackingId(state, action) {
      const data = action.payload;
      return { ...state, activeMissionTrackingId: data };
    },
    createUnit(state, action) {
      const data = action.payload;
      return { ...state, createUnit: { ...data } };
    },
    resetCreateUnit(state, action) {
      return { ...state, createUnit: { status: "" } };
    },
    unitMissionList(state, action) {
      const data = action.payload;
      return { ...state, unitMissionList: data };
    },
  },
});
export const {
  unitList,
  selectedUnitId,
  selectedUnitMission,
  activeMissionTrackingId,
  createUnit,
  resetCreateUnit,
  unitMissionList,
} = SecurityAppSlice.actions;
export default SecurityAppSlice.reducer;

export const getUnitList = () =>
  apiCallBegan({
    url: `/security-app/units`,
    method: "Get",
    onSuccess: unitList.type,
    onError: unitList.type,
  });

export const sendCreateUnit = (data) =>
  apiCallBegan({
    url: "security-app/units",
    method: "Post",
    data,
    onSuccess: createUnit.type,
    onError: createUnit.type,
  });

  export const getUnitMissionList = (unitId) => {
    return apiCallBegan({
      url: `/security-app/units/${unitId}/missions`,
      method: "Get",
      onSuccess: unitMissionList.type,
      onError: unitMissionList.type,
    })};