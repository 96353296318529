import "./TabBar.sass";

import { useState } from "react";
import EmptyTab from "src/ui/EmptyTab/EmptyTab";
import { useEffect } from "react";
import classNames from "classnames";
import { Heading, Text } from "@radix-ui/themes";

function TabBar({ title, tabs, theme, empty, separator = true }) {
  const [tabItems, setTabItems] = useState(tabs);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const setActive = (index) => {
    tabItems.forEach((item) => {
      item.active = false;
    });
    tabItems[index].active = true;
    setActiveTab(tabItems[index]);
  };

  useEffect(() => {
    setTabItems(tabs);
  }, [tabs]);

  useEffect(() => {
    setActiveTab(
      tabItems !== undefined &&
      tabItems.filter((item) => {
        return item.active === true;
      })[0]
    );
  }, [tabItems]);

  return (
    <div className={classNames("tab-bar", { 'with-separator': separator })}>
      <div className="tab-bar-container">
        <Heading size="2" className="tab-bar-header">{title}</Heading>
        <div className="tab-bar-tabs-container">
          <div className="tab-bar-tabs-box">
            <ul className="tab-bar-content-header">
              {tabItems !== undefined &&
                tabItems.map((tab, index) => (
                  <li
                    onClick={() => setActive(tab.index)}
                    key={index}
                    className={`tab-bar-content-title ${tab.active ? "active" : ""
                      }`}
                    style={{
                      borderBottom: tab.active ? "2px solid" + theme : "none",
                    }}
                  >
                    <Text>{tab.title}</Text>
                  </li>
                ))}
            </ul>
          </div>

          {activeTab.content !== undefined ? (
            <div className="tab-bar-content-body">{activeTab.content}</div>
          ) : (
            <div className="tab-bar-content-empty-body">
              <div className="tab-bar-content-empty">
                {/* <img src={empty} alt="IRobotics Empty Tab" /> */}
                <EmptyTab />
                <p>No items added recently.</p>
                <p>
                  From the Recent tab, pin a document and we'll put it here.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TabBar;
