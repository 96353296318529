import classNames from "classnames";
import { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useTheme } from "../../helper/ThemeProvider";
import Button from "../Button/Button";
import { RemoveUserIcon } from "../Icons/Icons";
import "./Dialog.sass";
import { DialogStatus } from "./DialogSlice";

function Dialog({ children, noOperation, floatCloseButton, icon, model, className, opaque }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(DialogStatus({ open: true }));
  }, [dispatch]);
  const [dialog, setDialog] = useState({});
  const { currentContext } = useTheme();
  const [confirmation, setConfirmation] = useState();
  useEffect(() => {
    setDialog({ ...model });
    setConfirmation(!model?.forceConfirm);
  }, [model]);

  const confirmInput = (event) => {
    setConfirmation(event.target.value === dialog?.forceConfirmText);
  };
  return (
    <div className={classNames("dialog", {'opaque': opaque})}>
      <div className={classNames("dialog-container", className)}>
        {floatCloseButton && (
          <div className="dialog-close-button">
            <MdOutlineClose onClick={dialog.CloseHandler} />
          </div>
        )}
        {children ? children : (
          <div className="dialog-header">
            <span>{icon}</span>
            <p>{dialog.message}</p>
          </div>
        )}
        {dialog.forceConfirm && (
          <div className="dialog-force-confirmation">
            <p>Please type {dialog.forceConfirmText} here to confirm</p>
            <input
              type="text"
              onInput={confirmInput}
              style={{ border: `1px solid ${currentContext.theme.color}` }}
              placeholder="Confirmation Text"
            />
          </div>
        )}

        {!noOperation && (
          <div className="dialog-operation">
            <Button
              value="Cancel"
              isSelectable={true}
              width="30%"
              onClick={dialog.CloseHandler}
            />
            <Button
              value="OK"
              color="#2699fb"
              width="30%"
              disabled={!confirmation}
              onClick={dialog.ConfirmHandler}
            />
          </div>
        )}
      </div>
    </div>
  );
}
Dialog.defaultProps = {
  icon: <RemoveUserIcon />,
};

export default Dialog;
