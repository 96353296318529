import ApplicationContainer from "../ApplicationContainer/ApplicationContainer";
import ApplicationOperationManagerStatic from "../ApplicationOperationManagerStatic/ApplicationOperationManagerStatic";
import "./ApplicationContentAreaContainer.sass";

function ApplicationContentAreaContainerStatic({
  histories,
  feature,
  children,
}) {
  return (
    <ApplicationContainer histories={histories}>
      <div className="application-content-area-static-header"></div>
      <div className="application-content-area-static-body">
        <ApplicationOperationManagerStatic selectedFeature={feature} />
      </div>
      {children}
    </ApplicationContainer>
  );
}
ApplicationContentAreaContainerStatic.defaultProps = {
  AppTable: { tags: [], rows: [], show: false },
  operations: [],
};
export default ApplicationContentAreaContainerStatic;
