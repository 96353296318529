import LabeledButton from "../../../../ui/LabeledButton/LabeledButton";
import "./ApplicationOptionBar.sass";
import { FiMoreHorizontal, FiMoreVertical } from "react-icons/fi";
import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import OutSideDetector from "../../../../ui/OutsideDetector/OutSideDetector";
import { useTheme } from "../../../../helper/ThemeProvider";
import SearchOption from "../../../../ui/SearchOption/SearchOption";

function ApplicationOptionBar({ base, buttons, onClick, searchable }) {
  const { currentContext } = useTheme();
  const [toggleMore, setToggleMore] = useState(false);
  const [baseButtons, setBaseButtons] = useState();
  const [moreButtons, setMoreButtons] = useState();
  useEffect(() => {
    setBaseButtons(buttons.slice(0, base));
    setMoreButtons(buttons.slice(base, buttons.length));
  }, [buttons]);
  const moreOptions = () => {
    setToggleMore(!toggleMore);
  };
  return (
    <div
      className="application-option-bar"
      style={{ background: currentContext.theme.secondColor }}
    >
      <div className="application-option-bar-buttons">
        <ul className="application-option-bar-buttons-container">
          {baseButtons &&
            baseButtons.map((button, index) => (
              <li className="application-option-bar-button" key={index}>
                <LabeledButton
                  disabled={!button.active}
                  color="#2699fb"
                  title={button.title}
                  icon={button.icon}
                  onClick={() => {
                    onClick(button.name);
                  }}
                />
              </li>
            ))}
        </ul>
        {moreButtons && moreButtons.length > 0 && (
          <div className="application-option-bar-more">
            {toggleMore ? (
              <>
                <GrClose onClick={moreOptions} />
                <OutSideDetector detect={moreOptions}>
                  <ul
                    className="application-option-bar-more-options"
                    style={{ background: currentContext.theme.secondColor }}
                  >
                    {moreButtons.map((button, index) => (
                      <li className="application-option-bar-button" key={index}>
                        <LabeledButton
                          disabled={!button.active}
                          color="#2699fb"
                          title={button.title}
                          icon={button.icon}
                          onClick={() => {
                            moreOptions();
                            onClick(button.name);
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </OutSideDetector>
              </>
            ) : (
              <FiMoreVertical onClick={moreOptions} />
            )}
          </div>
        )}
        {searchable === true && <SearchOption />}
      </div>
    </div>
  );
}

export default ApplicationOptionBar;
