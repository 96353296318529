import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function RoleResource({ render }) {
  const table = useSelector((state) => state.table);
  const [data, setData] = useState({
    name: {
      name: "name",
      title: "Role Name",
      type: "text",
    },
    category: {
      name: "category",
      title: "Category",
      type: "text",
    },
    description: {
      name: "description",
      title: "Description",
      type: "text",
    },
  });

  useEffect(() => {
    const row = table.row;
    var temp = {};
    Object.entries(row).map((item) => {
      var key = item[0];
      var value = item[1];
      if (data[key] !== undefined)
        temp = {
          ...temp,
          [key]: { ...data[key], value: value !== null ? value : "" },
        };
    });
    setData({ ...temp });
  }, [table.row]);

  return <>{render(data)}</>;
}

export default RoleResource;
