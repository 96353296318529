import './DroneMode.sass'
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const modes = [
  { code: -1, value: "N/A", description: "MODE" },
  { code: 0, value: "MANUAL CTRL", description: "MANUAL" },
  { code: 1, value: "P-ATTI MODE", description: "MODE" },
  { code: 6, value: "P-GPS MODE", description: "MODE" },
  { code: 9, value: "HOTPOINT MODE", description: "MODE" },
  { code: 10, value: "ASSISTED TAKEOFF", description: "ASSISTED" },
  { code: 11, value: "AUTO TAKEOFF", description: "AUTO" },
  { code: 12, value: "AUTO LANDING", description: "AUTO" },
  { code: 14, value: "EXECUTING FLIGHT ROUTE", description: "WAYPOINT" },
  { code: 15, value: "GO-HOME", description: "NAVIGATION" },
  { code: 17, value: "SDK-CTRL", description: "NAVIGATION" },
  { code: 33, value: "FORCE AUTO-LANDING", description: "FORCE" },
  { code: 40, value: "SEARCH MODE", description: "MODE" },
  { code: 41, value: "ENGINE-START", description: "MODE" },
];

function DroneMode() {
  const displayMode = useSelector((state) => state.flight.droneMode.displayMode);
  const currMode = useMemo(() => modes.find(item => item.code === displayMode), [displayMode]);

  return (
    <div className="drone-mode">
      {currMode?.value || null}
    </div>
  )
}

export default DroneMode