import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { envSettings } from './settingsSlice';

function useEnvSettings() {
  const currSettings = useSelector((state) => state.settings.env);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!currSettings) {
      fetch('/appsettings.json')
        .then((r) => r.json())
        .then((settings) => {
          dispatch(envSettings(settings));
        });
    }
  }, []);

  return currSettings;
}

export default useEnvSettings