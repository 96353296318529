import './PrimaryFlightDisplay.sass'
import React, { useMemo } from 'react'
import VerticalSlideMeter from '../VerticalSlideMeter/VerticalSlideMeter'
import { useSelector } from 'react-redux';
import { useViewport } from 'src/helper/ViewPortProvider';
import { useCameraSource } from 'src/helper/CameraSourceProvider';
import AttitudeIndicator from '../AttitudeIndicator/AttitudeIndicator';
import { MeasurementSystem, MeasurementType } from 'src/helper/constants'
import useMeasurement from 'src/helper/useMeasurement';

function PrimaryFlightDisplay() {
  const { height: viewportHeight } = useViewport();
  const { isFpvCamera } = useCameraSource();
  const velocity = useSelector((state) => state.flight.velocity);
  const altitudeInfo = useSelector(state => state.flight.altitude);
  const gpsInfo = useSelector(state => state.flight.gps);
  const flightStatus = useSelector(state => state.flight.droneMode?.flightStatus);
  const { convertValue, measurementSystem } = useMeasurement();

  const speedRangeMin = 0;
  const speedRangeMax = measurementSystem === MeasurementSystem.METRIC ? 50 : 100;
  const speedRangeStep = measurementSystem === MeasurementSystem.METRIC ? 10 : 20;
  const altitudeRangeMin = measurementSystem === MeasurementSystem.METRIC ? -150 : -500;
  const altitudeRangeMax = measurementSystem === MeasurementSystem.METRIC ? 150 : 500;
  const altitudeRangeStep = measurementSystem === MeasurementSystem.METRIC ? 10 : 50;

  const droneAltitude = useMemo(() => {
    if (flightStatus !== 2 || !gpsInfo) return 0;
    return altitudeInfo?.asl - gpsInfo.homepoint?.alt;
    // return altitudeInfo?.height > 0 ? altitudeInfo?.height : gpsAltitude;
  }, [gpsInfo, flightStatus]);

  const heading = useSelector((state) => state.flight.heading);
  const height = useMemo(() => viewportHeight > 500 ? 200 : 100, [viewportHeight]);

  const speed = useMemo(() => {
    return Math.sqrt(Math.pow(velocity.x, 2) + Math.pow(velocity.y, 2));
  }, [velocity.x, velocity.y]);

  const formatVsValue = (value) => {
    const isNegative = value < 0;
    const absValue = Math.abs(value);

    if (!absValue)
      return "0.0";

    const fixedValue = value.toFixed(1);
    const newValue = Math.abs(fixedValue).toString().padStart(1, "0");

    return isNegative && fixedValue < 0 ? '-' + newValue : newValue;
  };

  if (!isFpvCamera) return null;

  return (
    <div className="primary-flight-display">
      <VerticalSlideMeter
        min={speedRangeMin}
        max={speedRangeMax}
        steps={speedRangeStep}
        subSteps={Math.round(speedRangeStep/10)}
        current={convertValue(speed, MeasurementType.SPEED, MeasurementSystem.METRIC)}
        middleUpLabel="WS"
        middleUpValue="N/A"
        inverse={true}
        height={height}
        currentLabel={(
          <>
            <span>SPD</span><br />
            <span>{measurementSystem === MeasurementSystem.METRIC ? 'm/s' : 'mph'}</span>
          </>
        )}
        currentValueFormatter={value => {
          return value ? value.toFixed(1).padStart(4, "0") : "00.0";
        }}
      />
      <AttitudeIndicator pitch={heading.x} roll={-heading.y} />
      <VerticalSlideMeter
        min={altitudeRangeMin}
        max={altitudeRangeMax}
        steps={altitudeRangeStep}
        current={convertValue(droneAltitude, MeasurementType.ALTITUDE, MeasurementSystem.METRIC)}
        middleUpLabel="VS"
        middleUpValue={velocity?.z ? formatVsValue(convertValue(velocity?.z, MeasurementType.SPEED, measurementSystem.METRIC)) : "0.0"}
        bottomLabel="ASL"
        bottomValue={altitudeInfo?.asl ? convertValue(altitudeInfo?.asl, MeasurementType.ALTITUDE, MeasurementSystem.METRIC).toFixed(1) : "0.0"}
        height={height}
        currentLabel={(
          <>
            <span>ALT</span><br />
            <span>{measurementSystem === MeasurementSystem.METRIC ? 'm' : 'ft'}</span>
          </>
        )}
      />
    </div>
  )
}

export default PrimaryFlightDisplay