import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "src/redux/action/api";
import { WaypointLocalState } from "src/ui/Drone/WaypointController/WaypointController";

export const cameraSource = {
  FPV: 'fpv',
  MAIN: 'main'
};

const initialState = {
  connected: false,
  fullscreen: false,
  mapLimitedView: false,
  enlargeMap: false,
  videoPip: false,
  mainVideoSource: 'fpv',
  laserRangeEnabled: false,
  waypoint: {
    inProgress: false,
    state: WaypointLocalState.IDLE,
    startTimestamp: null,
    endTimestamp: null,
    coordinate: null,
    selectedCoordinate: null,
    altitude: 0,
    speed: 0,
    showForm: false,
  },
  notification: { message: "Initializing...", status: "progress" },
  hms: {
    newErrorTimestamp: 0,
    errors: [],
  },
  heading: { x: 0, y: 0, z: 0 },
  gps: {
    lat: 0,
    long: 0,
    alt: 0,
    visibleSats: 0,
    signalLvl: 0,
  },
  altitude: {
    asl: 0,
    height: 0,
  },
  batteries: [],
  files: [],
  uploadResult: {},
  gimbal: { x: 0, y: 0, z: 0 },
  velocity: { x: 0, y: 0, z: 0 },
  deviceModel: null,
  devicePayloads: [],
  activeCameraPayloadSerial: null,
  obstacleSensors: null,
  camera: { aperture: 0, exposureVal: 0, iso: 0, shutterSpeed: 0 },
  compass: { facing: 0, heading: 0, current: 0 },
  type: "",
  cameraStats: {},
  droneMode: { displayMode: -1 },
  videoStreaming: {},
  recordTime: "00:00:00",
  isRecording: false,
  viewerToken: { status: "" },
  viewerTokenRemoval: { status: "" },
  modemInfo: null,
};

const FlightControllerSlice = createSlice({
  name: "flight-dashboard",
  initialState,
  reducers: {
    connected(state, action) {
      const data = action.payload;
      return { ...state, connected: data };
    },
    fullscreen(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, fullscreen: data };
    },
    mapLimitedView(state, action) {
      const data = action.payload;
      return { ...state, mapLimitedView: data };
    },
    enlargeMap(state, action) {
      const data = action.payload;
      return { ...state, enlargeMap: data };
    },
    videoPip(state, action) {
      const data = action.payload ?? !state.videoPip;
      return { ...state, videoPip: data };
    },
    mainVideoSource(state, action) {
      const data = action.payload;
      return { ...state, mainVideoSource: data };
    },
    laserRangeEnabled(state, action) {
      const data = action.payload ?? !state.laserRangeEnabled;
      return { ...state, laserRangeEnabled: data };
    },
    showWaypointForm(state, action) {
      const data = action.payload ?? !state.waypoint.showForm;
      state.waypoint.showForm = data;
      return state;
    },
    setWaypointInfo(state, action) {
      const data = action.payload;
      state.waypoint = {
        ...state.waypoint,
        ...data,
      }
      return state;
    },
    resetWaypoint(state, action) {
      state.waypoint = {
        ...initialState.waypoint,
        showForm: true,
      };

      return state;
    },
    setRecordTime(state, action) {
      const data = action.payload;
      return { ...state, recordTime: data };
    },
    setIsRecording(state, action) {
      const data = action.payload;
      return { ...state, isRecording: data };
    },
    notification(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, notification: data };
    },
    hmsError(state, action) {
      const data = action.payload;
      const currTime = (new Date()).getTime() / 1000;
      let errors = state.hms.errors.map(item => ({ ...item }));
      let newErrorTimestamp = state.hms.newErrorTimestamp;

      data.forEach(dataItem => {
        const index = errors.findIndex(item => item.errorCode === dataItem.errorCode);

        if (index === -1) {
          errors.unshift(dataItem);
          newErrorTimestamp = currTime;
        }
        else errors[index].ts = dataItem.ts;
      });

      errors = errors.filter(item => currTime - item.ts < 10);
      return { ...state, hms: { ...state.hms, newErrorTimestamp, errors } };
    },
    heading(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, heading: data };
    },
    gps(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, gps: data };
    },
    altitude(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, altitude: data };
    },
    uploadResult(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, uploadResult: data };
    },
    batteries(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, batteries: data };
    },
    files(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, files: data };
    },
    gimbal(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, gimbal: data };
    },
    velocity(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, velocity: data };
    },
    devicePayloads(state, action) {
      const data = action.payload;
      return { ...state, devicePayloads: data };
    },
    deviceInfo(state, action) {
      const data = action.payload;
      return { ...state, deviceInfo: data };
    },
    activeCameraPayloadSerial(state, action) {
      const data = action.payload;
      return { ...state, activeCameraPayloadSerial: data };
    },
    obstacleSensors(state, action) {
      const data = action.payload;
      return { ...state, obstacleSensors: data };
    },
    camera(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, camera: data };
    },
    compass(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, compass: data };
    },
    cameraStats(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, cameraStats: data };
    },
    droneMode(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, droneMode: data };
    },
    videoStreaming(state, action) {
      const data = action.payload;
      return { ...state, type: action.type, videoStreaming: data };
    },
    viewerToken(state, action) {
      const data = action.payload;
      return { ...state, viewerToken: { ...data } };
    },
    viewerTokenRemoval(state, action) {
      const data = action.payload;
      return { ...state, viewerTokenRemoval: { ...data } };
    },
    modemInfo(state, action) {
      const data = action.payload;
      return { ...state, modemInfo: data };
    },
    resetFlightInfo(state) {
      return {
        ...initialState,
        connected: state.connected,
        notification: state.notification,
        fullscreen: state.fullscreen,
        enlargeMap: state.enlargeMap,
        mapLimitedView: state.mapLimitedView,
      };
    },
  },
});
export const {
  connected,
  fullscreen,
  mapLimitedView,
  enlargeMap,
  videoPip,
  mainVideoSource,
  laserRangeEnabled,
  showWaypointForm,
  setWaypointInfo,
  resetWaypoint,
  notification,
  hmsError,
  heading,
  gps,
  altitude,
  uploadResult,
  batteries,
  files,
  gimbal,
  velocity,
  deviceInfo,
  devicePayloads,
  activeCameraPayloadSerial,
  obstacleSensors,
  camera,
  type,
  compass,
  cameraStats,
  droneMode,
  videoStreaming,
  setRecordTime,
  setIsRecording,
  viewerToken,
  viewerTokenRemoval,
  modemInfo,
  resetFlightInfo,
} = FlightControllerSlice.actions;

export default FlightControllerSlice.reducer;

export const createViewerToken = (deviceId) =>
  apiCallBegan({
    url: `/devices/app/onboard/live/token/${deviceId}`,
    method: "post",
    data: {},
    onSuccess: viewerToken.type,
    onError: viewerToken.type,
  });

export const removeViewerToken = (deviceId) =>
  apiCallBegan({
    url: `/devices/app/onboard/live/token/${deviceId}`,
    method: "delete",
    data: {},
    onSuccess: viewerTokenRemoval.type,
    onError: viewerTokenRemoval.type,
  });