/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tabletSize, useViewport } from "../../../../helper/ViewPortProvider";
import {
  help,
  stepIndex,
} from "../../../AccountManagement/AccountManagementSlice";
import Button from "../../../../ui/Button/Button";
import Select from "../../../../ui/Select/Select";
import InputBox from "../../../../ui/InputBox/InputBox";
import "./AccountInformation.sass";
import MultiSelect from "../../../../ui/MultiSelect/MultiSelect";
import SmallAlert from "../../../../ui/SmallAlert/SmallAlert";
import useDebounce from "../../../../helper/use-debounce";
import {
  accountMode,
  checkAccountName,
  create,
  createAccount,
  update,
  updateAccount,
  accountResult,
  setAccountModel,
} from "./../../AccountManagementSlice";
import PhoneSelector from "../../../../ui/PhoneSelector/PhoneSelector";
import { activityFields } from "../../../../helper/constants";
import { useSettings } from "src/helper/SettingsProvider";

const AccountInformation = () => {
  const { width } = useViewport();
  const settings = useSettings();
  const [status, setStatus] = useState(undefined);
  const companySizes = [
    { id: 1, name: "<20", value: 20 },
    { id: 2, name: "20-50", value: 50 },
    { id: 3, name: "51-100", value: 100 },
    { id: 4, name: "200>", value: 200 },
  ];

  const regions = [
    { id: 1, name: "Australia", value: "Australia" },
    { id: 2, name: "United Kingdom", value: "UnitedKingdom" },
  ];
  const required = ["CompanyName", "Region", "ActivityFields"];

  const [alerts, setAlerts] = useState({
    CompanyName: { alert: false, type: "error", message: "" },
  });

  const dispatch = useDispatch();

  const [icons, setIcons] = useState({ CompanyName: "default" });
  const [matches, setMatches] = useState(false);
  const account = useSelector((state) => state.account);
  const phone = useSelector((state) => state.phone);
  const [data, setData] = useState(account.model);
  const [CompanyName, setCompanyName] = useState("");
  const debouncedSearchTerm = useDebounce(CompanyName, 1500);

  useEffect(() => {
    setData({ ...account.model });
  }, [account.model]);

  useEffect(() => {
    setAlerts({ ...alerts, [phone.name]: { alert: false } });

    if (phone.value !== undefined && phone.value !== "") {
      dispatch(
        setAccountModel({ ...data, [phone.name]: phone.prefix + phone.value })
      );
    } else {
      const { [phone.name]: value, ...withoutSecond } = data;
      dispatch(setAccountModel({ ...withoutSecond }));
    }
  }, [phone]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(checkAccountName(CompanyName));
    }
  }, [debouncedSearchTerm]);

  const focusHandler = (event) => {
    setStatus(undefined);
  };

  useEffect(() => {
    if (account.create.status === "success") {
      dispatch(accountMode("Edit"));
      dispatch(
        accountResult({
          region: data.Region,
          accountId: account.create.data.accountId,
          message: account.create.data.message,
        })
      );
      dispatch(create({ status: "" }));
      nextStep();
    } else if (account.create.status === "failed") {
      setStatus({
        ...status,
        alert: account.create.status === "failed",
        type: "error",
        message: account.create.message,
      });
    } else if (account.create.status === "validation") {
      let validations = {};
      account.create.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    } else if (account.create.status === "forbidden") {
      setAlerts({
        ...alerts,
        [account.create.message.instance]: {
          alert: true,
          type: "error",
          message: account.create.message.error,
        },
      });
    }
  }, [account.create]);
  const nextStep = () => {
    dispatch(stepIndex(3));
    dispatch(help("confirm"));
  };
  useEffect(() => {
    if (account.update.status === "success") {
      dispatch(
        accountResult({
          ...account.result,
          message: account.update.data.message,
        })
      );
      dispatch(update({ status: "" }));
      nextStep();
    } else if (account.update.status === "notFounded") {
      if (account.mode === "Edit") {
        dispatch(update({ status: "" }));
        dispatch(accountMode("Create"));
      }
    } else if (account.update.status === "validation") {
      let validations = {};
      account.update.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    } else if (account.update.status === "forbidden") {
      setAlerts({
        ...alerts,
        [account.update.reason]: {
          alert: true,
          type: "error",
          message: account.update.message,
        },
      });
    }
  }, [account.update]);

  useEffect(() => {
    setIcons({ ...icons, CompanyName: account.name.status });
    if (account.name.status === "validation") {
      account.name.errors.forEach((item) => {
        setAlerts({
          ...alerts,
          [item.propertyName]: {
            alert: true,
            type: "error",
            message: item.errorMessage,
          },
        });
      });
    } else if (account.name.status === "forbidden") {
      setAlerts({
        ...alerts,
        [account.name.message.instance]: {
          alert: true,
          type: "error",
          message: account.name.message.error,
        },
      });
    } else if (account.name.status === "refresh") {
      alert("Your token is not acquired!");
      window.location.reload();
    }
  }, [account.name]);

  useEffect(() => {
    let res = 0;
    Object.keys(data).forEach((item) => {
      required.includes(item) && res++;
    });
    setMatches(res === required.length);
  }, [data]);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    setAlerts({ ...alerts, [key]: { alert: false } });

    if (key === "ActivityFields") {
      value =
        event.target.value.toString() !== ""
          ? event.target.value.split(",")
          : [];
    }

    if (key === "CompanyName") {
      setIcons({ ...icons, CompanyName: "default" });
      setCompanyName(value);
    }

    if (value.toString() !== "") {
      dispatch(setAccountModel({ ...data, [key]: value }));
    } else {
      const { [key]: value, ...withoutSecond } = data;
      dispatch(setAccountModel({ ...withoutSecond }));
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setStatus(undefined);
    dispatch(setAccountModel({ ...data }));
    //TODO preserve order of inputs on submit for next page
    if (account.mode === "Create") {
      dispatch(createAccount({ ...data }));
    } else if (account.mode === "Edit") {
      dispatch(updateAccount({ ...data, accountId: account.result.accountId }));
    }
  };

  return (
    <form className="account-information" onSubmit={submitHandler}>
      <div className="account-information-container">
        <div className="account-information-inputs">
          <InputBox
            title="Company name"
            name="CompanyName"
            isRequired={true}
            width={width < tabletSize ? "100%" : "48%"}
            onFocus={focusHandler}
            onChange={changeHandler}
            status={alerts.CompanyName}
            value={data.CompanyName}
            index={0}
            icon={icons.CompanyName}
            style={{ background: 'white', color: 'black' }}
          />

          <Select
            title="Region"
            name="Region"
            items={regions}
            isRequired={true}
            onChange={changeHandler}
            width={width < tabletSize ? "100%" : "48%"}
            value={data.Region}
            index={1}
            status={alerts.Region}
          />
        </div>
        <MultiSelect
          title="Fields of activity"
          name="ActivityFields"
          items={activityFields}
          isRequired={true}
          onChange={changeHandler}
          status={alerts.ActivityFields}
          value={data.ActivityFields}
          index={2}
          placeholder="Select Your Fields of activity"
        />

        <div className="account-information-inputs">
          <Select
            title="Company size"
            name="CompanySize"
            width={width < tabletSize ? "100%" : "48%"}
            items={companySizes}
            onChange={changeHandler}
            status={alerts.CompanySize}
            value={data.CompanySize}
            index={3}
          />

          <InputBox
            title="Business number"
            name="BusinessNumber"
            isRequired={false}
            width={width < tabletSize ? "100%" : "48%"}
            onChange={changeHandler}
            status={alerts.BusinessNumber}
            value={data.BusinessNumber}
            index={4}
            style={{ background: 'white', color: 'black' }}
          />
        </div>
        <div className="account-information-inputs">
          <PhoneSelector
            title="Business phone number"
            name="BusinessPhone"
            status={alerts.BusinessPhone}
            width={width < tabletSize ? "100%" : "48%"}
            countries={settings.countries}
            currentValue={phone.value}
            currentPrefix={phone.prefix}
            index={5}
          />
          <InputBox
            title="E-mail"
            name="EmailAddress"
            isRequired={false}
            width={width < tabletSize ? "100%" : "48%"}
            type="email"
            onChange={changeHandler}
            status={alerts.EmailAddress}
            value={data.EmailAddress}
            index={6}
            style={{ background: 'white', color: 'black' }}
          />
        </div>

        {status !== undefined && status.alert && (
          <SmallAlert
            alert={status.alert}
            alertType={status.type}
            message={status.message}
            ratio={0.5}
            hideIcon={true}
          />
        )}
      </div>
      <Button
        value={account.mode.toString()}
        disabled={!matches}
        width={width < tabletSize ? "40%" : "20%"}
        color="#2699fb"
      />
    </form>
  );
};

export default AccountInformation;
