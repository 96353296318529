import { useEffect, useState } from "react";
import { MdDeleteForever, MdRefresh, MdGroup } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import AdminContentAreaContainer from "../../AdminContentAreaContainer/AdminContentAreaContainer";
import "./DeviceManagement.sass";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import { getList, removeDevices } from "./DeviceManagementSlice";
import { FaUserCircle, FaUserEdit } from "react-icons/fa";
import GroupAssignment from "./GroupAssignment/GroupAssignment";
import EditDevice from "./EditDevice/EditDevice";
import EditDeviceOptions from "./EditDeviceOptions/EditDeviceOptions";
import LabeledIcon from "src/ui/LabeledIcon/LabeledIcon";
import Avatar from "src/ui/Avatar/Avatar";
import SVGAvatar from "src/ui/SVGAvatar/SVGAvatar";
import EntityName from "src/ui/EntityName/EntityName";

function DeviceManagement() {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api.state);
  const device = useSelector((state) => state.device);
  const { privileges } = usePrivileges();
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined) {
      if (
        privileges.admins.filter((item) => item.id === "devices")[0] !==
        undefined
      )
        setPermissions([
          ...privileges.admins.filter((item) => item.id === "devices")[0]
            ?.privileges,
        ]);
    }
  }, [privileges.admins]);

  const toggleOperationManagerOff = () => {
    setShowOperationManager(false);
  };
  const toggleManagerOperationOn = () => {
    setShowOperationManager(true);
    setOperationManagerHandler(true);
  };

  const removeDeviceGenerator = (data) => {
    dispatch(removeDevices(data[0]?.id));
  };

  const refreshGenerator = () => {
    dispatch(getList());
  };

  useEffect(() => {
    if (api !== undefined) {
      dispatch(getList());
    }
  }, [api]);

  useEffect(() => {
    if (device?.list?.data !== undefined) {
      const devices = [];
      device.list.data.devices.map((item) =>
        devices.push({
          id: item.id,
          model: item.model,
          serialNumber: item.serialNumber,
          manufacturer: item.manufacturer,
          dfrSetting: item.dfrSetting,
          registeredBy: (
            <EntityName
              item={{
                title: item.registeredBy.displayName,
                subtitle: item.registeredBy.emailAddress,
                icon: item.registeredBy.avatar ? (
                  <Avatar
                    image={item.registeredBy.avatar}
                    isCovered={true}
                    width="2em"
                    height="2em"
                  />
                ) : (
                  <SVGAvatar />
                ),
              }}
            />
          ),
          sdk: item.sdk,
          videoStreamingRegion: item.videoStreamingRegion,
          dataStreamingRegion: item.dataStreamingRegion,
          unitOfMeasurement: item.unitOfMeasurement,
          deviceName: item.deviceName,
          firmwareVersion: item.firmwareVersion,
          groups: item.groups,
          active: false,
        })
      );
      setRows({
        ...rows,
        values: [...devices],
      });
    }
  }, [device?.list]);

  const histories = [
    { title: "Home", link: "/admin/home" },
    { title: "Devices", link: "/admin/devices" },
  ];
  const [features, setFeatures] = useState([]);
  const [options, setOptions] = useState([]);

  const dataOptions = [
    {
      title: "Delete Device",
      icon: <MdDeleteForever />,
      name: "delete_devices",
      active: true,
    },
    {
      title: "Edit Device",
      icon: <FaUserEdit />,
      name: "edit_device",
      active: true,
      selected: true,
    },
  ];

  const userOptions = [
    {
      title: "Create Device",
      icon: <AiOutlineUsergroupAdd />,
      name: "create_device",
      active: true,
    },
    {
      title: "Refresh",
      icon: <MdRefresh />,
      name: "list_devices",
      active: true,
    },
    {
      title: "Delete Device",
      icon: <MdDeleteForever />,
      name: "delete_devices",
      type: "dependent",
      singular: true,
      active: false,
    },
    {
      title: "Edit Device",
      icon: <FaUserEdit />,
      name: "edit_device",
      type: "dependent",
      active: false,
      singular: true,
    },
    {
      title: "Group Assignment",
      icon: <MdGroup />,
      name: "device_groups_assignment",
      type: "dependent",
      active: false,
      singular: true,
    },
  ];

  const userFeatures = [
    {
      id: "create_device",
      title: "Create Device",
      description: "You can create a device ",
      icon: <AiOutlineUsergroupAdd />,
      color: "#2699fb",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [],
    },
    {
      id: "delete_devices",
      title: "Delete Device(s)",
      confirm: "Are you sure for deleting selected device?",
      description: "You can delete a device under your company account",
      type: "dialog",
      actionGenerator: removeDeviceGenerator,
    },
    {
      id: "list_devices",
      type: "no-op",
      actionGenerator: refreshGenerator,
    },
    {
      id: "edit_device",
      title: "Edit Device",
      description: "Please input all measurement units in meters. If the 'imperial' option is selected under 'edit device' settings, the system will automatically convert these measurements to imperial units across all applications.",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_device",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["device/update", "device/updateDfr", "group/details", "device/groupAssignment"],
          activity: (
            <EditDeviceOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
    {
      id: "device_groups_assignment",
      title: "Edit Device",
      description: "You edit device properties for selected device.",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_device",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["device/update", "device/updateDfr", "group/details", "device/groupAssignment"],
          activity: (
            <EditDeviceOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
              defaultIndex={1}
            />
          ),
        },
      ],
    },
  ];

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Name", value: "deviceName", status: false },
      { title: "Model", value: "model", status: true },
      { title: "Serial Number", value: "serialNumber", status: true },
      { title: "Manufacturer", value: "manufacturer", status: true },
      { title: "Registered By", value: "registeredBy", status: true },
      { title: "SDK", value: "sdk", status: false },
      {
        title: "Video Streaming Region",
        value: "videoStreamingRegion",
        status: true,
      },
      {
        title: "Data Streaming Region",
        value: "dataStreamingRegion",
        status: true,
      },
      {
        title: "Current Firmware Version",
        value: "firmwareVersion",
        status: false,
      },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    if (device.create.status === "success") {
      dispatch(getList());
    }
  }, [device.create]);

  useEffect(() => {
    if (device.groupAssignment.status === "success") {
      dispatch(getList());
    }
  }, [device.groupAssignment]);

  useEffect(() => {
    if (device.remove.status === "success") {
      dispatch(getList());
    }
  }, [device.remove]);

  useEffect(() => {
    if (device.updateDfr.status === "success") {
      dispatch(getList());
    }
  }, [device.updateDfr]);

  useEffect(() => {
    if (device.update.status === "success") {
      dispatch(getList());
    }
  }, [device.update]);

  useEffect(() => {
    setFeatures([
      ...userFeatures
        .filter((feature) => permissions.includes(feature.id))
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        })),
    ]);
    setOptions([
      ...userOptions.filter((option) => permissions.includes(option.name)),
    ]);
    setRows({
      ...rows,
      options: dataOptions.filter((option) =>
        permissions.includes(option.name)
      ),
    });
  }, [permissions]);

  const [currentFeature, setCurrentFeature] = useState();
  const [currentFeatureLabel, setCurrentFeatureLabel] = useState("edit_device");
  const onFeatureChanged = (name) => {
    if (["list_devices", "delete_devices"].includes(name)) return;
    setCurrentFeatureLabel(name);
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === name)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
    toggleManagerOperationOn();
  };
  const onRowChange = () => {
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === currentFeatureLabel)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
    toggleManagerOperationOn();
  };

  return (
    <AdminContentAreaContainer
      histories={histories}
      title="Device Management"
      data={rows}
      features={[...features]}
      operations={[...options]}
      table={{
        multiSelect: false,
        tags: ["device/list", "device/remove"],
        show: true,
      }}
      operationManager={showOperationManager}
      managerHandler={operationManagerHandler}
      onRowChange={onRowChange}
      currentFeature={currentFeature}
      onCurrentFeatureChanged={onFeatureChanged}
    />
  );
}

export default DeviceManagement;
