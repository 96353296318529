import './FlightStatusBar.sass'
import logo from "src/assets/img/logo-mini-white.svg"
import FlightStatusMessage from './FlightStatusMessage/FlightStatusMessage'
import { MdOutlineMoreHoriz } from 'react-icons/md'
import BatteryPercent from './BatteryPercent/BatteryPercent'
import GPSSignal from './GPSSignal/GPSSignal'
import RemoteControlSignal from './RemoteControlSignal/RemoteControlSignal'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VscScreenFull, VscScreenNormal } from 'react-icons/vsc'
import { fullscreen } from '../FlightControllerSlice'
import DesktopSize from 'src/ui/DesktopSize/DesktopSize'
import DroneMode from './DroneMode/DroneMode'
import StreamRegion from './StreamRegion/StreamRegion'
import NetworkSignal from 'src/ui/Drone/DroneComponents/NetworkSignal/NetworkSignal'
import NetworkInfo from './NetworkInfo/NetworkInfo'

function FlightStatusBar({showHms}) {
  const [fullscreenToggle, setFullscreenToggle] = useState(false);
  const dispatch = useDispatch();
  const isFullScreen = useSelector((state) => state.flight.fullscreen);
  const mapLimitedViewState = useSelector((state) => state.flight.mapLimitedView);

  useEffect(() => {
    setFullscreenToggle(isFullScreen);
  }, [isFullScreen]);

  return (
    <div className="flight-status-bar">
      <div className="container">
        <div className="left-side">
          <img src={logo} alt="logo" className="logo" />
          {!!showHms && <FlightStatusMessage />}
        </div>
        <div className="right-side">
          {!showHms && <DroneMode />}
          <StreamRegion />
          <GPSSignal />
          {/* <RemoteControlSignal size="2.8em" /> */}
          <NetworkInfo />
          <BatteryPercent />
          {!mapLimitedViewState ? (
            <DesktopSize>
              <div className="more-button">
                {fullscreenToggle ? (
                  <VscScreenNormal cursor="pointer" onClick={() => {
                    dispatch(fullscreen(false));
                  }} />
                ) : (
                  <VscScreenFull cursor="pointer" onClick={() => {
                    dispatch(fullscreen(true));
                  }} />
                )}
                {/* <MdOutlineMoreHoriz /> */}
              </div>
            </DesktopSize>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default FlightStatusBar