import "./SwiperSlider.sass";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { useEffect, useRef, useState } from "react";
import { useCallback } from "react";

function SwiperSlider({
  items,
  b320,
  b1024,
  b1280,
  initialSlide,
  navigation,
  onSlideChange,
  spaceBetween,
  nextEl,
  prevEl,
  activeColor,
  passiveColor,
  vicinityColor,
  customActive,
  activeSlide,
  direction,
  centeredSlides,
}) {
  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
  const [swiper, setSwiper] = useState();
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const slide = useRef();

  const slideChange = (event) => {
    onSlideChange(event.activeIndex);
    setCurrentSlide(event.activeIndex);
  };

  const getColor = useCallback((index, isActive) => {
    if(isActive) return activeColor;

    if(vicinityColor && (index === currentSlide - 1 || index === currentSlide + 1)) {
        return vicinityColor;
    }

    return passiveColor;
  }, [currentSlide]);

  useEffect(() => {
    slide.current = activeSlide;
  }, [activeSlide]);

  useEffect(() => {
    if (swiper !== undefined && slide.current !== undefined) {
      swiper.slideTo(slide.current);
      slide.current = undefined;
    }
  }, [swiper, activeSlide]);

  return (
    <Swiper
      onSwiper={setSwiper}
      onSlideChange={slideChange}
      navigation={{ navigation, nextEl, prevEl }}
      spaceBetween={spaceBetween}
      direction={direction || "horizontal"}
      centeredSlides={centeredSlides}
      slideToClickedSlide={true}
      touchStartPreventDefault={false}
      breakpoints={{
        // when window width is >= 640px
        320: {
          slidesPerView: b320,
        },
        // when window width is >= 768px
        1024: {
          slidesPerView: b1024,
          initialSlide,
        },
        1280: {
          slidesPerView: b1280,
          initialSlide,
        },
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide key={index}>
          {({ isActive }) => {
            return customActive ? (
              <span
                style={{
                  color: getColor(index, isActive),
                  fontWeight: isActive ? "bold" : "normal",
                }}
              >
                {item.component}
              </span>
            ) : (
              item.component
            );
          }}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
SwiperSlider.defaultProps = {
  b320: 1,
  b1024: 2,
  b1280: 3,
  navigation: null,
  prevEl: null,
  nextEl: null,
  activeSlide: 0,
  spaceBetween: 10,
  initialSlide: 0,
  customActive: false,
  passiveColor: "white",
  onSlideChange: () => {},
};

export default SwiperSlider;
