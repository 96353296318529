import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PayloadSourceContext = createContext({});
export const PayloadSourceProvider = ({ children }) => {
  const zoomFactor = useSelector(
    (state) => state.flight.cameraStats?.zoomFactor
  );
  const [isPayloadDisconnected, setIsPayloadDisconnected] = useState(true);

  useEffect(() => {
    if (zoomFactor !== undefined) {
      setIsPayloadDisconnected(zoomFactor === -1);
      // flight.cameraStats?.zoomFactor === 0.05999999865889549
    }
  }, [zoomFactor]);

  return (
    <PayloadSourceContext.Provider value={{ isPayloadDisconnected }}>
      {children}
    </PayloadSourceContext.Provider>
  );
};

export const usePayloadSource = () => {
  const { isPayloadDisconnected } = useContext(PayloadSourceContext);
  return { isPayloadDisconnected };
};
