import { useState } from "react";
import LaserRangeMainDisplay from "src/ui/Drone/LaserRangeMainDisplay/LaserRangeMainDisplay";
import { useCameraSource } from "../../../../../helper/CameraSourceProvider";
import DroneCameraManager from "../../../../../ui/Drone/DroneCameraManager/DroneCameraManager";
import FocusWindow from "../../../../../ui/Drone/FocusWindow/FocusWindow";
import "./FlightCameraManager.sass";

function FlightCameraManager() {
  const [currentZoom, setCurrentZoom] = useState(0);
  const { isMainCamera } = useCameraSource();

  const zoomChangeHandler = (zoom) => {
    setCurrentZoom(zoom);
  };

  return (
    <div className="flight-camera-manager">
      {/* <div className="gimbal-area">
        <Gimbal
          pointer={<GimbalPITCHPointer size="6" time=".05" />}
          name="PITCH"
          offset="180"
          rotate="40"
        />
        <Gimbal
          pointer={<GimbalROLLPointer size="6" time=".05" />}
          name="ROLL"
          offset="0"
          rotate="359"
        />
        <Gimbal
          pointer={<GimbalYAWPointer size="6" time=".05" />}
          name="YAW"
          offset="0"
          rotate="180"
        />
      </div> */}

      <div className="focus-area">
        {isMainCamera ? (
          <>
            <FocusWindow size={currentZoom} />
            <LaserRangeMainDisplay />
          </>
        ) : null}
      </div>
      <div className="camera-manager-area">
        <DroneCameraManager zoomChanged={zoomChangeHandler} />
      </div>
    </div>
  );
}

export default FlightCameraManager;
