import './EditContainerOptions.sass'

import React, { useEffect, useMemo } from 'react'
import { usePrivileges } from 'src/helper/AccountStateProvider';
import Tabs from 'src/ui/Tabs/Tabs';
import GroupAssignment from '../GroupAssignment/GroupAssignment';
import EditContainer from '../EditContainer/EditContainer';

function EditContainerOptions({ onOpen, onConfirm, defaultIndex }) {
  const { privileges } = usePrivileges();
  const permissions = useMemo(() => {
    return privileges?.admins.filter(item => item.id === "storages" || item.id === "containers")
      .map(item => item.privileges.filter(pr => pr !== "")).flat();
  }, [privileges]);

  console.log('container-permission', privileges, permissions);

  const hasPermission = (id) => permissions?.includes(id);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className="edit-container-options">
      <Tabs
        defaultIndex={defaultIndex}
        items={[
          {
            title: "Edit Metadata",
            content: <EditContainer />,
            hidden: !hasPermission('edit_container')
          },
          {
            title: "Group Assignment",
            content: <GroupAssignment onConfirm={onConfirm} />,
            hidden: !hasPermission('container_groups_assignment')
          },
        ]}
      />
    </div>
  )
}

export default EditContainerOptions