import "./AppTable.sass";
import { FiMoreVertical } from "react-icons/fi";
import { useEffect, useState } from "react";
import { ChooseColumns } from "../Icons/Icons";
import OutSideDetector from "../OutsideDetector/OutSideDetector";
import CheckBox from "../CheckBox/CheckBox";
import LabeledButton from "../LabeledButton/LabeledButton";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import { useAccount } from "../../helper/AccountStateProvider";
import { SetCurrentRow } from "./AppTableSlice";
import { useTheme } from "../../helper/ThemeProvider";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";

function AppTable({
  rows,
  onRowChange,
  onRowsChange,
  onRowSelect,
  optionChangeHandler,
  tags,
  selectable,
  boldIndexes,
  multiSelect,
  disableColumnSelector,
}) {
  const [localRows, setLocalRows] = useState({
    values: [],
    headers: [],
    options: [],
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [markAll, setMarkAll] = useState(false);
  const [checkMultiSelect, setCheckMultiSelect] = useState(false);
  const [toggleColumnSelector, setToggleColumnSelector] = useState(false);
  const loading = useSelector((state) => state.loading);
  const { account } = useAccount();
  const dispatch = useDispatch();
  const { currentContext } = useTheme();

  useEffect(() => {
    setCheckMultiSelect(multiSelect);
  }, [multiSelect]);

  const checkSelected = (row) => {
    dispatch(
      SetCurrentRow(
        Object.entries(row)
          .map((y) => ({ [y[0]]: y[1] }))
          .reduce((z, w) => Object.assign(z, w), {})
      )
    );
    if (checkMultiSelect) {
      setLocalRows({
        ...localRows,
        values: [
          ...localRows.values.map((item) => ({
            ...item,
            active: item.id === row.id ? !item.active : item.active,
          })),
        ],
      });
    } else {
      setLocalRows({
        ...localRows,
        values: [
          ...localRows.values.map((item) => ({
            ...item,
            active: item.id === row.id ? true : false,
          })),
        ],
      });
    }
    onRowChange(row);
  };

  const checkSelectedRow = (row) => {
    dispatch(
      SetCurrentRow(
        Object.entries(row)
          .map((y) => ({ [y[0]]: y[1] }))
          .reduce((z, w) => Object.assign(z, w), {})
      )
    );
    if (checkMultiSelect) {
      setLocalRows({
        ...localRows,
        values: [
          ...localRows.values.map((item) => ({
            ...item,
            active: item.id === row.id ? !item.active : item.active,
          })),
        ],
      });
    } else {
      setLocalRows({
        ...localRows,
        values: [
          ...localRows.values.map((item) => ({
            ...item,
            active: item.id === row.id ? true : false,
          })),
        ],
      });
    }
  };

  useEffect(() => {
    setSelectedRows(localRows.values.filter((x) => x.active === true));
    onRowChange(localRows.values.find((x) => x.active === true));
  }, [localRows]);

  useEffect(() => {
    onRowsChange(selectedRows);
    if (onRowSelect !== undefined) onRowSelect(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    setLocalRows(rows);
  }, [rows]);

  useEffect(() => {
    setLocalRows({
      ...localRows,
      values: [...localRows.values.map((row) => ({ ...row, active: markAll }))],
    });
  }, [markAll]);

  const moreOptionsHandler = (row) => {
    dispatch(
      SetCurrentRow(
        Object.entries(row)
          .filter((x) => typeof x[1] === "string")
          .map((y) => ({ [y[0]]: y[1] }))
          .reduce((z, w) => Object.assign(z, w), {})
      )
    );
    const conflict = row.id === account.user.localAccountId;
    setLocalRows({
      ...localRows,
      options: [
        ...localRows.options.map((item) => ({
          ...item,
          active:
            item.type === "dependent"
              ? item.except === "account" && !conflict
              : item.active,
        })),
      ],
      values: [
        ...localRows.values.map((item) => ({
          ...item,
          active: item.id === row.id,
          moreOption: item.id === row.id ? !row.moreOption : false,
        })),
      ],
    });
  };

  const triggerColumns = () => {
    setToggleColumnSelector(!toggleColumnSelector);
  };

  const chooseColumnHandler = (event) => {
    const key = event.target.name;
    const value = event.target.checked;
    localRows.headers.map((row) =>
      row.value === key ? (row.status = value) : ""
    );

    setLocalRows({
      ...localRows,
      headers: [...localRows.headers],
    });
  };

  return (
    <div className="app-table-wrapper">
      {loading.status &&
        (tags.includes(loading.name) ||
          loading.name === "accountState/accountState") && <Loading />}
      <div className="app-table-container">
        <table className="app-table">
          <thead>
            <tr>
              {selectable && (
                <th
                  onClick={(event) => {
                    event.stopPropagation();
                    setMarkAll(!markAll);
                  }}
                >
                  {markAll ? (
                    <IoIosCheckmarkCircle
                      cursor="pointer"
                      fontSize="1.5em"
                      color={currentContext.theme.color}
                    />
                  ) : (
                    <RiCheckboxBlankCircleLine
                      cursor="pointer"
                      fontSize="1.5em"
                      color={currentContext.theme.color}
                    />
                  )}
                </th>
              )}

              {localRows.headers.map(
                (rKey, kIndex) =>
                  rKey.status === true && (
                    <th className="app-table-head-row" key={kIndex}>
                      {rKey.title}
                    </th>
                  )
              )}
              {!disableColumnSelector && (
                <th>
                  <ChooseColumns onClick={triggerColumns} />
                  {toggleColumnSelector && (
                    <OutSideDetector
                      detect={triggerColumns}
                      className="app-table-head-choose-columns-container"
                    >
                      <ul className="app-table-head-choose-columns">
                        {localRows.headers.map((column, index) => (
                          <li
                            key={index}
                            className="app-table-choose-column-item"
                          >
                            <CheckBox
                              name={column.value}
                              text={column.title}
                              checked={column.status}
                              onChecked={chooseColumnHandler}
                            />
                          </li>
                        ))}
                      </ul>
                    </OutSideDetector>
                  )}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {localRows.values.map((row, index) => (
              <tr
                className="app-table-body-row"
                key={index}
                onClick={(event) => {
                  event.stopPropagation();
                  checkSelected(row);
                }}
                style={{
                  background: row.active && currentContext.theme.color + "09",
                }}
              >
                {selectable && (
                  <td
                    className="table-body-item-select"
                    onClick={(event) => {
                      event.stopPropagation();
                      checkSelectedRow(row);
                    }}
                  >
                    {row.active ? (
                      <IoIosCheckmarkCircle
                        cursor="pointer"
                        fontSize="1.5em"
                        color={currentContext.theme.color}
                      />
                    ) : (
                      <RiCheckboxBlankCircleLine
                        cursor="pointer"
                        fontSize="1.5em"
                        color={currentContext.theme.color}
                      />
                    )}
                  </td>
                )}
                {Object.entries(row).map((item, index) =>
                  localRows.headers.map(
                    (x) =>
                      x.value === item[0] &&
                      x.status === true && (
                        <td
                          key={index + 1}
                          className={
                            boldIndexes.includes(index) ? "bolder" : ""
                          }
                        >
                          {item[1]}
                        </td>
                      )
                  )
                )}
                <td className="app-table-body-item-more">
                  {localRows.options.length > 0 && (
                    <FiMoreVertical
                      color="#272d3b"
                      onClick={(event) => {
                        event.stopPropagation();
                        moreOptionsHandler(row);
                      }}
                    />
                  )}

                  {row.moreOption === true && (
                    <OutSideDetector
                      className="app-table-item-more-options"
                      object={row}
                      detect={moreOptionsHandler}
                      style={{ background: currentContext.theme.secondColor }}
                    >
                      <ul
                        className="item-more-options-container"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        {localRows.options &&
                          localRows.options.map((option, index) => (
                            <li className="item-more-option" key={index}>
                              <LabeledButton
                                disabled={!option.active}
                                title={option.title}
                                icon={option.icon}
                                onClick={() => {
                                  moreOptionsHandler(row);
                                  optionChangeHandler(option.name);
                                }}
                              />
                            </li>
                          ))}
                      </ul>
                    </OutSideDetector>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
AppTable.defaultProps = {
  tags: [],
  boldIndexes: [],
  multiSelect: false,
};
export default AppTable;
