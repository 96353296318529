import { MdHeight, MdLink, MdOutlineSatelliteAlt } from "react-icons/md";
import { TbActivity, TbBattery3, TbDatabase } from "react-icons/tb";
import SignalQualityIcon from "src/hci/other/SignalQualityIcon";
import { MeasurementSystem, MeasurementType } from "src/helper/constants";
import useMeasurement from "src/helper/useMeasurement";
import { DjiDockDroneModeText, DjiCloudLinkWorkModeText } from "src/services/device/common/deviceConstants";
import prettyBytes from 'pretty-bytes';

export const CloudDroneTelemetryItems = ({telemetries, dockTelemetries, render}) => {
  const { convertValue, getMeasurementUnit } = useMeasurement();

  const items = [
    {
      title: 'Battery',
      value: `${telemetries?.battery?.capacity_percent ?? '-'}%`,
      icon: <TbBattery3 />
    },
    {
      title: 'Satellites',
      value: `${telemetries?.position_state?.gps_number ?? '-'}`,
      icon: <MdOutlineSatelliteAlt />
    },
    {
      title: 'SPD',
      value: `${convertValue(telemetries?.horizontal_speed, MeasurementType.SPEED, MeasurementSystem.METRIC)} ${getMeasurementUnit(MeasurementType.SPEED)}`,
    },
    {
      title: 'V.S',
      value: `${convertValue(telemetries?.vertical_speed, MeasurementType.SPEED, MeasurementSystem.METRIC)} ${getMeasurementUnit(MeasurementType.SPEED)}`,
    },
    {
      title: 'Elevation (ALT)',
      value: `${Number(convertValue(telemetries?.elevation, MeasurementType.ALTITUDE, MeasurementSystem.METRIC)).toFixed(2) ?? '-'} ${getMeasurementUnit(MeasurementType.ALTITUDE)}`,
      icon: <MdHeight />
    },
    {
      title: 'E.H',
      value: `${Number(convertValue(telemetries?.height, MeasurementType.ALTITUDE, MeasurementSystem.METRIC)).toFixed(2) ?? '-'} ${getMeasurementUnit(MeasurementType.ALTITUDE)}`,
    },
    ...(
      telemetries?.storage?.total ? [
        {
          name: 'Storage status',
          value: `${prettyBytes(telemetries?.storage?.total * 1024 )} (${Math.ceil((telemetries?.storage?.used / telemetries?.storage?.total) * 100)}% used)`, // Kilobytes to bytes
          icon: <TbDatabase />
        }
      ] : []
    ),
    ...(
      dockTelemetries?.wireless_link?.dongle_number ? [
        {
          name: `Current connection link`,
          value: `${DjiCloudLinkWorkModeText[dockTelemetries?.wireless_link?.link_workmode]} Link`,
          icon: <MdLink />
        },
        {
          name: `4G Signal ${dockTelemetries?.wireless_link?.['4g_uav_quality'] * 20}`,
          value: '4G',
          icon: <SignalQualityIcon quality={dockTelemetries?.wireless_link?.['4g_uav_quality']} />,
        },
      ] : []
    ),
    {
      name: `SDR Signal ${dockTelemetries?.wireless_link?.sdr_quality * 20}`,
      value: 'SDR',
      icon: <SignalQualityIcon quality={dockTelemetries?.wireless_link?.sdr_quality} />
    },
    {
      name: 'Operation Mode',
      value: `${DjiDockDroneModeText[telemetries?.mode_code] ?? '-'}`,
      icon: <TbActivity />
    },
  ];

  return render ? render(items) : items;
};