import classNames from 'classnames';
import './CloudVideoTypeToolbar.sass'
import { useEffect, useState } from 'react'
import { dockConnectionManager } from 'src/helper/HubConnectionManager';
import { djiCloudMethod } from 'src/services/common/constants';
import useDeviceConnection from 'src/services/device/common/useDeviceConnection';
import useDeviceLiveCapacity from 'src/services/device/common/useDeviceLiveCapacity';

function CloudVideoTypeToolbar({ deviceId, serialNumber, config, className, ...props }) {
  let { 
    video_type: currVideoType, 
    switchable_video_types: switchableVideoTypes,
    camera_index: cameraIndex
  } = config || {};

  switchableVideoTypes = switchableVideoTypes?.filter(item => item !== 'normal');
  currVideoType = currVideoType === 'normal' ? 'wide' : currVideoType;
  
  const [videoType, setVideoType] = useState(currVideoType);
  const connection = useDeviceConnection(deviceId);
  const { firstVideoInfo: videoCapacity } = useDeviceLiveCapacity(deviceId, serialNumber);
  const videoId = `${serialNumber}/${cameraIndex}/normal-0`;
  

  const hadnleChangeVideoType = (type) => {
    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.live.LIVE_LENS_CHANGE,
      data: {
        video_id: videoId,
        video_type: type
      }
    });
  }

  useEffect(() => {
    setVideoType(videoCapacity?.video_type);
  }, [videoCapacity]);

  useEffect(() => {
    if(!videoType)
      setVideoType(currVideoType);
  }, [currVideoType]);

  if(!switchableVideoTypes || switchableVideoTypes.length < 2) return null;

  return (
    <div className={classNames('cloud-video-type-toolbar', className)} {...props}>
      <div className='toolbar'>
        {switchableVideoTypes.map((type, index) => (
          <div 
            key={index} 
            className={`toolbar-item ${videoType === type ? 'active' : ''}`}
            onClick={() => hadnleChangeVideoType(type)}
          >
            {type}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CloudVideoTypeToolbar