import "./DateComponent.sass";
import { MdDateRange } from "react-icons/md";

function DateComponent({ date }) {
  return (
    <div className="date-component">
      <MdDateRange />
      <span>{date}</span>
    </div>
  );
}

export default DateComponent;
