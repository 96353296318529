import './AttitudeIndicator.sass'

import React from 'react'
import Crosshair from 'src/ui/Crosshair/Crosshair'

function AttitudeIndicator({ pitch = 0, roll = 0 }) {

  const transformPitch = x => {
    const min = -25;
    const max = 25;
    const minOutput = -120;
    const maxOutput = 120;

    return (x - min) / (max - min) * (maxOutput - minOutput) + minOutput;
  };

  const transformRoll = x => {
    const min = -25;
    const max = 25;
    const minOutput = -30;
    const maxOutput = 30;

    return (x - min) / (max - min) * (maxOutput - minOutput) + minOutput;
  };

  return (
    <div className="attitude-indicator">
      <Crosshair />
      <div className="horizon-line" style={{
        top: transformPitch(pitch) + 'px',
        transform: `rotate(${transformRoll(roll)}deg)`
      }}>
        <div className="part-left"></div>
        <div className="part-right"></div>
      </div>
    </div>
  )
}

export default AttitudeIndicator