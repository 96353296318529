import "./DesktopSize.sass";
import { tabletSize, useViewport } from "../../helper/ViewPortProvider";

function DesktopSize({ children }) {
  const { width } = useViewport();
  const IsDesktopSize = () => {
    return width > tabletSize;
  };
  return <> {width > tabletSize && children} </>;
}

export default DesktopSize;
