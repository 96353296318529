import { useSelector } from "react-redux";
import useDeviceInfo from "./useDeviceInfo";
import useDeviceConnection from "./useDeviceConnection";
import { useEffect, useRef, useState } from "react";
import { dockConnectionManager } from "src/helper/HubConnectionManager";

export default function useDeviceLiveCapacity (deviceId, targetSerialNumber) {
  const deviceInfo = useDeviceInfo(deviceId);
  const connection = useDeviceConnection(deviceId);
  const groupHandlerIds = useRef([]);
  const [liveCapacity, setLiveCapacity] = useState();

  useEffect(() => {
    dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.state],
        handler: (message) => {
          if(message.data?.live_capacity) {
            const liveCapacity = message.data.live_capacity.device_list.find(device => device.sn === (targetSerialNumber || deviceInfo?.serialNumber));
            
            if(liveCapacity) {
              setLiveCapacity(liveCapacity);
              console.log('🎦 DEVICE_LIVE_CAPACITY', deviceInfo.serialNumber, liveCapacity);
            }
          }
        },
      },
    ], 'use-live-capacity');

    return () => {
      dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    }
  });

  return {
    liveCapacity,
    firstVideoInfo: liveCapacity?.camera_list?.[0]?.video_list?.[0],
  };
}