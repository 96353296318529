import { BsArrowLeftRight } from 'react-icons/bs';
import { HiOutlineChevronDoubleDown, HiOutlineChevronDoubleUp } from 'react-icons/hi';
import './DeviceViewer.sass';
import { useDockHub } from 'src/components/Dashboard/Components/DockHubProvider/DockHubProvider';
import classNames from 'classnames';
import { useState } from 'react';

function DeviceViewer({ title, children, collapsed = false, telemetries = [] }) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const handleCollapseClick = () => {
    setIsCollapsed(curr => !curr);
  }

  return (
    <div className={classNames("device-viewer", {"collapsed": isCollapsed})}>
      <div className="title-bar">
        <div className="title" onClick={handleCollapseClick}>{title}</div>
        <div className="tools">
          <BsArrowLeftRight />
          {isCollapsed ? <HiOutlineChevronDoubleDown onClick={handleCollapseClick} /> : <HiOutlineChevronDoubleUp onClick={handleCollapseClick} />}
        </div>
      </div>
      <div className="stream">
        {children}
      </div>
      <div className="telemetries">
        {telemetries?.map(item => (
          <div className="item" key={item.name} title={item.title}>
            <div className="icon">{item.icon}</div>
            <div className="value">{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DeviceViewer