import './OperationTaskbar.sass'

export default function OperationTaskbar() {
  return (
    <div className="operation-taskbar">
      <div className="main-container">
        <div className="taskbar-item"></div>
        <div className="taskbar-item"></div>
      </div>
    </div>
  )
}
