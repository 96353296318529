import "./StorageContainerApp.sass";
import { useEffect, useState } from "react";
import { IoFileTrayStackedOutline, IoLocationSharp } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { TiGroupOutline } from "react-icons/ti";
import ApplicationContentAreaContainer from "../../../Components/ApplicationContentAreaContainer/ApplicationContentAreaContainer";
import { useDispatch, useSelector } from "react-redux";
import { usePrivileges } from "../../../../../helper/AccountStateProvider";
import { getList, setContainerModel } from "./StorageContainerAppSlice";
import LabeledIcon from "../../../../../ui/LabeledIcon/LabeledIcon";
import moment from "moment";
import EntityName from "../../../../../ui/EntityName/EntityName";
import SVGAvatar from "../../../../../ui/SVGAvatar/SVGAvatar";
import DateTimeComponent from "../../../../../ui/DateTimeComponent/DateTimeComponent";
import { FaUserCircle } from "react-icons/fa";
import ContainerInformation from "./ContainerInformation/ContainerInformation";
import GroupManagementApplication from "../../../Components/GroupManagementApplication/GroupManagementApplication";
import { selectedGroups } from "../../../../../ui/GroupApp/GroupAppSlice";
import ContainerFileManagement from "./ContainerFileManagement/ContainerFileManagement";
import Avatar from "src/ui/Avatar/Avatar";
import { useHistory, useLocation } from "react-router-dom";

function StorageContainerApp() {
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const { privileges } = usePrivileges();
  const [permissions, setPermissions] = useState([]);
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api);
  const container = useSelector((state) => state.containerApp);
  const [containers, setContainers] = useState([]);
  const [selectedContainer, setSelectedContainer] = useState();
  const location = useLocation();
  const history = useHistory();
  const urlId = location.pathname.split("/").pop();

  useEffect(() => {
    if (privileges.applications !== undefined) {
      setPermissions([
        ...privileges.applications
          .filter(
            (app) =>
              app.id === "storage" ||
              app.id === "container" ||
              app.id === "groups"
          )
          .map((app) => app.privileges.filter((pr) => pr !== ""))
          .reduce((result, current) => result.concat(current), []),
      ]);
    }
  }, [privileges.applications]);

  useEffect(() => {
    if (api.token !== undefined) dispatch(getList());
  }, [api.token]);

  const dataOptions = [];

  useEffect(() => {
    if (container.list.data !== undefined) {
      setContainers([
        ...container.list.data.containers.map((container) => ({
          id: container.id,
          containerName: container.name,
          storageLocation: (
            <LabeledIcon
              color="#878787"
              textColor="#878787"
              icon={<IoLocationSharp size="15" />}
              label={container.storageRegion}
            />
          ),

          createdBy: (
            <EntityName
              item={{
                online: false,
                title: container.createdBy.displayName,
                subtitle: container.createdBy.emailAddress,
                icon: container.createdBy.avatar ? (
                  <Avatar
                    image={container.createdBy.avatar}
                    isCovered={true}
                    width="32px"
                    height="32px"
                  />
                ) : (
                  <SVGAvatar />
                ),
              }}
            />
          ),
          creationDate: (
            <DateTimeComponent
              date={moment(container.creationDate).format("DD MMMM YYYY")}
              time={moment(container.creationDate).format("hh:mm A")}
              hour={parseInt(moment(container.creationDate).format("h"))}
            />
          ),
          groups: container.groups,
          storageId: container.storageId,
          description: container.description,
        })),
      ]);
    }
  }, [container.list]);

  useEffect(() => {
    setRows({ ...rows, values: [...containers] });
  }, [containers]);

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Container Name", value: "containerName", status: true },
      { title: "Storage Location", value: "storageLocation", status: true },
      { title: "Created By", value: "createdBy", status: true },
      { title: "Creation Date", value: "creationDate", status: false },
    ],
    values: [],
    options: dataOptions,
  });

  const histories = [
    { title: "Storage Application", link: "/dashboard/storage-app" },
    { title: "Container App", link: "/dashboard/storage-app/container" },
  ];

  useEffect(() => {
    console.log('🥼', rows.values?.length, urlId);
    if (urlId === 'container' || !rows.values?.length) return;
    const targetRow = rows?.values?.find(item => item.id === urlId);

    if (targetRow) {
      rowChangeHandler(targetRow);
      setSelectedContainer(targetRow);
    };
  }, [urlId, rows.values]);

  const goBack = () => {
    history.replace(`/dashboard/storage-app/container`);
  };

  const featureActionGenerator = () => {
    console.log("action");
  };

  const [appFeature, setAppFeature] = useState({
    id: "container_information",
    icon: <IoMdInformationCircleOutline />,
    actionGenerator: featureActionGenerator,
    steps: [],
  });
  const steps = [
    {
      name: "storage_information",
      title: "Container Information",
      icon: <IoMdInformationCircleOutline />,
      active: true,
      visible: true,
      locked: false,
      tags: ["container/list"],
      activity: <ContainerInformation />,
    },
    {
      name: "groups_app_info",
      title: "Group Management",
      icon: <TiGroupOutline />,
      active: false,
      visible: true,
      locked: false,
      activity: <GroupManagementApplication />,
      dependent: true,
    },
    {
      name: "file-manager",
      title: "File Management",
      icon: <IoFileTrayStackedOutline />,
      active: false,
      visible: true,
      locked: false,
      activity: <ContainerFileManagement />,
    },
  ];

  useEffect(() => {
    setAppFeature({
      ...appFeature,
      steps: [
        ...steps.filter(
          (af) =>
            !af.dependent || (af.dependent && permissions.includes(af.name))
        ),
      ],
    });
  }, [permissions]);

  const closeOperationHandler = () => {
    setShowOperationManager(false);
    setOperationManagerHandler(false);
  };

  const [options, setOptions] = useState([]);
  const [dialog, setDialog] = useState();
  const [currentOption, setCurrentOption] = useState();

  const triggerCurrentOption = (name) => {
    setCurrentOption({
      ...options.filter((o) => {
        return o.name === name;
      })[0],
    });
  };

  useEffect(() => {
    if (currentOption !== undefined) currentOption.handler();
  }, [currentOption]);

  const rowChangeHandler = (row) => {
    console.log('👓', row);

    if (row !== undefined) {
      dispatch(setContainerModel({ ...row }));
      dispatch(selectedGroups(row.groups));

      if (row.id !== urlId)
        history.replace(`/dashboard/storage-app/container/${row.id}`);
    }
  };

  useEffect(() => {
    if (selectedContainer !== undefined && container.list.data !== undefined) {
      setRows((currentRow) => {
        currentRow.values.forEach((targetRow, index) => {
          if (targetRow.id === selectedContainer.id) {
            targetRow.active = true;
            currentRow.values[index] = targetRow;
          } else if (targetRow.active) {
            targetRow.active = false;
            currentRow.values[index] = targetRow;
          }
        });
        return currentRow;
      });
    }
  }, [selectedContainer, container.list]);

  return (
    <ApplicationContentAreaContainer
      histories={histories}
      title="Container Application"
      description="Here you will be able to add new container or see all the containers that have already been added to the storage management system. "
      data={rows}
      feature={appFeature}
      optionHandler={triggerCurrentOption}
      operations={[...options]}
      searchOption={true}
      onClose={closeOperationHandler}
      titleKey="containerName"
      subtitleKey="storageLocation"
      confirm={dialog}
      isAppArea={true}
      table={{
        tags: ["containerApp/list"],
        show: true,
        selectable: false,
        boldIndexes: [1],
      }}
      operationManager={showOperationManager}
      managerHandler={operationManagerHandler}
      filterSelectors={[]}
      showOptionSelector={false}
      onRowChangeHandler={rowChangeHandler}
      onCloseHandler={goBack}
    />
  );
}

export default StorageContainerApp;
