import { MeasurementSystem, footToMeterRatio } from 'src/helper/constants';
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import './MainAltitude.sass'
import convert from 'convert';
import useMeasurement from 'src/helper/useMeasurement';

function MainAltitude() {
  const { measurementSystem } = useMeasurement();
  const velocity = useSelector(state => state.flight.velocity);
  const flightStatus = useSelector(state => state.flight.droneMode?.flightStatus);
  const altitudeInfo = useSelector(state => state.flight.altitude);
  const gpsInfo = useSelector(state => state.flight.gps);

  const droneAltitude = useMemo(() => {
    if (flightStatus !== 2 || !gpsInfo) return 0;
    return altitudeInfo?.asl - gpsInfo.homepoint?.alt;
    // return altitudeInfo?.height > 0 ? altitudeInfo?.height : gpsAltitude;
  }, [gpsInfo, flightStatus]);

  const formatVsValue = (value) => {
    const isNegative = value < 0;
    const absValue = Math.abs(value);

    if (!absValue)
      return "0.0";

    const fixedValue = value.toFixed(1);
    const newValue = Math.abs(fixedValue).toString().padStart(1, "0");

    return isNegative && fixedValue < 0 ? '-' + newValue : newValue;
  };

  return (
    <div className="main-altitude">
      <div className="vs-info">
        {velocity.z ? formatVsValue(measurementSystem === MeasurementSystem.METRIC ? velocity.z : convert(velocity.z, "meters").to("miles")) : "0.0"} VS
      </div>
      <div className="alt-info">
        <div className="value">
          {droneAltitude > 0 ? (measurementSystem === MeasurementSystem.METRIC ? droneAltitude : convert(droneAltitude, "meters").to("feet"))?.toFixed(1).padStart(5, "0") : "000.0"}
        </div>
        <div className="info-label">
          <span>ALT</span>
          <br />
          <span>{measurementSystem === MeasurementSystem.METRIC ? 'm' : 'ft'}</span>
        </div>
      </div>
      <div className="asl-info">
        {altitudeInfo?.asl ? (measurementSystem === MeasurementSystem.METRIC ? altitudeInfo.asl : convert(altitudeInfo.asl, "meters").to("feet"))?.toFixed(1).padStart(5, "0") : "000.0"} ASL
      </div>
    </div>
  );
}

export default MainAltitude