import "./EditableTextBox.sass";
import { useEffect, useState } from "react";
import { useTheme } from "../../helper/ThemeProvider";

function EditableTextBox({ model, width, onChange, readonly, placeholder }) {
  const [edit, setEdit] = useState(false);
  const [val, setVal] = useState("");
  const { currentContext } = useTheme();

  useEffect(() => {
    setVal(model?.value !== undefined ? model?.value : "");
  }, [model?.value]);

  const changeHandler = (event) => {
    setVal(event.target.value);
  };
  return (
    <div className="editable-text-box" style={{ width: width }}>
      <label htmlFor={model?.name}>{model?.title}</label>
      <input
        onClick={() => {
          setEdit(!readonly);
        }}
        onChange={changeHandler}
        onInput={onChange}
        readOnly={!edit && readonly}
        type={model?.type}
        value={val}
        name={model?.name}
        id={model?.name}
        style={{
          background: edit ? "white" : "none",
          padding: edit ? ".5em 1em" : "0",
          color: edit ? currentContext.theme.color : "#605e5e",
        }}
        placeholder={placeholder}
      />
      {edit ? (
        <button
          style={{ color: currentContext.theme.color }}
          className="save"
          onClick={() => {
            setEdit(false);
          }}
        >
          Save
        </button>
      ) : (
        !readonly && (
          <button
            style={{ color: currentContext.theme.color }}
            className="edit"
            onClick={() => {
              setEdit(true);
            }}
          >
            Edit
          </button>
        )
      )}
    </div>
  );
}
EditableTextBox.defaultProps = {
  width: "100%",
};
export default EditableTextBox;
