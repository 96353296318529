import classNames from 'classnames';
import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';
import { PayloadIndexes } from 'src/helper/HubConnection';
import useDebounce from 'src/helper/use-debounce';
import { useCallback } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './ZoomControl.sass'

// Zoom slider steps ratios
// [0.025, 0.05, 0.1, 0.2, 0.4, 0.8, 1, 1]
// [0.01, 0.01, 0.025, 0.05, 0.1, 0.2, 0.4, 0.8]

function ZoomControl({ className, slider = true, min = 2, max = 200 }) {
  const rangeStepsCount = 8;
  const { hub, groups, deviceId, joinCompleted } = useOnboardHub();
  const zoomFactor = useSelector(state => state.flight.cameraStats?.zoomFactor);
  const [localZoom, setLocalZoom] = useState(zoomFactor || min);
  const [remoteZoom, setRemoteZoom] = useState(zoomFactor);
  const shortDebounceLocalZoom = useDebounce(localZoom, 300);
  const [rangeStep, setRangeStep] = useState(1);
  const [rangeMax, setRangeMax] = useState(max);
  const [rangeMin, setRangeMin] = useState(min);
  const [externalChange, setExternalChange] = useState(false);
  const [localZoomInProgress, setLocalZoomInProgress] = useState(false);
  const localZoomInProgressTimeoutId = useRef();
  const verticalLimitRef = useRef();
  const slideGripRef = useRef();
  const sliderGripDragging = useRef(false);
  const { activeCameraPayload } = useDeviceInfo();

  const sendZoomValue = (value) => {
    if (joinCompleted && deviceId !== undefined && Math.ceil(remoteZoom)) {
      hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
        zoomFactor: {
          payloadIndex: activeCameraPayload?.payloadIndex,
          zoomFactor: parseFloat(value),
        }
      });
    }
  };

  const handleRemoteZoomValueChange = () => {
    const value = zoomFactor;

    if (typeof value === "number") {
      const newZoom = value < 2 ? 2 : Math.round(value);
      if (localZoomInProgress) return;

      clearTimeout(localZoomInProgressTimeoutId.current);
      setRemoteZoom(newZoom);
    }
  };

  const lockRemoteZoomValueChange = () => {
    setLocalZoomInProgress(true);
    clearTimeout(localZoomInProgressTimeoutId.current);

    localZoomInProgressTimeoutId.current = setTimeout(() => {
      setLocalZoomInProgress(false);
      handleRemoteZoomValueChange();
    }, 3000);
  }

  const getZoomChangeAmount = (value, range) => {
    let steps;

    if (value < (range * .05)) steps = 200;
    else if (value < (range * .1)) steps = 100;
    else if (value < (range * .3)) steps = 50;
    else steps = 10;

    return Math.round(range / steps);
  }

  const changeZoom = useCallback((offset, dynamicChange = true) => {
    const range = max - min;

    setLocalZoom(current => {
      const change = dynamicChange ? getZoomChangeAmount(current, range) : Math.abs(offset);

      if (offset > 0) {
        const newValue = current + change;
        return newValue <= rangeMax ? newValue : rangeMax;
      }
      else if (offset < 0) {
        const newValue = current - change;
        return newValue >= rangeMin ? newValue : rangeMin;
      }
    });

    lockRemoteZoomValueChange();
  }, [rangeMax, rangeMin]);

  const changeAbsoluteZoom = useCallback((value, force = false) => {
    let newZoomValue = value;

    if (value > rangeMax && !force)
      newZoomValue = rangeMax;

    if (value < rangeMin && !force)
      newZoomValue = rangeMin;

    setLocalZoom(curr => {
      if (curr === newZoomValue)
        return curr;

      lockRemoteZoomValueChange();
      return newZoomValue;
    });
  }, [rangeMax, rangeMin]);

  const calculateZoomRange = (step) => {
    let newMin, newMax;

    if (rangeStepsCount - step <= 1) newMax = max;
    else newMax = max * (0.2 * Math.pow(2, step - 4));

    if (step <= 2) newMin = 0.01 * max;
    else newMin = max * (0.2 * Math.pow(2, step - 6));

    return [newMin, newMax];
  }

  const calculateZoomStep = (value) => {
    for (let step = 1; step++; step <= rangeStepsCount) {
      const [minValue, maxValue] = calculateZoomRange(step);

      if (minValue <= value && value <= maxValue)
        return step;
    }
  }

  const changeZoomStep = (step, absolute = false, adjustValue = true) => {
    setRangeStep(currStep => {
      let newStep = absolute ? step : currStep + step;

      if (newStep > rangeStepsCount)
        newStep = rangeStepsCount;

      if (newStep < 1)
        newStep = 1;

      const [newMin, newMax] = calculateZoomRange(newStep);

      setRangeMax(newMax);
      setRangeMin(newMin);

      if (adjustValue) {
        if (newStep === 1) changeAbsoluteZoom(min, true); else
          if (newStep === rangeStepsCount) changeAbsoluteZoom(max, true);
          else changeAbsoluteZoom(newMin + (newMax - newMin) / 2, true);
      }

      return newStep;
    })
  }

  const transformZoomPercent = useCallback(x => {
    const minInput = rangeMin, maxInput = rangeMax, minOutput = 0, maxOutput = 100;
    return (x - minInput) / (maxInput - minInput) * (maxOutput - minOutput) + minOutput;
  }, [rangeMax, rangeMin]);

  const handlePlusButtonClick = () => {
    setExternalChange(false);

    if (slider) changeZoomStep(1);
    else changeZoom(1);
  }

  const handleMinusButtonClick = () => {
    setExternalChange(false);

    if (slider) changeZoomStep(-1);
    else changeZoom(-1);
  }

  const handleKeyboard = (event) => {
    if (event.code === "KeyW") {
      setExternalChange(false);
      changeZoom(1, false);
    } else if (event.code === "KeyS") {
      setExternalChange(false);
      changeZoom(-1, false);
    }
  };

  const handleGripMouseMove = (event) => {
    event.preventDefault();
    if (!sliderGripDragging.current || !event.movementY)
      return;

    const limitBounds = verticalLimitRef.current?.getBoundingClientRect();
    const yOffset = limitBounds.bottom - event.clientY;
    const yRatio = yOffset / limitBounds.height;
    const newZoomValue = rangeMin + ((rangeMax - rangeMin) * yRatio);

    setExternalChange(false);
    changeAbsoluteZoom(newZoomValue);
  }

  const handleGripMouseUp = (event) => {
    sliderGripDragging.current = false;
    document.removeEventListener("mouseup", handleGripMouseUp);
    document.removeEventListener("mousemove", handleGripMouseMove);
    document.removeEventListener("touchmove", handleGripMouseMove);
  }

  const handleGripMouseDown = (event) => {
    event.preventDefault();
    sliderGripDragging.current = true;
    document.addEventListener("mouseup", handleGripMouseUp);
    document.addEventListener("mousemove", handleGripMouseMove, { passive: false });
    document.addEventListener("touchmove", handleGripMouseMove, { passive: false });
  };

  useEffect(() => {
    slider && changeZoomStep(0);
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboard);
    slideGripRef.current?.addEventListener("mousedown", handleGripMouseDown);

    return () => {
      document.removeEventListener("keydown", handleKeyboard);
      slideGripRef.current?.removeEventListener("mousedown", handleGripMouseDown);
    };
  }, [handleGripMouseDown]);

  useEffect(() => {
    if (shortDebounceLocalZoom && !externalChange && remoteZoom)
      sendZoomValue(shortDebounceLocalZoom);
  }, [shortDebounceLocalZoom]);

  useEffect(() => {
    if (externalChange) {
      const newZoomStep = calculateZoomStep(localZoom);
      changeZoomStep(newZoomStep, true, false);
    }
    else {
      if (localZoom === rangeMax) changeZoomStep(1);
      else if (localZoom === rangeMin) changeZoomStep(-1);
      else return;

      handleGripMouseUp();
    }
  }, [localZoom, rangeMax, rangeMin, externalChange]);

  useEffect(() => {
    if (remoteZoom > max || remoteZoom < min)
      return;

    setExternalChange(true);
    setLocalZoom(remoteZoom);
  }, [remoteZoom]);

  useEffect(handleRemoteZoomValueChange, [zoomFactor]);

  if (!activeCameraPayload?.videoSources?.zoom) return null;

  return (
    <div className={"zoom-control " + className}>
      <div className={classNames('control-button', { 'disabled': localZoom === max })} onClick={handlePlusButtonClick}>
        <div className="text">+</div>
      </div>
      {slider ? (
        <div className="control-slider">
          <div className="vertical-limit" ref={verticalLimitRef}>
            <div className="strips">
              {[...Array(20)].map((value, index) => (
                <div className="strip-item" key={index}></div>
              ))}
            </div>
            <div className="grip" ref={slideGripRef} style={{
              top: (100 - transformZoomPercent(localZoom)) + '%'
            }}>
              <div className="current-zoom">
                <div>Zoom</div>
                <div>{Math.round(localZoom)}x</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="current-zoom">
          <div>Zoom</div>
          <div>{Math.round(localZoom)}x</div>
        </div>
      )}
      <div className={classNames('control-button', { 'disabled': localZoom === min })} onClick={handleMinusButtonClick}>
        <div className="text">–</div>
      </div>
    </div>
  )
}

export default ZoomControl