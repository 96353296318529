import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "src/ui/Form/Form";
import InputBox from "src/ui/InputBox/InputBox";
import useDebounce from "src/helper/use-debounce";
import "./CreateNewGroup.sass";
import {
  create,
  checkGroupName,
  createGroup,
  groupName,
} from "src/ui/GroupApp/GroupAppSlice";
import GroupMembershipApp from "src/ui/GroupApp/GroupMembershipApp/GroupMembershipApp";

function CreateNewGroup({ onConfirm }) {
  const [icons, setIcons] = useState({ Name: "default" });
  const [validate, setValidate] = useState(false);
  const [alerts, setAlerts] = useState({});
  const group = useSelector((state) => state.groupApp);
  const [data, setData] = useState({});
  const [required, setRequired] = useState(["Name"]);
  const [Name, setName] = useState("");
  const DebouncedName = useDebounce(Name, 1000);
  const dispatch = useDispatch();

  useEffect(() => {
    if (DebouncedName) {
      dispatch(checkGroupName(Name));
    }
  }, [DebouncedName]);

  useEffect(() => {
    if (group?.groupName?.status === "forbidden") {
      setAlerts({
        ...alerts,
        Name: {
          alert: true,
          type: "error",
          message: group?.groupName?.message.error,
        },
      });
    } else if (group?.groupName.status === "validation") {
      let validations = {};
      group.groupName.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
    setIcons({ ...icons, Name: group?.groupName?.status });
    setValidate(group?.groupName?.status === "success");
  }, [group]);

  useEffect(() => {
    if (group.create.status === "validation") {
      let validations = {};
      group.create.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    } else if (group.create.status === "success") {
      dispatch(create({ status: "" }));
      onConfirm();
    }
  }, [group.create]);

  useEffect(() => {
    return () => {
      dispatch(groupName({ status: "" }));
    };
  }, []);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value.toString().trim();

    setAlerts({ ...alerts, [key]: { alert: false } });

    if (key === "Name") {
      setIcons({ ...icons, Name: "default" });
      setName(value);
      setValidate(false);
    }

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(createGroup(data));
  };

  const membersChanged = (members) => {
    setData({ ...data, members });
  };

  return (
    <div className="onboarding-create-group">
      <Form
        submit={{
          title: "Confirm",
          width: "30%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        validate={validate}
      >
        <div>New Group</div>
        <InputBox
          title="Group Name"
          name="Name"
          isRequired={required.includes("Name")}
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Name}
          value={data.Name}
          index={2}
          icon={icons.Name}
          background="white"
          type="text"
        />

        <InputBox
          title="Group Description"
          name="Description"
          isRequired={required.includes("Description")}
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Description}
          value={data.Description}
          index={1}
          background="white"
        />

        <div className="onboarding-create-group-members">
          <div>Add Members</div>
          <GroupMembershipApp
            title={"Search Members"}
            creationMode={true}
            onMemberChange={membersChanged}
          />
        </div>
      </Form>
    </div>
  );
}

export default CreateNewGroup;
