import { useEffect, useState } from "react";
import { MdDeleteForever, MdRefresh } from "react-icons/md";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import "./StorageManagement.sass";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import { getList, setModel } from "./StorageManagementSlice";
import { FaUserCircle, FaUserEdit } from "react-icons/fa";
import ApplicationContentAreaContainer from "../../../Dashboard/Components/ApplicationContentAreaContainer/ApplicationContentAreaContainer";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { TiGroupOutline } from "react-icons/ti";
import LabeledIcon from "../../../../ui/LabeledIcon/LabeledIcon";
import { IoLocationOutline } from "react-icons/io5";
import StorageInformation from "./StorageInformation/StorageInformation";
import StorageContainerManagement from "./StorageContainerManagement/StorageContainerManagement";
import AdminOperationManager from "../../AdminOperationManager/AdminOperationManager";
import { BiListPlus } from "react-icons/bi";
import CreateStorage from "./CreateStorage/CreateStorage";
import EntityName from "../../../../ui/EntityName/EntityName";
import SVGAvatar from "../../../../ui/SVGAvatar/SVGAvatar";
import DateTimeComponent from "../../../../ui/DateTimeComponent/DateTimeComponent";
import moment from "moment";
import Avatar from "src/ui/Avatar/Avatar";

function StorageManagement() {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api.state);
  const storage = useSelector((state) => state.storage);
  const { privileges } = usePrivileges();
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [dialog, setDialog] = useState();
  const [currentFeature, setCurrentFeature] = useState();
  const [featureManager, setFeatureManager] = useState(false);
  const [isFeatureManagerOpen, setIsFeatureManagerOpen] = useState(false);
  const [storages, setStorages] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined) {
      let storagesPermissions = [];
      if (
        privileges.admins.filter((item) => item.id === "storages")[0] !==
        undefined
      )
        storagesPermissions = [
          ...privileges.admins.filter((item) => item.id === "storages")[0]
            ?.privileges,
        ];

      if (
        privileges.admins.filter((item) => item.id === "containers")[0] !==
        undefined
      )
        setPermissions([
          ...storagesPermissions.concat([
            ...privileges.admins.filter((item) => item.id === "containers")[0]
              ?.privileges,
          ]),
        ]);
      else setPermissions(...storagesPermissions);
    }
  }, [privileges.admins]);

  const removeStoragesGenerator = (data) => {
    console.log("delete storages");
  };

  const refreshGenerator = () => {
    dispatch(getList());
  };

  const toggleFeatureManagerOn = () => {
    setFeatureManager(true);
    setIsFeatureManagerOpen(true);
  };

  useEffect(() => {
    if (api !== undefined) {
      dispatch(getList());
    }
  }, [api]);
  useEffect(() => {
    if (Array.isArray(storage?.list?.data?.storages)) {
      setStorages([
        ...storage.list.data.storages?.map((item) => ({
          ...item,
          deleted: item.isDeleted,
          storageRegion: (
            <LabeledIcon
              color="#23333d"
              textColor="#878787"
              icon={<IoLocationOutline size="15" />}
              label={item.storageRegion}
            />
          ),
          createdBy: (
            <EntityName
              item={{
                online: false,
                title: item.createdBy?.displayName,
                subtitle: item.createdBy?.emailAddress,
                icon: item.createdBy?.avatar ? (
                  <Avatar
                    image={item.createdBy?.avatar}
                    isCovered={true}
                    width="32px"
                    height="32px"
                  />
                ) : (
                  <SVGAvatar />
                ),
              }}
            />
          ),
          registeredDate: (
            <DateTimeComponent
              date={moment(item.creationDate).format("DD MMMM YYYY")}
              time={moment(item.creationDate).format("hh:mm A")}
              hour={parseInt(moment(item.creationDate).format("h"))}
            />
          ),
          active: false,
        })),
      ]);
      setFilters([...filters.map((f) => ({ ...f, active: f.default }))]);
    }
  }, [storage?.list]);

  useEffect(() => {
    setRows({
      ...rows,
      values: [...storages.filter((storage) => !storage.isDeleted)],
    });
  }, [storages]);

  const featureActionGenerator = () => {
    console.log("action");
  };

  const closeOperationHandler = () => {
    setShowOperationManager(false);
    setOperationManagerHandler(false);
  };

  const toggleManagerOperationOn = () => {
    setShowOperationManager(true);
    setOperationManagerHandler(true);
  };

  const toggleOperationManagerOff = () => {
    setShowOperationManager(false);
  };

  const toggleFeatureManager = () => {
    setFeatureManager(false);
    setIsFeatureManagerOpen(false);
  };

  const closeFeatureManager = () => {
    setFeatureManager(false);
  };

  const histories = [
    { title: "Home", link: "/admin/home" },
    { title: "Storages", link: "/admin/storages" },
  ];
  const [features, setFeatures] = useState([]);
  const [options, setOptions] = useState([
    {
      title: "Create Storage",
      icon: <BiListPlus size="15" />,
      name: "create_storage",
      active: true,
    },
    {
      title: "Refresh",
      icon: <MdRefresh size="15" />,
      name: "list_storages",
      active: true,
    },
  ]);

  const dataOptions = [
    {
      title: "Refresh",
      icon: <MdRefresh />,
      name: "list_storages",
      active: true,
    },
    {
      title: "Delete Storage",
      icon: <MdDeleteForever />,
      name: "delete_storages",
      active: true,
    },
    {
      title: "Edit Storage",
      icon: <FaUserEdit />,
      name: "edit_storage",
      active: true,
      selected: true,
    },
  ];

  const userOptions = [
    {
      title: "Create Storage",
      icon: <AiOutlineUsergroupAdd />,
      name: "create_storage",
      active: true,
    },
    {
      title: "Refresh",
      icon: <MdRefresh />,
      name: "list_storages",
      active: true,
    },
    {
      title: "Delete Storage",
      icon: <MdDeleteForever />,
      name: "delete_storages",
      type: "dependent",
      singular: true,
      active: false,
    },
    {
      title: "Group Assignment",
      icon: <FaUserEdit />,
      name: "device_groups_assignment",
      type: "dependent",
      active: false,
      singular: true,
    },
  ];

  const userFeatures = [
    {
      id: "create_storage",
      title: "Create Storage",
      description: "You can create storages in your account.",
      icon: <BiListPlus />,
      color: "#2699fb",
      type: "form",
      actionGenerator: () => {
        toggleFeatureManagerOn();
      },
      steps: [
        {
          name: "create_storage",
          title: "Create",
          active: true,
          visible: true,
          locked: false,
          closable: false,
          tags: ["storage/create"],
          activity: (
            <CreateStorage
              onOpen={toggleFeatureManagerOn}
              onConfirm={toggleFeatureManager}
            />
          ),
        },
      ],
    },
    {
      id: "delete_storages",
      title: "Delete Storage(s)",
      confirm: "Are you sure for deleting selected storage?",
      description: "You can delete a storage under your company account",
      type: "dialog",
      actionGenerator: removeStoragesGenerator,
    },
    {
      id: "list_storages",
      type: "no-op",
      actionGenerator: refreshGenerator,
    },
  ];

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Name", value: "name", status: true },
      { title: "Description", value: "description", status: true },
      { title: "Region", value: "storageRegion", status: true },
      { title: "Created By", value: "createdBy", status: true },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    if (storage.create.status === "success") {
      dispatch(getList());
    }
  }, [storage.create]);

  useEffect(() => {
    if (storage.update.status === "success") {
      dispatch(getList());
    }
  }, [storage.update]);

  useEffect(() => {
    if (storage.remove.status === "success") {
      dispatch(getList());
    }
  }, [storage.remove]);

  useEffect(() => {
    if (storage.restore.status === "success") {
      dispatch(getList());
    }
  }, [storage.restore]);

  const steps = [
    {
      name: "list_storages",
      title: "Storage Information",
      icon: <IoMdInformationCircleOutline />,
      active: true,
      visible: true,
      locked: false,
      tags: ["storage/list"],
      activity: (
        <StorageInformation
          onOpen={toggleManagerOperationOn}
          onConfirm={toggleOperationManagerOff}
        />
      ),
    },
    {
      name: "list_storage_containers",
      title: "Containers",
      icon: <TiGroupOutline />,
      active: false,
      visible: true,
      locked: false,
      activity: <StorageContainerManagement />,
    },
  ];
  const [appFeature, setAppFeature] = useState({
    id: "container_information",
    icon: <IoMdInformationCircleOutline />,
    actionGenerator: featureActionGenerator,
  });

  useEffect(() => {
    setAppFeature({
      ...appFeature,
      steps: [...steps.filter((af) => permissions.includes(af.name))],
    });

    setRows({
      ...rows,
      options: dataOptions.filter((option) =>
        permissions.includes(option.name)
      ),
    });
  }, [permissions]);

  const rowChangeHandler = (row) => {
    if (row !== undefined) {
      dispatch(setModel({ ...row }));
    }
  };

  const triggerCurrentOption = (name) => {
    const feature = userFeatures.find((u) => u.id === name);
    if (feature !== undefined) {
      setCurrentFeature({ ...feature });
      feature.actionGenerator();
    }
  };



  const toggleFilter = (filter) => {
    filter?.action(storages);
    setFilters([...filters.map((f) => ({ ...f, active: f.id === filter.id }))]);
  };
  const [filters, setFilters] = useState([
    {
      id: "active_storage",
      title: "Active Storages",
      value: "Active Storages",
      active: true,
      default: true,
      action: (storages) => {
        setRows({
          ...rows,
          values: [
            ...storages
              .filter((storage) => !storage.isDeleted)
              .map((storage) => ({ ...storage, active: false })),
          ],
        });
      },
    },
    {
      id: "deleted_storage",
      title: "Deleted Storages",
      value: "Deleted Storages",
      action: (storages) => {
        setRows({
          ...rows,
          values: [
            ...storages
              .filter((storage) => storage.isDeleted)
              .map((storage) => ({ ...storage, active: false })),
          ],
        });
      },
    },
    {
      id: "all_storage",
      title: "All Storages",
      value: "All Storages",

      action: (storages) => {
        setRows({
          ...rows,
          values: [
            ...storages.map((storage) => ({ ...storage, active: false })),
          ],
        });
      },
    },
  ]);

  return (
    <>
      <ApplicationContentAreaContainer
        histories={histories}
        title="Container Application"
        data={rows}
        feature={appFeature}
        optionHandler={triggerCurrentOption}
        operations={[...options]}
        searchOption={true}
        onClose={closeOperationHandler}
        titleKey="name"
        subtitleKey="storageRegion"
        confirm={dialog}
        isAppArea={false}
        table={{
          tags: ["storage/list", "storage/remove", "storage/restore"],
          show: true,
          selectable: true,
          boldIndexes: [1],
        }}
        operationManager={showOperationManager}
        managerHandler={operationManagerHandler}
        showOptionSelector={true}
        filterSelectors={[...filters]}
        filterSelectorHandler={toggleFilter}
        OptionSelector={true}
        onRowChangeHandler={rowChangeHandler}
      />
      {isFeatureManagerOpen && (
        <AdminOperationManager
          isOpen={featureManager}
          toggle={toggleFeatureManager}
          close={closeFeatureManager}
          selectedFeature={currentFeature}
        />
      )}
    </>
  );
}

export default StorageManagement;
