import './Card.sass'

function Card({ children, className, ...props }) {
  return (
    <div className={'app-card ' + (className || '')}>
      {children}
    </div>
  )
}

export default Card