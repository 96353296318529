import React, { useState, useEffect } from 'react'
import './UserProfileOrganizations.sass'
import { FaRegBuilding } from 'react-icons/fa';
import OrganizationProfile from './OrganizationProfile/OrganizationProfile';
import Loading from "src/ui/Loading/Loading";
import { useDispatch, useSelector } from 'react-redux';
import { getUserCompany } from 'src/redux/ducks/accountStateSlice';

function UserProfileOrganizations() {
  const [showOrganizationProfile, setShowOrganizationProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState();
  const dispatch = useDispatch();
  const company = useSelector(state => state.accountState.company);

  useEffect(() => {
    if (company.status === 'success') {
      setCompanyInfo(company?.data);
      setLoading(false);
    }
    console.log(company);
  }, [company]);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserCompany());
  }, []);

  return (
    <div className="user-profile-organizations">
      {!showOrganizationProfile ? (
        <>
          <h2>Organizations</h2>
          <div className="guide-text">Your home organization is the organization that manages your account. You can't leave your home organization.</div>
          <div className="organizations">
            <div className="organization" onClick={() => setShowOrganizationProfile(true)}>
              <div className="organization-name">
                <FaRegBuilding />
                <div className="name">{companyInfo?.companyName || 'Loading...'}</div>
              </div>
              <div className="auth-status">Signed-in</div>
            </div>
          </div>
        </>
      ) : (
        <OrganizationProfile organization={companyInfo} />
      )}
      {loading && <Loading />}
    </div>
  )
}

export default UserProfileOrganizations