import "./GroupMemberItem.sass";
import { useState } from "react";
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";
import OutSideDetector from "../OutsideDetector/OutSideDetector";
import { useTheme } from "../../helper/ThemeProvider";

function GroupMemberItem({ member, onTypeChange, types, defaultMembership,actionable }) {
  const [toggle, setToggle] = useState(false);
  const [selectedType, setSelectedType] = useState(defaultMembership);
  const { currentContext } = useTheme();
  const toggleType = (type) => {
    setSelectedType(type !== "Remove" ? type : selectedType);
    onTypeChange({ member, type });
  };
  return (
    <OutSideDetector
      className="group-member-item"
      background={currentContext.theme.highlight}
      detect={() => {
        setToggle(false);
      }}
    >
      <div className="group-item-info">
        <span className="item-icon">{member.icon}</span>
        <div className="item-info">
          <span>{member.title}</span>
          {member.subtitle && <small>{member.subtitle}</small>}
        </div>
      </div>
      {actionable && (
        <div
          className="group-item-action"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <span className="group-item-selected-type">{selectedType}</span>
          {!toggle ? (
            <FaChevronCircleDown />
          ) : (
            <>
              <FaChevronCircleUp />
              <ul className="group-item-types">
                {types.map((type, index) => (
                  <li
                    className="group-type-item"
                    key={index}
                    onClick={() => {
                      toggleType(type);
                    }}
                    style={{
                      borderTop: `1px solid ${currentContext.theme.secondColor}`,
                    }}
                  >
                    {type}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}
    </OutSideDetector>
  );
}

GroupMemberItem.defaultProps = {
  actionable : true
}
export default GroupMemberItem;
