import useDeviceInfo from "./useDeviceInfo";
import useDeviceConnection from "./useDeviceConnection";
import { useEffect, useRef, useState } from "react";
import { dockConnectionManager } from "src/helper/HubConnectionManager";
import { getDeviceInfo } from "./deviceUtils";
import { useDispatch, useStore } from "react-redux";
import { setDeviceLiveStatus } from "../DeviceServiceSlice";

export default function useDeviceLiveStatus (deviceId, videoId) {
  const dispatch = useDispatch();
  const store = useStore();
  const connection = useDeviceConnection(deviceId);
  const groupHandlerIds = useRef([]);
  const [liveStatus, setLiveStatus] = useState();

  useEffect(() => {
    const serialNumber = getDeviceInfo(deviceId)?.serialNumber;
    const storedLiveStatus = store.getState()?.deviceService?.deviceLiveStatus?.[deviceId];

    if(videoId && storedLiveStatus) {
      const liveStatus = storedLiveStatus.find(data => data.video_id === videoId);
      
      if(liveStatus) {
        setLiveStatus(liveStatus);
      }
    }

    dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    groupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.state],
        handler: (message) => {
          if(message.data?.live_status) {
            const liveStatus = !videoId ? message.data?.live_status : message.data.live_status.find(data => data.video_id === videoId);
            
            if(liveStatus) {
              setLiveStatus(liveStatus);
              dispatch(setDeviceLiveStatus({ deviceId, data: message.data.live_status }));
              console.log('🎦 DEVICE_LIVE_STATUS', serialNumber, videoId, liveStatus);
            }
          }
        },
      },
    ], 'use-live-status');

    return () => {
      dockConnectionManager.unsubscribeGroupMessages(groupHandlerIds.current);
    }
  }, [deviceId, videoId, connection]);

  return liveStatus;
}