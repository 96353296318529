import { useEffect, useState } from "react";
import "./DataEntryPanel.sass";
import PanelItem from "../../../ui/PanelItem/PanelItem";
import TabletSize from "../../../ui/TabletSize/TabletSize";
import DesktopSize from "../../../ui/DesktopSize/DesktopSize";

const DataEntryPanel = ({data}) => {
  const [panels,setPanels]=useState([])
  useEffect(() => {
   setPanels([...data])
  }, [data])
  return (
    <div className="panels">
      <TabletSize>
        {panels.map(
          (panel,index) =>
            panel.active && (
              <PanelItem
                key={index}
                panel={panel}
              />
            )
        )}
      </TabletSize>
      <DesktopSize>
        {panels.map((panel,index) => (
          <PanelItem
            key={index}
            panel={panel}
          />
        ))}
      </DesktopSize>
    </div>
  );
};

export default DataEntryPanel;
