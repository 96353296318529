import { FaRegUser } from 'react-icons/fa';
import './UnitListCard.sass';
import Card from 'src/hci/common/Card/Card';
import classNames from 'classnames';
import { MdCastle, MdDomain } from 'react-icons/md';

function UnitListCard({ data }) {
  return (
    <Card className="unit-list-card">
      <div className="title-bar">
        <MdDomain className="icon" />
        <div className="title">{ data.name }</div>
        <div className="members">
          { data.pilots?.length + ' pilots'}
        </div>
      </div>
      <div className="register-by">
        <FaRegUser className="icon" />
        <div className="value">{ data.registeredBy?.displayName || data.registeredBy?.emailAddress }</div>
      </div>
      <div className="register-time">{ data.creationDate }</div>
    </Card>
  )
}

export default UnitListCard