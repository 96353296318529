import './Tabs.sass';
import * as RadixTabs from '@radix-ui/react-tabs';
import { forwardRef } from 'react';
import classNames from 'classnames';

export const Root = forwardRef(({children, className, ...props}, ref) => (
  <RadixTabs.Root className={classNames('ir-tabs-root', className)} {...props}>
    {children}
  </RadixTabs.Root>
));

export const List = forwardRef(({children, className, ...props}, ref) => (
  <RadixTabs.List className={classNames('ir-tabs-list', className)} {...props}>
    {children}
  </RadixTabs.List>
));

export const Trigger = forwardRef(({children, className, ...props}, ref) => (
  <RadixTabs.Trigger className={classNames('ir-tabs-trigger', className)} {...props}>
    {children}
  </RadixTabs.Trigger>
));

export const Content = forwardRef(({children, className, ...props}, ref) => (
  <RadixTabs.Content className={classNames('ir-tabs-content', className)} {...props}>
    {children}
  </RadixTabs.Content>
));

export default {
  Root,
  List,
  Trigger,
  Content
};