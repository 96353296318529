import React, { useState } from 'react'
import './UnitMissionsSideList.sass'
import UnitMissionListCard from '../UnitMissionListCard/UnitMissionListCard';
import { selectedUnitMission } from '../SecurityAppSlice';
import { useDispatch, useSelector } from 'react-redux';
import SideListItem from 'src/hci/organism/SideListPanel/SideListItem/SideListItem';
import SideListPanel from 'src/hci/organism/SideListPanel/SideListPanel';
import Dialog from 'src/hci/common/Dialog/Dialog';
import { BiPlus } from 'react-icons/bi';
import AddDfrMission from '../AddDfrMission/AddDfrMission';
import useUnitMissionList from '../common/useUnitMissionList';
import { dockConnectionManager } from 'src/helper/HubConnectionManager';

function UnitMissionsSideList({ id: unitId, onListItemClick, onMissionCreateSuccess }) {
  const dispatch = useDispatch();
  const [selectedListItemsArray, setSelectedListItemsArray] = useState([]);
  const [showAddDfrMission, setShowAddDfrMission] = useState(false);
  const {data: sourceItems, loading: loadingSourceItems} = useUnitMissionList(unitId);
  const dockConnections = useSelector(state => state.deviceService.dockConnections);

  const listItems = !sourceItems ? [] : sourceItems.map(item => {
    const data = {
      ...item,
    };

    return {
      id: item.id,
      content: (
        <SideListItem data={data}>
          <UnitMissionListCard data={data} />
        </SideListItem>
      )
    };
  });

  const panelActions = [
    {
      title: "Create Mission",
      handler: () => setShowAddDfrMission(true),
      content: <BiPlus />,
    }
  ];

  const handleListItemClick = id => {
    const data = sourceItems.find(item => item.id === id);
    setSelectedListItemsArray([id]);
    dispatch(selectedUnitMission(data));
    onListItemClick?.(data);
  }

  const handleMissionCreateSuccess = (missionData) => {
    setShowAddDfrMission(false);
    onMissionCreateSuccess?.(missionData);
    console.log({missionData});
  }

  return (
    <div className='unit-missions-side-list'>
      {showAddDfrMission ? (
        <AddDfrMission onCancel={() => setShowAddDfrMission(false)} onSuccess={handleMissionCreateSuccess} />
      ) : (
        <SideListPanel
          panelTitle="Unit Missions"
          listItems={listItems}
          actions={panelActions}
          activeIds={selectedListItemsArray}
          loading={loadingSourceItems}
          onListItemClick={handleListItemClick}
        />
      )}
    </div>
  )
}

export default UnitMissionsSideList