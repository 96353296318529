import './EditGuestOptions.sass'

import React, { useEffect, useMemo } from 'react'
import { usePrivileges } from 'src/helper/AccountStateProvider';
import Tabs from 'src/ui/Tabs/Tabs';
import EditGuest from '../EditGuest/EditGuest';
import ManageGuestRoles from '../ManageGuestRoles/ManageGuestRoles';

function EditGuestOptions({ onOpen, onConfirm, defaultIndex }) {
  const { privileges } = usePrivileges();
  const permissions = useMemo(() => privileges?.admins.find((item) => item.id === "guests")?.privileges, [privileges]);
  const hasPermission = (id) => permissions?.includes(id);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className="edit-guest-options">
      <Tabs
        defaultIndex={defaultIndex}
        items={[
          {
            title: "Information",
            content: <EditGuest onConfirm={onConfirm} />,
            hidden: !hasPermission('edit_guest')
          },
          {
            title: "Manage Guest Roles",
            content: <ManageGuestRoles onConfirm={onConfirm} />,
            hidden: !hasPermission('manage_guest_roles')
          },
        ]}
      />
    </div>
  )
}

export default EditGuestOptions