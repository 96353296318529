import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const SerialNumberContext = createContext({});
export const SerialNumberProvider = ({ children, externalDeviceId, externalSerialNumber }) => {
  const onboard = useSelector((state) => state.onboard);
  const [serialNumber, setSerialNumber] = useState(externalSerialNumber);
  const [deviceId, setDeviceId] = useState(externalDeviceId);

  useEffect(() => {
    if (!externalSerialNumber && onboard.droneModel?.serialNumber !== undefined) {
      setSerialNumber(onboard.droneModel?.serialNumber);
    }

    if (!externalDeviceId && onboard.droneModel?.id !== undefined) {
      setDeviceId(onboard.droneModel?.id);
    }
  }, [onboard?.droneModel]);

  return (
    <SerialNumberContext.Provider value={{ serialNumber, deviceId }}>
      {children}
    </SerialNumberContext.Provider>
  );
};
export const useSerialNumber = () => {
  const { serialNumber } = useContext(SerialNumberContext);
  return { serialNumber };
};

export const useDeviceId = () => {
  const { deviceId } = useContext(SerialNumberContext);
  return { deviceId };
};
