import { createSlice } from "@reduxjs/toolkit";

const DockIdentitySlice = createSlice({
  name: "identities",
  initialState: {
    identity: null,
    connectionId: null,
  },
  reducers: {
    setIdentity(state, action) {
      const data = action.payload;
      return { ...state, identity: data };
    },
    setConnectionId(state, action) {
      const data = action.payload;
      return { ...state, connectionId: data };
    },
  },
});
export const { setIdentity, setConnectionId } = DockIdentitySlice.actions;
export default DockIdentitySlice.reducer;
