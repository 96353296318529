import "./AppNavigation.sass";
import AppNavigationItem from "./AppNavigationItem/AppNavigationItem";
import AppNavigationMenu from "../AppNavigationMenu/AppNavigationMenu";
import { useSpring, animated } from "react-spring";
import { useEffect, useState } from "react";
import OutSideDetector from "../../../ui/OutsideDetector/OutSideDetector";
import { menuItems } from "../../../helper/constants";
import { usePrivileges } from "../../../helper/AccountStateProvider";

function AppNavigation({ isOpen, toggle }) {
  const [open, setOpen] = useState(isOpen);
  const { privileges } = usePrivileges();
  const [items, setItems] = useState([
    ...menuItems.filter((item) => item.name === "dashboard"),
  ]);

  useEffect(() => {
    if (privileges.applications !== undefined)
      setItems(
        menuItems.filter((item) =>
          privileges.applications.map((a) => a.id).includes(item.name)
        )
      );
  }, [privileges.applications]);

  const toggleOff = () => {
    setOpen(false);
  };

  const delayedToggleOff = () => {
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };
  
  const props = useSpring({
    to: {
      opacity: open ? 1 : 0,
      transform: `translateX(${open ? 0 : -100}px)`,
    },
    from: { opacity: 0, transform: "translateX(-100px)" },
    onRest: () => {
      if (!open) toggle();
    },
  });
  return (
    <OutSideDetector detect={toggleOff}>
      <animated.div className="app-navigation" style={props}>
        <AppNavigationMenu onToggleClose={toggleOff} />
        <ul className="app-navigation-container">
          {items.map((item, index) => (
            <li key={index}>
              <AppNavigationItem
                navItem={item}
                navItemClicked={delayedToggleOff}
              />
            </li>
          ))}
        </ul>
      </animated.div>
    </OutSideDetector>
  );
}

export default AppNavigation;
