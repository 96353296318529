import { useDispatch, useSelector } from 'react-redux';
import './MissionTracking.sass';
import { useEffect, useRef, useState } from 'react';
import SidePanel from 'src/hci/organism/SidePanel/SidePanel';
import Loading from 'src/ui/Loading/Loading';
import DockDeviceViewer from './DockDeviceViewer/DockDeviceViewer';
import DockPairedDroneViewer from './DockPairedDroneViewer/DockPairedDroneViewer';
import { MdOutlineClose } from 'react-icons/md';
import MissionFlightDashboard from './MissionFlightDashboard/MissionFlightDashboard';
import DroneViewer from './DroneViewer/DroneViewer';
import { activeMissionTrackingId } from '../SecurityAppSlice';
import MapViewer from './MapViewer/MapViewer';
import { dockConnectionManager, mobileConnectionManager } from 'src/helper/HubConnectionManager';
import DeviceMap from 'src/components/DeviceMap/DeviceMap';
import WaypointCommandPanel from './WaypointCommandPanel/WaypoointCommandPanel';
import { setRunMissionRequest } from 'src/services/mission/MissionServiceSlice';
import { djiWaylineOutOfControlActions, djiWaylineTaskTypes } from 'src/helper/constants';
import MissionLogViewer from 'src/services/mission/MissionLogViewer/MissionLogViewer';
import { generateId } from 'src/helper/utils';
import Button from 'src/hci/common/Button/Button';

export const StageElement = {
  MAP: 'map',
  DOCK: 'dock',
  DRONE: 'drone_controller',
};

function MissionTracking({ missionData, onClose }) {
  const dockGroupHandlerIds = useRef([]);
  const dockId = missionData?.dockId || missionData?.dock?.id;
  const droneId = missionData?.deviceId || missionData?.device?.id;
  const dispatch = useDispatch();
  const dockConnection = useSelector(state => state.deviceService.dockConnections?.find(item => item.deviceId === dockId));
  const subDevice = useSelector(state => state.deviceService.deviceTelemetries[dockId]?.sub_device);
  const [activeStageElement, setActiveStageElement] = useState(StageElement.MAP);
  const issueMissionResult = useSelector(state => state.missionService.issueMissionResult);
  const executeMissionResult = useSelector(state => state.missionService.executeMissionResult);

  const sendReturnHome = (flightId) => {
    dockConnectionManager.sendToDjiCloudGroup(dockId, dockConnection?.group?.send?.services, {
      method: 'return_home',
      data: null,
    });
  }

  const sendExecuteMission = (flightId) => {
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: dockId,
        name: [dockConnection?.group?.join?.services_reply],
        handler: (data) => {
          console.log('📦 EXECUTE', data);
          //dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
        },
      },
    ], 'mission-tracking');

    dockConnectionManager.sendToDjiCloudGroup(dockId, dockConnection?.group?.send?.services, {
      method: 'flighttask_execute',
      data: {
        flight_id: flightId
      },
    });
  }

  const sendFlightAuthorityGrab = () => {
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: dockId,
        name: [dockConnection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'flight_authority_grab') {
            console.log('📦 FLIGHT_AUTHORITY_GRAB_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');

    dockConnectionManager.sendToDjiCloudGroup(dockId, dockConnection?.group?.send?.services, {
      method: 'flight_authority_grab',
      data: {},
    });
  }

  const sendOneKeyTakeoff = (flightId) => {
    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: dockId,
        name: [dockConnection?.group?.join?.services_reply],
        handler: (message) => {
          if(message.method === 'takeoff_to_point') {
            console.log('📦 ONE_KEY_TAKEOFF_RESULT', message.data);
          }
        },
      },
    ], 'mission-tracking');

    dockConnectionManager.sendToDjiCloudGroup(dockId, dockConnection?.group?.send?.services, {
      method: 'takeoff_to_point',
      data: {
        "commander_flight_height": 80,
        "commander_mode_lost_action": 1,
        "flight_id": generateId(),
        "max_speed": 12,
        "rc_lost_action": 0,
        "rth_altitude": 100,
        "security_takeoff_height": 100,
        "target_height": missionData?.waypoints?.[1]?.altitude,
        "target_latitude": missionData?.waypoints?.[1]?.latitude,
        "target_longitude": missionData?.waypoints?.[1]?.longitude,
        "simulate_mission": {
          "is_enable": 1,
          "latitude": 1.3561,
          "longitude": 103.8370
        },
      },
    });
  }

  const sendIssueMission = () => {
    alert('ok');
    const messageHandlers = [
      {
        identity: dockId,
        name: [dockConnection?.group?.join?.services_reply],
        handler: (message) => {
          //dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
          console.log('📦 MESSAGE', message);

          if(message?.method === 'flighttask_prepare' && message?.data?.result === 0)
            sendExecuteMission(missionData?.flightId);
        },
      },
      {
        identity: dockId,
        name: [dockConnection?.group?.join?.events],
        handler: (data) => {
          console.log('✨ EVENT', data);
          //dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
        },
      },
    ];

    if(subDevice?.device_sn) {
      messageHandlers.push({
        identity: subDevice.device_sn,
        name: [
          `thing/product/${subDevice.device_sn}/state`,
          `thing/product/${subDevice.device_sn}/osd`
        ],
        handler: (message) => {
          //dockConnectionManager.unsubscribeGroupMessages(dockGroupHandlerIds.current);
          console.log('✈️ paired drone message', message);
        },
      });
    }

    dockGroupHandlerIds.current = dockConnectionManager.subscribeGroupMessages(messageHandlers, 'mission-tracking');

    dockConnectionManager.sendToDjiCloudGroup(dockId, dockConnection?.group?.send?.services, {
      method: 'flighttask_prepare',
      data: {
        "file": {
          "fingerprint": missionData?.flight?.fingerprint,
          "url": missionData?.flight?.url
        },
        "flight_id": missionData?.flight?.id,
        "out_of_control_action": 0,
        "simulate_mission": {
          "is_enable": 1,
          "latitude": 1.3561,
          "longitude": 103.8370
        },
        "task_type": 0,
        "rth_altitude": 30,
        "exit_wayline_when_rc_lost": 1,
        "execute_time": Date.now()
      },
    });

    // dispatch(setRunMissionRequest({
    //   "file": {
    //     "fingerprint": missionData?.flight?.fingerprint,
    //     "url": missionData?.flight?.url
    //   },
    //   "flight_id": missionData?.flight?.id,
    //   "out_of_control_action": djiWaylineOutOfControlActions.RETURN_HOME,
    //   "simulate_mission": {
    //     "is_enable": 1,
    //     "latitude": 1.3561,
    //     "longitude": 103.8370
    //   },
    //   "task_type": djiWaylineTaskTypes.IMMEDIATE,
    //   "rth_altitude": Math.max(...(missionData.waypoints.map(item => item.altitude) || [30])),
    //   "exit_wayline_when_rc_lost": 1,
    //   "execute_time": Date.now()
    // }));
  }

  useEffect(() => {
    if(issueMissionResult) {
      console.log('📦 MISSION ISSUE RESULT', issueMissionResult);
    }
  }, [issueMissionResult]);

  useEffect(() => {
    if(executeMissionResult) {
      console.log('📦 MISSION EXECUTE RESULT', executeMissionResult)
    }
  }, [executeMissionResult]);

  const swapStageElement = (element) => {
    setActiveStageElement(element);
  }

  useEffect(() => {
    if(missionData)
      dispatch(activeMissionTrackingId(missionData.id));

    return () => {
      dispatch(activeMissionTrackingId(null));
    }
  }, [missionData]);

  const panelActions = [
    {
      title: "Close",
      content: <MdOutlineClose />,
      handler: () => {
        onClose?.();
      }
    }
  ];

  return (
    <div className="mission-tracking">
      <SidePanel panelTitle={`Mission Tracking`} actions={panelActions}>
        <div className="side-panels-container">
          <Button onClick={sendIssueMission} variant='soft' color='green'>Trigger Mission</Button>
          <Button onClick={sendFlightAuthorityGrab} variant='soft' color='green'>Flight Authority Grab</Button>
          <Button onClick={sendOneKeyTakeoff} variant='soft' color='green'>One-key takeoff</Button>
          <Button onClick={() => {
            mobileConnectionManager.sendToGroup()
          }} variant='soft' color='green'>Return Home</Button>
          { !!(true) && (
            <WaypointCommandPanel deviceId={ dockId || droneId } />
          )}
          { !!(activeStageElement !== StageElement.MAP) && (
            <MapViewer onSwap={swapStageElement} />
          )}
          { !!(activeStageElement !== StageElement.DOCK && dockId) && (
            <DockDeviceViewer deviceId={dockId} onSwap={swapStageElement} /> 
          )}
          { !!(activeStageElement !== StageElement.DRONE && droneId) && (
            <DroneViewer deviceId={droneId} onSwap={swapStageElement} /> 
          )}
          { !!(activeStageElement !== StageElement.DRONE && dockId && droneId) && (
            <DockPairedDroneViewer 
              dockId={dockId} 
              droneId={droneId} 
              onSwap={swapStageElement} />
          )}
        </div>
      </SidePanel>
      <div className="stage-container">
        {
          {
            [StageElement.MAP]: <DeviceMap />,
            [StageElement.DOCK]: <DockDeviceViewer deviceId={dockId} expanded={true} />,
            [StageElement.DRONE]: <MissionFlightDashboard deviceId={droneId} />,
          }[activeStageElement]
        }
      </div>
      {/* <MissionLogViewer flightId={missionData?.flight?.id} /> */}
    </div>
  )
}

export default MissionTracking