import './StageStatusbarTabs.sass'
import { MdClose, MdFolder } from 'react-icons/md';
import classNames from 'classnames';
import DeviceMediaList from '../../DeviceMediaList/DeviceMediaList';
import { useState } from 'react';

function StageStatusbarTabs({ tabs }) {
  const [activeStatusbarTab, setActiveStatusbarTab] = useState(null);
  const [panelHeaderLeft, setPanelHeaderLeft] = useState();
  const [panelHeaderTools, setPanelHeaderTools] = useState();

  return (
    <div className="stage-statusbar-tabs">
      {tabs?.map(tab => (
        <div className={classNames("statusbar-tab", {'active': activeStatusbarTab === tab.id})}>
          <div className="tab-button" onClick={() => setActiveStatusbarTab(tab.id)}>
            {tab.icon}
            <div className="button-text">{tab.title}</div>
          </div>
          <div className="tab-panel">
            <div className="panel-header">
              <div className="panel-title">{tab.title}</div>
              {panelHeaderLeft ? <div className="panel-header-left">{panelHeaderLeft}</div> : null}
              <div className="panel-tools">
                {panelHeaderTools}
                <MdClose className="tool-item" onClick={() => setActiveStatusbarTab(null)} />
              </div>
            </div>
            <div className="panel-content">
              {activeStatusbarTab === tab.id ? tab.content?.(setPanelHeaderLeft, setPanelHeaderTools) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default StageStatusbarTabs