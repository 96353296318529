import React from "react";
import "./ThankYou.sass";
import Button from "../../../../ui/Button/Button";
import { tabletSize, useViewport } from "../../../../helper/ViewPortProvider";
import { useHistory } from "react-router";
import { useAccount } from "src/helper/AccountStateProvider";

const ThankYou = () => {
  const history = useHistory();
  const dashboard = () => {
    history.push("dashboard");
  };
  const { account } = useAccount();
  const { width } = useViewport();
  return (
    <div className="thank-you">
      <p>
        Thank you <strong>{account?.user?.name}</strong> for providing all the
        information!
      </p>
      <Button
        color="#2699fb"
        value="Get Started"
        width={width < tabletSize ? "50%" : "25%"}
        onClick={dashboard}
      />
    </div>
  );
};

export default ThankYou;
