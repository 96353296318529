import { useEffect, useRef, useState } from "react";
import {
  LoadingIcon,
  OneDriveIcon,
  StorageAccountIcon,
} from "../../../../../../ui/Icons/Icons";
import { MdError, MdInsertDriveFile, MdRefresh } from "react-icons/md";
import { useTheme } from "../../../../../../helper/ThemeProvider";
import { ImageFileIcon, VideoFileIcon } from "../../../../../../ui/Icons/Icons";
import LabeledButton from "../../../../../../ui/LabeledButton/LabeledButton";
import "./DeviceFileManager.sass";
import { useDispatch } from "react-redux";
import { FormatBytes } from "../../../../../../helper/utils";
import ApplicationComponentContent from "../../../../Components/ApplicationComponentContent/ApplicationComponentContent";
import AppTable from "../../../../../../ui/AppTable/AppTable";
import EntityName from "../../../../../../ui/EntityName/EntityName";
import { RiFolderTransferLine } from "react-icons/ri";
import UploadFileToContainer from "./UploadFileToContainer/UploadFileToContainer";
import { useAccount } from "../../../../../../helper/AccountStateProvider";
import LabeledIcon from "../../../../../../ui/LabeledIcon/LabeledIcon";
import DateTimeComponent from "../../../../../../ui/DateTimeComponent/DateTimeComponent";
import moment from "moment";
import { IoCloudDownload, IoCloudOutline } from "react-icons/io5";
import { IoMdCloudDownload } from "react-icons/io";
import { previewFile, selectedFiles } from "../OnboardDeviceAppSlice";
import UploadedFilePreview from "./UploadedFilePreview/UploadedFilePreview";
import { useDeviceId } from "../../../../../../helper/SerialNumberProvider";
import { useSelector } from "react-redux";
import { onboardConnectionManager } from "../../../../../../helper/HubConnectionManager";
import OnboardHubProvider from "src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider";
import {
  FileManagerIndexes,
  HubActions,
  HubEventName,
  HubName,
  PayloadIndexes,
  StorageIndexes,
} from "src/helper/HubConnection";
import {
  files,
  uploadResult,
} from "src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";

function DeviceFileManager() {
  const [uploadProgress, setUploadProgress] = useState();
  const [operationHandler, setOperationHandler] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState({});
  const [multiSelect, setMultiSelect] = useState(true);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(250);
  const { account } = useAccount();
  const { currentContext } = useTheme();
  const dispatch = useDispatch();
  const { deviceId } = useDeviceId();
  const droneFiles = useSelector((state) => state.flight.files);
  const droneUploadResult = useSelector((state) => state.flight.uploadResult);
  const permissionGroups = useSelector(
    (state) => state.websocket.permissionGroups[deviceId]
  );
  const serverMessageHandlerIds = useRef([]);
  const groupMessageHandlerIds = useRef([]);

  const requestFileList = () => {
    onboardConnectionManager?.sendToGroup(
      deviceId,
      permissionGroups?.send?.commandChannel,
      {
        fileManagement: {
          action: FileManagerIndexes.GetFiles,
          payloadIndex: PayloadIndexes.Camera1,
          page,
          itemsPerPage,
        }
      }
    );
  };

  const onboardServerMessageHandlers = [
    {
      identity: deviceId,
      name: HubEventName.UPLOAD_TO_STORAGE_COMPLETE,
      handler: (data) => {
        const message = JSON.parse(data?.message);
        setUploadProgress(message);
      },
    },
    {
      identity: deviceId,
      name: HubEventName.UPLOAD_TO_STORAGE_FAILS,
      handler: (data) => {
        //TODO handle errors
        console.log(data);
      },
    },
  ];

  useEffect(() => {
    onboardConnectionManager.unsubscribeServerMessages(
      serverMessageHandlerIds.current
    );

    serverMessageHandlerIds.current =
      onboardConnectionManager.subscribeServerMessages(
        onboardServerMessageHandlers,
        "deviceFileManager"
      );

    return () => {
      onboardConnectionManager.unsubscribeServerMessages(
        serverMessageHandlerIds.current
      );
      dispatch(files([]));
    };
  }, [deviceId]);

  useEffect(() => {
    console.log(droneFiles);
  }, [droneFiles]);

  const [fileSize, setFileSize] = useState(0);
  useEffect(() => {
    console.log(FormatBytes(fileSize, 2));
  }, [fileSize]);

  useEffect(() => {
    setFileSize(0);
    droneFiles.map((file) =>
      setFileSize((currentSize) => {
        return currentSize + file.fileSize;
      })
    );
    setTableRows({
      ...tableRows,
      values: [
        ...droneFiles?.map((file) => ({
          id: file.fileName,
          name: file.fileName,
          brand: (
            <LabeledIcon
              color="#878787"
              textColor="#455060"
              icon={(() => {
                const extenstion = file.fileName?.split('.')?.slice(-1)[0];

                if (['jpg', 'jpeg', 'png'].includes(extenstion)) return (
                  <ImageFileIcon
                    type={extenstion}
                    size="2"
                  />
                );

                if (['mp4', 'avi'].includes(extenstion)) return (
                  <VideoFileIcon
                    type={extenstion}
                    size="2"
                  />
                );

                return (
                  <MdInsertDriveFile color="#b8b8b8" size="2em" />
                )
              })()}
              label={file.fileName}
            />
          ),
          size: FormatBytes(file.fileSize, 2),
          type: types[file.fileType],
          uploadDate: (
            <DateTimeComponent
              date={moment.utc(file.date).format("DD MMMM YYYY")}
              time={moment.utc(file.date).format("HH:mm:ss A")}
              hour={parseInt(moment.utc(file.date).format("h"))}
            />
          ),
          date: moment.utc(file.date).format("ddd , DD MMMM YYYY HH:mm:ss A"),
          state: (
            <IoCloudOutline size="20" color={currentContext.theme.color} />
          ),
        })),
      ],
    });
  }, [droneFiles]);

  const subscribeGroups = () => {
    const fileManagerActions = [
      ['fileManagement', data => {
        switch (data?.action) {
          case FileManagerIndexes.GetFiles:
            dispatch(files(data?.fileList));
            break;
          case FileManagerIndexes.GetUploadResult:
            dispatch(uploadResult(data));
            break;

          default:
            break;
        }
      }],
    ];

    const groupMessageHandlers = [
      {
        identity: deviceId,
        name: permissionGroups?.join?.onDemandChannel,
        handler: (data) => {
          fileManagerActions.forEach(([key, handler]) => {
            if (key in data) handler(data[key]);
          });
        },
      },
    ];

    onboardConnectionManager.unsubscribeGroupMessages(
      groupMessageHandlerIds.current
    );

    groupMessageHandlerIds.current =
      onboardConnectionManager.subscribeGroupMessages(
        groupMessageHandlers,
        "deviceFileManager"
      );
  };

  const types = [
    "Image",
    "Image",
    "Video",
    "Video",
    "Image",
    "Image",
    "CTRL",
    "CTRL",
    "CTRL",
    "CTRL",
    "Audio",
  ];

  const showPreview = (fileName) => {
    dispatch(previewFile(tableRows.values?.find(row => row.name === fileName)));
    toggleFeature("file_preview", "file_preview_information");
  }

  useEffect(() => {
    if (droneUploadResult?.status !== undefined) {
      if (droneUploadResult.status === true) {
        var localUploadedFiles = localStorage.getItem("uploadedFiles");
        if (localUploadedFiles !== null) {
          localUploadedFiles = localUploadedFiles.split(",");
          if (!localUploadedFiles.includes(droneUploadResult.fileName)) {
            localUploadedFiles.push(droneUploadResult.fileName);
            localStorage.setItem("uploadedFiles", localUploadedFiles);
          }
        } else {
          localUploadedFiles = Array([]);
          localUploadedFiles.push(droneUploadResult.fileName);
          localStorage.setItem(
            "uploadedFiles",
            localUploadedFiles.filter((x) => x !== null)
          );
        }
      }

      setTableRows({
        ...tableRows,
        values: [
          ...tableRows.values.map((file) => ({
            ...file,
            state:
              file.name === droneUploadResult.fileName ? (
                droneUploadResult.status === true ? (
                  <LabeledButton
                    title="Preview"
                    icon={
                      <IoMdCloudDownload
                        size="20"
                        color={currentContext.theme.color}
                      />
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                      showPreview(file.name);
                    }}
                  />
                ) : (
                  <MdError size="20" color="#ff7c7c" />
                )
              ) : (
                file.state
              ),
          })),
        ],
      });
    }
  }, [droneUploadResult]);

  useEffect(() => {
    if (uploadProgress?.success === true) {
      setTableRows({
        ...tableRows,
        values: [
          ...tableRows.values.map((file) => ({
            ...file,
            link: uploadProgress?.files.map((f) => f.name).includes(file.name)
              ? uploadProgress?.files.find((f) => f.name === file.name)?.link
              : file.link,
            active: uploadProgress?.files.map((f) => f.name).includes(file.name),
            state: uploadProgress?.files
              .map((f) => f.name)
              .includes(file.name) ? (
              <LoadingIcon size="20" color={currentContext.theme.color} />
            ) : (
              file.state
            ),
          })),
        ],
      });
    }
  }, [uploadProgress]);

  const uploadFiles = (container, uploadType, deviceId) => {
    if (uploadType === "storage")
      onboardConnectionManager?.sendEvent(
        deviceId,
        HubEventName.UPLOAD_TO_STORAGE,
        {
          action: HubActions.UPLOAD_FILES,
          payloadIndex: PayloadIndexes.Camera1,
          destination: StorageIndexes.BlobStorage,
          platform: HubName.FRONTEND,
          deviceId: deviceId,
          accountId: account?.user?.idTokenClaims?.extension_accountId,
          userId: account?.user?.localAccountId,
          ...container,
        }
      );
    toggleOperationHandlerOff();
  };

  const controls = [];

  const actions = [
    {
      name: "container_upload",
      active: true,
      component: (
        <LabeledButton
          title="Upload"
          icon={
            <RiFolderTransferLine size="15" style={{ marginLeft: ".2em" }} />
          }
          onClick={() => {
            toggleFeature("upload_files", "container_upload");
          }}
        />
      ),
    },
    {
      name: "onedrive_upload",
      active: false,
      component: (
        <LabeledButton
          title="Upload to OneDrive"
          icon={<OneDriveIcon size="15" />}
          onClick={() => {
            toggleFeature("onedrive_upload");
          }}
        />
      ),
    },

    {
      name: "refresh_files",
      active: true,
      component: (
        <LabeledButton
          title="Refresh"
          icon={<MdRefresh size="15" style={{ marginLeft: ".2em" }} />}
          onClick={() => {
            toggleOperationHandlerOff();
            requestFileList();
          }}
        />
      ),
    },
  ];

  const [tableRows, setTableRows] = useState({
    headers: [
      { title: "Name", value: "brand", status: true },
      { title: "Type", value: "type", status: true },
      { title: "Size", value: "size", status: true },
      { title: "Upload Date", value: "uploadDate", status: true },
      { title: "State", value: "state", status: true },
    ],
    values: [],
    options: [],
  });

  const features = [
    {
      id: "upload_files",
      info: (
        <EntityName
          item={{
            title: "Upload",
            icon: (
              <RiFolderTransferLine
                size="20"
                color={currentContext.theme.color}
              />
            ),
          }}
        />
      ),
      steps: [
        {
          name: "container_upload",
          title: (
            <LabeledIcon
              icon={<StorageAccountIcon size="1.5em" />}
              textColor="#23333d"
              label="Upload to Container"
            />
          ),
          active: true,
          visible: true,
          locked: false,
          closable: false,
          tags: ["containerApp/list", "containerApp/blobs"],
          activity: <UploadFileToContainer onUpload={uploadFiles} />,
        },
        {
          name: "onedrive_upload",
          title: "Upload to OneDrive",
          active: false,
          visible: false,
          locked: false,
          tags: [""],
          activity: <span>Upload to OneDrive</span>,
        },
      ],
    },
    {
      id: "file_preview",
      info: (
        <EntityName
          item={{
            title: "Preview & Download",
            icon: (
              <IoCloudDownload size="20" color={currentContext.theme.color} />
            ),
          }}
        />
      ),
      steps: [
        {
          name: "file_preview_information",
          title: (
            <LabeledIcon
              icon={<StorageAccountIcon size="1.5em" />}
              textColor="#23333d"
              label="File Preview"
            />
          ),
          active: true,
          visible: true,
          locked: false,
          closable: false,
          tags: [],
          activity: <UploadedFilePreview />,
        },
      ],
    },
  ];

  const rowChangeHandler = (row) => {
    if (row !== undefined) {
      // console.log(row);
    }
  };

  const rowsChangeHandler = (rows) => {
    if (rows !== undefined) {
      dispatch(selectedFiles([...rows]));
    }
  };
  const optionHandler = (name) => {
    if (name !== undefined) {
      // console.log(name);
    }
  };

  const toggleOperationHandlerOff = () => {
    setOperationHandler(false);
    setMultiSelect(true);
  };

  const toggleOperationHandlerOn = () => {
    setOperationHandler(true);
  };
  const toggleFeature = (featureName, stepName) => {
    const currentFeature = features.find((x) => x.id === featureName);
    setSelectedFeature({
      ...currentFeature,
      steps: currentFeature.steps.map((step) => ({
        ...step,
        active: step.name === stepName,
      })),
    });
    toggleOperationHandlerOn();
  };

  return (
    <OnboardHubProvider
      onJoinComplete={() => {
        subscribeGroups();
        requestFileList();
      }}
      candidateGroups={['onDemandChannel']}
    >
      <ApplicationComponentContent
        controls={controls}
        actions={actions}
        feature={selectedFeature}
        toggleOperation={operationHandler}
        onCloseOperationManager={toggleOperationHandlerOff}
      >
        <div className="device-file-manager">
          <AppTable
            rows={tableRows}
            onRowChange={rowChangeHandler}
            onRowsChange={rowsChangeHandler}
            optionChangeHandler={optionHandler}
            tags={["onboard/files"]}
            selectable
            multiSelect={multiSelect}
            boldIndexes={[]}
          />
        </div>
      </ApplicationComponentContent>
    </OnboardHubProvider>
  );
}
export default DeviceFileManager;