import { notification } from "src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToPermissionGroups,
  onboardConnection,
  connections,
  getOnboardConnection,
  getWsPermissions,
  permissions,
  addToConnectionPool,
  removeFromConnectionPool,
} from "../WebsocketHandler/WebsocketSlice";

function WebsocketHubProvider({ children, hub }) {
  const dispatch = useDispatch();
  const instance = useSelector((state) => state.websocket);
  const { identity: id } = useSelector((state) => state.identities);

  useEffect(() => {
    if (instance.newOnboardConnection) {
      dispatch(removeFromConnectionPool(instance.newOnboardConnection));
      dispatch(addToConnectionPool(instance.newOnboardConnection));
    }
  }, [instance.newOnboardConnection]);

  useEffect(() => {
    if (
      instance?.permissionPool.includes(id) &&
      !instance?.permissionGroups[id] &&
      hub?.getConnectionId(id)
    ) {
      dispatch(
        getWsPermissions({
          ids: [id],
          connectionId: hub?.getConnectionId(id),
        })
      );
    }
  }, [instance?.permissionPool, id]);

  useEffect(() => {
    if (instance?.connectionPool.includes(id) && !instance?.connections[id]) {
      dispatch(
        getOnboardConnection(id, {
          deviceId: id,
        })
      );
    }
  }, [instance?.connectionPool, id]);

  useEffect(() => {
    if (instance?.disposedIdentity) {
      hub?.closeConnection(instance?.disposedIdentity, true);
    }
  }, [instance?.disposedIdentity]);

  useEffect(() => {
    if (instance.permissions?.status === "success") {
      dispatch(
        addToPermissionGroups({
          [id]: instance.permissions.data.group,
        })
      );

      dispatch(
        permissions({
          status: "",
        })
      );
    }
  }, [instance]);

  useEffect(() => {
    if (instance.onboardConnection?.status === "success") {
      if (instance?.onboardConnection?.data?.url) {
        hub?.create(
          instance?.onboardConnection?.data?.url,
          () => {
            dispatch(
              connections({
                [id]: instance?.onboardConnection?.data?.url,
              })
            );
          },
          () => {
            dispatch(
              notification({
                message: "Reconnecting...",
                status: "warning",
              })
            );
          },
          () => {
            dispatch(
              notification({
                message: "Client Maximum Reconnection has Failed!",
                status: "error",
              })
            );
          },
          () => {
            dispatch(
              notification({
                message: "Poor Connection...",
                status: "warning",
              })
            );
          }
        );
      }
      dispatch(
        onboardConnection({
          status: "",
        })
      );
    }
  }, [instance]);

  return <>{children}</>;
}

export default WebsocketHubProvider;
