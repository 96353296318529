import './EmptyMediaList.sass'
import { TbFoldersOff } from 'react-icons/tb'

function EmptyMediaList() {
  return (
    <div className="empty-media-list">
      <div className="content">
        <div className="icon-container">
          <TbFoldersOff className="icon" />
        </div>
        <div className="title">No Media Found</div>
        <div className="description">We'll display your media here once it's processed</div>
      </div>
    </div>
  )
}

export default EmptyMediaList