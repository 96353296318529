import React from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';

function VideoStreamView({ stream, className, onVideoRenderChange, onClick }) {
  const videoRef = useRef();

  const onRenderInfoChange = () => {
    onVideoRenderChange?.({
      width: videoRef.current?.videoWidth,
      height: videoRef.current?.videoHeight,
    });
  }

  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.srcObject = stream;
    videoRef.current.load();
  }, [stream]);

  return (
    <video
      ref={videoRef}
      className={className}
      onLoadedMetadata={onRenderInfoChange}
      onClick={onClick}
      autoPlay
      playsInline
      muted
    />
  );
}

export default VideoStreamView