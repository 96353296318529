import { forwardRef } from 'react';
import './Popover.sass';
import { Popover as TinyPopover, ArrowContainer } from 'react-tiny-popover';

// a custom popover component using react-tiny-popover
const Popover = ({isOpen, children, content, onClose, ...props}) => {
  return (
    <TinyPopover
      isOpen={isOpen}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowSize={8}
          arrowColor='#838383'
          className='popover-arrow-container'
          arrowClassName='ir-popover-arrow'
        >
          <div className="ir-popover">
            {content}
          </div>
        </ArrowContainer>
      )}
      onClickOutside={onClose}
      {...props}
    >
      {children}
    </TinyPopover>
  );
};

export { Popover }