import { useSettings } from "./SettingsProvider";
import convert from 'convert';
import { MeasurementSystem, MeasurementType } from "./constants";
import { useCallback } from "react";

const useMeasurement = () => {
  const { measurementSystem } = useSettings();

  const getMeasurementConvertUnits = (system) => {
    if(system === MeasurementSystem.METRIC) return {
      speedUnit: 'meters',
      distanceUnit: 'meters',
      altitudeUnit: 'meters',
      temperatureUnit: 'celsius',
    }
    else return {
      speedUnit: 'miles',
      distanceUnit: 'feet',
      altitudeUnit: 'feet',
      temperatureUnit: 'fahrenheit',
    }
  }

  const getMeasurementUnit = useCallback((measurementType) => {
    if(measurementSystem === MeasurementSystem.METRIC) {
      return {
        [MeasurementType.SPEED]: 'm/s',
        [MeasurementType.DISTANCE]: 'm',
        [MeasurementType.ALTITUDE]: 'm',
        [MeasurementType.TEMPERATURE]: '°C',
      }[measurementType];
    }
    else {
      return {
        [MeasurementType.SPEED]: 'mph',
        [MeasurementType.DISTANCE]: 'ft',
        [MeasurementType.ALTITUDE]: 'ft',
        [MeasurementType.TEMPERATURE]: '°F',
      }[measurementType];
    }
  }, [measurementSystem]);

  const convertValue = useCallback((value, type, from, to = measurementSystem, dynamicUnit = false, precision = 1) => {
    const fromUnits = getMeasurementConvertUnits(from);
    const toUnits = getMeasurementConvertUnits(to);
    const bestKind = dynamicUnit ? to : undefined;
    const inputValue = value || 0;
    let result;

    if(type === MeasurementType.SPEED) {
      result = convert(inputValue, fromUnits.speedUnit).to(dynamicUnit ? 'best' : toUnits.speedUnit, bestKind);

      // convert speed from m/s to mph or vice versa
      result = !dynamicUnit && from === MeasurementSystem.METRIC && to === MeasurementSystem.IMPERIAL ? result * 60 * 60 : result;
      result = !dynamicUnit && from === MeasurementSystem.IMPERIAL && to === MeasurementSystem.METRIC ? result / (60 * 60) : result;
    }
    else if(type === MeasurementType.DISTANCE) {
      result = convert(inputValue, fromUnits.distanceUnit).to(dynamicUnit ? 'best' : toUnits.distanceUnit, bestKind);
    }
    else if(type === MeasurementType.ALTITUDE) {
      result = convert(inputValue, fromUnits.altitudeUnit).to(dynamicUnit ? 'best' : toUnits.altitudeUnit, bestKind);
    }
    else if(type === MeasurementType.TEMPERATURE) {
      result = convert(inputValue, fromUnits.temperatureUnit).to(dynamicUnit ? 'best' : toUnits.temperatureUnit, bestKind);
    }

    // if result is a float number, round it to the precision
    if(!dynamicUnit && result % 1 !== 0) {
      result = result.toFixed(precision);
    }

    return parseFloat(result);
  }, [measurementSystem]);

  return { measurementSystem, convertValue, getMeasurementConvertUnits, getMeasurementUnit };
}

export default useMeasurement;