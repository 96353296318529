import './MainVideo.sass'

import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { cameraSource, enlargeMap, videoPip } from "src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";
import classNames from "classnames";
import { useEffect } from 'react';
import VideoStreamView from '../VideoStreamView/VideoStreamView';
import CameraFocusBoundary from '../CameraFocusBoundary/CameraFocusBoundary';
import { useState } from 'react';
import { useRef } from 'react';
import CameraBusyOverlay from '../CameraBusyOverlay/CameraBusyOverlay';

function MainVideo({ mainStream, fpvStream }) {
  const videoContainerRef = useRef();
  const mapOverlay = useSelector((state) => state.flight?.enlargeMap);
  const videoPipState = useSelector((state) => state.flight?.videoPip);
  const enlargeMapState = useSelector((state) => state.flight?.enlargeMap);
  const mainVideoSourceState = useSelector((state) => state.flight?.mainVideoSource);
  const workMode = useSelector((state) => state.flight.cameraStats?.workMode);
  const [videoDimension, setVideoDimension] = useState({ width: 0, height: 0 });
  const [focusBoundaryDimension, setFocusBoundaryDimension] = useState({ width: 0, height: 0 });
  const dispatch = useDispatch();

  const handleContainerClick = () => {
    if (enlargeMapState) {
      dispatch(enlargeMap(false));
      dispatch(videoPip(false));
    }
  }

  const handleVideoRenderChange = (data) => {
    const info = data?.width ? data : videoDimension;
    const videoRatio = workMode === 1 ? info.width / info.height : 1.33;

    if (!videoRatio) return;

    setVideoDimension({
      width: info.width,
      height: info.height
    });

    setFocusBoundaryDimension({
      width: videoContainerRef.current?.offsetHeight * videoRatio,
      height: videoContainerRef.current?.offsetHeight
    });

    console.log('focus-boundary', {
      videoInfo: info,
      container: [videoContainerRef.current?.offsetWidth, videoContainerRef.current?.offsetHeight],
      boundaryWidth: videoContainerRef.current?.offsetHeight * videoRatio,
      ratio: videoRatio
    });
  }

  useEffect(() => {
    handleVideoRenderChange();
  }, [workMode]);

  useEffect(() => {
    window.addEventListener('resize', () => handleVideoRenderChange());
  }, []);

  return (
    <div className={classNames('main-video', {
      'pip': videoPipState,
      'hidden': mapOverlay && !videoPipState
    })} onClick={handleContainerClick} ref={videoContainerRef}>
      <VideoStreamView
        stream={mainVideoSourceState === cameraSource.FPV ? fpvStream : mainStream}
        className="main-video-viewer"
        onVideoRenderChange={handleVideoRenderChange}
      />
      {!videoPipState && mainVideoSourceState === cameraSource.MAIN ? (
        <CameraFocusBoundary
          className="main-focus-boundary"
          width={focusBoundaryDimension.width}
          height={focusBoundaryDimension.height}
        />
      ) : null}
      {workMode === 2 && <CameraBusyOverlay />}
    </div>
  )
}

export default MainVideo