import React, { useEffect, useRef } from "react";

function OutSideDetector({ className, children, detect, object, width,background }) {
  function useOutsideDetector(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          object !== undefined ? detect(object) : detect();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideDetector(wrapperRef);
  return (
    <div style={{  width:width,background:background  }} className={className} ref={wrapperRef}>
      {children}
    </div>
  );
}
OutSideDetector.defaultProps = {
  className: "outside-detector",
};

export default OutSideDetector;
