import "./AppLauncher.sass";
import AppLauncherItem from "./AppLauncherItem/AppLauncherItem";
import { appLauncherItems } from "src/helper/constants";
import { useTheme } from "src/helper/ThemeProvider";
import { Link } from "react-router-dom";
import { usePrivileges } from "src/helper/AccountStateProvider";
import { useEffect, useMemo, useState } from "react";
import { BiPin } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { RiUnpinLine } from "react-icons/ri";
import { toggleMainMenuOpen, toggleMainMenuPin } from "src/store/UiSlice";
import { VscClose } from "react-icons/vsc";

function AppLauncher() {
  const [items, setItems] = useState([
    ...appLauncherItems.filter((item) => item.name === "dashboard"),
  ]);
  const { privileges } = usePrivileges();
  const dispatch = useDispatch();
  const uiState = useSelector((state) => state.ui);

  useEffect(() => {
    if (privileges.applications !== undefined)
      setItems(
        appLauncherItems.filter((item) =>
          privileges.applications.map((a) => a.id).includes(item.name) || item.name === "security" || item.name === "explore"
        )
      );
  }, [privileges.applications]);

  const pinButton = useMemo(() => (
    <div className={classNames("app-launcher-pin", {"active": uiState.mainMenu.pinned})} onClick={() => dispatch(toggleMainMenuPin())}>
      {uiState.mainMenu.pinned ?  <RiUnpinLine /> : <BiPin />}
    </div>
  ), [uiState.mainMenu.pinned]);

  return (
    <div className={classNames("app-launcher", {"pinned": uiState.mainMenu.pinned, "open": uiState.mainMenu.open})}>
      <div className="app-launcher-container">
        <div className="app-launcher-header">
          <VscClose className="app-launcher-close" onClick={() => dispatch(toggleMainMenuOpen())} />
          {pinButton}
        </div>
        {items.map((item, index) => (
          <AppLauncherItem item={item} key={item.name} />
        ))}
        {uiState.mainMenu.pinned ? pinButton : null}
      </div>
    </div>
  );
}

export default AppLauncher;
