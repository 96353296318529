import CameraTelemetry from 'src/components/Dashboard/Components/CameraTelemetry/CameraTelemetry';
import { cameraExposureModes } from 'src/helper/constants';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

function CameraMM() {
  const flight = useSelector((state) => state.flight);
  const exposureMode = useSelector((state) => state.flight.camera?.exposureMode);
  const [currValue, setCurrValue] = useState("Auto");
  const { activeCameraPayload } = useDeviceInfo();
  const exposureValues = activeCameraPayload?.settings?.exposure?.values;

  useEffect(() => {
    if (flight.camera?.exposureVal)
      setCurrValue(exposureValues?.[flight.camera?.exposureVal - 1]);
  }, [flight.camera, exposureValues]);

  if (exposureMode === cameraExposureModes.EXPOSURE_MANUAL) return null;

  return (
    <CameraTelemetry
      name="mm"
      title="MM"
      value={'-'}
    />
  );
}

export default CameraMM