import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const GuestManagementSlice = createSlice({
  name: "guest",
  initialState: {
    mail: { status: "default" },
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    model: { AutoGeneratedPassword: false },
    list: [],
    permissions: [],
    name: { status: "" },
    roles: { status: "" },
    rolesList: { status: "" },
    password: { status: "" },
    search: { status: "" },
    assignRoles: { status: "" },
  },
  reducers: {
    mail(state, action) {
      const data = action.payload;
      return { ...state, mail: data };
    },
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    permissions(state, action) {
      const data = action.payload;
      return { ...state, permissions: data };
    },
    setModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
    name(state, action) {
      const data = action.payload;
      return { ...state, name: { ...data } };
    },
    roles(state, action) {
      const data = action.payload;
      return { ...state, roles: { ...data } };
    },
    password(state, action) {
      const data = action.payload;
      return { ...state, password: { ...data } };
    },
    search(state, action) {
      const data = action.payload;
      return { ...state, search: { ...data } };
    },
    assignRoles(state, action) {
      const data = action.payload;
      return { ...state, assignRoles: { ...data } };
    },
    rolesList(state, action) {
      const data = action.payload;
      return { ...state, rolesList: { ...data } };
    },
  },
});
export const {
  mail,
  list,
  permissions,
  create,
  update,
  remove,
  setModel,
  name,
  roles,
  password,
  search,
  assignRoles,
  rolesList,
} = GuestManagementSlice.actions;
export default GuestManagementSlice.reducer;

export const getList = () =>
  apiCallBegan({
    url: "/guests",
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const createGuest = (data) =>
  apiCallBegan({
    url: "/guests",
    method: "post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const removeGuests = (data) =>
  apiCallBegan({
    url: "/guests",
    method: "delete",
    data,
    onSuccess: remove.type,
    onError: remove.type,
  });

export const updateGuest = (data, id) =>
  apiCallBegan({
    url: "/guests/" + id,
    method: "patch",
    data,
    onSuccess: update.type,
    onError: update.type,
  });

export const check = (mailToCheck) =>
  apiCallBegan({
    url: "/guests/check-mail/" + mailToCheck,
    method: "Get",
    onSpin: mail.type,
    onSuccess: mail.type,
    onError: mail.type,
  });

export const getRolesList = () =>
  apiCallBegan({
    url: "/guests/roles",
    method: "Get",
    onSuccess: rolesList.type,
    onError: rolesList.type,
  });

export const changePassword = (data, id) =>
  apiCallBegan({
    url: "guests/" + id + "/credential/",
    method: "Patch",
    data,
    onSuccess: password.type,
    onError: password.type,
  });

export const searchGuests = (query) =>
  apiCallBegan({
    url: "guests/search/" + query,
    method: "Get",
    onSuccess: search.type,
    onError: search.type,
  });

export const assignGuestRoles = (id, data) =>
  apiCallBegan({
    url: "guests/" + id + "/roles",
    method: "Put",
    data,
    onSuccess: assignRoles.type,
    onError: assignRoles.type,
  });

export const getGuestRoles = (id) =>
  apiCallBegan({
    url: "guests/" + id + "/roles",
    method: "Get",
    onSuccess: roles.type,
    onError: roles.type,
  });
