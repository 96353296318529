import InputBox from "../../../../../ui/InputBox/InputBox";
import "./EditDevice.sass";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import useDebounce from "../../../../../helper/use-debounce";
import { checkName, name, updateDevice } from "../DeviceManagementSlice";
import { useSelector } from "react-redux";
import Select from "../../../../../ui/Select/Select";
import { dataStreamingRegions, measurementUnitItems } from "../../../../../helper/constants";
import Button from "../../../../../ui/Button/Button";
import { useSettings } from "src/helper/SettingsProvider";
import { useMemo } from "react";

function EditDevice({ onConfirm, onOpen }) {
  const [required] = useState(["videoStreamingRegion", "dataStreamingRegion"]);
  const [alerts, setAlerts] = useState({});
  const [data, setData] = useState({});
  const [DeviceName, setDeviceName] = useState("");
  const [oldDeviceName, setOldDeviceName] = useState("");
  const [icons, setIcons] = useState({ DeviceName: "default" });
  const DebouncedBusinessName = useDebounce(DeviceName, 1000);
  const [validate, setValidate] = useState(true);
  const device = useSelector((state) => state.device);
  const table = useSelector((state) => state.table);
  const settings = useSettings();
  const dispatch = useDispatch();

  const videoStreamingRegions = useMemo(() => {
    return settings.videoStreamRegions ? settings.videoStreamRegions.map(item => ({
      id: item.id,
      name: item.name,
      value: item.name
    })) : [];
  }, [settings]);

  useEffect(() => {
    setData({
      deviceName: table.row.deviceName,
      videoStreamingRegion: table.row.videoStreamingRegion,
      dataStreamingRegion: table.row.dataStreamingRegion,
      // unitOfMeasurement: table.row.unitOfMeasurement,
    });
    setOldDeviceName(table.row.deviceName);
    setAlerts({});
  }, [table.row]);

  useEffect(() => {
    console.log(data.deviceName, DeviceName);
    if (DebouncedBusinessName && oldDeviceName !== DeviceName) {
      dispatch(name({ status: "" }));
      dispatch(checkName(DeviceName));
    }
  }, [DebouncedBusinessName]);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAlerts({});

    if (key === "deviceName") {
      setIcons({ ...icons, DeviceName: "default" });
      setDeviceName(value);
      setValidate(false);
    }
    if (key === "videoStreamingRegion" || key === "dataStreamingRegion" || key === "unitOfMeasurement") {
      setValidate(true);
    }

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = () => {
    if (table.row !== undefined) dispatch(updateDevice(data, table.row.id));
  };

  useEffect(() => {
    if (device.name.status === "failed") {
      setAlerts({
        ...alerts,
        DeviceName: {
          alert: true,
          type: "error",
          message: device.name.message,
        },
      });
    } else if (device.name.status === "forbidden") {
      setAlerts({
        ...alerts,
        DeviceName: {
          alert: true,
          type: "error",
          message: device.name.message.error,
        },
      });
    } else if (device.name.status === "validation") {
      let validations = {};
      device.name.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
    setIcons({ ...icons, DeviceName: device.name.status });
    setValidate(
      device.name.status === "success" &&
      data?.videoStreamingRegion !== undefined &&
      data?.dataStreamingRegion !== undefined
      // data?.unitOfMeasurement !== undefined
    );
  }, [device.name]);

  useEffect(() => {
    if (device.update.status === "validation") {
      let validations = {};
      device.update.errors.forEach((item) => {
        validations[item.propertyName.replace(/\s+/g, "-").toLowerCase()] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    } else if (device.update.status === "success") {
      onConfirm();
    }
  }, [device.update]);

  useEffect(() => {
    onOpen?.();
  }, [data]);

  return (
    <div className="edit-device">
      <InputBox
        title="Device Name"
        name="deviceName"
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.DeviceName}
        value={data.deviceName}
        index={0}
        background="white"
        width="100%"
        icon={icons.DeviceName}
      />
      <Select
        isRequired={required.includes("videoStreamingRegion")}
        title="Video Stream Region"
        name="videoStreamingRegion"
        items={videoStreamingRegions}
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.videoStreamingRegion}
        value={data.videoStreamingRegion}
        current={data.videoStreamingRegion}
        index={1}
        background="white"
        width="100%"
      />
      <Select
        isRequired={required.includes("dataStreamingRegion")}
        title="Data Stream Region"
        name="dataStreamingRegion"
        items={dataStreamingRegions}
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.dataStreamingRegion}
        value={data.dataStreamingRegion}
        current={data.dataStreamingRegion}
        index={1}
        background="white"
        width="100%"
      />
      {/* <Select
        isRequired={required.includes("unitOfMeasurement")}
        title="Measurement Unit"
        name="unitOfMeasurement"
        items={measurementUnitItems}
        onChange={changeHandler}
        onFocus={focusHandler}
        status={alerts.unitOfMeasurement}
        value={data.unitOfMeasurement}
        current={data.unitOfMeasurement}
        index={1}
        background="white"
        width="100%"
      /> */}
      <Button
        value="Update"
        color="#2699fb"
        width="30%"
        disabled={!validate}
        onClick={submitHandler}
      />
    </div>
  );
}

export default EditDevice;
