import LogoMini from "../../ui/LogoMini/LogoMini";
import ProfileMenu from "../../ui/ProfileMenu/ProfileMenu";
import Search from "../../ui/Search/Search";
import "./AppBar.sass";
import TabletSize from "../../ui/TabletSize/TabletSize";
import DesktopSize from "../../ui/DesktopSize/DesktopSize";
import { Link } from "react-router-dom";
import logo from "src/assets/img/logo-mini.svg";
import NotificationMenu from "src/components/Notification/NotificationMenu/NotificationMenu";
import NotificationProvider from "src/components/Notification/NotificationProvider/NotificationProvider";

function AppBar({ background, onToggleProfileMenu }) {
  return (
    <div className="app-bar">
      <div className="app-bar-container" style={{ background: background }}>
        <TabletSize>
          <LogoMini />
        </TabletSize>
        <DesktopSize>
          <div className="app-sidebar-logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        </DesktopSize>

        {/* <Search /> */}
        <NotificationMenu />
        <div className="separator"></div>
        <ProfileMenu toggleProfileMenu={onToggleProfileMenu} />
        <NotificationProvider />
      </div>
    </div>
  );
}

export default AppBar;
