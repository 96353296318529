import { useRef, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { dockConnectionManager, onboardConnectionManager } from "src/helper/HubConnectionManager";
import { deviceTypes, sdkTypes } from "src/helper/constants";
import { removeMissionPendingRequest, setMissionInfo, setMissionPendingRequest } from "../MissionServiceSlice";
import { createWaypointTemplate, createWaypointWaylines } from "src/helper/waypointMarkupUtils";
import JSZip from "jszip";
import { getDeviceModelInfo } from "src/services/device/common/deviceUtils";
import { MissionPendingRequestStatus, MissionPendingRequestType, onboardWaypointAction } from "./missionConstants";
import { getMissionInfo } from "./missionUtils";
import { generateId } from "src/helper/utils";
import useCurrentUserId from "src/helper/useCurrentUserId";

const DJI_CLOUD_FLIGHT_TASK_ISSUE = 'flighttask_prepare';
const DJI_CLOUD_FLIGHT_TASK_EXECUTE = 'flighttask_execute';

export const useIssueMission = () => {
  const store = useStore();
  const currUserId = useCurrentUserId();
  const dispatch = useDispatch();
  const issueHandlerIds = useRef([]);
  const executeHandlerIds = useRef([]);
  const [issueResult, setIssueResult] = useState(null);
  const [executeResult, setExecuteResult] = useState(null);
  const pendingUploadRequest = useRef(null);

  let device, sdk, connection, connectionManager, deviceId;

  const sendExecuteMission = (flightId, missionData) => {
    console.log('🚀 [sendExecuteMission]', flightId);

    if(sdk === sdkTypes.DJI_PSDK) {
      console.log('🚀 [sendExecuteMission] PSDK', missionData);

      onboardConnectionManager?.sendToGroup(device.id, connection?.group?.send?.commandChannel, {
        waypoint: {
          actionId: onboardWaypointAction.START,
        }
      });
    }
    else if(sdk === sdkTypes.DJI_CLOUD) {
      executeHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
        {
          identity: deviceId,
          name: [connection?.group?.join?.services_reply],
          handler: (message) => {
            if(message?.method === DJI_CLOUD_FLIGHT_TASK_EXECUTE){
              connectionManager.unsubscribeGroupMessages(executeHandlerIds.current);
              console.log('📦 Dock [executeMission]', message);
              setExecuteResult(message);
  
              if(message?.data?.result === 0)
                console.log('✈️ Mission executed successfully');
            }
          },
        },
      ], 'mission-execute');

      console.log({deviceId, group: connection?.group?.send?.services,})
  
      dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
        method: DJI_CLOUD_FLIGHT_TASK_EXECUTE,
        data: {
          flight_id: flightId
        },
      });
    }

    return;

    executeHandlerIds.current = connectionManager.subscribeGroupMessages([
      {
        identity: deviceId,
        name: [connection?.group?.join?.services_reply],
        handler: (data) => {
          console.log('dockMessage', data);
          connectionManager.unsubscribeGroupMessages(issueHandlerIds.current);
        },
      },
    ], 'mission-tracking');

    
  }

  const sendIssueMission = (targetDeviceId, missionData) => {
    deviceId = targetDeviceId;
    device = store.getState().deviceService.devices?.find(item => item.id === deviceId);
    const flightId = missionData?.flightId;

    console.log('🪄 send-issue-mission-device', device)

    if (!device) return;
    else if (device.type === deviceTypes.DOCK) {
      sdk = sdkTypes.DJI_CLOUD;
      connection = store.getState().deviceService.dockConnections?.find(item => item.deviceId === deviceId);
    }
    else if (device.type === deviceTypes.DRONE) {
      sdk = sdkTypes.DJI_PSDK;
      connection = store.getState().deviceService.onboardConnections?.find(item => item.deviceId === deviceId);
    }
    else throw new Error('Invalid device type');

    if (!connection)
      throw new Error('Connection not found');

    if (sdk === sdkTypes.DJI_CLOUD) {
      const connectionManager =  dockConnectionManager;

      issueHandlerIds.current = connectionManager.subscribeGroupMessages([
        {
          identity: deviceId,
          name: [connection?.group?.join?.services_reply],
          handler: (message) => {
            if(message?.method === DJI_CLOUD_FLIGHT_TASK_ISSUE){
              connectionManager.unsubscribeGroupMessages(issueHandlerIds.current);
              console.log('📦 Dock [issueMission]', message);
              setIssueResult(message);

              if(message?.data?.result === 0) {
                console.log('✨ Mission issued successfully');
                sendExecuteMission(flightId);
              }
            }
          },
        },
      ], 'mission-issue');

      const taskData = {
        "exit_wayline_when_rc_lost": 1,
        "file": {
          "fingerprint": missionData?.flightDetails?.fingerprint,
          "url": missionData?.flightDetails?.url
        },
        "flight_id": flightId,
        "out_of_control_action": 0,
        "simulate_mission": {
          "is_enable": 1,
          "latitude": missionData?.waypoints?.[0]?.latitude,
          "longitude": missionData?.waypoints?.[0]?.longitude,
        },
        "task_type": 0,
        "rth_altitude": 30,
        "execute_time": Date.now()
      };

      console.log({taskData});
  
      dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
        method: DJI_CLOUD_FLIGHT_TASK_ISSUE,
        data: taskData,
      });
    }
    else if(sdk === sdkTypes.DJI_PSDK) {
      const connectionManager = onboardConnectionManager;

      connectionManager.unsubscribeGroupMessages(issueHandlerIds.current);
      issueHandlerIds.current = connectionManager.subscribeGroupMessages([
        {
          identity: deviceId,
          name: [connection?.group?.join?.onDemandChannel],
          handler: (data) => {
            if(
              data?.action === 'WaypointResult' &&
              data.actionId === onboardWaypointAction.UPLOAD &&
              pendingUploadRequest.current
            ) {
              if(data.status) {
                console.log('✨ Mission issued successfully', data);
                sendExecuteMission(missionData?.flightId, missionData);
              }
              else {
                console.log('❌ Mission issue failed', data);
              }

              pendingUploadRequest.current = null;

              dispatch(removeMissionPendingRequest({
                missionId: missionData?.id,
                requestType: MissionPendingRequestType.ONBOARD_UPLOAD,
              }));
            }
          },
        },
      ], 'mission-issue');

      const taskData = {
        actionId: onboardWaypointAction.UPLOAD,
        numberOfPoints: missionData?.waypoints?.length,
        points: missionData?.waypoints.map(item => ({
          lat: item.latitude,
          long: item.longitude,
          altitude: item.altitude,
        })),
        speed: missionData?.speed,
        duration: 80,
        distance: 33,
        actions: {},
        id: generateId(),
        timestamp: Date.now(),
        userId: currUserId,
        kmzFile: missionData?.flightDetails?.kmz
      };

      pendingUploadRequest.current = taskData;

      setTimeout(() => {
        dispatch(setMissionPendingRequest({
          missionId: missionData?.id,
          requestType: MissionPendingRequestType.ONBOARD_UPLOAD,
          requestStatus: MissionPendingRequestStatus.PENDING
        }));

        connectionManager?.sendToGroup(deviceId, connection?.group?.send?.commandChannel, {
          waypoint: taskData,
        });
      }, 1000);
    }
  }

  return {
    sendIssueMission,
    issueResult,
    executeResult,
  }
}