import "./Shortcut.sass";
import React from 'react';
import Card from "src/ui/Card/Card";
import { Link } from "react-router-dom";

function Shortcut({ shortcut }) {
  return (
    <div className="shortcut-cart">
      <Card>
        <Link to={shortcut.link}>
          <div className="content">
            <div className="image">
              {React.cloneElement(shortcut.icon, {
                className: 'icon',
                size: 20,
                color: 'white',
              })}
            </div>
            <div className="main">
              <div className="info">
                <div className="name">{shortcut.title}</div>
                {shortcut.description && (
                  <div className="description">
                    <div className="value">{shortcut.description}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Link>
      </Card>
    </div>
  )
}

export default Shortcut;
