import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const RadarDegrees = ({ className, size, time }) => {
  const compass = useSelector((state) => state.flight.compass);
  const [animate, setAnimate] = useState(true);
  const [heading, setHeading] = useState(0);
  useEffect(() => {
    setHeading(curr => {
      setAnimate(Math.abs(compass.heading - curr) < 270);
      return compass.heading;
    });
  }, [compass]);

  return (
    <svg 
      className={className}
      style={{
        transform: `rotate(${-heading}deg)`,
        transition: animate ? `transform ${time}s ease` : 'none',
        transformOrigin: "center",
      }}
      width={`${size}em`}
      height={`${size}em`}
      xmlns="http://www.w3.org/2000/svg" 
      xmlnsXlink="http://www.w3.org/1999/xlink" 
      viewBox="0 0 230 230"
    >
      <defs>
        <radialGradient
          id="HvsW8j3p"
          cx={-839.8}
          cy={631.51}
          r={0.5}
          gradientTransform="matrix(228 0 0 -228 191589 144099.56)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#242120" stopOpacity={0} />
          <stop offset={0.73} stopColor="#2c2928" stopOpacity={0} />
          <stop offset={0.74} stopColor="#0a0909" stopOpacity={0.78} />
          <stop offset={1} stopOpacity={0.5} />
        </radialGradient>
        <style>
          {
            ".cls-3,.cls-4{isolation:isolate}.cls-4{font-size:14px;fill:#fff;font-family:Roboto-Medium,Roboto;font-weight:500}.cls-5{fill:#b8b8b8}.cls-6,.cls-7{fill:none;stroke:#858585}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M115 229a114.62 114.62 0 0 1-23-2.32A112.75 112.75 0 0 1 70.63 220 114.28 114.28 0 0 1 10 159.37 112.75 112.75 0 0 1 3.32 138a115 115 0 0 1 0-46A112.75 112.75 0 0 1 10 70.63 114.28 114.28 0 0 1 70.63 10 112.75 112.75 0 0 1 92 3.32a115 115 0 0 1 46 0A112.75 112.75 0 0 1 159.37 10 114.28 114.28 0 0 1 220 70.63 112.75 112.75 0 0 1 226.68 92a115 115 0 0 1 0 46 112.75 112.75 0 0 1-6.68 21.37A114.28 114.28 0 0 1 159.37 220a112.75 112.75 0 0 1-21.37 6.68 114.48 114.48 0 0 1-23 2.32Z"
            style={{
              fill: "url(#HvsW8j3p)",
            }}
          />
          <path
            d="M115 2a113.44 113.44 0 0 0-22.78 2.3 112.38 112.38 0 0 0-40.4 17A113.31 113.31 0 0 0 10.88 71 112.2 112.2 0 0 0 4.3 92.22a114 114 0 0 0 0 45.56 112.38 112.38 0 0 0 17 40.4A113.31 113.31 0 0 0 71 219.12a112.2 112.2 0 0 0 21.2 6.58 114 114 0 0 0 45.56 0 112.38 112.38 0 0 0 40.4-17 113.31 113.31 0 0 0 40.96-49.7 112.2 112.2 0 0 0 6.58-21.2 114 114 0 0 0 0-45.56 112.38 112.38 0 0 0-17-40.4A113.31 113.31 0 0 0 159 10.88a112.2 112.2 0 0 0-21.2-6.58A114 114 0 0 0 115 2m0-2A115 115 0 1 1 0 115 115 115 0 0 1 115 0Z"
            style={{
              fill: "#258467",
            }}
          />
          <g className="cls-3">
            <text className="cls-4" transform="translate(109 17.33)">
              {"N"}
            </text>
          </g>
          <g className="cls-3">
            <text className="cls-4" transform="translate(110 218.41)">
              {"S"}
            </text>
          </g>
          <g className="cls-3">
            <text className="cls-4" transform="rotate(-90 70.6 51.81)">
              {"W"}
            </text>
          </g>
          <g className="cls-3">
            <text className="cls-4" transform="rotate(90 50.575 162.985)">
              {"E"}
            </text>
          </g>
          <g className="cls-3">
            <g className="cls-3">
              <path
                className="cls-5"
                d="m169.35 201.57-4.29-7.43.94-.54 3.61 6.26 1.49-1.79.49.85-2.09 2.57ZM163.43 199.77l.86-.24 1.75 3.9-3.79 2.18-.5-.86 3-1.73-.94-2.14a3 3 0 0 1-.39.52 2.74 2.74 0 0 1-.66.51 2.61 2.61 0 0 1-1 .36 2.32 2.32 0 0 1-1-.1 2.57 2.57 0 0 1-.89-.53 3.66 3.66 0 0 1-.74-.93 3.4 3.4 0 0 1-.42-1.07 2.56 2.56 0 0 1 0-1 2.32 2.32 0 0 1 .43-.93 3.06 3.06 0 0 1 .93-.78 3.26 3.26 0 0 1 .92-.36 2.43 2.43 0 0 1 .93 0 2.23 2.23 0 0 1 .87.34 2.92 2.92 0 0 1 .77.76l-.89.52a2 2 0 0 0-.65-.56 1.34 1.34 0 0 0-.73-.15 1.78 1.78 0 0 0-.78.25 1.67 1.67 0 0 0-.52.46 1.41 1.41 0 0 0-.25.59 1.77 1.77 0 0 0 0 .68 2.39 2.39 0 0 0 .31.75 2.36 2.36 0 0 0 .47.6 1.62 1.62 0 0 0 .59.35 1.47 1.47 0 0 0 .66.08 1.67 1.67 0 0 0 .7-.24 1.72 1.72 0 0 0 .64-.54 3.09 3.09 0 0 0 .32-.69Z"
              />
            </g>
          </g>
          <g className="cls-3">
            <g className="cls-3">
              <path
                className="cls-5"
                d="m204.36 164.9-7.43-4.29.54-.94 6.25 3.61.41-2.29.84.49-.53 3.27ZM193.47 168.37l-.77-.45 2.79-4.83.68.39 1.3 4a11.26 11.26 0 0 0 .44 1.19 3.14 3.14 0 0 0 .43.7 2.07 2.07 0 0 0 .5.43 1.81 1.81 0 0 0 .75.23 1.33 1.33 0 0 0 .73-.17 1.43 1.43 0 0 0 .58-.59 1.89 1.89 0 0 0 .29-.93 1.43 1.43 0 0 0-.25-.8 2.17 2.17 0 0 0-.69-.61l.54-.94a2.77 2.77 0 0 1 1 .93 2.36 2.36 0 0 1 .35 1.29 2.93 2.93 0 0 1-.46 1.5 3.16 3.16 0 0 1-.93 1.06 2.1 2.1 0 0 1-1.13.36 2.27 2.27 0 0 1-1.2-.33 2.44 2.44 0 0 1-.59-.49 4.38 4.38 0 0 1-.49-.68 5.16 5.16 0 0 1-.39-.82 8.7 8.7 0 0 1-.32-.9l-1-3.21Z"
              />
            </g>
          </g>
          <g className="cls-3">
            <g className="cls-3">
              <path
                className="cls-5"
                d="M203.52 65.85v.09l-.8.46v-.09a3.32 3.32 0 0 0-1-1.1 2.29 2.29 0 0 0-1.1-.43 3 3 0 0 0-1.15.1 4.83 4.83 0 0 0-1.1.46l-1.08.63a3.08 3.08 0 0 0-.8.62 2.34 2.34 0 0 0-.46.68 1.73 1.73 0 0 0-.03.73 1.43 1.43 0 0 0 .17.61 1.63 1.63 0 0 0 .46.51 1.4 1.4 0 0 0 .6.22 1.81 1.81 0 0 0 .69 0 2.82 2.82 0 0 0 .73-.3 3 3 0 0 0 .59-.45 1.88 1.88 0 0 0 .41-.57 1.32 1.32 0 0 0-.07-1.31 1.65 1.65 0 0 0-.61-.62 1.88 1.88 0 0 0-.82-.26 1.61 1.61 0 0 0-.81.13l-.28-.5a3.49 3.49 0 0 1 1.13-.29 2.22 2.22 0 0 1 .94.11 2.16 2.16 0 0 1 .75.43 3 3 0 0 1 .57.7 2.47 2.47 0 0 1 .34 1.08 2.17 2.17 0 0 1-.18 1 2.65 2.65 0 0 1-.56.83 3.57 3.57 0 0 1-.81.64 3.84 3.84 0 0 1-1.07.42 2.66 2.66 0 0 1-1.05 0 2.32 2.32 0 0 1-1-.4 2.88 2.88 0 0 1-.76-.88 2.43 2.43 0 0 1-.13-2.31 3.48 3.48 0 0 1 .66-1 4 4 0 0 1 .93-.73l.45-.26a9 9 0 0 1 1.62-.73 4.86 4.86 0 0 1 1.67-.24 3.29 3.29 0 0 1 1.6.52 4.41 4.41 0 0 1 1.36 1.5Z"
              />
            </g>
          </g>
          <g className="cls-3">
            <g className="cls-3">
              <path
                className="cls-5"
                d="m163.44 30 .32-.56.81.46a4.07 4.07 0 0 1 1.07.84 2.2 2.2 0 0 1 .47.87 1.94 1.94 0 0 1 0 .83 2.82 2.82 0 0 1-.27.72 2.62 2.62 0 0 1-.71.82 2.33 2.33 0 0 1-.88.4 2.54 2.54 0 0 1-1 0 3.66 3.66 0 0 1-1.84-1.06 2.69 2.69 0 0 1-.52-.83 2.1 2.1 0 0 1-.13-.93 2.18 2.18 0 0 1 .32-1l.94.54a1.48 1.48 0 0 0-.22.77 1.31 1.31 0 0 0 .23.73 1.87 1.87 0 0 0 .65.59 2 2 0 0 0 .83.28 1.3 1.3 0 0 0 .75-.17 1.83 1.83 0 0 0 .61-.67 1.52 1.52 0 0 0 .24-.87 1.31 1.31 0 0 0-.29-.76 2.32 2.32 0 0 0-.74-.62Zm.44-.77.67.39a2 2 0 0 0 .91.3 1.26 1.26 0 0 0 .75-.17 1.56 1.56 0 0 0 .53-.55 2 2 0 0 0 .27-.8 1.11 1.11 0 0 0-.17-.69 2 2 0 0 0-.64-.56 1.87 1.87 0 0 0-.79-.25 1.37 1.37 0 0 0-.72.17 1.4 1.4 0 0 0-.56.57l-.94-.54a2.2 2.2 0 0 1 .88-.86 2.39 2.39 0 0 1 1.22-.28 3 3 0 0 1 1.36.42 3.18 3.18 0 0 1 1 .91 2 2 0 0 1 .38 1.15 2.49 2.49 0 0 1-.39 1.32 1.75 1.75 0 0 1-.47.52 2.22 2.22 0 0 1-.74.36 2.39 2.39 0 0 1-1 0 3.22 3.22 0 0 1-1.13-.42l-.8-.46Z"
              />
            </g>
          </g>
          <g className="cls-3">
            <g className="cls-3">
              <path
                className="cls-5"
                d="m61.08 31.72.67-.39a2.07 2.07 0 0 0 .72-.63 1.36 1.36 0 0 0 .23-.7 1.55 1.55 0 0 0-.22-.74 1.87 1.87 0 0 0-.55-.64 1.19 1.19 0 0 0-.68-.19 1.76 1.76 0 0 0-.81.27 1.84 1.84 0 0 0-.61.56 1.31 1.31 0 0 0-.21.71 1.4 1.4 0 0 0 .21.77l-.94.54a2.2 2.2 0 0 1-.3-1.19 2.34 2.34 0 0 1 .37-1.2 2.85 2.85 0 0 1 1-1 3.13 3.13 0 0 1 1.31-.44 2 2 0 0 1 1.18.25 2.53 2.53 0 0 1 .95 1 1.89 1.89 0 0 1 .21.67 2 2 0 0 1-.06.83 2.31 2.31 0 0 1-.44.85 3.11 3.11 0 0 1-.94.77l-.8.47Zm.45.77-.32-.56.8-.47a3.87 3.87 0 0 1 1.26-.5 2.18 2.18 0 0 1 1 0 2 2 0 0 1 .72.4 2.35 2.35 0 0 1 .5.6 2.59 2.59 0 0 1 .35 1 2.08 2.08 0 0 1-.1 1 2.38 2.38 0 0 1-.5.85 3.22 3.22 0 0 1-.85.68 3.37 3.37 0 0 1-1 .38 2.51 2.51 0 0 1-1 0 2.17 2.17 0 0 1-.88-.35 2.35 2.35 0 0 1-.69-.77l.94-.54a1.61 1.61 0 0 0 .56.57 1.38 1.38 0 0 0 .75.17 2.06 2.06 0 0 0 .84-.27 2 2 0 0 0 .66-.58 1.28 1.28 0 0 0 .22-.73 1.88 1.88 0 0 0-.28-.87 1.52 1.52 0 0 0-.63-.65 1.35 1.35 0 0 0-.8-.12 2.59 2.59 0 0 0-.91.33ZM66.64 28.51l.67-.39a2.07 2.07 0 0 0 .72-.63 1.27 1.27 0 0 0 .22-.73A1.43 1.43 0 0 0 68 26a1.87 1.87 0 0 0-.55-.64 1.19 1.19 0 0 0-.68-.19 1.76 1.76 0 0 0-.81.27 1.84 1.84 0 0 0-.61.56 1.31 1.31 0 0 0-.21.71 1.4 1.4 0 0 0 .21.77l-.94.54a2.18 2.18 0 0 1-.3-1.19 2.34 2.34 0 0 1 .37-1.2 2.85 2.85 0 0 1 1-1 3.13 3.13 0 0 1 1.31-.44 2 2 0 0 1 1.18.25 2.53 2.53 0 0 1 1 1 1.89 1.89 0 0 1 .21.67 2 2 0 0 1-.06.83 2.31 2.31 0 0 1-.44.85 3.11 3.11 0 0 1-.94.77l-.8.47Zm.45.77-.33-.56.81-.47a3.87 3.87 0 0 1 1.26-.5 2.18 2.18 0 0 1 1 0 2 2 0 0 1 .72.4 2.35 2.35 0 0 1 .5.6 2.59 2.59 0 0 1 .35 1 2.08 2.08 0 0 1-.1 1 2.38 2.38 0 0 1-.5.85 3.34 3.34 0 0 1-.84.68 3.67 3.67 0 0 1-1 .38 2.51 2.51 0 0 1-1 0 2.17 2.17 0 0 1-.88-.35 2.35 2.35 0 0 1-.69-.77l.94-.54a1.61 1.61 0 0 0 .56.57 1.38 1.38 0 0 0 .75.17 2.06 2.06 0 0 0 .84-.27 2 2 0 0 0 .66-.58 1.28 1.28 0 0 0 .22-.73 1.88 1.88 0 0 0-.28-.87 1.52 1.52 0 0 0-.63-.65 1.35 1.35 0 0 0-.8-.12 2.59 2.59 0 0 0-.91.33Z"
              />
            </g>
          </g>
          <g className="cls-3">
            <g className="cls-3">
              <path
                className="cls-5"
                d="m26.5 69.78.38-.67a2.1 2.1 0 0 0 .31-.91 1.42 1.42 0 0 0-.17-.74 1.88 1.88 0 0 0-1.36-.8 1.1 1.1 0 0 0-.68.17 1.7 1.7 0 0 0-.56.64 1.59 1.59 0 0 0-.25.78 1.3 1.3 0 0 0 .17.73 1.46 1.46 0 0 0 .57.56l-.54.94a2.23 2.23 0 0 1-.87-.88 2.42 2.42 0 0 1-.27-1.23 2.9 2.9 0 0 1 .42-1.37 3.07 3.07 0 0 1 .91-1 2 2 0 0 1 1.15-.38A2.49 2.49 0 0 1 27 66a1.86 1.86 0 0 1 .52.47 2.22 2.22 0 0 1 .36.74 2.53 2.53 0 0 1 0 1 3.19 3.19 0 0 1-.42 1.14l-.47.8Zm.77.45-.56-.33.46-.8A4.07 4.07 0 0 1 28 68a2.2 2.2 0 0 1 .87-.47 1.76 1.76 0 0 1 .82 0 2.45 2.45 0 0 1 .73.27 2.74 2.74 0 0 1 .82.71 2.11 2.11 0 0 1 .39.88 2.36 2.36 0 0 1 0 1 3.51 3.51 0 0 1-.39 1 3.66 3.66 0 0 1-.66.83 2.69 2.69 0 0 1-.83.52 2.13 2.13 0 0 1-.94.13 2.4 2.4 0 0 1-1-.32l.54-.94a1.54 1.54 0 0 0 .78.22 1.34 1.34 0 0 0 .73-.23 1.91 1.91 0 0 0 .59-.66 2 2 0 0 0 .28-.83 1.22 1.22 0 0 0-.18-.74 1.75 1.75 0 0 0-.67-.61 1.57 1.57 0 0 0-.87-.25 1.35 1.35 0 0 0-.75.29 2.66 2.66 0 0 0-.63.75ZM31.47 60.88l1.12.65a6.89 6.89 0 0 1 1.44 1 3.22 3.22 0 0 1 .74 1.05 2 2 0 0 1 .12 1.05 3.09 3.09 0 0 1-.38 1.06 3.6 3.6 0 0 1-.56.73 2.08 2.08 0 0 1-.72.45 2.37 2.37 0 0 1-.9.13 3.69 3.69 0 0 1-1.1-.22 7.47 7.47 0 0 1-1.33-.63l-1.13-.65a7.47 7.47 0 0 1-1.43-1 3.16 3.16 0 0 1-.71-1 2 2 0 0 1-.11-1 2.92 2.92 0 0 1 .39-1.06 3 3 0 0 1 .56-.73 2.13 2.13 0 0 1 .7-.46 2.26 2.26 0 0 1 .88-.14 3.64 3.64 0 0 1 1.09.22 8.05 8.05 0 0 1 1.33.55Zm.73 1.69-1.44-.83a6.27 6.27 0 0 0-.9-.45 2.81 2.81 0 0 0-.75-.2 1.48 1.48 0 0 0-.6 0 1.17 1.17 0 0 0-.47.24 1.86 1.86 0 0 0-.37.45 1.63 1.63 0 0 0-.21.67 1.22 1.22 0 0 0 .14.66 2.29 2.29 0 0 0 .55.67 6 6 0 0 0 1 .71l1.43.83a6.25 6.25 0 0 0 .92.44 3.18 3.18 0 0 0 .75.21 1.8 1.8 0 0 0 .62 0 1.32 1.32 0 0 0 .49-.24 1.57 1.57 0 0 0 .36-.44 1.43 1.43 0 0 0 .22-.68 1.27 1.27 0 0 0-.16-.67 2.22 2.22 0 0 0-.58-.68 5.87 5.87 0 0 0-1-.69Z"
              />
            </g>
          </g>
          <g className="cls-3">
            <g className="cls-3">
              <path
                className="cls-5"
                d="m33.08 163.16.77-.45 2.8 4.84-.68.39-4.09-.87c-.5-.1-.92-.17-1.24-.2a2.77 2.77 0 0 0-.83 0 1.91 1.91 0 0 0-.62.22 1.68 1.68 0 0 0-.58.54 1.35 1.35 0 0 0-.22.71 1.55 1.55 0 0 0 .23.8 1.83 1.83 0 0 0 .66.71 1.39 1.39 0 0 0 .82.18 2 2 0 0 0 .87-.29l.54.94a2.8 2.8 0 0 1-1.3.39 2.4 2.4 0 0 1-1.29-.34 3 3 0 0 1-1.07-1.14 3.17 3.17 0 0 1-.45-1.34 2.19 2.19 0 0 1 .25-1.17 2.31 2.31 0 0 1 .89-.86 2.07 2.07 0 0 1 .72-.27 3.71 3.71 0 0 1 .83-.08 7.19 7.19 0 0 1 .91.13c.3.05.62.1.93.17l3.29.74ZM23.76 162.3l7.39-4.3.54.94-7.38 4.27Zm4.31-3.86.77-.45 3.08 5.34-.55.32-7-.35-.44-.77h2l4.69.21Z"
              />
            </g>
          </g>
          <g className="cls-3">
            <g className="cls-3">
              <path
                className="cls-5"
                d="m66.86 196.13.44-.77 4.84 2.79-.39.67-4 1.3a11.58 11.58 0 0 0-1.18.45 3.15 3.15 0 0 0-.7.42 2.08 2.08 0 0 0-.43.51 1.6 1.6 0 0 0-.23.75 1.36 1.36 0 0 0 .17.73 1.5 1.5 0 0 0 .59.58 1.87 1.87 0 0 0 .93.28 1.35 1.35 0 0 0 .8-.25 2 2 0 0 0 .61-.68l.94.54a2.82 2.82 0 0 1-.93 1 2.36 2.36 0 0 1-1.29.36 3 3 0 0 1-1.5-.46 3.07 3.07 0 0 1-1.06-.94 2 2 0 0 1-.36-1.13 2.24 2.24 0 0 1 .33-1.19 2.27 2.27 0 0 1 .49-.59 3.81 3.81 0 0 1 .68-.49 6.24 6.24 0 0 1 .82-.4l.89-.32 3.22-1ZM59.21 200.59l4.29-7.43.94.54-3.61 6.3 2.29.4-.49.85-3.27-.53Z"
              />
            </g>
          </g>
          <path
            className="cls-6"
            d="m171.3 18-3.1 5.15M168.75 207.12l3.22 5.15M59.47 18l2.92 5.15M62.5 207.06l-3.23 5.6"
          />
          <path
            className="cls-7"
            d="M211.7 57.68 206.09 61M206.91 167.32l5.74 3.36M17.71 59l5.76 3.22M23.24 166.63l-6 3.12"
          />
        </g>
      </g>
    </svg>
  );
};

export default RadarDegrees;
