import "./ApplicationContainer.sass";
import BreadCrumb from "../../../../ui/BreadCrumb/BreadCrumb";

function ApplicationContainer({ histories, children }) {
  return (
    <div className="application-container">
      <BreadCrumb histories={histories} />
      {children}
    </div>
  );
}

export default ApplicationContainer;
