import { VscChevronRight } from "react-icons/vsc";
import "./Breadcrumb.sass";
import classNames from "classnames";
import { Link } from "react-router-dom";

function Breadcrumb({ items, className }) {
  return (
    <div className={classNames("breadcrumb", className)}>
      {items.map((item, index) => (
        <div className="item" key={item.title}>
          <Link className="item-link" to={item.link}>
            {item.title}
          </Link>
          {index < items.length - 1 && (
            <VscChevronRight className="item-separator" />
          )}
        </div>
      ))}
    </div>
  );
}

export default Breadcrumb;