import "./AdminShortcuts.sass";
import { useState } from "react";
import { useEffect } from "react";
import { usePrivileges } from "src/helper/AccountStateProvider";
import { adminItems } from "src/helper/constants";
import Shortcut from "src/ui/Carts/Shortcut/Shortcut";

function AdminShortcuts() {
  const { privileges } = usePrivileges();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined)
      setMenuItems(
        adminItems.filter((item) =>
          privileges.admins.map((a) => a.id).includes(item.name)
        ).map(item => ({
          ...item,
          title: item.title + ' Management',
        }))
      );
  }, [privileges.admins]);

  return (
    <div className="admin-shortcuts">
      {menuItems.map((item, index) => (
        <Shortcut key={index} shortcut={item} />
      ))}
    </div>
  );
}

export default AdminShortcuts;