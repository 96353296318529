import { useTheme } from "../../../helper/ThemeProvider";
import "./AppMenu.sass";
function AppMenu({ color, onClick }) {
  const {  currentContext  } = useTheme();
  return (
    <div className="app-menu" style={{ color: color }} onClick={onClick}>
      <div className="app-menu-container">
        <svg
          className="menu"
          xmlns="http://www.w3.org/2000/svg"
          id="Group_16819"
          width="16"
          height="10.667"
          viewBox="0 0 16 10.667"
        >
          <defs></defs>
          <path id="Rectangle_5" d="M0 0H16V2H0z" fill="#fff" />
          <path
            id="Rectangle_5-2"
            d="M0 0H16V2H0z"
            fill="#fff"
            transform="translate(0 4.333)"
          />
          <path
            id="Rectangle_5-3"
            d="M0 0H16V2H0z"
            fill="#fff"
            transform="translate(0 8.667)"
          />
        </svg>
        {/* <img className="container" src={container} alt="IRobotics Menu" /> */}
        <svg
          className="container"
          xmlns="http://www.w3.org/2000/svg"
          width="43.493"
          height="51.841"
          viewBox="0 0 43.493 51.841"
        >
          <path
            fill={currentContext.theme.color}
            d="M9.882 11.624h21.969s4.629-.112 7.539-3.018S43.493 0 43.493 0v51.841a35.916 35.916 0 0 0-4.1-8.47c-2.594-3.7-7.539-3.577-7.539-3.577H9.882C4.408 39.794 0 33.058 0 26.015s4.408-14.391 9.882-14.391z"
            transform="rotate(180 21.747 25.92)"
          />
        </svg>
      </div>
    </div>
  );
}

export default AppMenu;
