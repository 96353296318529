import './EditDockOptions.sass'

import React, { useEffect, useMemo } from 'react'
import { usePrivileges } from 'src/helper/AccountStateProvider';
import Tabs from 'src/ui/Tabs/Tabs';
import EditDock from '../EditDock/EditDock';
import GroupAssignment from '../GroupAssignment/GroupAssignment';
import EditDockDfrOptions from '../EditDockDfrOptions/EditDockDfrOptions';

function EditDockOptions({ onOpen, onConfirm, defaultIndex }) {
  const { privileges } = usePrivileges();
  const permissions = useMemo(() => privileges?.admins.find(item => item.id === "docks")?.privileges, [privileges]);
  const hasPermission = (id) => permissions?.includes(id);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className="edit-dock-options">
      <Tabs
        defaultIndex={defaultIndex}
        items={[
          {
            title: "Edit Dock",
            content: <EditDock onConfirm={onConfirm} />,
            hidden: !hasPermission('edit_dock')
          },
          {
            title: "Group Assignment",
            content: <GroupAssignment onConfirm={onConfirm} setAsActive={false} />,
            hidden: !hasPermission('dock_groups_assignment')
          },
          // {
          //   title: "DFR Options",
          //   content: <EditDockDfrOptions onConfirm={onConfirm} setAsActive={false} />,
          //   hidden: !hasPermission('edit_dock')
          // },
        ]}
      />
    </div>
  )
}

export default EditDockOptions