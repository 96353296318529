import { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdRestore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { usePrivileges } from "../../../../../helper/AccountStateProvider";
import Dialog from "../../../../../ui/Dialog/Dialog";
import LabeledButton from "../../../../../ui/LabeledButton/LabeledButton";
import ApplicationComponentContent from "../../../../Dashboard/Components/ApplicationComponentContent/ApplicationComponentContent";
import LabeledInformationContainer from "../../../../Dashboard/Components/LabeledInformationContainer/LabeledInformationContainer";
import AdminOperationManager from "../../../AdminOperationManager/AdminOperationManager";
import EditStorage from "../EditStorage/EditStorage";
import {
  deleteStorage,
  deleteStoragePermanently,
  restoreStorage,
} from "../StorageManagementSlice";
import "./StorageInformation.sass";

function StorageInformation({ onOpen, onConfirm }) {
  const [information, setInformation] = useState({});
  const [data, setData] = useState({});
  const storage = useSelector((state) => state.storage);
  const [showDialog, setShowDialog] = useState(false);
  const [dialog, setDialog] = useState({});
  const dispatch = useDispatch();
  const { privileges } = usePrivileges();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined) {
      if (
        privileges.admins.filter((item) => item.id === "storages")[0] !==
        undefined
      )
        setPermissions([
          ...privileges.admins.filter((item) => item.id === "storages")[0]
            ?.privileges,
        ]);
    }
  }, [privileges.admins]);

  useEffect(() => {
    setData({ ...storage?.model });
  }, [storage.model]);

  const [featureManager, setFeatureManager] = useState(false);
  const [isFeatureManagerOpen, setIsFeatureManagerOpen] = useState(false);
  const [currentFeature, setCurrentFeature] = useState();

  const toggleFeatureManagerOn = () => {
    setFeatureManager(true);
    setIsFeatureManagerOpen(true);
  };

  const triggerCurrentOption = (name) => {
    const feature = userFeatures.find((u) => u.id === name);
    if (feature !== undefined) {
      setCurrentFeature({ ...feature });
      feature.actionGenerator();
    }
  };

  const userFeatures = [
    {
      id: "edit_storage",
      title: "Edit Storage",
      description: "You can edit your storage information",
      icon: <AiOutlineEdit />,
      color: "#2699fb",
      type: "form",
      actionGenerator: () => {
        toggleFeatureManagerOn();
      },
      steps: [
        {
          name: "edit_storage",
          title: "Edit",
          active: true,
          visible: true,
          locked: false,
          closable: false,
          tags: ["storage/update"],
          activity: <EditStorage />,
        },
      ],
    },
  ];

  const [controlsData, setControlsData] = useState([]);
  const controls = [
    {
      name: "delete_storage",
      dependent: !data.isDeleted,
      component: (
        <LabeledButton
          title="Delete Storage"
          icon={<BsTrash size="10" />}
          onClick={() => {
            setDialog({
              forceConfirmText: data.name,
              message: `are you sure for deleting ${data?.name}?`,
              CloseHandler: CloseDialogHandler,
              ConfirmHandler: () => {
                dispatch(deleteStorage(data.id));
                setShowDialog(false);
                onConfirm();
              },
            });
            setShowDialog(true);
          }}
        />
      ),
    },
    {
      name: "delete_storage_permanently",
      dependent: data.isDeleted,
      component: (
        <LabeledButton
          title="Delete Storage Permanently"
          icon={<BsTrash size="10" />}
          onClick={() => {
            setDialog({
              forceConfirm: true,
              forceConfirmText: data.name,
              message: `All containers,files and folders in ${data?.name} will be deleted permanently.`,
              CloseHandler: CloseDialogHandler,
              ConfirmHandler: () => {
                dispatch(deleteStoragePermanently(data.id));
                setShowDialog(false);
                onConfirm();
              },
            });
            setShowDialog(true);
          }}
        />
      ),
    },
    {
      name: "restore_storage",
      dependent: data.isDeleted,
      component: (
        <LabeledButton
          title={"Restore"}
          icon={<MdRestore size="12" />}
          onClick={() => {
            setDialog({
              message: "Restore selected item ?",
              CloseHandler: CloseDialogHandler,
              ConfirmHandler: () => {
                dispatch(restoreStorage(data.id));
                setShowDialog(false);
                onConfirm();
              },
            });
            setShowDialog(true);
          }}
        />
      ),
    },
    {
      name: "edit_storage",
      dependent: true,
      component: (
        <LabeledButton
          title={"Edit Storage"}
          icon={<FiEdit size="10" />}
          onClick={() => {
            triggerCurrentOption("edit_storage");
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    setControlsData([
      ...controls.map((control) => ({
        ...control,
        active: permissions.includes(control.name) && control.dependent,
      })),
    ]);
  }, [permissions, data]);

  const CloseDialogHandler = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    setInformation({
      "Storage Name": data.name,
      "Storage Location": data.storageRegion,
      Description: data.description,
      "Created By": data.createdBy,
    });

    onOpen();
  }, [data]);

  const toggleFeatureManagerOff = () => {
    setFeatureManager(false);
    setIsFeatureManagerOpen(false);
  };

  const closeFeatureManager = () => {
    setFeatureManager(false);
  };

  return (
    <div className="storage-management-information">
      <ApplicationComponentContent
        tags={["storage/remove"]}
        controls={controlsData}
        actions={[]}
      >
        <LabeledInformationContainer information={information} />
      </ApplicationComponentContent>
      {showDialog && <Dialog model={dialog} />}
      {isFeatureManagerOpen && (
        <AdminOperationManager
          isOpen={featureManager}
          toggle={toggleFeatureManagerOff}
          close={closeFeatureManager}
          selectedFeature={currentFeature}
        />
      )}
    </div>
  );
}

export default StorageInformation;
