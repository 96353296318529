import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries, getVideoStreamRegions, measurementSystem } from "./settingsSlice";
const settingsContext = React.createContext({});

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({});
  const api = useSelector(state => state.api);
  const settingsState = useSelector(state => state.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (api.token !== undefined) {
      if (settingsState.countries.status !== "success")
        dispatch(getCountries());

      if (settingsState.videoStreamRegions.status !== "success")
        dispatch(getVideoStreamRegions());
    }
  }, [api.token]);

  useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      ...settingsState,
      countries: settingsState.countries?.data?.countries || [],
      videoStreamRegions: settingsState.videoStreamRegions?.data?.videoStreamingRegions || [],
    }));
  }, [settingsState]);

  useEffect(() => {
    const savedMeasurementSystem = localStorage.getItem("measurement_system");
    if (savedMeasurementSystem) {
      dispatch(measurementSystem(savedMeasurementSystem));
    }
  }, []);

  return (
    <settingsContext.Provider value={settings}>
      {children}
    </settingsContext.Provider>
  );
};

export const useSettings = () => {
  return React.useContext(settingsContext);
};
