import { FaRegUser } from 'react-icons/fa';
import './UnitMissionListCard.sass';
import Card from 'src/hci/common/Card/Card';
import classNames from 'classnames';
import { TbRoute } from 'react-icons/tb';
import { MdOutlineLocationOn } from 'react-icons/md';
import Progress from 'src/hci/common/Progress/Progress';
import { useSelector } from 'react-redux';
import { DeviceOnlineStatus } from 'src/helper/useOnboardDeviceList';
import { deviceTypes } from 'src/helper/constants';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import useSubDevice from 'src/services/device/common/useSubDevice';

function UnitMissionListCard({ data }) {
  const deviceId = data.dock?.id || data.device?.id;
  const deviceInfo = useDeviceInfo(deviceId);
  const subDevice = useSubDevice(deviceId, deviceInfo?.type);
  
  return (
    <Card className="unit-mission-list-card">
      <div className="title-bar">
        <TbRoute className="icon" />
        <div className="title">{ data.name || data.title }</div>
        <div className="status">
          { data.status }
        </div>
      </div>
      <div className="progress">
        <Progress value={10} />
      </div>
      <div className="location">
        <MdOutlineLocationOn className="icon" />
        <div className="value">Sansmith Bridge, 23E</div>
      </div>
      <div className="devices-status">
        <div className={classNames("item", { 'status-online': deviceInfo?.onlineStatus === DeviceOnlineStatus.ONLINE })}>
          <div className="indicator"></div>
          <div className="name">{ deviceInfo?.modelInfo?.model || (
            deviceInfo?.type === deviceTypes.DOCK ? 'DJI Dock' : 'Drone'
          ) }</div>
        </div>
        { typeof subDevice === 'object' && (
          <div className={classNames("item", { 'status-online': !!subDevice.device_online_status })}>
            <div className="indicator"></div>
            <div className="name">{ subDevice.modelInfo?.model || 'Drone' }</div>
          </div>
        )}
      </div>
      <div className="created-by">
        <FaRegUser className="icon" />
        <div className="value">{ data.cretedBy?.displayName || data.createdBy?.emailAddress }</div>
      </div>
      <div className="register-time">{ data.creationDate }</div>
    </Card>
  )
}

export default UnitMissionListCard