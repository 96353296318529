import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function RadarFacing({ className, size, time }) {
  const compass = useSelector((state) => state.flight.compass);
  const [animate, setAnimate] = useState(true);
  const [facing, setFacing] = useState(0);

  useEffect(() => {
    setFacing(curr => {
      setAnimate(Math.abs(compass.facing - curr) < 270);
      return compass.facing;
    });
  }, [compass]);

  return (
    <svg
      className={className}
      style={{
        transform: `rotate(${facing}deg)`,
        transition: animate ? `transform ${time}s ease` : 'none',
        transformOrigin: "center",
      }}
      width={`${size}em`}
      height={`${size}em`}
      viewBox="0 0 230 230"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g data-name="Layer 2">
        <path
          d="m109.17 12.4 5.26-6.32 5.38 6.32V25h-10.64Z"
          style={{
            fill: "#2699fb",
          }}
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}
export default RadarFacing;
