import "./CameraObserverApp.sass";
import ApplicationContainer from "src/components/Dashboard/Components/ApplicationContainer/ApplicationContainer";
import ObserverDevicePanel from "./ObserverDevicePanel/ObserverDevicePanel";
import ObserverVideoStage from "./ObserverVideoStage/ObserverVideoStage";
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getList, list } from "../OnboardDeviceApp/OnboardDeviceAppSlice";
import { useState } from "react";
import { generateId } from "src/helper/utils";
import classNames from "classnames";
import { useAccount } from "src/helper/AccountStateProvider";
import { useRef } from "react";
import { HubEventName, HubName } from "src/helper/HubConnection";

export const CAMERA_OBSERVER_FULLSCREEN_URL_PARAM = 'w3E5yRZe';
export const CAMERA_OBSERVER_VIDEO_STAGE_STORAGE_ID = 'camera-observer-stage';

function CameraObserverApp() {
  const {
    hub: frontendConnectionManager,
    connected: frontendHubConnected,
    checkConnectivity,
  } = useFrontendHub();
  const dispatch = useDispatch();
  const { account } = useAccount();
  const api = useSelector((state) => state.api);
  const deviceListState = useSelector((state) => state.onboard?.list);
  const serverMessageHandlerIds = useRef([]);
  const systemMessageHandlerIds = useRef([]);
  const [loadingDevices, setLoadingDevices] = useState(true);
  const [devices, setDevices] = useState([]);
  const [initStatusRequested, setInitStatusRequested] = useState(false);
  const [videoStageItems, setVideoStageItems] = useState([]);
  const [enlargedApp, setEnlargedApp] = useState(false);

  const windowParams = new URL(window.location).searchParams;
  const popupWindowMode = windowParams.get(CAMERA_OBSERVER_FULLSCREEN_URL_PARAM);

  const histories = [
    { title: "Device Application", link: "/dashboard/device-app" },
    { title: "Camera Observer", },
  ];

  const serverMessageHandlers = devices.map((item) => [
    {
      identity: item.id,
      name: HubEventName.CONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        const drone = devices.find(d => d.id === data?.userId);
        if (drone) {
          checkConnectivity(account.user.localAccountId, data?.userId);
        }
      },
    },
    {
      identity: item.id,
      name: HubEventName.CONNECTIVITY_STATUS,
      platform: HubName.ONBOARD,
      handler: (data) => {
        const message = JSON.parse(data?.message);
        setDevices(curr => curr.map(d => d.id !== data?.userId ? d : ({
          ...d,
          isOnline: true,
          streamConfig: message?.videoStreaming || {},
        })));
      },
    },
    {
      identity: item.id,
      name: HubEventName.DISCONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        setDevices(curr => curr.map(d => d.id !== data?.userId ? d : ({
          ...d,
          isOnline: false,
        })));
      },
    },
  ]);

  const systemMessageHandlers = [
    {
      identity: account?.user?.localAccountId,
      name: HubEventName.DISCONNECTED,
      handler: (data) => {
        setDevices(curr => curr.map(d => ({
          ...d,
          isOnline: false,
        })));
      },
    },
    {
      identity: account?.user?.localAccountId,
      name: HubEventName.CONNECTED,
      handler: (data) => {
        devices?.forEach((item) => {
          checkConnectivity(account.user.localAccountId, item.id);
        });
      },
    },
  ];

  useEffect(() => {
    frontendConnectionManager.unsubscribeSystemMessages(
      systemMessageHandlerIds.current
    );

    systemMessageHandlerIds.current = frontendConnectionManager?.subscribeSystemMessages(
      systemMessageHandlers,
      "onboarddeviceapp"
    );

    frontendConnectionManager.unsubscribeServerMessages(
      serverMessageHandlerIds.current
    );

    serverMessageHandlerIds.current =
      frontendConnectionManager.subscribeServerMessages(
        serverMessageHandlers.reduce(function (result, current) {
          return [...result, ...current];
        }, []),
        "cameraobserverapp"
      );

    return () => {
      frontendConnectionManager.unsubscribeServerMessages(
        serverMessageHandlerIds.current
      );

      frontendConnectionManager?.unsubscribeSystemMessages(
        systemMessageHandlerIds.current
      );
    };
  });

  // Get device list when api token is available
  useEffect(() => {
    if (api.token !== undefined && deviceListState?.status !== "success")
      dispatch(getList());

    return () => dispatch(list({ status: "" }));
  }, [api.token]);

  // Set devices list on api response
  useEffect(() => {
    if (deviceListState?.data?.drones)
      setDevices(deviceListState.data.drones);

    if (deviceListState?.status)
      setLoadingDevices(false);
  }, [deviceListState, frontendHubConnected]);

  // Send device status request on devices list availability
  useEffect(() => {
    if (initStatusRequested) return;

    if (devices?.length) {
      devices.forEach(device => {
        checkConnectivity(account.user.localAccountId, device.id);
      });

      setInitStatusRequested(true);
    }
  }, [devices, initStatusRequested, frontendHubConnected]);

  // Handle initial video stage items list (load from storage / query string)
  // Update video stage items on device online status change
  useEffect(() => {
    if (!devices.length)
      return;

    if (videoStageItems?.length) {
      setVideoStageItems(curr => curr.map(item => {
        const device = devices.find(d => d.id === item.deviceId);
        return (device && device.isOnline !== item.isOnline) ? getVideoStageItem(device) : item;
      }));

      return;
    }

    if (popupWindowMode) {
      const deviceId = windowParams.get('device_id');

      if (devices.find(device => device.id === deviceId))
        addDeviceToStage(deviceId);
    } else {
      const savedStageItems = loadVideoStageItems() || [];
      const availableDevices = savedStageItems.map(item => devices.find(d => d.id === item.deviceId)).filter(Boolean);
      const listItems = availableDevices.map(item => getVideoStageItem(item));

      setVideoStageItems(listItems);
    }
  }, [devices]);

  const saveVideoStageItems = (items) => {
    localStorage.setItem(CAMERA_OBSERVER_VIDEO_STAGE_STORAGE_ID, JSON.stringify(items || []));
  }

  const loadVideoStageItems = () => {
    return JSON.parse(localStorage.getItem(CAMERA_OBSERVER_VIDEO_STAGE_STORAGE_ID));
  }

  const getVideoStageItem = (device) => device && ({
    id: generateId(),
    deviceId: device.id,
    title: device.name || device.model,
    isOnline: device.isOnline,
    streamConfig: {
      fpv: device.streamConfig?.fpvViewerConfig,
      main: device.streamConfig?.mainViewerConfig,
    },
    controllerRoute: `/dashboard/device-app/onboard/flight-app/${device.name}/${device.id}/${device.serialNumber}`
  });

  const addDeviceToStage = (id) => {
    const device = devices.find(item => item.id === id);
    if (!device) return;

    setVideoStageItems(curr => {
      const newValue = [
        ...curr,
        getVideoStageItem(device),
      ];

      if (!popupWindowMode)
        saveVideoStageItems(newValue);

      return newValue;
    });
  };

  const handleUserVideoClose = (id) => {
    setVideoStageItems(curr => {
      const newValue = curr.filter(item => item.id !== id);
      saveVideoStageItems(newValue);

      return newValue;
    })
  }

  const handleEnlargeAppToggle = () => {
    setEnlargedApp(curr => !curr);
  }

  return (
    <div className={classNames("camera-observer-app", { 'enlarged': enlargedApp || popupWindowMode })}>
      {!popupWindowMode && (
        <ObserverDevicePanel
          deviceList={devices}
          activeIds={videoStageItems.map(item => item.deviceId)}
          loading={loadingDevices}
          onDeviceClick={addDeviceToStage}
        />
      )}
      <ObserverVideoStage
        itemList={videoStageItems}
        enlargedApp={enlargedApp}
        onUserVideoClose={handleUserVideoClose}
        onEnlargeClick={handleEnlargeAppToggle}
        singleVideoView={popupWindowMode}
      />
    </div>
  );
}

export default CameraObserverApp;
