import { FiBatteryCharging } from "react-icons/fi";
import { MdHeight, MdOutlineSatelliteAlt } from "react-icons/md";
import { RiRemoteControlLine } from "react-icons/ri";
import { MeasurementSystem, MeasurementType } from "src/helper/constants";
import useMeasurement from "src/helper/useMeasurement";

const formatVsValue = (value) => {
  const isNegative = value < 0;
  const absValue = Math.abs(value);

  if (!absValue)
    return "0.0";

  const fixedValue = value.toFixed(1);
  const newValue = Math.abs(fixedValue).toString().padStart(1, "0");

  return isNegative && fixedValue < 0 ? '-' + newValue : newValue;
};

const formatSpeed = (value) => {
  const isNegative = value < 0;
  const absValue = Math.abs(value);

  if(!absValue)
    return "0.0";
  
  const fixedValue = absValue.toFixed(1);
  const newValue = fixedValue.padStart(3, "0")
  
  return isNegative && fixedValue < 0 ? '-' + newValue : newValue;
};

export const droneTelemetryItems = (telemetries) => {
  const { convertValue, getMeasurementUnit } = useMeasurement();
  const flightStatus = telemetries?.flightControl?.flightStatus;
  let droneAltitude = 0;

  if(flightStatus === 2 && telemetries?.gps)
    droneAltitude = telemetries?.altitudeInfo?.asl - telemetries?.gps?.homepoint?.alt;

  const speed = Math.sqrt(Math.pow(telemetries?.velocity?.x, 2) + Math.pow(telemetries?.velocity?.y, 2));
  const verticalSpeed = telemetries?.velocity?.z ? formatVsValue(telemetries?.velocity?.z) : "0.0";
  const asl = telemetries?.altitudeInfo?.asl ? telemetries?.altitudeInfo?.asl.toFixed(1) : "0.0";

  return [
    {
      title: 'Battery',
      value: `${telemetries?.batteryPercent?.[0]?.capacityPercent ?? '-'}%`,
      icon: <FiBatteryCharging />
    },
    {
      title: 'Satellites',
      value: `${telemetries?.gps?.visibleSats ?? '-'}`,
      icon: <MdOutlineSatelliteAlt />
    },
    {
      title: 'RC Status',
      value:  telemetries?.waypointState?.isRcConnected ? 'RC Connected' : 'RC Disconnected',
      icon: <RiRemoteControlLine />
    },
    {
      title: 'Altitude',
      value: `${Number(convertValue(droneAltitude, MeasurementType.ALTITUDE, MeasurementSystem.METRIC)).toFixed(2) ?? '-'} ${getMeasurementUnit(MeasurementType.ALTITUDE)}`,
      icon: <MdHeight />
    },
    {
      title: 'ASL',
      value: `${Number(convertValue(asl, MeasurementType.ALTITUDE, MeasurementSystem.METRIC)).toFixed(2) ?? '-'} ${getMeasurementUnit(MeasurementType.ALTITUDE)}`,
    },
    {
      title: 'SPD',
      value: `${formatSpeed(convertValue(speed, MeasurementType.SPEED, MeasurementSystem.METRIC))} ${getMeasurementUnit(MeasurementType.SPEED)}`,
    },
    {
      title: 'V.S',
      value: `${formatSpeed(convertValue(verticalSpeed, MeasurementType.SPEED, MeasurementSystem.METRIC))} ${getMeasurementUnit(MeasurementType.SPEED)}`,
    },
  ]
};