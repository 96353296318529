import "./ManageGuestRoles.sass";
import Form from "../../../../../ui/Form/Form";
import RadioButtonGroup from "../../../../../ui/RadioButtonGroup/RadioButtonGroup";
import { useEffect, useState } from "react";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";
import { groupBy } from "../../../../../helper/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  assignGuestRoles,
  getGuestRoles,
  assignRoles,
  getRolesList,
  roles,
} from "../GuestManagementSlice";

function ManageGuestRoles({ onOpen, onConfirm }) {
  const [selected] = useState("application");
  const [currentOption, setCurrentOption] = useState("no-role");
  const api = useSelector((state) => state.api.state);
  const role = useSelector((state) => state.role);
  const table = useSelector((state) => state.table);
  const dispatch = useDispatch();
  const [localRoles, setLocalRoles] = useState({
    application: [],
    administration: [],
  });
  const [guestRoles, setGuestRoles] = useState([]);
  const [data, setData] = useState([]);
  const guest = useSelector((state) => state.guest);
  const radioCheckChangedHandler = (event) => {
    setCurrentOption(event.target.value);
  };

  const checkboxCheckChangedHandler = (event) => {
    setLocalRoles({
      administration: localRoles.administration.map((role) => ({
        ...role,
        check:
          role.id === event.target.name ? event.target.checked : role.check,
      })),
      application: localRoles.application.map((role) => ({
        ...role,
        check:
          role.id === event.target.name ? event.target.checked : role.check,
      })),
    });
  };

  useEffect(() => {
    if (guest.assignRoles.status === "success") {
      dispatch(assignRoles({ status: "" }));
      dispatch(roles({ status: "" }));
      onConfirm();
    }
  }, [guest.assignRoles]);

  useEffect(() => {
    if (currentOption === "no-role") setData([]);
  }, [currentOption]);

  useEffect(() => {
    if (api !== undefined) {
      if (guest.rolesList.data === undefined) dispatch(getRolesList());
      else dispatch(getGuestRoles(table.row.id));
    }
  }, [api]);

  useEffect(() => {
    if (guest.rolesList.status === "success") {
      dispatch(getGuestRoles(table.row.id));
    }
  }, [table.row, guest.rolesList]);

  useEffect(() => {
    if (guest.roles.status === "success")
      if (
        guest.roles.data !== undefined &&
        guest.rolesList.data !== undefined
      ) {
        const tempRoles = [
          ...guest.rolesList.data.roles.map((item) => ({
            ...item,
            check: guest.roles.data.roles
              .map((role) => role.id)
              .includes(item.id),
          })),
        ];

        setCurrentOption(
          tempRoles.filter((x) => x.check).length > 0
            ? "application"
            : "no-role"
        );
        setLocalRoles({
          application: tempRoles.filter((item) => item.category === selected),
          administration: tempRoles,
        });
        setGuestRoles([...guest.roles.data.roles]);
      }
  }, [guest.roles]);

  useEffect(() => {
    if (localRoles[currentOption] !== undefined)
      setData([
        ...localRoles[currentOption]
          .filter((role) => role.check)
          .map((item) => ({ id: item.id, category: item.category })),
      ]);
    else setData([]);
  }, [localRoles]);

  useEffect(() => {
    onOpen?.();
    console.log(data);
  }, [data]);

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(assignGuestRoles(table.row.id, { roles: [...data] }));
  };

  return (
    <div className="manage-guest-roles">
      <Form
        data={{}}
        required={[]}
        validate={
          !data
            .map((item) => guestRoles.map((role) => role.id).includes(item.id))
            .reduce(
              (result, item) => result && item,
              data.length >= guestRoles.length
            ) > 0
        }
        submit={{
          title: "Confirm",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
          className: "submit-button"
        }}
        className="manage-roles-container"
      >
        <div>
          <p className="manage-roles-description">
            You can assign roles for {table.row.displayName}.
          </p>
          <RadioButtonGroup
            className="radio-button-group"
            name="role"
            selected={currentOption}
            group={["no-role", "application"]}
            titles={["no-role", "Application Access Level"]}
            onChange={radioCheckChangedHandler}
          />
          <p>assign your selected role for {table.row.displayName}</p>
          <ul className="role-groups">
            {Object.entries(groupBy(localRoles[currentOption], "category")).map(
              (category, cIndex) => (
                <li className="role-group-item" key={cIndex}>
                  <span>{category[0]}</span>
                  <ul className="role-items">
                    {category[1].map((role, rIndex) => (
                      <li className="role-item" key={rIndex}>
                        <CheckBox
                          title={role.description}
                          name={role.id}
                          text={role.name}
                          checked={role.check}
                          onChecked={checkboxCheckChangedHandler}
                        />
                      </li>
                    ))}
                  </ul>
                </li>
              )
            )}
          </ul>
        </div>
      </Form>
    </div>
  );
}

export default ManageGuestRoles;
