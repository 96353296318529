import { useEffect, useState } from "react";
import "./EditStorage.sass";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../../../../ui/Form/Form";
import { setOperation } from "../../../AdminOperationManager/OperationStepSlice";
import { updateStorage } from "../StorageManagementSlice";
import InputBox from "../../../../../ui/InputBox/InputBox";
import KeyValueFormNew from "../../../../../ui/KeyValueFormNew/KeyValueFormNew";

function EditStorage() {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [required, setRequired] = useState(["name"]);
  const [validate, setValidate] = useState(true);
  const [alerts, setAlerts] = useState({});
  const [tags, setTags] = useState({});
  const storage = useSelector((state) => state.storage);

  useEffect(() => {
    setData({
      id: storage?.model.id,
      storageTags: storage?.model.storageTags,
      name: storage?.model.name,
      description: storage?.model.description,
    });
    setTags({ ...storage?.model?.storageTags });
  }, [storage.model]);

  useEffect(() => {
    dispatch(setOperation("edit_storage"));
  }, []);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAlerts({ ...alerts, [key]: { alert: false } });
    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(updateStorage({ ...data }, data.id));
  };

  useEffect(() => {
    if (storage.update.status === "success") {
    } else if (storage.update.status === "validation") {
      let validations = {};
      storage.update.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
  }, [storage.update]);

  const onKeyValueChange = (tags) => {
    setData({ ...data, storageTags: { ...tags } });
  };

  return (
    <div className="edit-storage-form">
      <Form
        submit={{
          title: "Confirm",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        className="edit-storage-container"
        validate={validate}
      >
        <InputBox
          isRequired={required.includes("name")}
          title="Storage Name"
          name="name"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Name}
          value={data.name}
          index={0}
          background="white"
          width="100%"
        />
        <InputBox
          isRequired={required.includes("description")}
          title="Storage Description"
          name="description"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Description}
          value={data.description}
          index={1}
          background="white"
          width="100%"
        />

        <KeyValueFormNew
          description="You can add optional tags for this storage up to 10 fields."
          onDataChange={onKeyValueChange}
          tags={tags}
        />
      </Form>
    </div>
  );
}

export default EditStorage;
