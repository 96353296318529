import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../redux/action/api";

const WorkspaceSlice = createSlice({
  name: "workspace",
  initialState: {
    create: { status: "" },
    update: { status: "" },
    workspace: {
      id: 12,
      parent: 1,
      title: "Inspection",
      name: "insp",
    },
    workspaces: [
      {
        id: 1,
        parent: null,
        title: "Projects",
        name: "projects",
      },
      {
        id: 11,
        parent: 1,
        title: "oil",
        name: "oil&gas",
      },
      {
        id: 12,
        parent: 1,
        title: "Inspection",
        name: "insp",
      },
      {
        id: 2,
        parent: null,
        title: "Departments",
        name: "departments",
      },
      {
        id: 21,
        parent: 2,
        title: "Engineering",
        name: "engineering",
      },
      {
        id: 3,
        parent: null,
        title: "Customers",
        name: "Customers",
      },
      {
        id: 31,
        parent: 3,
        title: "Edd",
        name: "edd",
      },
    ],
  },
  reducers: {
    setWorkspace(state, action) {
      const data = action.payload;
      return { ...state, workspace: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
  },
});
export const { create, update, setWorkspace } = WorkspaceSlice.actions;
export default WorkspaceSlice.reducer;

export const createWorkspace = (data) =>
  apiCallBegan({
    url: "workspace",
    method: "Post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const updateWorkspace = (data) =>
  apiCallBegan({
    url: "workspace",
    method: "Put",
    data,
    onSuccess: update.type,
    onError: update.type,
  });
