import "./ManageRolePermissions.sass";
import Form from "../../../../../ui/Form/Form";
import RadioButtonGroup from "../../../../../ui/RadioButtonGroup/RadioButtonGroup";
import { useEffect, useState } from "react";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";
import { groupBy } from "../../../../../helper/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  assignPermissions,
  assignRolePermissions,
  getRolePermissions,
  permissions,
  rolePermissions,
} from "../RoleManagementSlice";

function ManageRolePermissions({ onOpen, onConfirm }) {
  const [selected] = useState("application");
  const [currentOption, setCurrentOption] = useState("application");
  const [adminPermission, setAdminPermission] = useState(false);
  const role = useSelector((state) => state.role);
  const table = useSelector((state) => state.table);
  const dispatch = useDispatch();
  const [localPermissions, setLocalPermissions] = useState({
    application: [],
    administration: [],
  });
  const [roleAllPermissions, setRoleAllPermissions] = useState([]);
  const [data, setData] = useState([]);

  const radioCheckChangedHandler = (event) => {
    setCurrentOption(event.target.value);
  };

  const checkboxCheckChangedHandler = (event) => {
    setLocalPermissions({
      administration: localPermissions.administration.map((permission) => ({
        ...permission,
        check:
          permission.id === event.target.name
            ? event.target.checked
            : permission.check,
      })),
      application: localPermissions.application.map((permission) => ({
        ...permission,
        check:
          permission.id === event.target.name
            ? event.target.checked
            : permission.check,
      })),
    });
  };

  useEffect(() => {
    if (role.assignPermissions.status === "success") {
      dispatch(assignPermissions({ status: "" }));
      dispatch(rolePermissions({ status: "" }));
    }
  }, [role.assignPermissions]);

  useEffect(() => {
    if (table.row.category !== undefined
    ) {
      dispatch(getRolePermissions(table.row.id, table.row.category));
    }
  }, [table.row]);

  useEffect(() => {
    if (role.rolePermissions.status === "success")
      if (
        role.permissions.data !== undefined &&
        role.rolePermissions.data !== undefined
      ) {
        const tempRoles = [
          ...role.permissions.data.permissions.map((item) => ({
            ...item,
            check: role.rolePermissions.data.permissions
              .map((permission) => permission.id)
              .includes(item.id),
          })),
        ];
        setLocalPermissions({
          application: tempRoles.filter((item) => item.category === selected),
          administration: tempRoles,
        });
        setRoleAllPermissions([...role.permissions.data.permissions]);
        setAdminPermission(
          tempRoles
            .filter((item) => item.category !== selected)
            .reduce((result, item) => item.check || result, false)
        );
      }
  }, [role.rolePermissions]);

  useEffect(() => {
    setData([
      ...localPermissions[currentOption]
        .filter((permission) => permission.check)
        .map((item) => ({ id: item.id, category: item.category })),
    ]);
  }, [localPermissions]);

  useEffect(() => {
    setCurrentOption(adminPermission ? "administration" : "application");
  }, [adminPermission]);

  useEffect(() => {
    onOpen?.();
    console.log(data);
  }, [data]);

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(
      assignRolePermissions({
        role: { id: table.row.id, category: table.row.category },
        permissions: [...data],
      })
    );
  };

  return (
    <div className="manage-user-permissions">
      <Form
        data={{}}
        required={[]}
        validate={
          !data
            .map((item) =>
              roleAllPermissions
                .map((permission) => permission.id)
                .includes(item.id)
            )
            .reduce(
              (result, item) => result && item,
              data.length >= roleAllPermissions.length
            ) > 0
        }
        submit={{
          title: "Confirm",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        className="manage-permissions-container"
      >
        <p className="manage-permissions-description">
          You can assign permissions for {table.row.name}.
        </p>
        <RadioButtonGroup
          className="radio-button-group"
          name="permission"
          selected={currentOption}
          group={["application", "administration"]}
          titles={["Application", "Administration"]}
          onChange={radioCheckChangedHandler}
        />
        <p>assign your selected permission for {table.row.name}</p>
        <ul className="permission-groups">
          {Object.entries(
            groupBy(localPermissions[currentOption], "category")
          ).map((category, cIndex) => (
            <li className="permission-group-item" key={cIndex}>
              <span>{category[0]}</span>
              <ul className="permission-items">
                {category[1].map((permission, rIndex) => (
                  <li className="permission-item" key={rIndex}>
                    <CheckBox
                      title={permission.description}
                      name={permission.id}
                      text={permission.name}
                      checked={permission.check}
                      onChecked={checkboxCheckChangedHandler}
                    />
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Form>
    </div>
  );
}

export default ManageRolePermissions;
