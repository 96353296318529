import "./Application.sass";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useState } from "react";
function Application({ image, name, link, color }) {
  const [lazy, setLazy] = useState(true);
  const contentLoaded = () => {
    setLazy(false);
  };
  return (
    <div className="application">
      <Link to={link} className="application-container">
        <img
          src={image}
          alt="Application icon"
          className={`${lazy && "lazyColor"}`}
          onLoad={contentLoaded}
        />
        <div className="application-link" style={{ color: color }}>
          <span>{name}</span>
          <HiOutlineArrowNarrowRight />
        </div>
      </Link>
    </div>
  );
}

export default Application;
