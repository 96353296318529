import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import { useAccount } from "src/helper/AccountStateProvider";
import { HubEventName, HubName } from "src/helper/HubConnection";
import { setOnboardConnectionInfo } from "src/services/device/DeviceServiceSlice";

export default function useOnboardStreamingInfo(deviceId) {
  const serverMessageHandlerIds = useRef([]);
  const [streamingInfo, setStreamingInfo] = useState();
  const { account } = useAccount();
  const {hub: frontendHub, checkConnectivity} = useFrontendHub();
  const streamingInfoState = useSelector(state => state.deviceService.onboardConnections?.find(item => item.deviceId === deviceId)?.streamingInfo);

  useEffect(() => {
    if (!deviceId) return;

    if (streamingInfoState) {
      setStreamingInfo(streamingInfoState);
      return;
    }

    const serverMessageHandlers = [
      {
        identity: deviceId,
        name: HubEventName.CONNECTIVITY_STATUS,
        platform: HubName.ONBOARD,
        handler: (data) => {
          const message = JSON.parse(data?.message);
          if (message?.videoStreaming) {
            setStreamingInfo(message?.videoStreaming);
            setOnboardConnectionInfo({
              deviceId,
              data: {
                streamingInfo: message?.videoStreaming
              },
            })
          }
        },
      }
    ];

    frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);
    serverMessageHandlerIds.current = frontendHub.subscribeServerMessages(
      serverMessageHandlers,
      "streamingInfo"
    );

    checkConnectivity(account.user.localAccountId, deviceId);

    return () => {
      frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);
    };
  }, [deviceId]);

  return streamingInfo;
}
