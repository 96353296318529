import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const S3ManagementSlice = createSlice({
  name: "s3Storage",
  initialState: {
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    restore: { status: "" },
    model: {},
    list: [],
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    setModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    restore(state, action) {
      const data = action.payload;
      return { ...state, restore: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
  },
});
export const {
  list,
  create,
  update,
  restore,
  remove,
  setModel,
  createContainer,
} = S3ManagementSlice.actions;
export default S3ManagementSlice.reducer;

export const getList = () =>
  apiCallBegan({
    url: "/storages/s3",
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const createStorage = (data) =>
  apiCallBegan({
    url: "/storages/s3",
    method: "post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const deleteStorage = (id) =>
  apiCallBegan({
    url: `/storages/s3/${id}`,
    method: "delete",
    onSuccess: remove.type,
    onError: remove.type,
  });
export const deleteStoragePermanently = (id) =>
  apiCallBegan({
    url: `/storages/s3/permanently/${id}`,
    method: "delete",
    onSuccess: remove.type,
    onError: remove.type,
  });
export const restoreStorage = (id) =>
  apiCallBegan({
    url: `/storages/s3/restore/${id}`,
    method: "patch",
    onSuccess: restore.type,
    onError: restore.type,
  });

export const updateStorage = (data, id) =>
  apiCallBegan({
    url: "/storages/s3" + id,
    method: "patch",
    data,
    onSuccess: update.type,
    onError: update.type,
  });
