import { createSlice } from "@reduxjs/toolkit";

const DialogSlice = createSlice({
  name: "dialog",
  initialState: {
    status: { open: false },
  },
  reducers: {
    DialogStatus(state, action) {
      const data = action.payload;
      return { ...state, status: data };
    },
  },
});
export const { DialogStatus } = DialogSlice.actions;
export default DialogSlice.reducer;
