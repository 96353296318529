import './EntityDetails.sass';
import classNames from 'classnames';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries';
import { EntityType } from '../../../ExploreApp';
import { dockTelemetryItems } from './common/dockTelemetryItems';
import { droneTelemetryItems } from './common/droneTelemetryItems';
import { BsBoxSeam } from 'react-icons/bs';
import { TbBuildingCommunity, TbDeviceIpadQuestion, TbDrone } from 'react-icons/tb';
import { MdDevices, MdOutlineClose } from 'react-icons/md';
import { DeviceOnlineStatus } from 'src/helper/useDockList';
import UnitExtraDetails from './common/UnitExtraDetails/UnitExtraDetails';
import MobileExtraDetails from './common/MobileExtraDetails/MobileExtraDetails';
import useSubDevice from 'src/services/device/common/useSubDevice';
import { CloudDroneTelemetryItems } from './common/CloudDroneTelemetryItems';
import HmsStatusView from '../../../common/HmsStatusView/HmsStatusView';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { setSelectedEntity } from '../../../ExploreAppSlice';
import { useEffect, useRef, useState } from 'react';
import { setLastSelectedMarker, setNewFlyCoordinates } from 'src/components/DeviceMap/DeviceMapSlice';

function EntityDetails({ entity, onClose, missionController }) {
  const dispatch = useDispatch();
  const store = useStore();
  const deviceInfo = useDeviceInfo(entity?.id);
  const telemetries = useDeviceTelemetries(entity?.id);
  const subDeviceInfo = useSubDevice(entity?.id);
  const subDeviceTelemetries = useDeviceTelemetries(subDeviceInfo?.device_sn);
  const lastSelectedMarker = useSelector(state => state.deviceMap.lastSelectedMarker);
  const [subDeviceSelected, setSubDeviceSelected] = useState(lastSelectedMarker?.data?.sourceId === subDeviceInfo?.device_sn);
  const lastSubdeviceOnlineStatus = useRef();
  
  const getMarkerById = (id) => store.getState().deviceMap.markers?.find(marker => marker.data?.sourceId === id);

  const entityName = entity?.name || deviceInfo?.name || deviceInfo?.title || entity?.user?.displayName;
  const entityType = deviceInfo?.type || entity?.entityType;
  let entityOnlineStatus = deviceInfo?.onlineStatus;

  const entityIcon = entityType ? {
    [EntityType.DOCK]: <BsBoxSeam />,
    [EntityType.DRONE]: <TbDrone />,
    [EntityType.MOBILE_DEVICE]: <MdDevices />,
    [EntityType.OPERATION_UNIT]: <TbBuildingCommunity />
  }[entityType] : <TbDeviceIpadQuestion />;

  const entityTypeTitle = entityType ? {
    [EntityType.DOCK]: 'Dock',
    [EntityType.DRONE]: 'Drone',
    [EntityType.MOBILE_DEVICE]: 'Mobile',
    [EntityType.OPERATION_UNIT]: 'Unit'
  }[entityType] : 'Unknown';

  const mainTelemetryItems = !!deviceInfo && {
    [EntityType.DOCK]: dockTelemetryItems(telemetries),
    [EntityType.DRONE]: droneTelemetryItems(telemetries)
  }[deviceInfo?.type];

  const renderTelemetryItems = (telemetryItems) => {
    return telemetryItems.map(item => (
      <div className="item" key={item.name} title={item.name}>
        {item.icon && <div className="icon">{item.icon}</div>}
        {!item.icon && <div className="title">{item.title}</div>}
        <div className="value">{item.value}</div>
      </div>
    ));
  }

  const switchDevice = (subDevice) => {
    const mapMarker = getMarkerById(subDevice ? subDeviceInfo?.device_sn : entity.id);
    dispatch(setLastSelectedMarker(mapMarker || null));
    setSubDeviceSelected(subDevice);
  }

  useEffect(() => {
    
    if(lastSubdeviceOnlineStatus.current === subDeviceInfo?.device_online_status)
      return;
    
    if(!subDeviceInfo?.device_online_status || (subDeviceInfo?.device_online_status && subDeviceTelemetries?.latitude)) {
      setTimeout(() => switchDevice(!!subDeviceInfo?.device_online_status), 500);
      lastSubdeviceOnlineStatus.current = subDeviceInfo?.device_online_status;
    }
    else {
      switchDevice(false);
      lastSubdeviceOnlineStatus.current = false;
    }
  }, [subDeviceInfo?.device_online_status, subDeviceTelemetries?.latitude]);

  useEffect(() => {
    if(lastSelectedMarker?.data?.sourceId && subDeviceInfo?.device_sn)
      setSubDeviceSelected(lastSelectedMarker?.data?.sourceId === subDeviceInfo?.device_sn);
  }, [lastSelectedMarker]);

  return (
    <div className={classNames("entity-details")}>
      <div className="entity-header">
        {[EntityType.DOCK, EntityType.DRONE].includes(entityType) && (
          <div className={classNames("status-indicator", {'status-online': entityOnlineStatus === DeviceOnlineStatus.ONLINE})}></div>
        )}
        
        <div className="title" title={
          entity?.id 
          + (deviceInfo?.serialNumber ? (' / ' + deviceInfo?.serialNumber) : '') 
          + ' / ' + entityName + (!!telemetries?.droneInfo?.model ? ` (${telemetries.droneInfo.model})` : '')
        }>
          {entityName + (!!telemetries?.droneInfo?.model ? ` (${telemetries.droneInfo.model})` : '')}
        </div>

        <div className="icon">{entityIcon}</div>
        <div className="type">{entityTypeTitle}</div>
        <MdOutlineClose className="close-button" onClick={() => onClose?.()} />
      </div>
      {mainTelemetryItems?.length > 0 && entityOnlineStatus === DeviceOnlineStatus.ONLINE && (
        <>
          <div 
            key="entity-group-1"
            className={classNames("entity-details-group", { 
              selected: subDeviceInfo?.device_online_status === undefined || !subDeviceSelected,
              'is-selectable': !!subDeviceInfo?.device_online_status
            })}
            onClick = {() => switchDevice(false)}
          >
            <div className="group-title">
              <span>{ entityType === EntityType.DOCK ? 'Dock' : 'Drone'}</span>
            </div>
            <div>
              {entityOnlineStatus === DeviceOnlineStatus.ONLINE && (
                <HmsStatusView deviceId={entity?.id} deviceType={entityType} />
              )}
              <div className="group-telemetries">
                {renderTelemetryItems(mainTelemetryItems)}
              </div>
            </div>
            {/* <div className="group-actions">

            </div> */}
          </div>
          {!!subDeviceInfo?.device_online_status && <>
            <div 
              key="entity-group-2"
              className={classNames("entity-details-group is-selectable", { selected: subDeviceSelected })}
              onClick = {() => switchDevice(true)}
            >
              <div className="group-title">
                <span>Drone</span>
              </div>
              <div>
                {!!subDeviceInfo?.device_online_status && (
                  <HmsStatusView deviceId={entity?.id} deviceType={entityType} subDevice={true} />
                )}
                <div className="group-telemetries">
                  <CloudDroneTelemetryItems
                    telemetries={subDeviceTelemetries}
                    dockTelemetries={telemetries}
                    render={renderTelemetryItems}
                  />
                </div>
              </div>
            </div>
          </>}
        </>
      )}
      {entityType === EntityType.OPERATION_UNIT && (
        <UnitExtraDetails entity={entity} />
      )}
      {entityType === EntityType.MOBILE_DEVICE && (
        <MobileExtraDetails entity={entity} />
      )}
    </div>
  )
}

export default EntityDetails