import { Link } from "react-router-dom";
import { useTheme } from "src/helper/ThemeProvider";
import "./AppLauncherItem.sass";
import { Text } from "@radix-ui/themes";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { mainMenu } from "src/store/UiSlice";
function AppLauncherItem({ item, className }) {
  const { currentContext } = useTheme();
  const dispatch = useDispatch();

  return (
    <Link
      to={item.link}
      className={classNames("app-launcher-item", className, {"active": item.name === currentContext.theme.name})}
      title={item.title}
      onClick={() => dispatch(mainMenu({ open: false }))}
    >
      <div className="app-launcher-item-icon">{item.defaultImage}</div>
      <div className="app-launcher-item-title">{item.title}</div>
    </Link>
  );
}

export default AppLauncherItem;
