import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import "./ApplicationsOperationStep.sass";
import Toast from "../Toast/Toast";
import { useTheme } from "../../helper/ThemeProvider";

function ApplicationsOperationStep({
  name,
  title,
  icon,
  toggleActive,
  isActive,
  isLocked,
  children,
  tags,
}) {
  const [active, setActive] = useState(isActive);
  const [locked, setLocked] = useState(isLocked);
  const loading = useSelector((state) => state.loading);
  const toast = useSelector((state) => state.toast);

  useEffect(() => {}, [name]);
  useEffect(() => {}, [isLocked]);
  useEffect(() => {}, [loading]);

  const { currentContext } = useTheme();

  const toggle = () => {
    if (!isLocked) {
      setActive(!active);
      toggleActive(name);
    }
  };
  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  useEffect(() => {
    setLocked(isLocked);
  }, [isLocked]);

  return (
    <li
      className={`app-operation-step app-operation-step${
        active ? "-active" : "-passive"
      }`}
    >
      <div
        className={`app-operation-step-title app-operation-step-title${
          active ? "-active" : "-passive"
        }`}
        onClick={toggle}
      >
        {icon}
        <span>{title}</span>
      </div>
      {active && !locked && (
        <div className="app-operation-step-content">
          {tags.includes(loading.name) && loading.status && <Loading />}
          {tags.includes(toast.name) && <Toast />}
          {children}
        </div>
      )}
    </li>
  );
}
ApplicationsOperationStep.defaultProps = {
  tags: [],
};
export default ApplicationsOperationStep;
