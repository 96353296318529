import PropTypes from "prop-types";
import "./AdjustmentOverlay.sass";
import React, { useEffect, useState, useRef } from "react";

function AdjustmentOverlay({
  value,
  min,
  max,
  title,
  valueRender
}) {
  const [isActive, setIsActive] = useState(false);
  const activationTimerId = useRef();

  useEffect(() => {
    setIsActive(true);

    if(activationTimerId.current) {
      clearTimeout(activationTimerId.current);
    }

    activationTimerId.current = setTimeout(() => setIsActive(false), 1000);
  }, [value]);

  return (
    <>
      <div
        className={`adjustment-overlay ${isActive ? "active" : ""}`}
      >
        <div className="container">
          <div className="title">{title}</div>
          <div className="progress">
            <div className="progress-bar" style={{width: (value/max)*100 + '%'}}></div>
          </div>
          <div className="value">{valueRender ? valueRender(value) : value}</div>
        </div>
      </div>
    </>
  );
}

AdjustmentOverlay.prototype = {
  title: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  valueDisplayRatio: PropTypes.number
};

export default AdjustmentOverlay;
