import './UserProfileGroupsMembership.sass'
import React, { useState, useEffect } from 'react'
import GroupMembershipApp from 'src/ui/GroupApp/GroupMembershipApp/GroupMembershipApp';
import OperationHandler from 'src/ui/OperationHandler/OperationHandler'
import { setOperation } from 'src/components/Dashboard/Components/ApplicationOperationManager/AppOperationStepSlice';
import { useDispatch } from 'react-redux';

function UserProfileGroupsMembership({ onClose }) {
  const [operationHandlerOpen, setOperationHandlerOpen] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOperation('groups_app_membership'));
  }, []);

  const feature = {
    id: "edit_group",
    title: "Edit Group Members",
    steps: [
      {
        name: "groups_app_membership",
        title: "Group Membership",
        active: true,
        visible: true,
        locked: false,
        closable: false,
        tags: ["groupApp/assignMembers"],
        activity: <GroupMembershipApp />,
        fullHeight: true,
      },
    ],
  };

  return (
    <OperationHandler
      selectedFeature={feature}
      isOpen={operationHandlerOpen}
      close={() => setOperationHandlerOpen(false)}
      toggle={() => onClose?.()}
      onStepChange={() => { }}
    />
  )
}

export default UserProfileGroupsMembership