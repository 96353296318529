import "./GroupInformationApplication.sass";
import EditableTextBox from "../../../../../ui/EditableTextBox/EditableTextBox";
import Button from "../../../../../ui/Button/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGroup } from "../../../../../ui/GroupApp/GroupAppSlice";

function GroupInformationApplication() {
  const [changeDetector, setChangeDetector] = useState(false);
  const [objectModel, setObjectModel] = useState({});
  const dispatch = useDispatch();
  const groupApp = useSelector((state) => state.groupApp);
  const [currentGroup, setCurrentGroup] = useState();

  const [data, setData] = useState({
    name: {
      name: "name",
      title: "Group Name",
      type: "text",
    },
    description: {
      name: "description",
      title: "Description",
      type: "text",
    },
  });

  useEffect(() => {
    if (groupApp.currentGroup !== undefined) {
      var temp = {};
      Object.entries(groupApp.currentGroup).map((item) => {
        var key = item[0];
        var value = item[1];
        if (data[key] !== undefined)
          temp = {
            ...temp,
            [key]: { ...data[key], value: value !== null ? value : "" },
          };
      });
      setCurrentGroup(groupApp.currentGroup);
      setData({ ...temp });
    }
  }, [groupApp.currentGroup]);

  const changeHandler = (event) => {
    var key = event.target.name;
    var value = event.target.value;
    if (value.toString() !== "") {
      setObjectModel({ ...objectModel, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = objectModel;
      setObjectModel({ ...withoutSecond });
    }
  };

  useEffect(() => {
    setChangeDetector(false);
    Object.entries(objectModel).forEach((item) => {
      if (
        Object.entries(data).filter((x) => {
          return x[0] === item[0];
        })[0][1].value !== item[1]
      ) {
        setChangeDetector(true);
        return;
      }
    });
  }, [objectModel]);

  const submitHandler = () => {
    dispatch(updateGroup(objectModel, currentGroup?.id));
  };

  return (
    <div className="group-information-application">
      <div className="group-information-application-container">
        <div className="edit-group-information-application-section">
          <EditableTextBox
            readonly={!currentGroup?.isOwner}
            model={data.name}
            width="48%"
            onChange={changeHandler}
          />
          <EditableTextBox
            readonly={!currentGroup?.isOwner}
            model={data.description}
            width="48%"
            onChange={changeHandler}
          />
        </div>
        {changeDetector && currentGroup?.isOwner && (
          <Button
            value="Confirm"
            color="#2699fb"
            width="30%"
            onClick={submitHandler}
          />
        )}
      </div>
    </div>
  );
}

export default GroupInformationApplication;
