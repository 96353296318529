import React from 'react'
import './Accordion.sass'
import AccordionItem from './AccordionItem'

function Accordion({items, ...props}) {
  return (
    <div className="accordion" {...props}>
      {items.map((item, itemIndex) => (
        <AccordionItem key={itemIndex} {...item}>{item.content}</AccordionItem>
      ))}
    </div>
  )
}

export default Accordion