import { useEffect, useState } from "react";
import DesktopSize from "../../ui/DesktopSize/DesktopSize";
import TabletSize from "../../ui/TabletSize/TabletSize";
import MobileProfileNavigation from "../ProfileNavigation/MobileProfileNavigation/MobileProfileNavigation";
import "./AdminCenter.sass";
import AdminContentArea from "./AdminContentArea/AdminContentArea";
import AdminNavbar from "./AdminNavbar/AdminNavbar";
import AdminMobileFooter from "./AdminMobileFooter/AdminMobileFooter";
import { useHistory } from "react-router";
import { useAccountState } from "../../helper/AccountStateProvider";
import Loading from "../../ui/Loading/Loading";
import { useDispatch } from "react-redux";
import { setContext } from "../../redux/ducks/appContextSlice";
import AppBar from "src/components/AppBar/AppBar";
import HeaderLoading from "src/ui/HeaderLoading/HeaderLoading";
import { AppThemeNames, useAppTheme } from "src/helper/AppThemeProvider";

function AdminCenter() {
  const [openProfile, setOpenProfile] = useState(false);
  const [theme, setTheme] = useAppTheme();
  const toggleProfileOn = (event) => {
    setOpenProfile(true);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setContext({
        theme: {
          color: "#2699fb",
          name: "admin",
          secondColor: "#f3f6fb",
          textColor: "#23333d",
        },
      })
    );

    setTheme(AppThemeNames.LIGHT);
  }, []);
  const toggleProfileOff = (event) => {
    setOpenProfile(false);
  };
  const history = useHistory();
  const { accountStatus } = useAccountState();
  useEffect(() => {
    if (
      accountStatus.mobile === false ||
      accountStatus.agreement === false ||
      accountStatus.account === false
    )
      history.push("/account");
  }, [accountStatus, history]);

  return accountStatus.mobile === true &&
    accountStatus.agreement === true &&
    accountStatus.account === true ? (
    <div className="admin-center">
      <TabletSize>
        <AppBar onToggleProfileMenu={toggleProfileOn} background="#f3f6fb" />
        <div className="admin-center-mobile">
          {openProfile && (
            <MobileProfileNavigation
              isOpen={openProfile}
              toggle={toggleProfileOff}
            />
          )}
          <AdminNavbar />
          <AdminContentArea />
        </div>
        <AdminMobileFooter />
      </TabletSize>

      <DesktopSize>
        <AppBar onToggleProfileMenu={toggleProfileOn} background="#f3f6fb" />
        <div className="admin-center-desktop">
          {openProfile && (
            <MobileProfileNavigation
              isOpen={openProfile}
              toggle={toggleProfileOff}
            />
          )}
          <AdminNavbar fixed={true} />
          <AdminContentArea />
        </div>
      </DesktopSize>
    </div>
  ) : (
    <HeaderLoading />
  );
}

export default AdminCenter;
