import { cameraExposureModes } from "src/helper/constants";
import { useDeviceInfo } from "src/helper/DeviceInfoProvider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CameraTelemetry from "../../../../components/Dashboard/Components/CameraTelemetry/CameraTelemetry";
import "./CameraEV.sass";
import CameraEvSelector from "./CameraEvSelector/CameraEvSelector";

function CameraEV() {
  const flight = useSelector((state) => state.flight);
  const exposureMode = useSelector((state) => state.flight.camera?.exposureMode);
  const [currValue, setCurrValue] = useState("Auto");
  const { activeCameraPayload } = useDeviceInfo();
  const exposureValues = activeCameraPayload?.settings?.exposure?.values;

  useEffect(() => {
    if (flight.camera?.exposureVal)
      setCurrValue(exposureValues?.[flight.camera?.exposureVal - 1]);
  }, [flight.camera, exposureValues]);

  if (!activeCameraPayload?.settings?.exposure) return null;

  return exposureMode !== cameraExposureModes.EXPOSURE_MANUAL ? (
    <CameraTelemetry
      name="ev"
      title="EV"
      value={currValue}
      selector={<CameraEvSelector />}
    />
  ) : null;
}

export default CameraEV;
