/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Button from "../../../../../ui/Button/Button";
import PhoneSelector from "../../../../../ui/PhoneSelector/PhoneSelector";
import Stepper from "../../../../../ui/Stepper/Stepper";
import "../PhoneVerification.sass";
import { useDispatch, useSelector } from "react-redux";
import {
  tabletSize,
  useViewport,
} from "../../../../../helper/ViewPortProvider";
import { help } from "../../../AccountManagementSlice";
import { sendVerification } from "src/store/UserVerificationSlice";
import { useSettings } from "src/helper/SettingsProvider";

const SendCode = ({ nextStep }) => {
  const [status, setStatus] = useState({
    alert: false,
    type: "error",
    message: "mobile not found",
  });

  const dispatch = useDispatch();
  const settings = useSettings();
  const phone = useSelector((state) => state.phone);
  const sendSMS = useSelector((state) => state.verification.sendSMS);
  const { width } = useViewport();

  useEffect(() => {
    if (sendSMS.status === "validation") {
      setStatus({
        ...status,
        alert: sendSMS.status === "validation",
        message: sendSMS.errors[0].errorMessage,
      });
    } else if (sendSMS.status === "success") {
      nextStep(sendSMS.data);
      dispatch(help("verify_code"));
    }
  }, [sendSMS]);

  useEffect(() => {
    setStatus({ ...status, alert: false });
  }, [phone]);

  const verifyStep = () => {
    setStatus({ ...status, alert: false });
    dispatch(sendVerification({ mobilePhone: phone.prefix + phone.value }));
  };

  return (
    <div className="phone-verification">
      <div className="phone-verification-container">
        <p className="phone-verification-title">
          Please enter your phone number
        </p>
        {/* <Stepper
          steps={2}
          color="#2699fb"
          currentStep={1}
          firstStep={true}
          secondStep={false}
        /> */}
        <PhoneSelector
          status={status}
          countries={settings.countries}
          currentPrefix={settings.countries[0]?.prefix}
          currentValue=""
        />
      </div>
      <Button
        color="#2699fb"
        onClick={verifyStep}
        value="Next"
        disabled={phone.value === "" || phone.prefix === ""}
        width={width < tabletSize ? "40%" : "25%"}
      />
    </div>
  );
};

export default SendCode;
