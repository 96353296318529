import { useTheme } from "src/helper/ThemeProvider";
import { FaUserCircle } from "react-icons/fa";
import "./SVGAvatar.sass";

function SVGAvatar() {
  const { currentContext } = useTheme();
  return (
    <span className="svg-avatar">
      <FaUserCircle fontSize="32px" color={`${currentContext.theme.color}99`} />
    </span>
  );
}

export default SVGAvatar;
