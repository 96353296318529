import React, { useEffect, useState } from "react";
import { setOperation } from "src/components/Dashboard/Components/ApplicationOperationManager/AppOperationStepSlice";
import "./EnterNumberForm.sass";
import { useDispatch, useSelector } from "react-redux";
import PhoneSelector from "src/ui/PhoneSelector/PhoneSelector";
import Form from "src/ui/Form/Form";
import { useCurrentUser } from "src/helper/AccountStateProvider";
import { sendVerification } from "src/store/UserVerificationSlice";
import { useSettings } from "src/helper/SettingsProvider";

function EnterNumberForm() {
  const requiredFields = ["mobilePhone"];
  const settings = useSettings();
  const [isFormValid, setIsFormValid] = useState(false);
  const [alerts, setAlerts] = useState({});
  const dispatch = useDispatch();
  const [currentPhone, setCurrentPhone] = useState({});
  const { currentUser } = useCurrentUser();
  const [data, setData] = useState({ ...currentUser?.profile });
  const phone = useSelector((state) => state.phone);
  const sendSMS = useSelector((state) => state.verification.sendSMS);
  const [status, setStatus] = useState({
    alert: false,
    type: "error",
    message: "mobile not found",
  });

  useEffect(() => {
    const countryCode = settings.countries?.find((item) =>
      currentUser?.profile?.mobilePhone?.startsWith(item.prefix)
    );

    if (countryCode)
      setCurrentPhone({
        prefix: countryCode.prefix,
        value: currentUser?.profile?.mobilePhone?.substring(
          countryCode.prefix.length
        ),
      });
  }, [currentUser, settings.countries]);

  useEffect(() => {
    setStatus({ ...status, alert: false });
    setIsFormValid(
      phone.prefix + phone.value !== currentUser?.profile?.mobilePhone
    );
  }, [phone]);

  useEffect(() => {
    if (sendSMS.status === "validation") {
      setStatus({
        ...status,
        alert: sendSMS.status === "validation",
        message: sendSMS.errors[0].errorMessage,
      });
    } else if (sendSMS.status === "success") {
      dispatch(setOperation("enter_verification_code"));
    }
  }, [sendSMS]);

  const submitHandler = (event) => {
    event.preventDefault();
    setStatus({ ...status, alert: false });
    dispatch(sendVerification({ mobilePhone: phone.prefix + phone.value }));
  };

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAlerts({ ...alerts, [key]: { alert: false } });
    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };

  return (
    <div className="enter-number-form">
      <div className="enter-number-form-description">
        Please enter your phone number.
      </div>
      <Form
        submit={{
          title: "Next",
          color: "#2699fb",
          action: submitHandler,
          className: "submit-button",
        }}
        required={requiredFields}
        className="password-form"
        validate={isFormValid}
        data={data}
      >
        <PhoneSelector
          title="Phone number"
          name="mobilePhone"
          status={status}
          countries={settings.countries}
          currentPrefix={currentPhone.prefix}
          currentValue={currentPhone.value}
          index={0}
          onChange={changeHandler}
          width="100%"
        />
      </Form>
    </div>
  );
}

export default EnterNumberForm;
