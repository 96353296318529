import moment from "moment";
import { useEffect, useState } from "react";
import { FcFolder } from "react-icons/fc";
import { MdDelete, MdInsertDriveFile, MdRefresh } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { usePrivileges } from "../../../../../../helper/AccountStateProvider";
import { useTheme } from "../../../../../../helper/ThemeProvider";
import { FormatBytes } from "../../../../../../helper/utils";
import AppTable from "../../../../../../ui/AppTable/AppTable";
import DateTimeComponent from "../../../../../../ui/DateTimeComponent/DateTimeComponent";
import Dialog from "../../../../../../ui/Dialog/Dialog";
import { ImageFileIcon, VideoFileIcon } from "../../../../../../ui/Icons/Icons";
import LabeledButton from "../../../../../../ui/LabeledButton/LabeledButton";
import LabeledIcon from "../../../../../../ui/LabeledIcon/LabeledIcon";
import SearchOption from "../../../../../../ui/SearchOption/SearchOption";
import ApplicationComponentContent from "../../../../Components/ApplicationComponentContent/ApplicationComponentContent";
import ContainerBlobInformation from "../ContainerBlobInformation/ContainerBlobInformation";
import {
  deleteBlobs,
  getBlobs,
  setBlob,
  remove,
} from "../StorageContainerAppSlice";
import "./ContainerFileManagement.sass";

function ContainerFileManagement() {
  const [operationHandler, setOperationHandler] = useState(false);
  const [operationHandlerOpen, setOperationHandlerOpen] = useState(false);
  const [isMultiSelect, setIsMultiSelect] = useState(true);
  const dispatch = useDispatch();
  const [selectedFeature, setSelectedFeature] = useState({});
  const [permissions, setPermissions] = useState([]);
  const { privileges } = usePrivileges();
  const container = useSelector((state) => state.containerApp);
  const [currentContainer, setCurrentContainer] = useState();
  const [prefixes, setPrefixes] = useState([]);
  const { currentContext } = useTheme();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (currentContainer !== undefined) {
      if (prefixes.length > 0)
        dispatch(
          getBlobs(
            currentContainer?.storageId,
            currentContainer?.id,
            prefixes[prefixes.length - 1]?.value
          )
        );
      else
        dispatch(
          getBlobs(currentContainer?.storageId, currentContainer?.id, "")
        );
    }
  }, [currentContainer, prefixes]);

  useEffect(() => {
    if (container?.model !== undefined) {
      setPrefixes([{ path: container?.model.containerName, value: "" }]);
      setCurrentContainer({ ...container?.model });
    }
  }, [container.model]);

  useEffect(() => {
    if (container.remove?.status === "success") {
      dispatch(remove({ status: "" }));
      refreshFiles();
    }
  }, [container.remove]);

  const [tableRows, setTableRows] = useState({
    headers: [
      { title: "Name", value: "brand", status: true },
      { title: "Type", value: "type", status: true },
      { title: "Size", value: "size", status: true },
      { title: "Data Modified", value: "dateModified", status: true },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    if (privileges.applications !== undefined) {
      if (
        privileges.applications.filter((item) => item.id === "groups")[0] !==
        undefined
      )
        setPermissions(
          [
            ...privileges.applications.filter((item) => item.id === "groups")[0]
              ?.privileges,
          ].concat([
            ...privileges.applications.filter(
              (item) => item.id === "container"
            )[0]?.privileges,
          ])
        );
    }
  }, [privileges.applications]);

  useEffect(() => {
    if (container?.blobs?.data?.blobs !== undefined) {
      if (!container?.blobs?.data?.isRoot)
        setTableRows({
          ...tableRows,
          values: [
            {
              id: "root",
              brand: (
                <LabeledIcon
                  color="#878787"
                  textColor="#455060"
                  icon={<FcFolder size="20" />}
                  label={"[..]"}
                />
              ),
              back: true,
            },
            ...container?.blobs?.data?.blobs
              .filter((blob) => blob.isPrefix)
              .map((item) => ({
                id: item.prefix,
                brand: (
                  <LabeledIcon
                    color="#878787"
                    textColor="#455060"
                    icon={<FcFolder size="20" />}
                    label={item.folder}
                  />
                ),
                folder: item.folder,
                type: "Folder",
                prefix: item.prefix,
              })),
            ...container?.blobs?.data?.blobs
              .filter((blob) => blob.isBlob)
              .map((item) => ({
                id: item?.blob?.name,
                name: item?.blob?.name,
                link: item?.blob?.properties?.link,
                brand: (
                  <LabeledIcon
                    color="#878787"
                    textColor="#455060"
                    icon={
                      item?.blob?.properties?.contentType.includes("image") ? (
                        <ImageFileIcon
                          type={
                            item?.blob?.properties?.contentType.split("/")[1]
                          }
                          size="2"
                        />
                      ) : (
                        <VideoFileIcon
                          type={
                            item?.blob?.properties?.contentType.split("/")[1]
                          }
                          size="2"
                        />
                      )
                    }
                    label={item?.blob?.name}
                  />
                ),
                type: item?.blob?.properties?.contentType,
                size: FormatBytes(item?.blob?.properties?.contentLength, 1),
                dateModified: (
                  <DateTimeComponent
                    date={moment(item?.blob?.properties?.lastModified).format(
                      "DD MMMM YYYY"
                    )}
                    time={moment(item?.blob?.properties?.lastModified).format(
                      "hh:mm A"
                    )}
                    hour={parseInt(
                      moment(item?.blob?.properties?.lastModified).format("h")
                    )}
                  />
                ),
                date: moment(item?.blob?.properties?.lastModified).format(
                  "ddd , DD MMMM YYYY hh:mm A"
                ),
                isBlob: true,
              })),
          ],
        });
      else
        setTableRows({
          ...tableRows,
          values: [
            ...container?.blobs?.data?.blobs
              .filter((blob) => blob.isPrefix)
              .map((item) => ({
                id: item.prefix,
                brand: (
                  <LabeledIcon
                    color="#878787"
                    textColor="#455060"
                    icon={<FcFolder size="20" />}
                    label={item.folder}
                  />
                ),
                folder: item.folder,
                type: "Folder",
                prefix: item.prefix,
              })),
            ...container?.blobs?.data?.blobs
              .filter((blob) => blob.isBlob)
              .map((item) => ({
                id: item?.blob?.name,
                name: item?.blob?.name,
                link: item?.blob?.properties?.link,
                brand: (
                  <LabeledIcon
                    color="#878787"
                    textColor="#455060"
                    icon={(() => {
                      const extenstion = item?.blob?.name?.split('.')?.slice(-1)[0];

                      if (['jpg', 'jpeg', 'png'].includes(extenstion)) return (
                        <ImageFileIcon
                          type={extenstion}
                          size="2"
                        />
                      );

                      if (['mp4', 'avi'].includes(extenstion)) return (
                        <VideoFileIcon
                          type={extenstion}
                          size="2"
                        />
                      );

                      return (
                        <MdInsertDriveFile color="#b8b8b8" size="2em" />
                      )
                    })()}
                    label={item?.blob?.name}
                  />
                ),
                type: item?.blob?.properties?.contentType,
                size: FormatBytes(item?.blob?.properties?.contentLength, 1),
                dateModified: (
                  <DateTimeComponent
                    date={moment(item?.blob?.properties?.lastModified).format(
                      "DD MMMM YYYY"
                    )}
                    time={moment(item?.blob?.properties?.lastModified).format(
                      "hh:mm A"
                    )}
                    hour={parseInt(
                      moment(item?.blob?.properties?.lastModified).format("h")
                    )}
                  />
                ),
                date: moment(item?.blob?.properties?.lastModified).format(
                  "ddd , DD MMMM YYYY hh:mm A"
                ),
                isBlob: true,
              })),
          ],
        });
    }
  }, [container?.blobs]);

  const toggleOperationHandlerOn = () => {
    setOperationHandler(true);
    setOperationHandlerOpen(true);
    setIsMultiSelect(false);
  };

  const toggleOperationHandlerOff = () => {
    setOperationHandler(false);
    setIsMultiSelect(true);
  };

  const refreshFiles = () => {
    toggleOperationHandlerOff();
    if (currentContainer !== undefined) {
      dispatch(
        getBlobs(
          currentContainer.storageId,
          currentContainer.id,
          prefixes[prefixes.length - 1].value
        )
      );
    }
  };

  const deleteFiles = () => {
    toggleOperationHandlerOff();
    setShowDialog(true);
  };

  const features = [
    {
      id: "file_information",
      title: "Overview",
      steps: [
        {
          name: "file_information",
          active: true,
          visible: true,
          locked: false,
          closable: false,
          noBorder: true,
          tags: ["containerApp/blob"],
          activity: <ContainerBlobInformation />,
        },
      ],
    },
    {
      id: "folder_information",
      title: "Folder Overview",
      description: "Folder Information",
      steps: [
        {
          name: "container_folder_info",
          title: "Folder",
          active: true,
          visible: true,
          locked: false,
          tags: ["containerApp/blob"],
          activity: <span>Folder Information</span>,
        },
      ],
    },
  ];
  const filterIndexes = (index) => {
    const allPrefixes = [...prefixes];
    allPrefixes.splice(index);
    setPrefixes([...allPrefixes]);
  };
  const rowSelectHandler = (rows) => {
    setSelectedFiles([...rows]);
  };
  const CloseDialogHandler = () => {
    setShowDialog(false);
  };

  const ConfirmDialogHandler = () => {
    const prefix = prefixes.slice(-1)[0]?.value;
    const files = selectedFiles.map((file) => prefix + file.name);
    dispatch(
      deleteBlobs(currentContainer?.storageId, currentContainer?.id, { files })
    );
    setShowDialog(false);
    setAppControls([
      ...controls.map((control) => ({
        ...control,
        component: {
          ...control.component,
          props: {
            ...control.component.props,
            disabled:
              !selectedFiles.length > 0 &&
              control.name === "delete_container_files",
          },
        },
        active: permissions.includes(control.name) && control.dependent,
      })),
    ]);
  };

  const rowChangeHandler = (row) => {
    if (row !== undefined) {
      if (row.isBlob) {
        dispatch(setBlob({ ...row }));
        toggleOperationHandlerOn();
        setSelectedFeature({
          ...features.find((x) => x.id === "file_information"),
        });
      } else {
        if (currentContainer !== undefined) {
          if (!row.back) {
            const newPrefix = { path: `/${row.folder}`, value: row.prefix };
            if (!prefixes.find(item => JSON.stringify(newPrefix) === JSON.stringify(item))) {
              setPrefixes([
                ...prefixes,
                newPrefix,
              ]);
            }
          }
          else {
            filterIndexes(prefixes.length - 1);
          }
        }
      }
    }
  };

  const rowsChangeHandler = (rows) => {
    if (rows !== undefined) {
      // console.log(rows);
    }
  };
  const optionHandler = (name) => {
    if (name !== undefined) {
      // console.log(name);
    }
  };
  const [appControls, setAppControls] = useState([]);

  const controls = [
    {
      name: "list_container_blobs",
      active: true,
      dependent: true,
      component: (
        <LabeledButton
          title="Refresh"
          icon={<MdRefresh size="15" style={{ marginLeft: "-0.15em" }} />}
          onClick={refreshFiles}
        />
      ),
    },
    {
      name: "delete_container_files",
      active: true,
      dependent: true,
      component: (
        <LabeledButton
          title="Delete"
          icon={<MdDelete size="15" style={{ marginLeft: "-0.15em" }} />}
          onClick={deleteFiles}
          disabled={true}
        />
      ),
    },
  ];

  useEffect(() => {
    setAppControls([
      ...controls.filter(
        (af) => !af.dependent || (af.dependent && permissions.includes(af.name))
      ),
    ]);
  }, [permissions]);

  useEffect(() => {
    setAppControls([
      ...controls.map((control) => ({
        ...control,
        component: {
          ...control.component,
          props: {
            ...control.component.props,
            disabled:
              !selectedFiles.length > 0 &&
              control.name === "delete_container_files",
          },
        },
        active: permissions.includes(control.name) && control.dependent,
      })),
    ]);
  }, [permissions, selectedFiles]);

  return (
    <ApplicationComponentContent
      controls={appControls}
      feature={selectedFeature}
      toggleOperation={operationHandler}
      onCloseOperationManager={toggleOperationHandlerOff}
    >
      <div className="container-file-management-header">
        <h4>File Manager</h4>
        <div className="container-file-management-header-info">
          <p>All files and folders of this container listed in table below.</p>
          <SearchOption />
        </div>
        <ul className="container-file-management-header-path">
          <span>Location : </span>
          {prefixes.map((prefix, index) => (
            <li
              key={index}
              style={{
                cursor: index < prefixes.length - 1 ? "pointer" : "initial",
                color:
                  index < prefixes.length - 1
                    ? currentContext.theme.color
                    : "black",
              }}
              onClick={() => {
                if (index < prefixes.length - 1) filterIndexes(index + 1);
              }}
            >
              {prefix.path}
            </li>
          ))}
        </ul>
      </div>
      <div className="container-file-management-body">
        <AppTable
          rows={tableRows}
          onRowChange={rowChangeHandler}
          onRowsChange={rowsChangeHandler}
          optionChangeHandler={optionHandler}
          tags={["containerApp/blobs", "containerApp/remove"]}
          selectable
          multiSelect={isMultiSelect}
          boldIndexes={[]}
          onRowSelect={rowSelectHandler}
        />
      </div>
      {showDialog && (
        <Dialog
          model={{
            message: "are you sure to delete selected files?",
            CloseHandler: CloseDialogHandler,
            ConfirmHandler: ConfirmDialogHandler,
          }}
        />
      )}
    </ApplicationComponentContent>
  );
}

export default ContainerFileManagement;
