/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./MultiSelect.sass";
import SmallAlert from "../SmallAlert/SmallAlert";
import down_icon from "./../../assets/img/arrow-down.svg";
import up_icon from "./../../assets/img/arrow-up.svg";
import tick from "../../assets/img/tick.svg";
import OutSideDetector from "../OutsideDetector/OutSideDetector";

export default function MultiSelect({
  items,
  title,
  isRequired,
  name,
  placeholder,
  onChange,
  value,
  status,
}) {
  const [multiSelect, setMultiSelect] = useState({ open: false });
  const [selected, setSelected] = useState([]);
  const [values, setValues] = useState([]);
  const [selectItems, setSelectItems] = useState(items);
  const [val] = useState(value !== undefined ? value : []);

  const toggle = (event) => {
    event.stopPropagation();
    setMultiSelect({ ...multiSelect, open: !multiSelect.open });
  };
  const toggleOff = (event) => {
    event.stopPropagation();
    setMultiSelect({ ...multiSelect, open: false });
  };
  const toggleOn = (event) => {
    event.stopPropagation();
    setMultiSelect({ ...multiSelect, open: true });
  };

  useEffect(() => {
    const input = document.querySelector(".multi-select-box-input");
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;
    nativeInputValueSetter.call(input, values);
    var ev2 = new Event("input", { bubbles: true });
    input.dispatchEvent(ev2);
  }, [values]);

  useEffect(() => {
    setSelected([...val]);
    setSelectItems(
      selectItems.map((item) => ({
        ...item,
        selected: val.includes(item.value),
      }))
    );
  }, [val]);

  useEffect(() => {
    setValues([...selected]);
  }, [selected]);

  const toggleSelectBox = (name) => {
    if (selected.includes(name)) {
      removeItem(name)
    } else {
      addItem(name);
    }
  };

  const removeItem = (name) => {
    setSelected([...selected.filter((item) => item !== name)]);
      setSelectItems(
        selectItems.map((item) => ({
          ...item,
          selected: item.name === name ? false : item.selected,
        }))
      );
  };

  const addItem = (name)=>{
    setSelected([...selected, name]);
      setSelectItems(
        selectItems.map((item) => ({
          ...item,
          selected: item.name === name ? true : item.selected,
        }))
      );
  }
  
  return (
    <OutSideDetector
      className="multi-select-box"
      detect={() => {
        setMultiSelect({ ...multiSelect, open: false, name: name });
      }}
    >
      <label htmlFor="input">
        {title} {isRequired ? "*" : ""}
      </label>
      <img
        className="multi-select-box-arrow"
        src={multiSelect.open ? up_icon : down_icon}
        alt="down"
        onClick={toggle}
      />

      <div
        onClick={toggleOn}
        className={`multi-select-items ${
          multiSelect.open
            ? "open"
            : "" || (status !== undefined && status.alert === true)
            ? "multi-select-items-" + status.type
            : ""
        }`}
      >
        {values.length === 0 && (
          <span className="multi-select-place-holder">{placeholder}</span>
        )}
        {values.map((item, index) => (
          <div key={index} className="multi-select-item">
            <span className="multi-select-item-text">{item}</span>
            <span
              className="multi-select-item-close"
              onClick={() => removeItem(item)}
            >
              &#10005;
            </span>
          </div>
        ))}
      </div>
      {multiSelect.open && (
        <div className="multi-select-box-items-container">
          <span className="multi-select-item-close" onClick={toggleOff}>
            &#10005;
          </span>
          <ul className="multi-select-box-items">
            {selectItems.map((item) => (
              <li
                className={`${
                  item.selected === true ? "multi-select-box-item-active" : ""
                }`}
                key={item.id}
                onClick={() => toggleSelectBox(item.value)}
              >
                {item.selected === true && <img src={tick} alt="tick" />}

                <span> {item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      <input
        className="multi-select-box-input"
        type="hidden"
        name={name}
        id={name}
        onInput={onChange}
        readOnly
      />
      {status !== undefined && status.alert && (
        <SmallAlert
          alert={status.alert}
          alertType={status.type}
          message={status.message}
          hideIcon={true}
        />
      )}
    </OutSideDetector>
  );
}
