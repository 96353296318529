import "./ApplicationsArea.sass";
import { Route, Switch, useRouteMatch } from "react-router";
import MissionApp from "./MissionApp/MissionApp";
import DashboardHome from "./DashboardHome/DashboardHome";
import DeviceApp from "./DeviceApp/DeviceApp";
import Portrait from "../../../ui/Portrait/Portrait";
import StorageApp from "./StorageApp/StorageApp";
import DesktopSize from "src/ui/DesktopSize/DesktopSize";
import AppSideBar from "src/components/AppSideBar/AppSideBar";
import UserProfileOptions from "./UserProfileOptions/UserProfileOptions";
import MobileProfileNavigation from "src/components/ProfileNavigation/MobileProfileNavigation/MobileProfileNavigation";
import AppLauncher from "src/hci/organism/AppLauncher/AppLauncher";
import SecurityApp from "./SecurityApp/SecurityApp";
import ExploreApp from "./ExploreApp/ExploreApp"
import ServiceRunner from "src/services/common/ServiceRunner";

function ApplicationsArea({ openProfile, onProfileClose }) {
  let { path } = useRouteMatch();
  
  return (
    <div className="application-area">
      <ServiceRunner />
      <DesktopSize>
        <AppLauncher />
      </DesktopSize>
      {/* <DesktopProfileNavigation /> */}
      <Switch>
        <Route exact path={path}>
          <Portrait>
            <DashboardHome />
          </Portrait>
        </Route>
        <Route path={`${path}/profile`}>
          <Portrait>
            <UserProfileOptions />
          </Portrait>
        </Route>
        <Route path={`${path}/device-app`}>
          <DeviceApp />
        </Route>
        <Route path={`${path}/security-app`}>
          <SecurityApp />
        </Route>
        <Route path={`${path}/explore-app`}>
          <ExploreApp />
        </Route>
        <Route path={`${path}/storage-app`}>
          <StorageApp />
        </Route>
      </Switch>
      {openProfile && (
        <MobileProfileNavigation isOpen={openProfile} toggle={onProfileClose} />
      )}
    </div>
  );
}

export default ApplicationsArea;
