const psdkHmsMessages = [
  ['0x16070035', "Aircraft D-RTK antenna error. Fly with caution", ""],
  ['0x16070034', "RTK flight heading inconsistent with other sources. Fly with caution", ""],
  ['0x16070033', "D-RTK mobile station moved. Check mobile station and restart aircraft", ""],
  ['0x16070032', "D-RTK mobile station detached. Check mobile station and restart aircraft", ""],
  ['0x16070031', "D-RTK mobile station switched signal source. Restart aircraft", ""],
  ['0x16070030', "RTK firmware version does not match. Update required", ""],
  ['0x16070029', "RTK position data changed slightly while aircraft was stationary. Restart aircraft", ""],
  ['0x16070028', "RTK and GPS positions do not match. Restart aircraft", ""],
  ['0x16070027', "RTK and sensor altitude data inconsistent. Restart aircraft", ""],
  ['0x16070026', "Sudden RTK position data change. Restart aircraft", ""],
  ['0x16070025', "Unable to refresh RTK position data. Restart aircraft", ""],
  ['0x16070024', "RTK data error. Fly with caution", ""],
  ['0x16070023', "RTK data refresh frequency error. Fly with caution", ""],
  ['0x16070021', "RTK data error. Restart aircraft", ""],
  ['0x16070020', "RTK data not received by aircraft. Restart aircraft", ""],
  ['0x1a010040', "Downward-left vision sensor connection error", ""],
  ['0x1a010041', "Downward-right vision sensor connection error", ""],
  ['0x1a010042', "Forward-left vision sensor connection error", ""],
  ['0x1a010043', "Forward-right vision sensor connection error", ""],
  ['0x1a010044', "Backward-left vision sensor connection error", ""],
  ['0x1a010045', "Backward-right vision sensor connection error", ""],
  ['0x1a010046', "Upward-left vision sensor connection error", ""],
  ['0x1a010047', "Upward-right vision sensor connection error", ""],
  ['0x1a010048', "Left-rear vision sensor connection error. Fly with caution", ""],
  ['0x1a010049', "Left-front vision sensor connection error. Fly with caution", ""],
  ['0x1a01004a', "Right-front vision sensor connection error. Fly with caution", ""],
  ['0x1a01004b', "Right-rear vision sensor connection error. Fly with caution", ""],
  ['0x1a010080', "Downward-left vision sensor connection error ", ""],
  ['0x1a010081', "Downward-right vision sensor connection error", ""],
  ['0x1a010082', "Forward-left vision sensor connection error ", ""],
  ['0x1a010083', "Forward-right vision sensor connection error ", ""],
  ['0x1a010084', "Backward-left vision sensor connection error ", ""],
  ['0x1a010085', "Backward-right vision sensor connection error", ""],
  ['0x1a010086', "Upward-left vision sensor connection error ", ""],
  ['0x1a010087', "Upward-right vision sensor connection error", ""],
  ['0x1a010088', "Left-rear vision sensor connection error. Fly with caution", ""],
  ['0x1a010089', "Left-front vision sensor connection error. Fly with caution", ""],
  ['0x1a01008a', "Right-front vision sensor connection error. Fly with caution", ""],
  ['0x1a01008b', "Right-rear vision sensor connection error. Fly with caution", ""],
  ['0x1a0100c0', "Downward-left vision sensor connection error ", ""],
  ['0x1a0100c1', "Downward-right vision sensor connection error", ""],
  ['0x1a0100c2', "Forward-left vision sensor connection error ", ""],
  ['0x1a0100c3', "Forward-right vision sensor connection error ", ""],
  ['0x1a0100c4', "Backward-left vision sensor connection error ", ""],
  ['0x1a0100c5', "Backward-right vision sensor connection error", ""],
  ['0x1a0100c6', "Upward-left vision sensor connection error ", ""],
  ['0x1a0100c7', "Upward-right vision sensor connection error", ""],
  ['0x1a0100c8', "Left-rear vision sensor connection error. Fly with caution", ""],
  ['0x1a0100c9', "Left-front vision sensor connection error. Fly with caution", ""],
  ['0x1a0100ca', "Right-front vision sensor connection error. Fly with caution", ""],
  ['0x1a0100cb', "Right-rear vision sensor connection error. Fly with caution", ""],
  ['0x1a020040', "Downward infrared sensor connection error", ""],
  ['0x1a020041', "Forward infrared sensor connection error", ""],
  ['0x1a020042', "Right infrared sensor connection error", ""],
  ['0x1a020043', "Backward infrared sensor connection error", ""],
  ['0x1a020044', "Left infrared sensor connection error", ""],
  ['0x1a020045', "Upward infrared sensor connection error", ""],
  ['0x1a020080', "Downward Infrared Sensor Connection Error", ""],
  ['0x1a020081', "Forward infrared sensor connection error", ""],
  ['0x1a020082', "Right infrared sensor connection error", ""],
  ['0x1a020083', "Backward infrared sensor connection error", ""],
  ['0x1a020084', "Left infrared sensor connection error", ""],
  ['0x1a020085', "Upward infrared sensor connection error", ""],
  ['0x1a0200c0', "Downward Infrared Sensor Connection Error", ""],
  ['0x1a0200c1', "Forward infrared sensor connection error", ""],
  ['0x1a0200c2', "Right infrared sensor connection error", ""],
  ['0x1a0200c3', "Backward infrared sensor connection error", ""],
  ['0x1a0200c4', "Left infrared sensor connection error", ""],
  ['0x1a0200c5', "Upward infrared sensor connection error", ""],
  ['0x1a020100', "Downward infrared sensor calibration error", ""],
  ['0x1a020101', "Forward infrared sensor calibration error", ""],
  ['0x1a020102', "Right infrared sensor calibration error", ""],
  ['0x1a020103', "Backward infrared sensor calibration error", ""],
  ['0x1a020104', "Left infrared sensor calibration error", ""],
  ['0x1a020105', "Upward infrared sensor calibration error", ""],
  ['0x1a020140', "Infrared sensors blocked", ""],
  ['0x1a310980', "Vision positioning system error", ""],
  ['0x1a310981', "Vision positioning system error", ""],
  ['0x1a420040', "Downward obstacle sensing system error", ""],
  ['0x1a420041', "Forward obstacle sensing system error", ""],
  ['0x1a420042', "Right obstacle sensing system error", ""],
  ['0x1a420043', "Backward obstacle sensing system error", ""],
  ['0x1a420044', "Left obstacle sensing system error", ""],
  ['0x1a420045', "Upward obstacle sensing system error", ""],
  ['0x1a420440', "Obstacle sensing system error", ""],
  ['0x1a4205c0', "Obstacle sensing system error", ""],
  ['0x1a420680', "Obstacle sensing system error", ""],
  ['0x1a510380', "Downward vision sensor calibration error", ""],
  ['0x1a510381', "Forward vision sensor calibration error", ""],
  ['0x1a510382', "Backward vision sensor calibration error", ""],
  ['0x1a510383', "Upward vision sensor calibration error", ""],
  ['0x1a510384', "Left vision sensor calibration error", ""],
  ['0x1a510385', "Right vision sensor calibration error", ""],
  ['0x1a5103c0', "Downward vision sensor calibration error", ""],
  ['0x1a5103c1', "Forward vision sensor calibration error", ""],
  ['0x1a5103c2', "Backward vision sensor calibration error", ""],
  ['0x1a5103c3', "Upward vision sensor calibration error", ""],
  ['0x1a5103c4', "Left vision sensor calibration error", ""],
  ['0x1a5103c5', "Right vision sensor calibration error", ""],
  ['0x1a020180', "Infrared sensors overheated. Return to home or land promptly. Move away from high-temperature environment", ""],
  ['0x1a420bc0', "Downward ambient light too low. Obstacle avoidance unavailable. Fly with caution", ""],
  ['0x1a420bc1', "Forward ambient light too low. Obstacle avoidance unavailable. Fly with caution", ""],
  ['0x1a420bc2', "Backward ambient light too low. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420bc3', "Rightward ambient light too low. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420bc4', "Leftward ambient light too low. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420bc5', "Upward ambient light too low. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420bc6', "Horizontal ambient light too low. Horizontal obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c00', "Downward ambient light too bright. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c01', "Forward ambient light too bright. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c02', "Backward ambient light too bright. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c03', "Rightward ambient light too bright. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c04', "Leftward ambient light too bright. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c05', "Upward ambient light too bright. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c06', "Horizontal ambient light too bright. Horizontal obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c40', "Downward sensor(s) blocked. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c41', "Forward sensor(s) blocked. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c42', "Backward sensor(s) blocked. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c43', "Rightward sensor(s) blocked. Rightward obstacle avoidance unavailable. Only infrared sensors available.  Fly with caution", ""],
  ['0x1a420c44', "Leftward sensor(s) blocked. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c45', "Upward sensor(s) blocked. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c80', "Downward sensor(s) blurry. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c81', "Forward sensor(s) blurry. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c82', "Backward sensor(s) blurry. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c83', "Right sensor(s) blurry. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c84', "Left sensor(s) blurry. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420c85', "Upward sensor(s) blurry. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution", ""],
  ['0x1a420cc0', "Aircraft attitude angle too large. Obstacle avoidance unavailable. Fly with caution", ""],
  ['0x1a420d00', "Aircraft attitude angle too large. Landing protection unavailable. Land manually", ""],
  ['0x1a420d40', "Aircraft approaching obstacle sensing blind spot and may be unable to detect obstacles. Fly with caution", ""],
  ['0x1afe0040', "Vision system overloaded. Fly to open area", ""],
  ['0x11000020', "Gimbal %index payload voltage too high", ""],
  ['0x11000021', "Gimbal %index payload voltage too low", ""],
  ['0x11000022', "Gimbal %index battery module overheated", ""],
  ['0x11000023', "Gimbal %index battery module temperature too low", ""],
  ['0x11000024', "Total payload power too high", ""],
  ['0x11000025', "Gimbal %index payload transmission error", ""],
  ['0x11000029', "OSDK voltage too high", ""],
  ['0x1100002a', "OSDK voltage too low", ""],
  ['0x1100002b', "OSDK battery module overheated", ""],
  ['0x1100002c', "OSDK battery module temperature too low", ""],
  ['0x1100002d', "Total payload power too high", ""],
  ['0x16010001', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16010005', "Sensor system initializing. Wait for initialization to complete", "Sensor system error. Return to home or land"],
  ['0x16010007', "Sensor system error. Land promptly", "Sensor system error. Return to home or land"],
  ['0x1601000a', "Sensor system initializing. Wait for initialization to complete", "Sensor system error. Return to home or land"],
  ['0x1601000d', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16010010', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16010016', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16010019', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16010041', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16010032', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x1601002f', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16010013', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x1601001c', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x1601001f', "Sensor system error. Restart aircraft", "Not Applicable. This string has been deleted"],
  ['0x16010022', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16010025', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16010028', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x1601002b', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x16020001', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16020004', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16020007', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x1602000a', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x1602000d', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16020016', "IMU bias too large. Calibrate IMU", "IMU error. Return to home or land"],
  ['0x1602002a', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16020027', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16030004', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16030007', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x1603000d', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16030016', "IMU bias too large. Calibrate IMU", "IMU error. Return to home or land"],
  ['0x16030001', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16030013', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x1603000a', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16030010', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x1603001c', "IMU error. Restart aircraft", "IMU error. Return to home or land"],
  ['0x16040001', "Barometer error. Restart aircraft", "Barometer error. Return to home or land"],
  ['0x16040004', "Barometer error. Restart aircraft", "Barometer error. Return to home or land"],
  ['0x16040007', "Barometer error. Restart aircraft", "Barometer error. Return to home or land"],
  ['0x1604000a', "Barometer error. Restart aircraft", "Barometer error. Return to home or land"],
  ['0x16040010', "Barometer error. Restart aircraft", "Barometer error. Return to home or land"],
  ['0x16040013', "Barometer error. Restart aircraft", "Barometer error. Return to home or land"],
  ['0x16040016', "Barometer error. Restart aircraft", "Barometer error. Return to home or land"],
  ['0x16040019', "Barometer error. Restart aircraft", "Barometer error. Return to home or land"],
  ['0x16050019', "GPS error. Restart aircraft", "GPS error. Return to home or land"],
  ['0x16050001', "GPS error. Restart aircraft", "GPS error. Return to home or land"],
  ['0x16050004', "GPS error. Restart aircraft", "GPS error. Return to home or land"],
  ['0x1605001c', "GPS error. Restart aircraft", "GPS error. Return to home or land"],
  ['0x16060001', "Compass error. Restart aircraft", "Compass error. Return to home or land"],
  ['0x16060007', "Compass error. Restart aircraft", "Compass error. Return to home or land"],
  ['0x1606000a', "Compass error. Restart aircraft", "Compass error. Return to home or land"],
  ['0x1606000d', "Compass interference. Calibrate compass", "Compass interference. Move aircraft away from interference source"],
  ['0x16060010', "Compass interference. Calibrate compass", "Compass interference. Move aircraft away from interference source"],
  ['0x16100001', "Compass error. Unable to take off. Restart aircraft", "Compass error. Return to home or land"],
  ['0x16100002', "DJI Assistant connected. Unable to take off. Disconnect before taking off", "DJI Assistant connected. Disconnect before taking off"],
  ['0x16100003', "Aircraft not activated or firmware out-of-date. Unable to take off. Activate aircraft or update to latest firmware version", "Device activation or firmware update required"],
  ['0x16100006', "Invalid IMU serial number. Unable to take off. Maintenance required", "Invalid IMU serial number. Return to home or land"],
  ['0x16100008', "Compass calibrating. Unable to take off. Wait for calibration to complete before taking off(%alarmid)", "Compass calibrating. Wait for calibration to complete before taking off"],
  ['0x16100009', "Sensor system initializing. Unable to take off. Wait for initialization to complete before taking off", "Sensor system initializing. Return to home or land"],
  ['0x1610000a', "Aircraft in Beginner mode. Unable to take off. Take off in an open outdoor area when in Beginner mode", "Beginner mode enabled. When using Beginner mode', take off in an open', outdoor area"],
  ['0x1610000b', "Battery cell error. Unable to take off. Contact DJI Support", "Battery cell error. Return to home or land"],
  ['0x1610000c', "Battery communication error. Unable to take off. Reinstall battery", "Battery communication error. Return to home"],
  ['0x1610000d', "Critical low battery voltage. Unable to take off. Charge promptly", "Critical low battery voltage. Return to home or land promptly"],
  ['0x1610000e', "Critical low battery. Unable to take off. Charge promptly", "Critical low battery. Return to home or land promptly"],
  ['0x1610000f', "Critical low battery voltage. Unable to take off. Charge promptly", "Critical low battery voltage. Return to home or land promptly"],
  ['0x16100010', "Battery output power insufficient. Unable to take off. Charge promptly", "Battery power output insufficient. Return to home or land"],
  ['0x16100011', "Critical low battery. Unable to take off. Charge promptly", "Critical low battery. Return to home or land promptly"],
  ['0x16100012', "Battery initializing. Unable to take off. Wait for initialization to complete before taking off", "Battery initialization error. Return to home or land"],
  ['0x16100013', "Running Flight Simulator. Unable to take off. Restart aircraft before taking off", "Running Flight Simulator. Restart aircraft before taking off"],
  ['0x16100015', "Aircraft pitch angle too large. Unable to take off. Ensure aircraft is level before taking off", "Aircraft pitch angle too large. Ensure aircraft is level before taking off"],
  ['0x16100016', "Aircraft not activated. Unable to take off. Restart DJI Pilot and activate aircraft", "Aircraft not activated. Restart DJI Pilot and activate aircraft"],
  ['0x16100017', "Aircraft in GEO Zone. Unable to take off. Check map to find Recommended Zones", "Aircraft in GEO Zone. Check map to find Recommended Zones"],
  ['0x16100018', "IMU error. Unable to take off. Calibrate IMU", "IMU initialization error. Return to home or land"],
  ['0x16100019', "ESC error. Unable to take off. Contact DJI Support", "ESC error. Land immediately"],
  ['0x1610001a', "Sensor system initializing. Unable to take off. Wait for initialization to complete before taking off", "Sensor system initializing. Return to home or land"],
  ['0x1610001b', "System updating. Unable to take off. Wait for update to complete", "System updating. Wait for update to complete before taking off"],
  ['0x1610001c', "Running Flight Simulator. Unable to take off. Restart aircraft before taking off", "Running Flight Simulator. Restart aircraft before taking off"],
  ['0x1610001d', "IMU calibrating. Unable to take off. Wait for calibration to complete before takeoff", "Calibrating IMU. Wait for calibration to complete before taking off"],
  ['0x1610001e', "Aircraft pitch angle too large. Unable to take off. Ensure aircraft is level before taking off", "Aircraft pitch angle too large. Ensure aircraft is level before taking off"],
  ['0x16100029', "Invalid aircraft serial number. Unable to take off. Contact your local dealer or DJI Support", "Invalid aircraft serial number. Return to home or land"],
  ['0x1610002d', "GPS disconnected. Unable to take off. Restart aircraft", "GPS disconnected. Return to home or land"],
  ['0x1610002f', "Data recorder error. Unable to take off. Restart aircraft", "Data recorder error. Return to home or land"],
  ['0x16100030', "Aircraft model and firmware version do not match. Unable to take off. Contact your local dealer or DJI Support.", "Aircraft model and firmware version do not match. Return to home or land"],
  ['0x1610003d', "Sensor system disconnected. Unable to take off. Restart aircraft", "Sensor system disconnected. Return to home or land"],
  ['0x1610004a', "Sensor system error. Unable to take off. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x1610004b', "Sensor system error. Unable to take off. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x1610004d', "Flight controller data error. Unable to take off. Restart aircraft", "Flight controller data error. Return to home or land"],
  ['0x1610004e', "Not enough batteries installed. Unable to take off. Insert two batteries before taking off", "Insufficient batteries. Install two batteries before taking off"],
  ['0x1610004f', "Battery authentication failed. Unable to take off. Replace with DJI battery", "Battery authentication failed. Replace with standard DJI battery"],
  ['0x16100051', "Large voltage difference between batteries. Unable to take off. Replace batteries with new ones of a similar capacity", "Large voltage difference between batteries. Return to home or land. Replace batteries with new ones of a similar capacity and try again"],
  ['0x16100053', "Aircraft module firmware versions do not match. Unable to take off. Update to latest firmware versions.", "Aircraft module firmware versions do not match. Update to latest firmware versions"],
  ['0x16100054', "Gimbal error. Unable to take off. Contact DJI Support", "Gimbal error. Return to home or land"],
  ['0x16100055', "Gimbal error. Unable to take off. Contact DJI Support", "Gimbal error. Return to home or land"],
  ['0x16100056', "Gimbal error. Unable to take off. Contact DJI Support", "Gimbal error. Return to home or land"],
  ['0x16100057', "Gimbal error. Unable to take off. Contact DJI Support", "Gimbal error. Return to home or land"],
  ['0x16100058', "Gimbal firmware updating. Unable to take off...", "Gimbal firmware update in progress"],
  ['0x1610005d', "IMU calibration successful. Unable to take off. Restarting aircraft required", "IMU calibration error. Return to home or land"],
  ['0x1610005e', "Aircraft rolled during takeoff. Unable to take off. Check whether propellers were installed correctly", "Aircraft rolled during takeoff. Check whether propellers are installed correctly"],
  ['0x1610005f', "Motor stalled. Unable to take off. Power off aircraft and check whether motor can rotate freely", "Motor stalled. Land promptly. Power off airfcraft and check whether motor can rotate freely"],
  ['0x16100060', "Motor rotation speed error. Unable to take off. Restart aircraft", "Motor rotation speed error. Land and restart aircraft"],
  ['0x16100061', "Motor idle. Unable to take off. Check whether propellers are detached or installed incorrectly", "Motor idle. Unable to take off. Check whether propellers are detached or installed incorrectly"],
  ['0x16100062', "Unable to turn on motor. Unable to take off. Check aircraft status and restart", "Unable to turn on aircraft motor(s). Check aircraft status and restart"],
  ['0x16100063', "Auto Takeoff failed. Unable to take off", "Auto Takeoff failed"],
  ['0x16100064', "Aircraft rolled over. Unable to take off. Restart aircraft and ensure it is level before taking off", "Aircraft rolled over. Return to home or land"],
  ['0x16100065', "Battery firmware version error. Unable to take off. Replace battery or update battery firmware to latest version", "Battery firmware version error. Replace battery or update to the latest firmware version"],
  ['0x16100066', "RTK signal weak. Unable to take off. Move to an open area for takeoff or turn off RTK", "RTK signal weak. Move to an open area for takeoff or turn off RTK"],
  ['0x16100067', "Compass interference. Unable to take off. Calibrate compass", "Compass interference. Move away from interference source"],
  ['0x16100068', "ESC short-circuited. Unable to take off. Restart aircraft", "ESC short-circuited. Restart aircraft"],
  ['0x16100069', "ESC auto-check error. Unable to take off. Restart aircraft", "ESC auto-check error. Return to home or land"],
  ['0x16100071', "GPS error. Unable to take off. Restart aircraft", "GPS error. Return to home or land"],
  ['0x16100072', "Gimbal calibrating. Unable to take off", "Gimbal calibration error. Return to home or land"],
  ['0x16100073', "Takeoff conditions not met. Unable to take off. Firmware out-of-date or flight route uploading to aircraft", "Firmware out of date or flight route uploading to aircraft. Return to home or land"],
  ['0x16100074', "Takeoff altitude error. Unable to take off. Restart aircraft", "Takeoff altitude error. Restart aircraft"],
  ['0x16100075', "ESC firmware versions do not match. Unable to take off. Update to latest firmware version", "ESC firmware versions do not match. Update to latest firmware versions"],
  ['0x16100076', "IMU error. Unable to take off. Contact DJI Support", "IMU error. Return to home or land"],
  ['0x16100078', "Compass error. Unable to take off. Contact DJI Support", "Compass error. Return to home or land"],
  ['0x1610007a', "ESC beeping. Unable to take off. Restart aircraft before taking off", "ESC beeping. Return to home or land"],
  ['0x1610007b', "ESC overheated. Unable to take off. Power off aircraft and wait for temperature to return to normal", "ESC overheated. Land promptly. Power off aircraft and wait for temperature to return to normal"],
  ['0x1610007d', "Impact detected. Takeoff failed and aircraft landed. Unable to take off. Restart aircraft", "Impact detected. Aircraft landed. Restart aircraft"],
  ['0x1610007f', "Impact detected. Unable to take off. Restart aircraft", "Impact detected. Return to home or land"],
  ['0x16100080', "Aircraft altitude control error. Unable to take off. Restart aircraft", "Aircraft altitude control error. RTH or land"],
  ['0x16100081', "Battery firmware out-of-date. Unable to take off. Update to latest firmware version", "Battery firmware version out of date. Return to home or land. Update to latest firmware version"],
  ['0x16100082', "Large voltage different between battery cells. Unable to take off. Battery maintenance required", "Large difference in battery cell voltage detected. Return to home or land. Battery maintenance required"],
  ['0x16100083', "Battery installed incorrectly. Unable to take off. Turn battery locker to its limit and ensure both batteries are installed correctly", "Battery installed incorrectly. Return to home or land. Turn battery locker to its limit and ensure both batteries are installed correctly"],
  ['0x16100084', "Upper fan error. Unable to take off. Check whether fan is stalled or making strange noises", "Upper fan error. Return to home or land. Check whether fan is stalled or making strange noises"],
  ['0x16100085', "Aircraft overheated. Unable to take off. Power off aircraft and wait for temperature to return to normal", "Aircraft overheated. Return to home or land. Power off aircraft and wait for temperature to return to normal"],
  ['0x16100087', "Emergency Propeller Stop triggered. Unable to take off", "Emergency Propeller Stop triggered"],
  ['0x16100088', " Control sticks not centered. Unable to take off. Ensure control sticks are centered", "Takeoff failed. Control sticks not centered. Ensure control sticks are centered"],
  ['0x16100089', "Security code verification failed. Unable to take off. Re-enter code", "Security code verification failed. Unable to take off. Re-enter verification code"],
  ['0x1610008a', "Flight controller unit error. Unable to take off. Restart aircraft", "Flight controller unit error. Return to home or land"],
  ['0x1610008f', "Aircraft antenna satellite signal searching error. Unable to take off. Move to an open area for takeoff", "Aircraft antenna satellite signal searching error. Fly with caution"],
  ['0x16100091', "Flight prohibited in current area. Unable to take off", "Flying prohibited in current area. Return to home or land"],
  ['0x16100092', "Battery capacity error. Unable to take off. Battery maintenance required", "Battery capacity error. Return to home or land. Battery maintenance required"],
  ['0x16100099', "Gimbal starting error. Unable to take off. Check whether gimbal can rotate freely and restart aircraft", "Gimbal startup error. Return to home or land. Check whether gimbal can rotate freely and restart aircraft"],
  ['0x1610009a', "Excessive gimbal vibration. Unable to take off. Check whether gimbal can rotate freely or is damaged. Restart aircraft", "Excessive gimbal vibration. Return to home or land. Check whether gimbal can rotate freely or is damaged. Restart aircraft"],
  ['0x1610009f', "Flight controller unit error. Unable to take off. Restart aircraft", "Flight controller unit error. Return to home or land"],
  ['0x161000c8', "Unable to take off. Check whether aircraft is connected to DJI Assistant or system is updating", "Check whether aircraft is connected to DJI Assistant or system is updating"],
  ['0x161000c9', "Unable to take off. Check whether aircraft is connected to DJI Assistant or system is updating", "Check whether aircraft is connected to DJI Assistant or system is updating"],
  ['0x16000001', "Flight controller overloaded. Unable to take off. Restart aircraft", "Flight controller overloaded. If the issue persists', land aircraft promptly"],
  ['0x16100090', "Sensor system error. Restart aircraft", "Sensor system error. Return to home or land"],
  ['0x161000a1', "Unable to take off. Check whether frame arm sleeves are tightened securely", "Land promptly and check whether frame arm sleeves are tightened securely"],
  ['0x161000a2', "Lower fan error. Unable to take off. Check whether fan is stalled or making strange noises", "Lower fan error. Land promptly and check whether fan is stalled or making strange noises"],
  ['0x161000a6', "Remote controller battery low. Unable to take off. Recharge battery", "Remote controller battery low. Return to home or land promptly"],
  ['0x16000002', "Flight data record abnorma.If the warning persists', contact your local dealer or DJI Support.", "Flight data record abnorma.If the warning persists', contact your local dealer or DJI Support."],
  ['0x1d010001', "Gimbal stuck", ""],
  ['0x1d010002', "Gimbal auto-check failed", ""],
  ['0x1d010003', "Gimbal motor overloaded", ""],
  ['0x1d020001', "Gimbal calibration error", ""],
  ['0x1d030001', "Gimbal unable to retrieve aircraft data", ""],
  ['0x1d040001', "Excessive gimbal vibration", ""],
  ['0x1d040002', "Gimbal sensor error", ""],
  ['0x1d040004', "Gimbal ESC malfunctioned", ""],
  ['0x1d040003', "Gimbal ESC malfunctioned", ""],
  ['0x1d040005', "Gimbal ESC malfunctioned", ""],
  ['0x1d050003', "Gimbal %component_index pan axis endpoint reached", ""],
  ['0x1d050002', "Gimbal %component_index pitch axis endpoint reached", ""],
  ['0x1d050001', "Gimbal %component_index roll axis endpoint reached", ""],
  ['0x1d001001', "Gimbal unresponsive after power on", ""],
  ['0x1d100002', "Gimbal unresponsive after power on", ""],
  ['0x1d100006', "Gimbal unresponsive after power on", ""],
  ['0x1d001101', "Gimbal unable to complete auto check", ""],
  ['0x1d110002', "Gimbal unable to complete auto check", ""],
  ['0x1d110005', "Gimbal unable to complete auto check", ""],
  ['0x1d120001', "Error detected during gimbal auto check", ""],
  ['0x1d120002', "Error detected during gimbal auto check", ""],
  ['0x1d120004', "Error detected during gimbal auto check", ""],
  ['0x1d120006', "Error detected during gimbal auto check", ""],
  ['0x1d130003', "Excessive gimbal vibration", ""],
  ['0x1d130005', "Excessive gimbal vibration", ""],
  ['0x1d13000a', "Excessive gimbal vibration", ""],
  ['0x1d140001', "Gimbal motor overloaded", ""],
  ['0x1d150002', "Gimbal drifting", ""],
  ['0x1d150003', "Gimbal drifting", ""],
  ['0x1d150004', "Gimbal drifting", ""],
  ['0x1d150005', "Gimbal drifting", ""],
  ['0x1d160004', "Camera tilted", ""],
  ['0x1d170001', "Gimbal error", ""],
  ['0x1d180001', "Gimbal calibration data error", ""],
  ['0x1d190002', "Gimbal update failed", ""],
  ['0x1d010003', "Gimbal motor overloaded", ""],
  ['0x1d050a01', "Gimbal voltage too low", ""],
  ['0x1d05030f', "Gimbal rotation count approaching max limit", ""],
  ['0x1d050301', "Gimbal operation retry attempts limit exceeded", ""],
  ['0x1d050302', "Camera exposure error", ""],
  ['0x1d050a02', "Gimbal time synchronization error", ""],
  ['0x110b0001', "Battery %index overcurrent. Check for heavy payload and reduce excessive motion during flight", ""],
  ['0x110b0002', "Battery %index overheated. Return to home promptly and wait for battery temperature to return to normal before use", ""],
  ['0x110b0003', "Battery %index temperature too low. Warm up battery to 5°C or higher", ""],
  ['0x110b0006', "Battery %index cell damaged. Stop using this battery and contact DJI Support", ""],
  ['0x110b0011', "Battery %index data communication error. Reinstall battery. If the issue persists', replace battery", ""],
  ['0x110b0004', "Battery %index short-circuited during discharge. Replace battery", ""],
  ['0x110b0005', "Battery %index cell voltage low. Replace battery", ""],
  ['0x110b0012', "Battery %index requires maintenance", ""],
  ['0x110b0015', "Battery %index requires maintenance to ensure flight safety", ""],
  ['0x110b0016', "Battery %index requires maintenance to ensure flight safety", ""],
  ['0x110b0017', "Battery %index requires maintenance to ensure flight safety", ""],
  ['0x110b0018', "Battery %index requires maintenance to ensure flight safety", ""],
  ['0x110b0019', "Battery %index requires maintenance to ensure flight safety", ""],
  ['0x110b001a', "Battery %index requires maintenance to ensure flight safety", ""],
  ['0x110b001b', "Battery %index requires maintenance to ensure flight safety", ""],
  ['0x110b0007', "Battery %index damaged. Stop using this battery and contact DJI Support", ""],
  ['0x110b0008', "Battery %index damaged. Stop using this battery and contact DJI Support", ""],
  ['0x110b0009', "Battery %index damaged. Stop using this battery and contact DJI Support", ""],
  ['0x110b000a', "Battery %index damaged. Stop using this battery and contact DJI Support", ""],
  ['0x110b000b', "Battery %index damaged. Stop using this battery and contact DJI Support", ""],
  ['0x110b000c', "Battery %index damaged. Stop using this battery and contact DJI Support", ""],
  ['0x110b000d', "Battery %index damaged. Stop using this battery and contact DJI Support", ""],
  ['0x110b000f', "Battery %index capacity significantly decreased. Continuing use poses serious safety risks", ""],
  ['0x110b0010', "Safety requirements not met. Dispose of Battery %index properly", ""],
  ['0x110b0013', "Battery not detected in slot %index. Insert or replace battery", "Battery not detected in slot %index. Return to home or land promptly "],
  ['0x110b001c', "Batteries do not match. Replace with matching batteries", ""],
  ['0x110b0014', "Battery %index auto-heating", ""],
  ['0x110b000e', "Battery %index self-discharged during storage", ""],
  ['0x110b001d', "Discharge error detected for battery in slot %index. Return to home or land promptly", ""],
  ['0x110b001e', "Discharge error detected for battery in slot %index. Return to home or land promptly", ""],
  ['0x16080020', "Motor %index stalled. Land aircraft immediately", ""],
  ['0x16080021', "ESC %index short-circuited. Land immediately and restart aircraft", ""],
  ['0x16080022', "ESC %index overloaded. Land aircraft immediately", ""],
  ['0x16080023', "Motor %index communication error. Land immediately and restart aircraft", ""],
  ['0x16080024', "Motor %index over-accelerating. Stop pressing control stick", ""],
  ['0x16080025', "Motor %index communication error", ""],
  ['0x16080026', "Motor %index communication error", ""],
  ['0x16080027', "Motor %index communication error", ""],
  ['0x16080028', "Motor %index communication error", ""],
  ['0x16080029', "Motor %index propeller detached or installed incorrectly", ""],
  ['0x1608002a', "ESC %index overheated", ""],
  ['0x1608002b', "ESC %index overheated", ""],
  ['0x1608002c', "ESC %index voltage too high", ""],
  ['0x1608002d', "ESC %index voltage too low", ""],
  ['0x1608002e', "ESC %index flash memory error", ""],
  ['0x1608002f', "ESC %index auto-check error", ""],
  ['0x16080030', "ESC %index auto-check error", ""],
  ['0x16080031', "ESC %index auto-check error", ""],
  ['0x16080032', "ESC %index auto-check error", ""],
  ['0x16080033', "ESC %index auto-check error", ""],
  ['0x16080034', "ESC %index auto-check error", ""],
  ['0x16080035', "ESC %index auto-check error", ""],
  ['0x16080036', "ESC %index auto-check error", ""],
  ['0x16080037', "ESC %index auto-check error", ""],
  ['0x16080038', "ESC %index auto-check error", ""],
  ['0x16080039', "ESC %index auto-check error", ""],
  ['0x16080040', "ESC %index auto-check error", ""],
  ['0x16080041', "ESC %index auto-check error", ""],
  ['0x16080042', "ESC %index auto-check error", ""],
  ['0x16080043', "ESC %index auto-check error", ""],
  ['0x16080044', "ESC %index voltage too low", ""],
  ['0x16080045', "ESC %index voltage too high", ""],
  ['0x16080046', "ESC %index auto-check error", ""],
  ['0x16080047', "ESC %index auto-check error", ""],
  ['0x16080048', "ESC %index auto-check error", ""],
  ['0x16080049', "ESC %index auto-check error", ""],
  ['0x1e000001', "Payload %component_index startup error", ""],
  ['0x1e000002', "Payload %component_index communication error", ""],
  ['0x1e000003', "Payload %component_index overheated", ""],
  ['0x1e000004', "Payload %component_index hardware error", ""],
  ['0x1b010001', "Navigation system error. Restart aircraft", ""],
  ['0x1b010002', "Smart Track unavailable for current payload", ""],
  ['0x1b010003', "Camera mode error. Smart Track unavailable", ""],
  ['0x1b010004', "Target too close", ""],
  ['0x1b010005', "Target lost. Smart Track stopped", ""],
  ['0x1b010006', "Switched to gimbal free mode. Use the RC to control aircraft yaw.", ""],
  ['0x1b010007', "Smart Track stopped. Switch to P mode", ""],
  ['0x1b010008', "Enabling Smart Track failed", ""],
  ['0x1b010009', "Obstacle detected. Circling stopped. Manually control aircraft to avoid obstacle", ""],
  ['0x1b01000a', "GEO Zone nearby. Circling stopped. Manually control aircraft to avoid GEO Zone", ""],
  ['0x1b01000b', "Smart Track paused", ""],
  ['0x1b01000c', "Target moving too fast. Circling stopped", ""],
  ['0x1b010401', "Target too far away. Smart Track stopped", ""],
  ['0x1b010402', "Camera data sending error. Smart Track paused. Restart camera", ""],
  ['0x1b010403', "Camera data sending error. Smart Track paused. Restart camera", ""],
  ['0x1b010404', "Camera data sending error. Smart Track paused. Restart camera", ""],
  ['0x1b010405', "Camera data sending error. Smart Track paused. Restart camera", ""],
  ['0x1b010406', "Camera data sending error. Smart Track paused. Restart camera", ""],
  ['0x1b010407', "Camera data sending error. Smart Track paused. Restart camera", ""],
  ['0x1b010408', "Camera data sending error. Smart Track paused. Restart camera", ""],
  ['0x1b010801', "Camera parameters changed. Smart Track stopped", ""],
  ['0x1b010802', "DJI Pilot error. Smart Track paused. Restart DJI Pilot", ""],
  ['0x1b010803', "DJI Pilot error. Smart Track paused. Restart DJI Pilot", ""],
  ['0x1b010c01', "Target lost. Smart Track stopped", ""],
  ['0x1b010c02', "Flight Controller data sending error. Smart Track paused. Restart aircraft", ""],
  ['0x1b010c03', "Flight Controller data sending error. Smart Track paused. Restart aircraft", ""],
  ['0x1b011001', "Gimbal data sending error. Smart Track stopped. Reinstall payload", ""],
  ['0x1b011002', "Gimbal data sending error. Smart Track paused. Reinstall payload", ""],
  ['0x1b011003', "Gimbal data sending error. Smart Track paused. Reinstall payload", ""],
  ['0x1b011801', "Remote controller data sending error. Smart Track stopped. Restart RC", ""],
  ['0x1b011802', "Remote controller data sending error. Smart Track stopped. Check connection between RC and aircraft", ""],
  ['0x1b030001', "Obstacle detected. RTH stopped. Manually control aircraft to avoid obstacle", ""],
  ['0x1b030002', "GEO Zone nearby. RTH stopped. Manually control aircraft to avoid GEO Zone", ""],
  ['0x1b030003', "RTH error. Fly aircraft manually", ""],
  ['0x1b030004', "Remote controller disconnected. Failsafe RTH in progress", ""],
  ['0x1b030005', "Enabling RTH Obstacle Check failed. Control aircraft to return to home manually", ""],
  ['0x1b030c02', "GPS signal weak. RTH accuracy affected. Manual RTH recommended", ""],
  ['0x1b033001', "RTH Obstacle Check error. Control aircraft to return to home manually", ""],
  ['0x1b040001', "AI Spot-Check unavailable with current payload", ""],
  ['0x1b040002', "AI Spot-Check failed. Switched to normal shooting mode", ""],
  ['0x1b040003', "AI Spot-Check failed. Switched to normal shooting mode", ""],
  ['0x1b040004', "Camera not mounted. AI Spot-Check failed", ""],
  ['0x1b040401', "Camera focusing timed out. AI Spot-Check failed. Restart camera", ""],
  ['0x1b040402', "Switching shooting modes failed. AI Spot-Check failed. Restart camera", ""],
  ['0x1b040403', "Camera zoom timed out. AI Spot-Check failed. Restart camera", ""],
  ['0x1b040801', "Unable to locate AI Spot-Check sample. Reupload flight route", ""],
  ['0x1b040802', "Target box parameter error. Readjust target box", ""],
  ['0x1b041001', "Gimbal error. AI Spot-Check failed. Reinstall payload", ""],
  ['0x1b090001', "Smart Track stopped", ""],
  ['0x1b090002', "Target tracking stopped", ""],
  ['0x1b090003', "Target tracking stopped", ""],
  ['0x1b092c01', "Target identification error", ""],
  ['0x1b092c02', "Target identification error", ""],
  ['0x1b092c03', "Target identification error", ""],
  ['0x1b092c04', "Target identification error", ""],
  ['0x1b092c05', "Target identification error", ""],
  ['0x1b092c06', "Target identification error", ""],
  ['0x1b092c07', "Target identification error", ""],
  ['0x1b092c08', "Target identification error", ""],
  ['0x1b092c09', "Target identification error", ""],
  ['0x1b092c0a', "Target identification error", ""],
  ['0x1b092c0b', "Target identification error", ""],
  ['0x1b092c0c', "Target identification error", ""],
  ['0x1b092c0d', "Target identification error", ""],
  ['0x1b092c0e', "Target identification error", ""],
  ['0x1b092c0f', "Target identification error", ""],
  ['0x1b092c10', "Target identification error", ""],
  ['0x1b092c11', "Target identification error", ""],
  ['0x1b092c12', "Target identification error", ""],
  ['0x1b092c13', "Target identification error", ""],
  ['0x1b092c14', "Target identification error", ""],
  ['0x1b092c15', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c16', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c17', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c18', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c19', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c1a', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c1b', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c1c', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c1d', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c1e', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c1f', "Enabling Smart Track failed. Ensure selected target is valid", ""],
  ['0x1b092c20', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b092c21', "Enabling Smart Track failed. Reduce selected area", ""],
  ['0x1b092c22', "Enabling Smart Track failed. Increase selected area", ""],
  ['0x1b092c23', "Enabling Smart Track failed. Ensure selected target is valid", ""],
  ['0x1b092c24', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093001', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093002', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093003', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093004', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093005', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093006', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093007', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093008', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093009', "Enabling Smart Track failed. Increase selected area", ""],
  ['0x1b09300a', "Enabling Smart Track failed. Ensure selected target is valid", ""],
  ['0x1b09300b', "Target lost. Smart Track stopped", ""],
  ['0x1b09300c', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b09300d', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b09300e', "Camera parameters changed. Smart Track stopped", ""],
  ['0x1b09300f', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093010', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093011', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093012', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093013', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093014', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093015', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093016', "Target lost. Exited Smart Track", ""],
  ['0x1b093017', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b093018', "Target too far away. Smart Track stopped", ""],
  ['0x1b093019', "Camera parameters changed. Exited Smart Track", ""],
  ['0x1b09301a', "Enabling Smart Track failed. Check and try again", ""],
  ['0x1b01000d', "Unusual target movement. Drag-select target again", ""],
  ['0x1b01000e', "GPS signal weak and vision positioning unavailable. Aircraft switched to A mode. Control aircraft manually", ""],
  ['0x1b01000f', "Target lost. Searching...", ""],
  ['0x1b010010', "During Smart Track', you can control gimbals within a certain limit", ""],
  ['0x1b010011', "During Smart Track', you can control lens zoom within a certain limit", ""],
  ['0x1f0b0001', "Aircraft unable to use LTE Transmission. Network connection unstable or SIM card unable to connect to network", ""],
  ['0x1f0b0002', "Remote controller unable to use LTE Transmission. Network connection unstable or SIM card unable to connect to network", ""],
  ['0x1f0b0003', "LTE Server and LTE Transmission unavailable", ""],
  ['0x1f0b0004', "LTE Server and LTE Transmission unavailable", ""],
  ['0x1f0b0005', "LTE Transmission unavailable. Check and ensure remote controller and aircraft are linked properly", ""],
  ['0x1f0b0006', "LTE Transmission unavailable. Restart remote controller", ""],
  ['0x1f0b0007', "LTE Transmission unavailable. Check aircraft 4G Dongle network connectivity", ""],
  ['0x1f0b0008', "LTE Transmission unavailable. Restart aircraft and remote controller", ""],
  ['0x1f0b0009', "LTE Transmission unavailable. Restart remote controller", ""],
  ['0x1f0b001a', "LTE Transmission unavailable. Check aircraft 4G Dongle network connectivity", ""],
  ['0x1f0b001b', "LTE Transmission unavailable. Restart aircraft and remote controller", ""],
  ['0x1f0b0016', "LTE Transmission unavailable. Check remote controller network connectivity", ""],
  ['0x1f0b0017', "LTE Transmission unavailable. Check remote controller network connectivity", ""],
  ['0x1f0b0018', "LTE Transmission unavailable. Restart aircraft and remote controller", ""],
  ['0x1c200001', "Camera %component_index overheated. Wait for temperature to return to normal before use", ""],
  ['0x1c100001', "Camera %component_index overheated. Wait for temperature to return to normal before use", ""],
  ['0x1c300001', "Camera %component_index overheated. Wait for temperature to return to normal before use", ""],
  ['0x1c200101', "Camera %component_index error. Restart camera", ""],
  ['0x1c200102', "Camera %component_index error. Restart camera", ""],
  ['0x1c200103', "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use", "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"],
  ['0x1c200104', "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use", "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"],
  ['0x1c200105', "Camera %component_index error. Restart camera", ""],
  ['0x1c100101', "Camera %component_index error. Restart camera", ""],
  ['0x1c100102', "Camera %component_index error. Restart camera", ""],
  ['0x1c100103', "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use", "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"],
  ['0x1c100104', "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use", "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"],
  ['0x1c100105', "Camera %component_index error. Restart camera", ""],
  ['0x1c300101', "Camera %component_index error. Restart camera", ""],
  ['0x1c300102', "Camera %component_index error. Restart camera", ""],
  ['0x1c300103', "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use", "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"],
  ['0x1c300104', "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use", "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"],
  ['0x1c300105', "Camera %component_index error. Restart camera", ""],
  ['0x1c200201', "Camera %component_index error. Restart camera", ""],
  ['0x1c200202', "Camera %component_index error. Restart camera", ""],
  ['0x1c200203', "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use", "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"],
  ['0x1c200204', "H20 camera image transmission sensor processor error", ""],
  ['0x1c100201', "H20 camera image transmission sensor processor error", ""],
  ['0x1c100202', "H20 camera image transmission sensor processor error", ""],
  ['0x1c100203', "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use", "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"],
  ['0x1c100204', "H20 camera image transmission sensor processor error", ""],
  ['0x1c200301', "H20 camera lens error. Restart camera", ""],
  ['0x1c200302', "H20 camera lens error. Restart camera", ""],
  ['0x1c200303', "H20 camera lens error. Restart camera", ""],
  ['0x1c200304', "H20 camera lens error. Restart camera", ""],
  ['0x1c100301', "H20 camera lens error. Restart camera", ""],
  ['0x1c100302', "H20 camera lens error. Restart camera", ""],
  ['0x1c100303', "H20 camera lens error. Restart camera", ""],
  ['0x1c100304', "H20 camera lens error. Restart camera", ""],
  ['0x1c300301', "H20 camera lens error. Restart camera", ""],
  ['0x1c300302', "H20 camera lens error. Restart camera", ""],
  ['0x1c300303', "H20 camera lens error. Restart camera", ""],
  ['0x1c300304', "H20 camera lens error. Restart camera", ""],
  ['0x1c200401', "Invalid SD card. Replace card", ""],
  ['0x1c200403', "SD card error. Replace card", ""],
  ['0x1c100401', "Invalid SD card. Replace SD card", ""],
  ['0x1c100403', "SD card error. Change card", ""],
  ['0x1c300401', "Invalid SD card. Replace SD card", ""],
  ['0x1c300403', "SD card error. Change card", ""],
  ['0x1c300601', "Temperature of current environment too low. Enable temperature measurement when temperature is within normal range", ""],
  ['0x1c300602', "Temperature of current evironment too high. Only enable temperature measurement when temperature is within normal range", ""],
  ['0x1c300603', "Zenmuse H20T infrared thermal camera switching modes...", ""],
  ['0x1c300604', "Zenmuse H20T infrared thermal camera calibration data missing", ""],
  ['0x1c300605', "Zenmuse H20T infrared thermal camera initialization error. Restart camera", ""],
  ['0x1c300606', "Temperature measurement failed. Adjust camera parameters and try again", ""],
  ['0x1c300701', "Sensor protection enabled. Infrared thermal camera shutter closed", ""],
  ['0x1c100405', "Confirm SD card read and write permissions", ""],
  ['0x1c200405', "Confirm SD card read and write permissions", ""],
  ['0x1c300405', "Confirm SD card read and write permissions", ""],
  ['0x1c100406', "SD card not formatted. Format SD card before use", ""],
  ['0x1c200406', "SD card not formatted. Format SD card before use", ""],
  ['0x1c300406', "SD card not formatted. Format SD card before use", ""],
  ['0x1c100407', "SD card formatting. Please wait", ""],
  ['0x1c200407', "SD card formatting. Please wait", ""],
  ['0x1c300407', "SD card formatting. Please wait", ""],
  ['0x1c100408', "SD card file system not supported. Format card and try again", ""],
  ['0x1c200408', "SD card file system not supported. Format card and try again", ""],
  ['0x1c300408', "SD card file system not supported. Format card and try again", ""],
  ['0x1c100409', "SD card refreshing. Please wait", ""],
  ['0x1c200409', "SD card refreshing. Please wait", ""],
  ['0x1c300409', "SD card refreshing. Please wait", ""],
  ['0x1c10040a', "SD card full. Clear space", ""],
  ['0x1c20040a', "SD card full. Clear space", ""],
  ['0x1c30040a', "SD card full. Clear space", ""],
  ['0x1c10040b', "SD card memory overflow. Format SD card and restart camera", ""],
  ['0x1c20040b', "SD card memory overflow. Format SD card and restart camera", ""],
  ['0x1c30040b', "SD card memory overflow. Format SD card and restart camera", ""],
  ['0x1c10040c', "SD card initializing. Please wait", ""],
  ['0x1c20040c', "SD card initializing. Please wait", ""],
  ['0x1c30040c', "SD card initializing. Please wait", ""],
  ['0x1c10040d', "SD card error. Format SD card before use", ""],
  ['0x1c20040d', "SD card error. Format SD card before use", ""],
  ['0x1c30040d', "SD card error. Format SD card before use", ""],
  ['0x1c10040e', "SD card repair in progress. Please wait", ""],
  ['0x1c20040e', "SD card repair in progress. Please wait", ""],
  ['0x1c30040e', "SD card repair in progress. Please wait", ""],
  ['0x1c10040f', "SD card read and write speed low. Please wait", ""],
  ['0x1c20040f', "SD card read and write speed low. Please wait", ""],
  ['0x1c30040f', "SD card read and write speed low. Please wait", ""],
  ['0x1c000103', "Camera processor overheated. Power off aircraft. Wait for processor to cool down before use", "Camera processor overheated. Return to home or land promptly. Wait for processor to cool down before use"],
  ['0x1c000401', "Invalid SD card. Replace card", ""],
  ['0x1c000402', "SD card speed low. Replace with faster SD card", ""],
  ['0x1c000403', "SD card error. Replace card", ""],
  ['0x1c000404', "No SD card", ""],
  ['0x1c000405', "Confirm SD card read and write permissions ", ""],
  ['0x1c00040e', "SD card write speed slow", ""],
  ['0x1c000407', "SD card formatting. Please wait ", ""],
  ['0x1c000408', "SD card file system not supported. Format card before use ", ""],
  ['0x1c00040a', "SD card full. Clear space ", ""],
  ['0x1c00040b', "SD card memory overflow. Format card and restart camera ", ""],
  ['0x1c00040c', "SD card initializing. Please wait ", ""],
  ['0x1c00040d', "SD card error. Format card before use ", ""],
  ['0x1c000303', "Camera lens error. Restart camera ", ""],
  ['0x1c000305', "Shutter trigger count reached 100K design limit", ""],
  ['0x1c000306', "Certain features unavailable for current camera", ""],
  ['0x1c000d01', "Focusing failed", ""],
  ['0x1c000204', "Camera image sensor overheated", ""],
  ['0x1c000d03', "Infinity focus calibration data expired. Recalibrate", ""],
  ['0x1c000d02', "Loading camera calibration file failed", ""],
  ['0x1c000e01', "Calibration parameters do not match camera or lens", ""],
  ['0x1c000e02', "Update camera intrinsic parameter calibration data", ""],
  ['0x1c000901', "PPS signal sending error", ""],
  ['0x1c000902', "Camera time synchronization error", ""],
  ['0x14010042', "Payload IMU warming up. Wait until IMU is warmed up", ""],
  ['0x14010043', "Payload IMU temperature controlling processor error. Restart payload", ""],
  ['0x14010044', "Payload IMU overheated. Restart payload", ""],
  ['0x14010045', "Payload IMU overheated. Restart payload", ""],
  ['0x19000001', "Avionics system overloaded. Check whether logs are being transmitted. Restart aircraft to restore", "Avionics system overloaded. Fly with caution"],
  ['0x19000002', "Avionics system overloaded. Check whether logs are being transmitted. Restart aircraft to restore", "Avionics system overloaded. Fly with caution"],
  ['0x17000001', "Avionics system overloaded. Restart aircraft to restore", "Avionics system overloaded. Fly with caution"],
  ['0x19000011', "Avionics system memory insufficient. Restart aircraft to restore", "Avionics system memory insufficient. Fly with caution"],
  ['0x19000012', "Avionics system memory insufficient. Restart aircraft to restore", "Avionics system memory insufficient. Fly with caution"],
  ['0x17000011', "Avionics system memory insufficient. Restart aircraft to restore", "Avionics system memory insufficient. Fly with caution"],
  ['0x19000021', "Avionics system error. Restart aircraft to restore", "Avionics system error. Fly with caution"],
  ['0x19000022', "Avionics system error. Restart aircraft to restore", "Avionics system error. Fly with caution"],
  ['0x15000020', "Radar temperature too low. Check and make sure temperature of current environment is within normal range", ""],
  ['0x15000021', "Radar overheated. Check and make sure temperature of current environment is within normal range", ""],
  ['0x15080020', "Radar overheated. Check and make sure temperature of current environment is within normal range", ""],
  ['0x15090020', "Radar overheated. Check and make sure temperature of current environment is within normal range", ""],
  ['0x15020020', "Radar motor error. Check whether radar motor is blocked", ""],
  ['0x15020021', "Radar motor error. Check whether radar motor is blocked", ""],
  ['0x15020022', "Radar motor error. Check whether radar motor is blocked", ""],
  ['0x15040020', "Radar motor error. Check whether radar motor is blocked", ""],
  ['0x15040021', "Radar motor error. Check whether radar motor is blocked", ""],
  ['0x15110020', "Radar motor error. Check whether radar motor is blocked", ""],
  ['0x15060020', "Radar motor error. Check whether radar motor is blocked", ""],
  ['0x15010020', "Radar internal power supply error. Restart radar", ""],
  ['0x15010021', "Radar internal power supply error. Restart radar", ""],
  ['0x15010022', "Radar internal power supply error. Restart radar", ""],
  ['0x15010023', "Radar internal power supply error. Restart radar", ""],
  ['0x15030020', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15030021', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15030022', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15030023', "Starting radar timed out. Check for strong interference in the surrounding area", ""],
  ['0x15030024', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15030025', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15030026', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15100020', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15100021', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15100022', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15070020', "Radar communication link unstable. Check for strong interference in the surrounding area", ""],
  ['0x15140020', "Radar RF clock error. Return to home immediately and check radar", ""],
  ['0x15020023', "Radar detection capability error. Check firmware version", ""],
  ['0x15130021', "Radar detection capability error. Check firmware version", ""],
  ['0x15090021', "Radar firmware error. Restart radar", ""],
];

export default psdkHmsMessages;