import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUnitMissionList, unitMissionList } from "../SecurityAppSlice";

function useUnitMissionList(unitId) {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api);
  const requestState = useSelector((state) => state.securityApp);
  const [loading, setLoading] = useState(true);
  const [dataItems, setDataItems] = useState([]);

  useEffect(() => {
    if (unitId && api.token !== undefined)
      dispatch(getUnitMissionList(unitId));
    return () => {
      dispatch(unitMissionList({ status: "" }));
    };
  }, [api.token, unitId]);

  useEffect(() => {
    if (requestState.unitMissionList?.data !== undefined) {
      setDataItems(requestState.unitMissionList.data.missions);
      setLoading(false);
    }
  }, [requestState.unitMissionList])

  return {
    loading,
    data: dataItems,
    refresh: () => dispatch(getUnitMissionList()),
  };
}

export default useUnitMissionList