import React, { useEffect, useState } from "react";
import "./UserProfileRoles.sass";
import AppTable from "src/ui/AppTable/AppTable";
import Loading from "src/ui/Loading/Loading";
import { useDispatch, useSelector } from 'react-redux';
import { getUserRoles } from "src/redux/ducks/accountStateSlice";

function UserProfileRoles() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const roles = useSelector(state => state.accountState.roles);

  const [tableRows, setTableRows] = useState({
    headers: [
      { title: "Role", value: "name", status: true },
      { title: "Description", value: "description", status: true },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    setTableRows({
      ...tableRows,
      values: roles.data?.roles || [],
    });

    if (roles.status === 'success')
      setLoading(false);
  }, [roles]);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserRoles());
  }, []);

  return (
    <div className="user-profile-roles">
      <h2>Roles</h2>
      <div className="roles-table-container">
        <AppTable
          rows={tableRows}
          onRowChange={() => { }}
          onRowsChange={() => { }}
          optionChangeHandler={() => { }}
          tags={[]}
          disableColumnSelector={true}
        />
      </div>
      {loading && <Loading />}
    </div>
  );
}

export default UserProfileRoles;
