import SidePanel from 'src/hci/organism/SidePanel/SidePanel'
import './AddUnit.sass'
import { FaSave } from 'react-icons/fa'
import { MdEdit, MdOutlineLocationOn } from 'react-icons/md';
import FormItem from 'src/hci/common/FormItem/FormItem';
import Button from 'src/hci/common/Button/Button';
import Form from 'src/hci/common/Form/Form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLastClickCoordinates, setPointerMode } from 'src/components/DeviceMap/DeviceMapSlice';
import TextInput from 'src/hci/common/TextInput/TextInput';
import { SelectGroupDialog } from '../SelectGroupDialog/SelectGroupDialog';
import { getGroupsInformation } from 'src/ui/GroupApp/GroupAppSlice';
import Checkbox from 'src/hci/common/Checkbox/Checkbox';
import Avatar from 'src/ui/Avatar/Avatar';
import { resetCreateUnit, sendCreateUnit } from '../SecurityAppSlice';
import { DeviceMapPointerModes } from 'src/components/DeviceMap/DeviceMap';
import { isValidCoordinate } from 'src/helper/utils';

function AddUnit({ onCancel, onSuccess }) {
  const dispatch = useDispatch();
  const formRef = useRef();
  const mapClickCoordinates = useSelector(state => state.deviceMap.lastClickCoordinates);
  const [showSelectGroupDialog, setShowSelectGroupDialog] = useState(false);
  const [unitGroupIds, setUnitGroupIds] = useState([]);
  const [unitGroupsInfo, setUnitGroupsInfo] = useState([]);
  const [unitGroupMembers, setUnitGroupMembers] = useState([]);
  const groupApp = useSelector((state) => state.groupApp);
  const securityApp = useSelector((state) => state.securityApp);
  const [locationValue, setLocationValue] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const mapClickCoordinatesString = useMemo(() => {
    return mapClickCoordinates ? `${mapClickCoordinates.lat},${mapClickCoordinates.lng}` : ''
  }, [mapClickCoordinates]);

  useEffect(() => {
    if (mapClickCoordinates) {
      setLocationValue(mapClickCoordinatesString);
    }
  }, [mapClickCoordinatesString]);

  const handleBeforeClose = () => {
    dispatch(setPointerMode(DeviceMapPointerModes.NORMAL));
  }

  const handleOnCancel = () => {
    handleBeforeClose();
    setShowSelectGroupDialog(false);
    onCancel?.();
  }

  const handleSelectGroupComplete = (groupIds) => {
    setUnitGroupIds(groupIds);
    setShowSelectGroupDialog(false);
    dispatch(getGroupsInformation(groupIds));
    setLoading(true);
  }

  const handleServerFormErrors = (errors) => {
    const errorList = {};

    if(!errors || !Array.isArray(errors))
      return;

    errors.forEach((error) => {
      errorList[error.propertyName.toLowerCase()] = error.errorMessage;
    });

    setFormErrors(errorList);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    const form = e.target;
    const formData = new FormData(form);
    const name = formData.get('name');
    const location = formData.get('location');
    const pilots = formData.getAll('pilots');

    if (!name) {
      errors.name = 'Unit name is required';
    }

    if (!location) {
      errors.location = 'Location is required';
    }

    if(location && !location.includes(',')) {
      errors.location = 'Invalid location';
    }

    setFormErrors(errors);

    if(Object.keys(errors).length !== 0)
      return;

    const requestData = {
      name,
      pilots,
      groups: unitGroupIds,
      location: {
        lat: location.split(',')[0],
        long: location.split(',')[1],
      },
    };

    dispatch(sendCreateUnit(requestData));
    setLoading(true);
  }

  const handleCoordinateChange = (e) => {
    setLocationValue(e.target.value);
    const coordinates = e.target.value?.split(',');

    if(isValidCoordinate(coordinates)) {
      console.log(coordinates)
      const [lat, long] = coordinates;
      dispatch(setLastClickCoordinates({ lat: parseFloat(lat), lng: parseFloat(long) }));
    }
  }

  useEffect(() => {
    dispatch(setLastClickCoordinates(null));
    dispatch(setPointerMode(DeviceMapPointerModes.SELECT_POINT));
    setShowSelectGroupDialog(true);
    resetCreateUnit();
  }, []);

  useEffect(() => {
    if (
      groupApp.groupsInformation?.status === "success" &&
      groupApp.groupsInformation?.data?.groups
    ) {
      const users = [];
      const groups = groupApp.groupsInformation?.data?.groups;
      
      groups.forEach((group) => {
        group.members.forEach((member) => {
          if(!users.find((user) => user.userId === member.userId)) {
            users.push(member);
          }
        });
      });

      setUnitGroupsInfo(groups);
      setUnitGroupMembers(users);
    }

    setLoading(false);
  }, [groupApp]);

  useEffect(() => {
    setLoading(false);

    if (securityApp.createUnit.status === "success") {
      onSuccess?.();
    } else if(securityApp.createUnit.status === "validation") {
      handleServerFormErrors(securityApp.createUnit.errors);
    }
  }, [securityApp.createUnit]);


  const panelActions = [
    {
      title: "Save",
      content: <FaSave />,
    }
  ];

  const footerItems = [
    <Button onClick={handleOnCancel} variant="outline">Cancel</Button>,
    <Button style={{flexGrow: '1'}} onClick={() => {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }}>Save</Button>
  ];

  return (
    <SidePanel panelTitle="Add New Unit" actions={panelActions} footerItems={footerItems} collapsable={false} loading={loading}>
      <div className="add-unit">
        <Form onSubmit={handleFormSubmit} ref={formRef}>
          <FormItem label="Unit Name" error={formErrors.name}>
            <TextInput name="name" postfix={<MdEdit />} />
          </FormItem>
          <FormItem label="Location" error={formErrors.location}>
            <TextInput name="location" postfix={<MdOutlineLocationOn />} value={locationValue} onChange={handleCoordinateChange} />
          </FormItem>
          <FormItem label="Pilot(s) Preference">
            <div className="users-checkbox-list">
              {unitGroupMembers.map((member) => (
                <div key={member.userId} className="item">
                  <Checkbox label={member.name} name='pilots' value={member.userId} />
                  <Avatar image={member.avatar} isCovered={true} width="2em" height="2em" isBordered={true} />
                  <div>
                    <div className="name">{member.displayName}</div>
                    <div className="email">{member.emailAddress}</div>
                  </div>
                </div>
              ))}
            </div>
          </FormItem>
        </Form>
      </div>
      <SelectGroupDialog
        open={showSelectGroupDialog}
        onOpenChange={handleOnCancel}
        onConfirm={handleSelectGroupComplete}
      />
    </SidePanel>
  )
}

export default AddUnit