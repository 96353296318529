import './WaypointController.sass'

import React, { useEffect, useRef, useState } from 'react'
import InputBox from 'src/ui/InputBox/InputBox'
import Button from 'src/ui/Button/Button'
import { MdClose } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { resetWaypoint, setWaypointInfo, showWaypointForm } from 'src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice'
import CountdownTimer from 'src/ui/CountdownTimer/CountdownTimer'
import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider'
import Loading from 'src/ui/Loading/Loading'
import { addNotification } from 'src/components/Notification/NotificationSlice'
import { NotificationType } from 'src/components/Notification/NotificationItem/NotificationItem'
import useDialog from 'src/helper/useDialog'
import { createWaypointTemplate, createWaypointWaylines } from 'src/helper/waypointMarkupUtils'
import JSZip from 'jszip'
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider'
import { MeasurementSystem } from 'src/helper/constants'
import { feetToMeter, meterToFeet, meterToMile, mileToMeter } from 'src/helper/utils'
import useMeasurement from 'src/helper/useMeasurement'

export const WaypointAction = {
  UPLOAD: 1,
  START: 2,
  PAUSE: 3,
  RESUME: 4,
  STOP: 5,
};

export const WaypointEvent = {
  MISSION_INTERRUPT_EVENT: {
    id: 1,
    message: 'Mission inteterrupt',
  },
  MISSION_RESUME_EVENT: {
    id: 2,
    message: 'Mission resumed',
  },
  MISSION_STOP_EVENT: {
    id: 3,
    message: 'Mission stopped',
  },
  MISSION_ARRIVAL_EVENT: {
    id: 16,
    message: 'Mission arrived at point',
  },
  MISSION_FINISHED_EVENT: {
    id: 17,
    message: 'Mission finished',
  },
  MISSION_OBSTACLE_EVENT: {
    id: 18,
    message: 'Mission obstacle detected',
  },
  MISSION_SWITCH_EVENT: {
    id: 48,
    message: 'Mission switch happened',
  },
};

export const WaypointState = {
  GROUND_STATION_NOT_START: {
    id: 0,
    message: 'Ground station not start',
  },
  MISSION_PREPARED: {
    id: 1,
    message: 'Mission uploaded',
  },
  ENTER_MISSION: {
    id: 2,
    message: 'Mission started',
  },
  EXECUTE_FLYING_ROUTE_MISSION: {
    id: 3,
    message: 'Mission flying',
  },
  PAUSE_STATE: {
    id: 4,
    message: 'Mission paused',
  },
  ENTER_MISSION_AFTER_ENDING_PAUSE: {
    id: 5,
    message: 'Mission resumed',
  },
  EXIT_MISSION: {
    id: 6,
    message: 'Mission exit happend',
  },
};

export const WaypointStateV3 = {
  GROUND_STATION_NOT_START: {
    id: 0,
    message: 'Ground station not start',
  },
  MISSION_PREPARED: {
    id: 16,
    message: 'Mission uploaded',
  },
  ENTER_MISSION: {
    id: 32,
    message: 'Mission started',
  },
  EXECUTE_FLYING_ROUTE_MISSION: {
    id: 48,
    message: 'Mission flying',
  },
  PAUSE_STATE: {
    id: 64,
    message: 'Mission paused',
  },
  ENTER_MISSION_AFTER_ENDING_PAUSE: {
    id: 80,
    message: 'Mission resumed',
  },
  EXIT_MISSION: {
    id: 98,
    message: 'Mission exit happend',
  },
};

export const WaypointLocalState = {
  IDLE: 0,
  IN_PROGRESS: 1,
  WAITAING_FOR_START: 2,
  PAUSED: 3,
};

const MAX_ALTITUDE = 100;
const MIN_ALTITUDE = 5;
const MAX_SPEED = 10;
const MIN_RTH_ALTITUDE = 20;

function WaypointController() {
  const dispatch = useDispatch();
  const { measurementSystem: unit } = useMeasurement();
  const { hub, groups, deviceId, joinCompleted } = useOnboardHub();
  const waypoint = useSelector((state) => state.flight.waypoint);
  const droneDisplayMode = useSelector((state) => state.flight.droneMode.displayMode);
  const gps = useSelector((state) => state.flight.gps);
  const altitude = useSelector((state) => state.flight.altitude?.height);
  const dfrSettings = useSelector((state) => state.onboard.deviceDetails?.data?.dfrSetting);
  const groupMessageHandlerIds = useRef([]);
  const waypointStartTimeoutId = useRef();
  const confirmLandingDialogActive = useRef();
  const dialog = useDialog();
  const { activeCameraPayload } = useDeviceInfo();
  const [data, setData] = useState({
    coordinate: undefined,
    altitude: 80,
    speed: 10,
    rthAltitude: 30,
  });
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [lastEvent, setLastEvent] = useState(null);
  const [lastState, setLastState] = useState(null);
  const [statusMessages, setStatusMessages] = useState([]);
  const pendingAction = useRef();

  // sync with external coordinate change
  useEffect(() => {
    const coord = waypoint.selectedCoordinate;

    if (coord) {
      setData(curr => ({
        ...curr,
        coordinate: coord.lat + ',' + coord.lng,
      }));

      setValidation(curr => ({
        ...curr,
        coordinate: undefined,
      }));
    }
  }, [waypoint.selectedCoordinate]);

  // confirm landing for RTH
  useEffect(() => {
    if (
      droneDisplayMode === 12 &&
      (altitude - 0.7 < 0.001) &&
      !confirmLandingDialogActive.current
    ) {
      dialog.fire({
        title: <b>Auto Landing</b>,
        text: "Do you want to finalize landing?",
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Confirm Landing',
      }).then(result => {
        if (result.isConfirmed) {
          hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
            flightControl: {
              actionId: 6,
            }
          });
        }

        setTimeout(() => confirmLandingDialogActive.current = false, 2000);
      });

      confirmLandingDialogActive.current = true;
    }
  }, [droneDisplayMode, altitude]);

  // Sync min waypoint state on error/refresh
  useEffect(() => {
    if (droneDisplayMode === 14 && waypoint.state !== WaypointLocalState.IN_PROGRESS) {
      setWaypointInfo(curr => ({
        ...curr,
        state: WaypointLocalState.IN_PROGRESS,
      }));
    }
  }, [droneDisplayMode]);

  //join groups
  useEffect(() => {
    if (joinCompleted) subsrcibeGroups();
  }, [joinCompleted]);

  const uploadWaypointData = (points, speed, kmzFile) => {
    if (!points?.length || !speed) return;

    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      waypoint: {
        actionId: WaypointAction.UPLOAD,
        numberOfPoints: points.length,
        points,
        speed,
        kmzFile
      }
    });

    pendingAction.current = WaypointAction.UPLOAD;
  };

  //set waypoint default settings from drone options
  useEffect(() => {
    if (!dfrSettings) return;

    let defaultSpeed = dfrSettings.speed;
    let defaultAltitude = dfrSettings.altitude;

    if (defaultSpeed > MAX_SPEED || defaultSpeed < 1)
      defaultSpeed = 9;

    if (defaultAltitude > MAX_ALTITUDE || defaultAltitude < MIN_ALTITUDE)
      defaultAltitude = 30;

    if(unit === MeasurementSystem.IMPERIAL) {
      defaultSpeed = Math.floor(meterToMile(defaultSpeed));
      defaultAltitude = Math.floor(meterToFeet(defaultAltitude));
    }

    setData(curr => ({
      ...curr,
      speed: defaultSpeed || data.speed,
      altitude: defaultAltitude || data.altitude,
      rthAltitude: defaultAltitude || data.altitude,
    }));
  }, [dfrSettings]);

  const sendStartWaypointCommand = () => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      waypoint: {
        actionId: WaypointAction.START,
      }
    });

    pendingAction.current = WaypointAction.START;
  };

  const sendStopWaypointCommand = () => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      waypoint: {
        actionId: WaypointAction.STOP,
      }
    });

    pendingAction.current = WaypointAction.STOP;
  };

  const sendPauseWaypointCommand = () => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      waypoint: {
        actionId: WaypointAction.PAUSE,
      }
    });

    pendingAction.current = WaypointAction.PAUSE;
  };

  const sendResumeWaypointCommand = () => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      waypoint: {
        actionId: WaypointAction.RESUME,
      }
    });

    pendingAction.current = WaypointAction.RESUME;
  };

  const isValidCoordinate = (coordinate) => {
    if (typeof coordinate !== 'string') return;
    const [lat, lng] = coordinate?.split(',');

    if (lat === undefined || lng === undefined) return false;
    return (lat < -90 || lat > 90) || (lng < -180 || lng > 180) ? false : true;
  }

  const validateWaypointForm = (input) => {
    const errors = [];
    if (!input) input = data;

    let realMinAltitude = MIN_ALTITUDE;
    let realMaxAltitude = MAX_ALTITUDE;
    let realMaxSpeed = MAX_SPEED;
    let realMinRthAltitude = MIN_RTH_ALTITUDE;

    if(unit === MeasurementSystem.IMPERIAL) {
      realMinAltitude = Math.ceil(meterToFeet(MIN_ALTITUDE));
      realMaxAltitude = Math.floor(meterToFeet(MAX_ALTITUDE));
      realMaxSpeed = Math.floor(meterToMile(MAX_SPEED));
      realMinRthAltitude = Math.ceil(meterToFeet(MIN_RTH_ALTITUDE));
    }

    // Altitude validation
    if (input.altitude > realMaxAltitude || input.altitude < realMinAltitude)
      errors.push(['altitude', `Value should be between ${realMinAltitude} and ${realMaxAltitude}`]);

    // Speed validation
    if (!input.speed || input.speed > realMaxSpeed)
      errors.push(['speed', `Max value is ${realMaxSpeed}`]);

    // Coordinate validation
    if (!input.coordinate || !isValidCoordinate(input.coordinate))
      errors.push(['coordinate', 'Invalid coordinate (lat,lng)']);

    // RTH Altitude validation
    if (input.rthAltitude > realMaxAltitude || input.rthAltitude < realMinRthAltitude)
      errors.push(['rthAltitude', `Value should be between ${realMinRthAltitude} and ${realMaxAltitude}`]);

    setValidation(Object.fromEntries(errors.map(([key, value]) => [
      key, {
        alert: true,
        type: 'error',
        message: value
      }
    ])));

    return errors;
  }

  const onWaypointStop = () => {
    dispatch(resetWaypoint());
    setLoading(false);
  }

  const handlePanelCloseButtonClick = () => {
    dispatch(showWaypointForm(false));
  }

  const handleWaypointFormChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setData(curr => {
      const newData = {
        ...curr,
        [key]: value,
      };

      if (validation[key])
        validateWaypointForm(newData);

      return newData;
    });

    if (key === 'coordinate' && isValidCoordinate(value)) {
      const [lat, lng] = value.split(',');
      dispatch(setWaypointInfo({
        selectedCoordinate: { lat, lng },
      }))
    }
  }

  const handleWaypointFormItemBlur = () => {
    validateWaypointForm();
  }

  const handleWaypointFormConfirm = () => {
    const errors = validateWaypointForm();
    if (errors.length) return;

    let realData = data;
    const [lat, lng] = data.coordinate.split(',');
    setStatusMessages([]);

    if(unit === MeasurementSystem.IMPERIAL) {
      realData = {
        ...data,
        altitude: Math.floor(feetToMeter(data.altitude)),
        speed: Math.floor(mileToMeter(data.speed)),
      }
    }

    dispatch(setWaypointInfo({
      ...realData,
      coordinate: { lat, lng },
      startTimestamp: Date.now(),
      state: WaypointLocalState.WAITAING_FOR_START,
    }));

    // Create config for createWaypointTemplate
    const kmzConfig = {
      droneEnumValue: 67,
      droneSubEnumValue: 1,
      payloadEnumValue: activeCameraPayload.enumValue,
      payloadSubEnumValue: activeCameraPayload.subEnumValue ?? 0,
      pyaloadPositionIndex: 0,
      autoFlightSpeed: Number(realData.speed),
      globalHeight: Number(realData.altitude),
      waypoints: [
        {
          lat: Number(gps.lat),
          long: Number(gps.long),
        },
        {
          lat: Number(lat),
          long: Number(lng),
        }
      ]
    };

    const waypointTemplate = createWaypointTemplate(kmzConfig);
    const waypointWaylines = createWaypointWaylines(kmzConfig);

    // Create zip file and upload mission data
    const zip = new JSZip();
    const wpmz = zip.folder('wpmz');

    wpmz.file('template.kml', waypointTemplate);
    wpmz.file('waylines.wpml', waypointWaylines);

    zip.generateAsync({ type: 'base64' }).then((content) => {
      uploadWaypointData([
        {
          lat: Number(gps.lat),
          long: Number(gps.long),
          altitude: Number(realData.altitude),
        },
        {
          lat: Number(lat),
          long: Number(lng),
          altitude: Number(realData.altitude),
        }
      ], Number(realData.speed), content);
      setLoading(true);

      waypointStartTimeoutId.current = setTimeout(() => {
        if (waypoint.state !== WaypointLocalState.IDLE) return;

        onWaypointStop();
        dispatch(addNotification({
          title: 'Waypoint Mission',
          message: "Mission didn't started in 50 seconds.",
          type: NotificationType.ERROR,
        }));
      }, 50000);

      // FileSaver.saveAs(content, Date.now() + '_waypoint.kmz');
    });
  }

  const handleWaypointStopButtonClick = () => {
    sendStopWaypointCommand();
  }

  const handleWaypointPauseButtonClick = () => {
    sendPauseWaypointCommand();
  }

  const handleWaypointResumeButtonClick = () => {
    sendResumeWaypointCommand();
  }

  const handleWaypointReturnToHome = () => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      flightControl: {
        actionId: 9,
        altitude: parseInt(data.rthAltitude),
      }
    });
  }

  const handleWaypointCancelReturnToHome = () => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      flightControl: {
        actionId: 10,
      }
    });
  }

  const handleWaypointCancelLanding = () => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      flightControl: {
        actionId: 7,
      }
    });
  }

  const handleWaypointRemoteEvent = (data) => {
    const id = data?.waypointEvent;
    const event = Object.entries(WaypointEvent).find(([key, item]) => item.id === id)?.[1];
    if (!event) return;

    // mission stop
    if ([
      WaypointEvent.MISSION_FINISHED_EVENT.id,
      WaypointEvent.MISSION_STOP_EVENT.id,
    ].includes(id)) {
      onWaypointStop();
    }

    setLastEvent(id);
    setStatusMessages(curr => [...curr, event.message]);
  }

  const handleWaypointRemoteState = (data) => {
    const id = data?.waypointState;
    const targetStateSet = data?.waypointVersion === '3.0.0' ? WaypointStateV3 : WaypointState;
    const state = Object.entries(targetStateSet).find(([key, item]) => item.id === id);
    console.log('[state]', state);
    //if (!state) return;

    // mission started
    if (
      id === targetStateSet.ENTER_MISSION.id ||
      (id === targetStateSet.EXECUTE_FLYING_ROUTE_MISSION.id && lastState !== targetStateSet.ENTER_MISSION.id)
    ) {
      dispatch(setWaypointInfo({
        inProgress: true,
        state: WaypointLocalState.IN_PROGRESS,
        showForm: false,
      }));

      clearTimeout(waypointStartTimeoutId.current);
      setLoading(false);
    }

    // mission paused
    if (id === targetStateSet.PAUSE_STATE.id) {
      dispatch(setWaypointInfo({
        state: WaypointLocalState.PAUSED,
      }));
    }

    // mission resume
    if (id === targetStateSet.ENTER_MISSION_AFTER_ENDING_PAUSE.id) {
      dispatch(setWaypointInfo({
        state: WaypointLocalState.IN_PROGRESS,
      }));
    }

    // mission stop
    if ([
      targetStateSet.EXIT_MISSION.id,
      targetStateSet.GROUND_STATION_NOT_START.id,
    ].includes(id)) {
      onWaypointStop();
    };

    setLastState(id);
    setStatusMessages(curr => [...curr, state.message]);
  }

  const subsrcibeGroups = () => {
    const waypointActionHandlers = [
      ['waypointEvent', handleWaypointRemoteEvent],
      ['waypointState', handleWaypointRemoteState],
    ];

    const groupMessageHandlers = [
      {
        identity: deviceId,
        name: groups?.join?.lowFreqChannel,
        handler: (data) => {
          if(data.waypointState) handleWaypointRemoteState(data.waypointState);
          if(data.waypointEvent) handleWaypointRemoteEvent(data.waypointEvent);

          // console.log('[Waypoint:lowFreqChannel]', data);
          // waypointActionHandlers.forEach(([key, handler]) => {
          //   if (data[key] ) handler(data[key]);
          // });
        },
      },
      {
        identity: deviceId,
        name: groups?.join?.onDemandChannel,
        handler: (data) => {
          if(data?.action === 'WaypointResult') {
            if(data.actionId === WaypointAction.UPLOAD && pendingAction.current === WaypointAction.UPLOAD){
              if(data.status)
                sendStartWaypointCommand();
              else if(pendingAction.current === WaypointAction.UPLOAD) {
                dispatch(addNotification({
                  title: 'Waypoint Mission Failed',
                  message: "Mission upload failed, please change your input and try again.",
                  type: NotificationType.ERROR,
                }));

                onWaypointStop();
              }
            }

            if(data.actionId === WaypointAction.START){
              if(data.status && data.detail) {
                const lastPoint = data.detail.points?.[data.detail.points?.length - 1];

                if(lastPoint) {
                  dispatch(setWaypointInfo({
                    altitude: lastPoint.altitude,
                    coordinate: {
                      lat: lastPoint.lat, 
                      lng: lastPoint.long
                    },
                    speed: data.detail.speed,
                    startTimestamp: Date.now(),
                    state: WaypointLocalState.IN_PROGRESS,
                    selectedCoordinate: {
                      lat: lastPoint.lat,
                      lng: lastPoint.long,
                    },
                  }));
                } 
              }
              else if(!data.status && pendingAction.current === WaypointAction.START) {
                dispatch(addNotification({
                  title: 'Waypoint Mission Failed',
                  message: "Mission start command failed, please try again.",
                  type: NotificationType.ERROR,
                }));

                onWaypointStop();
              }
            }

            if(data.actionId === pendingAction.current)
              pendingAction.current = null;

            clearTimeout(waypointStartTimeoutId.current);
            setLoading(false);
          }
        },
      },
    ];

    hub.unsubscribeGroupMessages(
      groupMessageHandlerIds.current
    );

    groupMessageHandlerIds.current = hub.subscribeGroupMessages(
      groupMessageHandlers,
      "waypoint"
    );
  }

  return (
    <div className="waypoint-controller">
      {waypoint.state !== WaypointLocalState.IDLE ? (
        <>
          <div className="panel-header">
            <div className="title">Mission in progress...</div>
          </div>
          <div className="main">
            <div className="waypoint-info">
              <div className="item">
                <div className="caption">Time Elapsed:</div>
                <div className="value"><CountdownTimer seconds={Math.round((Date.now() - waypoint.startTimestamp) / 1000)} inverse={true} /></div>
              </div>
              <div className="item">
                <div className="caption">Coordinate:</div>
                <div className="value">{waypoint.coordinate?.lat + ',' + waypoint.coordinate?.lng}</div>
              </div>
              <div className="item">
                <div className="caption">Altitude:</div>
                <div className="value">{unit === MeasurementSystem.METRIC ? waypoint.altitude + 'm' : meterToFeet(waypoint.altitude)?.toFixed(2) + 'ft'}</div>
              </div>
              <div className="item">
                <div className="caption">Speed:</div>
                <div className="value">{unit === MeasurementSystem.METRIC ? waypoint.speed + 'm/s' : meterToMile(waypoint.speed)?.toFixed(2) + 'mph'}</div>
              </div>
            </div>
            <div className="operation-log">
              <div className="caption">Event Log:</div>
              <div className="value">{statusMessages.map((item, index) => (
                <div key={index}>{item}</div>
              ))}</div>
            </div>
            <div className="waypoint-actions">
              <Button value="Stop" className="stop-button" onClick={handleWaypointStopButtonClick} />
              {waypoint.state === WaypointLocalState.PAUSED ? (
                <Button value="Resume" className="resume-button" onClick={handleWaypointResumeButtonClick} />
              ) : (
                <Button value="Pause" className="pause-button" onClick={handleWaypointPauseButtonClick} />
              )}
            </div>
          </div>
        </>
      ) : waypoint.showForm && (
        <>
          <div className="panel-header">
            <div className="title">Set Destination</div>
            <MdClose className="close-button" onClick={handlePanelCloseButtonClick} />
          </div>
          <div className="main">
            <div className="waypoint-form">
              <InputBox
                title="Coordinates"
                name="coordinate"
                isRequired={true}
                value={data.coordinate}
                type="text"
                placeholder="lat,lng"
                onChange={handleWaypointFormChange}
                onBlur={handleWaypointFormItemBlur}
                status={validation.coordinate}
              />
              <InputBox
                title={`Altitude (` + (unit === MeasurementSystem.METRIC ? MIN_ALTITUDE + 'm - ' + MAX_ALTITUDE + 'm' : Math.ceil(meterToFeet(MIN_ALTITUDE)) + 'ft - ' + Math.floor(meterToFeet(MAX_ALTITUDE)) + 'ft') + `)`}
                name="altitude"
                isRequired={true}
                value={data.altitude}
                type="text"
                onChange={handleWaypointFormChange}
                onBlur={handleWaypointFormItemBlur}
                status={validation.altitude}
              />
              <InputBox
                title={`Speed (` + (unit === MeasurementSystem.METRIC ? '1m/s - ' + MAX_SPEED + 'm/s' : Math.ceil(meterToMile(1)) + 'mph - ' + Math.floor(meterToMile(MAX_SPEED)) + 'mph') + `)`}
                name="speed"
                isRequired={true}
                value={data.speed}
                type="text"
                onChange={handleWaypointFormChange}
                onBlur={handleWaypointFormItemBlur}
                status={validation.speed}
              />
              <InputBox
                title={`RTH Altitude (` + (unit === MeasurementSystem.METRIC ? MIN_RTH_ALTITUDE + 'm - ' + MAX_ALTITUDE + 'm' : Math.ceil(meterToFeet(MIN_RTH_ALTITUDE)) + 'ft - ' + Math.floor(meterToFeet(MAX_ALTITUDE)) + 'ft') + `)`}
                name="rthAltitude"
                isRequired={true}
                value={data.rthAltitude}
                type="text"
                onChange={handleWaypointFormChange}
                onBlur={handleWaypointFormItemBlur}
                status={validation.rthAltitude}
              />
            </div>
            <div className="waypoint-actions">
              {droneDisplayMode === 15 && (
                <Button value="Cancel RTH" className="rth-button" onClick={handleWaypointCancelReturnToHome} />
              )}
              {droneDisplayMode === 12 && (
                <Button value="Cancel Landing" className="rth-button" onClick={handleWaypointCancelLanding} />
              )}
              {droneDisplayMode !== 15 && droneDisplayMode !== 12 && (
                <>
                  <Button
                    value="Go to Location"
                    className="submit-button"
                    onClick={handleWaypointFormConfirm}
                  />
                  <Button value="RTH" className="rth-button" onClick={handleWaypointReturnToHome} />
                </>
              )}
            </div>
          </div>
        </>
      )}
      {loading && <Loading dark={true} />}
    </div>
  )
}

export default WaypointController