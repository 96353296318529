import { AiOutlineBell } from "react-icons/ai";
import { IoAppsOutline } from "react-icons/io5";
import "./AdminMobileFooter.sass";
import { CgHome } from "react-icons/cg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTheme } from "../../../helper/ThemeProvider";

function AdminMobileFooter() {
  const { currentContext } = useTheme();
  const footerItems = [
    {
      id: 1,
      title: "Notification",
      name: "notification",
      icon: <AiOutlineBell />,
    },
    {
      id: 2,
      title: "Home",
      name: "home",
      link: "/dashboard",
      icon: <CgHome />,
    },
    {
      id: 3,
      title: "Apps",
      name: "apps",
      icon: <IoAppsOutline />,
    },
  ];
  return (
    <div
      className="admin-mobile-footer"
      style={{ background: currentContext.theme.secondColor }}
    >
      <ul className="admin-mobile-footer-container">
        {footerItems.map((item, index) => (
          <li key={index}>
            <Link className="admin-mobile-footer-item" to={item.link}>
              {item.icon}
              <span>{item.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AdminMobileFooter;
