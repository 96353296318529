import { isNumber } from "../../helper/utils";
import "./Code.sass";
function Code(props) {
  const { onFocus, mouseDown, onKeyDown, onChange, index, input, type } = props;
  const checkNumber = (event) => {
    if (type === "numeric") isNumber(event);
  };
  return (
    <input
      autoComplete="off"
      className="code"
      name={"code-" + index}
      type="text"
      maxLength="1"
      placeholder="-"
      onMouseDown={mouseDown}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onInput={onChange}
      data-index={index}
      ref={input}
      onKeyPress={checkNumber}
    />
  );
}
export default Code;
