import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

function SlideLeft({ className, isOpen, children, toggle, style }) {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const props = useSpring({
    to: {
      opacity: open ? 1 : 0,
      transform: `translateX(${open ? 0 : 100}px)`,
    },
    from: { opacity: 0, transform: "translateX(100px)" },
    onRest: () => {
      if (!open) toggle();
    },
  });
  return (
    <animated.div className={className} style={{ ...props, ...style }}>
      {children}
    </animated.div>
  );
}

export default SlideLeft;
