import { useSelector } from 'react-redux';
import './BatteryPercent.sass'

function BatteryPercentIcon({ value }) {
  return (
    <div className="battery-percent-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="56px" height="31px" viewBox="0 0 56 31">
        <defs>
          <path
            d="M40.625,0 C42.9261865,0 44.7916667,1.790861 44.7916667,4 L44.7916667,7 L47.9166667,7 C49.0672599,7 50,7.8954305 50,9 L50,16 C50,17.1045695 49.0672599,18 47.9166667,18 L44.7916667,18 L44.7916667,21 C44.7916667,23.209139 42.9261865,25 40.625,25 L4.16666667,25 C1.86548021,25 0,23.209139 0,21 L0,4 C0,1.790861 1.86548021,0 4.16666667,0 L40.625,0 Z"
            id="path-1"
          />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
          <g id="battery-v2" transform="translate(3.000000, 3.000000)">
            <g id="battery-masked-shape">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <path
                stroke="#FFFFFF"
                strokeWidth={3}
                d="M40.625,-1.5 C42.1825839,-1.5 43.5902061,-0.889583196 44.610076,0.0894918937 C45.6525617,1.09027819 46.2916667,2.47444737 46.2916667,4 L46.2916667,4 L46.2916667,5.5 L47.9166667,5.5 C48.898954,5.5 49.7857428,5.88655918 50.4286035,6.50370546 C51.0940801,7.14256294 51.5,8.02673212 51.5,9 L51.5,9 L51.5,16 C51.5,16.9732679 51.0940801,17.8574371 50.4286035,18.4962945 C49.7857428,19.1134408 48.898954,19.5 47.9166667,19.5 L47.9166667,19.5 L46.2916667,19.5 L46.2916667,21 C46.2916667,22.5255526 45.6525617,23.9097218 44.610076,24.9105081 C43.5902061,25.8895832 42.1825839,26.5 40.625,26.5 L40.625,26.5 L4.16666667,26.5 C2.60908276,26.5 1.20146055,25.8895832 0.181590669,24.9105081 C-0.860895057,23.9097218 -1.5,22.5255526 -1.5,21 L-1.5,21 L-1.5,4 C-1.5,2.47444737 -0.860895057,1.09027819 0.181590669,0.0894918937 C1.20146055,-0.889583196 2.60908276,-1.5 4.16666667,-1.5 L4.16666667,-1.5 Z"
              />
              <rect
                id="Rectangle"
                fill="#A2A2A2"
                mask="url(#mask-2)"
                x={0}
                y={0}
                width={value * 0.5}
                height={25}
              />
            </g>
            <text
              id="battery-percentage"
              fontFamily="Roboto-Regular, Roboto"
              fontSize={18}
              fontWeight="normal"
              fill="#FFFFFF"
            >
              <tspan x={value === 100 ? 7 : 12} y={19}>
                {value}
              </tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  )
}

function BatteryPercent() {
  const batteries = useSelector((state) => state.flight.batteries);

  return Array.isArray(batteries) ? (
    <div className="battery-percent">
      {batteries.map((item, index) => (
        <BatteryPercentIcon key={index} value={item.capacityPercent} />
      ))}
    </div>
  ) : null;
}

export default BatteryPercent