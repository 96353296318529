import { useEffect, useState } from "react";
import { MdCheck, MdClose, MdModeEdit, MdOutlineGroups } from "react-icons/md";
import Button from "../Button/Button";
import InputBox from "../InputBox/InputBox";
import "./KeyValueForm.sass";

function KeyValueForm({ onDataChange, details,description }) {
  const [data, setData] = useState({});
  const [currentKey, setCurrentKey] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [validate, setValidate] = useState(true);
  const [add, setAdd] = useState(false);

  const filterItem = (item) => {
    const { [item]: value, ...withoutValue } = data;
    setData({ ...withoutValue });
    onDataChange({ ...withoutValue });
  };

  const newItem = () => {
    setValidate(false);
    setCurrentKey("");
    setCurrentValue("");
    setAdd(true);
  };
  const updateItem = (item) => {
    setValidate(false);
    const { [item[0]]: value, ...withoutValue } = data;
    setData({ ...withoutValue });
    setCurrentKey(item[0]);
    setCurrentValue(item[1]);
    setAdd(true);
  };
  const keyChanged = (event) => {
    setCurrentKey(event.target.value);
  };
  const valueChanged = (event) => {
    setCurrentValue(event.target.value);
  };

  const addItem = () => {
    if (currentKey !== "" && currentValue !== "") {
      // console.log(data.find((d) => d[currentKey]));
      setData({ ...data, [currentKey]: currentValue });
      onDataChange({ ...data, [currentKey]: currentValue });
      setCurrentKey("");
      setCurrentValue("");
      setAdd(false);
    }
  };

  useEffect(() => {
    setData({ ...details });
  }, [details]);

  useEffect(() => {
    Object.entries(data).map((item) =>
      setValidate(item[0] !== "" && item[1] !== "")
    );
  }, [data]);

  return (
    <div className="key-value-form">
      <div className="key-value-form-container">
        <p>{description}</p>
        <ul className="key-value-form-items">
          {Object.entries(data).map((item, index) => (
            <li className="key-value-form-item" key={index}>
              <InputBox
                type="text"
                name={item[0]}
                background="white"
                value={item[0]}
                placeHolder="Key"
                isReadOnly={true}
                onFocus={(event) => {
                  event.preventDefault();
                }}
                width="45%"
              />
              <small>:</small>
              <InputBox
                type="text"
                name={item[1]}
                background="white"
                value={item[1]}
                placeHolder="Value"
                isReadOnly={true}
                onFocus={(event) => {
                  event.preventDefault();
                }}
                width="45%"
              />
              {/* {item[0] !== currentKey && item[1] !== currentValue && ( */}
              <div className="key-value-form-item-options">
                <MdModeEdit
                  onClick={() => {
                    updateItem(item);
                  }}
                />
                <MdClose
                  onClick={() => {
                    filterItem(item[0]);
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
        {add ? (
          <div className="key-value-form-add">
            <InputBox
              type="text"
              name={currentKey}
              background="white"
              value={currentKey}
              placeHolder="Key"
              onChange={keyChanged}
              onFocus={(event) => {
                event.preventDefault();
              }}
            />
            <small>:</small>
            <InputBox
              type="text"
              name={currentValue}
              background="white"
              value={currentValue}
              placeHolder="Value"
              onChange={valueChanged}
              onFocus={(event) => {
                event.preventDefault();
              }}
            />
            <div>
              <MdCheck onClick={addItem} />
              <MdClose
                onClick={() => {
                  setAdd(false);
                  setValidate(true);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="key-value-form-button">
            <Button
              value="Add *"
              color="#2699fb"
              width="15%"
              fontSize=".75em"
              disabled={!validate}
              onClick={newItem}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default KeyValueForm;
