import CameraTelemetry from 'src/components/Dashboard/Components/CameraTelemetry/CameraTelemetry';
import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { cameraExposureModes } from 'src/helper/constants';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';
import { PayloadIndexes } from 'src/helper/HubConnection';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { FaLock, FaUnlock } from 'react-icons/fa';
import { useSelector } from 'react-redux';

function CameraAE() {
  const { hub, groups, deviceId } = useOnboardHub();
  const exposureMode = useSelector((state) => state.flight.camera?.exposureMode);
  const [autoEnabled, setAutoEnabled] = useState(false);
  const { activeCameraPayload } = useDeviceInfo();

  useEffect(() => {
    if (exposureMode)
      setAutoEnabled(exposureMode !== cameraExposureModes.PROGRAM_AUTO);
  }, [exposureMode]);

  const sendModeValue = (auto) => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      camSetting: {
        payloadIndex: activeCameraPayload?.payloadIndex,
        exposureMode: auto ? cameraExposureModes.EXPOSURE_MANUAL : cameraExposureModes.PROGRAM_AUTO,
      }
    });
  }

  const toggleAuto = () => {
    setAutoEnabled(curr => {
      const newVal = !curr;
      sendModeValue(newVal);
      return newVal;
    });
  };

  if (!activeCameraPayload?.settings?.exposure) return null;

  return exposureMode !== 4 ? (
    <CameraTelemetry
      name="ae"
      title="AE"
      value={autoEnabled ? <FaLock /> : <FaUnlock />}
      onClick={toggleAuto}
    />
  ) : null;
}

export default CameraAE