export const PsdkHmsErrorLevel = {
  MIN: 0,
  MID: 3,
  MAX: 6,
}

export const CloudApiHmsErrorLevel = {
  NOTIFICATION: 0,
  REMINDER: 1,
  WARNING: 2,
}

export const CloudApiHmsErrorModule = {
  FLIGHT_MISSION: 0,
  DEVICE_MANAGEMENT: 1,
  MEDIA: 2,
  HMS: 3,
}

export const CloudApiHmsErrorInSkyStatus = {
  NOT_IN_SKY: 0,
  IN_SKY: 1,
}

export const CloudApiHmsErrorImminentStauts = {
  NOT_IMMINENT: 0,
  IMMINENT: 1,
}

export const DjiDockMode = {
  IDLE: 0,
  ON_SITE_DEBUGGING: 1,
  REMOTE_DEBUGGING: 2,
  FIRMWARE_UPGRADE_IN_PROGRESS: 3,
  IN_OPERATION: 4,
}

export const DjiDockModeText = {
  0: 'Idle',
  1: 'On-site debugging',
  2: 'Remote debugging',
  3: 'Firmware upgrade',
  4: 'In operation',
}

export const DjiDockDroneMode = {
  STANDBY: 0,
  TAKEOFF_PREPARATION: 1,
  TAKEOFF_PREPARATION_COMPLETED: 2,
  MANUAL_FLIGHT: 3,
  AUTOMATIC_TAKEOFF: 4,
  WAYLINE_FLIGHT: 5,
  PANORAMA: 6,
  INTELLIGENT_TRACKING: 7,
  ADS_B_AVOIDANCE: 8,
  AUTO_RETURNING_TO_HOME: 9,
  AUTOMATIC_LANDING: 10,
  FORCED_LANDING: 11,
  THREE_BLADE_LANDING: 12,
  UPGRADING: 13,
  NOT_CONNECTED: 14,
  APAS: 15,
  VIRTUAL_STICK_STATE: 16,
  LIVE_FLIGHT_CONTROLS: 17,
  AIRBORNE_RTK_FIXING_MODE: 18,
  DOCK_ADDRESS_SELECTING: 19,
}

export const DjiDockDroneModeText = {
  0: 'Standby',
  1: 'Takeoff preparation',
  2: 'Takeoff preparation completed',
  3: 'Manual flight',
  4: 'Automatic takeoff',
  5: 'Wayline flight',
  6: 'Panorama',
  7: 'Intelligent tracking',
  8: 'ADS-B avoidance',
  9: 'Auto returning to home',
  10: 'Automatic landing',
  11: 'Forced landing',
  12: 'Three-blade landing',
  13: 'Upgrading',
  14: 'Not connected',
  15: 'APAS',
  16: 'Virtual stick state',
  17: 'Live Flight Controls',
  18: 'Airborne RTK fixing mode',
  19: 'Dock address selecting',
}

export const DjiCloudStreamQuality = {
  ADAPTIVE: 0,
  SMOOTH: 1,
  STANDARD_DEFINITION: 2,
  HIGHT_DEFINITION: 3,
  ULTRA_HIGHT_DEFINITION: 4,
}

export const DjiCloudStreamQualityText = {
  0: 'Adaptive',
  1: 'Smooth',
  2: 'Standard Definition',
  3: 'High Definition',
  4: 'Ultra High Definition',
}

export const DjiCloudLinkWorkMode = {
  SDR: 0,
  FUSION_4G: 1,
}

export const DjiCloudLinkWorkModeText = {
  0: 'SDR',
  1: '4G Fusion',
}

export const DjiCloudNetworkType = {
  LTE: 1,
  Ethernet: 2,
}

export const DjiCloudNetworkTypeText = {
  1: 'LTE',
  2: 'Ethernet',
}

export const DjiDockDroneCameraMode = {
  CAPTURE: 0,
  RECORDING: 1,
  SMART_LOW_LIGHT: 2,
  PANORAMA: 3
}

export const DjiDockDroneRecordingState = {
  IDLE: 0,
  RECORDING: 1
}

export const DjiCloudDrcState = {
  DISABLED: 0,
  REQUESTED: 1,
  ENABLED: 2
}