import classNames from 'classnames';
import './MapUnitMarker.sass';
import { MdMapsHomeWork } from 'react-icons/md';

function MapUnitMarker({title, className}) {
  return (
    <div className={classNames("map-unit-marker", className)} title="Unit">
      <div className="icon">
        <MdMapsHomeWork />
      </div>
      <div className="title">
        {title}
      </div>
    </div>
  )
}

export default MapUnitMarker