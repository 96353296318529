import { useEffect, useState } from "react";
import Button from "../Button/Button";
import "./Form.sass";

function Form({ className, data, required, children, submit, validate }) {
  const [matches, setMatches] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(validate);
  }, [validate]);
  useEffect(() => {
    let res = 0;
    Object.keys(data).forEach((item) => {
      required.includes(item) && res++;
    });
    setMatches(res === required.length);
  }, [data, required]);

  return (
    <form className={className} onSubmit={submit.action}>
      {children}
      {submit && (
        <Button
          value={submit.title}
          disabled={!matches || !valid || submit.disabled}
          width={submit.width}
          color={submit.color}
          className={submit?.className}
        />
      )}
    </form>
  );
}

export default Form;
