import classNames from 'classnames'
import { cameraSource, laserRangeEnabled } from 'src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice';
import { useDispatch, useSelector } from 'react-redux';
import laserIcon from "src/assets/img/laser.svg";
import './LaserRangeToggle.sass';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';

function LaserRangeToggle({ className }) {
  const dispatch = useDispatch();
  const enabled = useSelector((state) => state.flight?.laserRangeEnabled);
  const mainVideoSource = useSelector((state) => state.flight?.mainVideoSource);
  const { activeCameraPayload } = useDeviceInfo();

  if (!activeCameraPayload?.laserRangeFinder || mainVideoSource === cameraSource.FPV) return null;

  return (
    <div
      className={classNames('laser-range-toggle', className, { 'active': enabled })}
      onClick={() => dispatch(laserRangeEnabled())}
    >
      <img
        src={laserIcon}
        className="laser-icon"
        alt="Laser range toggle"
        title="Laser range finder"
      />
    </div>
  )
}

export default LaserRangeToggle