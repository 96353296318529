import classNames from 'classnames'
import { cameraSource } from 'src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice';
import { footToMeterRatio } from 'src/helper/constants';
import { TbFocus2 } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import './LaserRangeDetails.sass'

function LaserRangeDetails({ className }) {
  const enabled = useSelector((state) => state.flight?.laserRangeEnabled);
  const rangeInfo = useSelector((state) => state.flight?.camera?.rangingInfo);
  const mainVideoSource = useSelector((state) => state.flight?.mainVideoSource);

  if (!enabled || mainVideoSource === cameraSource.FPV) return null;

  return (
    <div className={classNames('laser-range-details', className)}>
      <div className="content">
        <TbFocus2 className="main-icon" />
        <div className="details">
          {!rangeInfo?.exception || rangeInfo?.exception === 3 ? (
            <>
              <div>ASL {rangeInfo?.alt ? Number((rangeInfo.alt / 10) * footToMeterRatio).toFixed() + ' ft' : 'N/A'}</div>
              <div>{(rangeInfo?.lat.toFixed(5) ?? '-') + ' S'}, {(rangeInfo?.long.toFixed(5) ?? '-') + ' E'}</div>
            </>
          ) : rangeInfo?.exception === 2 ? 'Subject is too close to laser' : 'Measurement is not available now'}
        </div>
      </div>
    </div>
  )
}

export default LaserRangeDetails