import { MdSignalCellular0Bar, MdSignalCellular1Bar, MdSignalCellular2Bar, MdSignalCellular3Bar, MdSignalCellular4Bar } from "react-icons/md";

const SignalQualityIcon = ({ quality }) => {
  switch(quality) {
    case 0:
      return <MdSignalCellular0Bar />;
    case 1:
    case 2:
      return <MdSignalCellular1Bar />;
    case 3:
      return <MdSignalCellular2Bar />;
    case 4:
      return <MdSignalCellular3Bar />;
    case 5:
      return <MdSignalCellular4Bar />;
  }
}

export default SignalQualityIcon;