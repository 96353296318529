import { useEffect, useState } from "react";
import "./CameraAction.sass";

function CameraAction({ item, index }) {
  const [action, setAction] = useState({});
  useEffect(() => {
    setAction(item);
  }, [item]);
  return (
    <li className="camera-action" key={index} onClick={action.eventHandler}>
      {action.icon}
    </li>
  );
}

export default CameraAction;
