import { useState } from "react";
import { useSpring, animated } from "react-spring";
import OutSideDetector from "../../../ui/OutsideDetector/OutSideDetector";
import ProfileInformation from "../ProfileInformation/ProfileInformation";
import "./MobileProfileNavigation.sass";

function MobileProfileNavigation({ isOpen, toggle }) {
  const [open, setOpen] = useState(isOpen);
  const toggleOff = () => {
    setOpen(false);
  };
  const props = useSpring({
    to: {
      opacity: open ? 1 : 0,
    },
    from: { opacity: 0 },
    onRest: () => {
      if (!open) toggle();
    },
  });
  return (
    <OutSideDetector detect={toggleOff}>
      <animated.div className="mobile-profile-navigation" style={props}>
        <ProfileInformation onClose={toggleOff} />
      </animated.div>
    </OutSideDetector>
  );
}

export default MobileProfileNavigation;
