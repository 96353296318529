import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../../../../helper/use-debounce";
import Form from "../../../../../../ui/Form/Form";
import InputBox from "../../../../../../ui/InputBox/InputBox";
import KeyValueFormNew from "../../../../../../ui/KeyValueFormNew/KeyValueFormNew";
import { setOperation } from "../../../../AdminOperationManager/OperationStepSlice";
import {
  checkContainerName,
  createContainer,
} from "../../S3BucketSlice";

import "./CreateContainer.sass";

function CreateContainer({ onOpen, onConfirm }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [required, setRequired] = useState(["name"]);
  const [validate, setValidate] = useState(false);
  const [alerts, setAlerts] = useState({});
  const storage = useSelector((state) => state.s3Storage);
  const container = useSelector((state) => state.s3Bucket);
  const [currentStorage, setCurrentStorage] = useState({});
  const [tagErrors, setTagErrors] = useState([]);
  const [icons, setIcons] = useState({ Name: "default" });
  const [Name, SetName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(Name, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(checkContainerName(currentStorage?.id, Name));
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    console.log({model: storage.model});
    if(storage.model?.id)
      setCurrentStorage(storage.model);
  }, [storage.model]);

  useEffect(() => {
    dispatch(setOperation("create_container"));
  }, []);

  useEffect(() => {
    onOpen();
  }, [data]);

  useEffect(() => {
    if (container.create?.status === "success") {
      onConfirm();
    }
    if (container.create?.status === "validation") {
      setTagErrors(
        container.create.errors
          .filter((error) =>
            error["propertyName"].includes("Properties.Metadata")
          )
          .map((item) => parseInt(item["propertyName"].match(/\d+/)))
      );
    }

    setIsLoading(false);
  }, [container.create]);

  const onKeyValueChange = (tags) => {
    setData({ ...data, properties: { metaData: { ...tags } } });
    setTagErrors([]);
  };
  const submitHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);
    dispatch(createContainer(currentStorage.id, { groups: [], ...data }));
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };
  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    if (key === "name") {
      // setValidate(false);
      setValidate(true);
      setIcons({ ...icons, Name: "default" });
      // SetName(value);
    }
    setAlerts({ ...alerts, [key]: { alert: false } });
    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };

  useEffect(() => {
    setIcons({ ...icons, Name: container.name.status });
    if (container.name.status === "validation") {
      container.name.errors.forEach((item) => {
        setAlerts({
          ...alerts,
          [item.propertyName]: {
            alert: true,
            type: "error",
            message: item.errorMessage,
          },
        });
      });
    } else if (container.name.status === "forbidden") {
      setAlerts({
        ...alerts,
        [container.name.message.instance]: {
          alert: true,
          type: "error",
          message: container.name.message.error,
        },
      });
    } else if (container.name.status === "success") {
      setValidate(true);
    }
  }, [container.name]);

  return (
    <div className="create-container-form">
      <Form
        submit={{
          title: "Confirm",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        className="create-container-container"
        validate={validate}
      >
        <InputBox
          isRequired={required.includes("name")}
          title="Bucket Name"
          name="name"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Name}
          value={data.Name}
          index={0}
          background="white"
          width="100%"
          icon={icons.Name}
        />
        <InputBox
          isRequired={required.includes("description")}
          title="Bucket Description"
          name="description"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Description}
          value={data.Description}
          index={1}
          background="white"
          width="100%"
        />
      </Form>
    </div>
  );
}

export default CreateContainer;
