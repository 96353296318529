import useDockList from 'src/helper/useDockList';
import './MixedDeviceSelection.sass'
import useOnboardDeviceList, { DeviceOnlineStatus } from 'src/helper/useOnboardDeviceList'
import { deviceTypes } from 'src/helper/constants';
import { useMemo, useState } from 'react';
import DeviceCard from './DeviceCard/DeviceCard';
import classNames from 'classnames';
import TextInput from 'src/hci/common/TextInput/TextInput';
import { FiSearch } from 'react-icons/fi';
import Loading from 'src/ui/Loading/Loading';
import { useSelector } from 'react-redux';

function MixedDeviceSelection({ className, label, onChange }) {
  const [search, setSearch] = useState('');
  const [selectedId, setSelectedId] = useState();
  const devices = useSelector(state => state.deviceService.devices);

  const devicesList = useMemo(() => {
    if(!devices || !devices.length) return [];

    const allDevices = devices.map(device => ({
      ...device,
      isOnline: device.onlineStatus === DeviceOnlineStatus.ONLINE,
    }));

    allDevices.sort((a, b) => {
      if (a.isOnline === b.isOnline) return 0;
      return a.isOnline ? -1 : 1;
    });

    if(search) {
      return allDevices.filter(item => (item.title || item.devieName || item.serialNumber || item.manuafturer)?.toLowerCase()?.includes(search.toLowerCase()));
    }

    return allDevices;
  }, [devices, search]);

  const handleOnSearchInputChange = (e) => {
    setSearch(e.target.value);
  }

  const handleOnItemClick = (data) => {
    setSelectedId(data.id);
    onChange?.(data);
  }

  return (
    <div className={classNames("mixed-device-selection", className)}>
      {label && <div className="section-label">{label}</div>}
      <TextInput placeholder="Search..." postfix={<FiSearch />} className="search-input" onChange={handleOnSearchInputChange} />
      <div className="device-list">
        {devicesList.map(item => (
          <DeviceCard 
            key={item.id} data={item} 
            onClick={() => handleOnItemClick(item)} 
            className={classNames({'active': item.id == selectedId})} 
          />
        ))}
      </div>
    </div>
  )
}

export default MixedDeviceSelection