import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import useCurrentUserId from "./useCurrentUserId";
import { generateId } from "./utils";

export function useBroadcastToFrontend() {
  const { hub: frontendHub, group: frontendGroup } = useFrontendHub();
  const currUserId = useCurrentUserId();

  const broadcast = (method, data, bid) => {
    if(method && data) {
      frontendHub.sendToGroup(currUserId, frontendGroup?.send?.broadcast, {
        bid: bid || generateId(),
        method,
        data,
      });
    }
  }

  return broadcast;
}