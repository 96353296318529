import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../redux/action/api";

const UserProfileSlice = createSlice({
  name: "profile",
  initialState: {
    basic: { status: "" },
    model: {},
  },
  reducers: {
    basic(state, action) {
      const data = action.payload;
      return { ...state, basic: { ...data } };
    },
  },
});
export const { basic } = UserProfileSlice.actions;
export default UserProfileSlice.reducer;

export const getBasicInfo = (data) =>
  apiCallBegan({
    url: "users/profile",
    method: "Get",
    data,
    onSuccess: basic.type,
    onError: basic.type,
    baseURI: "http://localhost:7073/api/v1.0/",
  });
