import React, { useState, useMemo, useEffect } from 'react';
import './UserProfileGroups.sass';
import OptionSelector from 'src/ui/OptionSelector/OptionSelector';
import SearchOption from 'src/ui/SearchOption/SearchOption';
import AppTable from 'src/ui/AppTable/AppTable';
import GroupMembershipApp from 'src/ui/GroupApp/GroupMembershipApp/GroupMembershipApp';
import OperationHandler from 'src/ui/OperationHandler/OperationHandler';
import { useDispatch, useSelector } from 'react-redux';
import { setOperation } from 'src/components/Dashboard/Components/ApplicationOperationManager/AppOperationStepSlice';
import { currentGroup, getGroupsInformation } from 'src/ui/GroupApp/GroupAppSlice';
import { BiPlusCircle } from 'react-icons/bi';
import { getUserGroups } from 'src/redux/ducks/accountStateSlice';
import Loading from 'src/ui/Loading/Loading';
import { waitForStore } from 'src/helper/utils';
import UserProfileGroupsMembership from './UserProfileGroupsMembership/UserProfileGroupsMembership';

function UserProfileGroups() {
  const [groupMembershipPanelActive, setGroupMembershipPanelActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const groups = useSelector(state => state.accountState.groups);

  const filterSelectors = [
    { title: "All Groups", value: "all", active: true },
    { title: "Owner", value: "owner" },
    { title: "Member", value: "member" },
  ];

  const [groupsListFilter, setgroupsListFilter] = useState(
    filterSelectors.filter((x) => x.active)[0]?.value
  );

  const [tableRows, setTableRows] = useState({
    headers: [
      { title: "Group ID", value: "id", status: false },
      { title: "Group Name", value: "name", status: true },
      { title: "Roles", value: "membership", status: true },
      { title: "", value: "tools", status: true },
    ],
    values: [],
    options: [],
  });

  const filteredGroupRows = useMemo(() => {
    if (groupsListFilter === "all") return tableRows;

    return {
      ...tableRows,
      values: [
        ...tableRows.values.filter((item) =>
          groupsListFilter === "member" ? item.membership.includes('member') : item.membership.includes('owner')
        ),
      ],
    };
  }, [tableRows, groupsListFilter]);

  const openMemberManagement = async (group) => {
    setLoading(true);
    dispatch(getGroupsInformation([group.id]));

    const result = await waitForStore(state => state.groupApp.groupsInformation, value => value.status === 'success');
    const groupInfo = result?.data?.groups?.[0];
    setLoading(false);
    console.log(result);

    if (!groupInfo)
      return;

    dispatch(currentGroup({
      ...group,
      isOwner: group.membership.includes('owner'),
      isMember: group.membership.includes('member'),
      members: groupInfo.members,
    }));

    setGroupMembershipPanelActive(true);
  }

  useEffect(() => {
    setTableRows({
      ...tableRows,
      values: groups.data?.groups?.map((item) => ({
        ...item,
        tools: item.membership.includes('owner') ? (
          <div
            className="add-member-button"
            onClick={() => openMemberManagement(item)}
          >
            <BiPlusCircle />
            <span>Add Member</span>
          </div>
        ) : '',
      })) || [],
    });

    if (groups.status === 'success')
      setLoading(false);
  }, [groups]);

  useEffect(() => {
    setLoading(true);
    dispatch(getUserGroups());
  }, []);

  const filterSelectorsWithActiveOption = useMemo(() => filterSelectors.map((item) => {
    if (!groupsListFilter) return item;
    return { ...item, active: item.value === groupsListFilter };
  }), [groupsListFilter]);

  const optionSelectorHandler = (item) => {
    setgroupsListFilter(item.value);
  };

  return (
    <div className="user-profile-groups">
      <h2>Groups</h2>
      <div className="section-title">Groups I'm in:</div>
      <div className="table-tools">
        <OptionSelector
          options={filterSelectorsWithActiveOption}
          selectHandler={optionSelectorHandler}
        />
        <SearchOption />
      </div>
      <div className="groups-table-container">
        <AppTable
          rows={filteredGroupRows}
          onRowChange={() => { }}
          onRowsChange={() => { }}
          optionChangeHandler={() => { }}
          tags={[]}
          disableColumnSelector={true}
        />
      </div>
      {groupMembershipPanelActive && (
        <UserProfileGroupsMembership
          onClose={() => setGroupMembershipPanelActive(false)}
        />
      )}
      {loading && <Loading />}
    </div>
  )
}

export default UserProfileGroups