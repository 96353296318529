import './DroneViewer.sass';
import CollapsablePanel from '../CollapsablePanel/CollapsablePanel';
import { RiFoggyLine, RiRainyLine } from 'react-icons/ri';
import { MdHeight, MdOutlineSatelliteAlt, MdOutlineUploadFile } from 'react-icons/md';
import { TbCameraQuestion, TbNetwork, TbTemperature } from 'react-icons/tb';
import { FiBatteryCharging, FiWind } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'src/helper/AccountStateProvider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFrontendHub } from 'src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider';
import DockHubProvider, { useDockHub } from 'src/components/Dashboard/Components/DockHubProvider/DockHubProvider';
import VideoStreamView from 'src/ui/Drone/VideoStreamView/VideoStreamView';
import useOnboardStreamingInfo from '../common/useOnboardStreamingInfo';
import { useVideoStream } from 'src/helper/useVideoStream';
import { DeviceOnlineStatus } from 'src/helper/useOnboardDeviceList';
import { IoCloudOfflineOutline } from 'react-icons/io5';
import { StageElement } from '../MissionTracking';

function DroneViewer({ deviceId, onSwap }) {
  const { account } = useAccount();
  const dispatch = useDispatch();
  const serverMessageHandlerIds = useRef([]);
  const groupMessageHandlerIds = useRef([]);
  const deviceInfo = useSelector((state) => state.deviceService.devices?.find(item => item.id === deviceId));
  const lastTelemetries = useSelector((state) => state.deviceService.deviceTelemetries?.[deviceId]);
  const streamingInfo = useOnboardStreamingInfo(deviceId);
  const { mediaStream: fpvMediaStream } = useVideoStream(streamingInfo?.['fpvViewerConfig']);
  const { mediaStream: mainMediaStream } = useVideoStream(streamingInfo?.['mainViewerConfig']);

  const handleSwap = () => {
    onSwap?.(StageElement.DRONE);
  }

  const telemetries = [
    {
      name: 'Altitude',
      value: `${lastTelemetries?.gps?.alt ?? '-'}m`,
      icon: <MdHeight />
    },
    {
      name: 'Battery',
      value: `${lastTelemetries?.batteryPercent?.[0]?.capacityPercent ?? '-'}%`,
      icon: <FiBatteryCharging />
    },
    {
      name: 'Satellites',
      value: `${lastTelemetries?.gps?.visibleSats ?? '-'}`,
      icon: <MdOutlineSatelliteAlt />
    }
  ];

  const connectionMessage = (deviceInfo?.onlineStatus !== DeviceOnlineStatus.ONLINE ?
    'No drone connection.' :
    'Waiting for stream...'
  );

  const connectionMessageIcon = (deviceInfo?.onlineStatus !== DeviceOnlineStatus.ONLINE ?
    <IoCloudOfflineOutline className="icon" /> :
    <TbCameraQuestion className="icon" />
  );

  return (
    <div className="drone-viewer">
      <CollapsablePanel title="Paired Drone" telemetries={telemetries}  onSwap={handleSwap}>
        <div className="stream-container">
          <div className="connection-message">
              {connectionMessageIcon}
              <div className="description">{connectionMessage}</div>
          </div>
          <VideoStreamView stream={fpvMediaStream || mainMediaStream} className="video-view" />
        </div>
      </CollapsablePanel>
    </div>
  )
}

export default DroneViewer