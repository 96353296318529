import { useMsal } from "@azure/msal-react";
import { newFrontendConnection } from "src/components/Dashboard/Components/WebsocketHandler/WebsocketSlice";
import Header from "src/components/Header/Header";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckAccountState,
  state,
  permissions,
  getUserPermissions,
  getUserProfile,
  profile,
} from "../redux/ducks/accountStateSlice";

const AccountStateContext = createContext({});
export const AccountStateProvider = ({ children }) => {
  const accountState = useSelector((state) => state.accountState.state);
  const accountPermissions = useSelector(
    (state) => state.accountState.permissions
  );
  const profileState = useSelector((state) => state.accountState.profile);
  const token = useSelector((state) => state.api.token);
  const [accountStatus, setAccountStatus] = useState(accountState);
  const [privileges, setPrivileges] = useState(accountPermissions);
  const [currentUser, setCurrentUser] = useState();
  const [account, setAccount] = useState({ instance: null, user: {} });
  const [call, setCall] = useState(false);

  const dispatch = useDispatch();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (accounts[0]) {
      setAccount({
        instance: instance,
        user: accounts[0] !== undefined ? accounts[0] : null,
      });
    }
  }, [instance]);

  useEffect(() => {
    if (token !== undefined) {
      setCall(true);
    }
  }, [token]);

  useEffect(() => {
    if (call) {
      dispatch(CheckAccountState());
      dispatch(getUserPermissions(account.user.localAccountId));
      dispatch(getUserProfile());
    }
  }, [call]);

  useEffect(() => {
    if (accountState.status === "success") {
      setAccountStatus(accountState.data.accountStatus);
      dispatch(state({ status: "" }));
    }
  }, [accountState]);

  useEffect(() => {
    if (profileState.status === "success") {
      setCurrentUser({
        profile: { ...profileState?.data?.profile },
        avatar: profileState?.data?.avatar?.url,
      });
      dispatch(profile({ status: "" }));
    }
  }, [profileState]);

  useEffect(() => {
    if (accountPermissions.status === "success") {
      setPrivileges(accountPermissions.data);
      dispatch(permissions({ status: "" }));
      dispatch(newFrontendConnection(account.user.localAccountId));
    }
  }, [accountPermissions]);

  return (
    <AccountStateContext.Provider
      value={{ accountStatus, account, privileges, currentUser }}
    >
      {children}
    </AccountStateContext.Provider>
  );
};
export const useAccountState = () => {
  const { accountStatus } = useContext(AccountStateContext);
  return { accountStatus };
};
export const useAccount = () => {
  const { account } = useContext(AccountStateContext);
  return { account };
};

export const useCurrentUser = () => {
  const { currentUser } = useContext(AccountStateContext);
  return { currentUser };
};

export const usePrivileges = () => {
  const { privileges } = useContext(AccountStateContext);
  return { privileges };
};

