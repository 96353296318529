import React, { useEffect, useState } from "react";
import { MdNavigation } from "react-icons/md";
import { useSelector } from "react-redux";

function GPSMarker({ size, currHeading }) {
  const compass = useSelector((state) => state.flight.compass);
  const [animate, setAnimate] = useState(true);
  const [heading, setHeading] = useState(currHeading || 0);

  useEffect(() => {
    setHeading(curr => {
      setAnimate(Math.abs(compass.heading - curr) < 270);
      return compass.heading;
    });
  }, [compass]);

  useEffect(() => {
    setHeading(curr => {
      setAnimate(Math.abs(currHeading - curr) < 270);
      return currHeading;
    });
  }, [currHeading]);

  return (
    <MdNavigation
      color="#ff0000"
      style={{ 
        transform: `rotate(${heading}deg)`,
        fontSize: `var(--markerSize, ${size ? size+'em' : '2em'})`,
        transition: animate ? 'all 0.3s' : 'none',
       }}
    />
  );
}

export default GPSMarker;
