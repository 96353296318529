import "./CircleAvatar.sass";

function CircleAvatar({ size, fontSize, name, color }) {
  return (
    <span
      className="circle-avatar"
      style={{
        color: "white",
        width: `${size}em`,
        height: `${size}em`,
        fontSize,
        background: color,
      }}
    >
      {name}
    </span>
  );
}

export default CircleAvatar;
