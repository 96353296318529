import { createSlice } from "@reduxjs/toolkit";
const VerificationCodeSlice = createSlice({
  name: "verification",
  initialState: {},
  reducers: {
    getVerificationCode(state, action) {
      const selectData = action.payload;
      return { ...state, ...selectData };
    },
  },
});
export const { getVerificationCode } = VerificationCodeSlice.actions;
export default VerificationCodeSlice.reducer;
