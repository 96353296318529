import { useState } from 'react';
import './NetworkInfo.sass'
import NetworkSignal from 'src/ui/Drone/DroneComponents/NetworkSignal/NetworkSignal'
import { MdClose } from 'react-icons/md';
import SpeedTest from './SpeedTest/SpeedTest';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

function NetworkInfo() {
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const modemInfo = useSelector((state) => state.flight.modemInfo);

  const getNetworkTypeAbbr = (networkType) => {
    const type = networkType?.toUpperCase();

    if (type === 'WCDMA')
      return '3G';
    else if (type.indexOf('LTE') !== -1)
      return '4G';
    else
      return type;
  }

  const getSignalStrength = (rssi) => {
    if (rssi >= 1 && rssi <= 5)
      return 1;
    else if (rssi <= 15)
      return 2;
    else if (rssi <= 25)
      return 3;
    else if (rssi <= 31)
      return 4;
    else
      return 0;
  }

  if (!modemInfo)
    return null;

  return (
    <div className="network-info">
      <div className={classNames("status-bar-content", { 'active': showInfoPanel })} onClick={() => setShowInfoPanel(curr => !curr)}>
        <NetworkSignal type={modemInfo?.networkType ? getNetworkTypeAbbr(modemInfo.networkType) : 'n/a'} strength={getSignalStrength(modemInfo?.signalQuality)} />
      </div>
      {!!showInfoPanel && (
        <div className="info-panel">
          <div className="info-panel-header">
            <div className="title">Network Info</div>
            <MdClose className="close-icon" onClick={() => setShowInfoPanel(false)} />
          </div>
          <div className="info-panel-content">
            <div className="info">
              <div className="info-item">
                <strong>Operator</strong>
                <span>{modemInfo?.operator || 'n/a'}</span>
              </div>
              <div className="info-item">
                <strong>Phone number</strong>
                <span>{modemInfo?.phoneNumber || 'n/a'}</span>
              </div>
              <div className="info-item">
                <strong>ٔNetwork type</strong>
                <span>{modemInfo?.networkType || 'n/a'}</span>
              </div>
              <div className="info-item">
                <strong>Signal quality</strong>
                <span>{modemInfo?.signalQuality || 'n/a'}</span>
              </div>
              <div className="info-item">
                <strong>Module name</strong>
                <span>{modemInfo?.moduleName || 'n/a'}</span>
              </div>
            </div>
          </div>
          <div className="speed-test-container">
            <div className="info-panel-header">
              <div className="title">Internet Speed Test</div>
            </div>
            <SpeedTest />
          </div>
        </div>
      )}
    </div>
  )
}

export default NetworkInfo