import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CameraTelemetry from "../../../../components/Dashboard/Components/CameraTelemetry/CameraTelemetry";
import CameraIsoSelector from "./CameraIsoSelector/CameraIsoSelector";
import "./CameraIso.sass";
import { useDeviceInfo } from "src/helper/DeviceInfoProvider";
import { cameraExposureModes } from "src/helper/constants";

function CameraIso() {
  const flight = useSelector((state) => state.flight);
  const exposureMode = useSelector((state) => state.flight.camera?.exposureMode);
  const { activeCameraPayload } = useDeviceInfo();
  const [currValue, setCurrValue] = useState("Auto");

  useEffect(() => {
    setCurrValue(flight.camera?.iso < 100 ? "Auto" : flight.camera?.iso);
  }, [flight.camera]);

  if (!activeCameraPayload?.settings?.iso) return null;

  return (
    <CameraTelemetry
      name="iso"
      title="ISO"
      value={currValue}
      selector={<CameraIsoSelector />}
      disabled={exposureMode === cameraExposureModes.PROGRAM_AUTO}
    />
  );
}

export default CameraIso;
