import { useSelector } from "react-redux";
import useDeviceTelemetries from "./useDeviceTelemetries";
import { deviceTypes } from "src/helper/constants";
import { getDeviceModelInfo } from "./deviceUtils";

export default function useDeviceInfo (deviceId) {
  const deviceInfo = useSelector(state => {
    return state.deviceService.devices.find(device => device.id === deviceId);
  });

  const modelInfo = useDeviceTelemetries(deviceId, (telemetries) => {
    if (deviceInfo?.type === deviceTypes.DRONE && telemetries?.droneInfo) 
      return ({
        model: telemetries.droneInfo.model,
        modelNumber: telemetries.droneInfo.modelNumber,
        details: getDeviceModelInfo(telemetries.droneInfo.model),
      });
    else return null;
  });

  return {
    ...deviceInfo,
    modelInfo,
  };
}