import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { setContext } from "../../../../redux/ducks/appContextSlice";
import Portrait from "../../../../ui/Portrait/Portrait";
import "./StorageApp.sass";
import StorageContainerApp from "./StorageContainerApp/StorageContainerApp";
import StorageHome from "./StorageHome/StorageHome";

function StorageApp() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setContext({
        theme: { color: "#9c27b0", name: "storage", highlight: "#9c27b011" },
      })
    );
  });

  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Portrait>
          <StorageHome />
        </Portrait>
      </Route>
      <Route path={`${path}/container`}>
        <Portrait>
          <StorageContainerApp />
        </Portrait>
      </Route>
    </Switch>
  );
}

export default StorageApp;
