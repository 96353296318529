import classNames from 'classnames'
import React from 'react'
import { useState } from 'react';
import { BsCaretLeftFill } from 'react-icons/bs';
import { FiCloudOff } from 'react-icons/fi';
import Loading from 'src/ui/Loading/Loading';
import './ObserverDevicePanel.sass'

function ObserverDevicePanel({ onDeviceClick, loading, collapsed, deviceList = [], activeIds = [] }) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const handleToggleCollapse = () => {
    setIsCollapsed(curr => !curr);
  }

  return (
    <div className={classNames("observer-device-panel", { 'collapsed': isCollapsed })} onClick={() => isCollapsed && handleToggleCollapse()}>
      <div className="panel-header">
        <div className="panel-title">Devices</div>
        <BsCaretLeftFill className="collapse-button" title="Hide device list" onClick={handleToggleCollapse} />
      </div>
      {deviceList?.length ? (
        <div className="device-list">
          {deviceList.map(item => (
            <div
              className={classNames("device-item", { 'active': activeIds.includes(item.id) })}
              key={item.id}
              onClick={() => onDeviceClick?.(item.id)}
            >
              <div className="title">{item.name || item.model}</div>
              <div className={classNames("status", { 'status-online': item.isOnline })}>
                <div className="indicator"></div>
                <div className="value">{item.isOnline ? 'online' : 'offline'}</div>
              </div>
            </div>
          ))}
        </div>
      ) : !loading && (
        <div className="status-message">
          <FiCloudOff className="icon" />
          <div className="message">No device found</div>
        </div>
      )}
      {loading && <Loading dark={true} />}
    </div>
  )
}

export default ObserverDevicePanel