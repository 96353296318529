import "./Portrait.sass";
import { useLandscape } from "../../helper/OrientationProvider";
import ViewPortResource from "../ViewPortResource/ViewPortResource";

function Portrait({ background, color, message, children, closable }) {
  const { landscape } = useLandscape();
  return landscape ? (
    <div className="portrait show" style={{ background }}>
      <ViewPortResource color={color} message={message} closable={closable} />
    </div>
  ) : (
    children
  );
}
Portrait.defaultProps = {
  background: "black",
  color: "white",
};
export default Portrait;
