import "./DockManagerApp.sass";
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getList, list } from "../OnboardDeviceApp/OnboardDeviceAppSlice";
import { useState } from "react";
import { generateId } from "src/helper/utils";
import classNames from "classnames";
import { useAccount, usePrivileges } from "src/helper/AccountStateProvider";
import { useRef } from "react";
import { HubEventName, HubName } from "src/helper/HubConnection";
import SideListPanel from "src/hci/organism/SideListPanel/SideListPanel";
import { Tabs } from "@radix-ui/themes";
import SideListItem from "src/hci/organism/SideListPanel/SideListItem/SideListItem";
import { RiFolderOpenLine, RiRouteFill } from "react-icons/ri";
import { FiBox, FiUsers } from "react-icons/fi";
import useDockList, { DeviceOnlineStatus } from "src/helper/useDockList";
import { getDockDetails, selectedDockId } from "./DockManagerAppSlice";
import DockManagerConsole from "./DockManagerConsole/DockManagerConsole";
import WebsocketDockHubProvider from "src/components/Dashboard/Components/WebsocketDockHubProvider/WebsocketDockHubProvider";
import DockHubProvider from "src/components/Dashboard/Components/DockHubProvider/DockHubProvider";
import { setIdentity } from "src/components/Dashboard/Components/WebsocketHandler/DockIdentitySlice";
import { dockConnectionManager } from "src/helper/HubConnectionManager";
import { headerBreadcrumb } from "src/store/UiSlice";
import DockListCard from "./DockListCard/DockListCard";
import { BiPlus } from "react-icons/bi";
import Dialog from "src/hci/common/Dialog/Dialog";
import AddDockDialog from "./AddDockDialog/AddDockDialog";
import DeviceMap from "src/components/DeviceMap/DeviceMap";
import AppGroupsSideList from "src/components/AppGroupsSideList/AppGroupsSideList";

function DockManagerApp() {
  const { privileges } = usePrivileges();
  const {docks, loading: loadingDocks} = useDockList('dock-manager');
  const [selectedListItemsArray, setSelectedListItemsArray] = useState([]);
  const [mapRenderId, setMapRenderId] = useState(0);
  const selectedListItemId = useMemo(() => selectedListItemsArray[0], [selectedListItemsArray]);
  const selectedListItemData = useMemo(() => docks.find(item => item.id === selectedListItemId), [selectedListItemId, docks]);
  const dispatch = useDispatch();

  const accessStatus = useMemo(() => ({
    groupOwnership: privileges?.applications?.find(item => item.id === "groups")?.privileges.some(item => item === "groups_app_ownership"),
    groupMembership: privileges?.applications?.find(item => item.id === "groups")?.privileges.some(item => item === "groups_app_membership"),
  }), [privileges]);

  const listItems = loadingDocks ? [] : docks.map(item => {
    const data = {
      ...item,
      isOnline: item.onlineStatus === DeviceOnlineStatus.ONLINE ? true : false,
    };

    return {
      id: item.id,
      content: (
        <SideListItem data={data}>
          <DockListCard data={data} />
        </SideListItem>
      )
    };
  });

  const panelActions = [
    {
      title: "Add Dock",
      content: (
        <Dialog 
          title="Add Dock" 
          trigger={(
            <BiPlus />
          )}
        >
          <Dialog.Body>
            <AddDockDialog />
          </Dialog.Body>
        </Dialog>
      )
    }
  ];

  const handleListItemClick = id => {
    setSelectedListItemsArray([id]);
    dispatch(setIdentity(id));
    dispatch(selectedDockId(id));
  }

  const handleDocksListPanelCollapse = () => {
    setMapRenderId(Date.now());
  }

  useEffect(() => {
    dispatch(headerBreadcrumb([
      {title: "Dashboard", link: "/dashboard"},
      {title: "Dock Manager", link: "/dashboard/device-app/dock-manager"}
    ]))
  }, []);

  return (

      <div className="dock-manager-app">
        <SideListPanel
          panelTitle="Dock List"
          listItems={listItems}
          actions={panelActions}
          activeIds={selectedListItemsArray}
          loading={loadingDocks}
          onListItemClick={handleListItemClick}
          onCollapse={handleDocksListPanelCollapse}
        />
        <div className="stage">
          {selectedListItemId ? (
            <Tabs.Root className="app-options" defaultValue="overview" orientation="vertical">
              <Tabs.List className="tabs-list">
                <Tabs.Trigger value="overview">
                  <FiBox />
                </Tabs.Trigger>
                <Tabs.Trigger value="mission">
                  <RiRouteFill />
                </Tabs.Trigger>
                <Tabs.Trigger value="storage">
                  <RiFolderOpenLine />
                </Tabs.Trigger>
                {(accessStatus.groupMembership || accessStatus.groupOwnership) && (
                  <Tabs.Trigger value="group">
                    <FiUsers />
                  </Tabs.Trigger>
                )}
              </Tabs.List>
              <div className="tabs-content">
                <Tabs.Content value="overview">
                  <DockManagerConsole dockId={selectedListItemId} />
                </Tabs.Content>
                <Tabs.Content value="mission">
                  Mission
                </Tabs.Content>
                <Tabs.Content value="storage">
                  Storage
                </Tabs.Content>
                {(accessStatus.groupMembership || accessStatus.groupOwnership) && (
                  <Tabs.Content value="group">
                    <AppGroupsSideList ids={selectedListItemData?.groups} />
                  </Tabs.Content>
                )}
              </div>
            </Tabs.Root>
          ) : null}
          <DeviceMap resizeTrigger={mapRenderId} />
        </div>
      </div>

  );
}

export default DockManagerApp;
