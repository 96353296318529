import useUnitList from "src/components/Dashboard/ApplicationsArea/SecurityApp/common/useUnitList"
import { setExecuteMissionResult, setIssueMissionResult, setOperationUnits } from "./MissionServiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useIssueMission } from "./common/useIssueMission";
import useMissionObserver from "./common/useMissionObserver";
import useMissionCloudAgent from "./common/useMissionCloudAgent";

export const useMissionService = () => {
  const dispatch = useDispatch();
  const { data: unitList, loading } = useUnitList();
  const runMissionRequest = useSelector((state) => state.missionService.runMissionRequest);
  const { issueResult, executeResult, sendIssueMission } = useIssueMission();
  const observer = useMissionObserver();
  const missionCloudAgent = useMissionCloudAgent();

  useEffect(() => {
    if (unitList) {
      dispatch(setOperationUnits(unitList));
    }
  }, [unitList]);

  useEffect(() => { runMissionRequest && sendIssueMission(runMissionRequest) }, [runMissionRequest]);
  useEffect(() => { issueResult && setIssueMissionResult(issueResult) }, [issueResult]);
  useEffect(() => { executeResult && setExecuteMissionResult(executeResult) }, [executeResult]);

  return { unitList }
}