import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../../redux/action/api";

const OnboardDeviceAppSlice = createSlice({
  name: "onboard",
  initialState: {
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    passkey: { status: "" },
    droneModel: {},
    list: [],
    deviceDetails: { status: "" },
    roles: [],
    permissions: [],
    name: { status: "" },
    selectedFiles: [],
    previewFile: undefined,
    uploadResult: {},
    checkPermission: { status: "" },
    connection: {},
    frontendConnection: {},
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    roles(state, action) {
      const data = action.payload;
      return { ...state, roles: data };
    },
    permissions(state, action) {
      const data = action.payload;
      return { ...state, permissions: data };
    },
    droneModel(state, action) {
      const data = action.payload;
      return { ...state, droneModel: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
    name(state, action) {
      const data = action.payload;
      return { ...state, name: { ...data } };
    },
    deviceDetails(state, action) {
      const data = action.payload;
      return { ...state, deviceDetails: { ...data } };
    },
    passkey(state, action) {
      const data = action.payload;
      return { ...state, passkey: { ...data } };
    },
    wsConnection(state, action) {
      const data = action.payload;
      return { ...state, wsConnection: { ...data } };
    },
    ws(state, action) {
      const data = action.payload;
      return { ...state, ws: { ...data } };
    },
    checkPermission(state, action) {
      const data = action.payload;
      return { ...state, checkPermission: { ...data } };
    },
    connection(state, action) {
      const data = action.payload;
      return { ...state, connection: data };
    },
    selectedFiles(state, action) {
      const data = action.payload;
      return { ...state, selectedFiles: data };
    },
    previewFile(state, action) {
      const data = action.payload;
      return { ...state, previewFile: data };
    },
    frontendConnection(state, action) {
      const data = action.payload;
      return { ...state, frontendConnection: { ...data } };
    }
  },
});
export const {
  list,
  roles,
  permissions,
  create,
  update,
  remove,
  droneModel,
  deviceDetails,
  name,
  passkey,
  checkPermission,
  connection,
  selectedFiles,
  previewFile,
  frontendConnection,
  droneUrl,
} = OnboardDeviceAppSlice.actions;
export default OnboardDeviceAppSlice.reducer;

export const GenerateOnboardPassKey = (data, meta) =>
  apiCallBegan({
    url: "/devices/passkey",
    data,
    method: "Post",
    onSuccess: passkey.type,
    onError: passkey.type,
    meta,
  });

export const getList = () =>
  apiCallBegan({
    url: `/devices/onboard`,
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const getOnboardGroupPermissions = (data) =>
  apiCallBegan({
    url: `/devices/onboard/hub-permissions`,
    method: "Post",
    data,
    onSuccess: checkPermission.type,
    onError: checkPermission.type,
  });

export const getDeviceDetails = (id) =>
  apiCallBegan({
    url: `/devices/onboard/${id}`,
    method: "Get",
    onSuccess: deviceDetails.type,
    onError: deviceDetails.type,
  });