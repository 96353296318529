import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ThemeContext = createContext({});
export const ThemeProvider = ({ children }) => {
  const context = useSelector((state) => state.context);
  const [currentContext, setCurrentContext] = useState({
    theme: { color: "#fff", name: "dashboard", textColor: "#23333d" },
  });
  useEffect(() => {
    setCurrentContext(context);
  }, [context]);
  return (
    <ThemeContext.Provider value={{ currentContext }}>
      <div
        style={{
          "--theme-color": currentContext.theme.color,
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
export const useTheme = () => {
  const { currentContext } = useContext(ThemeContext);
  return { currentContext };
};
