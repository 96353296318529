import { Checkbox as RadixCheckbox } from '@radix-ui/themes'
import { CheckboxProps } from '@radix-ui/themes/dist/cjs/components/checkbox'
import classNames from 'classnames'

function Checkbox({ className, ...props}: CheckboxProps) {
  return (
    <RadixCheckbox className={classNames('ir-checkbox', className)} {...props} />
  )
}

export default Checkbox