import LogoMini from "src/ui/LogoMini/LogoMini";
import ProfileMenu from "src/ui/ProfileMenu/ProfileMenu";
import Search from "src/ui/Search/Search";
import "./AppHeader.sass";
import TabletSize from "src/ui/TabletSize/TabletSize";
import DesktopSize from "src/ui/DesktopSize/DesktopSize";
import { Link } from "react-router-dom";
import logo from "src/assets/img/logo-mini.svg";
import NotificationMenu from "src/components/Notification/NotificationMenu/NotificationMenu";
import NotificationProvider from "src/components/Notification/NotificationProvider/NotificationProvider";
import { Box } from "@radix-ui/themes";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";
import { IoMdMenu } from "react-icons/io";
import Breadcrumb from "./Breadcrumb/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { toggleMainMenuOpen } from "src/store/UiSlice";
import MeasurementSystemSwitch from "../MeasurementSystemSwitch/MeasurementSystemSwitch";

function AppHeader({ background, onToggleProfileMenu }) {
  const uiState = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  return (
    <Box className="app-header">
      <div className="app-header-container" style={{ background: background }}>
        {!uiState.mainMenu.pinned ? (
          <IoMdMenu className="main-menu-trigger" onClick={() => dispatch(toggleMainMenuOpen())} />
        ) : null}
        <Breadcrumb items={uiState.headerBreadcrumb} className="app-breadcrumb" />
        {/* <Search /> */}
        <ThemeSwitch />
        <MeasurementSystemSwitch />
        <NotificationMenu />
        <div className="separator"></div>
        <ProfileMenu toggleProfileMenu={onToggleProfileMenu} />
        <NotificationProvider />
      </div>
    </Box>
  );
}

export default AppHeader;
