import { getCoordinateGeocoding } from 'src/services/mission/common/missionUtils';
import './UnitExtraDetails.sass'
import { TbBuildingCommunity } from 'react-icons/tb';
import { useEffect, useState } from 'react';

export default function UnitExtraDetails({ entity }) {
  const [address, setAddress] = useState('Looking for address...');

  useEffect(() => {
    if(entity.location)
      getCoordinateGeocoding(entity.location)?.then(geocode => {
          const poi = geocode?.features?.find(feature => feature?.place_type?.includes('poi'));
          setAddress(poi?.place_name);
      })?.catch(() => setAddress(null));
  }, [entity]);

  return (
    <div className="unit-extra-details">
      <div className="entity-avatar">
        <TbBuildingCommunity className='icon' />
      </div>
      <div className="detail-list">
        <div className="detail-item">
          <div className="detail-label">Estimated Address</div>
          <div className="detail-value">{address || '-'}</div>
        </div>
        <div className="detail-item">
          <div className="detail-label">Coordinates</div>
          <div className="detail-value">{entity?.location ? `${entity.location.lat}, ${entity.location.long}` : '-'}</div>
        </div>
      </div>
    </div>
  )
}
