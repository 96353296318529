import "./EditCustomer.sass";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activityFields } from "../../../../../helper/constants";
import useDebounce from "../../../../../helper/use-debounce";
import Form from "../../../../../ui/Form/Form";
import InputBox from "../../../../../ui/InputBox/InputBox";
import MultiSelect from "../../../../../ui/MultiSelect/MultiSelect";
import { checkName, update, updateCustomer } from "../CustomerManagementSlice";
import { validateEmail } from "../../../../../helper/utils";

function EditCustomer({ onOpen, onConfirm }) {
  const [icons, setIcons] = useState({ BusinessName: "default" });
  const [validate, setValidate] = useState(true);
  const [alerts, setAlerts] = useState({});
  const customer = useSelector((state) => state.customer);
  const table = useSelector((state) => state.table);
  const [data, setData] = useState(table.row);
  const [BusinessName, setBusinessName] = useState("");
  const [required] = useState([
    "businessName",
    "activityFields",
    "emailAddress",
  ]);

  const DebouncedBusinessName = useDebounce(BusinessName, 1000);
  const dispatch = useDispatch();

  useEffect(() => {
    if (DebouncedBusinessName) {
      dispatch(checkName(BusinessName));
    }
  }, [DebouncedBusinessName]);

  useEffect(() => {
    onOpen?.();
  }, [data]);

  useEffect(() => {
    if (customer.name.status === "forbidden") {
      setAlerts({
        ...alerts,
        BusinessName: {
          alert: true,
          type: "error",
          message: customer.name.message.error,
        },
      });
    } else if (customer.name.status === "validation") {
      let validations = {};
      customer.name.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
    setIcons({ ...icons, BusinessName: customer.name.status });
    setValidate(customer.name.status === "success");
  }, [customer.name]);

  useEffect(() => {
    if (customer.update.status === "validation") {
      let validations = {};
      customer.update.errors.forEach((item) => {
        var x = item.propertyName.replace(/\s+/g, "-").toLowerCase();
        console.log(x);
        validations[item.propertyName.replace(/\s+/g, "-").toLowerCase()] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    } else if (customer.update.status === "success") {
      onConfirm();
    }
  }, [customer.update]);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    setAlerts({ ...alerts, [key]: { alert: false } });

    if (key === "emailAddress") {
      setValidate(validateEmail(value));
    }
    if (key === "activityFields") {
      value =
        event.target.value.toString() !== ""
          ? event.target.value.split(",")
          : [];
    }

    if (key === "businessName") {
      setIcons({ ...icons, BusinessName: "default" });
      setBusinessName(value);
      setValidate(false);
    }

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(update({ status: "" }));
    dispatch(updateCustomer({ ...data }, table.row.id));
  };

  return (
    <div className="edit-customer-form">
      <Form
        submit={{
          title: "Update",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        className="edit-customer-container"
        validate={validate}
      >
        <div className="edit-customer-section">
          <InputBox
            title="Business Name"
            name="businessName"
            isRequired={required.includes("businessName")}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.businessName}
            value={data.businessName}
            index={0}
            background="white"
            width="48%"
            icon={icons.businessName}
          />

          <InputBox
            title="Business Number"
            name="businessNumber"
            isRequired={required.includes("businessNumber")}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.businessNumber}
            value={data.businessNumber}
            index={1}
            background="white"
            width="48%"
          />
        </div>

        <div className="edit-customer-section">
          <InputBox
            title="Email Address"
            name="emailAddress"
            isRequired={required.includes("emailAddress")}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.emailAddress}
            value={data.emailAddress}
            index={2}
            icon={icons.emailAddress}
            background="white"
            type="email"
            width="100%"
          />
        </div>

        <MultiSelect
          title="Fields of activity"
          name="activityFields"
          items={activityFields}
          isRequired={required.includes("activityFields")}
          onChange={changeHandler}
          status={alerts.activityFields}
          value={data.activityFields}
          index={2}
          placeholder="Select Your Fields of activity"
        />

        <InputBox
          isRequired={required.includes("phoneNumber")}
          title="Phone number"
          name="phoneNumber"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.phoneNumber}
          value={data.phoneNumber}
          index={4}
          background="white"
          width="100%"
        />

        <InputBox
          isRequired={required.includes("postalAddress")}
          title="Address"
          name="postalAddress"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.postalAddress}
          value={data.postalAddress}
          index={4}
          background="white"
          width="100%"
        />
      </Form>
    </div>
  );
}

export default EditCustomer;
