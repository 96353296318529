import { FiSearch } from "react-icons/fi";
import "./SearchOption.sass";

function SearchOption() {
  return (
    <div className="search-option">
      <FiSearch />
      <input
        className="search-option-input"
        placeholder="Search Keywords..."
        type="text"
      />
    </div>
  );
}

export default SearchOption;
