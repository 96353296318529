import { useEffect, useState } from "react";
import { useTheme } from "../../helper/ThemeProvider";
import { FilledCheck, OutlinedCheck } from "../Icons/Icons";
import "./CheckBox.sass";
function CheckBox({ info, name, text, onChecked, checked }) {
  const [check, setCheck] = useState(checked !== undefined && checked);
  const { currentContext } = useTheme();
  useEffect(() => {
    setCheck(checked !== undefined && checked);
  }, [checked]);

  const checkChanged = (event) => {
    setCheck(event.target.checked);
    onChecked(event);
  };
  return (
    <label className="check-box" style={{ width: "fit-content" }}>
      <input
        title={info}
        type="checkbox"
        checked={check}
        onChange={checkChanged}
        name={name}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        {check === true ? (
          <FilledCheck color={currentContext.theme.color} />
        ) : (
          <OutlinedCheck color={currentContext.theme.color} />
        )}
        <span className="check-value">{text}</span>
      </div>
    </label>
  );
}

CheckBox.defaultProps = {
  color: "#2699fb",
};

export default CheckBox;
