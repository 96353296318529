import { useEffect, useRef, useState } from "react";
import { dockConnectionManager } from "src/helper/HubConnectionManager";
import { djiCloudMethod } from "../../common/constants";
import { getDeviceConnectionGroups } from "src/services/device/common/deviceUtils";
import { useStore } from "react-redux";

export default function useMissionCloudAgent() {
  const messageHandlerIds = useRef([]);
  const store = useStore();
  const [lastResponse, setLastResponse] = useState();

  function replyFlightAreasGet(deviceId, replyMeta) {
    const targetGroup = getDeviceConnectionGroups(deviceId)?.send?.request_reply;
    if(!targetGroup) return console.error('🚩 No target group found for custom flight area reply!');

    dockConnectionManager.sendToDjiCloudGroup(deviceId, targetGroup, {
      method: djiCloudMethod.customFlightArea.FLIGHT_AREAS_GET,
      data: {
        result: 0,
        output: {
          files: [],
        }
      },
      ...replyMeta,
    });
  }

  function replyFlightTaskResourceGet(deviceId, flightId, replyMeta) {
    const targetGroup = getDeviceConnectionGroups(deviceId)?.send?.request_reply;
    const mission = store.getState().missionService.missions?.find(item => item.flightId === flightId);

    if(!mission) return console.error('🚩 No flight info found for flight resources reply!');

    dockConnectionManager.sendToDjiCloudGroup(deviceId, targetGroup, {
      method: djiCloudMethod.wayline.FLIGHTTASK_RESOURCE_GET,
      data: {
        result: 0,
        output: {
          file: {
            url: mission.flight?.url,
            fingerprint: mission.flight?.fingerprint,
          }
        }
      },
      ...replyMeta,
    });
  }

  function replyRebootResult(deviceId, replyMeta) {
    const targetGroup = getDeviceConnectionGroups(deviceId)?.send?.events_reply;

    dockConnectionManager.sendToDjiCloudGroup(deviceId, targetGroup, {
      method: djiCloudMethod.debug.DEVICE_REBOOT,
      data: {
        result: 0,
      },
      ...replyMeta,
    });
  }

  function replyUpdateTopo(deviceId, replyMeta) {
    const targetGroup = getDeviceConnectionGroups(deviceId)?.send?.status_reply;

    dockConnectionManager.sendToDjiCloudGroup(deviceId, targetGroup, {
      method: djiCloudMethod.status.UPDATE_TOPO,
      data: {
        result: 0,
      },
      ...replyMeta,
    });
  }

  useEffect(() => {
    messageHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: 'missionRequestMessages/*',
        name: ['requests'],
        handler: (message, meta) => {
          if(!meta?.fromUserId) return;
          const targetId = meta.fromUserId.split('_')[0];

          switch(message.method) {
            case djiCloudMethod.customFlightArea.FLIGHT_AREAS_GET:
              replyFlightAreasGet(targetId, { bid: message.bid, tid: message.tid });
              break;
            case djiCloudMethod.wayline.FLIGHTTASK_RESOURCE_GET:
              replyFlightTaskResourceGet(targetId, message.data.flight_id, { bid: message.bid, tid: message.tid });
              break;
          }
        },
      },
      {
        identity: 'missionEventMessages/*',
        name: ['events'],
        handler: (message, meta) => {
          if(!meta?.fromUserId) return;
          const targetId = meta.fromUserId.split('_')[0];

          switch(message.method) {
            case djiCloudMethod.debug.DEVICE_REBOOT:
              if(message.need_reply) {
                replyRebootResult(targetId, { bid: message.bid, tid: message.tid });
              }
              break;
          }
        },
      },
      {
        identity: 'deviceStatusMessages/*',
        name: ['status'],
        handler: (message, meta) => {
          const targetId = meta.fromUserId.split('_')[0];

          switch(message.method) {
            case djiCloudMethod.status.UPDATE_TOPO:
              replyUpdateTopo(targetId, { bid: message.bid, tid: message.tid });
              break;
          }
        },
      },
    ], 'mission-cloud-agent');

    return () => {
      dockConnectionManager.unsubscribeGroupMessages(messageHandlerIds.current);
    }
  }, []);

  return lastResponse;
}
