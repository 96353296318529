import React, {useCallback} from 'react'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { FaRegAddressCard, FaRegBuilding } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { MdMailOutline, MdOutlineLocationOn, MdOutlinePhone, MdWorkOutline } from 'react-icons/md'
import './OrganizationProfile.sass'

function OrganizationProfile({organization: info = {}}) {
  const showInfo = (value) => value || 'Not specified';

  const companySizes = [
    { id: 1, name: "<20", value: 20 },
    { id: 2, name: "20-50", value: 50 },
    { id: 3, name: "51-100", value: 100 },
    { id: 4, name: "200>", value: 200 },
  ];

  return (
    <div className="organization-profile">
      <h2>Welcome to {info.companyName} Profile</h2>
      <div className="organization-profile-header">
        <div className="organization-name">
          <FaRegBuilding />
          <h3>{showInfo(info.companyName)}</h3>
        </div>
        <div className="organization-email">
          <MdMailOutline />
          <div className="caption">Email:</div>
          <div className="value">{showInfo(info.emailAddress)}</div>
        </div>
      </div>
      <div className="organization-profile-details">
        <div className="item">
          <FaRegAddressCard />
          <div className="caption">Account Name: </div>
          <div className="value">{showInfo(info.companyName)}</div>
        </div>
        <div className="item">
          <MdOutlinePhone />
          <div className="caption">Phone Number: </div>
          <div className="value">{showInfo(info.emailAddress)}</div>
        </div>
        <div className="item">
          <MdOutlineLocationOn />
          <div className="caption">Region: </div>
          <div className="value">{showInfo(info.region)}</div>
        </div>
        <div className="item">
          <MdWorkOutline />
          <div className="caption">Field of Activity: </div>
          <div className="value">{showInfo(info.activityFields?.join(', '))}</div>
        </div>
        <div className="item">
          <FiUsers />
          <div className="caption">Company Size: </div>
          <div className="value">{showInfo(companySizes.find(i => i.value === info.companySize)?.name || info.companySize)}</div>
        </div>
        <div className="item">
          <AiOutlineFieldNumber />
          <div className="caption">Business Number: </div>
          <div className="value">{showInfo(info.businessNumber)}</div>
        </div>
      </div>
    </div>
  )
}

export default OrganizationProfile