import classNames from 'classnames'
import './Progress.sass'
import React from 'react'

function Progress({ value, text, className, height = '0.3em', ...props }) {
  return (
    <div className={classNames("ir-progress", className)} {...props}>
      <div className="bar-container">
        <div className="bar"></div>
        <div className="completed-bar" style={{ width: value + '%' }}></div>
      </div>
      <label className="text" style={{ left: (value - (value < 5 ? 0 : 2.5)) + '%' }}>{ text || value + '%' }</label>
    </div>
  )
}

export default Progress