import React from 'react'
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import useMissionInfo from './useMissionInfo';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries';
import { MissionStatus } from './missionConstants';
import { getCoordinatesDistanceAndTime } from 'src/helper/waypointMarkupUtils';
import { deviceTypes } from 'src/helper/constants';

function useMissionProgress(missionId) {
  const missionDetails = useMissionInfo(item => item.id === missionId);
  const deviceId = missionDetails?.deviceId;
  const deviceInfo = useDeviceInfo(deviceId);
  const telemetries = useDeviceTelemetries(deviceId);
  const subDeviceTelemetries = useDeviceTelemetries(telemetries?.sub_device?.device_sn);

  if(!missionDetails || !telemetries) return {};

  const isMissionCompleted = missionDetails?.status === MissionStatus.COMPLETED;
  const waypoints = missionDetails?.waypoints;

  let totalTime = missionDetails?.duration;
  let totalDistance = missionDetails?.distance;
  let lastWaypoint = waypoints?.[waypoints?.length - 1];
  let reportedRemainingTime = missionDetails?.remainingTime ?? lastWaypoint?.remainingTime;
  let reportedRemainingDistance = missionDetails?.remainingDistance ?? lastWaypoint?.remainingDistance;
  let remainingTime, remainingDistance;

  const {
    time: _totalTime, 
    distance: _totalDistance
  } = getCoordinatesDistanceAndTime(
    waypoints?.slice(-2)?.map(item => ({
      lat: item.latitude,
      long: item.longitude
    })), 
    missionDetails?.speed || 10
  );

  totalDistance = _totalDistance;
  totalTime = _totalTime;

  if(missionDetails.status !== MissionStatus.EXECUTING && missionDetails.status !== MissionStatus.RETURNING) {
    remainingTime = totalTime = 0;
    remainingDistance = totalDistance = 0;
  } else if(isNaN(reportedRemainingTime) || isNaN(reportedRemainingDistance)) {
    let currentGpsCoords;

    if(deviceInfo?.type === deviceTypes.DRONE)
      currentGpsCoords = {latitude: telemetries?.gps?.lat, longitude: telemetries?.gps?.long};
    else if(deviceInfo?.type === deviceTypes.DOCK)
      currentGpsCoords = {latitude: (subDeviceTelemetries || telemetries)?.latitude, longitude: (subDeviceTelemetries || telemetries)?.longitude};

    const lastWaypointCoords = { lat: lastWaypoint?.latitude, long: lastWaypoint?.longitude };
    const currentCoords = { lat: currentGpsCoords?.latitude, long: currentGpsCoords?.longitude };

    const estimate = getCoordinatesDistanceAndTime([
      currentCoords,
      lastWaypointCoords
    ], missionDetails?.speed || 10);

    remainingTime = estimate?.time;
    remainingDistance = estimate?.distance;

    // console.log({
    //   title: '🚧🚧🚧',
    //   coords: [
    //     currentCoords,
    //     lastWaypointCoords
    //   ],
    //   remainingTime,
    //   remainingDistance,
    //   totalDistance,
    //   totalTime
    // })
  }
  else {
    remainingTime = reportedRemainingTime;
    remainingDistance = reportedRemainingDistance;
  }

  

  const calculatedProgress = 100 - (remainingDistance / totalDistance) * 100;
  const progressPercent = isMissionCompleted ? 100 :
        calculatedProgress > 1 ? calculatedProgress : 0;

  return {
    percent: progressPercent?.toFixed(0),
    remainingDistance: isMissionCompleted ? false : remainingDistance?.toFixed(1),
    remainingTime: Math.round(remainingTime),
    totalDistance: totalDistance?.toFixed(1),
    totalTime: Math.round(totalTime),
  }
}

export default useMissionProgress