import { Link } from "react-router-dom";
import "./AppNavigationItem.sass";

function AppNavigationItem({ navItem, navItemClicked }) {
  return (
    <>
      <Link
        to={navItem.link}
        className="app-navigation-item"
        style={{ color: navItem.color }}
        onClick={navItemClicked}
      >
        {navItem.defaultImage}
        <span>{navItem.title}</span>
      </Link>
    </>
  );
}

export default AppNavigationItem;
