import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { useCameraSource } from 'src/helper/CameraSourceProvider';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';
import React from 'react'
import { useSelector } from 'react-redux';
import './CameraViewSelector.sass'

function CameraViewSelector() {
  const { isMainCamera } = useCameraSource();
  const camSource = useSelector((state) => state.flight.cameraStats?.camSource);
  const { hub, groups, deviceId } = useOnboardHub();
  const { activeCameraPayload: activePayload } = useDeviceInfo();

  const wideSource = activePayload?.videoSources?.wide;
  const zoomSource = activePayload?.videoSources?.zoom;
  const infraredSource = activePayload?.videoSources?.infrared;

  const sendCameraVideoSource = (id) => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      cameraSource: {
        payloadIndex: activePayload.payloadIndex,
        source: id,
      }
    });
  };

  if (!isMainCamera || activePayload?.videoSources.length <= 1) return null;

  return (
    <div className="camera-view-selector">
      {(infraredSource && wideSource) && (
        <div className="button-item" onClick={() => sendCameraVideoSource(camSource === wideSource.id ? infraredSource.id : wideSource.id)}>
          {camSource === infraredSource.id ? "WIDE" : "IR"}
        </div>
      )}
      {(zoomSource && wideSource) && (
        <div className="button-item" onClick={() => sendCameraVideoSource(camSource === wideSource.id ? zoomSource.id : wideSource.id)}>
          {camSource === zoomSource.id ? "WIDE" : "ZOOM"}
        </div>
      )}
      {(!wideSource && zoomSource && infraredSource) && (
        <div className="button-item" onClick={() => sendCameraVideoSource(camSource === zoomSource.id ? infraredSource.id : zoomSource.id)}>
          {camSource === zoomSource.id ? "IR" : "ZOOM"}
        </div>
      )}
    </div>
  )
}

export default CameraViewSelector