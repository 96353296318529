import { FaRegUser } from 'react-icons/fa';
import './DeviceCard.sass';
import Card from 'src/hci/common/Card/Card';
import classNames from 'classnames';
import { HiOutlineChip } from 'react-icons/hi';
import { FiBox } from 'react-icons/fi';
import { deviceTypes } from 'src/helper/constants';

function DeviceCard({ data, className, ...props }) {
  const getDeviceTypeTitle = (type) => {
    switch (type) {
      case deviceTypes.DOCK:
        return 'Docking Station';
      case deviceTypes.DRONE:
        return 'Drone (onboard)';
      default:
        return 'Unknown';
    }
  }
  return (
    <Card className={classNames("device-card", { 'offline': !data.isOnline }, className)} {...props}>
      <div className="title-bar">
        <div className={classNames("status", { 'status-online': data.isOnline })}>
          <div className="indicator"></div>
        </div>
        <div className="title">{ data.name || data.title || data.deviceName || data.serailNumber || data.manufacturer }</div>
        <div className="status-text">{ data.isOnline ? 'online' : 'offline' }</div>
      </div>
      <div className="device-type">
        {(data.deviceType || data.type) === deviceTypes.DOCK ? <FiBox className='icon' /> : <HiOutlineChip className='icon' /> }
        <div className="value">{ getDeviceTypeTitle(data.deviceType || data.type) }</div>
      </div>
    </Card>
  )
}

export default DeviceCard