import "./UserProfileOptions.sass";
import React, { useEffect } from "react";
import { MdGroup, MdPerson } from "react-icons/md";
import { FaKey, FaRegBuilding, FaUserCog } from "react-icons/fa";
import UserProfileInformation from "./UserProfileInformation/UserProfileInformation";
import UserProfilePassword from "./UserProfilePassword/UserProfilePassword";
import UserProfileGroups from "./UserProfileGroups/UserProfileGroups";
import UserProfileRoles from "./UserProfileRoles/UserProfileRoles";
import UserProfileOrganizations from "./UserProfileOrganizations/UserProfileOrganizations";
import ApplicationContentAreaContainerStatic from "src/components/Dashboard/Components/ApplicationContentAreaContainer/ApplicationContentAreaContainerStatic";
import { useDispatch } from "react-redux";
import { setContext } from "src/redux/ducks/appContextSlice";
import { useRouteMatch } from "react-router-dom";

function UserProfileOptions() {
  const histories = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Manage your account", link: "/dashboard/profile" },
  ];
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(
      setContext({
        theme: {
          color: "#2699fb",
          name: "profile",
          secondColor: "#f3f6fb",
          textColor: "#23333d",
        },
      })
    );
  });

  const feature = {
    id: "user_profile_options",
    steps: [
      {
        name: "profile_information",
        title: "Information",
        icon: <MdPerson />,
        path: `${path}`,
        active: true,
        visible: true,
        locked: false,
        activity: <UserProfileInformation />,
      },
      {
        name: "profile_password",
        title: "Password",
        icon: <FaKey size="0.7em" />,
        path: `${path}/password`,
        active: false,
        visible: true,
        locked: false,
        activity: <UserProfilePassword />,
      },
      {
        name: "profile_groups",
        title: "My Groups",
        icon: <MdGroup />,
        path: `${path}/groups`,
        active: false,
        visible: true,
        locked: false,
        activity: <UserProfileGroups />,
      },
      {
        name: "profile_roles",
        title: "My Roles",
        icon: <FaUserCog />,
        path: `${path}/roles`,
        active: false,
        visible: true,
        locked: false,
        activity: <UserProfileRoles />,
      },
      {
        name: "profile_organizations",
        title: "Organizations",
        icon: <FaRegBuilding size="0.9em" />,
        path: `${path}/organizations`,
        active: false,
        visible: true,
        locked: false,
        activity: <UserProfileOrganizations />,
      },
    ],
  };

  useEffect(() => {
    dispatch(setContext({
      theme: {
        color: "#2699fb",
        name: "profile",
        secondColor: "#f3f6fb",
        textColor: "#23333d",
      },
    }));
  });

  return (
    <div className="user-profile-options">
      <ApplicationContentAreaContainerStatic
        histories={histories}
        feature={feature}
        operationManager={true}
      />
    </div>
  );
}

export default UserProfileOptions