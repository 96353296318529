import { useEffect, useRef, useState } from "react";
import { KinesisVideo } from "@aws-sdk/client-kinesis-video";
import { KinesisVideoSignaling } from "@aws-sdk/client-kinesis-video-signaling";
import { SignalingClient } from "amazon-kinesis-video-streams-webrtc";
import { v4 as uuidv4 } from "uuid";

const OPTIONS = {
  TRAVERSAL: {
    STUN_TURN: "stunTurn",
    TURN_ONLY: "turnOnly",
    DISABLED: "disabled",
  },
  ROLE: {
    MASTER: "MASTER",
    VIEWER: "VIEWER",
  },
  RESOLUTION: {
    WIDESCREEN: "widescreen",
    FULLSCREEN: "fullscreen",
  },
};

export function useVideoStream(streamConfig) {
  const [lastConfig, setLastConfig] = useState();
  const [region, setRegion] = useState(undefined);
  const [accessKeyId, setAccessKeyId] = useState("");
  const [secretKeyId, setSecretKeyId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [mediaStream, setMediaStream] = useState();
  const [natTraversal] = useState(OPTIONS.TRAVERSAL.STUN_TURN);
  const signalingClient = useRef(null);
  const peerConnection = useRef(null);

  useEffect(() => {
    console.log('⛅ changed-stream-config', streamConfig);

    if (streamConfig?.region !== undefined) {
      setAccessKeyId(streamConfig?.accessKey);
      setSecretKeyId(streamConfig?.secretKey);
      setRegion(streamConfig?.region);
      setChannelName(streamConfig?.channelName);
    }
    else {
      setAccessKeyId("");
      setSecretKeyId("");
      setRegion(undefined);
      setChannelName("");
    }
  
    setLastConfig(streamConfig);
  }, [streamConfig]);

  const startPlayerForViewer = async () => {
    console.log("[VideoStreaming] Client created...");
    const kinesisVideoClient = new KinesisVideo({
      region,

      // The transformation for endpoint is not implemented.
      // Refer to UPGRADING.md on aws-sdk-js-v3 for changes needed.
      // Please create/upvote feature request on aws-sdk-js-codemod for endpoint.
      endpoint: null,

      // The key correctClockSkew is no longer supported in v3, and can be removed.
      // @deprecated The clock skew correction is applied by default.
      correctClockSkew: true,

      credentials: {
        accessKeyId,
        secretAccessKey: secretKeyId,
        sessionToken: null,
      },
    });

    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: channelName,
      });
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ["WSS", "HTTPS"],
          Role: OPTIONS.ROLE.VIEWER,
        },
      });

    const endpointsByProtocol =
      getSignalingChannelEndpointResponse.ResourceEndpointList.reduce(
        (endpoints, endpoint) => {
          endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
          return endpoints;
        },
        {}
      );
    console.log(`[VideoStreaming] Creating streaming client...`);
    signalingClient.current = new SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      role: OPTIONS.ROLE.VIEWER,
      region,
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
      clientId: uuidv4(),
      credentials: {
        accessKeyId,
        secretAccessKey: secretKeyId,
        sessionToken: null,
      },
    });
    signalingClient.current.on("open", async () => {
      console.log("[VideoStreaming] Connected to the backend service");
      await peerConnection.current.setLocalDescription(
        await peerConnection.current.createOffer({
          offerToReceiveAudio: false,
          offerToReceiveVideo: true,
        })
      );
      signalingClient.current.sendSdpOffer(
        peerConnection.current.localDescription
      );
      console.log("[VideoStreaming] Generating Ice candidates");
    });

    signalingClient.current.on("sdpAnswer", async (answer) => {
      await peerConnection.current.setRemoteDescription(answer);
    });

    signalingClient.current.on("iceCandidate", (candidate) => {
      peerConnection.current.addIceCandidate(candidate);
    });

    signalingClient.current.on("close", () => {
      console.log("[VideoStreaming] Disconnected from signaling channel");
    });

    signalingClient.current.on("error", (error) => {
      console.error("[VideoStreaming] Signaling client error: ", error);
    });

    // Get ICE server configuration
    console.log("[VideoStreaming] Creating Ice server configuration...");
    const kinesisVideoSignalingChannelsClient =
      new KinesisVideoSignaling({
        region,

        // The transformation for endpoint is not implemented.
        // Refer to UPGRADING.md on aws-sdk-js-v3 for changes needed.
        // Please create/upvote feature request on aws-sdk-js-codemod for endpoint.
        endpoint: endpointsByProtocol.HTTPS,

        // The key correctClockSkew is no longer supported in v3, and can be removed.
        // @deprecated The clock skew correction is applied by default.
        correctClockSkew: true,

        credentials: {
          accessKeyId,
          secretAccessKey: secretKeyId,
          sessionToken: null,
        },
      });

    console.log("[VideoStreaming] Getting Ice server config result...");
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      });

    const iceServers = [];
    if (natTraversal === OPTIONS.TRAVERSAL.STUN_TURN) {
      console.log("[VideoStreaming] Initialize servers...");
      iceServers.push({
        urls: `stun:stun.kinesisvideo.${region}.amazonaws.com:443`,
      });
    }

    if (natTraversal !== OPTIONS.TRAVERSAL.DISABLED) {
      console.log("[VideoStreaming] Initialize relay servers...");
      getIceServerConfigResponse.IceServerList.forEach((iceServer) =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        })
      );
    }

    const configuration = {
      iceServers,
      iceTransportPolicy:
        natTraversal === OPTIONS.TRAVERSAL.TURN_ONLY ? "relay" : "all",
      sdpSemantics: 'unified-plan',
    };

    peerConnection.current = new RTCPeerConnection(configuration);

    // Access the transceivers for the media stream
    const videoTransceiver = peerConnection.current.addTransceiver('video');
    const params = videoTransceiver.sender.getParameters();

    if (!params.encodings) {
        params.encodings = [{}];
    }

    // Enable TWCC by specifying 'transport-cc' in the RTCP feedback
    params.encodings[0].rtcpFeedback = [{ type: 'transport-cc' }];
    videoTransceiver.sender.setParameters(params);

    peerConnection.current.addEventListener("icecandidate", ({ candidate }) => {
      if (candidate) {
        signalingClient.current?.sendIceCandidate(candidate);
      } else {
        console.log("[VideoStreaming] all Ice candidates have been generated");
      }
    });

    peerConnection.current.addEventListener("track", (event) => {
      console.log("[VideoStreaming] remote track received");

      setMediaStream(event.streams[0]);
      console.log('🥽 new-video-track', event.streams[0]);
    });
    if (signalingClient.current) signalingClient.current.open();
  };

  function stopPlayerForViewer() {
    console.log("[VIEWER] Stopping viewer connection");

    if (signalingClient.current) {
      signalingClient.current.close();
      signalingClient.current = null;
    }

    if (peerConnection.current) {
      peerConnection.current.close();
      peerConnection.current = null;
    }

    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
      setMediaStream(null);
    }
  }

  useEffect(() => {
    stopPlayerForViewer();
    console.log('🎈 new-channel-or-region', channelName, region);

    if (region !== undefined) {
      setTimeout(() => startPlayerForViewer(), 1000);
    }
  }, [region, channelName]);

  useEffect(() => {
    return () => {
      stopPlayerForViewer();
    };
  }, []);

  return ({
    mediaStream,
  });
}