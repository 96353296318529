import useDialog from 'src/helper/useDialog';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updatePassword, password as passwordAction } from 'src/redux/ducks/accountStateSlice';
import { sendEmailVerificationCode, sendEmail as sendEmailAction } from 'src/store/UserVerificationSlice';
import Button from 'src/ui/Button/Button';
import CountdownTimer from 'src/ui/CountdownTimer/CountdownTimer';
import Form from 'src/ui/Form/Form'
import InputBox from 'src/ui/InputBox/InputBox';
import Loading from 'src/ui/Loading/Loading';
import './UserProfilePassword.sass'

function UserProfilePassword() {
  const requiredFields = ['VerificationCode', 'Password', 'ConfirmPassword'];
  const [data, setData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [alerts, setAlerts] = useState({});
  const [expireTime, setExpireTime] = useState(120);
  const [resetCounter, setResetCounter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const dispatch = useDispatch();
  const dialog = useDialog();

  const email = useSelector(state => state.verification.sendEmail);
  const password = useSelector(state => state.accountState.password);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setAlerts([]);

    dispatch(updatePassword({
      Password: data.Password,
      ConfirmPassword: data.ConfirmPassword,
      VerificationCode: data.VerificationCode,
    }));
  }

  const resetForm = () => {
    setData({});
  }

  useEffect(() => {
    if (email.status === 'success') {
      setOtpExpired(false);
      setOtpSent(true);
      setExpireTime(email.data.expirationTime);
      setLoading(false);
      setResetCounter((currentValue) => {
        return !currentValue;
      });
    }
    else if (email.status === 'failed') {
      setLoading(false);
      dialog.fire({
        title: 'Verification Failed',
        text: 'An error ocurred while sending request for the verification code, please try again.',
        icon: 'error'
      });
    }
  }, [email]);

  useEffect(() => {
    if (password.status === 'success') {
      dispatch(passwordAction({ status: "" }));
      dispatch(sendEmailAction({ status: "" }));
      setOtpSent(false);
      setOtpExpired(false);
      resetForm();
      dialog.fire({
        title: 'Password Changed',
        text: 'Your password has been changed successfully!',
        icon: 'success'
      });
    }
    else if (password.status === "validation") {
      let validations = {};
      password.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });

      setAlerts({ ...alerts, ...validations });
    }
    else if (password.status === 'forbidden') {
      setAlerts({
        VerificationCode: {
          alert: true,
          type: "error",
          message: password.message || "Verification code is wrong",
        }
      });
    }
    else if (password.status === 'error') {
      dialog.fire({
        title: 'Password Not Changed',
        text: 'An error ocurred during update process.',
        icon: 'error'
      });
    }

    if (password.status !== 'pending') setLoading(false);
    console.log('pass-res', password);
  }, [password]);

  const handleOnTimerFinish = () => {
    setOtpSent(false);
    setOtpExpired(true);
    setAlerts([]);
  }

  const getVerificationCode = () => {
    setLoading(true);
    dispatch(sendEmailVerificationCode());
  }

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    setAlerts({ ...alerts, [key]: { alert: false } });
    setIsFormValid(true);

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  }

  return (
    <div className="user-profile-password">
      <h2>Change Password</h2>
      <div className="verification">
        <div className="intro-text">For changing password, please first click the button below to receive a verification code.</div>
        <Button className="trigger-button" value="Get verification code" onClick={getVerificationCode} width="200px" />
      </div>
      <Form
        submit={{
          title: "Submit",
          color: "#2699fb",
          action: submitHandler,
          className: "submit-button",
          disabled: !otpSent || otpExpired
        }}
        required={requiredFields}
        className="password-form"
        validate={isFormValid}
        data={data}
      >
        <div className="verification-info">
          {otpSent && (
            <div>
              <span>
                We've sent a code to <strong>{email?.data?.emailAddress}</strong> which
                expires in:&nbsp;
              </span>
              <CountdownTimer
                seconds={expireTime}
                className="otp-countdown"
                reset={resetCounter}
                onFinish={handleOnTimerFinish}
              />
            </div>
          )}
          {otpExpired && (
            <div>
              <span>
                The code has been expired, please get a new verification code to continue.
              </span>
            </div>
          )}
        </div>
        <div className="form-row">
          <div className="field-title">Verification Code</div>
          <InputBox
            name="VerificationCode"
            isRequired={requiredFields.includes("VerificationCode")}
            type="text"
            onChange={changeHandler}
            status={alerts.VerificationCode}
            index={0}
            isReadOnly={!otpSent}
            value={data.VerificationCode}
          />
        </div>
        <div className="form-row">
          <div className="field-title">Create New Password</div>
          <InputBox
            name="Password"
            isRequired={requiredFields.includes("Password")}
            type="password"
            onChange={changeHandler}
            status={alerts.Password}
            index={1}
            isReadOnly={!otpSent}
            value={data.Password}
          />
        </div>
        <div className="form-row">
          <div className="field-title">Confirm New Password</div>
          <InputBox
            name="ConfirmPassword"
            isRequired={requiredFields.includes("ConfirmPassword")}
            type="password"
            onChange={changeHandler}
            status={alerts.ConfirmPassword}
            index={2}
            isReadOnly={!otpSent}
            value={data.ConfirmPassword}
          />
        </div>
      </Form>
      {loading && <Loading />}
    </div>
  )
}

export default UserProfilePassword