import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { apiPrefix } from "./settingsSlice";

const AuthConfigContext = createContext();
export const AuthConfigProvider = ({ children }) => {
  const [configs, setConfigs] = useState({});
  const [loginRequest, setLoginRequest] = useState([]);
  const [forgotPasswordRequest, setForgotPasswordRequest] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    fetch('/appsettings.json')
      .then((r) => r.json())
      .then((settings) => {
        setConfigs({
          auth: {
            ...settings.configs.auth,
            knownAuthorities: [settings.configs.auth.authorities],
          },
        });
        setLoginRequest({ scopes: [settings.login] });
        setForgotPasswordRequest({ authority: settings.forgotPasswordRequest });
        dispatch(apiPrefix(settings.apiPrefix));
        setIsLoaded(true);
      });
  }, []);

  return (
    isLoaded && (
      <AuthConfigContext.Provider
        value={{ configs, loginRequest, forgotPasswordRequest }}
      >
        {children}
      </AuthConfigContext.Provider>
    )
  );
};

export const useAuthConfigs = () => {
  const { configs } = useContext(AuthConfigContext);
  return { configs };
};

export const useLoginRequest = () => {
  const { loginRequest } = useContext(AuthConfigContext);
  return { loginRequest };
};

export const useForgotPasswordRequest = () => {
  const { forgotPasswordRequest } = useContext(AuthConfigContext);
  return { forgotPasswordRequest };
};