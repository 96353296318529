import { useEffect, useState } from "react";
import "./PhoneVerification.sass";
import SendCode from "./SendCode/SendCode";
import VerifyCode from "./VerifyCode/VerifyCode";
import { useDispatch, useSelector } from "react-redux";
import { checkVerificationCode, verifySMS } from "src/store/UserVerificationSlice";
import { CheckAccountState } from "src/redux/ducks/accountStateSlice";
import { help } from "../../AccountManagementSlice";
import useDialog from "src/helper/useDialog";

const PhoneVerification = () => {
  const [active, setActive] = useState(true);
  const dispatch = useDispatch();
  const dialog = useDialog();
  const verify = useSelector((state) => state.verification.verifySMS);

  const nextStep = (data) => {
    // setActive(false);
    dispatch(
      checkVerificationCode({
        verificationCode: data.smsCode,
        mobilePhone: data.mobilePhone
      })
    );
  };

  const previousStep = () => {
    setActive(true);
  };

  useEffect(() => {
    if (verify.status === "failed") {
      dialog.fire({
        title: <b>Phone Number</b>,
        text: "Failed to save phone number, please try again.",
        icon: 'error',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });
    } else if (verify.status === "success") {
      dispatch(CheckAccountState());
      dispatch(verifySMS({ status: "" }));
      dispatch(help("agreement"));
    }
  }, [verify]);

  return (
    <>
      {active ? (
        <SendCode nextStep={nextStep} />
      ) : (
        <VerifyCode backStep={previousStep} />
      )}
    </>
  );
   
  
};

export default PhoneVerification;
