import { MdAssistantNavigation } from 'react-icons/md';
import './MapShipMarker.sass';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Popover } from 'src/hci/common/Popover/Popover';
import { MapShipTypeProperties } from './MapShipTypeProperties';
import { FiNavigation2 } from 'react-icons/fi';

function MapShipMarker({title, data, className}) {
  const [animate, setAnimate] = useState(true);
  const [lastHeading, setLastHeading] = useState(data?.heading || 0);
  const [showDetails, setShowDetails] = useState(false);
  
  const dataDetailsToShow = ['name', 'mmsi', 'imo', 'country_iso', 'type', 'type_specific', 'speed', 'course', 'heading'];
  const shipProps = MapShipTypeProperties[data?.type] || {
    size: 1,
    color: 'white',
  };

  useEffect(() => {
    const heading = data?.heading || 0;
    setLastHeading(curr => {
      setAnimate(Math.abs(heading - curr) < 270);
      return heading;
    });
  }, [data]);

  return (
    <div className={classNames("map-ship-marker", className)} title={title}>
      <Popover 
        isOpen={showDetails} 
        onClose={() => setShowDetails(false)}
        content={
          <div className="details">
            {dataDetailsToShow.map(key => (
              <div key={key}>
                <span>{key}</span>: {data[key]}
              </div>
            ))}
          </div>
        }
      >
        <div 
          className="icon"
          style={{ 
            transform: `rotate(${lastHeading}deg)`,
            fontSize: `${shipProps.size}em`,
            transition: animate ? 'all 0.3s' : 'none',
            color: shipProps.color,
          }}
        >
          <FiNavigation2 
            onClick={() => setShowDetails(prev => !prev)}
          />
        </div>
      </Popover>
    </div>
  )
}

export default MapShipMarker