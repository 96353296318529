import { useAppTheme } from 'src/helper/AppThemeProvider';
import './ThemeSwitch.sass';
import { MdOutlineLightMode } from 'react-icons/md';
import { HiOutlineMoon } from 'react-icons/hi';
import { BiMoon } from 'react-icons/bi';

function ThemeSwitch() {
  const [theme, setTheme] = useAppTheme();

  return (
    <div className="theme-switch">
      {theme === 'dark' ? (
        <MdOutlineLightMode className="icon" onClick={() => setTheme('light')} />
      ):(
        <BiMoon className="icon" onClick={() => setTheme('dark')} />
      )}
    </div>
  )
}

export default ThemeSwitch