import classNames from 'classnames';
import './Card.sass';

function Card({ children, className, ...props }) {
  return (
    <div className={ classNames("card", className) } {...props}>
      { children }
    </div>
  )
}

export default Card