import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const RoleManagementSlice = createSlice({
  name: "role",
  initialState: {
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    model: {},
    list: [],
    roles: [],
    permissions: [],
    assignPermissions: { status: "" },
    rolePermissions: [],
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    roles(state, action) {
      const data = action.payload;
      return { ...state, roles: data };
    },
    permissions(state, action) {
      const data = action.payload;
      return { ...state, permissions: data };
    },
    assignPermissions(state, action) {
      const data = action.payload;
      return { ...state, assignPermissions: data };
    },
    setRoleModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
    rolePermissions(state, action) {
      const data = action.payload;
      return { ...state, rolePermissions: { ...data } };
    },
  },
});
export const {
  list,
  roles,
  permissions,
  create,
  update,
  remove,
  setRoleModel,
  assignPermissions,
  rolePermissions,
} = RoleManagementSlice.actions;
export default RoleManagementSlice.reducer;

export const createFreshRole = (data) =>
  apiCallBegan({
    url: "access/roles",
    method: "Post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const getRolesList = () =>
  apiCallBegan({
    url: "access/roles",
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const removeRoles = (data) =>
  apiCallBegan({
    url: "access/roles",
    method: "Delete",
    data,
    onSuccess: remove.type,
    onError: remove.type,
  });

export const updateRole = (data, id) =>
  apiCallBegan({
    url: "access/roles/" + id,
    method: "Patch",
    data,
    onSuccess: update.type,
    onError: update.type,
  });

export const getPermissionsList = () =>
  apiCallBegan({
    url: "access/permissions",
    method: "Get",
    onSuccess: permissions.type,
    onError: permissions.type,
  });

export const assignRolePermissions = (data) =>
  apiCallBegan({
    url: "access/roles/permissions",
    method: "Put",
    data,
    onSuccess: assignPermissions.type,
    onError: assignPermissions.type,
  });

export const getRolePermissions = (id, category) =>
  apiCallBegan({
    url: "access/roles/" + id + "/permissions?Category=" + category,
    method: "Get",
    onSuccess: rolePermissions.type,
    onError: rolePermissions.type,
  });

export const getUserRoles = (id) =>
  apiCallBegan({
    url: "users/" + id + "/roles",
    method: "Get",
    onSuccess: roles.type,
    onError: roles.type,
  });
