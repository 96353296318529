import { NotificationType } from "src/components/Notification/NotificationItem/NotificationItem";
import { addNotification } from "src/components/Notification/NotificationSlice";

import * as actions from "../action/api";
const toast =
  ({ dispatch }) =>
    (next) =>
      (action) => {
        switch (action.type) {
          case actions.apiCallBegan.type:
            next(action);
            break;
          case actions.apiCallFailed.type:
            next(action);
            console.log("failed", action.payload);
            break;
          case actions.apiCallSuccess.type:
            next(action);
            console.log("success", action);
            console.log("success", action.payload.message);
            // dispatch(addNotification({
            //   message: action.payload.message,
            //   type: NotificationType.SUCCESS,
            // }));
            break;
          case actions.apiCallSucceed.type:
            next(action);
            console.log("success", action);
            // dispatch(addNotification({
            //   message: action.payload.message,
            //   type: NotificationType.SUCCESS,
            // }));
            break;
          case actions.apiCallError.type:
            next(action);
            console.log(action.payload);
            // dispatch(addNotification({
            //   message: action.payload.message,
            //   type: NotificationType.ERROR,
            // }));
            break;
          default:
            next(action);
        }
      };
export default toast;
