import "react-contexify/dist/ReactContexify.css";
import './ContextMenu.sass';
import { useContextMenu, Menu, Item, Submenu, Separator, RightSlot } from 'react-contexify';
import { classNames } from 'classnames';

function ContextMenu({children, ...props}) {
  return (
    <Menu {...props} className="ir-context-menu">
      {children}
    </Menu>
  );
}

function Icon ({children, className}) {
  const Icon = children;
  return <Icon className={classNames('item-icon', className)} />;
}

ContextMenu.Item = Item;
ContextMenu.Separator = Separator;
ContextMenu.Submenu = Submenu;
ContextMenu.RightSlot = RightSlot;
ContextMenu.Icon = Icon;

export { ContextMenu, useContextMenu, Menu, Item, Submenu, Separator, RightSlot };