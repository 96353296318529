import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setOperation } from "../../../AdminOperationManager/OperationStepSlice";
import RoleResource from "../RoleResource/RoleResource";
import "./RoleInformation.sass";

function RoleInformation() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setOperation("role_info"));
  }, []);
  return (
    <RoleResource
      render={(data) => (
        <div className="role-information">
          <div className="role-information-container">
            <h4>Name : {data.name.value}</h4>
            <h4>Category : {data.category.value}</h4>
            <h4>Description :</h4>
            <p>{data.description.value}</p>
          </div>
        </div>
      )}
    />
  );
}

export default RoleInformation;
