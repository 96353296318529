import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan, apiCallSucceed } from "../redux/action/api";

const UserVerificationSlice = createSlice({
  name: "user-verification",
  initialState: {
    sendSMS: { status: "" },
    verifySMS: { status: "" },
    sendEmail: { status: "" },
  },
  reducers: {
    sendSMS(state, action) {
      const data = action.payload;
      return { ...state, sendSMS: { ...data } };
    },
    verifySMS(state, action) {
      const data = action.payload;
      return { ...state, verifySMS: { ...data } };
    },
    sendEmail(state, action) {
      const data = action.payload;
      return { ...state, sendEmail: { ...data } };
    },
  },
});
export const { sendSMS, verifySMS, sendEmail } = UserVerificationSlice.actions;
export default UserVerificationSlice.reducer;

export const sendVerification = (data) =>
  apiCallBegan({
    url: "account/send-verification",
    method: "Post",
    data,
    onSuccess: sendSMS.type,
    onError: sendSMS.type,
  });

export const resendVerification = (data) =>
  apiCallBegan({
    url: "account/send-verification",
    method: "Post",
    data,
    onSuccess: apiCallSucceed.type,
  });

export const checkVerificationCode = (data) =>
  apiCallBegan({
    url: "account/verify",
    method: "Post",
    data,
    onSuccess: verifySMS.type,
    onError: verifySMS.type,
  });

export const sendEmailVerificationCode = () =>
  apiCallBegan({
    url: "me/profile/verify/email",
    method: "Get",
    onSuccess: sendEmail.type,
    onError: sendEmail.type,
  });