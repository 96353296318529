import { useEffect, useState } from "react";
import { MdFileDownload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../../../ui/Button/Button";
import "./UploadedFilePreview.sass";
import { setOperation } from "../../../../../../AdminCenter/AdminOperationManager/OperationStepSlice";
import Loading from "../../../../../../../ui/Loading/Loading";

function UploadedFilePreview() {
  const previewFileState = useSelector((state) => state.onboard.previewFile);
  const [currentBlob, setCurrentBlob] = useState();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(setOperation("file_preview_information"));
  }, []);

  useEffect(() => {
    if (previewFileState !== undefined) {
      setCurrentBlob(previewFileState);
    }
    return () => {
      setIsLoading(true);
    };
  }, [previewFileState]);

  const contentLoaded = () => {
    setIsLoading(false);
  };
  return (
    <div className="uploaded-file-preview">
      <div className="uploaded-file-preview-header">
        <div className="uploaded-file-preview-title">
          <h3>{currentBlob?.name}</h3>
          <div className="uploaded-file-header-info">
            <span>{currentBlob?.type}</span> <span> - {currentBlob?.size}</span>
          </div>
        </div>
        {currentBlob?.link && (
          <a href={currentBlob?.link}>
            <Button value="Download" icon={<MdFileDownload />} width="100%" />
          </a>
        )}
      </div>
      <div className="uploaded-file-preview-body">
        {currentBlob?.link ? (
          <div className="uploaded-file-preview-content">
            {isLoading && <Loading />}
            {currentBlob?.type?.includes("Image") ? (
              <img
                className="uploaded-file-content-img"
                src={currentBlob?.link}
                alt="Img"
                onLoad={contentLoaded}
              />
            ) : (
              currentBlob?.type?.includes("Video") && (
                <video
                  className="uploaded-file-content-video"
                  src={currentBlob?.link}
                  onLoadedData={contentLoaded}
                  controls
                />
              )
            )}
          </div>
        ) : (
          <div className="uploaded-file-preview-content">
            <p>No Preview and Download Available</p>
          </div>
        )}

        <ul className="uploaded-file-preview-properties">
          <li>
            <span className="uploaded-file-properties-key">Upload Date</span>
            <span className="uploaded-file-properties-value">
              {currentBlob?.date}
            </span>
          </li>
          <li>
            <span className="uploaded-file-properties-key">Type</span>
            <span className="uploaded-file-properties-value">
              {currentBlob?.type}
            </span>
          </li>
          <li>
            <span className="uploaded-file-properties-key">Size</span>
            <span className="uploaded-file-properties-value">
              {currentBlob?.size}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default UploadedFilePreview;
