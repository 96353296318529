import './StageStatusbar.sass'
import StageStatusbarTabs from './StageStatusbarTabs/StageStatusbarTabs'


function StageStatusbar({ tabs }) {
  return (
    <div className="stage-statusbar">
      <StageStatusbarTabs tabs={tabs} />
    </div>
  )
}

export default StageStatusbar