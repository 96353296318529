import './EditGroupOptions.sass'

import React, { useEffect, useMemo } from 'react'
import { usePrivileges } from 'src/helper/AccountStateProvider';
import Tabs from 'src/ui/Tabs/Tabs';
import GroupInformationApplication from '../GroupInformationApplication/GroupInformationApplication';
import GroupMembershipApp from 'src/ui/GroupApp/GroupMembershipApp/GroupMembershipApp';
import GroupOwnershipApp from 'src/ui/GroupApp/GroupOwnershipApp/GroupOwnershipApp';
import { useSelector } from 'react-redux';

function EditGroupOptions({ onOpen, defaultIndex }) {
  const { privileges } = usePrivileges();
  const permissions = useMemo(() => privileges?.applications.find((item) => item.id === "groups")?.privileges, [privileges]);
  const hasPermission = (id) => permissions?.includes(id);
  const currentGroup = useSelector(state => state.groupApp.currentGroup);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className="edit-group-options">
      <Tabs
        defaultIndex={defaultIndex}
        items={[
          {
            title: "Information",
            content: <GroupInformationApplication />,
            hidden: !hasPermission('groups_app_info')
          },
          {
            title: "Membership",
            content: <GroupMembershipApp />,
            hidden: !hasPermission('groups_app_membership')
          },
          {
            title: "Ownership",
            content: <GroupOwnershipApp />,
            hidden: !hasPermission('groups_app_ownership') || !currentGroup?.isOwner
          },
        ]}
      />
    </div>
  )
}

export default EditGroupOptions