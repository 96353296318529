import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../redux/action/api";

const accountStateSlice = createSlice({
  name: "accountState",
  initialState: {
    state: { status: "" },
    permissions: { status: "" },
    profile: { status: "" },
    uploadAvatar: { status: "" },
    removeAvatar: { status: "" },
    password: { status: "" },
    changeName: { status: "" },
    groups: { status: "" },
    roles: { status: "" },
    company: { status: "" },
  },
  reducers: {
    state(state, action) {
      const data = action.payload;
      return { ...state, state: { ...data } };
    },
    permissions(state, action) {
      const data = action.payload;
      return { ...state, permissions: { ...data } };
    },
    profile(state, action) {
      const data = action.payload;
      return { ...state, profile: { ...data } };
    },
    uploadAvatar(state, action) {
      const data = action.payload;
      return { ...state, uploadAvatar: { ...data } };
    },
    removeAvatar(state, action) {
      const data = action.payload;
      return { ...state, removeAvatar: { ...data } };
    },
    password(state, action) {
      const data = action.payload;
      return { ...state, password: { ...data } };
    },
    changeName(state, action) {
      const data = action.payload;
      return { ...state, changeName: { ...data } };
    },
    groups(state, action) {
      const data = action.payload;
      return { ...state, groups: { ...data } };
    },
    roles(state, action) {
      const data = action.payload;
      return { ...state, roles: { ...data } };
    },
    company(state, action) {
      const data = action.payload;
      return { ...state, company: { ...data } };
    },
  },
});
export const { state, permissions, profile, uploadAvatar, removeAvatar, password, changeName, groups, roles, company } =
  accountStateSlice.actions;
export default accountStateSlice.reducer;

export const CheckAccountState = () =>
  apiCallBegan({
    url: "account/check-state",
    method: "Get",
    onSuccess: state.type,
    onError: state.type,
  });

export const getUserPermissions = (id) =>
  apiCallBegan({
    url: `users/${id}/permissions`,
    method: "Get",
    onSuccess: permissions.type,
    onError: permissions.type,
  });

export const getUserProfile = () =>
  apiCallBegan({
    url: "me/profile",
    method: "Get",
    onSuccess: profile.type,
    onError: profile.type,
  });

export const updateProfileImage = (data) =>
  apiCallBegan({
    url: "me/profile/photo",
    method: "Post",
    data,
    onSuccess: uploadAvatar.type,
    onError: uploadAvatar.type,
    onSpin: uploadAvatar.type,
  });

export const removeProfileImage = (data) =>
  apiCallBegan({
    url: "me/profile/photo",
    method: "Delete",
    data,
    onSuccess: removeAvatar.type,
    onError: removeAvatar.type,
  });

export const updatePassword = (data) =>
  apiCallBegan({
    url: "me/profile/password",
    method: "Post",
    data,
    onSuccess: password.type,
    onError: password.type,
  });

export const updateName = (data) =>
  apiCallBegan({
    url: "me/profile",
    method: "Post",
    data,
    onSuccess: changeName.type,
    onError: changeName.type,
  });

export const getUserGroups = () =>
  apiCallBegan({
    url: "me/profile/groups",
    method: "Get",
    onSuccess: groups.type,
    onError: groups.type,
  });

export const getUserRoles = () =>
  apiCallBegan({
    url: "me/profile/roles",
    method: "Get",
    onSuccess: roles.type,
    onError: roles.type,
  });

export const getUserCompany = () =>
  apiCallBegan({
    url: "me/profile/company",
    method: "Get",
    onSuccess: company.type,
    onError: company.type,
  });