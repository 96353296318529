import "./Map.sass";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapboxReact, { Marker } from 'react-map-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from "mapbox-gl";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import GPSMarker from "../../DroneIcons/GPSMarker";
import MapToolbar from "./MapToolbar/MapToolbar";
import HomePointIcon from 'src/assets/img/home_point.svg';
import DestinationPointIcon from 'src/assets/img/destination_point.svg';
import { enlargeMap, gps, setWaypointInfo, videoPip } from "src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";
import { sampleRoute } from "./sampleRoute";
import useResizeObserver from "@react-hook/resize-observer";
import { WaypointLocalState } from "../WaypointController/WaypointController";
import { TbChevronDownLeft, TbMap } from "react-icons/tb";
import classNames from "classnames";
import { CgShapeRhombus } from "react-icons/cg";

// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export const MapLayerStyles = {
  STREET: 1,
  SATELLITE: 2
};

function Map({ center, zoom }) {
  const dispatch = useDispatch();
  const flight = useSelector((state) => state.flight);
  const defaultZoom = 16;

  const mapContainerRef = useRef();
  const mapInstance = useRef();
  const edgeSenseTimeoutId = useRef();
  const isMapFlying = useRef();
  const lastFlyCoords = useRef();
  const geocoderInstance = useRef();

  const [homePoint, setHomePoint] = useState();
  const [currentCenter, setCurrentCenter] = useState();
  const [currentZoom, setCurrentZoom] = useState(zoom || defaultZoom);
  const [edgeSenseEnabled, setEdgeSenseEnabled] = useState();
  const [lastGpsLocation, setLastGpsLocation] = useState();
  const [minimized, setMinimized] = useState();
  const [currLayerStyle, setCurrLayerStyle] = useState(MapLayerStyles.SATELLITE);

  const flightRouteGeoJson = useRef({
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': []
        }
      }
    ]
  });

  const waypointRouteGeoJson = useRef({
    'type': 'FeatureCollection',
    'features': [
      {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': []
        }
      }
    ]
  });

  const AIRMAP_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVkZW50aWFsX2lkIjoiY3JlZGVudGlhbHxsUlhvR1dNU3B6eHkwTEh6NTMzWXhmWXB2ZzdQIiwiYXBwbGljYXRpb25faWQiOiJhcHBsaWNhdGlvbnxYQW96eUFGREFxbjczVHZ5Uks1WElEOVplWiIsIm9yZ2FuaXphdGlvbl9pZCI6ImRldmVsb3Blcnx4RzN6bng3c0x6TnEyZENYdmFCTTdUcFI5WHZYIiwiaWF0IjoxNjY2ODU1MzQwfQ.FZeOZq0SOBk2Chdu8dUv0hWXUULo3LTXNGJUY-J1TcM';
  const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiZGFuaWVsaW50dWl0aXZlIiwiYSI6ImNsOXFydWVnMDA4c28zbnFsYmFuMWpidmIifQ.05aJvcMIyceNQgs5s4DMGw';

  const airMapConfig = {
    airmap: {
      api_key: AIRMAP_API_KEY,
      client_id: "6549d1cd-4d2d-4bd3-8e66-ac69f88773dc",
      callback_url: null
    },
    mapbox: {
      access_token: MAPBOX_ACCESS_TOKEN,
    }
  };

  const airMapOptions = {
    preferredRulesets: [
      // 'aus_airmap_rules',
      // 'aus_notam',
      // 'aus_reoc',
      // 'aus_recreational'
    ],
    overrideRulesets: [],
    enableRecommendedRulesets: true,
    theme: 'light'
  };

  const normalizeCoord = (coord) => {
    //TODO check current flight mode
    if (flight.gps.visibleSats <= 3) return undefined;
    if (coord.lat < -90 || coord.lat > 90) coord.lat = 0;
    if (coord.lng < -180 || coord.lng > 180) coord.lng = 0;

    return coord;
  }

  useEffect(() => {
    if (!flight.gps.lat || !flight.gps.long) return;
    const lastGpsPoint = normalizeCoord({ lat: flight.gps.lat, lng: flight.gps.long });
    const homePointCoord = flight.gps.homepoint ? normalizeCoord({ lat: flight.gps.homepoint.lat, lng: flight.gps.homepoint.long }) : lastGpsLocation;

    setHomePoint(homePointCoord);
    if (homePointCoord && !homePoint) handleRoutePathChanges(homePointCoord);
    if (!flight.enlargeMap || !currentCenter) updateCurrentCenter(lastGpsPoint);

    setLastGpsLocation(lastGpsPoint);
    handleRoutePathChanges(lastGpsPoint);
    if (edgeSenseEnabled) checkPointerEdges(lastGpsPoint);
  }, [flight.gps, flight.enlargeMap]);

  useEffect(() => {
    updateCurrentCenter(center);
  }, [center]);

  useEffect(() => {
    setCurrentZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    if (flight.enlargeMap) {
      setEdgeSenseEnabled(true);
      enableGeocoder();
    }
    else {
      setEdgeSenseEnabled(false);
      disableGeocoder();
    }
  }, [flight.enlargeMap]);

  useResizeObserver(mapContainerRef, (entry) => {
    mapInstance.current?.resize();
    recenterMap();
  });

  const updateCurrentCenter = (coord) => {
    setCurrentCenter(coord);
  }

  const recenterMap = () => {
    if (!lastGpsLocation) return;
    let newCenter = { ...lastGpsLocation };

    if (
      currentCenter &&
      currentCenter.lat === lastGpsLocation.lat &&
      currentCenter.lng === lastGpsLocation.lng
    ) {
      newCenter.lat += 0.00001;
      newCenter.lng += 0.00001;
    }

    updateCurrentCenter(newCenter);
    setEdgeSenseEnabled(true);
  }

  const checkPointerEdges = (coord) => {
    const map = mapInstance.current;
    const container = mapContainerRef.current;
    if (!map || !coord || !container) return;

    const pointerPoint = map.project(coord);
    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;
    const edgeThreshold = Math.min(containerWidth / 5, containerHeight / 5);

    if (
      (containerWidth - pointerPoint.x < edgeThreshold) ||
      (containerHeight - pointerPoint.y < edgeThreshold) ||
      (pointerPoint.x < edgeThreshold) ||
      (pointerPoint.y < edgeThreshold)
    ) updateCurrentCenter(coord);
  }

  const enableGeocoder = () => {
    if (!mapInstance.current) return;

    const geocoder = geocoderInstance.current = new MapboxGeocoder({
      accessToken: MAPBOX_ACCESS_TOKEN,
      marker: null,
      mapboxgl,
    });

    geocoder.on('result', (info) => {
      const centerCoordinate = info.result?.center;

      clearTimeout(edgeSenseTimeoutId.current);
      setEdgeSenseEnabled(false);
      console.log('🌞', info.result?.center)

      if (Array.isArray(centerCoordinate)) {
        dispatch(setWaypointInfo({
          selectedCoordinate: {
            lat: centerCoordinate[1],
            lng: centerCoordinate[0],
          },
        }));
      }
    });

    mapInstance.current.addControl(geocoder, 'top-left');
  }

  const disableGeocoder = () => {
    if (!mapInstance.current || !geocoderInstance.current) return;
    mapInstance.current.removeControl(geocoderInstance.current);
  }

  const handleMapApiLoaded = (map) => {
    if (!map) return;

    // Attach AirMap layers to the map
    setTimeout(() => {
      if (false && window.AirMap) {
        const airmapPlugin = new window.AirMap.ContextualAirspacePlugin(airMapConfig, airMapOptions);
        map.addControl(airmapPlugin, 'top-left');

        airmapPlugin.on('jurisdictionChange', (data) => console.log('🌞', 'jurisdictionChange', data))
        airmapPlugin.on('airspaceLayerClick', (data) => console.log('🌞', 'airspaceLayerClick', data))

        setTimeout(() => {
          console.log('🌞', {
            jurisdictions: airmapPlugin.getJurisdictions(),
            selectedRulelsets: airmapPlugin.getSelectedRulesets()
          })
        }, 10000);
      }
    }, 5000);

    // Handle drag events of map for edge-sense
    map.on('dragstart', () => {
      clearTimeout(edgeSenseTimeoutId.current);
      setEdgeSenseEnabled(false);
    });

    map.on('dragend', () => {
      edgeSenseTimeoutId.current = setTimeout(() => {
        setEdgeSenseEnabled(true);
      }, 5 * 60 * 1000);
    });

    // Add flight route line to the map
    map.addSource('flight-route-line', {
      'type': 'geojson',
      'data': flightRouteGeoJson.current
    });

    map.addLayer({
      'id': 'flight-route',
      'type': 'line',
      'source': 'flight-route-line',
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#4769b6',
        'line-width': 5,
        'line-opacity': 0.8
      }
    });

    // Add waypoint route line to the map
    map.addSource('waypoint-route-line', {
      'type': 'geojson',
      'data': waypointRouteGeoJson.current
    });

    map.addLayer({
      'id': 'waypoint-route',
      'type': 'line',
      'source': 'waypoint-route-line',
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#FB0505',
        'line-width': 5,
        'line-opacity': 0.8,
        'line-dasharray': [0.2, 2]
      }
    });
  }

  const handleRoutePathChanges = (coord) => {
    const map = mapInstance.current;
    const routeCoords = flightRouteGeoJson.current;

    if (!coord || !map || !routeCoords) return;

    routeCoords.features[0].geometry.coordinates.push([coord.lng, coord.lat]);
    map.getSource('flight-route-line')?.setData(routeCoords);
  }

  const handleOnMapClick = (e) => {
    if (!e.lngLat || !flight.waypoint.showForm) return;

    dispatch(setWaypointInfo({
      selectedCoordinate: {
        lat: e.lngLat.lat.toFixed(7),
        lng: e.lngLat.lng.toFixed(7),
      },
    }));
  }

  const testSampleMovement = () => {
    let intervalId, currIndex = 0;

    intervalId = setInterval(() => {
      if (currIndex >= sampleRoute.length) {
        clearInterval(intervalId);
        return;
      }

      dispatch(gps({
        ...flight.gps,
        lat: sampleRoute[currIndex][1],
        long: sampleRoute[currIndex][0]
      }));

      currIndex++;
    }, 1000);
  }

  const flyToCoords = (lng, lat) => {
    const map = mapInstance.current;
    if (!map || !lng || !lat) return;

    if (isMapFlying.current) {
      lastFlyCoords.current = { lng, lat };
      return;
    }

    map.once('moveend', () => {
      isMapFlying.current = false;

      if (lastFlyCoords.current) {
        flyToCoords(lastFlyCoords.current.lng, lastFlyCoords.current.lat);
        lastFlyCoords.current = undefined;
      }
    });

    isMapFlying.current = true;

    map.flyTo({
      center: [lng.toFixed(6), lat.toFixed(6)],
      zoom: currentZoom || defaultZoom,
    });
  };

  useEffect(() => {
    if (!currentCenter) return;
    flyToCoords(currentCenter.lng, currentCenter.lat);
  }, [currentCenter]);

  useEffect(() => {
    const map = mapInstance.current;
    const routeCoords = waypointRouteGeoJson.current;
    const coord = flight.waypoint.coordinate;

    if (!lastGpsLocation || !coord || !map || !routeCoords) return;

    routeCoords.features[0].geometry.coordinates = flight.waypoint.state !== WaypointLocalState.IDLE ? [
      [lastGpsLocation.lng, lastGpsLocation.lat],
      [coord.lng, coord.lat]
    ] : [];

    map.getSource('waypoint-route-line')?.setData(routeCoords);
  }, [flight.waypoint.state, flight.waypoint.coordinate]);

  useEffect(() => {
    //setTimeout(() => testSampleMovement(), 5000);

    return () => {
      clearInterval(edgeSenseTimeoutId.current);
      edgeSenseTimeoutId.current = undefined;
      dispatch(enlargeMap(false));
      dispatch(videoPip(false));
    }
  }, []);

  return (
    <div className={classNames('map', { 'enlarged': flight.enlargeMap, 'minimized': minimized })} ref={mapContainerRef}>
      <MapboxReact
        onLoad={event => {
          mapInstance.current = event.target;
          handleMapApiLoaded(event.target);
        }}
        cursor={flight.waypoint.showForm ? 'crosshair' : 'grab'}
        onZoomEnd={(e) => {
          const newState = e.viewState;
          if (!isMapFlying && newState.zoom)
            setCurrentZoom(newState.zoom);
        }}
        onClick={handleOnMapClick}
        mapStyle={currLayerStyle === MapLayerStyles.SATELLITE ? 'mapbox://styles/mapbox/satellite-streets-v12' : 'mapbox://styles/mapbox/streets-v12'}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      >
        {homePoint ? (
          <Marker latitude={homePoint.lat} longitude={homePoint.lng}>
            <img
              src={HomePointIcon}
              alt="Home Point"
              className="home-point"
            />
          </Marker>
        ) : null}

        {(flight.waypoint.state !== WaypointLocalState.IDLE || flight.waypoint.showForm) && flight.waypoint.selectedCoordinate ? (
          <Marker latitude={flight.waypoint.selectedCoordinate.lat} longitude={flight.waypoint.selectedCoordinate.lng}>
            <img
              src={DestinationPointIcon}
              alt="Destination Point"
              className="destination-point"
            />
          </Marker>
        ) : null}

        {(flight.laserRangeEnabled && (!flight.camera.rangingInfo?.exception || flight.camera.rangingInfo?.exception === 3)) ? (
          <Marker latitude={flight.camera?.rangingInfo?.lat} longitude={flight.camera?.rangingInfo?.long}>
            <CgShapeRhombus className="laser-target-point" />
          </Marker>
        ) : null}

        {lastGpsLocation ? (
          <Marker latitude={lastGpsLocation?.lat} longitude={lastGpsLocation?.lng}>
            <GPSMarker
              nativeSvg={true}
              time=".1"
            />
          </Marker>
        ) : null}
      </MapboxReact>
      {!minimized && <MapToolbar onRecenterClick={lastGpsLocation ? recenterMap : undefined} currLayerStyle={currLayerStyle} onStyleChangeClick={setCurrLayerStyle} />}
      <div className="map-minimize-toggle" onClick={() => setMinimized(curr => !curr)} title={minimized ? 'Show Map' : 'Hide Map'}>
        <TbChevronDownLeft className="toggle-icon" />
        <TbMap className="main-icon" />
      </div>
    </div>
  );
}

export default Map;
