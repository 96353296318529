import CameraTelemetry from 'src/components/Dashboard/Components/CameraTelemetry/CameraTelemetry';
import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { cameraFocusModes } from 'src/helper/constants';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';
import { PayloadIndexes } from 'src/helper/HubConnection';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import './CameraFocusMode.sass'

function CameraFocusMode() {
  const { hub, groups, deviceId } = useOnboardHub();
  const camSource = useSelector((state) => state.flight.cameraStats?.camSource);
  const [isZoomState, setIsZoomState] = useState(false);
  const [focusMode, setFocusMode] = useState(1);
  const { activeCameraPayload } = useDeviceInfo();

  useEffect(() => {
    if (camSource !== undefined)
      setIsZoomState(camSource === 2);
  }, [camSource]);

  const sendModeValue = (newMode) => {
    if (newMode === focusMode) return;

    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      camSetting: {
        payloadIndex: activeCameraPayload?.payloadIndex,
        focusMode: newMode,
      }
    });
  };

  const modeChange = () => {
    setFocusMode(curr => {
      const focusValues = Object.values(cameraFocusModes);
      const currIndex = focusValues.indexOf(curr);
      const newValue = currIndex + 1 < focusValues.length ?
        focusValues[currIndex + 1] :
        focusValues[0];

      sendModeValue(newValue);
      return newValue;
    });
  };

  if (!isZoomState) return null;

  return (
    <div className="camera-focus-mode">
      <CameraTelemetry
        name="mode"
        title=""
        value={Object.keys(cameraFocusModes).find(key => cameraFocusModes[key] === focusMode)}
        onClick={modeChange}
      />
    </div>
  );
}

export default CameraFocusMode