import { useEffect, useRef, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { useTheme } from "../../helper/ThemeProvider";
import useDebounce from "../../helper/use-debounce";
import SmallAlert from "../SmallAlert/SmallAlert";
import "./KeyValueFormNew.sass";

function KeyValueFormNew({ tags, errors, description, onDataChange }) {
  const { currentContext } = useTheme();
  const [list, setList] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [current, setCurrent] = useState({ key: "", value: "" });
  const [edited, setEdited] = useState({ key: "", value: "" });
  const debouncedValue = useDebounce(current, 1000);
  const currentInput = useRef();
  const editInput = useRef();

  useEffect(() => {
    setErrorList(errors);
    errors.map((error) => console.log(list[error]));
  }, [errors]);

  useEffect(() => {
    if (debouncedValue && current.key !== "" && current.value !== "") {
      if (
        list
          .map((item) =>
            Object.entries(item)
              .map((obj) => obj[0] !== current.key)
              .reduce((result, item) => Object.assign(result, item))
          )
          .reduce((result, item) => result && item, true)
      ) {
        setList([...list, { [current.key]: current.value }]);
        setCurrent({ key: "", value: "" });
        currentInput.current?.focus();
      }
    }
  }, [debouncedValue]);

  const dataKeyChange = (event) => {
    setErrorList([...errorList.filter((error) => error !== event.target.name)]);
    const key = Object.keys(list[event.target.name])[0];
    setList([
      ...list.map(
        (litem) =>
          Object.entries(litem).map((item) =>
            item[0] === key ? { [event.target.value]: item[1] } : litem
          )[0]
      ),
    ]);
    setEdited({ key: event.target.value, value: edited.value });
  };
  const dataValueChange = (event) => {
    const value = Object.values(list[event.target.name])[0];
    setList([
      ...list.map(
        (litem) =>
          Object.entries(litem).map((item) =>
            item[1] === value ? { [item[0]]: event.target.value } : litem
          )[0]
      ),
    ]);
    setEdited({ key: edited.key, value: event.target.value });
  };

  const currentKeyChange = (event) => {
    setCurrent({ key: event.target.value, value: current.value });
  };
  const currentValueChange = (event) => {
    setCurrent({ key: current.key, value: event.target.value });
  };
  const removeItem = (index) => {
    const obj = Object.entries(list[index])[0];
    setList([
      ...list.filter(
        (litem) =>
          Object.entries(litem).map(
            (item) => item[0] !== obj[0] && item[1] !== obj[1]
          )[0] && litem
      ),
    ]);
  };
  useEffect(() => {
    setList([...Object.entries(tags).map((item) => ({ [item[0]]: item[1] }))]);
  }, [tags]);

  useEffect(() => {
    onDataChange(
      list
        .map((item) => ({ ...item }))
        .reduce((result, item) => Object.assign(result, item), {})
    );
  }, [list]);

  return (
    <div className="key-value-form">
      <div className="key-value-form-container">
        <p>{description}</p>
        <table className="key-value-form-table">
          <thead>
            <tr>
              <th>Key*</th>
              <th></th>
              <th>Value*</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index}>
                <td style={{ position: "relative" }}>
                  <input
                    type="text"
                    value={Object.entries(item).map((i) => i[0])}
                    name={index}
                    onInput={dataKeyChange}
                    style={{
                      color: errorList.includes(index)
                        ? "#ff7c7c"
                        : currentContext.theme.color,
                      border: "1px solid #e0e7ff",
                      borderColor: errorList.includes(index)
                        ? "#ff7c7c"
                        : "#e0e7ff",
                    }}
                  />
                  {errorList.includes(index) && (
                    <SmallAlert
                      alert={errorList.includes(index)}
                      alertType={"error"}
                      message={
                        "Acceptable key can be any combination of  <ul><li>[a-z]</li><li>[A-z]</li><li>[0-9]</li> <li>_ underscore</li>"
                      }
                      ratio={0.5}
                      hideIcon={false}
                    />
                  )}
                </td>
                <td>:</td>
                <td>
                  <input
                    type="text"
                    value={Object.entries(item).map((i) => i[1])}
                    name={index}
                    onInput={dataValueChange}
                  />
                </td>
                <td
                  onClick={() => {
                    removeItem(index);
                  }}
                >
                  <BsTrash color={currentContext.theme.color} size="12" />
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <input
                  ref={currentInput}
                  type="text"
                  value={current.key}
                  name={current.key}
                  onInput={currentKeyChange}
                />
              </td>
              <td>:</td>
              <td>
                <input
                  type="text"
                  value={current.value}
                  name={current.value}
                  onInput={currentValueChange}
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
KeyValueFormNew.defaultProps = {
  tags: {},
  errors: [],
};

export default KeyValueFormNew;
