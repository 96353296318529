import { useAppTheme } from 'src/helper/AppThemeProvider';
import './MeasurementSystemSwitch.sass';
import { MdOutlineLightMode } from 'react-icons/md';
import { HiOutlineMoon } from 'react-icons/hi';
import { BiMoon } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { MeasurementSystem } from 'src/helper/constants';
import { measurementSystem as setMeasurementSystem } from 'src/helper/settingsSlice';
import { TbCurrencyDollar, TbMeterSquare } from 'react-icons/tb';
import useMeasurement from 'src/helper/useMeasurement';

function MeasurementSystemSwitch() {
  const { measurementSystem } = useMeasurement();
  const dispatch = useDispatch();

  const handleNewValue = (value) => {
    dispatch(setMeasurementSystem(value));
    localStorage.setItem('measurement_system', value);
  }

  return (
    <div className="measurement-unit-switch">
      {measurementSystem === MeasurementSystem.METRIC ? (
        <TbMeterSquare className="icon" onClick={() => handleNewValue(MeasurementSystem.IMPERIAL)} title="Metric measurement unit" />
      ):(
        <TbCurrencyDollar className="icon" onClick={() => handleNewValue(MeasurementSystem.METRIC)} title="Imperial measurement unit" />
      )}
    </div>
  )
}

export default MeasurementSystemSwitch