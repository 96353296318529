import { useThrottleFn } from 'ahooks';
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { getSubDeviceTelemetries } from 'src/services/device/common/deviceUtils';
import useSubDevice from 'src/services/device/common/useSubDevice';
import AdjustmentOverlay from 'src/ui/AdjustmentOverlay/AdjustmentOverlay';
import 'gamecontroller.js';

const DRC_HORIZONTAL_MAX_SPEED = 17;
const DRC_VERTICAL_MAX_SPEED = 4;
const DRC_YAW_MAX_SPEED = 90;
const DRC_SPEED_RATIO_MIN = 1;
const DRC_SPEED_RATIO_MAX = 10;

function ManualFlightShortcuts({ deviceId, missionController }) {
  const disableKeyboardEvents = useSelector(state => state.ui.disableKeyboardEvents);
  const { telemetries: subDeviceTelemetries } = useSubDevice(deviceId);
  const [adjustmentOverlayConfig, setAdjustmentOverlayConfig] = useState();
  const lastLocalValuesRef = useRef({});
  const drcControlValuesRef = useRef({});
  const drcControlSeqRef = useRef(0);
  const drcIntervalIdRef = useRef(null);
  const drcSpeedRatioRef = useRef(5);

  const { run: throttledHandleKeydown } = useThrottleFn((event) => {
    const shift = !!event.shiftKey;
    var key = event.code.replace("Arrow", ""); // ArrowUp -> Up, etc... for Edge and IE 11
    let targetCameraId = null;

    if(subDeviceTelemetries?.['80-0-0']) {
      targetCameraId = "80-0-0";
    } else if(subDeviceTelemetries?.['81-0-0']) {
      targetCameraId = "81-0-0";
    } else {
      console.log('❌ No camera found for manual flight shortcuts');
      return;
    }

    if(shift && (key === "Up" || key === "Down" || key === "Right" || key === "Left")){
      const ratio = 10;
      const pitchMultiply = key === "Up" ? 1 : key === "Down" ? -1 : 0;
      const yawMultiply = key === "Left" ? -1 : key === "Right" ? 1 : 0;

      missionController.liveFlight?.sendCameraScreenDrag(deviceId, {
        locked: false,
        payload_index: targetCameraId,
        pitch_speed: ratio * pitchMultiply,
        yaw_speed: ratio * yawMultiply,
      });
    }

    if(key === "KeyR"){
      missionController.liveFlight?.sendGimbalReset(deviceId, {
        payload_index: targetCameraId,
        reset_mode: 0
      });
    }

    if(key === "KeyX"){
      drcSpeedModeRef.current = (drcSpeedModeRef.current + 1) % 3;
    }

    if(key === "KeyQ" || key === "KeyE"){
      const ratio = 4;
      const maxZoomFactor = 56;
      const minZoomFactor = 2;
      const zoomMultiply = key === "KeyE" ? 1 : -1;
      const currZoomFactor = getSubDeviceTelemetries(deviceId)?.[targetCameraId]?.zoom_factor || 1;
      let newZoomFactor = Math.round((lastLocalValuesRef.current.zoom_factor || currZoomFactor) + (ratio * zoomMultiply));

      if(newZoomFactor > maxZoomFactor) newZoomFactor = maxZoomFactor;
      if(newZoomFactor < minZoomFactor) newZoomFactor = minZoomFactor;

      setAdjustmentOverlayConfig({
        minValue: minZoomFactor,
        maxValue: maxZoomFactor,
        value: newZoomFactor,
        title: 'Zoom Factor'
      });

      lastLocalValuesRef.current.zoom_factor = newZoomFactor;

      missionController.liveFlight?.sendCameraFocalLengthSet(deviceId, {
        camera_type: 'zoom',
        payload_index: targetCameraId,
        zoom_factor: newZoomFactor
      });
    }

    if(key === "KeyZ" || key === "KeyC"){
      let newSpeedRatio = drcSpeedRatioRef.current + (key === "KeyC" ? 1 : -1);

      if(newSpeedRatio > DRC_SPEED_RATIO_MAX) newSpeedRatio = DRC_SPEED_RATIO_MAX;
      if(newSpeedRatio < DRC_SPEED_RATIO_MIN) newSpeedRatio = DRC_SPEED_RATIO_MIN;

      setAdjustmentOverlayConfig({
        minValue: DRC_SPEED_RATIO_MIN,
        maxValue: DRC_SPEED_RATIO_MAX,
        value: newSpeedRatio,
        title: 'Speed Ratio'
      });

      drcSpeedRatioRef.current = newSpeedRatio;
    }
  }, { wait: 500 });

  const drcHandleKeydown = (event) => {
    const ratio = 0.1;
    const shift = !!event.shiftKey;
    const controlValues = { ...drcControlValuesRef.current };
    const key = event.code.replace("Arrow", "");
    const normalizeDrcValue = (value) => value != undefined ? parseFloat(value.toFixed(2)) : 0;

    // Skip alternative shortcuts
    if(shift) return;

    // ratio based on drcSpeedMode
    const horizontalRatio = (drcSpeedRatioRef.current /10);
    const verticalRatio = (drcSpeedRatioRef.current / 10);
    const rotateRatio = 0.2;

    switch(key) {
      case 'KeyW':
        controlValues.x = normalizeDrcValue(DRC_HORIZONTAL_MAX_SPEED * horizontalRatio);
        break;
    
      case 'KeyS':
        controlValues.x = normalizeDrcValue(-DRC_HORIZONTAL_MAX_SPEED * horizontalRatio);
        break;
    
      case 'KeyA':
        controlValues.w = normalizeDrcValue(-DRC_YAW_MAX_SPEED * rotateRatio);
        break;
    
      case 'KeyD':
        controlValues.w = normalizeDrcValue(DRC_YAW_MAX_SPEED * rotateRatio);
        break;
    
      case 'Up':
        controlValues.h = normalizeDrcValue(DRC_VERTICAL_MAX_SPEED * verticalRatio);
        break;
    
      case 'Down':
        controlValues.h = normalizeDrcValue(-DRC_VERTICAL_MAX_SPEED * verticalRatio);
        break;
    
      case 'Left':
        controlValues.y = normalizeDrcValue(-DRC_HORIZONTAL_MAX_SPEED * horizontalRatio);
        break;

      case 'Right':
        controlValues.y = normalizeDrcValue(DRC_HORIZONTAL_MAX_SPEED * horizontalRatio);
        break;
    }

    // check if any of prev values is different from current values
    if(Object.keys(controlValues).some(key => controlValues[key] !== drcControlValuesRef.current[key])) {
      drcControlSeqRef.current = 0;
    }

    drcControlValuesRef.current = controlValues;
  }

  const handleKeydown = useCallback((event) => {
    throttledHandleKeydown(event);
    drcHandleKeydown(event);
  }, [throttledHandleKeydown]);

  const handleKeyUp = (event) => {
    const key = event.code.replace("Arrow", "");
    const controlValues = { ...drcControlValuesRef.current };

    if(key === 'KeyW' || key === 'KeyS') {
      delete controlValues.x;
    }

    if(key === 'KeyA' || key === 'KeyD') {
      delete controlValues.w;
    }

    if(key === 'Up' || key === 'Down') {
      delete controlValues.h;
    }

    if(key === 'Left' || key === 'Right') {
      delete controlValues.y;
    }

    drcControlValuesRef.current = controlValues;
  }

  useEffect(() => {
    document.removeEventListener('keydown', handleKeydown);
    document.addEventListener('keyup', handleKeyUp);

    // if(!disableKeyboardEvents) {
      document.addEventListener('keydown', handleKeydown);
      document.addEventListener('keyup', handleKeyUp);
    // }

    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [disableKeyboardEvents, handleKeydown]);
  
  useEffect(() => {
    clearInterval(drcIntervalIdRef.current);

    drcIntervalIdRef.current = setInterval(() => {
      if(Object.keys(drcControlValuesRef.current).length) {
        missionController.liveFlight?.sendDrcDroneControl(deviceId, {
          ...drcControlValuesRef.current,
          seq: drcControlSeqRef.current++,
        });
      }
    }, 100);

    return () => clearInterval(drcIntervalIdRef.current);
  }, []);

  return adjustmentOverlayConfig ? (
    <AdjustmentOverlay
      min={adjustmentOverlayConfig.minValue} 
      max={adjustmentOverlayConfig.maxValue} 
      value={adjustmentOverlayConfig.value}
      title={adjustmentOverlayConfig.title}
    />
  ) : (
    <div></div>
  )
}

export default ManualFlightShortcuts