import { useTheme } from "src/helper/ThemeProvider";
import React from "react";

function EmptyTab() {
  const { currentContext } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      viewBox="0 0 240.508 183.334"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_503"
            data-name="Rectangle 503"
            width="199.697"
            height="148.422"
            fill="none"
          />
        </clipPath>
        <pattern
          id="pattern"
          preserveAspectRatio="none"
          width="100%"
          height="100%"
          viewBox="0 0 117 90"
        >
          <image
            width="117"
            height="90"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHUAAABaCAYAAACG/hlfAAAAAXNSR0IArs4c6QAABAVJREFUeAHtndtu20AQQ5O2///JTU0hxxhvKLVAZRlsuYA6s5y9jHmg9FFvbx3/nAPvB7/oqHawraULHfhwdzlwTtPePd2dW+05DliIt6se9BXUOldraMSpKe94rgMT2F6uDu61I1DUFF3+3J/S06cDAFN0OWu32g9mSwSki1oK5GVbp09yAJgzzqsALS4fQJ2QVpDfbgulEVlLnIc3P9cBYE2YP29XMCd/uBWoiA6oYPLM+txD3niOA8DkNM0BKAbK9cwXiz3vK9Tbum1oMSC/j5y3VYvmgZp3nO8AoBTl/QpSN0pjbOsnVEGajw4BKBHYOqRQ5cJzx4SqfPUcjXVbNxMq7U2wQOSt7ZuKS9dFAeOZt6IpipPiNlaoAJ0QZ05dseMaBwAGNCCuLFj3tkKdbc5N5AKsoXnH9Q7IdzEQ2Pn/q3RgfwDppt1BARCNuSLaZ9pwkQPOe6dt7QgqRQlrrvnUtKbjNQ7AYTJBe+iIN3Ut7s1X/eGwTi51YLKY+fb3+dJOetnpDjwA1em8qaff1AOf4sAXgO6WQnWuhGuFGg7QtV+ozpVwrVDDAbr2C9W5Eq4VajhA136hOlfCtUINB+jaL1TnSrhWqOEAXfuF6lwJ1wo1HKBrv1CdK+FaoYYDdO0XqnMlXCvUcICu/UJ1roRrhRoO0LVfqM6VcK1QwwG69gvVuRKuFWo4QNd+oTpXwrVCDQfo2i9U50q4VqjhAF37hepcCdcKNRyga79QnSvhWqGGA3TtF6pzJVwr1HCArv1Cda6Ea4UaDtC1X6jOlXCtUMMBuvYL1bkSrhVqOEDXfqE6V8K1Qg0H6NovVOdKuFao4QBd+4XqXAnXCjUcoGu/UJ0r4VqhhgN07ReqcyVcK9RwgK79QnWuhGuFGg7QtV+ozpVwrVDDAbr2C9W5Eq4VajhA136hOlfCtULNAsiXGQ+7LtRDeyKKX0ADdS3szVc94lf/o01OFjO/f4uT3/1QvImarxprG1/jwGRi2fCmqj0WsIlIjfprfsr/fSssJgNy4t2hoy8dcxBf1GV+39zkMgfwXlE89KCpCeUaW1yhsnCNOoShLwP+0dcB2dD4Vw5MFhPm1HWB5ttYoUpUUZuBtwJE19qO5zsw4YnL+lC/dzKh3knfqhOsFrNRsVDlyHVjev9boGprQp1tcpAOYaDx5hKpN57vgDzXwPs9qNS3xStUDlERoGwQRGl9U+XOdQMmivLfRdaoq48V6ibqn88BRG0C5nxDZ86exvMcAJYiOWCn9nDjhDJzLdIcbc05hDrzxnMdAKSLq6abN22FcjSnRjy3/Z525AAAtYacOPdZqFqwB21Pn4c2f74DuzC5+gjUUY39ja93wEF+fVft4FwHfgF7EMmtEbrBGwAAAABJRU5ErkJggg=="
          />
        </pattern>
      </defs>
      <g
        id="Group_41694"
        data-name="Group 41694"
        transform="translate(-244.141 -771.636)"
      >
        <g
          id="Group_41693"
          data-name="Group 41693"
          transform="translate(258.935 795.656)"
        >
          <g id="Group_7806" data-name="Group 7806" clipPath="url(#clip-path)">
            <rect
              id="Rectangle_502"
              data-name="Rectangle 502"
              width="199.697"
              height="148.422"
              fill="url(#pattern)"
            />
          </g>
        </g>
        <path
          id="Path_76196"
          data-name="Path 76196"
          d="M374.541,112.685H374.3a9.2,9.2,0,0,0,.161-1.7,9.347,9.347,0,0,0-9.347-9.347h-.068a7.146,7.146,0,0,0-12.525.263,7.826,7.826,0,0,0-9.866,7.316,7.129,7.129,0,0,0,4.249,12.865,7.222,7.222,0,0,0,1.971-.28H372.96a4.7,4.7,0,1,0,1.589-9.118Z"
          transform="translate(44.515 673.574)"
          fill="#d7d7d7"
        />
        <g
          id="Group_41663"
          data-name="Group 41663"
          transform="translate(445.475 815.389)"
        >
          <rect
            id="Rectangle_15689"
            data-name="Rectangle 15689"
            width="39.083"
            height="39.078"
            rx="4.71"
            transform="translate(0.09 0.096)"
            fill={currentContext?.theme?.color}
          />
          <rect
            id="Rectangle_15690"
            data-name="Rectangle 15690"
            width="29.037"
            height="28.867"
            transform="translate(5.114 5.199)"
            fill="#fff"
            opacity="0.25"
          />
          <rect
            id="Rectangle_15691"
            data-name="Rectangle 15691"
            width="2.925"
            height="7.498"
            transform="translate(8.079 22.943)"
            fill="#fff"
            opacity="0.35"
          />
          <rect
            id="Rectangle_15692"
            data-name="Rectangle 15692"
            width="2.925"
            height="11.123"
            transform="translate(8.079 22.943)"
            fill="#fff"
            opacity="0.35"
          />
          <rect
            id="Rectangle_15693"
            data-name="Rectangle 15693"
            width="2.925"
            height="11.038"
            transform="translate(13.122 17.688)"
            fill="#fff"
            opacity="0.35"
          />
          <rect
            id="Rectangle_15694"
            data-name="Rectangle 15694"
            width="2.925"
            height="16.377"
            transform="translate(13.122 17.688)"
            fill="#fff"
            opacity="0.35"
          />
          <rect
            id="Rectangle_15695"
            data-name="Rectangle 15695"
            width="2.925"
            height="8.859"
            transform="translate(18.17 20.921)"
            fill="#fff"
            opacity="0.35"
          />
          <rect
            id="Rectangle_15696"
            data-name="Rectangle 15696"
            width="2.925"
            height="13.145"
            transform="translate(18.17 20.921)"
            fill="#fff"
            opacity="0.35"
          />
          <rect
            id="Rectangle_15697"
            data-name="Rectangle 15697"
            width="2.925"
            height="11.92"
            transform="translate(23.218 16.383)"
            fill="#fff"
            opacity="0.35"
          />
          <rect
            id="Rectangle_15698"
            data-name="Rectangle 15698"
            width="2.925"
            height="17.683"
            transform="translate(23.218 16.383)"
            fill="#fff"
            opacity="0.35"
          />
          <rect
            id="Rectangle_15699"
            data-name="Rectangle 15699"
            width="2.925"
            height="16.589"
            transform="translate(28.261 9.45)"
            fill="#fff"
            opacity="0.35"
          />
          <rect
            id="Rectangle_15700"
            data-name="Rectangle 15700"
            width="2.925"
            height="24.617"
            transform="translate(28.261 9.45)"
            fill="#fff"
            opacity="0.35"
          />
          <path
            id="Path_76213"
            data-name="Path 76213"
            d="M329.59,140v21.379a2.38,2.38,0,0,1-2.436,2.319H319V142.466A2.521,2.521,0,0,1,321.461,140Z"
            transform="translate(-319 -140)"
            fill="#fff"
            opacity="0.52"
          />
        </g>
        <path
          id="Path_76219"
          data-name="Path 76219"
          d="M373.263,112h-.229a8.678,8.678,0,0,0,.155-1.619,8.956,8.956,0,0,0-9-8.907h-.065a6.92,6.92,0,0,0-12.065.251,7.6,7.6,0,0,0-6.459,1.209,7.434,7.434,0,0,0-3.045,5.763,6.752,6.752,0,0,0-2.442,7.6,6.861,6.861,0,0,0,6.535,4.663,7.028,7.028,0,0,0,1.9-.267h23.2a4.561,4.561,0,0,0,4.839-1.144,4.441,4.441,0,0,0,.854-4.848,4.528,4.528,0,0,0-4.162-2.7Z"
          transform="translate(-80.828 691.691)"
          fill="#e5e5e5"
        />
        <path
          id="Path_76427"
          data-name="Path 76427"
          d="M334.8,78.51H168.926a5.163,5.163,0,0,0-5.176,5.15V200.243a5.163,5.163,0,0,0,5.176,5.154h63.663s4.053,15.588-8.5,22.036h59.532s-14.177-7.963-10.533-22.036h61.707a5.167,5.167,0,0,0,5.18-5.154V83.66A5.163,5.163,0,0,0,334.8,78.51Z"
          transform="translate(108.592 727.538)"
          fill="#d2d1d1"
        />
        <path
          id="Path_76428"
          data-name="Path 76428"
          d="M389.254,375.619a3.188,3.188,0,0,1-4.5,4.478,1.185,1.185,0,0,1-.278-.276,3.188,3.188,0,0,1,4.5-4.479A1.186,1.186,0,0,1,389.254,375.619Z"
          transform="translate(-26.586 549.555)"
          fill="#455a64"
        />
        <path
          id="Path_76676"
          data-name="Path 76676"
          d="M0,0H171.11V111.5H0Z"
          transform="translate(275.196 808.189)"
          fill="#fafafa"
        />
        <rect
          id="Rectangle_15782"
          data-name="Rectangle 15782"
          width="170.881"
          height="11"
          transform="translate(275.425 808.262)"
          fill={currentContext?.theme?.color}
        />
        <rect
          id="Rectangle_15752"
          data-name="Rectangle 15752"
          width="5.025"
          height="6.885"
          transform="translate(421.261 887.82)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41671"
          data-name="Group 41671"
          transform="translate(421.261 887.82)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15753"
            data-name="Rectangle 15753"
            width="5.025"
            height="6.885"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_15754"
          data-name="Rectangle 15754"
          width="5.025"
          height="10.937"
          transform="translate(410.651 883.767)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41672"
          data-name="Group 41672"
          transform="translate(410.651 883.767)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15755"
            data-name="Rectangle 15755"
            width="5.025"
            height="10.937"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_15756"
          data-name="Rectangle 15756"
          width="5.025"
          height="14.049"
          transform="translate(399.205 880.473)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41673"
          data-name="Group 41673"
          transform="translate(399.205 880.473)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15757"
            data-name="Rectangle 15757"
            width="5.025"
            height="14.049"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_15758"
          data-name="Rectangle 15758"
          width="5.025"
          height="9.494"
          transform="translate(293.056 885.029)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41674"
          data-name="Group 41674"
          transform="translate(293.056 885.029)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15759"
            data-name="Rectangle 15759"
            width="5.025"
            height="9.494"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_15760"
          data-name="Rectangle 15760"
          width="5.025"
          height="5.611"
          transform="translate(303.963 888.913)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41675"
          data-name="Group 41675"
          transform="translate(303.963 888.913)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15761"
            data-name="Rectangle 15761"
            width="5.025"
            height="5.611"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_15762"
          data-name="Rectangle 15762"
          width="5.025"
          height="12.875"
          transform="translate(314.098 881.825)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41676"
          data-name="Group 41676"
          transform="translate(314.098 881.825)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15763"
            data-name="Rectangle 15763"
            width="5.025"
            height="12.875"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_15764"
          data-name="Rectangle 15764"
          width="5.025"
          height="6.704"
          transform="translate(324.708 887.82)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41677"
          data-name="Group 41677"
          transform="translate(324.708 887.82)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15765"
            data-name="Rectangle 15765"
            width="5.025"
            height="6.704"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_15766"
          data-name="Rectangle 15766"
          width="5.025"
          height="12.695"
          transform="translate(335.14 881.825)"
          fill={currentContext?.theme?.color}
        />
        <rect
          id="Rectangle_15767"
          data-name="Rectangle 15767"
          width="5.025"
          height="28.932"
          transform="translate(345.472 865.592)"
          fill={currentContext?.theme?.color}
        />
        <rect
          id="Rectangle_15768"
          data-name="Rectangle 15768"
          width="5.025"
          height="40.801"
          transform="translate(356.24 853.718)"
          fill={currentContext?.theme?.color}
        />
        <rect
          id="Rectangle_15769"
          data-name="Rectangle 15769"
          width="5.025"
          height="24.062"
          transform="translate(366.966 870.458)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41678"
          data-name="Group 41678"
          transform="translate(345.472 865.592)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15770"
            data-name="Rectangle 15770"
            width="5.025"
            height="28.932"
            fill="#fff"
          />
        </g>
        <g
          id="Group_41679"
          data-name="Group 41679"
          transform="translate(366.966 870.458)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15771"
            data-name="Rectangle 15771"
            width="5.025"
            height="24.062"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_15772"
          data-name="Rectangle 15772"
          width="5.025"
          height="28.026"
          transform="translate(387.985 866.493)"
          fill={currentContext?.theme?.color}
        />
        <rect
          id="Rectangle_15773"
          data-name="Rectangle 15773"
          width="5.025"
          height="36.553"
          transform="translate(377.668 857.97)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76429"
          data-name="Path 76429"
          d="M207.4,189.2h-.771v-.384h2v.384h-.771v1.988h-.44Z"
          transform="translate(82.249 659.56)"
          fill="#263238"
        />
        <path
          id="Path_76430"
          data-name="Path 76430"
          d="M211.679,191.054a.961.961,0,1,1,.96.917.909.909,0,0,1-.682-.25A.9.9,0,0,1,211.679,191.054Zm1.5,0a.536.536,0,1,0-.536.56.526.526,0,0,0,.393-.162A.52.52,0,0,0,213.175,191.054Z"
          transform="translate(79.148 658.796)"
          fill="#263238"
        />
        <path
          id="Path_76431"
          data-name="Path 76431"
          d="M219.221,188.49v2.5h-.4v-.23a.746.746,0,0,1-.594.253.917.917,0,1,1,0-1.834.738.738,0,0,1,.579.242V188.5Zm-.413,1.6a.527.527,0,1,0-.145.4.524.524,0,0,0,.145-.4Z"
          transform="translate(75.694 659.763)"
          fill="#263238"
        />
        <path
          id="Path_76432"
          data-name="Path 76432"
          d="M225.044,191.036V192.1h-.386v-.219a.638.638,0,0,1-.567.242c-.413,0-.671-.223-.671-.537s.2-.533.748-.533h.463v-.027c0-.242-.147-.384-.447-.384a.886.886,0,0,0-.544.177l-.166-.3a1.254,1.254,0,0,1,.771-.223A.687.687,0,0,1,225.044,191.036Zm-.424.491v-.2h-.432c-.289,0-.366.107-.366.238s.131.249.343.249A.459.459,0,0,0,224.619,191.528Z"
          transform="translate(71.935 658.655)"
          fill="#263238"
        />
        <path
          id="Path_76433"
          data-name="Path 76433"
          d="M230.1,190.31l-.841,1.938a.7.7,0,0,1-.694.53.739.739,0,0,1-.482-.165l.166-.307a.464.464,0,0,0,.312.123c.147,0,.235-.069.316-.249l.031-.069-.771-1.8h.44l.567,1.328.567-1.328Z"
          transform="translate(69.072 658.643)"
          fill="#263238"
        />
        <path
          id="Path_76434"
          data-name="Path 76434"
          d="M207.009,330.14v1.2h-.123v-1.09H206.6v-.107Z"
          transform="translate(82.268 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76435"
          data-name="Path 76435"
          d="M223.109,331.2v.111h-.845V331.2l.5-.487c.135-.134.162-.215.162-.3,0-.138-.1-.226-.282-.226a.387.387,0,0,0-.328.134l-.089-.073a.537.537,0,0,1,.428-.173c.243,0,.386.123.386.322a.49.49,0,0,1-.2.384l-.409.384Z"
          transform="translate(72.666 572.512)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76436"
          data-name="Path 76436"
          d="M239.118,330.992c0,.2-.147.353-.436.353a.615.615,0,0,1-.432-.154l.058-.1a.526.526,0,0,0,.386.138c.2,0,.309-.092.309-.242s-.1-.238-.324-.238h-.085v-.092l.332-.414h-.613v-.107h.771v.084l-.339.426C238.99,330.666,239.118,330.8,239.118,330.992Z"
          transform="translate(62.824 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76437"
          data-name="Path 76437"
          d="M255.279,331.023h-.22v.315h-.112v-.315h-.617v-.092l.578-.791h.127l-.559.768h.474v-.276h.108v.276h.22Z"
          transform="translate(52.946 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76438"
          data-name="Path 76438"
          d="M271.258,330.984c0,.2-.143.361-.436.361a.623.623,0,0,1-.432-.153l.062-.1a.522.522,0,0,0,.386.138c.2,0,.312-.1.312-.246s-.1-.249-.386-.249h-.258l.062-.6h.659v.107h-.551l-.039.384h.154C271.123,330.631,271.258,330.769,271.258,330.984Z"
          transform="translate(43.08 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76439"
          data-name="Path 76439"
          d="M287.382,330.982a.383.383,0,0,1-.121.28.387.387,0,0,1-.288.1c-.32,0-.494-.219-.494-.6a.551.551,0,0,1,.548-.618.542.542,0,0,1,.285.065l-.046.1a.422.422,0,0,0-.235-.058c-.258,0-.428.165-.428.491a.949.949,0,0,0,0,.1.386.386,0,0,1,.366-.2.364.364,0,0,1,.282.08A.36.36,0,0,1,287.382,330.982Zm-.12,0c0-.157-.12-.257-.3-.257a.267.267,0,0,0-.276.111.264.264,0,0,0,0,.3.318.318,0,0,0,.581-.146Z"
          transform="translate(33.195 572.468)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76440"
          data-name="Path 76440"
          d="M303.363,330.134v.084l-.5,1.113h-.135l.494-1.09H302.6v.223h-.123v-.334Z"
          transform="translate(23.366 572.478)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76441"
          data-name="Path 76441"
          d="M207.009,207.81v1.2h-.123v-1.086H206.6v-.111Z"
          transform="translate(82.268 647.858)"
          fill="#263238"
        />
        <path
          id="Path_76442"
          data-name="Path 76442"
          d="M208.4,208.4c0-.384.2-.606.482-.606s.478.226.478.606-.2.61-.478.61S208.4,208.78,208.4,208.4Zm.837,0c0-.319-.147-.5-.355-.5s-.355.177-.355.5.143.5.355.5S209.237,208.719,209.237,208.4Z"
          transform="translate(81.162 647.87)"
          fill="#263238"
        />
        <path
          id="Path_76443"
          data-name="Path 76443"
          d="M211.31,210.562a.093.093,0,0,1,.185,0,.093.093,0,1,1-.185,0Z"
          transform="translate(79.375 646.219)"
          fill="#263238"
        />
        <path
          id="Path_76444"
          data-name="Path 76444"
          d="M212.2,208.4c0-.384.2-.606.478-.606s.482.226.482.606-.2.61-.482.61S212.2,208.78,212.2,208.4Zm.833,0c0-.319-.143-.5-.355-.5s-.351.177-.351.5.143.5.351.5S213.033,208.719,213.033,208.4Z"
          transform="translate(78.828 647.87)"
          fill="#263238"
        />
        <path
          id="Path_76445"
          data-name="Path 76445"
          d="M215.15,208.4c0-.384.2-.606.478-.606s.482.226.482.606-.2.61-.482.61S215.15,208.78,215.15,208.4Zm.833,0c0-.319-.143-.5-.355-.5s-.351.177-.351.5.143.5.351.5S215.983,208.719,215.983,208.4Z"
          transform="translate(77.016 647.87)"
          fill="#263238"
        />
        <path
          id="Path_76446"
          data-name="Path 76446"
          d="M218.1,208.4c0-.384.2-.606.478-.606s.482.226.482.606-.2.61-.482.61S218.1,208.78,218.1,208.4Zm.833,0c0-.319-.143-.5-.355-.5s-.355.177-.355.5.147.5.355.5S218.933,208.719,218.933,208.4Z"
          transform="translate(75.203 647.87)"
          fill="#263238"
        />
        <path
          id="Path_76447"
          data-name="Path 76447"
          d="M319.423,330.985c0,.211-.181.342-.47.342s-.463-.13-.463-.342a.3.3,0,0,1,.231-.3.273.273,0,0,1-.189-.265c0-.192.166-.315.42-.315s.428.123.428.315a.269.269,0,0,1-.193.265A.3.3,0,0,1,319.423,330.985Zm-.127,0c0-.146-.127-.238-.343-.238s-.336.092-.336.238.123.242.336.242.343-.1.343-.246Zm-.343-.338c.189,0,.3-.081.3-.211s-.12-.219-.3-.219-.3.084-.3.215.112.211.3.211Z"
          transform="translate(13.53 572.491)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76448"
          data-name="Path 76448"
          d="M335.445,330.683c0,.407-.224.618-.548.618a.542.542,0,0,1-.285-.065l.046-.1a.43.43,0,0,0,.235.058c.258,0,.428-.165.428-.491q0-.048,0-.1a.386.386,0,0,1-.366.2.364.364,0,0,1-.283-.088.36.36,0,0,1-.122-.269.383.383,0,0,1,.121-.28.387.387,0,0,1,.288-.1C335.271,330.084,335.445,330.3,335.445,330.683Zm-.17-.238a.28.28,0,0,0-.1-.2.283.283,0,0,0-.214-.061.266.266,0,0,0-.3.261c0,.157.12.257.3.257a.277.277,0,0,0,.309-.261Z"
          transform="translate(3.664 572.518)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76449"
          data-name="Path 76449"
          d="M350.789,330.14v1.2h-.123v-1.09h-.285v-.107Z"
          transform="translate(-6.061 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76450"
          data-name="Path 76450"
          d="M352.18,330.72c0-.384.2-.61.482-.61s.478.226.478.61-.2.606-.478.606S352.18,331.1,352.18,330.72Zm.833,0c0-.322-.143-.5-.351-.5s-.355.177-.355.5.143.5.355.5S353.013,331.039,353.013,330.72Z"
          transform="translate(-7.166 572.491)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76451"
          data-name="Path 76451"
          d="M366.386,330.14v1.2h-.123v-1.09H366v-.107Z"
          transform="translate(-15.657 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76452"
          data-name="Path 76452"
          d="M367.985,330.14v1.2h-.123v-1.09h-.282v-.107Z"
          transform="translate(-16.627 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76453"
          data-name="Path 76453"
          d="M382.417,330.14v1.2h-.123v-1.09H382v-.107Z"
          transform="translate(-25.486 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76454"
          data-name="Path 76454"
          d="M384.6,331.2v.111h-.845V331.2l.5-.487c.135-.134.158-.215.158-.3,0-.138-.1-.226-.282-.226a.41.41,0,0,0-.328.134l-.085-.073a.53.53,0,0,1,.424-.173c.243,0,.386.123.386.322a.491.491,0,0,1-.2.384l-.409.384Z"
          transform="translate(-26.542 572.512)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76455"
          data-name="Path 76455"
          d="M398.362,330.14v1.2h-.112v-1.09H398v-.107Z"
          transform="translate(-35.315 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76456"
          data-name="Path 76456"
          d="M400.271,330.992a.348.348,0,0,1-.113.263.352.352,0,0,1-.272.09.534.534,0,0,1-.386-.154l.054-.1a.468.468,0,0,0,.339.138c.181,0,.282-.092.282-.242s-.089-.238-.293-.238H399.8v-.092l.3-.414h-.559v-.107h.706v.084l-.312.426a.329.329,0,0,1,.24.1A.325.325,0,0,1,400.271,330.992Z"
          transform="translate(-36.236 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76457"
          data-name="Path 76457"
          d="M414.525,330.14v1.2H414.4v-1.09h-.282v-.107Z"
          transform="translate(-45.218 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76458"
          data-name="Path 76458"
          d="M416.905,331.023h-.243v.315h-.123v-.315h-.679v-.092l.64-.791h.135l-.613.768h.521v-.276h.12v.276h.243Z"
          transform="translate(-46.286 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76459"
          data-name="Path 76459"
          d="M430.475,330.14v1.2h-.123v-1.09h-.282v-.107Z"
          transform="translate(-55.017 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76460"
          data-name="Path 76460"
          d="M432.618,330.984c0,.2-.143.361-.436.361a.615.615,0,0,1-.432-.153l.062-.1a.522.522,0,0,0,.386.138c.2,0,.312-.1.312-.246s-.1-.249-.386-.249h-.278l.062-.6h.66v.107h-.552l-.039.384h.154C432.475,330.631,432.618,330.769,432.618,330.984Z"
          transform="translate(-56.049 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76461"
          data-name="Path 76461"
          d="M446.569,330.14v1.2h-.123v-1.09h-.285v-.107Z"
          transform="translate(-64.901 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76462"
          data-name="Path 76462"
          d="M448.862,330.982a.385.385,0,0,1-.409.384c-.32,0-.494-.219-.494-.6a.552.552,0,0,1,.552-.618.561.561,0,0,1,.285.065l-.05.1a.422.422,0,0,0-.235-.058c-.258,0-.428.165-.428.491a.949.949,0,0,0,0,.1.386.386,0,0,1,.366-.2.362.362,0,0,1,.413.341Zm-.12,0c0-.157-.12-.257-.3-.257a.279.279,0,0,0-.211.064.276.276,0,0,0-.1.2c0,.13.112.257.32.257a.263.263,0,0,0,.207-.062.26.26,0,0,0,.09-.2Z"
          transform="translate(-66.007 572.468)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76463"
          data-name="Path 76463"
          d="M462.5,330.14v1.2h-.123v-1.09H462.1v-.107Z"
          transform="translate(-74.693 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76464"
          data-name="Path 76464"
          d="M464.693,330.134v.084l-.505,1.113h-.135l.5-1.09h-.621v.223h-.12v-.334Z"
          transform="translate(-75.744 572.478)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76465"
          data-name="Path 76465"
          d="M478.615,330.14v1.2h-.123v-1.09h-.282v-.107Z"
          transform="translate(-84.59 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76466"
          data-name="Path 76466"
          d="M480.923,330.985c0,.211-.181.342-.471.342s-.463-.13-.463-.342a.3.3,0,0,1,.231-.3.273.273,0,0,1-.189-.265c0-.192.166-.315.42-.315s.424.123.424.315a.269.269,0,0,1-.189.265A.3.3,0,0,1,480.923,330.985Zm-.127,0c0-.146-.127-.238-.343-.238s-.336.092-.336.238.123.242.336.242.343-.1.343-.246Zm-.343-.338c.189,0,.3-.081.3-.211s-.12-.219-.3-.219-.3.084-.3.215.112.211.3.211Z"
          transform="translate(-85.684 572.491)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76467"
          data-name="Path 76467"
          d="M493.667,330.14v1.2h-.116v-1.09h-.281v-.107Z"
          transform="translate(-93.842 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76468"
          data-name="Path 76468"
          d="M495.862,330.682c0,.407-.224.618-.552.618a.561.561,0,0,1-.285-.065l.05-.1a.422.422,0,0,0,.235.058c.258,0,.424-.165.424-.491a.867.867,0,0,0,0-.1.405.405,0,1,1-.363-.537C495.689,330.084,495.862,330.3,495.862,330.682Zm-.17-.238c0-.13-.112-.257-.32-.257a.263.263,0,0,0-.206.065.26.26,0,0,0-.087.2c0,.157.116.257.3.257a.279.279,0,0,0,.211-.064A.276.276,0,0,0,495.693,330.445Z"
          transform="translate(-94.881 572.518)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76469"
          data-name="Path 76469"
          d="M509.209,331.2v.111h-.845V331.2l.5-.487c.135-.134.162-.215.162-.3,0-.138-.1-.226-.282-.226a.387.387,0,0,0-.328.134l-.089-.073a.537.537,0,0,1,.428-.173c.243,0,.386.123.386.322a.5.5,0,0,1-.2.384l-.409.384Z"
          transform="translate(-103.094 572.512)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76470"
          data-name="Path 76470"
          d="M511,330.72c0-.384.2-.61.482-.61s.478.226.478.61-.2.606-.478.606S511,331.1,511,330.72Zm.833,0c0-.322-.143-.5-.351-.5s-.355.177-.355.5.143.5.355.5.347-.177.347-.5Z"
          transform="translate(-104.734 572.491)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76471"
          data-name="Path 76471"
          d="M524.249,331.2v.111H523.4V331.2l.5-.487c.135-.134.162-.215.162-.3,0-.138-.1-.226-.282-.226a.387.387,0,0,0-.328.134l-.089-.073a.537.537,0,0,1,.428-.173c.243,0,.386.123.386.322a.5.5,0,0,1-.2.384l-.409.384Z"
          transform="translate(-112.334 572.512)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76472"
          data-name="Path 76472"
          d="M526.269,330.14v1.2h-.123v-1.09h-.285v-.107Z"
          transform="translate(-113.863 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76473"
          data-name="Path 76473"
          d="M539.289,331.2v.111h-.845V331.2l.5-.487c.135-.134.162-.215.162-.3,0-.138-.1-.226-.282-.226a.387.387,0,0,0-.328.134l-.089-.073a.537.537,0,0,1,.428-.173c.243,0,.386.123.386.322a.5.5,0,0,1-.2.384l-.39.414Z"
          transform="translate(-121.573 572.512)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76474"
          data-name="Path 76474"
          d="M541.8,331.2v.111h-.856V331.2l.5-.487c.135-.134.162-.215.162-.3,0-.138-.1-.226-.282-.226a.387.387,0,0,0-.328.134l-.089-.073a.537.537,0,0,1,.428-.173c.243,0,.386.123.386.322a.5.5,0,0,1-.2.384l-.409.384Z"
          transform="translate(-123.109 572.512)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76475"
          data-name="Path 76475"
          d="M554.329,331.2v.111h-.845V331.2l.5-.487c.135-.134.162-.215.162-.3,0-.138-.1-.226-.281-.226a.387.387,0,0,0-.328.134l-.089-.073a.537.537,0,0,1,.424-.173c.247,0,.386.123.386.322a.5.5,0,0,1-.2.384l-.4.414Z"
          transform="translate(-130.812 572.512)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76476"
          data-name="Path 76476"
          d="M556.808,330.992c0,.2-.143.353-.436.353a.615.615,0,0,1-.432-.154l.062-.1a.522.522,0,0,0,.386.138c.2,0,.309-.092.309-.242s-.1-.238-.324-.238h-.085v-.092l.332-.414h-.613v-.107h.771v.084l-.339.426C556.68,330.666,556.808,330.8,556.808,330.992Z"
          transform="translate(-132.342 572.474)"
          fill="#e0e0e0"
        />
        <path
          id="Path_76477"
          data-name="Path 76477"
          d="M344.5,324.99c0,.054-30.881.1-68.965.1s-68.973-.046-68.973-.1,30.877-.1,68.973-.1S344.5,324.932,344.5,324.99Z"
          transform="translate(82.292 575.709)"
          fill="#e0e0e0"
        />
        <g
          id="Group_41680"
          data-name="Group 41680"
          transform="translate(377.668 857.97)"
          opacity="0.3"
        >
          <rect
            id="Rectangle_15774"
            data-name="Rectangle 15774"
            width="5.025"
            height="36.553"
            fill="#fff"
          />
        </g>
        <g
          id="Group_41681"
          data-name="Group 41681"
          transform="translate(335.14 881.825)"
          opacity="0.79"
        >
          <rect
            id="Rectangle_15775"
            data-name="Rectangle 15775"
            width="5.025"
            height="12.695"
            fill="#fff"
          />
        </g>
        <path
          id="Path_76478"
          data-name="Path 76478"
          d="M228.714,209.89a6.759,6.759,0,0,1-2.314,0A6.759,6.759,0,0,1,228.714,209.89Z"
          transform="translate(70.104 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76479"
          data-name="Path 76479"
          d="M248.918,209.87a26.879,26.879,0,0,1-4.628,0,26.887,26.887,0,0,1,4.628,0Z"
          transform="translate(59.114 646.658)"
          fill="#263238"
        />
        <path
          id="Path_76480"
          data-name="Path 76480"
          d="M272.808,209.87a26.879,26.879,0,0,1-4.628,0,26.883,26.883,0,0,1,4.628,0Z"
          transform="translate(44.437 646.658)"
          fill="#263238"
        />
        <path
          id="Path_76481"
          data-name="Path 76481"
          d="M296.739,209.89a26.662,26.662,0,0,1-4.609,0A26.662,26.662,0,0,1,296.739,209.89Z"
          transform="translate(29.724 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76482"
          data-name="Path 76482"
          d="M320.628,209.888a26.665,26.665,0,0,1-4.628,0A26.659,26.659,0,0,1,320.628,209.888Z"
          transform="translate(15.06 646.639)"
          fill="#263238"
        />
        <path
          id="Path_76483"
          data-name="Path 76483"
          d="M344.539,209.89a26.659,26.659,0,0,1-4.609,0A26.662,26.662,0,0,1,344.539,209.89Z"
          transform="translate(0.359 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76484"
          data-name="Path 76484"
          d="M368.439,209.89a26.659,26.659,0,0,1-4.609,0,26.662,26.662,0,0,1,4.609,0Z"
          transform="translate(-14.323 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76485"
          data-name="Path 76485"
          d="M392.329,209.89a26.659,26.659,0,0,1-4.609,0A26.662,26.662,0,0,1,392.329,209.89Z"
          transform="translate(-28.999 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76486"
          data-name="Path 76486"
          d="M416.229,209.89a26.662,26.662,0,0,1-4.609,0A26.662,26.662,0,0,1,416.229,209.89Z"
          transform="translate(-43.682 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76487"
          data-name="Path 76487"
          d="M440.125,209.89a26.617,26.617,0,0,1-4.6,0A26.614,26.614,0,0,1,440.125,209.89Z"
          transform="translate(-58.365 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76488"
          data-name="Path 76488"
          d="M464.019,209.89a26.662,26.662,0,0,1-4.609,0A26.662,26.662,0,0,1,464.019,209.89Z"
          transform="translate(-73.041 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76489"
          data-name="Path 76489"
          d="M487.919,209.89a26.662,26.662,0,0,1-4.609,0A26.662,26.662,0,0,1,487.919,209.89Z"
          transform="translate(-87.723 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76490"
          data-name="Path 76490"
          d="M511.819,209.89a26.662,26.662,0,0,1-4.609,0A26.662,26.662,0,0,1,511.819,209.89Z"
          transform="translate(-102.405 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76491"
          data-name="Path 76491"
          d="M535.7,209.889a26.705,26.705,0,0,1-4.628,0A26.7,26.7,0,0,1,535.7,209.889Z"
          transform="translate(-117.064 646.639)"
          fill="#263238"
        />
        <path
          id="Path_76492"
          data-name="Path 76492"
          d="M557.314,209.89a6.758,6.758,0,0,1-2.314,0A6.758,6.758,0,0,1,557.314,209.89Z"
          transform="translate(-131.765 646.637)"
          fill="#263238"
        />
        <path
          id="Path_76493"
          data-name="Path 76493"
          d="M222.512,296.09a62.792,62.792,0,0,1-5,4.981,30.629,30.629,0,0,1,2.43-2.56A31.054,31.054,0,0,1,222.512,296.09Z"
          transform="translate(75.565 593.457)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76494"
          data-name="Path 76494"
          d="M250.706,296.31a31.34,31.34,0,0,1-2.43,2.56,30.244,30.244,0,0,1-2.576,2.418,31.761,31.761,0,0,1,2.434-2.56,31.371,31.371,0,0,1,2.572-2.418Z"
          transform="translate(58.247 593.32)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76495"
          data-name="Path 76495"
          d="M304.556,296.08a31.015,31.015,0,0,1-2.434,2.56,30.345,30.345,0,0,1-2.572,2.422,30.694,30.694,0,0,1,2.434-2.564A31.373,31.373,0,0,1,304.556,296.08Z"
          transform="translate(25.166 593.463)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76496"
          data-name="Path 76496"
          d="M222.336,284.57a31.03,31.03,0,0,1-2.434,2.56,30.657,30.657,0,0,1-2.572,2.418,31.033,31.033,0,0,1,2.434-2.56A30.65,30.65,0,0,1,222.336,284.57Z"
          transform="translate(75.676 600.556)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76497"
          data-name="Path 76497"
          d="M277.272,296.28a31.351,31.351,0,0,1-2.43,2.56,30.562,30.562,0,0,1-2.572,2.418,32.075,32.075,0,0,1,2.43-2.56A31.368,31.368,0,0,1,277.272,296.28Z"
          transform="translate(41.925 593.339)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76498"
          data-name="Path 76498"
          d="M277.1,284.76a31.026,31.026,0,0,1-2.434,2.56,31.373,31.373,0,0,1-2.572,2.418,31.346,31.346,0,0,1,2.43-2.56A31.024,31.024,0,0,1,277.1,284.76Z"
          transform="translate(42.035 600.438)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76499"
          data-name="Path 76499"
          d="M275.52,276a29.3,29.3,0,0,1-3.44,3.423A30.112,30.112,0,0,1,275.52,276Z"
          transform="translate(42.041 605.836)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41682"
          data-name="Group 41682"
          transform="translate(356.287 889.543)"
          opacity="0.6"
        >
          <path
            id="Path_76500"
            data-name="Path 76500"
            d="M386.412,296.08a62.793,62.793,0,0,1-5,4.981,31.006,31.006,0,0,1,2.43-2.564A31.389,31.389,0,0,1,386.412,296.08Z"
            transform="translate(-381.41 -296.08)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_41683"
          data-name="Group 41683"
          transform="translate(356.217 885.121)"
          opacity="0.6"
        >
          <path
            id="Path_76501"
            data-name="Path 76501"
            d="M386.232,284.56a30.561,30.561,0,0,1-2.43,2.56,31.362,31.362,0,0,1-2.572,2.418,31.341,31.341,0,0,1,2.43-2.56A32.125,32.125,0,0,1,386.232,284.56Z"
            transform="translate(-381.23 -284.56)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_41684"
          data-name="Group 41684"
          transform="translate(356.217 880.181)"
          opacity="0.6"
        >
          <path
            id="Path_76502"
            data-name="Path 76502"
            d="M386.232,271.7a30.546,30.546,0,0,1-2.43,2.56,31.368,31.368,0,0,1-2.572,2.418,61.9,61.9,0,0,1,5.014-4.989Z"
            transform="translate(-381.23 -271.69)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_41685"
          data-name="Group 41685"
          transform="translate(356.287 875.427)"
          opacity="0.6"
        >
          <path
            id="Path_76503"
            data-name="Path 76503"
            d="M386.412,259.3a61.89,61.89,0,0,1-5,4.977,31.335,31.335,0,0,1,2.43-2.56A30.573,30.573,0,0,1,386.412,259.3Z"
            transform="translate(-381.41 -259.3)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_41686"
          data-name="Group 41686"
          transform="translate(356.217 871.003)"
          opacity="0.6"
        >
          <path
            id="Path_76504"
            data-name="Path 76504"
            d="M386.232,247.77a30.553,30.553,0,0,1-2.43,2.56,31.057,31.057,0,0,1-2.572,2.422,30.624,30.624,0,0,1,2.43-2.56A31.8,31.8,0,0,1,386.232,247.77Z"
            transform="translate(-381.23 -247.77)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_41687"
          data-name="Group 41687"
          transform="translate(356.217 866.076)"
          opacity="0.6"
        >
          <path
            id="Path_76505"
            data-name="Path 76505"
            d="M386.232,234.93a61.893,61.893,0,0,1-5,4.977,61.873,61.873,0,0,1,5-4.977Z"
            transform="translate(-381.23 -234.93)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_76506"
          data-name="Path 76506"
          d="M413.926,296.068a63.717,63.717,0,0,1-5.006,4.981,60.367,60.367,0,0,1,5.014-4.989Z"
          transform="translate(-42.023 593.475)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76507"
          data-name="Path 76507"
          d="M413.752,284.56a31.346,31.346,0,0,1-2.43,2.56,32.115,32.115,0,0,1-2.572,2.418,30.542,30.542,0,0,1,2.43-2.56,31.352,31.352,0,0,1,2.572-2.418Z"
          transform="translate(-41.919 600.562)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76508"
          data-name="Path 76508"
          d="M413.752,271.7a31.341,31.341,0,0,1-2.43,2.56,32.1,32.1,0,0,1-2.572,2.418,61.886,61.886,0,0,1,5.014-4.989Z"
          transform="translate(-41.919 608.492)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76509"
          data-name="Path 76509"
          d="M413.926,259.3a31.026,31.026,0,0,1-2.434,2.56,31.373,31.373,0,0,1-2.572,2.418,31.326,31.326,0,0,1,2.43-2.56A30.247,30.247,0,0,1,413.926,259.3Z"
          transform="translate(-42.023 616.128)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76510"
          data-name="Path 76510"
          d="M413.752,247.77a31.346,31.346,0,0,1-2.43,2.56,31.774,31.774,0,0,1-2.572,2.422,29.867,29.867,0,0,1,2.43-2.56A31.044,31.044,0,0,1,413.752,247.77Z"
          transform="translate(-41.919 623.233)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76511"
          data-name="Path 76511"
          d="M468.552,296.08a62.806,62.806,0,0,1-5,4.981,30.987,30.987,0,0,1,2.43-2.564A31.368,31.368,0,0,1,468.552,296.08Z"
          transform="translate(-75.584 593.463)"
          fill="#263238"
        />
        <path
          id="Path_76512"
          data-name="Path 76512"
          d="M468.376,284.56a31.031,31.031,0,0,1-2.434,2.56,31.378,31.378,0,0,1-2.572,2.418,31.031,31.031,0,0,1,2.434-2.56A30.645,30.645,0,0,1,468.376,284.56Z"
          transform="translate(-75.473 600.562)"
          fill="#263238"
        />
        <path
          id="Path_76513"
          data-name="Path 76513"
          d="M468.376,271.72a31.031,31.031,0,0,1-2.434,2.56,31.378,31.378,0,0,1-2.572,2.418,31.031,31.031,0,0,1,2.434-2.56A29.9,29.9,0,0,1,468.376,271.72Z"
          transform="translate(-75.473 608.473)"
          fill="#263238"
        />
        <path
          id="Path_76514"
          data-name="Path 76514"
          d="M468.552,259.3a61.89,61.89,0,0,1-5,4.977,31.33,31.33,0,0,1,2.43-2.56A30.568,30.568,0,0,1,468.552,259.3Z"
          transform="translate(-75.584 616.128)"
          fill="#263238"
        />
        <path
          id="Path_76515"
          data-name="Path 76515"
          d="M468.376,247.77a63.723,63.723,0,0,1-5.006,4.981,63.7,63.7,0,0,1,5.006-4.981Z"
          transform="translate(-75.474 623.233)"
          fill="#263238"
        />
        <path
          id="Path_76516"
          data-name="Path 76516"
          d="M468.376,236.78a31.026,31.026,0,0,1-2.434,2.56,31.373,31.373,0,0,1-2.572,2.418,31.025,31.025,0,0,1,2.434-2.56A30.655,30.655,0,0,1,468.376,236.78Z"
          transform="translate(-75.473 630.005)"
          fill="#263238"
        />
        <path
          id="Path_76517"
          data-name="Path 76517"
          d="M358.352,296.08a62.793,62.793,0,0,1-5,4.981,30.992,30.992,0,0,1,2.43-2.564A31.363,31.363,0,0,1,358.352,296.08Z"
          transform="translate(-7.885 593.463)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76518"
          data-name="Path 76518"
          d="M358.176,284.56a31.03,31.03,0,0,1-2.434,2.56,30.65,30.65,0,0,1-2.572,2.418,31.03,31.03,0,0,1,2.434-2.56A30.645,30.645,0,0,1,358.176,284.56Z"
          transform="translate(-7.774 600.562)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76519"
          data-name="Path 76519"
          d="M358.176,271.72a31.026,31.026,0,0,1-2.434,2.56,30.649,30.649,0,0,1-2.572,2.418,31.031,31.031,0,0,1,2.434-2.56,29.89,29.89,0,0,1,2.572-2.418Z"
          transform="translate(-7.774 608.473)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76520"
          data-name="Path 76520"
          d="M331.946,296.08a31.356,31.356,0,0,1-2.43,2.56,30.706,30.706,0,0,1-2.576,2.422,30.685,30.685,0,0,1,2.434-2.564A31.358,31.358,0,0,1,331.946,296.08Z"
          transform="translate(8.339 593.463)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76521"
          data-name="Path 76521"
          d="M331.772,284.542a61.9,61.9,0,0,1-5,4.977,61.886,61.886,0,0,1,5.014-4.989Z"
          transform="translate(8.444 600.579)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76522"
          data-name="Path 76522"
          d="M330.175,275.86a14.954,14.954,0,0,1-1.631,1.765,14.578,14.578,0,0,1-1.774,1.623,28.714,28.714,0,0,1,3.405-3.389Z"
          transform="translate(8.444 605.922)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76523"
          data-name="Path 76523"
          d="M358.34,259.3a61.3,61.3,0,0,1-5.01,4.989,31.345,31.345,0,0,1,2.43-2.56,30.575,30.575,0,0,1,2.58-2.429Z"
          transform="translate(-7.873 616.128)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76524"
          data-name="Path 76524"
          d="M358.352,236.2a62.786,62.786,0,0,1-5,4.981,30.991,30.991,0,0,1,2.43-2.564A31.358,31.358,0,0,1,358.352,236.2Z"
          transform="translate(-7.885 630.362)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76525"
          data-name="Path 76525"
          d="M358.176,247.77a31.028,31.028,0,0,1-2.434,2.56,30.347,30.347,0,0,1-2.572,2.422,63.71,63.71,0,0,1,5.006-4.981Z"
          transform="translate(-7.774 623.233)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76526"
          data-name="Path 76526"
          d="M442.032,296.08a30.546,30.546,0,0,1-2.43,2.56,31.052,31.052,0,0,1-2.572,2.422,30.986,30.986,0,0,1,2.43-2.564,32.115,32.115,0,0,1,2.572-2.418Z"
          transform="translate(-59.292 593.463)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76527"
          data-name="Path 76527"
          d="M441.892,284.56a31.351,31.351,0,0,1-2.43,2.56,32.114,32.114,0,0,1-2.572,2.418,29.786,29.786,0,0,1,2.43-2.56,31.379,31.379,0,0,1,2.572-2.418Z"
          transform="translate(-59.207 600.562)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76528"
          data-name="Path 76528"
          d="M441.892,271.72a31.341,31.341,0,0,1-2.43,2.56,32.115,32.115,0,0,1-2.572,2.418,29.8,29.8,0,0,1,2.43-2.56A30.582,30.582,0,0,1,441.892,271.72Z"
          transform="translate(-59.207 608.473)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76529"
          data-name="Path 76529"
          d="M442.032,259.3a30.561,30.561,0,0,1-2.43,2.56,31.357,31.357,0,0,1-2.572,2.418,61.877,61.877,0,0,1,5-4.977Z"
          transform="translate(-59.292 616.128)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76530"
          data-name="Path 76530"
          d="M441.892,247.77a31.344,31.344,0,0,1-2.43,2.56,31.788,31.788,0,0,1-2.572,2.422,29.151,29.151,0,0,1,2.43-2.56A31.05,31.05,0,0,1,441.892,247.77Z"
          transform="translate(-59.207 623.233)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76531"
          data-name="Path 76531"
          d="M441.892,234.93a61.886,61.886,0,0,1-5,4.977,30.478,30.478,0,0,1,2.43-2.56,31.378,31.378,0,0,1,2.572-2.418Z"
          transform="translate(-59.207 631.144)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76532"
          data-name="Path 76532"
          d="M441.892,223.26a31.343,31.343,0,0,1-2.43,2.56,32.12,32.12,0,0,1-2.572,2.418,29.793,29.793,0,0,1,2.43-2.56,31.371,31.371,0,0,1,2.572-2.418Z"
          transform="translate(-59.207 638.336)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76533"
          data-name="Path 76533"
          d="M441.036,213.81a42.534,42.534,0,0,1-4.146,4.125,42.525,42.525,0,0,1,4.146-4.125Z"
          transform="translate(-59.207 644.159)"
          fill={currentContext?.theme?.color}
        />
        <g
          id="Group_41688"
          data-name="Group 41688"
          transform="translate(356.287 861.066)"
          opacity="0.6"
        >
          <path
            id="Path_76534"
            data-name="Path 76534"
            d="M386.412,221.88a30.626,30.626,0,0,1-2.43,2.56,31.059,31.059,0,0,1-2.572,2.422,62.762,62.762,0,0,1,5-4.981Z"
            transform="translate(-381.41 -221.88)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_41689"
          data-name="Group 41689"
          transform="translate(356.217 856.646)"
          opacity="0.6"
        >
          <path
            id="Path_76535"
            data-name="Path 76535"
            d="M386.232,210.36a61.9,61.9,0,0,1-5,4.977,61.88,61.88,0,0,1,5-4.977Z"
            transform="translate(-381.23 -210.36)"
            fill="#fff"
          />
        </g>
        <g
          id="Group_41690"
          data-name="Group 41690"
          transform="translate(356.222 853.718)"
          opacity="0.6"
        >
          <path
            id="Path_76536"
            data-name="Path 76536"
            d="M384.233,202.73a11.031,11.031,0,0,1-1.427,1.562,11.252,11.252,0,0,1-1.566,1.416,11.156,11.156,0,0,1,1.427-1.558A11.45,11.45,0,0,1,384.233,202.73Z"
            transform="translate(-381.24 -202.73)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_76537"
          data-name="Path 76537"
          d="M497.806,296.538a63.725,63.725,0,0,1-5.006,4.981,63.714,63.714,0,0,1,5.014-4.989Z"
          transform="translate(-93.554 593.184)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76538"
          data-name="Path 76538"
          d="M497.632,285a31.35,31.35,0,0,1-2.43,2.56,30.583,30.583,0,0,1-2.572,2.418,32.108,32.108,0,0,1,2.43-2.56A31.363,31.363,0,0,1,497.632,285Z"
          transform="translate(-93.449 600.289)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76539"
          data-name="Path 76539"
          d="M497.605,272.26a31.432,31.432,0,0,1-2.418,2.544,30.655,30.655,0,0,1-2.557,2.406A61.221,61.221,0,0,1,497.605,272.26Z"
          transform="translate(-93.449 608.141)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76540"
          data-name="Path 76540"
          d="M527.452,296.55a29.865,29.865,0,0,1-2.43,2.56,31.066,31.066,0,0,1-2.572,2.422,31.335,31.335,0,0,1,2.43-2.56A31.783,31.783,0,0,1,527.452,296.55Z"
          transform="translate(-111.768 593.173)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76541"
          data-name="Path 76541"
          d="M527.282,284.982a61.9,61.9,0,0,1-5,4.977,61.871,61.871,0,0,1,5.014-4.989Z"
          transform="translate(-111.664 600.308)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76542"
          data-name="Path 76542"
          d="M523.9,281.045c.019.019-.054.127-.185.284-.065.077-.147.169-.239.269a1.861,1.861,0,0,1-.147.15l-.077.081-.039.042-.023.019h0l-.143-.138h0l.143.142h0l-.023.019-.039.042-.081.077-.154.146-.27.238c-.158.13-.262.2-.282.18s.05-.127.181-.28c.066-.081.147-.173.239-.269l.147-.154.077-.081.039-.042.023-.019h0l.143.142h0c-.281-.28-.077-.077-.143-.138h0v-.019l.039-.038.081-.081.154-.142.27-.238C523.776,281.1,523.88,281.026,523.9,281.045Z"
          transform="translate(-111.701 602.729)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76543"
          data-name="Path 76543"
          d="M554.912,296.55a30.609,30.609,0,0,1-2.43,2.56,31.8,31.8,0,0,1-2.572,2.421,62.791,62.791,0,0,1,5-4.981Z"
          transform="translate(-128.638 593.173)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76544"
          data-name="Path 76544"
          d="M552.117,291.74a7.456,7.456,0,0,1-1.157,1.27,7.208,7.208,0,0,1-1.28,1.132,7.449,7.449,0,0,1,1.138-1.27A7.02,7.02,0,0,1,552.117,291.74Z"
          transform="translate(-128.497 596.138)"
          fill={currentContext?.theme?.color}
        />
        <path
          id="Path_76545"
          data-name="Path 76545"
          d="M342.031,108.52c0,.058-38.253.1-85.435.1s-85.446-.042-85.446-.1,38.25-.1,85.446-.1S342.031,108.466,342.031,108.52Z"
          transform="translate(104.275 711.106)"
          fill={currentContext?.theme?.color}
          stroke={currentContext?.theme?.color}
          strokeWidth="1"
        />
        <rect
          id="Rectangle_15776"
          data-name="Rectangle 15776"
          width="35.894"
          height="11.946"
          rx="4.64"
          transform="translate(300.568 822.933)"
          fill="#d7d7d7"
        />
        <g
          id="Group_41692"
          data-name="Group 41692"
          transform="translate(342.996 822.933)"
        >
          <rect
            id="Rectangle_15778"
            data-name="Rectangle 15778"
            width="35.894"
            height="11.946"
            rx="3.41"
            fill="#d7d7d7"
          />
        </g>
        <rect
          id="Rectangle_15779"
          data-name="Rectangle 15779"
          width="35.894"
          height="11.946"
          rx="3.64"
          transform="translate(385.422 822.933)"
          fill="#d7d7d7"
        />
        <ellipse
          id="Ellipse_2519"
          data-name="Ellipse 2519"
          cx="1.034"
          cy="1.028"
          rx="1.034"
          ry="1.028"
          transform="translate(283.581 811.734)"
          fill="#d7d7d7"
        />
        <ellipse
          id="Ellipse_2520"
          data-name="Ellipse 2520"
          cx="1.034"
          cy="1.028"
          rx="1.034"
          ry="1.028"
          transform="translate(287.178 811.734)"
          fill="#d7d7d7"
        />
        <ellipse
          id="Ellipse_2521"
          data-name="Ellipse 2521"
          cx="1.034"
          cy="1.028"
          rx="1.034"
          ry="1.028"
          transform="translate(290.777 811.734)"
          fill="#d7d7d7"
        />
        <path
          id="Path_76594"
          data-name="Path 76594"
          d="M551,28l-.019-.008.22-.76.019.008Z"
          transform="translate(-129.295 759.138)"
          fill="#263238"
        />
        <g
          id="Group_41662"
          data-name="Group 41662"
          transform="translate(244.141 891.719)"
        >
          <rect
            id="Rectangle_15701"
            data-name="Rectangle 15701"
            width="40.161"
            height="40.156"
            rx="4.71"
            transform="translate(0 0)"
            fill={currentContext?.theme?.color}
          />
          <ellipse
            id="Ellipse_2518"
            data-name="Ellipse 2518"
            cx="13.166"
            cy="13.168"
            rx="13.166"
            ry="13.168"
            transform="translate(4.981 30.966) rotate(-80.78)"
            fill="#fff"
            opacity="0.25"
          />
          <path
            id="Path_76214"
            data-name="Path 76214"
            d="M281.775,113.541,294.731,116a13.167,13.167,0,1,1-12.955-15.627Z"
            transform="translate(-261.705 -93.463)"
            fill="#fff"
            opacity="0.25"
          />
          <path
            id="Path_76215"
            data-name="Path 76215"
            d="M281.77,113.539V126.7a13.17,13.17,0,1,1,.036-26.34Z"
            transform="translate(-261.699 -93.461)"
            fill="#fff"
            opacity="0.25"
          />
          <path
            id="Path_76216"
            data-name="Path 76216"
            d="M287.549,113.536l-6.939-11.192a12.957,12.957,0,0,1,3.311-1.466,13.122,13.122,0,0,1,3.586-.518Z"
            transform="translate(-267.478 -93.459)"
            fill="#fff"
            opacity="0.25"
          />
        </g>
      </g>
    </svg>
  );
}

export default EmptyTab;
