import './InlineEdit.sass';

import React, { useState, useEffect } from 'react'
import { MdCheck, MdOutlineClose } from 'react-icons/md';
import InputBox from 'src/ui/InputBox/InputBox';
import Loading from 'src/ui/Loading/Loading';

function InlineEdit({ defaultValue, editView, displayView, onChange, onConfirm, onCancel, noActionButtons }) {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    const newValue = typeof e === 'object' ? e.target.value : e;
    setValue(newValue);
    onChange?.(newValue);
  }

  const enableEditMode = () => {
    if (!editMode) {
      setEditMode(true);
    }
  }

  const defaultEditView = ({ errorMessage, ...fieldProps }) => (
    <InputBox {...fieldProps} status={errorMessage && { alert: true, type: 'error', message: errorMessage }} />
  )

  const getEditView = () => {
    if (typeof editView === 'function') return editView;
    else return defaultEditView;
  }

  const getEditViewValues = () => {
    return { value, errorMessage, onChange: handleChange };
  }

  const getDisplayView = () => {
    if (typeof displayView === 'function') return displayView;
    else return (lastValue) => lastValue;
  }

  const getDisplayViewValues = () => {
    return { value, enableEditMode };
  }

  const confirmChanges = async () => {
    if (onConfirm) {
      try {
        setIsLoading(true);
        const result = await onConfirm(value);
        if (result === undefined || result === true) {
          setEditMode(false);
          setErrorMessage(undefined);
        }
        else setErrorMessage(result);
      }
      catch (e) {
        setErrorMessage(e.message || "An error occurred");
      }
      finally {
        setIsLoading(false);
      }
    }
    else {
      setEditMode(false);
      setErrorMessage(undefined);
    }
  }

  const cancelChanges = () => {
    setEditMode(false);
    setValue(defaultValue);
    onCancel?.();
  }

  return (
    <div className="inline-edit" onClick={enableEditMode}>
      {editMode ? getEditView()(getEditViewValues()) : getDisplayView()(getDisplayViewValues())}

      {editMode && !noActionButtons && (
        <div className="inline-edit-actions">
          <div className="item action-cancel" onClick={cancelChanges}>
            <MdOutlineClose />
          </div>
          <div className="item action-confirm" onClick={confirmChanges}>
            <MdCheck />
          </div>
        </div>
      )}

      {isLoading && <Loading />}
    </div>
  )
}

export default InlineEdit