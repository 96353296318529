import "./SignalStrength.sass";

function SignalStrength({ size, total, strength }) {
  return (
    <svg
      width={`${size}em`}
      height={`${size}em`}
      viewBox="0 0 71 60"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="signal"
          transform="translate(5.000000, 5.000000)"
          strokeWidth="10"
        >
          {total.map((item, index) => (
            <path
              key={index}
              d={`M${15 * index},${40 - 10 * index} L${15 * index},50`}
              id={`s${item}`}
              stroke={
                strength > index
                  ? strength > 2
                    ? "#FFFFFF"
                    : "#FF0000"
                  : "#979797"
              }
            ></path>
          ))}
          {/* <path d="M60.0,0 L60.0,50" id="s4" stroke="#979797"></path>
          <path d="M45.0,10 L45.0,50" id="s3" stroke="#979797"></path>
          <path d="M30.0,20 L30.0,50" id="s2" stroke="#979797"></path>
          <path d="M15.0,30 L15.0,50" id="s1" stroke="#FFFFFF"></path>
          <path d="M0.0,40 L0.0,50" id="s0" stroke="#FFFFFF"></path> */}
        </g>
      </g>
    </svg>
  );
}
SignalStrength.defaultProps = {
  total: [],
};

export default SignalStrength;
