import { createSlice } from "@reduxjs/toolkit";

const stepsSlice = createSlice({
  name: "context",
  initialState: { theme: { color: "#2699fb", name: "dashboard" } },
  reducers: {
    setContext(state, action) {
      const selectData = action.payload;
      return { ...state, ...selectData };
    },
  },
});
export const { getContext, setContext } = stepsSlice.actions;
export default stepsSlice.reducer;
