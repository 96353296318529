import classNames from 'classnames'
import './Loading.sass'
import { OrbitProgress } from 'react-loading-indicators'

function Loading({ message, withBackground = true }) {
  return (
    <div className={classNames("loading-2", {'with-background': withBackground})}>
      <div className="content">
        <OrbitProgress size="small" color="#fff" text="" textColor="" />
        {message ? <div className="message">{message}</div> : null}
      </div>
    </div>
  )
}

export default Loading