import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../../../../ui/Form/Form";
import KeyValueForm from "../../../../../ui/KeyValueForm/KeyValueForm";
import { update, updateCustomer } from "../CustomerManagementSlice";
import "./CustomerDetails.sass";

function CustomerDetails({onConfirm, onOpen }) {
  const table = useSelector((state) => state.table);
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);

  useEffect(() => {
   if (customer.update.status === "success") {
      onConfirm();
    }
  }, [customer.update]);

  useEffect(() => {
    if (table.row.details !== undefined) {
      setDetails(
        table.row.details
          .map((item) => ({
            [item.key]: item.value,
          }))
          .reduce(function (result, current) {
            return Object.assign(result, current);
          }, {})
      );
    }
  }, [table?.row?.details]);

  const onKeyValueChange = (data) => {
    setDetails(data);
  };
  const submitDetails = (event) => {
    event.preventDefault();
    dispatch(update({ status: "" }));
    dispatch(updateCustomer({ ...table.row,details:Object.entries(details).map((item) => ({key: item[0],value: item[1] })) }, table.row.id));
  };
  useEffect(() => {
    onOpen?.();
  }, [details]);
  
  return (
    <div className="customer-details-form">
      <Form
        className="customer-details-form-container"
        data={details}
        required={[]}
        submit={{
          title: "Confirm",
          color: "#2699fb",
          width: "35%",
          action: submitDetails,
        }}
        validate={Object.entries(details).length > 0}
      >
        <KeyValueForm onDataChange={onKeyValueChange} details={details} />
      </Form>
    </div>
  );
}

export default CustomerDetails;
