import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Button/Button";
import GroupMemberItem from "../../GroupMemberItem/GroupMemberItem";
import SearchableList from "../../SearchableList/SearchableList";
import SVGAvatar from "../../SVGAvatar/SVGAvatar";
import {
  search,
  optionalSearch,
  assignMemberships,
  currentGroup,
  assignMembers,
} from "../GroupAppSlice";
import "./GroupMembershipApp.sass";
import { useTheme } from "../../../helper/ThemeProvider";
import Avatar from "src/ui/Avatar/Avatar";

function GroupMembershipApp({
  creationMode,
  title,
  onComplete,
  onMemberChange,
}) {
  const groupApp = useSelector((state) => state.groupApp);
  const { currentContext } = useTheme();
  const [selected, setSelected] = useState([]);
  const [groupMembers, setGroupMembers] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (onMemberChange) onMemberChange(groupMembers);
  }, [groupMembers]);

  useEffect(() => {
    if (groupApp?.currentGroup?.members !== undefined && !creationMode) {
      setSelected([
        ...groupApp.currentGroup?.members?.map((item) => ({
          id: item.userId,
          title: item.displayName,
          subtitle: item.emailAddress,
          owner: false,
          member: true,
          icon: item.avatar ? (
            <Avatar
              image={item.avatar}
              isCovered={true}
              width="32px"
              height="32px"
            />
          ) : (
            <SVGAvatar />
          ),
        })),
      ]);
    }
  }, [groupApp.currentGroup, creationMode]);

  useEffect(() => {
    if (groupApp?.search?.status === "success")
      setData([
        ...groupApp?.search?.data.users
          .filter((u) => !selected.map((d) => d.id).includes(u.id))
          .map((item) => ({
            id: item.id,
            title: item.displayName,
            subtitle: item.emailAddress,
            active: false,
          })),
      ]);
  }, [groupApp?.search]);

  useEffect(() => {
    return () => {
      setData([]);
      setGroupMembers([]);
      setSelected([]);
      dispatch(search({ status: "" }));
    };
  }, []);

  const onSelectChange = (item) => {
    if (!selected.map((x) => x.id).includes(item.id))
      setSelected([
        ...selected.filter((x) => x.id !== item.id),
        { ...item, owner: false, member: true },
      ]);
    else setSelected([...selected.filter((x) => x.id !== item.id)]);

    setData([
      ...data.map((s) =>
        s.id !== item.id ? { ...s } : { ...s, active: !s.active }
      ),
    ]);
  };

  const typeChangeHandler = (item) => {
    switch (item.type) {
      case "Remove":
        setSelected([...selected.filter((x) => x.id !== item.member.id)]);
        // setData([...data.filter((x) => x.id !== item.member.id), item.member]);
        setData([
          ...data.map((s) =>
            s.id !== item.member.id ? { ...s } : { ...s, active: false }
          ),
        ]);
        break;
      case "Member":
        setSelected([
          ...selected.map((s) =>
            s.id !== item.member.id
              ? { ...s }
              : { ...s, owner: false, member: true }
          ),
        ]);
        break;
      case "Owner":
        setSelected([
          ...selected.map((s) =>
            s.id !== item.member.id
              ? { ...s }
              : { ...s, member: false, owner: true }
          ),
        ]);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setGroupMembers(selected.filter((s) => s.member === true).map((z) => z.id));
  }, [selected]);

  useEffect(() => {
    if (groupApp.assignMembers.status === "success") {
      dispatch(
        currentGroup({
          ...groupApp.currentGroup,
          members: groupApp?.assignMembers?.data?.members,
        })
      );
      dispatch(assignMembers({ status: "" }));
      if (onComplete) onComplete();
    }
  }, [groupApp.assignMembers]);

  const submitHandler = () => {
    if (groupApp.currentGroup?.id !== undefined) {
      dispatch(
        assignMemberships({ members: groupMembers }, groupApp.currentGroup?.id)
      );
      setData([]);
      dispatch(search({ status: "" }));
    }
  };

  return (
    <div className="group-membership-app">
      {(groupApp.currentGroup?.isOwner || creationMode) && (
        <SearchableList
          onDataChange={onSelectChange}
          minLength={1}
          tag="groupApp/search"
          feed={data}
          title={title ? title : "Search for user(s)"}
          searchCaption="Selected user(s)"
          searchHandler={optionalSearch}
          moreOptions={true}
        />
      )}

      <p>
        Members{" "}
        <span style={{ color: currentContext.theme.color }}>
          (total {groupMembers.length} member selected)
        </span>
      </p>
      <div className="group-membership-items-container">
        <ul className="group-membership-items">
          {selected
            .filter((s) => s.member === true)
            .map((item, index) => (
              <li className="group-membership-item" key={index}>
                <GroupMemberItem
                  member={item}
                  types={["Member", "Remove"]}
                  onTypeChange={typeChangeHandler}
                  defaultMembership="Member"
                  actionable={groupApp.currentGroup?.isOwner}
                />
              </li>
            ))}
        </ul>
      </div>
      {groupApp.currentGroup?.isOwner && !creationMode && (
        <Button
          value="Confirm"
          color="#2699fb"
          width="30%"
          onClick={submitHandler}
          className="confirm-button"
        />
      )}
    </div>
  );
}

export default GroupMembershipApp;
