import React, {useState, useRef, useMemo, useEffect} from 'react'
import imageCompression from 'browser-image-compression'
import './FileInput.sass'

const IMAGE_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];

function FileInput({children, name,  onChange, onError, className, multiple, accept, imageOptions, ...props}) {
  const [selectedFiles, setSelectedFiles] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const nativeInputRef = useRef();

  const mimeTypesString = useMemo(() => {
    return accept ? accept.join(',') : ''
  }, [accept]);

  const openFileDialog = () => {
    setErrorMessage(null);
    nativeInputRef.current.value = "";
    nativeInputRef.current.click();
  }

  const applyImageOptions = async (file) => {
    const options = {
      maxWidthOrHeight: imageOptions.maxDimension,
      initialQuality: imageOptions.quality || 1,
      maxSizeMB: imageOptions.maxFileSize || Number.POSITIVE_INFINITY,
    };

    const optimizedFileBlob = await imageCompression(file, options);
    return new File([optimizedFileBlob], file.name, {lastModified: file.lastModified, type: file.type});
  }

  const handleOnNativeInputChange = (e) => {
    const files = e.target.files.length ? Array.from(e.target.files) : [];
    if(!files.length) return;
    let fileTypesValid = true;
    
    files.forEach(item => {
      if(accept && !accept.includes(item.type)){
        setErrorMessage('Wrong file type selected, file type should be one of these: ' + mimeTypesString);
        fileTypesValid = false;
      }
    });

    if(!fileTypesValid) return;
    handleSelectedFilesChange(files);
  }

  const handleSelectedFilesChange = async (files) => {
    for(let [index, item] of files.entries()) {
      if(imageOptions && IMAGE_TYPES.includes(item.type)) {
        files[index] = await applyImageOptions(item);
      }
    }

    setSelectedFiles('files', files);
    onChange?.(files);
  }

  useEffect(() => {
    if(errorMessage)
      onError?.(errorMessage);
  }, [errorMessage]);

  return (
    <div className={"file-input " + (className || "")} {...props}>
      {children?.({
        openFileDialog, 
        selectedFiles,
        errorMessage
      })}

      <input 
        type="file" 
        name={name}
        className="native-input" 
        ref={nativeInputRef} 
        onChange={handleOnNativeInputChange}
        accept={mimeTypesString}
        multiple={multiple}
      />
    </div>
  )
}

export default FileInput