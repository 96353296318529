import useServices from './useServices'

export default function ServiceRunner() {
  useServices();

  // We use this component as a container for 
  // service hooks so internal state changes of them
  // doesn't trigger re-render of the parent component
  return null;
}
