import { useEffect, useState } from "react";
import "./VerificationCodeForm.sass";
import CountdownTimer from "src/ui/CountdownTimer/CountdownTimer";
import Button from "src/ui/Button/Button";
import LabeledButton from "src/ui/LabeledButton/LabeledButton";
import VerificationCode from "src/ui/VerificationCode/VerificationCode";
import LinkButton from "src/ui/LinkButton/LinkButton";
import { useDispatch, useSelector } from "react-redux";
import { setOperation } from "src/components/AdminCenter/AdminOperationManager/OperationStepSlice";
import { getUserProfile } from "src/redux/ducks/accountStateSlice";
import {
  checkVerificationCode,
  sendSMS,
  sendVerification,
  verifySMS,
} from "src/store/UserVerificationSlice";

function VerificationCodeForm({ onComplete }) {
  const [phoneNumber, setPhoneNumber] = useState();
  const [expireTime, setExpireTime] = useState(0);
  const [resetCounter, setResetCounter] = useState(false);
  const [lastCodeExpired, setLastCodeExpired] = useState(false);
  const sms = useSelector((state) => state.verification.sendSMS);
  const verify = useSelector((state) => state.verification.verifySMS);
  const verification = useSelector((state) => state.verificationCode);
  const dispatch = useDispatch();
  const [status, setStatus] = useState({
    alert: false,
    type: "error",
    message: "mobile not found",
  });

  useEffect(() => {
    if (sms.status === "success") {
      setPhoneNumber(sms?.data?.mobilePhone);
      setExpireTime(sms?.data?.expirationTime);
      setLastCodeExpired(false);
      setResetCounter((currentValue) => {
        return !currentValue;
      });
    }
  }, [sms]);

  const tryAnotherCode = () => {
    setResetCounter((currentValue) => {
      return !currentValue;
    });
    setStatus({ ...status, alert: false });
    dispatch(sendVerification({ mobilePhone: phoneNumber }));
  };

  const expireTimer = () => {
    setLastCodeExpired(true);
  };

  const submit = () => {
    setStatus({ ...status, alert: false });
    dispatch(
      checkVerificationCode({
        verificationCode: verification.code,
        mobilePhone: phoneNumber,
      })
    );
  };

  const cancel = () => {
    dispatch(sendSMS({ status: "" }));
    dispatch(setOperation("enter_number"));
  };

  useEffect(() => {
    if (verify.status === "failed") {
      setStatus({
        ...status,
        alert: verify.status === "failed",
        message: verify.message,
      });
    } else if (verify.status === "success") {
      dispatch(getUserProfile());
      dispatch(sendSMS({ status: "" }));
      dispatch(verifySMS({ status: "" }));
      onComplete();
    }
  }, [verify]);

  return (
    <div className="verification-code-form">
      <div className="verification-code-form-description">
        Please enter the verification code.
      </div>
      <div>
        <VerificationCode total={6} status={true} type="numeric" />
      </div>
      <div className="verification-code-form-details">
        <div>
          <span>
            We've sent a code to <strong>{phoneNumber}</strong> which expires
            in:&nbsp;
          </span>
          <CountdownTimer
            seconds={expireTime}
            reset={resetCounter}
            onFinish={expireTimer}
            className="verification-countdown"
          />
        </div>
        <div>
          {lastCodeExpired && (
            <>
              <span>Didn't got the code? </span>
              <LabeledButton
                className="verification-resend-button"
                title="click to resend"
                link={true}
                onClick={tryAnotherCode}
              />
            </>
          )}
        </div>
        <LinkButton onClick={cancel} title="Change Phone Number" />
      </div>

      <Button
        value="Verify"
        width="25%"
        disabled={false}
        className="submit-button"
        onClick={submit}
      />
    </div>
  );
}

export default VerificationCodeForm;
