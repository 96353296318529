import React from 'react'
import './Crosshair.sass'

function  Crosshair({ className }) {
  return (
    <div className={"crosshair " + (className || '')}>
      <div className="content">
        <div className="horizontal-line"></div>
        <div className="vertical-line"></div>
      </div>
    </div>
  )
}

export default  Crosshair