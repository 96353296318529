import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "src/redux/action/api";

const ExploreAppSlice = createSlice({
  name: "exploreApp",
  initialState: {
    selectedEntity: null,
    activeMissionTrackingId: null,
  },
  reducers: {
    setSelectedEntity(state, action) {
      const data = action.payload;
      return { ...state, selectedEntity: data };
    },
    activeMissionTrackingId(state, action) {
      const data = action.payload;
      return { ...state, activeMissionTrackingId: data };
    },
    createMission(state, action) {
      const data = action.payload;
      return { ...state, createMission: { ...data } };
    },
    unitMissionList(state, action) {
      const data = action.payload;
      return { ...state, unitMissionList: data };
    },
  },
});
export const {
  setSelectedEntity,
  activeMissionTrackingId,
  createMission,
  unitMissionList,
} = ExploreAppSlice.actions;
export default ExploreAppSlice.reducer;

export const getUnitMissionList = (unitId) => {
  return apiCallBegan({
    url: `/security-app/units/${unitId}/missions`,
    method: "Get",
    onSuccess: unitMissionList.type,
    onError: unitMissionList.type,
  })};