export const WaypointV2ErrorText = {
    '0x00000': "Success",
    '0x00001': "The length of the data is illegal based on the protocol",
    '0x00002': "Invalid float number (NAN or INF)",
    '0x00003': "Waypoint mission version can't match with firmware",
    '0x0ffff': "Fatal error! Unexpected result!",
    '0x10000': "Reserved for flight-trajectory module errors",
    '0x10001': "min_initial_waypoint_num is larger than permitted_max_waypoint_num",
    '0x10002': "min_initial_waypoint_num is less than permitted_min_waypoint_num",
    '0x10003': "waypoint_end_index is equal to or larger than total_waypoint_num",
    '0x10004': "The start index is greater than end index of uploaded waypoints",
    '0x10005': "The end index of uploaded waypoints is greater than initialized total numbers",
    '0x10006': "The index of first and end waypoint expected to download is not in range of stored in FC",
    '0x10008': "Current position is far away from the first waypoint",
    '0x1000a': "It is too close from two adjacent waypoints",
    '0x1000b': "The distance between two adjacent waypoints is not in [0.5m, 5000m]",
    '0x1000c': "The max velocity of uploaded waypoint is greater than global max velocity",
    '0x1000d': "The local cruise velocity of uploaded waypoint is greater than local max velocity",
    '0x1000e': "The local cruise velocity of uploaded waypoint is greater than global max velocity",
    '0x1000f': "global_max_velocity is greater than permitted_max_velocity or less than permitted_min_velocity",
    '0x10010': "global_cruise_velocity is greater than global_max_velocity",
    '0x10011': "goto_first_point_mode is out of range",
    '0x10012': "finished_action is out of range",
    '0x10013': "rc_lost_action is out of range",
    '0x10014': "The yaw mode of uploaded waypoint is invalid",
    '0x10015': "The yaw command of uploaded waypoint is not in range. The range for MR:[-180, 180], unit: degree",
    '0x10016': "The yaw turn direction of uploaded waypoint is invalid. It should be 0: clockwise or 1: anti-clockwise",
    '0x10017': "The waypoint type of uploaded waypoint is invalid",
    '0x10018': "go/stop command is invalid",
    '0x10019': "The command of pause/recovery is not valid",
    '0x1001a': "The command of break/restore is not valid",
    '0x1001b': "Initial reference point position coordinate exceeds set range",
    '0x1001c': "The damping distance is greater than or equal to the distance of adjacent points",
    '0x1001d': "Cannot set wp_line_exit type to wp",
    '0x1001e': "The initialization information of Ground Station is not uploaded yet",
    '0x1001f': "The waypoint has not been uploaded yet",
    '0x10020': "min_initial_waypoint_num is not uploaded",
    '0x10021': "Waypoint plan has started when receiving go command",
    '0x10022': "Waypoint plan not running when receiving stop command",
    '0x10023': "Ground station (GS) is not started (used by pause/recovery)",
    '0x10024': "Ground station (GS) is not started (used by break/restore)",
    '0x10025': "Not in the waypoint mission (MIS) (cannot pause/recovery or break/restore)",
    '0x10026': "The current status is paused, cannot command pause again",
    '0x10027': "Not in paused status, cannot command recovery",
    '0x10028': "The current status is broken, cannot command break again",
    '0x10029': "Not in break status, cannot command restore",
    '0x1002a': "The configuration forbids using pause/recovery API",
    '0x1002b': "The configuration forbids using break/restore API",
    '0x1002c': "No break point is recorded for restore",
    '0x1002d': "No current trajectory project point is recorded for restore",
    '0x1002e': "No next trajectory project point is recorded for restore",
    '0x1002f': "No next next trajectory project point is recorded for restore",
    '0x10030': "The index of uploaded waypoint is not continuous after the stored waypoint",
    '0x10031': "The WP_LINE_ENTER waypoint type set to a waypoint which is not the initial start waypoint",
    '0x10032': "The waypoint plan has started when initializing waypoint",
    '0x10033': "Waypoint damping distance exceeds set range",
    '0x10034': "Waypoint position coordinate exceeds rational range",
    '0x10035': "First waypoint type error, it cannot be WP_TURN_NO",
    '0x10036': "The uploaded waypoint exceeds height limit",
    '0x10038': "Waypoint position exceeds radius limit",
    '0x10039': "Waypoint position exceeds height limit",
    '0x10040': "The uploaded waypoint exceeds height limit",
    '0x20000': "Reserved for flight-status module errors",
    '0x20001': "head_node is null or atti_not_healthy or gyro_not_healthy or horiz_vel_not healthy or horiz_abs_pos_not_healthy",
    '0x20002': "The home point is not recorded yet",
    '0x20003': "Current location accuracy is low due to bad GPS signal",
    '0x20005': "Use of RTK data is not possible because RTK is not connected or RTK data is invalid",
    '0x30000': "Reserved for flight-secure module errors",
    '0x30001': "The trajectory crosses the NFZ",
    '0x30002': "Current capacity of smart battery or voltage of non-smart battery is lower than level 1 or level 2 threshold",
    '0x400000': "Reserved for action-common module errors",
    '0x400001': "The ID of Action is duplicated",
    '0x400002': "There is not enough memory space for new Action Item",
    '0x400003': "The size of buffer used to get the info of Action is less than the size of Action",
    '0x400004': "The ID of Action is not found",
    '0x400005': "The download action start ID is larger than the action end ID",
    '0x400006': "Cannot download or get min-max action ID for no action items stored in action kernel",
    '0x410000': "Reserved for trigger module errors",
    '0x410001': "The type ID of Trigger is invalid",
    '0x410021': "Waypoint end index is less than waypoint start index in reach_waypoint_trigger",
    '0x410022': "Interval waypoint number is larger than the difference of waypoint start index and waypoint end index in reach_waypoint_trigger",
    '0x410023': "auto_terminate_waypoint_num is larger than interval_waypoint_num in reach_waypoint_trigger",
    '0x410041': "The associate type is greater than the maximum value",
    '0x410081': "The interval type is greater than the maximum value",
    '0x420000': "Reserved for actuator-common module errors",
    '0x420001': "The execution of Actuator is not supported, e.g., trying to stop camera shooting",
    '0x420002': "The type ID of Actuator is invalid",
    '0x420003': "The Function ID of Actuator is invalid",
    '0x430000': "Reserved for action-camera module errors",
    '0x430001': "Fail to send shot command to camera because no camera is present or the camera is busy",
    '0x430002': "Fail to send video start command to camera because no camera is present or the camera is busy",
    '0x430003': "Fail to send video stop command to camera because no camera is present or the camera is not busy",
    '0x430004': "Camera focus parameter XY exceeds valid range (0, 1)",
    '0x430005': "Fail to send focus command to camera because no camera is present or the camera is busy",
    '0x430006': "Fail to send focalize command to camera because no camera is present or the camera is busy",
    '0x430007': "Focal distance of camera focalize function exceeds valid range",
    '0x430100': "Camera fails to execute corresponding command",
    '0x440000': "Reserved for action-gimbal module errors",
    '0x440001': "Gimbal roll/pitch/yaw angle control command parameter is invalid, unable to execute",
    '0x440002': "Gimbal duration parameter is invalid, unable to execute",
    '0x440003': "Gimbal failed to arrive at the target angle",
    '0x440004': "Fail to send command to gimbal because the gimbal is busy or no gimbal is present",
    '0x440005': "Fail to stop gimbal uniform control due to index error",
    '0x460000': "Reserved for action-flight module errors",
    '0x460001': "Yaw angle is larger than the maximum yaw angle",
    '0x460002': "Failed to target yaw angle due to timeout",
    '0x460003': "Yaw occupied by another action",
    '0x460004': "Current and target velocity control status are equal",
    '0x470000': "Reserved for payload module errors",
    '0x470001': "Fail to send command to payload",
    '0x470002': "Payload execution failed",
};

export const WaypointV3ErrorText = {
    '0x0000': "Success",
    '1': "Mission ID not exist",
    '2': "Wayline info error",
    '3': "WPMZ file version not match",
    '4': "WPMZ file load error",
    '5': "No break info",
    '6': "Cmd invalid",
    '255': "Unknown error",
    '257': "Cannot start wayline when wayline running",
    '258': "Cannot break wayline in current state",
    '259': "Cannot stop wayline when wayline not running",
    '260': "Cannot break wayline when wayline not running",
    '261': "Cannot request drone control",
    '262': "Cannot resume wayline in current state",
    '513': "Height limit",
    '514': "Radius limit",
    '515': "Cross flylimit area",
    '516': "Low limit",
    '517': "Obstacal stop",
    '518': "RTK disconnect",
    '519': "Boundary limit",
    '520': "RC pitch roll break",
    '521': "Airport height limit",
    '522': "Request takeoff fail",
    '523': "Autotakeoff run fail",
    '524': "Request wayline fail",
    '525': "Agro plan fail",
    '526': "Request quick takeoff assist fail",
    '527': "Quick takeoff assist run fail",
    '528': "Vfence limit",
    '769': "GPS invalid",
    '770': "Cannot start at current RC mode",
    '771': "Home point not recorded",
    '772': "Lower battery",
    '773': "Return home",
    '774': "ADSB error",
    '775': "RC lost",
    '776': "RTK not ready",
    '777': "Drone is moving",
    '778': "Drone on ground motor on",
    '779': "Surface follow camera invalid",
    '780': "Surface follow height invalid",
    '781': "Surface follow map wrong",
    '782': "Homepoint not match RTK",
    '784': "Strong wind gohome",
    '1023': "Drone critical error",
    '1025': "Cannot find payload",
    '1026': "Action execution failed",
    '1027': "Farm no pesticide",
    '1028': "Radar disconnect",
    '1281': "User exit",
    '1282': "User break",
    '1283': "User set gohome",
    '1284': "User agro planner state change",
    '1285': "User switch RC mode",
    '1536': "Traj init fail",
    '1537': "Traj job exit but mis running",
    '1538': "Traj on ground motor on cannot go",
    '1539': "Traj invalid start index or prog",
    '1540': "Traj invalid csys mode",
    '1541': "Traj invalid height mode",
    '1542': "Traj invalid fly WP mode",
    '1543': "Traj invalid yaw mode",
    '1544': "Traj invalid turn dir mode",
    '1545': "Traj invalid WP type",
    '1546': "Traj fir las WP type error",
    '1547': "Traj glob vel out of range",
    '1548': "Traj WP num out of range",
    '1549': "Traj lat long out of range",
    '1550': "Traj damp dis out of range",
    '1551': "Traj max vel out of range",
    '1552': "Traj vel out of range",
    '1553': "Traj WP yaw out of range",
    '1554': "Traj invalid yaw mode in vert segm",
    '1555': "Traj WP break info mission ID changed",
    '1556': "Traj WP break info progress out of range",
    '1557': "Traj WP break info invalid mission state",
    '1558': "Traj WP break info WP index out of range",
    '1559': "Traj break lat long out of range",
    '1560': "Traj break info WP yaw out of range",
    '1561': "Traj invalid break info flag",
    '1562': "Traj get traj info failed",
    '1563': "Traj generate fail",
    '1564': "Traj lib run fail",
    '1565': "Traj lib emergency brake",
    '1588': "Action common action not found",
    '1591': "Action common action index repeated",
    '1592': "Action common action info size too long or too short",
    '1593': "Action common action tree empty",
    '1594': "Action common action tree layer empty",
    '1595': "Action common action ID repeated",
    '1596': "Action common action node children num lt 2",
    '1597': "Action common action index out of range",
    '1598': "Action common action ID is 65535",
    '1599': "Action common action node children sum not equal next layer size",
    '1600': "Action common action tree layer num too more",
    '1601': "Action common action tree layer num too less",
    '1602': "Action common action group num out of range",
    '1603': "Action common action group valid range error",
    '1604': "Action common action tree root status invalid",
    '1605': "Action common action tree node status invalid",
    '1606': "Action common break info action group ID out of range",
    '1607': "Action common action status tree size error",
    '1608': "Action common break info trigger run result invalid",
    '1609': "Action common break info action group ID repeated",
    '1610': "Action common break info action location repeated",
    '1611': "Action common break info action location out of range",
    '1612': "Action common resume ID not in break info",
    '1613': "Action common resume info modify action status from no interrupt to interrupt",
    '1614': "Action common action resume fail for invalid resume info",
    '1634': "Actuator common actuator not found",
    '1649': "Trigger not found",
    '1650': "Trigger single time check fail"
};
 