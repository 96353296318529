const RadarDrone = ({ className, size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Layer_1" data-name="Layer 1" viewBox="0 0 230 230" width={`${size}em`} height={`${size}em`}>
      <defs>
        <radialGradient
          id="DG9QVyWX"
          cx={-830.19}
          cy={659.04}
          r={0.5}
          gradientTransform="matrix(0 30.14 32.75 0 -21466.48 25134.79)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#bebebe" />
        </radialGradient>
      </defs>
      <path
        d="m130.92 128.61-16.83-5.47-15.92 5.47 15.92-30.14Z"
        style={{
          fill: "url(#DG9QVyWX)",
        }}
      />
    </svg>
  );
};

export default RadarDrone;
