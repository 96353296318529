/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tabletSize, useViewport } from "../../../../helper/ViewPortProvider";
import { CheckAccountState } from "../../../../redux/ducks/accountStateSlice";
import { stepIndex } from "../../../AccountManagement/AccountManagementSlice";
import Button from "../../../../ui/Button/Button";
import CheckBox from "../../../../ui/CheckBox/CheckBox";
import "./Agreement.sass";
import { signAgreement } from "./AgreementSlice";
import { getAgreementContent } from "./AgreementSlice";
const Agreement = () => {
  const [check, setCheck] = useState(false);
  const [content, setContent] = useState("");
  const { width } = useViewport();
  const dispatch = useDispatch();

  const agreement = useSelector((state) => state.agreement);

  useEffect(() => {
    if (agreement.content.status === "") {
      dispatch(getAgreementContent());
    } else if (agreement.content.status === "success") {
      setContent(agreement.content.data.value);
    }
  }, [agreement.content]);

  useEffect(() => {
    if (agreement.sign.status === "success") {
      dispatch(CheckAccountState());
      dispatch(stepIndex(2));
    }
  }, [agreement.sign]);

  const goNext = () => {
    dispatch(signAgreement({ agreementStatus: true }));
  };
  const checkHandler = (event) => {
    setCheck(event.target.checked);
  };
  return (
    <div className="agreement">
      <div
        className="agreement-description"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div>
        <CheckBox text="I Agree" onChecked={checkHandler} />
        <Button
          disabled={!check}
          value="Next"
          width={width < tabletSize ? "40%" : "20%"}
          onClick={goNext}
          color="#2699fb"
        />
      </div>
    </div>
  );
};

export default Agreement;
