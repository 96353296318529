import React, { createContext, useContext, useEffect, useState } from "react";
import { mobileCheck } from "../helper/utils";
const OrientationContext = createContext({});
export const OrientationProvider = ({ children }) => {
  const [landscape, setLandscape] = useState(
    window.orientation === -90 || window.orientation === 90
  );
  setTimeout(() => {
    if (mobileCheck()) {
      doOnOrientationChange();
    }
  }, 250);

  const doOnOrientationChange = () => {
    switch (window.orientation) {
      case -90:
      case 90:
        setLandscape(true);
        break;
      default:
        setLandscape(false);
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("orientationchange", doOnOrientationChange);
    return () =>
      window.removeEventListener("orientationchange", doOnOrientationChange);
  }, []);

  return (
    <OrientationContext.Provider value={{ landscape }}>
      {children}
    </OrientationContext.Provider>
  );
};
export const useLandscape = () => {
  const { landscape } = useContext(OrientationContext);
  return { landscape };
};
