/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Footer.sass";

const Footer = () => {
  return (
    <div className="footer">
      <ul className="footer-content">
        <li>
          <a href="#">Privacy & Cookie</a>
        </li>
        <li>
          <a href="#">Terms of Use</a>
        </li>
        <li>
          <a href="#">@2020 Intuitive Robotics</a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
