import React, { useState } from 'react'
import './UserProfileInformation.sass'
import { FaRegBuilding, FaUserCircle } from 'react-icons/fa'
import { MdGroups, MdOutlineCameraAlt, MdVpnKey } from 'react-icons/md'
import { TbEditCircle } from 'react-icons/tb'
import InlineEdit from 'src/ui/InlineEdit/InlineEdit'
import Avatar from 'src/ui/Avatar/Avatar'
import DesktopSize from 'src/ui/DesktopSize/DesktopSize'
import UserProfilePictureDialog from './UserProfilePictureDialog/UserProfilePictureDialog'
import UserProfileUpdatePhone from './UserProfileUpdatePhone/UserProfileUpdatePhone'
import InputBox from 'src/ui/InputBox/InputBox'
import { useCurrentUser } from "src/helper/AccountStateProvider";
import { useDispatch } from 'react-redux'
import { getUserProfile, updateName } from "src/redux/ducks/accountStateSlice";
import { waitForStore } from 'src/helper/utils'
import Widget from 'src/ui/Widget/Widget'
import { useRouteMatch } from 'react-router-dom'
import { NotificationType } from 'src/components/Notification/NotificationItem/NotificationItem'
import { addNotification } from 'src/components/Notification/NotificationSlice'

function UserProfileInformation() {
  const [isPictureDialogOpen, setIsPictureDialogOpen] = useState(false);
  const [isUpdatePhoneOpen, setIsUpdatePhoneOpen] = useState(false);
  const { currentUser } = useCurrentUser();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  const updateProfileName = async (newName) => {
    let error = false;

    dispatch(
      updateName({
        DisplayName: newName,
      })
    );

    try {
      const result = await waitForStore(
        (state) => state.accountState.changeName,
        (value) => value?.status !== "pending"
      );
      if (result?.status !== "success")
        error = result?.message || "An error occurred during update process.";
      if (result?.status === "success") dispatch(getUserProfile());
    } catch (err) {
      error = err || "An error occurred during update process.";
    }

    if (error) {
      alert(error);
      return false;
    }
  };

  const widgets = [
    {
      title: "Password",
      linkTitle: "Change Password",
      link: `${path}/password`,
      description:
        "For more privacy make your password stronger and change it if someone else knows it.",
      icon: <MdVpnKey size="20" />,
    },
    {
      title: "Groups",
      linkTitle: "Group Information",
      link: `${path}/groups`,
      description:
        "See all groups that you're in owner or member.",
      icon: <MdGroups size="25" />,
    },
    {
      title: "Company",
      linkTitle: "Company Profile",
      link: `${path}/organizations`,
      description:
        "See your company information that you are part of.",
      icon: <FaRegBuilding size="20" />,
    },
  ];

  return (
    <div className="user-profile-information">
      <h2 onDoubleClick={() => {
        const types = Object.keys(NotificationType);

        dispatch(addNotification({
          type: types[Math.floor(Math.random() * types.length)],
          title: 'Machine Initialized',
          message: 'A new machine is now active in UK-EAST',
        }));
      }}>Edit Information</h2>
      <div className="main-info">
        <div className="avatar-container">
          {currentUser?.avatar ? (
            <Avatar
              image={currentUser?.avatar}
              isCovered={true}
              isBordered={true}
              width="110px"
              height="110px"
            />
          ) : (
            <Avatar
              image={<FaUserCircle color="#2699fb" size="110px" />}
              width="110px"
              height="110px"
            />
          )}

          <div
            className="avatar-edit-button"
            onClick={() => setIsPictureDialogOpen(true)}
          >
            <MdOutlineCameraAlt />
          </div>
        </div>
        <div className="details">
          <InlineEdit
            defaultValue={currentUser?.profile?.displayName}
            editView={({ errorMessage, ...fieldProps }) => (
              <InputBox className="name-edit-input" {...fieldProps} />
            )}
            displayView={({ enableEditMode, value }) => (
              <div className="user-name">
                <div className="value">{value}</div>
                <div
                  className="circle-edit-button"
                  onClick={() => {
                    enableEditMode();
                  }}
                >
                  <TbEditCircle />
                </div>
              </div>
            )}
            onConfirm={updateProfileName}
          />
          <div className="user-email">{currentUser?.profile?.email}</div>
          <div className="user-phone">
            <div className="caption">Phone Number:</div>
            <div>{currentUser?.profile?.mobilePhone}</div>
            <div
              className="circle-edit-button"
              onClick={() => {
                setIsUpdatePhoneOpen(true);
              }}
            >
              <TbEditCircle />
            </div>
          </div>
        </div>
      </div>
      <DesktopSize>
        <div className="shortcuts">
          {widgets.map((widget, index) => (
            <Widget widget={widget} key={index} />
          ))}
        </div>
      </DesktopSize>
      {isPictureDialogOpen && (
        <UserProfilePictureDialog
          onClose={() => setIsPictureDialogOpen(false)}
        />
      )}
      {isUpdatePhoneOpen && (
        <UserProfileUpdatePhone
          onCloseByUser={() => setIsUpdatePhoneOpen(false)}
        />
      )}
    </div>
  );
}

export default UserProfileInformation