import { createRef, useEffect, useState } from "react";
import { useTheme } from "../../helper/ThemeProvider";
import useDebounce from "../../helper/use-debounce";
import Button from "../Button/Button";
import { LoadingIcon } from "../Icons/Icons";
import OutSideDetector from "../OutsideDetector/OutSideDetector";
import SmallAlert from "../SmallAlert/SmallAlert";
import Loading from "../Loading/Loading";
import "./SearchableMultiSelect.sass";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";

function SearchableMultiSelect({
  entity,
  entities,
  items,
  validate,
  isValid,
  alert,
  loading,
  onAction,
  onSearch,
  inProgress,
  onSelectItem,
  enableAction,
  alwaysShow,
  onSelectionChange,
}) {
  const [name, setName] = useState("");
  const [search, setSearch] = useState("");
  const [valid, setValid] = useState(false);
  const { currentContext } = useTheme();

  const [selectedItems, setSelectedItems] = useState(
    entities ? [...entities] : []
  );

  const [searchItems, setSearchItems] = useState(items ? [...items] : []);

  const [showItems, setShowItems] = useState(false);
  const [status, setStatus] = useState({});

  const debouncedNameTerm = useDebounce(name, 1000);
  const debouncedSearchTerm = useDebounce(search, 200);

  useEffect(() => {
    if (debouncedNameTerm) {
      validate(name);
    }
  }, [debouncedNameTerm]);

  useEffect(() => {
    onSearch(search);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    onSelectionChange(
      selectedItems.map((item) => ({ id: item.id, name: item.name }))
    );
  }, [selectedItems]);

  useEffect(() => {
    setStatus({ ...alert });
  }, [alert]);

  useEffect(() => {
    setValid(name !== "" && isValid);
  }, [isValid]);

  const toggleShowItemsOn = () => {
    setShowItems(true);
  };

  const toggleShowItemsOff = () => {
    setShowItems(false);
  };

  const toggleSelect = (item) => {
    const currentItem = selectedItems.find((s) => s.name === item.name);
    if (currentItem === undefined) setSelectedItems([...selectedItems, item]);
    else
      setSelectedItems([...selectedItems.filter((s) => s.name !== item.name)]);
    onSelectItem(item);
    inputRef.current.value = "";
  };

  const addNewItem = () => {
    onAction(name);
    setName("");
    setValid(false);
  };

  useEffect(() => {
    setValid(name !== "" && isValid);
  }, [name]);

  useEffect(() => {
    if (entities !== undefined) setSelectedItems([...entities]);
  }, [entities]);

  useEffect(() => {
    if (items !== undefined) setSearchItems([...items]);
  }, [items]);

  const [inputRef, setInputRef] = useState(createRef());

  useEffect(() => {
    if (inputRef.current !== null && showItems) {
      inputRef.current.focus();
    }
  }, [inputRef, showItems]);

  return (
    <div className="searchable-multi-select">
      <span className="searchable-multi-select-title">Select {entity}(s)</span>
      <OutSideDetector
        className="searchable-multi-select-container"
        detect={toggleShowItemsOff}
      >
        <div
          className="searchable-multi-select-selected"
          onClick={toggleShowItemsOn}
        >
          {inProgress && <Loading />}

          <ul className="searchable-multi-select-selected-items">
            {selectedItems.map((item, index) => (
              <li
                className="searchable-multi-select-selected-item"
                key={index}
              >
                {item.icon}
                <span style={{ width: "max-content" }}>{item.name}</span>
                <span
                  className="searchable-multi-select-selected-item-close"
                  onClick={() => {
                    toggleSelect(item);
                  }}
                >
                  &#10005;
                </span>
              </li>
            ))}
            <li>
              <input
                ref={inputRef}
                className="searchable-multi-select-selected-input"
                type="text"
                placeholder={`Search ${entity}(s)`}
                onInput={(event) => {
                  setSearch(event.target.value);
                }}
              />
            </li>
          </ul>
        </div>
        {(showItems || alwaysShow) && (
          <div className="searchable-multi-select-list-items-container">
            <ul className="searchable-multi-select-list-items">
              {searchItems.map((item, index) => (
                <li
                  className="searchable-multi-select-list-item"
                  key={index}
                  style={{
                    background: item.select && currentContext.theme.highlight,
                  }}
                  onClick={() => {
                    toggleSelect(item);
                  }}
                >
                  <div className="searchable-multi-select-list-item-info">
                    {item.select ? (
                      <MdOutlineCheckBox
                        size="20"
                      />
                    ) : (
                      <MdOutlineCheckBoxOutlineBlank
                        size="20"
                      />
                    )}
                    {/* {item.icon} */}
                    <span>{item.name}</span>
                  </div>
                </li>
              ))}
            </ul>

            {enableAction && (
              <div
                className={`searchable-multi-select-list-add-item${
                  status.alert
                    ? " searchable-multi-select-list-add-item-" + status.type
                    : ""
                }`}
              >
                <div className={`searchable-multi-select-list-add-item-input`}>
                  <input
                    placeholder={`Type your ${entity}`}
                    type="text"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                      setStatus({});
                    }}
                  />
                  {loading && (
                    <LoadingIcon size="20" color={currentContext.theme.color} />
                  )}
                  {status.alert && (
                    <SmallAlert
                      alert={status.alert}
                      alertType={status.type}
                      message={status.message}
                      ratio={0.5}
                      hideIcon={false}
                    />
                  )}
                </div>
                {enableAction && (
                  <Button
                    disabled={!valid}
                    value={`+ New ${entity}`}
                    onClick={addNewItem}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </OutSideDetector>
    </div>
  );
}
SearchableMultiSelect.defaultProps = {
  entities: [],
  searchItems: [],
};

export default SearchableMultiSelect;
