import { createSlice } from "@reduxjs/toolkit";

const OperationStepSlice = createSlice({
  name: "operation",
  initialState: {
    step: "",
  },
  reducers: {
    setOperation(state, action) {
      const data = action.payload;
      return { ...state, step: data };
    },
  },
});
export const { setOperation } = OperationStepSlice.actions;
export default OperationStepSlice.reducer;
