import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "src/ui/Avatar/Avatar";
import { useTheme } from "../../../helper/ThemeProvider";
import Button from "../../Button/Button";
import GroupMemberItem from "../../GroupMemberItem/GroupMemberItem";
import SearchableList from "../../SearchableList/SearchableList";
import SVGAvatar from "../../SVGAvatar/SVGAvatar";
import {
  assignOwners,
  assignOwnerships,
  currentGroup,
  optionalSearch,
  search,
} from "../GroupAppSlice";
import "./GroupOwnershipApp.sass";

function GroupOwnershipApp() {
  const groupApp = useSelector((state) => state.groupApp);
  const { currentContext } = useTheme();
  const [selected, setSelected] = useState([]);
  const [owners, setOwners] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupApp.currentGroup?.owners !== undefined) {
      setSelected([
        ...groupApp?.currentGroup?.owners?.map((item) => ({
          id: item.userId,
          title: item.displayName,
          subtitle: item.emailAddress,
          owner: true,
          member: false,
          icon: item.avatar ? (
            <Avatar
              image={item.avatar}
              isCovered={true}
              width="32px"
              height="32px"
            />
          ) : (
            <SVGAvatar />
          ),
        })),
      ]);
    }
  }, [groupApp.currentGroup]);

  useEffect(() => {
    if (groupApp?.search?.data !== undefined)
      setData([
        ...groupApp?.search?.data.users
          .filter((u) => !selected.map((d) => d.id).includes(u.id))
          .map((item) => ({
            id: item.id,
            title: item.displayName,
            subtitle: item.emailAddress,
            active: false,
          })),
      ]);
  }, [groupApp?.search]);

  useEffect(() => {
    return () => {
      setData([]);
      dispatch(search({ status: "" }));
    };
  }, []);

  const onSelectChange = (item) => {
    if (!selected.map((x) => x.id).includes(item.id))
      setSelected([
        ...selected.filter((x) => x.id !== item.id),
        { ...item, owner: true, member: false },
      ]);
    else setSelected([...selected.filter((x) => x.id !== item.id)]);

    setData([
      ...data.map((s) =>
        s.id !== item.id ? { ...s } : { ...s, active: !s.active }
      ),
    ]);
  };

  const typeChangeHandler = (item) => {
    switch (item.type) {
      case "Remove":
        if (selected.length > 1) {
          setSelected([...selected.filter((x) => x.id !== item.member.id)]);
          setData([
            ...data.map((s) =>
              s.id !== item.member.id ? { ...s } : { ...s, active: false }
            ),
          ]);
        }

        break;
      case "Member":
        setSelected([
          ...selected.map((s) =>
            s.id !== item.member.id
              ? { ...s }
              : { ...s, owner: false, member: true }
          ),
        ]);
        break;
      case "Owner":
        setSelected([
          ...selected.map((s) =>
            s.id !== item.member.id
              ? { ...s }
              : { ...s, member: false, owner: true }
          ),
        ]);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setOwners(selected.filter((s) => s.owner === true).map((z) => z.id));
  }, [selected]);

  useEffect(() => {
    console.log(groupApp.assignOwners);
    if (groupApp.assignOwners.status === "success") {
      dispatch(
        currentGroup({
          ...groupApp.currentGroup,
          owners: groupApp?.assignOwners?.data?.owners,
        })
      );
      dispatch(assignOwners({ status: "" }));
    }
  }, [groupApp.assignOwners]);

  const submitHandler = () => {
    if (groupApp.currentGroup?.id !== undefined) {
      dispatch(assignOwnerships({ owners }, groupApp.currentGroup?.id));
      dispatch(search({ status: "" }));
    }
  };

  return (
    <div className="group-ownership-app">
      <SearchableList
        onDataChange={onSelectChange}
        minLength={1}
        tag="groupApp/search"
        feed={data}
        title="Search for user(s)"
        searchCaption="Selected user(s)"
        searchHandler={optionalSearch}
        moreOptions={true}
      />

      <p>
        Owners{" "}
        <span style={{ color: currentContext.theme.color }}>
          (total {owners.length} owner selected)
        </span>
      </p>
      <div className="group-ownership-items-container">
        <ul className="group-ownership-items">
          {selected
            .filter((s) => s.owner === true)
            .map((item, index) => (
              <li className="group-ownership-item" key={index}>
                <GroupMemberItem
                  member={item}
                  types={["Owner", "Remove"]}
                  onTypeChange={typeChangeHandler}
                  defaultMembership="Owner"
                />
              </li>
            ))}
        </ul>
      </div>

      {selected.filter((s) => s.owner === true).length > 0 && (
        <Button
          value="Confirm"
          color="#2699fb"
          width="30%"
          onClick={submitHandler}
          className="confirm-button"
        />
      )}
      {/* <p>At least one group owner is needed for managing group!</p> */}
    </div>
  );
}

export default GroupOwnershipApp;
