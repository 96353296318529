import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "src/redux/action/api";

const DeviceServiceSlice = createSlice({
  name: "deviceService",
  initialState: {
    devices: [],
    deviceTelemetries: {},
    deviceHms: {},
    deviceLiveStatus: {},
    dockConnections: [],
    dockConnectionProgress: null,
    dockWsPermissionProgress: null,
    onboardConnections: [],
    onboardConnectionProgress: null,
    onboardWsPermissionProgress: null,
    mobileConnection: null,
    mobileConnectionProgress: null,
    mobileWsPermissionProgress: null,
    subDevicePermissionRequest: null,
    subDevicePermissionRequestProgress: {},
    s3BucketSessionCredentialRequest: null,
    s3BucketSessionCredentials: {}
  },
  reducers: {
    setDevices(state, action) {
      const data = action.payload;
      return { ...state, devices: data };
    },
    //TODO: setDeviceInfo should accept ID instead of deviceId
    //TODO: setDeviceInfo and similar actions should replace data instead of merging
    setDeviceInfo(state, action) {
      const {deviceId, data} = action.payload;
      const index = state.devices.findIndex((device) => device.id === deviceId);

      if(index !== -1)
        state.devices[index] = { 
          ...state.devices[index], 
          ...data 
        };

      return state;
    },
    setDeviceTelemetries(state, action) {
      const {deviceId, data} = action.payload;

      state.deviceTelemetries[deviceId] = {
        ...state.deviceTelemetries[deviceId],
        ...(typeof data === 'object' ? data : {}),
      };

      return state;
    },
    removeDeviceTelemetries(state, action) {
      const deviceId = action.payload;
      delete state.deviceTelemetries[deviceId];
      return state;
    },
    setDeviceHms(state, action) {
      const { deviceId, data } = action.payload;
      const currTime = Date.now() / 1000;
    
      // Initialize currData if it doesn't exist
      const currData = state.deviceHms[deviceId] || {
        newErrorTimestamp: null,
        errors: [],
      };
    
      // Create a copy of currErrors to avoid direct mutation
      let currErrors = [...currData.errors.map(item => ({ ...item }))];
      let receivedErrors = Array.isArray(data?.list) ? data.list : data || [];
    
      receivedErrors.forEach((dataItem) => {
        let errorCode = dataItem.code || dataItem.errorCode;
        errorCode = String(errorCode).startsWith("0x")
          ? errorCode
          : "0x" + errorCode.toString(16).toUpperCase();
    
        const index = currErrors.findIndex((item) => item.code === errorCode);
    
        if (index === -1) {
          const newErrorInfo = {
            code: errorCode,
            level: dataItem.level || dataItem.errorLevel,
            componentIndex: dataItem.componentIndex || dataItem.args?.component_index,
            timestamp: currTime,
            inSky: dataItem.in_the_sky || null,
            module: dataItem.module || null,
            sensorIndex: dataItem.args?.sensor_index || null,
            imminent: dataItem.imminent || null,
            deviceType: dataItem.deviceType || null,
          };
    
          // console.log("➕ New hms error added", {
          //   errorCode,
          //   currData,
          //   newErrorInfo,
          // });
    
          // Add new error at the start of the array
          currErrors.unshift(newErrorInfo);
          currData.newErrorTimestamp = currTime;
        } else {
          // Update timestamp if the error already exists
          currErrors[index].timestamp = dataItem.ts || currTime;
        }
      });
    
      // Remove errors that are older than 10 seconds
      currErrors = currErrors.filter((item) => currTime - item.timestamp < 10);
    
      // Update currData.errors immutably
      const updatedCurrData = {
        ...currData,
        errors: currErrors,
      };
    
      // Assign updated data back to the state
      state.deviceHms[deviceId] = updatedCurrData;
    },    
    removeDeviceHms(state, action) {
      const deviceId = action.payload;
      delete state.deviceHms[deviceId];
      return state;
    },
    setDeviceLiveStatus(state, action) {
      const {deviceId, data} = action.payload;
      state.deviceLiveStatus[deviceId] = data;

      return state;
    },
    removeDeviceLiveStatus(state, action) {
      const deviceId = action.payload;
      delete state.deviceLiveStatus[deviceId];

      return state;
    },
    setDockConnectionInfo(state, action) {
      const {deviceId, data} = action.payload;
      const index = state.dockConnections.findIndex((dock) => dock.deviceId === deviceId);

      if(index !== -1)
        state.dockConnections[index] = {
          ...state.dockConnections[index], 
          ...data 
        };
      else {
        state.dockConnections.push({
          deviceId,
          ...data
        });
      }

      return state;
    },
    removeDockConnectionInfo(state, action) {
      const deviceId = action.payload;
      state.dockConnections = state.dockConnections.filter((dock) => dock.deviceId !== deviceId);

      return state;
    },
    setDockConnectionProgress(state, action) {
      const data = action.payload;
      return { ...state, dockConnectionProgress: data };
    },
    setDockWsPermissionProgress(state, action) {
      const data = action.payload;
      return { ...state, dockWsPermissionProgress: data };
    },
    setOnboardConnectionInfo(state, action) {
      const {deviceId, data} = action.payload;
      const index = state.onboardConnections.findIndex((item) => item.deviceId === deviceId);

      if(index !== -1)
        state.onboardConnections[index] = { 
          ...state.onboardConnections[index], 
          ...data 
        };
      else
        state.onboardConnections.push({
          deviceId,
          ...data
        });

      return state;
    },
    setOnboardConnectionProgress(state, action) {
      const data = action.payload;
      return { ...state, onboardConnectionProgress: data };
    },
    setOnboardWsPermissionProgress(state, action) {
      const data = action.payload;
      return { ...state, onboardWsPermissionProgress: data };
    },
    setMobileConnectionInfo(state, action) {
      const {data} = action.payload;

      state.mobileConnection = { 
        ...state.mobileConnection, 
        ...data 
      };

      return state;
    },
    setMobileConnectionProgress(state, action) {
      const data = action.payload;
      return { ...state, mobileConnectionProgress: data };
    },
    setMobileWsPermissionProgress(state, action) {
      const data = action.payload;
      return { ...state, mobileWsPermissionProgress: data };
    },
    setSubDevicePermissionRequest(state, action) {
      const data = action.payload;
      return { ...state, subDevicePermissionRequest: data };
    },
    setSubDevicePermissionRequestProgress(state, action) {
      const { deviceId, data } = action.payload;
      state.subDevicePermissionRequestProgress[deviceId] = data;
      return state;
    },
    cleanSubDevicePermissionRequest(state, action) {
      state.subDevicePermissionRequestProgress[action.payload] = null;
      state.subDevicePermissionRequest = null;

      return state;
    },
    setS3BucketSessionCredentialRequest(state, action) {
      const data = action.payload;
      return { ...state, s3BucketSessionCredentialRequest: data };
    },
    setS3BucketSessionCredentials(state, action) {
      const { bucketId, data } = action.payload;
      state.s3BucketSessionCredentials[bucketId] = data;
      return state;
    }
  },
});

export const {
  setDevices,
  setDeviceInfo,
  setDeviceTelemetries,
  removeDeviceTelemetries,
  setDeviceHms,
  removeDeviceHms,
  setDeviceLiveStatus,
  removeDeviceLiveStatus,
  addDockConnection,
  setDockConnectionInfo,
  setDockConnectionProgress,
  setDockWsPermissionProgress,
  removeDockConnectionInfo,
  addOnboardConnection,
  setOnboardConnectionInfo,
  setOnboardConnectionProgress,
  setOnboardWsPermissionProgress,
  setMobileConnectionInfo,
  setMobileConnectionProgress,
  setMobileWsPermissionProgress,
  setSubDevicePermissionRequest,
  setSubDevicePermissionRequestProgress,
  cleanSubDevicePermissionRequest,
  setS3BucketSessionCredentialRequest,
  setS3BucketSessionCredentials,
} = DeviceServiceSlice.actions;
export default DeviceServiceSlice.reducer;

export const startDockConnection = (id, meta) =>
  apiCallBegan({
    url: `/devices/app/docks/${id}/ws-connection/`,
    method: "Get",
    onSuccess: setDockConnectionProgress.type,
    onError: setDockConnectionProgress.type,
    meta,
  });

export const getDockWsPermissions = (data, meta) => {
  return apiCallBegan({
    url: `/devices/app/docks/${data.id}/hub-permissions`,
    method: "Post",
    data,
    onSuccess: setDockWsPermissionProgress.type,
    onError: setDockWsPermissionProgress.type,
    meta,
  })};

export const startOnboardConnection = (id, meta) =>
  apiCallBegan({
    url: `/devices/app/onboard/ws-connection/${id}`,
    method: "Get",
    onSuccess: setOnboardConnectionProgress.type,
    onError: setOnboardConnectionProgress.type,
    meta,
  });

export const getOnboardWsPermissions = (data, meta) => {
  return apiCallBegan({
    url: `/devices/onboard/hub-permissions`,
    method: "Post",
    data,
    onSuccess: setOnboardWsPermissionProgress.type,
    onError: setOnboardWsPermissionProgress.type,
    meta,
  })};

export const startMobileConnection = () =>
  apiCallBegan({
    url: `/account/app/frontend/ws-connection?hub=mobile`,
    method: "Get",
    onSuccess: setMobileConnectionProgress.type,
    onError: setMobileConnectionProgress.type,
  });

export const getMobileWsPermissions = (data) => {
  return apiCallBegan({
    url: `/account/mobile/wps/permissions`,
    method: "Post",
    data,
    onSuccess: setMobileWsPermissionProgress.type,
    onError: setMobileWsPermissionProgress.type,
  })};

export const getS3BucketSessionCredentials = (bucketId) => {
  return apiCallBegan({
    url: `/storages/s3/bucket/${bucketId}/session-credential`,
    method: "Post",
    data: {
      ExpirationSeconds: 3600,
    },
    onSuccess: setS3BucketSessionCredentialRequest.type,
    onError: setS3BucketSessionCredentialRequest.type,
  });
}