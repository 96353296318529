import React from 'react'
import './MapToolbar.sass';
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineMyLocation, MdOutlineOpenInNew, MdOutlinePictureInPictureAlt } from "react-icons/md";
import { enlargeMap, showWaypointForm, videoPip } from "src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";
import { RiFullscreenExitLine, RiFullscreenLine } from "react-icons/ri";
import { GrWaypoint } from 'react-icons/gr';
import { MapLayerStyles } from '../Map';
import { IoEarthOutline, IoLayersOutline } from 'react-icons/io5';

export const FLIGHT_MAP_FULLSCREEN_URL_PARAM = 'uN2Wu6ye';

function MapToolbar({ currLayerStyle, onRecenterClick, onStyleChangeClick }) {
  const flight = useSelector((state) => state.flight);
  const dispatch = useDispatch();

  return (
    <div className="map-toolbox">
      {!flight.mapLimitedView ? (
        <>
          {flight.enlargeMap ? (
            <>
              <button className="map-toolbox-button" title="Restore Map" onClick={() => {
                dispatch(enlargeMap(false));
                dispatch(videoPip(false));
              }}>
                <RiFullscreenExitLine className="button-icon" />
              </button>
              <button className="map-toolbox-button" title="Open in New Window" onClick={() => {
                const currUrl = new URL(window.location);
                currUrl.searchParams.set(FLIGHT_MAP_FULLSCREEN_URL_PARAM, 1);
                window.open(currUrl.toString(), '_blank', 'toolbar=0,location=0,menubar=0');
              }}>
                <MdOutlineOpenInNew className="button-icon" />
              </button>
              <button className="map-toolbox-button" title={currLayerStyle === MapLayerStyles.SATELLITE ? "Show street map" : "Show satellite map"} onClick={() => {
                onStyleChangeClick?.(currLayerStyle === MapLayerStyles.SATELLITE ? MapLayerStyles.STREET : MapLayerStyles.SATELLITE);
              }}>
                <IoLayersOutline className="button-icon" />
              </button>
              {flight.connected ? (
                <>
                  <button className="map-toolbox-button" title="Set New Destination" onClick={() => {
                    dispatch(showWaypointForm());
                  }}>
                    <GrWaypoint className="button-icon" />
                  </button>
                  <button className="map-toolbox-button" title="Toggle Video PiP" onClick={() => dispatch(videoPip())}>
                    <MdOutlinePictureInPictureAlt className="button-icon" />
                  </button>
                </>
              ) : null}
            </>
          ) : (
            <button className="map-toolbox-button" title="Enlarge Map" onClick={() => {
              dispatch(enlargeMap(true));
              dispatch(videoPip(true));
            }}>
              <RiFullscreenLine className="button-icon" />
            </button>
          )}
        </>
      ) : null}
      {flight.enlargeMap && onRecenterClick ? (
        <button className="map-toolbox-button" title="Recenter Map / Auto Recenter on Edges" onClick={onRecenterClick}>
          <MdOutlineMyLocation className="button-icon" />
        </button>
      ) : null}
    </div>
  )
}

export default MapToolbar