import { setIdentity } from "src/components/Dashboard/Components/WebsocketHandler/IdentitySlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router";
import { useLocation } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import { setContext } from "../../../../redux/ducks/appContextSlice";
import DesktopSize from "../../../../ui/DesktopSize/DesktopSize";
import Landscape from "../../../../ui/Landscape/Landscape";
import Portrait from "../../../../ui/Portrait/Portrait";
import TabletSize from "../../../../ui/TabletSize/TabletSize";
import FlightDashboardContainer from "../../Components/FlightDashboardContainer/FlightDashboardContainer";
import "./DeviceApp.sass";
import DeviceHome from "./DeviceHome/DeviceHome";
// import DeviceOnboarding from "./OnboardDeviceApp/DeviceOnboarding/DeviceOnboarding";
import OnboardDeviceApp from "./OnboardDeviceApp/OnboardDeviceApp";
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import CameraObserverApp from "./CameraObserverApp/CameraObserverApp";
import DockManagerApp from "./DockManagerApp/DockManagerApp";
function DeviceApp() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setContext({
        theme: { color: "#2699fb", name: "device", highlight: "#00b93811" },
      })
    );
  });

  let { path } = useRouteMatch();
  const location = useLocation();
  const { connected } = useFrontendHub();
  useEffect(() => {
    const locations = location.pathname.split("/");
    dispatch(setIdentity(locations[locations.length - 2]));
  }, [location]);

  return (
    <Switch>
      <Route exact path={path}>
        <Portrait>
          <DeviceHome />
        </Portrait>
      </Route>
      <Route path={`${path}/onboard/list`}>
        {connected && (
          <Portrait>
            <OnboardDeviceApp />
          </Portrait>
        )}
      </Route>
      <Route path={`${path}/camera-observer`}>
        {connected && (
          <Portrait>
            <CameraObserverApp />
          </Portrait>
        )}
      </Route>
      <Route path={`${path}/dock-manager`}>
        {connected && (
          <Portrait>
            <DockManagerApp />
          </Portrait>
        )}
      </Route>
      <Route path={`${path}/onboard/flight-app`}>
        <TabletSize>
          <Landscape
            background="transparent"
            color="#00b938"
            message="Please rotate your device to get page functional!"
            closable={true}
          >
            <FlightDashboardContainer />
          </Landscape>
        </TabletSize>
        <DesktopSize>
          <FlightDashboardContainer />
        </DesktopSize>
      </Route>
    </Switch>
  );
}

export default DeviceApp;
