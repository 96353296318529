import "./ProfileMenu.sass";
import Avatar from "../Avatar/Avatar";
import DesktopSize from "../DesktopSize/DesktopSize";
import { truncateText } from "src/helper/utils";
import { useCurrentUser } from "src/helper/AccountStateProvider";
import { FaUserCircle } from "react-icons/fa";
import { useTheme } from "src/helper/ThemeProvider";

function ProfileMenu({ toggleProfileMenu }) {
  const { currentUser } = useCurrentUser();
  const { currentContext } = useTheme();

  const name = currentUser?.profile?.displayName;
  const email = currentUser?.profile?.email || 'User Account';

  let minCharLength = Math.min(
    name?.length,
    email?.length
  );

  if (name && email && minCharLength < 20) minCharLength = 20;

  return (
    <div className="profile-menu" onClick={toggleProfileMenu}>
      {currentUser?.avatar ? (
        <Avatar
          image={currentUser?.avatar}
          isCovered={true}
          width="32px"
          height="32px"
        />
      ) : (
        <Avatar
          image={
            <FaUserCircle color={currentContext.theme.color} size="32px" />
          }
          width="32px"
          height="32px"
        />
      )}
      <DesktopSize>
        <div className="profile-menu-info">
          <span>
            {truncateText(name, minCharLength)}
          </span>
          <span>
            {truncateText(email, minCharLength)}
          </span>
        </div>
      </DesktopSize>
    </div>
  );
}

export default ProfileMenu;
