import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../../redux/action/api";

const StorageContainerAppSlice = createSlice({
  name: "containerApp",
  initialState: {
    list: [],
    model: {},
    blobs: [],
    blob: {},
    remove: { status: "" },
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    setContainerModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    blobs(state, action) {
      const data = action.payload;
      return { ...state, blobs: data };
    },
    setBlob(state, action) {
      const data = action.payload;
      return { ...state, blob: data };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: data };
    },
  },
});
export const { list, setContainerModel, model, blobs, setBlob, remove } =
  StorageContainerAppSlice.actions;
export default StorageContainerAppSlice.reducer;

export const getList = () =>
  apiCallBegan({
    url: `/containers`,
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const getBlobs = (storageId, containerId, folder) =>
  apiCallBegan({
    url: `/containers/${storageId}/blobs/${containerId}${
      folder !== "" ? `?prefix=${folder}` : ""
    }`,
    method: "Get",
    onSuccess: blobs.type,
    onError: blobs.type,
  });

export const deleteBlobs = (storageId, containerId, files) =>
  apiCallBegan({
    url: `/storages/${storageId}/container/${containerId}/files`,
    method: "delete",
    data: files,
    onSuccess: remove.type,
    onError: remove.type,
  });
