import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAccount } from "../../../helper/AccountStateProvider";
import BreadCrumb from "../../../ui/BreadCrumb/BreadCrumb";
import DesktopSize from "../../../ui/DesktopSize/DesktopSize";
import Dialog from "../../../ui/Dialog/Dialog";
import Table from "../../../ui/Table/Table";
import TabletSize from "../../../ui/TabletSize/TabletSize";
import AdminOperationManager from "../AdminOperationManager/AdminOperationManager";
import { setOperation } from "../AdminOperationManager/OperationStepSlice";
import AdminOptionBar from "../AdminOptionBar/AdminOptionBar";
import "./AdminContentAreaContainer.sass";

function AdminContentAreaContainer({
  histories,
  title,
  description,
  operations,
  features,
  data,
  table,
  operationManager,
  managerHandler,
  children,
  footer,
  onRowChange,
  currentFeature,
  onCurrentFeatureChanged,
}) {
  const [selectedFeature, setSelectedFeature] = useState();
  const [selectedRows, setSelectedRows] = useState({});
  const [options, setOptions] = useState([]);
  const [rows, setRows] = useState({ values: [], headers: [], options: [] });
  const [showDialog, setShowDialog] = useState(false);
  const [showOperationManager, setShowOperationManager] =
    useState(operationManager);
  const [operationManagerHandler, setOperationManagerHandler] =
    useState(managerHandler);
  const [multiSelect, setMultiSelect] = useState(table.multiSelect);
  const dispatch = useDispatch();
  const { account } = useAccount();
  useEffect(() => {
    setSelectedFeature(currentFeature);
  }, [currentFeature]);

  useEffect(() => {
    setOptions((currentOptions) => {
      const item = currentOptions.find((o) => o.selected);
      return operations.map((option) => ({
        ...option,
        selected: item?.selected ? item.name === option.name : option.selected,
      }));
    });
  }, [operations]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    setShowOperationManager(operationManager);
  }, [operationManager]);

  useEffect(() => {
    setOperationManagerHandler(managerHandler);
  }, [managerHandler]);

  useEffect(() => {
    if (selectedFeature !== undefined) {
      if (selectedFeature.type === "dialog") {
        setShowDialog(true);
        setMultiSelect(table.multiSelect);
        setShowOperationManager(false);
        setOperationManagerHandler(false);
      } else if (selectedFeature.type === "form") {
        setMultiSelect(false);
        setShowOperationManager(true);
        setOperationManagerHandler(true);
      } else {
        setMultiSelect(table.multiSelect);
        setShowOperationManager(false);
        setOperationManagerHandler(false);
        selectedFeature.actionGenerator();
      }
      if (selectedFeature.steps !== undefined) {
        if (
          selectedFeature.steps.find((step) => step.active) !== undefined ||
          selectedFeature.steps.find((step) => step.active)?.name !== ""
        )
          dispatch(
            setOperation(
              selectedFeature.steps.find((step) => step.active)?.name
            )
          );
      }
      setOptions((currentOptions) => {
        return currentOptions.map((option) => ({
          ...option,
          selected: option.name === selectedFeature?.id,
        }));
      });
    }
  }, [selectedFeature]);

  useEffect(() => {
    if (selectedRows !== undefined && selectedRows.length > 0) {
      const conflict =
        selectedRows?.filter((row) => {
          return row.id === account.user.localAccountId;
        }).length > 0;
      options.map(
        (item) =>
          (item.active =
            item.type === "dependent"
              ? item.except === "account"
                ? !conflict
                : true
              : item.active)
      );
      options.map(
        (option) =>
          (option.active =
            option.type === "dependent" && option.singular
              ? selectedRows.length < 2
              : option.active)
      );
      setOptions([...options]);
    } else {
      options.map(
        (item) =>
          (item.active = item.type === "dependent" ? false : item.active)
      );
      setOptions([...options]);
    }
  }, [selectedRows]);

  const rowChangeHandler = (row) => {
    setSelectedRows(row);
    onRowChange(row);
  };
  const rowsChangeHandler = (rows) => {
    setSelectedRows(rows);
  };

  const optionHandler = (name) => {
    setSelectedFeature({
      ...features.find((f) => {
        return f.id === name;
      }),
    });
    if (onCurrentFeatureChanged !== undefined) onCurrentFeatureChanged(name);
  };

  const CloseDialogHandler = () => {
    setShowDialog(false);
  };
  const ConfirmDialogHandler = () => {
    selectedFeature.actionGenerator(selectedRows);
    setShowDialog(false);
  };

  const toggleOperationManager = () => {
    setMultiSelect(table.multiSelect);
    setOperationManagerHandler(false);
  };

  const closeOperationManager = () => {
    setShowOperationManager(false);
  };

  return (
    <div className="admin-content-area-container">
      <BreadCrumb histories={histories} />
      <div className="admin-content-area-header">
        <h3 className="admin-content-title">{title}</h3>
        <p className="admin-content-description">{description}</p>
        {operations.length > 0 && (
          <>
            <TabletSize>
              <AdminOptionBar
                base={1}
                buttons={options}
                onClick={optionHandler}
                searchable={true}
              />
            </TabletSize>
            <DesktopSize>
              <AdminOptionBar
                base={6}
                buttons={options}
                onClick={optionHandler}
                searchable={true}
              />
            </DesktopSize>
          </>
        )}
      </div>
      <div className="admin-content-area-body">
        {children}

        {table.show === true && (
          <Table
            multipleSelect={multiSelect}
            rows={rows}
            onRowChange={rowChangeHandler}
            onRowsChange={rowsChangeHandler}
            optionChangeHandler={optionHandler}
            tags={[...table.tags]}
            selectable={true}
          />
        )}

        {showDialog && (
          <Dialog
            model={{
              message: selectedFeature.confirm,
              CloseHandler: CloseDialogHandler,
              ConfirmHandler: ConfirmDialogHandler,
            }}
          />
        )}
      </div>
      {operationManagerHandler && (
        <AdminOperationManager
          isOpen={showOperationManager}
          toggle={toggleOperationManager}
          close={closeOperationManager}
          selectedFeature={selectedFeature}
          onStepChanged={onCurrentFeatureChanged} //TODO check stepChange with MR
        />
      )}

      <div className="admin-content-area-footer">{footer}</div>
    </div>
  );
}
AdminContentAreaContainer.defaultProps = {
  table: { tags: [], rows: [], show: false },
  operations: [],
  onRowChange: () => {},
};
export default AdminContentAreaContainer;
