import { Badge as RadixBadge } from '@radix-ui/themes'
import { BadgeProps } from '@radix-ui/themes/dist/cjs/components/badge'
import classNames from 'classnames'

function Badge({ className, ...props}: BadgeProps) {
  return (
    <RadixBadge className={classNames('ir-badge', className)} {...props} />
  )
}

export default Badge