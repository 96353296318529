import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import '@radix-ui/themes/styles.css';
import './index.css';
import "./assets/style/animation.sass";
import "./assets/style/default.sass";
import App from "./components/App/App";
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from './redux/configureStore';
import { AuthConfigProvider } from "./helper/AuthConfigProvider";
import { BrowserRouter as Router } from 'react-router-dom';
import { Theme, ThemePanel } from '@radix-ui/themes';
import { AppThemeProvider } from './helper/AppThemeProvider';

// Sentry.init({
//   dsn: "https://f1df882e49c08520fea5496affb60d33@o4507132534325248.ingest.us.sentry.io/4507132538585088",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/ir-uav-legacy-api-au-east-dev-ext\.azure-api\.net\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <Theme radius="small">
        <Provider store={store}>
          <AuthConfigProvider>
            <Router>
              <App />
            </Router>
          </AuthConfigProvider>
        </Provider>
        {/* <ThemePanel /> */}
      </Theme>
    </AppThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
