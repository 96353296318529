import { notification } from "src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";
import { useAccount } from "src/helper/AccountStateProvider";
import {
  dockConnectionManager,
  frontendConnectionManager,
  onboardConnectionManager,
} from "src/helper/HubConnectionManager";
import React, { useEffect, useRef, useMemo, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  frontendConnection,
  frontendConnections,
  getFrontendConnection,
  removeFromConnectionPool,
  removeFromPermissionPool,
  removeFromFrontendConnectionPool,
  closeOnboardConnection,
  addToFrontendConnectionPool,
  closeFrontendConnection,
  removeFromConnections,
  removeFromDockConnectionPool,
  removeFromDockPermissionPool,
  removeFromDockConnections,
  closeDockConnection,
  getFrontendWsPermissions,
} from "../WebsocketHandler/WebsocketSlice";
import { HubEventName, HubName } from "src/helper/HubConnection";
import useDialog from "src/helper/useDialog";

const FrontendHubContext = React.createContext({});

export const useFrontendHub = () => {
  return useContext(FrontendHubContext);
};

function FrontendHubProvider({ children }) {
  const { account } = useAccount();
  const dispatch = useDispatch();
  const serverMessageHandlerIds = useRef([]);
  const systemMessageHandlerIds = useRef([]);
  const instance = useSelector((state) => state.websocket);
  const [connected, setConnected] = useState(false);
  const [broadcastReady, setBroadcastReady] = useState(false);
  const dialog = useDialog();

  const permissionGroups = useSelector(
    (state) => state.websocket.frontendPermissions?.data?.group
  );

  const contextValue = useMemo(
    () => ({
      hub: frontendConnectionManager,
      userId: account.user.localAccountId,
      connected,
      group: permissionGroups,
      checkConnectivity: (userId, deviceId) => {
        frontendConnectionManager?.sendEvent(
          userId,
          HubEventName.CHECK_DRONE_STATES,
          {
            action: HubEventName.CONNECTIVITY_STATUS,
            userId: deviceId,
            platform: HubName.FRONTEND,
            data: {
              userId: userId,
              deviceId,
              corelationId: "FrontendHubProvider",
            },
          }
        );
      },
      checkDockConnectivity: (userId, deviceId) => {
        frontendConnectionManager?.sendEvent(
          userId,
          HubEventName.CHECK_DOCK_STATES,
          {
            action: HubEventName.CONNECTIVITY_STATUS,
            userId: deviceId,
            platform: HubName.FRONTEND,
            data: {
              userId: userId,
              deviceId,
              corelationId: "FrontendHubProvider",
            },
          }
        );
      },
      checkTelemetries: (userId, deviceId) => {
        frontendConnectionManager?.sendEvent(
          userId,
          HubEventName.CHECK_DRONE_STATES,
          {
            action: HubEventName.TELEMETRIES_STATUS,
            userId: deviceId,
            platform: HubName.FRONTEND,
            data: {
              userId,
              deviceId,
              corelationId: "FrontendHubProvider",
            },
          }
        );
      },
    }),
    [account.user.localAccountId, connected, permissionGroups]
  );

  useEffect(() => {
    if (account.user.localAccountId)
      setConnected(
        frontendConnectionManager.getConnectionById(account.user.localAccountId)
      );
  }, [account.user.localAccountId]);

  const systemMessageHandlers = [
    {
      identity: account.user.localAccountId,
      name: HubEventName.CONNECTED,
      handler: (data) => {
        setConnected(true);
        dispatch(getFrontendWsPermissions({
          connectionId: data?.connectionId,
        }));
      },
    },
    {
      identity: account.user.localAccountId,
      name: HubEventName.DISCONNECTED,
      handler: (data) => {
        setConnected(false);
        dispatch(removeFromFrontendConnectionPool(account.user.localAccountId));
        dispatch(closeFrontendConnection());
      },
    },
  ];

  const serverMessageHandlers = [
    {
      name: HubEventName.DISCONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        dispatch(removeFromConnectionPool(data?.userId));
        dispatch(removeFromPermissionPool(data?.userId));
        dispatch(removeFromConnections(data?.userId));
        onboardConnectionManager?.closeConnection(data?.userId, true);
        dispatch(closeOnboardConnection());
      },
    },
    {
      name: HubEventName.DISCONNECTED,
      platform: HubName.DOCK,
      handler: (data) => {
        dispatch(removeFromDockConnectionPool(data?.userId));
        dispatch(removeFromDockPermissionPool(data?.userId));
        dispatch(removeFromDockConnections(data?.userId));
        dockConnectionManager?.closeConnection(data?.userId, true);
        dispatch(closeDockConnection());
      },
    },
  ];

  useEffect(() => {
    if (instance.newFrontendConnection) {
      dispatch(addToFrontendConnectionPool(instance.newFrontendConnection));
    }
  }, [instance.newFrontendConnection]);

  useEffect(() => {
    if (
      account.user.localAccountId &&
      instance?.frontendConnectionPool.includes(account.user.localAccountId) &&
      !instance?.frontendConnections[account.user.localAccountId]
    ) {
      dispatch(getFrontendConnection());
    }
  }, [instance?.frontendConnectionPool, account.user.localAccountId]);

  useEffect(() => {
    if (instance.frontendConnection?.status === "success") {
      if (instance?.frontendConnection?.data?.url) {
        frontendConnectionManager?.create(
          instance?.frontendConnection?.data?.url,
          () => {
            dispatch(
              frontendConnections({
                [account.user.localAccountId]:
                  instance?.frontendConnection?.data?.url,
              })
            );
          },
          () => {
            dispatch(
              notification({
                message: "Frontend Client Reconnecting...",
                status: "warning",
              })
            );
          },
          () => {
            dialog.fire({
              title: <b>Connection Lost</b>,
              text: "Your connection has been dropped, please refresh the page to continue.",
              icon: 'error',
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: 'Refresh',
            }).then(result => {
              if (result.isConfirmed) window.location.reload();
            });
          },
          () => {
            dialog.fire({
              title: <b>Connection Problem</b>,
              text: "We've detected a poor connection. Please check your network connection.",
              icon: 'warning',
            });
          }
        );
      }
      dispatch(
        frontendConnection({
          status: "",
        })
      );
    }
  }, [instance]);

  useEffect(() => {
    const result = instance.frontendPermissions;

    if (result) {
      if (result.status === "success") {
        setBroadcastReady(true);

        const targetGroup = result.data.group?.join?.['broadcast'];
        frontendConnectionManager.joinGroup(account.user.localAccountId, targetGroup);
      }
    }
  }, [instance.frontendPermissions]);

  useEffect(() => {
    //frontend server messages
    frontendConnectionManager.unsubscribeServerMessages(
      serverMessageHandlerIds.current
    );

    serverMessageHandlerIds.current =
      frontendConnectionManager.subscribeServerMessages(
        serverMessageHandlers,
        "frontendhubprovider"
      );

    //frontend system messages
    frontendConnectionManager.unsubscribeSystemMessages(
      systemMessageHandlerIds.current
    );

    systemMessageHandlerIds.current =
      frontendConnectionManager.subscribeSystemMessages(
        systemMessageHandlers,
        "frontendhubprovider"
      );

    return () => {
      frontendConnectionManager.unsubscribeServerMessages(
        serverMessageHandlerIds.current
      );
      frontendConnectionManager.unsubscribeSystemMessages(
        systemMessageHandlerIds.current
      );
    };
  }, [account.user.localAccountId]);

  return (
    <FrontendHubContext.Provider value={contextValue}>
      {children}
    </FrontendHubContext.Provider>
  );
}

export default FrontendHubProvider;
