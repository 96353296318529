import { useAccount } from "src/helper/AccountStateProvider";
import { dockConnectionManager } from "src/helper/HubConnectionManager";
import { useDeviceId } from "src/helper/SerialNumberProvider";
import React, { useEffect, useRef, useMemo, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "src/ui/Loading/Loading";
import { useFrontendHub } from "../FrontendHubProvider/FrontendHubProvider";
import {
  addToDockPermissionPool,
  newDockConnection,
  removeFromDockConnectionPool,
  removeFromDockConnections,
  removeFromDockPermissionPool,
} from "../WebsocketHandler/WebsocketSlice";
import { HubEventName, HubName } from "src/helper/HubConnection";

const DockHubContext = React.createContext({});

export const useDockHub = () => {
  return useContext(DockHubContext);
};

function DockHubProvider({
  deviceId,
  candidateGroups,
  onJoinComplete,
  onConnectComplete,
  onRequestForConnection,
  onDisconnect,
  children,
}) {
  const { account } = useAccount();
  const dispatch = useDispatch();
  const serverMessageHandlerIds = useRef([]);
  const systemMessageHandlerIds = useRef([]);
  const dockSystemMessageHandlerIds = useRef([]);
  const { hub: frontendHub, checkDockConnectivity } = useFrontendHub();

  const permissionGroups = useSelector(
    (state) => state.websocket.dockPermissionGroups
  );
  const [joinCompleted, setJoinCompleted] = useState(false);
  const [connectionId, setConnectionId] = useState();
  const loading = useSelector((state) => state.loading);
  const contextValue = useMemo(
    () => ({
      hub: dockConnectionManager,
      groups: permissionGroups[deviceId],
      deviceId,
      connectionId,
      joinCompleted,
    }),
    [deviceId, joinCompleted, permissionGroups]
  );

  const dockSystemMessageHandlers = [
    {
      identity: deviceId,
      name: HubEventName.CONNECTED,
      handler: (data) => {
        dispatch(removeFromDockPermissionPool(data?.userId.split("_")[0]));
        dispatch(addToDockPermissionPool(data?.userId.split("_")[0]));
      },
    },
  ];

  const systemMessageHandlers = [
    {
      identity: account.user.localAccountId,
      name: HubEventName.CONNECTED,
      handler: (data) => {
        checkDockConnectivity(account.user.localAccountId, deviceId);
        if (onRequestForConnection) onRequestForConnection();
      },
    },
  ];

  const serverMessageHandlers = [
    {
      identity: deviceId,
      name: HubEventName.CONNECTIVITY_STATUS,
      platform: HubName.DOCK,
      handler: (data) => {
        dispatch(newDockConnection(data?.userId));
        if (onConnectComplete) onConnectComplete(data);
      },
    },
    {
      identity: deviceId,
      name: HubEventName.CONNECTED,
      platform: HubName.DOCK,
      handler: (data) => {
        setConnectionId(data?.userId);
        checkDockConnectivity(account.user.localAccountId, data?.userId);
      },
    },
    {
      identity: deviceId,
      name: HubEventName.DISCONNECTED,
      platform: HubName.DOCK,
      handler: (data) => {
        dispatch(removeFromDockConnectionPool(data?.userId));
        dispatch(removeFromDockPermissionPool(data?.userId));
        dispatch(removeFromDockConnections(data?.userId));
        if (onDisconnect) onDisconnect(data);
      },
    },
  ];

  useEffect(() => {
    if (permissionGroups[deviceId] && permissionGroups[deviceId].join) {
      candidateGroups
        .map((group) => permissionGroups[deviceId]?.join[group])
        .filter((item) => item)
        .forEach((group) =>
          dockConnectionManager?.joinGroup(deviceId, group)
        );
      onJoinComplete();
      setJoinCompleted(true);
    }
    return () => {
      candidateGroups
        .map((group) => permissionGroups[deviceId]?.join[group])
        .filter((item) => item)
        .forEach((group) =>
          dockConnectionManager?.leaveGroup(deviceId, group)
        );
    };
  }, [permissionGroups[deviceId]]);

  useEffect(() => {
    //frontend server messages
    frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);

    serverMessageHandlerIds.current = frontendHub.subscribeServerMessages(
      serverMessageHandlers,
      "dockhubprovider"
    );

    //frontend system messages
    frontendHub.unsubscribeSystemMessages(systemMessageHandlerIds.current);

    systemMessageHandlerIds.current = frontendHub.subscribeSystemMessages(
      systemMessageHandlers,
      "dockhubprovider"
    );

    //dock system messages

    dockConnectionManager.unsubscribeSystemMessages(
      dockSystemMessageHandlerIds.current
    );

    dockSystemMessageHandlerIds.current =
      dockConnectionManager.subscribeSystemMessages(
        dockSystemMessageHandlers,
        "dockhubprovider"
      );

    if (deviceId !== undefined) {
      checkDockConnectivity(account.user.localAccountId, deviceId);
      if (onRequestForConnection) onRequestForConnection();
    }

    return () => {
      frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);
      frontendHub.unsubscribeSystemMessages(systemMessageHandlerIds.current);
      dockConnectionManager.unsubscribeSystemMessages(
        dockSystemMessageHandlerIds.current
      );
    };
  }, [deviceId]);

  return (
    <DockHubContext.Provider value={contextValue}>
      {loading.status && loading.name === "device-ws/permissions" && (
        <Loading />
      )}
      {children}
    </DockHubContext.Provider>
  );
}

export default DockHubProvider;
