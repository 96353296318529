import { cameraSource } from "src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CameraSourceContext = createContext({});
export const CameraSourceProvider = ({ children }) => {
  //const camPosition = useSelector((state) => state.flight.cameraStats?.camPosition);
  const mainVideoSourceState = useSelector(state => state.flight?.mainVideoSource);
  const connectedState = useSelector(state => state.flight?.connected);
  const [isMainCamera, setIsMainCamera] = useState(true);
  const [isFpvCamera, setIsFpvCamera] = useState(false);

  useEffect(() => {
    setIsMainCamera(connectedState && mainVideoSourceState === cameraSource.MAIN);
    setIsFpvCamera(connectedState && mainVideoSourceState === cameraSource.FPV);
    // setIsMainCamera(true);
    // setIsFpvCamera(false);
  }, [mainVideoSourceState, connectedState]);

  return (
    <CameraSourceContext.Provider value={{ isMainCamera, isFpvCamera }}>
      {children}
    </CameraSourceContext.Provider>
  );
};

export const useCameraSource = () => {
  const { isMainCamera, isFpvCamera } = useContext(CameraSourceContext);
  return { isMainCamera, isFpvCamera };
};