const cloudHmsMessages = {
  "dock_tip_0x12040000": {
      "en": "Dock RTK device disconnected"
  },
  "dock_tip_0x12040001": {
      "en": "Dock moved"
  },
  "dock_tip_0x12040002": {
      "en": "Dock RTK error"
  },
  "dock_tip_0x12040003": {
      "en": "Location calibrated by RTK device far from current dock location"
  },
  "dock_tip_0x12040004": {
      "en": "Dock RTK initialization error"
  },
  "dock_tip_0x16070045": {
      "en": "Dock moved. Exercise caution"
  },
  "dock_tip_0x160900BF": {
      "en": "Wind speed too high (≥9 m/s). Unable to perform task"
  },
  "dock_tip_0x17000001": {
      "en": "No image transmission signal"
  },
  "dock_tip_0x17000004": {
      "en": "Image transmission signal weak"
  },
  "dock_tip_0x17000005": {
      "en": "Strong signal interference in dock location. Fly with caution"
  },
  "dock_tip_0x17110041": {
      "en": "Dock working status error. Aircraft may perform signal lost action"
  },
  "dock_tip_0x1910F003": {
      "en": "Dock external security camera not installed or disconnected"
  },
  "dock_tip_0x19110000": {
      "en": "Dock %1$s cover motor stalled"
  },
  "dock_tip_0x19110002": {
      "en": "Dock %1$s cover excessive position error"
  },
  "dock_tip_0x19110003": {
      "en": "Dock %1$s cover motor overspeed"
  },
  "dock_tip_0x19110004": {
      "en": "Dock %1$s cover motor out of control"
  },
  "dock_tip_0x19110005": {
      "en": "Dock %1$s cover motor voltage too low"
  },
  "dock_tip_0x19110006": {
      "en": "Dock %1$s cover motor voltage too high"
  },
  "dock_tip_0x1911000F": {
      "en": "Dock %1$s cover motor driver communication error"
  },
  "dock_tip_0x19110011": {
      "en": "Dock %1$s cover unknown error"
  },
  "dock_tip_0x19110012": {
      "en": "Calibrating dock %dock_cover_index cover or position calibration data error"
  },
  "dock_tip_0x19110013": {
      "en": "Dock %1$s cover not properly open or closed"
  },
  "dock_tip_0x19110014": {
      "en": "Opening dock %1$s cover timed out"
  },
  "dock_tip_0x19110015": {
      "en": "Closing dock %1$s cover timed out"
  },
  "dock_tip_0x19110016": {
      "en": "Dock %1$s cover stalled. Failed to close"
  },
  "dock_tip_0x19110017": {
      "en": "Dock %1$s cover stalled. Failed to open"
  },
  "dock_tip_0x19110020": {
      "en": "%s dock cover Hall sensor error"
  },
  "dock_tip_0x19110021": {
      "en": "Opening or closing %s dock cover timed out"
  },
  "dock_tip_0x19110022": {
      "en": "Pinch protection triggered when opening or closing dock %dock_cover_index cover"
  },
  "dock_tip_0x19110023": {
      "en": "Driving rod of %s dock cover voltage too low"
  },
  "dock_tip_0x19110024": {
      "en": "Driving rod of %s dock cover disconnected"
  },
  "dock_tip_0x19110025": {
      "en": "%s driving rod short-circuited"
  },
  "dock_tip_0x19110026": {
      "en": "%s driving rod temperature too high"
  },
  "dock_tip_0x19110027": {
      "en": "%s dock cover error"
  },
  "dock_tip_0x19110028": {
      "en": "%s flight pause button error"
  },
  "dock_tip_0x19110029": {
      "en": "%s dock cover command execution failed"
  },
  "dock_tip_0x1911002A": {
      "en": "%s dock cover not sealed"
  },
  "dock_tip_0x1911002D": {
      "en": "Dock %dock_cover_index cover positioning error"
  },
  "dock_tip_0x19110400": {
      "en": "Dock %1$s driving rod position calibration data error"
  },
  "dock_tip_0x19110401": {
      "en": "Dock %1$s driving rod steering gear stalled"
  },
  "dock_tip_0x19110402": {
      "en": "Excessive resistance in steering %1$s driving rod"
  },
  "dock_tip_0x19110403": {
      "en": "Dock %1$s driving rod steering gear overheated"
  },
  "dock_tip_0x19110404": {
      "en": "Dock %1$s driving rod steering gear communication error"
  },
  "dock_tip_0x19110405": {
      "en": "Dock %1$s driving rod steering gear internal error"
  },
  "dock_tip_0x19110406": {
      "en": "Dock %1$s driving rod internal limit switch error"
  },
  "dock_tip_0x19110407": {
      "en": "Dock %1$s driving rod internal limit switch error"
  },
  "dock_tip_0x19110408": {
      "en": "Dock %1$s driving rod external limit switch error"
  },
  "dock_tip_0x19110409": {
      "en": "Dock %1$s driving rod external limit switch error"
  },
  "dock_tip_0x1911040B": {
      "en": "Pushing %1$s driving rod timed out"
  },
  "dock_tip_0x1911040C": {
      "en": "Pulling %1$s driving rod timed out"
  },
  "dock_tip_0x19110800": {
      "en": "Failed to charge dock backup battery"
  },
  "dock_tip_0x19110801": {
      "en": "Dock backup battery level low"
  },
  "dock_tip_0x19110802": {
      "en": "Dock backup battery overheated"
  },
  "dock_tip_0x19110803": {
      "en": "Dock backup battery temperature too low"
  },
  "dock_tip_0x19110804": {
      "en": "Dock backup battery switch not turned on"
  },
  "dock_tip_0x19110805": {
      "en": "Dock backup battery completely depleted. Unable to work properly"
  },
  "dock_tip_0x19110806": {
      "en": "Dock backup battery charging function error"
  },
  "dock_tip_0x19110807": {
      "en": "Dock backup battery charging function error"
  },
  "dock_tip_0x19110808": {
      "en": "Dock backup battery function error"
  },
  "dock_tip_0x19110809": {
      "en": "Dock backup battery function error"
  },
  "dock_tip_0x19110C00": {
      "en": "Dock security camera not installed or disconnected"
  },
  "dock_tip_0x19111400": {
      "en": "Dock electrical cabinet cover open"
  },
  "dock_tip_0x19111401": {
      "en": "Door sensor of dock electrical cabinet error"
  },
  "dock_tip_0x19111402": {
      "en": "Door sensor of dock electrical cabinet error"
  },
  "dock_tip_0x19111800": {
      "en": "Emergency stop button pressed down"
  },
  "dock_tip_0x19111D01": {
      "en": "Aircraft inside dock is not linked. Linked aircraft is outside of dock"
  },
  "dock_tip_0x19112C01": {
      "en": "Temperature sensor of dock control module damaged"
  },
  "dock_tip_0x19112C02": {
      "en": "Temperature sensor of dock control module damaged"
  },
  "dock_tip_0x19112C03": {
      "en": "Dock environment temperature sensor damaged"
  },
  "dock_tip_0x19112C04": {
      "en": "Temperature sensor of dock control module damaged"
  },
  "dock_tip_0x19112C05": {
      "en": "Temperature sensor of dock control module damaged"
  },
  "dock_tip_0x19112C06": {
      "en": "Temperature sensor of dock left charging connector damaged"
  },
  "dock_tip_0x19112C07": {
      "en": "Temperature sensor of dock right charging connector damaged"
  },
  "dock_tip_0x19112C08": {
      "en": "Temperature sensor of landing pad interface board damaged"
  },
  "dock_tip_0x19112C09": {
      "en": "Temperature sensor of landing pad interface board damaged"
  },
  "dock_tip_0x19112C0A": {
      "en": "Temperature sensor of landing pad interface board damaged"
  },
  "dock_tip_0x19112C0B": {
      "en": "Temperature sensor of dock control module damaged"
  },
  "dock_tip_0x19112C0C": {
      "en": "Temperature sensor of dock control module not connected"
  },
  "dock_tip_0x19112C0D": {
      "en": "Temperature sensor of dock charging module damaged"
  },
  "dock_tip_0x19112C0E": {
      "en": "Temperature sensor of dock charging module not connected"
  },
  "dock_tip_0x19112C0F": {
      "en": "Temperature sensor of dock battery module damaged"
  },
  "dock_tip_0x19112C10": {
      "en": "Temperature sensor of dock power module not connected"
  },
  "dock_tip_0x19112C11": {
      "en": "Temperature sensor of dock battery module connector damaged"
  },
  "dock_tip_0x19112C12": {
      "en": "Temperature sensor of dock battery module connector not connected"
  },
  "dock_tip_0x19112C13": {
      "en": "Temperature sensor of dock charging module connector damaged"
  },
  "dock_tip_0x19112C14": {
      "en": "Temperature sensor of dock charging module connector not connected"
  },
  "dock_tip_0x19112C15": {
      "en": "Temperature sensor of dock AC battery module damaged"
  },
  "dock_tip_0x19112C16": {
      "en": "Temperature sensor of dock AC battery module not connected"
  },
  "dock_tip_0x19112C17": {
      "en": "Temperature sensor of dock AC battery module connector damaged"
  },
  "dock_tip_0x19112C18": {
      "en": "Temperature sensor of dock AC battery module connector not connected"
  },
  "dock_tip_0x19112C19": {
      "en": "Temperature sensor of dock backup battery damaged"
  },
  "dock_tip_0x19112C1A": {
      "en": "Temperature sensor of dock backup battery not connected"
  },
  "dock_tip_0x19113000": {
      "en": "Dock vibration sensor error"
  },
  "dock_tip_0x19113001": {
      "en": "Dock vibration detected. Dock may be moved or damaged"
  },
  "dock_tip_0x19113401": {
      "en": "Aircraft battery charge voltage error"
  },
  "dock_tip_0x19113403": {
      "en": "Aircraft battery not properly installed, or battery level too low and battery enters hibernation mode"
  },
  "dock_tip_0x19113404": {
      "en": "Failed to charge aircraft battery"
  },
  "dock_tip_0x19113406": {
      "en": "Aircraft battery error"
  },
  "dock_tip_0x19113407": {
      "en": "Dock power supply module error. Unable to charge aircraft"
  },
  "dock_tip_0x19113414": {
      "en": "Battery overheated (≥44° C). Unable to start charging"
  },
  "dock_tip_0x19113800": {
      "en": "Dock internal power module error"
  },
  "dock_tip_0x19113802": {
      "en": "Dock electronic module power supply error"
  },
  "dock_tip_0x19113803": {
      "en": "Dock power supply disconnected"
  },
  "dock_tip_0x19113806": {
      "en": "Dock battery module error"
  },
  "dock_tip_0x19113807": {
      "en": "Output voltage of dock battery module too high"
  },
  "dock_tip_0x19113808": {
      "en": "Output voltage of dock battery module too low"
  },
  "dock_tip_0x19113809": {
      "en": "Dock battery module temperature too high"
  },
  "dock_tip_0x1911380A": {
      "en": "Dock battery module temperature too low"
  },
  "dock_tip_0x1911380B": {
      "en": "Dock battery module connector temperature too high"
  },
  "dock_tip_0x1911380C": {
      "en": "Dock battery module connector temperature too low"
  },
  "dock_tip_0x19113901": {
      "en": "Battery voltage of dock system too high"
  },
  "dock_tip_0x19113902": {
      "en": "Battery voltage of dock system too low"
  },
  "dock_tip_0x19113903": {
      "en": "Dock control module powered off abnormally"
  },
  "dock_tip_0x19113C00": {
      "en": "Surge protector circuit breaker not turned on, or surge protector device damaged"
  },
  "dock_tip_0x19113C01": {
      "en": "Landing pad connection error"
  },
  "dock_tip_0x19113C02": {
      "en": "Rainfall gauge connection error"
  },
  "dock_tip_0x19113C03": {
      "en": "AC connection error"
  },
  "dock_tip_0x19113C04": {
      "en": "Dock charging module connection error"
  },
  "dock_tip_0x19113D00": {
      "en": "AC cooler damaged"
  },
  "dock_tip_0x19113D01": {
      "en": "AC cooler damaged"
  },
  "dock_tip_0x19113D02": {
      "en": "AC cooling error"
  },
  "dock_tip_0x19113D03": {
      "en": "AC heater damaged"
  },
  "dock_tip_0x19113D04": {
      "en": "AC heater damaged"
  },
  "dock_tip_0x19113D05": {
      "en": "AC internal circulation fan damaged"
  },
  "dock_tip_0x19113D06": {
      "en": "AC external circulation fan damaged"
  },
  "dock_tip_0x19113D07": {
      "en": "Temperature sensor of AC external circulation supply vent damaged"
  },
  "dock_tip_0x19113D08": {
      "en": "Temperature sensor of AC internal circulation return vent damaged"
  },
  "dock_tip_0x19113D09": {
      "en": "Temperature sensor on the right of AC internal circulation supply vent damaged"
  },
  "dock_tip_0x19113D0A": {
      "en": "Temperature sensor on the left of AC internal circulation supply vent damaged"
  },
  "dock_tip_0x19113D0B": {
      "en": "AC cooler temperature sensor damaged"
  },
  "dock_tip_0x19113D0C": {
      "en": "Temperature sensor of AC internal circulation tube damaged"
  },
  "dock_tip_0x19113D0D": {
      "en": "Dock cover damaged, or unable to close cover properly"
  },
  "dock_tip_0x19113D0E": {
      "en": "AC internal circulation return vent blocked"
  },
  "dock_tip_0x19113D0F": {
      "en": "AC internal circulation supply vent blocked"
  },
  "dock_tip_0x19113D10": {
      "en": "Large temperature difference (≥20° C) between AC internal circulation supply and return vents"
  },
  "dock_tip_0x19113D11": {
      "en": "AC cooler power supply error"
  },
  "dock_tip_0x19113D12": {
      "en": "AC heater power supply error"
  },
  "dock_tip_0x19113D13": {
      "en": "AC external circulation fan power supply error"
  },
  "dock_tip_0x19113D14": {
      "en": "AC internal circulation fan power supply error"
  },
  "dock_tip_0x19113D15": {
      "en": "AC power supply error"
  },
  "dock_tip_0x19113D16": {
      "en": ""
  },
  "dock_tip_0x19113D20": {
      "en": "Temperature sensor of return vent of AC external circulation damaged"
  },
  "dock_tip_0x19113D21": {
      "en": "Temperature sensor of supply vent of AC internal circulation damaged"
  },
  "dock_tip_0x19113D22": {
      "en": "Temperature sensor of AC TEC cold side damaged"
  },
  "dock_tip_0x19113D23": {
      "en": "Large temperature difference between AC external circulation supply vent and return vent (≥10° C)"
  },
  "dock_tip_0x19113D24": {
      "en": "NTC temperature sensor of left driving rod damaged"
  },
  "dock_tip_0x19113D25": {
      "en": "NTC temperature sensor of right driving rod damaged"
  },
  "dock_tip_0x19113D26": {
      "en": "High temperature warning (≥45℃)"
  },
  "dock_tip_0x19113D27": {
      "en": "Low temperature warning (≤-20℃)"
  },
  "dock_tip_0x19113D28": {
      "en": "Temperature sensor of AC external circulation supply vent short-circuited"
  },
  "dock_tip_0x19113D29": {
      "en": "Temperature sensor of AC internal circulation return vent short-circuited"
  },
  "dock_tip_0x19113D2A": {
      "en": "AC cooler temperature sensor short-circuited"
  },
  "dock_tip_0x19113D2B": {
      "en": "Temperature sensor of AC TEC cold side short-circuited"
  },
  "dock_tip_0x19113D2C": {
      "en": "Temperature sensor of AC external circulation return vent short-circuited"
  },
  "dock_tip_0x19113D2D": {
      "en": "Temperature sensor of AC internal circulation supply vent short-circuited"
  },
  "dock_tip_0x19113D2E": {
      "en": "NTC temperature sensor of left driving rod short-circuited"
  },
  "dock_tip_0x19113D2F": {
      "en": "NTC temperature sensor of right driving rod short-circuited"
  },
  "dock_tip_0x19113D60": {
      "en": "AC cooling error"
  },
  "dock_tip_0x19113D61": {
      "en": "AC cooling error"
  },
  "dock_tip_0x19113D62": {
      "en": "AC external circulation fan damaged"
  },
  "dock_tip_0x19113D63": {
      "en": "AC external circulation fan speed error"
  },
  "dock_tip_0x19113D64": {
      "en": "AC internal circulation fan damaged"
  },
  "dock_tip_0x19113D65": {
      "en": "AC internal circulation fan speed error"
  },
  "dock_tip_0x19114000": {
      "en": "Wind speed too high (≥ 9 m/s). Unable to perform task"
  },
  "dock_tip_0x19114800": {
      "en": "Heavy rainfall. Unable to perform task"
  },
  "dock_tip_0x19114801": {
      "en": "Dock security camera power supply error"
  },
  "dock_tip_0x19114802": {
      "en": "DJI Cellular Dongle power supply error"
  },
  "dock_tip_0x19114803": {
      "en": "Power supply error in status indicator on dock cover"
  },
  "dock_tip_0x19114804": {
      "en": "Wind speed gauge heating module power supply error"
  },
  "dock_tip_0x19114805": {
      "en": "Temperature and humidity sensor connection error"
  },
  "dock_tip_0x19114806": {
      "en": "Rainfall gauge connection error"
  },
  "dock_tip_0x19114807": {
      "en": "Rainfall gauge heating module power supply error"
  },
  "dock_tip_0x19114808": {
      "en": "Temperature sensor of rainfall gauge heating module connection error"
  },
  "dock_tip_0x19114809": {
      "en": "Temperature sensor of rainfall gauge heating module overheated"
  },
  "dock_tip_0x1911480A": {
      "en": "Temperature sensor of wind speed gauge heating module damaged"
  },
  "dock_tip_0x1911480B": {
      "en": "Temperature sensor of wind speed gauge heating module overheated"
  },
  "dock_tip_0x1911480C": {
      "en": "Dock cover temperature sensor damaged. Heating module unable to work properly in low-temperature environments"
  },
  "dock_tip_0x1911480D": {
      "en": "Dock cover temperature sensor overheated. Heating module failed to work properly"
  },
  "dock_tip_0x1911480E": {
      "en": "Rainfall gauge connection error"
  },
  "dock_tip_0x1911480F": {
      "en": "Rainfall gauge short-circuited"
  },
  "dock_tip_0x19114810": {
      "en": ""
  },
  "dock_tip_0x19114811": {
      "en": "Hall temperature sensor of wind speed gauge damaged"
  },
  "dock_tip_0x19114812": {
      "en": "Hall temperature sensor of wind speed gauge damaged"
  },
  "dock_tip_0x19114813": {
      "en": "Temperature and humidity sensor self-heating time too long"
  },
  "dock_tip_0x19114814": {
      "en": "Rainfall gauge damaged"
  },
  "dock_tip_0x19114815": {
      "en": "Rainfall gauge damaged"
  },
  "dock_tip_0x1911481A": {
      "en": "Dock cover failed to deice"
  },
  "dock_tip_0x1911481B": {
      "en": "Wind speed gauge power supply error"
  },
  "dock_tip_0x19114C00": {
      "en": "Water immersion detected"
  },
  "dock_tip_0x19115000": {
      "en": "Error occurred and dock restarted"
  },
  "dock_tip_0x19116000": {
      "en": "Battery of dock charging module error"
  },
  "dock_tip_0x19116001": {
      "en": "Battery voltage of dock charging module too high"
  },
  "dock_tip_0x19116002": {
      "en": "Battery voltage of dock charging module too low"
  },
  "dock_tip_0x19116003": {
      "en": "Electric current of dock charging module too large"
  },
  "dock_tip_0x19116004": {
      "en": "Electric current of dock charging module too small"
  },
  "dock_tip_0x19116005": {
      "en": "Battery MOS of dock charging module temperature too high"
  },
  "dock_tip_0x19116006": {
      "en": "Battery MOS of dock charging module temperature too low"
  },
  "dock_tip_0x19116007": {
      "en": "Controller battery of dock charging module error"
  },
  "dock_tip_0x19116008": {
      "en": "Dock charging module connector temperature too high"
  },
  "dock_tip_0x19116009": {
      "en": "Dock charging module connector temperature too low"
  },
  "dock_tip_0x1911600A": {
      "en": "Dock AC battery power supply error"
  },
  "dock_tip_0x1911600B": {
      "en": "Dock AC battery voltage too high"
  },
  "dock_tip_0x1911600C": {
      "en": "Dock AC battery voltage too low"
  },
  "dock_tip_0x1911600D": {
      "en": "Electric current of dock AC power module too large"
  },
  "dock_tip_0x1911600E": {
      "en": "Electric current of dock AC power module too small"
  },
  "dock_tip_0x1911600F": {
      "en": "Dock AC controller module battery error"
  },
  "dock_tip_0x19116010": {
      "en": "Electric current of dock AC controller module too large"
  },
  "dock_tip_0x19116011": {
      "en": "Electric current of dock AC controller module too small"
  },
  "dock_tip_0x19116012": {
      "en": "Battery MOS of dock AC module temperature too high"
  },
  "dock_tip_0x19116013": {
      "en": "Battery MOS of dock AC module temperature too low"
  },
  "dock_tip_0x19116014": {
      "en": "AC module connector temperature too high"
  },
  "dock_tip_0x19116015": {
      "en": "AC module connector temperature too low"
  },
  "dock_tip_0x19116016": {
      "en": "Battery of weather station module error"
  },
  "dock_tip_0x19116017": {
      "en": "Electric current of weather station module too large"
  },
  "dock_tip_0x19116018": {
      "en": "Electric current of weather station module too small"
  },
  "dock_tip_0x1911601B": {
      "en": "Battery of dock image transmission module error"
  },
  "dock_tip_0x1911601F": {
      "en": "Battery voltage of dock internal security camera too high"
  },
  "dock_tip_0x19116020": {
      "en": "Battery voltage of dock internal security camera too low"
  },
  "dock_tip_0x19116021": {
      "en": "Battery voltage of right dock cover too high"
  },
  "dock_tip_0x19116022": {
      "en": "Battery voltage of right dock cover too low"
  },
  "dock_tip_0x19116023": {
      "en": "Dock control module temperature too high"
  },
  "dock_tip_0x19116024": {
      "en": "Dock control module temperature too low"
  },
  "dock_tip_0x19117000": {
      "en": "Failed to recharge aircraft battery"
  },
  "dock_tip_0x19117021": {
      "en": "Dock cover moving. Charging aircraft battery failed"
  },
  "dock_tip_0x19117022": {
      "en": "Updating dock. Charging aircraft battery failed"
  },
  "dock_tip_0x19117023": {
      "en": "No aircraft found"
  },
  "dock_tip_0x19117024": {
      "en": "Metal foreign objects detected in dock charging module. Charging aircraft battery failed"
  },
  "dock_tip_0x19117025": {
      "en": "Dock cover opened. Charging aircraft battery failed"
  },
  "dock_tip_0x19117041": {
      "en": "Aircraft not connected"
  },
  "dock_tip_0x19117043": {
      "en": "Aircraft battery level critically low. Unable to power on aircraft"
  },
  "dock_tip_0x19117045": {
      "en": "Aircraft battery error. Unable to charge battery or power on aircraft"
  },
  "dock_tip_0x19117046": {
      "en": "Aircraft battery communication error"
  },
  "dock_tip_0x19117047": {
      "en": "Aircraft battery level high. No battery charging required"
  },
  "dock_tip_0x19117048": {
      "en": "Aircraft wireless charging module error"
  },
  "dock_tip_0x19117061": {
      "en": "Waking aircraft charging module failed"
  },
  "dock_tip_0x19117202": {
      "en": "Powering on aircraft timed out"
  },
  "dock_tip_0x19117221": {
      "en": "Failed to connect dock to aircraft Bluetooth"
  },
  "dock_tip_0x19117222": {
      "en": "Dock Bluetooth link error"
  },
  "dock_tip_0x19117223": {
      "en": "Aircraft Bluetooth signal weak"
  },
  "dock_tip_0x19117321": {
      "en": "Aircraft image transmission link error. Powering off failed"
  },
  "dock_tip_0x19117322": {
      "en": "Powering off aircraft timed out"
  },
  "dock_tip_0x19117460": {
      "en": "Battery voltage of dock charging module too low"
  },
  "dock_tip_0x19117461": {
      "en": "Electric current of dock charging module too large"
  },
  "dock_tip_0x19117462": {
      "en": "Aircraft charging module coil overcurrent"
  },
  "dock_tip_0x19117463": {
      "en": "Aircraft charging module error"
  },
  "dock_tip_0x19117464": {
      "en": "Battery voltage of aircraft charging module too high"
  },
  "dock_tip_0x19117465": {
      "en": "Battery voltage of dock charging module too high"
  },
  "dock_tip_0x19117470": {
      "en": "Aircraft charging module error"
  },
  "dock_tip_0x19117480": {
      "en": "Dock charging module temperature too high"
  },
  "dock_tip_0x19117481": {
      "en": "Electric current of dock charging module too large"
  },
  "dock_tip_0x191174A0": {
      "en": "Temperature of dock charging module coil too high"
  },
  "dock_tip_0x191174B0": {
      "en": "Temperature of dock wireless charging module error"
  },
  "dock_tip_0x191174B1": {
      "en": "Temperature sensor of dock charging module coil not connected"
  },
  "dock_tip_0x191174B2": {
      "en": "Temperature sensor of dock charging module coil error"
  },
  "dock_tip_0x191174C0": {
      "en": "Temperature of aircraft charging module coil too high"
  },
  "dock_tip_0x191174D0": {
      "en": "Temperature of aircraft wireless charging module error"
  },
  "dock_tip_0x191174D1": {
      "en": "Temperature sensor of aircraft charging module coil not connected"
  },
  "dock_tip_0x191174D2": {
      "en": "Temperature sensor of aircraft charging module coil error"
  },
  "fpv_tip_0x11000002": {
      "en": "Aircraft power distribution board 1 overheated. Return to home or land promptly"
  },
  "fpv_tip_0x11000003": {
      "en": "Aircraft power distribution board 2 overheated. Return to home or land promptly"
  },
  "fpv_tip_0x11000020": {
      "en": "Gimbal %index payload voltage too high"
  },
  "fpv_tip_0x11000021": {
      "en": "Gimbal %index payload voltage too low"
  },
  "fpv_tip_0x11000022": {
      "en": "Gimbal %index battery module overheated"
  },
  "fpv_tip_0x11000023": {
      "en": "Gimbal %index battery module temperature too low"
  },
  "fpv_tip_0x11000024": {
      "en": "Total payload power too high"
  },
  "fpv_tip_0x11000025": {
      "en": "Gimbal %index payload transmission error"
  },
  "fpv_tip_0x11000026": {},
  "fpv_tip_0x11000029": {
      "en": "PSDK voltage too high"
  },
  "fpv_tip_0x1100002A": {
      "en": "PSDK voltage too low"
  },
  "fpv_tip_0x1100002B": {
      "en": "PSDK battery module overheated"
  },
  "fpv_tip_0x1100002C": {
      "en": "PSDK battery module temperature too low"
  },
  "fpv_tip_0x1100002D": {
      "en": "PSDK device rated power exceeded. Restarting..."
  },
  "fpv_tip_0x1100002E": {
      "en": "PSDK device rated power exceeded. Failed to restart"
  },
  "fpv_tip_0x11000034": {
      "en": "Landing gear motor power supply error. Restart aircraft"
  },
  "fpv_tip_0x11000035": {
      "en": "Radar power supply error. Restarting radar recommended"
  },
  "fpv_tip_0x11000036": {
      "en": "4G module power supply error. Remove and insert module again"
  },
  "fpv_tip_0x11000037": {
      "en": "Gimbal power supply error. Detach and remount gimbal"
  },
  "fpv_tip_0x11000038": {
      "en": "Left battery connector overheated. Return to home or land promptly"
  },
  "fpv_tip_0x11000039": {
      "en": "Right battery connector overheated. Return to home or land promptly"
  },
  "fpv_tip_0x1100003A": {
      "en": "Left battery not properly installed. Remove and reinstall battery"
  },
  "fpv_tip_0x1100003B": {
      "en": "Right battery not properly installed. Remove and reinstall battery"
  },
  "fpv_tip_0x1100003C": {
      "en": "Gimbal not properly installed. Detach and remount gimbal"
  },
  "fpv_tip_0x1100003D": {
      "en": "Aircraft center board overheated. Return to home or land promptly"
  },
  "fpv_tip_0x1100003E": {
      "en": "Radar disconnected. Fly with caution and return to home or land promptly"
  },
  "fpv_tip_0x1100003F": {
      "en": "Downward infrared sensing system connection error. Restart aircraft"
  },
  "fpv_tip_0x11000040": {
      "en": "Landing gear motor communication error. Restart aircraft"
  },
  "fpv_tip_0x11000041": {
      "en": "Landing gear motor stalled. Make sure motor is clear of obstacles and restart aircraft"
  },
  "fpv_tip_0x11000042": {
      "en": "Large current difference between batteries. Maintain smooth flight and land promptly"
  },
  "fpv_tip_0x11000043": {
      "en": "Motor overheated. Do not transform aircraft frequently"
  },
  "fpv_tip_0x11000044": {
      "en": "Motor overheated. Transforming aircraft stopped. Wait until motor cools down"
  },
  "fpv_tip_0x11000045": {
      "en": "Temperature too low. Landing gear stuck"
  },
  "fpv_tip_0x11000046": {
      "en": "Aircraft screw rod reaching maximum life span. To ensure flight safety, contact DJI Support and send aircraft back to DJI for maintenance"
  },
  "fpv_tip_0x11000047": {
      "en": "Aircraft screw rod exceeded maximum life span. To ensure flight safety, contact DJI Support immediately and send aircraft back to DJI for maintenance. You are responsible for the maintenance cost for the flight accident caused by continued use of the aircraft"
  },
  "fpv_tip_0x11000048": {
      "en": "Landing gear error. Obstacle avoidance performance degraded. Fly with caution. Pay attention to lens when aircraft is landing. Contact DJI Support"
  },
  "fpv_tip_0x11020030": {
      "en": "%battery_index battery cover temperature error. Switched to single-battery flight. Return to home or land promptly"
  },
  "fpv_tip_0x11020031": {
      "en": "%battery_index battery connector temperature error. Switched to single-battery flight. Return to home or land promptly"
  },
  "fpv_tip_0x11020032": {
      "en": "%battery_index battery temperature error. Switched to single-battery flight. Return to home or land promptly"
  },
  "fpv_tip_0x11020033": {
      "en": "%battery_index battery error. Switched to single-battery flight. Return to home or land promptly"
  },
  "fpv_tip_0x110B0001": {
      "en": "%battery_index Battery overcurrent when discharging. Maintain smooth flight and check if aircraft is overloaded"
  },
  "fpv_tip_0x110B0002": {
      "en": "%battery_index Battery overheated. Return to home promptly and wait until battery cools down"
  },
  "fpv_tip_0x110B0003": {
      "en": "%battery_index Battery temperature too low. Warm up battery to 5°C or higher"
  },
  "fpv_tip_0x110B0004": {
      "en": "%battery_index Battery short-circuited during discharge. Replace battery"
  },
  "fpv_tip_0x110B0005": {
      "en": "Battery %index cell voltage low. Replace battery"
  },
  "fpv_tip_0x110B0006": {
      "en": "%battery_index Battery cell damaged. Stop using battery and contact DJI Support"
  },
  "fpv_tip_0x110B0007": {
      "en": "%battery_index Battery auto-check failed. Stop using battery and contact DJI Support"
  },
  "fpv_tip_0x110B0008": {
      "en": "%battery_index Battery auto-check failed. Stop using battery and contact DJI Support"
  },
  "fpv_tip_0x110B0009": {
      "en": "%battery_index Battery auto-check failed. Stop using battery and contact DJI Support"
  },
  "fpv_tip_0x110B000A": {
      "en": "%battery_index Battery auto-check failed. Stop using battery and contact DJI Support"
  },
  "fpv_tip_0x110B000B": {
      "en": "%battery_index Battery damaged. Stop using battery and contact DJI Support"
  },
  "fpv_tip_0x110B000C": {
      "en": "%battery_index Battery maintenance required"
  },
  "fpv_tip_0x110B000D": {
      "en": "%battery_index Battery damaged. Stop using battery and contact DJI Support"
  },
  "fpv_tip_0x110B000E": {
      "en": "Battery %index self-discharged during storage"
  },
  "fpv_tip_0x110B000F": {
      "en": "%battery_index Battery capacity significantly reduced. Continue using battery may cause safety risks"
  },
  "fpv_tip_0x110B0010": {
      "en": "Safety requirements not met. Dispose of %battery_index Battery properly"
  },
  "fpv_tip_0x110B0011": {
      "en": "%battery_index Battery data communication error. Reinstall battery. Replace battery if issue persists"
  },
  "fpv_tip_0x110B0012": {
      "en": "%battery_index Battery maintenance required"
  },
  "fpv_tip_0x110B0013": {
      "en": "Battery not detected in %battery_index Battery slot. Insert or replace battery"
  },
  "fpv_tip_0x110B0013_in_the_sky": {
      "en": "Battery not detected in %battery_index Battery slot. Return to home or land promptly"
  },
  "fpv_tip_0x110B0014": {
      "en": "%battery_index Battery self-heating"
  },
  "fpv_tip_0x110B0015": {
      "en": "%battery_index Battery maintenance required to ensure flight safety"
  },
  "fpv_tip_0x110B0016": {
      "en": "%battery_index Battery maintenance required to ensure flight safety"
  },
  "fpv_tip_0x110B0017": {
      "en": "%battery_index Battery maintenance required to ensure flight safety"
  },
  "fpv_tip_0x110B0018": {
      "en": "%battery_index Battery maintenance required to ensure flight safety"
  },
  "fpv_tip_0x110B0019": {
      "en": "%battery_index Battery maintenance required to ensure flight safety"
  },
  "fpv_tip_0x110B001A": {
      "en": "%battery_index Battery maintenance required to ensure flight safety"
  },
  "fpv_tip_0x110B001B": {
      "en": "%battery_index Battery maintenance required to ensure flight safety"
  },
  "fpv_tip_0x110B001C": {
      "en": "Batteries do not match. Replace with matching batteries"
  },
  "fpv_tip_0x110B001D": {
      "en": "%battery_index Battery discharge error. Return to home or land promptly"
  },
  "fpv_tip_0x110B001E": {
      "en": "%battery_index Battery connection error. Return to home or land promptly"
  },
  "fpv_tip_0x110B007": {
      "en": "Battery %d% auto check failed. Replace battery and contact DJI Support"
  },
  "fpv_tip_0x110B0403": {
      "en": "Incompatible battery firmware versions. Update firmware"
  },
  "fpv_tip_0x110b001e": {
      "en": "%battery_index Battery connection error. Return to home or land promptly"
  },
  "fpv_tip_0x12000000": {
      "en": "Battery Station not activated"
  },
  "fpv_tip_0x12000001": {
      "en": "Battery Station auto check error"
  },
  "fpv_tip_0x12000002": {
      "en": "Battery Station expansion card communication error"
  },
  "fpv_tip_0x12010000": {
      "en": "Power Supply Module Not Recognized"
  },
  "fpv_tip_0x12010001": {
      "en": "Battery module input voltage too high"
  },
  "fpv_tip_0x12010002": {
      "en": "Battery module input voltage too low"
  },
  "fpv_tip_0x12010003": {
      "en": "Battery module output voltage error"
  },
  "fpv_tip_0x12010004": {
      "en": "Battery module output current error"
  },
  "fpv_tip_0x12010005": {
      "en": "Battery module short-circuited"
  },
  "fpv_tip_0x12010006": {
      "en": "Battery module communication error"
  },
  "fpv_tip_0x12010007": {
      "en": "Battery module fan malfunctioned"
  },
  "fpv_tip_0x12010008": {
      "en": "Battery module temperature too high"
  },
  "fpv_tip_0x12020000": {
      "en": "Battery port %index battery communication error"
  },
  "fpv_tip_0x12020001": {
      "en": "Battery port %index battery temperature too low"
  },
  "fpv_tip_0x12020002": {
      "en": "Battery port %index battery overheated"
  },
  "fpv_tip_0x12020003": {
      "en": "Battery port %index battery cell voltage too high"
  },
  "fpv_tip_0x12020004": {
      "en": "Battery port %index battery system error"
  },
  "fpv_tip_0x12020005": {
      "en": "Battery port %index battery overcurrent during charging"
  },
  "fpv_tip_0x12020006": {
      "en": "Battery port %index battery voltage too high"
  },
  "fpv_tip_0x12020007": {
      "en": "Battery port %index battery voltage too high during charging"
  },
  "fpv_tip_0x12020008": {
      "en": "Battery port %index battery voltage too low during charging"
  },
  "fpv_tip_0x12020009": {
      "en": "Battery port %index battery permanently damaged"
  },
  "fpv_tip_0x1202000A": {
      "en": "Battery port %index battery short-circuited"
  },
  "fpv_tip_0x1202000B": {
      "en": "Battery port %index battery charging taking too long"
  },
  "fpv_tip_0x1202000C": {
      "en": "No current detected for battery port %index battery during charging"
  },
  "fpv_tip_0x1202000D": {
      "en": "Battery port %index battery type not recognized"
  },
  "fpv_tip_0x12030000": {
      "en": "Battery Station motherboard overheated"
  },
  "fpv_tip_0x12030001": {
      "en": "Battery Station motherboard voltage too high"
  },
  "fpv_tip_0x12030002": {
      "en": "Battery Station motherboard voltage too low"
  },
  "fpv_tip_0x12030003": {
      "en": "Battery Station port %index error"
  },
  "fpv_tip_0x12030004": {
      "en": "AC Input Voltage Drop Too Large"
  },
  "fpv_tip_0x12030005": {
      "en": "Oil Maintenance Tips"
  },
  "fpv_tip_0x12030006": {
      "en": "Charging Device Controller Board Output Voltage Error"
  },
  "fpv_tip_0x12030007": {
      "en": "Controller Board of Charging Device Input Voltage Error"
  },
  "fpv_tip_0x12120000": {
      "en": "Battery port %index remote controller battery communication error"
  },
  "fpv_tip_0x12120001": {
      "en": "Battery port %index remote controller battery temperature error"
  },
  "fpv_tip_0x12120002": {
      "en": "Battery port %index remote controller battery charging error"
  },
  "fpv_tip_0x14010031": {
      "en": "Payload infusion data error. Restart payload"
  },
  "fpv_tip_0x14010032": {
      "en": "Unable to converge payload infusion data"
  },
  "fpv_tip_0x14010033": {
      "en": "Payload system time error. Restart aircraft"
  },
  "fpv_tip_0x14010034": {
      "en": "Payload tachometer data invalid. Restart payload"
  },
  "fpv_tip_0x14010035": {
      "en": "Payload tachometer data not updated. Restart payload"
  },
  "fpv_tip_0x14010036": {
      "en": "No payload tachometer data. Restart payload"
  },
  "fpv_tip_0x14010037": {
      "en": "Payload tachometer data exceeds limit. Restart payload"
  },
  "fpv_tip_0x14010038": {
      "en": "Payload tachometer data error. Restart payload"
  },
  "fpv_tip_0x14010039": {
      "en": "Payload accelerometer data invalid. Restart payload"
  },
  "fpv_tip_0x1401003A": {
      "en": "Payload accelerometer data not updated. Restart payload"
  },
  "fpv_tip_0x1401003B": {
      "en": "No payload accelerometer data. Restart payload"
  },
  "fpv_tip_0x1401003C": {
      "en": "Payload accelerometer data exceeds limit. Restart payload"
  },
  "fpv_tip_0x1401003D": {
      "en": "Payload accelerometer data error. Restart payload"
  },
  "fpv_tip_0x1401003E": {
      "en": "Payload RTK data calculation error. Restart aircraft"
  },
  "fpv_tip_0x1401003F": {
      "en": "Payload direction data error. Check satellite signal"
  },
  "fpv_tip_0x14010040": {
      "en": "Payload RTK data error. Restart aircraft"
  },
  "fpv_tip_0x14010041": {
      "en": "Payload RTK time error. Restart aircraft"
  },
  "fpv_tip_0x14010042": {
      "en": "Payload RTK data invalid. Restart aircraft"
  },
  "fpv_tip_0x14010042_in_the_sky": {
      "en": "Payload RTK data invalid. Make sure satellite signal searching is normal and wait until RTK is fixed"
  },
  "fpv_tip_0x14010043": {
      "en": "Payload IMU warming up. Wait until IMU finished warming up to continue"
  },
  "fpv_tip_0x14010044": {
      "en": "Payload IMU temperature control processor error. Restart payload"
  },
  "fpv_tip_0x14010045": {
      "en": "Payload IMU overheated. Restart payload"
  },
  "fpv_tip_0x14010046": {
      "en": "Payload IMU temperature too low. Restart payload"
  },
  "fpv_tip_0x14010047": {
      "en": "Payload processor overheated. Return to home or land promptly. Remove payload and wait for it to cool down before use"
  },
  "fpv_tip_0x14010047_in_the_sky": {
      "en": "Payload processor overheated. Return to home or land promptly. Remove payload and wait for it to cool down before use"
  },
  "fpv_tip_0x14010048": {
      "en": "Payload fan error. Check whether the fan is stalled"
  },
  "fpv_tip_0x14010048_in_the_sky": {
      "en": "Payload fan error. Return to home or land and check whether the fan is stalled"
  },
  "fpv_tip_0x14010049": {
      "en": "Payload PPS Data Error"
  },
  "fpv_tip_0x1401004A": {
      "en": "Payload UTC Time Error"
  },
  "fpv_tip_0x14020000": {
      "en": "ASIC芯片温度异常"
  },
  "fpv_tip_0x14020001": {
      "en": "主控芯片温度异常"
  },
  "fpv_tip_0x14020002": {
      "en": "看门狗超时"
  },
  "fpv_tip_0x14020003": {
      "en": "ASIC芯片错误"
  },
  "fpv_tip_0x14020004": {
      "en": "栈溢出"
  },
  "fpv_tip_0x14020005": {
      "en": "MIPI故障"
  },
  "fpv_tip_0x14020007": {
      "en": "脏污预警"
  },
  "fpv_tip_0x14020008": {
      "en": ""
  },
  "fpv_tip_0x14020031": {
      "en": "Payload infusion data error. Restart payload"
  },
  "fpv_tip_0x14020032": {
      "en": "Unable to converge payload infusion data"
  },
  "fpv_tip_0x14020033": {
      "en": "Payload system time error. Restart aircraft"
  },
  "fpv_tip_0x14020034": {
      "en": "Payload tachometer data invalid. Restart payload"
  },
  "fpv_tip_0x14020035": {
      "en": "Payload tachometer data not updated. Restart payload"
  },
  "fpv_tip_0x14020036": {
      "en": "No payload tachometer data. Restart payload"
  },
  "fpv_tip_0x14020037": {
      "en": "Payload tachometer data exceeds limit. Restart payload"
  },
  "fpv_tip_0x14020038": {
      "en": "Payload tachometer data error. Restart payload"
  },
  "fpv_tip_0x14020039": {
      "en": "Payload accelerometer data invalid. Restart payload"
  },
  "fpv_tip_0x1402003A": {
      "en": "Payload accelerometer data not updated. Restart payload"
  },
  "fpv_tip_0x1402003B": {
      "en": "No payload accelerometer data. Restart payload"
  },
  "fpv_tip_0x1402003C": {
      "en": "Payload accelerometer data exceeds limit. Restart payload"
  },
  "fpv_tip_0x1402003D": {
      "en": "Payload accelerometer data error. Restart payload"
  },
  "fpv_tip_0x1402003E": {
      "en": "Payload RTK data calculation error. Restart aircraft"
  },
  "fpv_tip_0x14020040": {
      "en": "Payload RTK data error. Restart aircraft"
  },
  "fpv_tip_0x14020041": {
      "en": "Payload RTK time error. Restart aircraft"
  },
  "fpv_tip_0x14020042": {
      "en": "Payload RTK data invalid. Restart aircraft"
  },
  "fpv_tip_0x14020042_in_the_sky": {
      "en": "Payload RTK data invalid. Make sure satellite signal searching is normal and wait until RTK is fixed"
  },
  "fpv_tip_0x14030000": {
      "en": "扫描模块温度异常"
  },
  "fpv_tip_0x14030001": {
      "en": "扫描模块电流异常"
  },
  "fpv_tip_0x14030002": {
      "en": "Rotation speed error of LiDAR scanning module. Restart aircraft"
  },
  "fpv_tip_0x14030003": {
      "en": "Auto Check error of LiDAR scanning module. Restart aircraft"
  },
  "fpv_tip_0x14030004": {},
  "fpv_tip_0x14030005": {},
  "fpv_tip_0x14030006": {},
  "fpv_tip_0x14030008": {},
  "fpv_tip_0x1403000B": {
      "en": "Calibrating LiDAR scanning module. Do not move aircraft"
  },
  "fpv_tip_0x1403000D": {},
  "fpv_tip_0x1403000E": {},
  "fpv_tip_0x1403000F": {},
  "fpv_tip_0x14030010": {},
  "fpv_tip_0x14030011": {},
  "fpv_tip_0x14030012": {},
  "fpv_tip_0x14030013": {},
  "fpv_tip_0x14030014": {},
  "fpv_tip_0x14030015": {},
  "fpv_tip_0x14030016": {},
  "fpv_tip_0x14030017": {},
  "fpv_tip_0x14030018": {},
  "fpv_tip_0x14030019": {},
  "fpv_tip_0x14040000": {
      "en": "接收器高压异常"
  },
  "fpv_tip_0x14040001": {
      "en": "接收器温度异常"
  },
  "fpv_tip_0x14040002": {
      "en": "激光器温度异常"
  },
  "fpv_tip_0x14040003": {
      "en": ""
  },
  "fpv_tip_0x14050000": {
      "en": "PPS同步异常"
  },
  "fpv_tip_0x14050001": {
      "en": ""
  },
  "fpv_tip_0x14050002": {
      "en": "PPS同步异常"
  },
  "fpv_tip_0x14050003": {
      "en": "时间同步精度低"
  },
  "fpv_tip_0x14050005": {
      "en": ""
  },
  "fpv_tip_0x14050006": {
      "en": ""
  },
  "fpv_tip_0x14060000": {
      "en": "NIR Illumination power error and disabled. Re-enable this function or contact DJI Support"
  },
  "fpv_tip_0x14810040": {
      "en": "LiDAR temperature too high/low"
  },
  "fpv_tip_0x14810080": {
      "en": "LiDAR voltage too high/low"
  },
  "fpv_tip_0x148100C0": {},
  "fpv_tip_0x148100c0": {
      "en": "LiDAR motor error"
  },
  "fpv_tip_0x14810100": {
      "en": "LiDAR lifecycle warning"
  },
  "fpv_tip_0x14810140": {
      "en": "LiDAR system error"
  },
  "fpv_tip_0x15000020": {
      "en": "Millimeter-wave radar temperature too low. Check if ambient temperature is too low"
  },
  "fpv_tip_0x15000021": {
      "en": "Millimeter-wave radar overheated. Check if ambient temperature is too high"
  },
  "fpv_tip_0x15010020": {
      "en": "Millimeter-wave radar internal power supply error. Restart radar"
  },
  "fpv_tip_0x15010021": {
      "en": "Millimeter-wave radar internal power supply error. Restart radar"
  },
  "fpv_tip_0x15010022": {
      "en": "Millimeter-wave radar internal power supply error. Restart radar"
  },
  "fpv_tip_0x15010023": {
      "en": "Millimeter-wave radar internal power supply error. Restart radar"
  },
  "fpv_tip_0x15020020": {
      "en": "Millimeter-wave radar motor error. Check whether radar is stalled"
  },
  "fpv_tip_0x15020021": {
      "en": "Millimeter-wave radar motor error. Check whether radar is stalled"
  },
  "fpv_tip_0x15020022": {
      "en": "Millimeter-wave radar motor error. Check whether radar is stalled"
  },
  "fpv_tip_0x15020023": {
      "en": "Millimeter-wave radar sensing error. Check firmware version"
  },
  "fpv_tip_0x15030020": {
      "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15030021": {
      "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15030022": {
      "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15030023": {
      "en": "Starting millimeter-wave radar timed out. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15030024": {
      "en": "Millimeter-wave radar communication link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15030025": {
      "en": "Millimeter-wave radar communication link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15030026": {
      "en": "Millimeter-wave radar communication link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15030028": {
      "en": "%index radar cable error. Obstacle avoidance and altitude stabilization unavailable"
  },
  "fpv_tip_0x15040020": {
      "en": "Millimeter-wave radar motor error. Check whether radar is stalled"
  },
  "fpv_tip_0x15040021": {
      "en": "Millimeter-wave radar motor error. Check whether radar is stalled"
  },
  "fpv_tip_0x15060020": {
      "en": "Millimeter-wave radar motor error. Check whether radar is stalled"
  },
  "fpv_tip_0x15070020": {
      "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15080020": {
      "en": "Millimeter-wave radar overheated. Check if ambient temperature is too high"
  },
  "fpv_tip_0x15080021": {
      "en": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected"
  },
  "fpv_tip_0x15080022": {
      "en": "Millimeter-wave radar processor overheated. Obstacle avoidance and altitude stabilization affected"
  },
  "fpv_tip_0x15090020": {
      "en": "Millimeter-wave radar overheated. Check if ambient temperature is too high"
  },
  "fpv_tip_0x15090021": {
      "en": "Millimeter-wave radar error. Obstacle sensing unavailable"
  },
  "fpv_tip_0x15100020": {
      "en": "Millimeter-wave radar communication link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15100021": {
      "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15100022": {
      "en": "Millimeter-wave radar link unstable. Check if there is strong interference in the surrounding area"
  },
  "fpv_tip_0x15110020": {
      "en": "Millimeter-wave radar motor error. Check whether radar is stalled"
  },
  "fpv_tip_0x15130021": {
      "en": "Millimeter-wave radar sensing error. Check firmware version"
  },
  "fpv_tip_0x15140020": {
      "en": "Millimeter-wave radar RF clock error. Return to home promptly and check radar"
  },
  "fpv_tip_0x15220020": {
      "en": "Backward and downward radar disconnected. Restart aircraft or check and replace radar"
  },
  "fpv_tip_0x15300020": {
      "en": "Millimeter-wave radar sensing error. Check firmware version"
  },
  "fpv_tip_0x15300021": {
      "en": "Millimeter-wave radar sensing error. Check firmware version"
  },
  "fpv_tip_0x15300022": {
      "en": "Millimeter-wave radar sensing error. Check firmware version"
  },
  "fpv_tip_0x16000001": {
      "en": "Flight controller overloaded. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x16000001_in_the_sky": {
      "en": "Flight controller overloaded. If the issue persists, land aircraft promptly"
  },
  "fpv_tip_0x16000002": {
      "en": "Flight data recording error. Contact your local dealer or DJI Support"
  },
  "fpv_tip_0x16000002_in_the_sky": {
      "en": "Flight data record abnorma.If the warning persists, contact your local dealer or DJI Support."
  },
  "fpv_tip_0x16010001": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010001_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010005": {
      "en": "Sensor system initializing. Wait for initialization to complete"
  },
  "fpv_tip_0x16010005_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010007": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010007_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x1601000A": {
      "en": "Sensor system initializing. Wait for initialization to complete"
  },
  "fpv_tip_0x1601000A_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x1601000D": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1601000D_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010010": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010010_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010013": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010013_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010016": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010016_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010019": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010019_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x1601001C": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1601001C_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x1601001F": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1601001F_in_the_sky": {
      "en": "Not Applicable. This string has been deleted"
  },
  "fpv_tip_0x16010022": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010022_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010025": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010025_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010026": {
      "en": "Unable to take off. Non-DJI RTK module detected"
  },
  "fpv_tip_0x16010026_in_the_sky": {
      "en": "Non-DJI RTK module detected"
  },
  "fpv_tip_0x16010028": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010028_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x1601002B": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1601002B_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x1601002F": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1601002F_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010032": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010032_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010033": {
      "en": "Vision positioning detection poor. Fly with caution"
  },
  "fpv_tip_0x16010034": {
      "en": "GNSS detection poor. Fly with caution"
  },
  "fpv_tip_0x16010035": {
      "en": "Positioning quality poor. Fly with caution"
  },
  "fpv_tip_0x16010036": {
      "en": "Vision positioning detection poor. Fly with caution"
  },
  "fpv_tip_0x16010037": {
      "en": "GNSS detection poor. Fly with caution"
  },
  "fpv_tip_0x16010038": {
      "en": "Positioning quality poor. Fly with caution"
  },
  "fpv_tip_0x16010039": {
      "en": "No GNSS available. Wait for searching satellite signal. Fly with caution"
  },
  "fpv_tip_0x16010041": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16010041_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16010042": {
      "en": "Severe error in aircraft positioning system. Aircraft will automatically land soon. Fly aircraft to open area promptly"
  },
  "fpv_tip_0x16010050": {
      "en": ""
  },
  "fpv_tip_0x16020001": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16020001_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16020004": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16020004_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16020007": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16020007_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x1602000A": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x1602000A_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x1602000D": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x1602000D_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16020016": {
      "en": "IMU bias too large. Calibrate IMU"
  },
  "fpv_tip_0x16020016_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16020027": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16020027_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x1602002A": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x1602002A_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x1602002B": {
      "en": "IMU constant temperature system error. Restart aircraft"
  },
  "fpv_tip_0x16020620": {
      "en": "RTH canceled. Control stick pushed down"
  },
  "fpv_tip_0x16020621": {
      "en": "RTH canceled. Obstacle detected"
  },
  "fpv_tip_0x16020622": {
      "en": "RTH canceled. Strong wind or aircraft approaching GEO Zone"
  },
  "fpv_tip_0x16020623": {
      "en": "GNSS error. RTH canceled"
  },
  "fpv_tip_0x16030001": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16030001_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16030004": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16030004_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16030007": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16030007_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x1603000A": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x1603000A_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x1603000D": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x1603000D_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16030010": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16030010_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16030013": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16030013_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16030016": {
      "en": "IMU bias too large. Calibrate IMU"
  },
  "fpv_tip_0x16030016_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x1603001C": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x1603001C_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x1603001D": {
      "en": "Abnormal aircraft vibration. Return to home or land promptly"
  },
  "fpv_tip_0x16040001": {
      "en": "Barometer error. Restart aircraft"
  },
  "fpv_tip_0x16040001_in_the_sky": {
      "en": "Barometer error. Return to home or land"
  },
  "fpv_tip_0x16040004": {
      "en": "Barometer error. Restart aircraft"
  },
  "fpv_tip_0x16040004_in_the_sky": {
      "en": "Barometer error. Return to home or land"
  },
  "fpv_tip_0x16040007": {
      "en": "Barometer error. Restart aircraft"
  },
  "fpv_tip_0x16040007_in_the_sky": {
      "en": "Barometer error. Return to home or land"
  },
  "fpv_tip_0x1604000A": {
      "en": "Barometer error. Restart aircraft"
  },
  "fpv_tip_0x1604000A_in_the_sky": {
      "en": "Barometer error. Return to home or land"
  },
  "fpv_tip_0x16040010": {
      "en": "Barometer error. Restart aircraft"
  },
  "fpv_tip_0x16040010_in_the_sky": {
      "en": "Barometer error. Return to home or land"
  },
  "fpv_tip_0x16040013": {
      "en": "Barometer error. Restart aircraft"
  },
  "fpv_tip_0x16040013_in_the_sky": {
      "en": "Barometer error. Return to home or land"
  },
  "fpv_tip_0x16040016": {
      "en": "Barometer error. Restart aircraft"
  },
  "fpv_tip_0x16040016_in_the_sky": {
      "en": "Barometer error. Return to home or land"
  },
  "fpv_tip_0x16040019": {
      "en": "Barometer error. Restart aircraft"
  },
  "fpv_tip_0x16040019_in_the_sky": {
      "en": "Barometer error. Return to home or land"
  },
  "fpv_tip_0x16050001": {
      "en": "GNSS error. Restart aircraft"
  },
  "fpv_tip_0x16050001_in_the_sky": {
      "en": "GNSS error. Return to home or land"
  },
  "fpv_tip_0x16050004": {
      "en": "GNSS error. Restart aircraft"
  },
  "fpv_tip_0x16050004_in_the_sky": {
      "en": "GNSS error. Return to home or land"
  },
  "fpv_tip_0x16050019": {
      "en": "GNSS error. Restart aircraft"
  },
  "fpv_tip_0x16050019_in_the_sky": {
      "en": "GNSS error. Return to home or land"
  },
  "fpv_tip_0x1605001C": {
      "en": "GNSS error. Restart aircraft"
  },
  "fpv_tip_0x1605001C_in_the_sky": {
      "en": "GNSS error. Return to home or land"
  },
  "fpv_tip_0x1605001F": {
      "en": "GNSS signal interfered. Fly with caution"
  },
  "fpv_tip_0x16050020": {
      "en": "Strong GNSS interference. Aircraft returning to home"
  },
  "fpv_tip_0x16050021": {
      "en": "RTK not converged. Place aircraft in an open area and start task after RTK converged"
  },
  "fpv_tip_0x16050022": {
      "en": "Large errors in GNSS positioning. There may be potential safety risks. Switch to A mode manually"
  },
  "fpv_tip_0x1605002B": {
      "en": "Only BDS data used. Number of searched satellites may be affected"
  },
  "fpv_tip_0x16060001": {
      "en": "Compass error. Restart aircraft"
  },
  "fpv_tip_0x16060001_in_the_sky": {
      "en": "Compass error. Return to home or land"
  },
  "fpv_tip_0x16060007": {
      "en": "Compass error. Restart aircraft"
  },
  "fpv_tip_0x16060007_in_the_sky": {
      "en": "Compass error. Return to home or land"
  },
  "fpv_tip_0x1606000A": {
      "en": "Compass error. Restart aircraft"
  },
  "fpv_tip_0x1606000A_in_the_sky": {
      "en": "Compass error. Return to home or land"
  },
  "fpv_tip_0x1606000D": {
      "en": "Compass interference. Calibrate compass"
  },
  "fpv_tip_0x1606000D_in_the_sky": {
      "en": "Compass interference. Move aircraft away from interference source"
  },
  "fpv_tip_0x16060010": {
      "en": "Compass interference. Calibrate compass"
  },
  "fpv_tip_0x16060010_in_the_sky": {
      "en": "Compass interference. Move aircraft away from interference source"
  },
  "fpv_tip_0x16070001": {
      "en": "Flight controller overheats. Returning to home..."
  },
  "fpv_tip_0x16070002": {
      "en": "Flight controller overheats. Returning to home..."
  },
  "fpv_tip_0x16070003": {
      "en": "Flight controller overheats. Auto landing..."
  },
  "fpv_tip_0x16070020": {
      "en": "RTK data not received by aircraft"
  },
  "fpv_tip_0x16070021": {
      "en": "RTK data error. Restart aircraft"
  },
  "fpv_tip_0x16070022": {
      "en": ""
  },
  "fpv_tip_0x16070023": {
      "en": "RTK data refresh frequency error"
  },
  "fpv_tip_0x16070024": {
      "en": "RTK data error"
  },
  "fpv_tip_0x16070025": {
      "en": "Unable to refresh RTK position data"
  },
  "fpv_tip_0x16070026": {
      "en": "Sudden RTK position data change"
  },
  "fpv_tip_0x16070027": {
      "en": "RTK and sensor altitude data inconsistent"
  },
  "fpv_tip_0x16070028": {
      "en": "RTK and GNSS positioning do not match"
  },
  "fpv_tip_0x16070029": {
      "en": "RTK position data changed slightly when aircraft is stationary"
  },
  "fpv_tip_0x16070030": {
      "en": "RTK firmware version does not match"
  },
  "fpv_tip_0x16070031": {
      "en": "RTK signal source switching error"
  },
  "fpv_tip_0x16070032": {
      "en": "D-RTK mobile station detached"
  },
  "fpv_tip_0x16070033": {
      "en": "D-RTK mobile station moved"
  },
  "fpv_tip_0x16070034": {
      "en": "RTK flight heading inconsistent with other sources"
  },
  "fpv_tip_0x16070035": {
      "en": "Aircraft D-RTK antenna error"
  },
  "fpv_tip_0x16070036": {
      "en": "Unable to take off. RTK antennas disconnected. Contact DJI Support"
  },
  "fpv_tip_0x16070036_in_the_sky": {
      "en": "RTK antennas disconnected. Return to home or land promptly"
  },
  "fpv_tip_0x16070037": {
      "en": "RTK Dongle Certification Error"
  },
  "fpv_tip_0x16070038": {
      "en": ""
  },
  "fpv_tip_0x16070039": {
      "en": ""
  },
  "fpv_tip_0x16070040": {
      "en": "RTK not converged. Place aircraft in an open environment and start task after RTK converged"
  },
  "fpv_tip_0x16070042": {
      "en": "RTK base station data error. Check if network RTK or D-RTK is connected"
  },
  "fpv_tip_0x16070043": {
      "en": "GNSS is interfered with error signal. Fly with caution or return to home"
  },
  "fpv_tip_0x16070043_in_the_sky": {
      "en": "GNSS is interfered with error signal. Take off with caution"
  },
  "fpv_tip_0x16070044": {
      "en": "GNSS is receiving false signal. Return to home or land promptly"
  },
  "fpv_tip_0x16070044_in_the_sky": {
      "en": "GNSS is receiving false signal. Take off with caution"
  },
  "fpv_tip_0x16070046": {
      "en": "Ionospheric scintillation detected. RTK positioning performance affected. Fly with caution"
  },
  "fpv_tip_0x16080020": {
      "en": "Motor %index stalled . Land aircraft immediately"
  },
  "fpv_tip_0x16080021": {
      "en": "ESC %index short-circuited . Land immediately and restart aircraft"
  },
  "fpv_tip_0x16080022": {
      "en": "ESC %index overloaded . Land aircraft immediately"
  },
  "fpv_tip_0x16080023": {
      "en": "Motor %index communication error . Land immediately and restart aircraft"
  },
  "fpv_tip_0x16080024": {
      "en": "Motor %index over-accelerating. Stop pressing control stick"
  },
  "fpv_tip_0x16080025": {
      "en": "Motor %index communication error"
  },
  "fpv_tip_0x16080026": {
      "en": "Motor %index communication error"
  },
  "fpv_tip_0x16080027": {
      "en": "Motor %index communication error"
  },
  "fpv_tip_0x16080028": {
      "en": "Motor %index communication error"
  },
  "fpv_tip_0x16080029": {
      "en": "Motor %index propeller detached, propeller not installed, or motor underloaded"
  },
  "fpv_tip_0x1608002A": {
      "en": "ESC %index overheated"
  },
  "fpv_tip_0x1608002B": {
      "en": "ESC %index overheated"
  },
  "fpv_tip_0x1608002C": {
      "en": "ESC %index voltage too high"
  },
  "fpv_tip_0x1608002D": {
      "en": "ESC %index voltage too low"
  },
  "fpv_tip_0x1608002E": {
      "en": "ESC %index flash memory error"
  },
  "fpv_tip_0x1608002F": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080030": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080031": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080032": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080033": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080034": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080035": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080036": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080037": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080038": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080039": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608003a": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608003b": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608003c": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608003d": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608003e": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608003f": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080040": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080041": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080042": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080043": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080044": {
      "en": "ESC %index voltage too low"
  },
  "fpv_tip_0x16080045": {
      "en": "ESC %index voltage too high"
  },
  "fpv_tip_0x16080046": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080047": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080048": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080049": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608004a": {
      "en": "ESC %index overheated"
  },
  "fpv_tip_0x1608004b": {
      "en": "ESC %index temperature too low"
  },
  "fpv_tip_0x1608004c": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608004d": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608004e": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x1608004f": {
      "en": "ESC %index auto-check error"
  },
  "fpv_tip_0x16080050": {
      "en": "ESC error. Unable to take off. Restart aircraft and try again"
  },
  "fpv_tip_0x16080050_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x16080051": {
      "en": "ESC error. Fly with caution"
  },
  "fpv_tip_0x16080052": {
      "en": "Propulsion system error. Propellers folded. Unable to take off"
  },
  "fpv_tip_0x16080052_in_the_sky": {
      "en": "Propulsion system error. Propellers folded"
  },
  "fpv_tip_0x16080053": {
      "en": "ESC error. Fly with caution"
  },
  "fpv_tip_0x16080054": {
      "en": "ESC error. Unable to take off. Restart aircraft and try again"
  },
  "fpv_tip_0x16080054_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x16080055": {
      "en": "Motor %index overheated. Land aircraft immediately"
  },
  "fpv_tip_0x16080056": {
      "en": "Motor %index overheated. Aircraft may be auto landing"
  },
  "fpv_tip_0x16080056_in_the_sky": {
      "en": "Motor %index temperature too high. Flight speed limited"
  },
  "fpv_tip_0x16080057": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x16080057_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x16080058": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x16080058_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x16080059": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x16080059_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x1608005A": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005A_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x1608005B": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005B_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x1608005C": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005C_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x1608005D": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005D_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x1608005E": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005F": {
      "en": "Motor %index overheated. Aircraft auto landing"
  },
  "fpv_tip_0x1608005a": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005b": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005c": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005d": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005e": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1608005e_in_the_sky": {
      "en": "ESC error. Return to home or land promptly"
  },
  "fpv_tip_0x16080077": {
      "en": "%index ESC overheated (%alarmid)"
  },
  "fpv_tip_0x16080078": {
      "en": "%index ESC overheated (%alarmid)"
  },
  "fpv_tip_0x16080079": {
      "en": "%index ESC firmware incompatible with aircraft model (%alarmid)"
  },
  "fpv_tip_0x1608007A": {
      "en": "%index ESC firmware incompatible with aircraft model (%alarmid)"
  },
  "fpv_tip_0x1608007B": {
      "en": "%index ESC auto-check error (%alarmid)"
  },
  "fpv_tip_0x1608007C": {
      "en": "%index ESC auto-check error (%alarmid)"
  },
  "fpv_tip_0x1608007D": {
      "en": "%index ESC or motor auto-check error (%alarmid)"
  },
  "fpv_tip_0x1608007E": {
      "en": "%index ESC or motor auto-check error (%alarmid)"
  },
  "fpv_tip_0x1608007F": {
      "en": "%index ESC or motor auto-check error (%alarmid)"
  },
  "fpv_tip_0x16080080": {
      "en": "%index ESC or motor auto-check error (%alarmid)"
  },
  "fpv_tip_0x16080083": {
      "en": "Failed to start Motor %d. Make sure propellers are unfolded and free from obstacles and try again"
  },
  "fpv_tip_0x16090050": {
      "en": "%battery_index Battery power supply error. Return to home or land promptly"
  },
  "fpv_tip_0x16090051": {
      "en": "%battery_index Battery power supply error. Return to home or land promptly"
  },
  "fpv_tip_0x16090052": {
      "en": "%battery_index Battery temperature sensor error. Return to home or land promptly"
  },
  "fpv_tip_0x16090061": {
      "en": "%battery_index Battery overheated. Return to home or land promptly. Stop using battery"
  },
  "fpv_tip_0x16090062": {
      "en": "%battery_index Battery MOS temperature too high. Return to home or land promptly. Wait until %battery_index Battery cools down"
  },
  "fpv_tip_0x16090063": {
      "en": "%battery_index Battery voltage too high. Return to home or land promptly"
  },
  "fpv_tip_0x16090064": {
      "en": "%battery_index Battery voltage too low. Return to home or land promptly"
  },
  "fpv_tip_0x16090065": {
      "en": "%battery_index Battery single cell voltage too high. Return to home or land promptly"
  },
  "fpv_tip_0x16090066": {
      "en": "%battery_index Battery single cell voltage too low. Return to home or land promptly"
  },
  "fpv_tip_0x16090067": {
      "en": "Large static voltage difference between %battery_index Battery cells. Stop using battery"
  },
  "fpv_tip_0x16090068": {
      "en": "Large charge voltage difference between %battery_index Battery cells. Return to home or land promptly. Stop using battery"
  },
  "fpv_tip_0x16090069": {
      "en": "Large discharge voltage difference between %battery_index Battery cells. Return to home or land promptly. Stop using battery"
  },
  "fpv_tip_0x1609006A": {
      "en": "%battery_index Battery MOS connection error. Return to home or land promptly"
  },
  "fpv_tip_0x1609006B": {
      "en": "%battery_index Battery MOS impedance error. Return to home or land promptly"
  },
  "fpv_tip_0x1609006C": {
      "en": "%battery_index Battery aging. Flight safety may be affected. Return to home or land promptly"
  },
  "fpv_tip_0x1609006D": {
      "en": "%battery_index Battery experienced micro short circuit. Flight safety may be affected. Return to home or land promptly"
  },
  "fpv_tip_0x1609006F": {
      "en": "%battery_index Battery connector temperature too high. Return to home or land promptly"
  },
  "fpv_tip_0x16090070": {
      "en": "%battery_index Battery voltage too low. Return to home or land promptly"
  },
  "fpv_tip_0x16090071": {
      "en": "Battery overvoltage protection"
  },
  "fpv_tip_0x16090072": {
      "en": "%battery_index Battery overcurrent when charging. Check charging device"
  },
  "fpv_tip_0x16090073": {
      "en": "%battery_index Battery overcurrent when discharging. Check if aircraft is overloaded"
  },
  "fpv_tip_0x16090074": {
      "en": "%battery_index Battery over discharged. Check if aircraft is overloaded"
  },
  "fpv_tip_0x16090075": {
      "en": "%battery_index Battery system error. Restart %battery_index Battery and try again"
  },
  "fpv_tip_0x16090076": {
      "en": "%battery_index Battery internal module error. Return to home or land promptly"
  },
  "fpv_tip_0x16090077": {
      "en": "%battery_index Battery charging temperature too high. Stop charging and wait until %battery_index Battery cools down"
  },
  "fpv_tip_0x16090078": {
      "en": "%battery_index Battery discharging temperature too high. Return to home or land promptly. Wait until %battery_index Battery cools down"
  },
  "fpv_tip_0x16090079": {
      "en": "%battery_index Battery charging temperature too low. Warm up %battery_index Battery before charging"
  },
  "fpv_tip_0x1609007A": {
      "en": "%battery_index Battery discharging temperature too low. Return to home or land promptly. Warm up %battery_index Battery before takeoff"
  },
  "fpv_tip_0x1609007B": {
      "en": "%battery_index Battery voltage too high. Reinstall %battery_index Battery before takeoff"
  },
  "fpv_tip_0x1609007C": {
      "en": "%battery_index Battery voltage too low. Reinstall %battery_index Battery before takeoff"
  },
  "fpv_tip_0x1609007D": {
      "en": "Charging device voltage too high. Check device"
  },
  "fpv_tip_0x1609007E": {
      "en": "Charging device voltage too low. Check device"
  },
  "fpv_tip_0x1609007F": {
      "en": "%battery_index Battery overheated. Unable to take off. Wait until %battery_index Battery cools down"
  },
  "fpv_tip_0x16090080": {
      "en": "%battery_index Battery overheated. Return to home or land promptly. Wait until %battery_index Battery cools down"
  },
  "fpv_tip_0x16090081": {
      "en": "%battery_index Battery overheated. Return to home or land promptly. Wait until %battery_index Battery cools down"
  },
  "fpv_tip_0x16090082": {
      "en": "%battery_index Battery internal communication error. Return to home or land promptly"
  },
  "fpv_tip_0x16090083": {
      "en": "%battery_index Battery internal communication error. Return to home or land promptly"
  },
  "fpv_tip_0x16090084": {
      "en": "%battery_index Battery internal communication error. Return to home or land promptly"
  },
  "fpv_tip_0x16090085": {
      "en": "%battery_index Battery external communication error. Return to home or land promptly"
  },
  "fpv_tip_0x16090086": {
      "en": "%battery_index battery connector temperature too high. Flight safety affected"
  },
  "fpv_tip_0x16090086_in_the_sky": {
      "en": "%battery_index battery connector temperature too high. Flight safety affected. Return to home or land promptly"
  },
  "fpv_tip_0x16090092": {
      "en": "Pack voltage sensor error"
  },
  "fpv_tip_0x16090093": {
      "en": "%battery_index Battery voltage unstable. Return to home or land promptly. Restart aircraft after landing"
  },
  "fpv_tip_0x16090094": {
      "en": "%battery_index Battery voltage sensor error. Return to home or land promptly"
  },
  "fpv_tip_0x16090095": {
      "en": "%battery_index Battery voltage too high. Return to home or land promptly. Restart aircraft after landing"
  },
  "fpv_tip_0x16090096": {
      "en": "%battery_index Battery maintenance required. Fly with caution"
  },
  "fpv_tip_0x16090097": {
      "en": "%battery_index battery maintenance required. Fly with caution"
  },
  "fpv_tip_0x16090098": {
      "en": "Large voltage difference during discharge"
  },
  "fpv_tip_0x16090099": {
      "en": "%battery_index Battery overcurrent. Fly with caution"
  },
  "fpv_tip_0x1609009B": {
      "en": "%battery_index Battery temperature high. Return to home or land promptly. Wait until %battery_index Battery cools down"
  },
  "fpv_tip_0x1609009C": {
      "en": "%battery_index Battery temperature high. Return to home or land promptly. Wait until %battery_index Battery cools down"
  },
  "fpv_tip_0x1609009D": {
      "en": "%battery_index battery connector temperature too high"
  },
  "fpv_tip_0x1609009D_in_the_sky": {
      "en": "%battery_index battery connector temperature too high. Flight safety affected. Return to home or land promptly"
  },
  "fpv_tip_0x1609009E": {
      "en": "%battery_index Battery voltage high. Return to home or land promptly"
  },
  "fpv_tip_0x1609009F": {
      "en": "%battery_index Battery voltage low. Return to home or land promptly"
  },
  "fpv_tip_0x160900A2": {
      "en": "%index battery connector overheated. Flight safety affected. Fly with caution"
  },
  "fpv_tip_0x160900A3": {
      "en": "%index battery exceeds power limit. Flight safety affected. Return to home or land promptly"
  },
  "fpv_tip_0x160900A6": {
      "en": "%battery_index Battery connection error. Return to home or land promptly"
  },
  "fpv_tip_0x160900B0": {
      "en": "%battery_index Battery level incorrect. Return to home or land promptly"
  },
  "fpv_tip_0x160900B1": {
      "en": "%battery_index Battery level unstable. Return to home or land promptly"
  },
  "fpv_tip_0x160900B2": {
      "en": "Abnormal SoC changes"
  },
  "fpv_tip_0x160900B3": {
      "en": "%battery_index Battery capacity error. Fly with caution"
  },
  "fpv_tip_0x160900B4": {
      "en": "%battery_index Battery capacity error. Fly with caution"
  },
  "fpv_tip_0x160900B5": {
      "en": "%battery_index Battery capacity error. Fly with caution"
  },
  "fpv_tip_0x160900B6": {
      "en": "%battery_index Battery capacity error. Fly with caution"
  },
  "fpv_tip_0x160900B7": {
      "en": "%battery_index Battery capacity error. Fly with caution"
  },
  "fpv_tip_0x160900B8": {
      "en": "%battery_index Battery capacity error. Fly with caution"
  },
  "fpv_tip_0x160900B9": {
      "en": "%battery_index Battery capacity not updated for extended period. Maintenance required. Fly with caution"
  },
  "fpv_tip_0x160900BA": {
      "en": "%battery_index Battery capacity not updated for extended period. Maintenance required. Fly with caution"
  },
  "fpv_tip_0x160900BB": {
      "en": "%battery_index Battery degraded. Fly with caution"
  },
  "fpv_tip_0x160900BC": {
      "en": "Battery internal resistance unusually low"
  },
  "fpv_tip_0x160900BD": {
      "en": "%battery_index Battery aging. Flight safety may be affected. Return to home or land promptly"
  },
  "fpv_tip_0x160900BE": {
      "en": "%battery_index Battery aging. Flight safety may be affected. Return to home or land promptly"
  },
  "fpv_tip_0x160900BF": {
      "en": "%battery_index Battery approaching cycle count limit. It is recommended to replace with new battery and maintain %battery_index Battery"
  },
  "fpv_tip_0x160900C0": {
      "en": "%battery_index Battery rated power exceeded. Performance limited. Fly with caution"
  },
  "fpv_tip_0x160900C1": {
      "en": "Imbalanced battery"
  },
  "fpv_tip_0x160900C2": {
      "en": "Sudden SoC changes"
  },
  "fpv_tip_0x160900C3": {
      "en": "Battery approaching cycle count limit. Continuing use will pose safety risks. Replace battery recommended"
  },
  "fpv_tip_0x160900C4": {
      "en": "%battery_index battery level calibration required. Let battery sit for 2 hours to restore"
  },
  "fpv_tip_0x160900C8": {
      "en": "D-RTK 3 battery level below 20%. Charge promptly"
  },
  "fpv_tip_0x160900C9": {
      "en": "D-RTK 3 battery level below 10%. Charge promptly"
  },
  "fpv_tip_0x160900CA": {
      "en": "D-RTK 3 battery overcurrent during charging. Disconnect charging device and reconnect"
  },
  "fpv_tip_0x160900CB": {
      "en": "D-RTK 3 battery overcurrent during discharging. Restart relay"
  },
  "fpv_tip_0x160900CD": {
      "en": "D-RTK 3 battery temperature exceeds 70° C. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x160900CE": {
      "en": "D-RTK 3 battery temperature exceeds 75° C. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x160900D0": {
      "en": "Battery temperature too low. D-RTK 3 will power off"
  },
  "fpv_tip_0x160900D2": {
      "en": "%battery_index battery level not calibrated for a long time. Let battery sit for 1 hour before calibration"
  },
  "fpv_tip_0x160900D5": {
      "en": "Unable to take off: Battery reached end of lifespan. Replace battery"
  },
  "fpv_tip_0x160900D5_in_the_sky": {
      "en": "Unable to take off: Battery reached end of lifespan. Replace battery"
  },
  "fpv_tip_0x160900D6": {
      "en": "Battery SOHc low. Battery will reach end of lifespan after 20 cycles or days. Replace battery promptly"
  },
  "fpv_tip_0x160900D7": {
      "en": "Non-standard PoE detected. Use PoE with power above 15 W"
  },
  "fpv_tip_0x160900D8": {
      "en": "Power of current adapter is too low. Unable to support D-RTK 3 operation in temperature below -20° C"
  },
  "fpv_tip_0x160A0001": {
      "en": "Parachute communication error. Make sure parachute is installed properly"
  },
  "fpv_tip_0x160A0011": {
      "en": "Real-name registration is canceled, which may result in flight safety risks. View Help Documentation and complete real-name registration based on instructions before flight"
  },
  "fpv_tip_0x160B0000": {
      "en": "D-RTK 3 multifunctional station disconnected"
  },
  "fpv_tip_0x160B0001": {
      "en": "D-RTK 3 multifunctional station moved"
  },
  "fpv_tip_0x160B0002": {
      "en": "D-RTK 3 multifunctional station RTK satellite signal searching error. Check if signal is obstructed and use device in area with strong satellite signal"
  },
  "fpv_tip_0x160B0003": {
      "en": "Initialization position of D-RTK 3 multifunctional station is too far from last calibrated position"
  },
  "fpv_tip_0x160B0004": {
      "en": "Failed to initiate D-RTK 3 multifunctional station positioning. Redeploy in area with strong satellite signal"
  },
  "fpv_tip_0x16100001": {
      "en": "Compass error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x16100001_in_the_sky": {
      "en": "Compass error. Return to home or land"
  },
  "fpv_tip_0x16100002": {
      "en": "DJI Assistant connected. Unable to take off. Disconnect before taking off"
  },
  "fpv_tip_0x16100002_in_the_sky": {
      "en": "DJI Assistant connected. Disconnect before taking off"
  },
  "fpv_tip_0x16100003": {
      "en": "Aircraft not activated or firmware out-of-date. Unable to take off. Activate aircraft or update to latest firmware version"
  },
  "fpv_tip_0x16100003_in_the_sky": {
      "en": "Device activation or firmware update required"
  },
  "fpv_tip_0x16100005": {
      "en": "IMU error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x16100005_in_the_sky": {
      "en": "IMU error. Restart aircraft"
  },
  "fpv_tip_0x16100006": {
      "en": "Invalid IMU serial number. Unable to take off. Maintenance required"
  },
  "fpv_tip_0x16100006_in_the_sky": {
      "en": "Invalid IMU serial number. Return to home or land"
  },
  "fpv_tip_0x16100008": {
      "en": "Compass calibrating. Unable to take off. Wait for calibration to complete before taking off(%alarmid)"
  },
  "fpv_tip_0x16100008_in_the_sky": {
      "en": "Compass calibrating. Wait for calibration to complete before taking off"
  },
  "fpv_tip_0x16100009": {
      "en": "Sensor system initializing. Unable to take off. Wait for initialization to complete before taking off"
  },
  "fpv_tip_0x16100009_in_the_sky": {
      "en": "Sensor system initializing. Return to home or land"
  },
  "fpv_tip_0x1610000A": {
      "en": "Aircraft in Beginner mode. Unable to take off. Take off in an open outdoor area when in Beginner mode"
  },
  "fpv_tip_0x1610000A_in_the_sky": {
      "en": "Beginner mode enabled. When using Beginner mode, take off in an open, outdoor area"
  },
  "fpv_tip_0x1610000B": {
      "en": "Battery cell error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x1610000B_in_the_sky": {
      "en": "Battery cell error. Return to home or land"
  },
  "fpv_tip_0x1610000C": {
      "en": "Battery communication error. Unable to take off. Reinstall battery"
  },
  "fpv_tip_0x1610000C_in_the_sky": {
      "en": "Battery communication error. Return to home"
  },
  "fpv_tip_0x1610000D": {
      "en": "Critical low battery voltage. Unable to take off. Charge promptly"
  },
  "fpv_tip_0x1610000D_in_the_sky": {
      "en": "Critical low battery voltage. Return to home or land promptly"
  },
  "fpv_tip_0x1610000E": {
      "en": "Critical low battery. Unable to take off. Charge promptly"
  },
  "fpv_tip_0x1610000E_in_the_sky": {
      "en": "Critical low battery. Return to home or land promptly"
  },
  "fpv_tip_0x1610000F": {
      "en": "Critical low battery voltage. Unable to take off. Charge promptly"
  },
  "fpv_tip_0x1610000F_in_the_sky": {
      "en": "Critical low battery voltage. Return to home or land promptly"
  },
  "fpv_tip_0x16100010": {
      "en": "Battery output power insufficient. Unable to take off. Charge promptly"
  },
  "fpv_tip_0x16100010_in_the_sky": {
      "en": "Battery power output insufficient. Return to home or land"
  },
  "fpv_tip_0x16100011": {
      "en": "Critical low battery. Unable to take off. Charge promptly"
  },
  "fpv_tip_0x16100011_in_the_sky": {
      "en": "Critical low battery. Return to home or land promptly"
  },
  "fpv_tip_0x16100012": {
      "en": "Battery initializing. Unable to take off. Wait for initialization to complete before taking off"
  },
  "fpv_tip_0x16100012_in_the_sky": {
      "en": "Battery initialization error. Return to home or land"
  },
  "fpv_tip_0x16100013": {
      "en": "Running Flight Simulator. Unable to take off. Restart aircraft before taking off"
  },
  "fpv_tip_0x16100013_in_the_sky": {
      "en": "Running Flight Simulator. Restart aircraft before taking off"
  },
  "fpv_tip_0x16100014": {
      "en": "Unable to take off. Landing gear in Travel mode"
  },
  "fpv_tip_0x16100015": {
      "en": "Aircraft pitch angle too large. Unable to take off. Ensure aircraft is level before taking off"
  },
  "fpv_tip_0x16100015_in_the_sky": {
      "en": "Aircraft pitch angle too large. Ensure aircraft is level before taking off"
  },
  "fpv_tip_0x16100016": {
      "en": "Aircraft not activated. Unable to take off. Restart DJI Pilot and activate aircraft"
  },
  "fpv_tip_0x16100016_in_the_sky": {
      "en": "Aircraft not activated. Restart DJI Pilot and activate aircraft"
  },
  "fpv_tip_0x16100017": {
      "en": "Aircraft in GEO Zone. Unable to take off. Check map to find Recommended Zones"
  },
  "fpv_tip_0x16100017_in_the_sky": {
      "en": "Aircraft in GEO Zone. Check map to find Recommended Zones"
  },
  "fpv_tip_0x16100018": {
      "en": "IMU error. Unable to take off. Calibrate IMU"
  },
  "fpv_tip_0x16100018_in_the_sky": {
      "en": "IMU initialization error. Return to home or land"
  },
  "fpv_tip_0x16100019": {
      "en": "ESC error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x16100019_in_the_sky": {
      "en": "ESC error. Land immediately"
  },
  "fpv_tip_0x1610001A": {
      "en": "Sensor system initializing. Unable to take off. Wait for initialization to complete before taking off"
  },
  "fpv_tip_0x1610001A_in_the_sky": {
      "en": "Sensor system initializing. Return to home or land"
  },
  "fpv_tip_0x1610001B": {
      "en": "System updating. Unable to take off. Wait for update to complete"
  },
  "fpv_tip_0x1610001B_in_the_sky": {
      "en": "System updating. Wait for update to complete before taking off"
  },
  "fpv_tip_0x1610001C": {
      "en": "Running Flight Simulator. Unable to take off. Restart aircraft before taking off"
  },
  "fpv_tip_0x1610001C_in_the_sky": {
      "en": "Running Flight Simulator. Restart aircraft before taking off"
  },
  "fpv_tip_0x1610001D": {
      "en": "IMU calibrating. Unable to take off. Wait for calibration to complete before takeoff"
  },
  "fpv_tip_0x1610001D_in_the_sky": {
      "en": "Calibrating IMU. Wait for calibration to complete before taking off"
  },
  "fpv_tip_0x1610001E": {
      "en": "Aircraft pitch angle too large. Unable to take off. Ensure aircraft is level before taking off"
  },
  "fpv_tip_0x1610001E_in_the_sky": {
      "en": "Aircraft pitch angle too large. Ensure aircraft is level before taking off"
  },
  "fpv_tip_0x1610001F": {
      "en": "Unable to take off. Flight controller overheats."
  },
  "fpv_tip_0x1610001F_in_the_sky": {
      "en": "Flight controller overheats. Returning to home..."
  },
  "fpv_tip_0x16100020": {
      "en": "Unable to take off. Flight controller overheats."
  },
  "fpv_tip_0x16100020_in_the_sky": {
      "en": "Flight controller overheats. Returning to home..."
  },
  "fpv_tip_0x16100021": {
      "en": "Unable to take off. Flight controller overheats."
  },
  "fpv_tip_0x16100021_in_the_sky": {
      "en": "Flight controller overheats. Auto landing..."
  },
  "fpv_tip_0x16100022": {
      "en": "Unable to take off. Aircraft unable to remain stable for several times. To ensure flight safety, contact DJI Support"
  },
  "fpv_tip_0x16100023": {
      "en": "AirSense error. Unable to obtain crewed aircraft information"
  },
  "fpv_tip_0x16100024": {
      "en": "Compass interference. Unable to take off. Move away from source of interference or calibrate compass"
  },
  "fpv_tip_0x16100025": {
      "en": "Hot swapping not supported for RTK module. Unable to take off"
  },
  "fpv_tip_0x16100025_in_the_sky": {
      "en": "RTK error. Land promptly"
  },
  "fpv_tip_0x16100026": {
      "en": "Unable to take off. Non-DJI RTK module detected"
  },
  "fpv_tip_0x16100026_in_the_sky": {
      "en": "Non-DJI RTK module detected"
  },
  "fpv_tip_0x16100027": {
      "en": "Flight control system error. Unable to take off"
  },
  "fpv_tip_0x16100027_in_the_sky": {
      "en": "Flight control system error. Land promptly"
  },
  "fpv_tip_0x16100028": {
      "en": "Unable to take off. Models of aircraft and RTK module do not match"
  },
  "fpv_tip_0x16100029": {
      "en": "Invalid aircraft serial number. Unable to take off. Contact your local dealer or DJI Support"
  },
  "fpv_tip_0x16100029_in_the_sky": {
      "en": "Invalid aircraft serial number. Return to home or land"
  },
  "fpv_tip_0x1610002D": {
      "en": "Unable to take off. GNSS signal lost. Restart aircraft"
  },
  "fpv_tip_0x1610002D_in_the_sky": {
      "en": "GNSS signal lost. Return to home or land"
  },
  "fpv_tip_0x1610002F": {
      "en": "Data recorder error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x1610002F_in_the_sky": {
      "en": "Data recorder error. Return to home or land"
  },
  "fpv_tip_0x16100030": {
      "en": "Aircraft model and firmware version do not match. Unable to take off. Contact your local dealer or DJI Support."
  },
  "fpv_tip_0x16100030_in_the_sky": {
      "en": "Aircraft model and firmware version do not match. Return to home or land"
  },
  "fpv_tip_0x16100031": {
      "en": "Unable to take off. Hook is not fully retracted. Make sure hook is retracted and secure"
  },
  "fpv_tip_0x16100032": {
      "en": "Winch cable wound in reverse direction. Unable to take off. Manually unwind cable before takeoff"
  },
  "fpv_tip_0x16100032_in_the_sky": {
      "en": "Winch cable wound in reverse direction. Do not operate winch system and land promptly to unwind cable"
  },
  "fpv_tip_0x16100033": {
      "en": "Real-name registration is not performed. View Help Documentation and complete real-name registration based on instructions before flight"
  },
  "fpv_tip_0x16100034": {
      "en": "Real-name registration is canceled. View Help Documentation and complete real-name registration based on instructions before flight"
  },
  "fpv_tip_0x16100038": {
      "en": "Unable to take off. ESC voltage error. Contact DJI Support"
  },
  "fpv_tip_0x16100038_in_the_sky": {
      "en": "ESC voltage error. Return to home and contact DJI Support"
  },
  "fpv_tip_0x16100039": {
      "en": "Geo-cage function is preparing"
  },
  "fpv_tip_0x16100039_in_the_sky": {
      "en": "Unable to take off. Geo-cage function is preparing"
  },
  "fpv_tip_0x1610003A": {
      "en": "Unable to take off. RTK module overheated. Aircraft will power off immediately"
  },
  "fpv_tip_0x1610003B": {
      "en": "Unable to take off. Speaker installed. Recalibrating compass required"
  },
  "fpv_tip_0x1610003D": {
      "en": "Sensor system disconnected. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x1610003D_in_the_sky": {
      "en": "Sensor system disconnected. Return to home or land"
  },
  "fpv_tip_0x1610003E": {
      "en": "Unable to take off. Calibrating remote controller. Wait for calibration to complete"
  },
  "fpv_tip_0x16100041": {
      "en": "Unable to take off. Control sticks off-centered. Calibrate remote controller"
  },
  "fpv_tip_0x16100042": {
      "en": "Unable to take off. Control stick hardware error. Contact DJI Support"
  },
  "fpv_tip_0x16100042_in_the_sky": {
      "en": "Control stick hardware error. Land promptly. Contact DJI Support"
  },
  "fpv_tip_0x1610004A": {
      "en": "Sensor system error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x1610004A_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x1610004B": {
      "en": "Sensor system error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x1610004B_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x1610004C": {
      "en": "Unable to take off. Remote Controller Calibration Required"
  },
  "fpv_tip_0x1610004D": {
      "en": "Flight controller data error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x1610004D_in_the_sky": {
      "en": "Flight controller data error. Return to home or land"
  },
  "fpv_tip_0x1610004E": {
      "en": "Not enough batteries installed. Unable to take off. Insert two batteries before taking off"
  },
  "fpv_tip_0x1610004E_in_the_sky": {
      "en": "Insufficient batteries. Return to home or land promptly"
  },
  "fpv_tip_0x1610004F": {
      "en": "Battery authentication failed. Unable to take off. Replace with DJI battery"
  },
  "fpv_tip_0x1610004F_in_the_sky": {
      "en": "Battery authentication failed. Replace with standard DJI battery"
  },
  "fpv_tip_0x16100051": {
      "en": "Large voltage difference between batteries. Unable to take off. Replace batteries with new ones of a similar capacity"
  },
  "fpv_tip_0x16100051_in_the_sky": {
      "en": "Large voltage difference between batteries. Return to home or land. Replace batteries with new ones of a similar capacity and try again"
  },
  "fpv_tip_0x16100053": {
      "en": "Aircraft module firmware versions do not match. Unable to take off. Update to latest firmware versions."
  },
  "fpv_tip_0x16100053_in_the_sky": {
      "en": "Aircraft module firmware versions do not match. Update to latest firmware versions"
  },
  "fpv_tip_0x16100054": {
      "en": "Gimbal error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x16100054_in_the_sky": {
      "en": "Gimbal error. Return to home or land"
  },
  "fpv_tip_0x16100055": {
      "en": "Gimbal error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x16100055_in_the_sky": {
      "en": "Gimbal error. Return to home or land"
  },
  "fpv_tip_0x16100056": {
      "en": "Gimbal error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x16100056_in_the_sky": {
      "en": "Gimbal error. Return to home or land"
  },
  "fpv_tip_0x16100057": {
      "en": "Gimbal error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x16100057_in_the_sky": {
      "en": "Gimbal error. Return to home or land"
  },
  "fpv_tip_0x16100058": {
      "en": "Gimbal firmware updating. Unable to take off..."
  },
  "fpv_tip_0x16100058_in_the_sky": {
      "en": "Gimbal firmware update in progress"
  },
  "fpv_tip_0x1610005D": {
      "en": "IMU calibration successful. Unable to take off. Restarting aircraft required"
  },
  "fpv_tip_0x1610005D_in_the_sky": {
      "en": "IMU calibration error. Return to home or land"
  },
  "fpv_tip_0x1610005E": {
      "en": "Aircraft rolled during takeoff. Unable to take off. Check whether propellers were installed correctly"
  },
  "fpv_tip_0x1610005E_in_the_sky": {
      "en": "Aircraft rolled during takeoff. Check whether propellers are installed correctly"
  },
  "fpv_tip_0x1610005F": {
      "en": "Motor stalled. Unable to take off. Power off aircraft and check whether motor can rotate freely"
  },
  "fpv_tip_0x1610005F_in_the_sky": {
      "en": "Motor stalled. Land promptly. Power off airfcraft and check whether motor can rotate freely"
  },
  "fpv_tip_0x16100060": {
      "en": "Motor rotation speed error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x16100060_in_the_sky": {
      "en": "Motor rotation speed error. Land and restart aircraft"
  },
  "fpv_tip_0x16100061": {
      "en": "Motor idle. Unable to take off. Check whether propellers are detached or installed incorrectly"
  },
  "fpv_tip_0x16100061_in_the_sky": {
      "en": "Motor idle. Unable to take off. Check whether propellers are detached or installed incorrectly"
  },
  "fpv_tip_0x16100062": {
      "en": "Unable to turn on motor. Unable to take off. Check aircraft status and restart"
  },
  "fpv_tip_0x16100062_in_the_sky": {
      "en": "Unable to turn on aircraft motor(s). Check aircraft status and restart"
  },
  "fpv_tip_0x16100063": {
      "en": "Auto Takeoff failed. Unable to take off"
  },
  "fpv_tip_0x16100063_in_the_sky": {
      "en": "Auto Takeoff failed"
  },
  "fpv_tip_0x16100064": {
      "en": "Aircraft rolled over. Unable to take off. Restart aircraft and ensure it is level before taking off"
  },
  "fpv_tip_0x16100064_in_the_sky": {
      "en": "Aircraft rolled over. Return to home or land"
  },
  "fpv_tip_0x16100065": {
      "en": "Battery firmware version error. Unable to take off. Replace battery or update battery firmware to latest version"
  },
  "fpv_tip_0x16100065_in_the_sky": {
      "en": "Battery firmware version error. Replace battery or update to the latest firmware version"
  },
  "fpv_tip_0x16100066": {
      "en": "RTK signal weak. Unable to take off. Move to an open area for takeoff or turn off RTK"
  },
  "fpv_tip_0x16100066_in_the_sky": {
      "en": "RTK signal weak. Move to an open area for takeoff or turn off RTK"
  },
  "fpv_tip_0x16100067": {
      "en": "Compass interference. Unable to take off. Calibrate compass"
  },
  "fpv_tip_0x16100067_in_the_sky": {
      "en": "Compass interference. Move away from interference source"
  },
  "fpv_tip_0x16100068": {
      "en": "ESC short-circuited. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x16100068_in_the_sky": {
      "en": "ESC short-circuited. Restart aircraft"
  },
  "fpv_tip_0x16100069": {
      "en": "ESC auto-check error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x16100069_in_the_sky": {
      "en": "ESC auto-check error. Return to home or land"
  },
  "fpv_tip_0x1610006A": {
      "en": "Incompatible battery. Replace battery"
  },
  "fpv_tip_0x1610006B": {
      "en": "Unable to take off. Battery firmware update required"
  },
  "fpv_tip_0x1610006B_in_the_sky": {
      "en": "Battery firmware error. Return to home or land promptly"
  },
  "fpv_tip_0x1610006C": {
      "en": "Unable to take off. Severe battery error"
  },
  "fpv_tip_0x1610006C_in_the_sky": {
      "en": "Severe battery error. Auto landing..."
  },
  "fpv_tip_0x1610006D": {
      "en": "Unable to take off. Power restricted. Check battery warning for issue reason"
  },
  "fpv_tip_0x1610006D_in_the_sky": {
      "en": "Power and flight attitude restricted. Return to home promptly. Check battery warning for issue reason"
  },
  "fpv_tip_0x16100070": {
      "en": "Not Applicable. This string has been deleted"
  },
  "fpv_tip_0x16100070_in_the_sky": {
      "en": "Not Applicable. This string has been deleted"
  },
  "fpv_tip_0x16100071": {
      "en": "Unable to take off. GNSS error. Restart aircraft"
  },
  "fpv_tip_0x16100071_in_the_sky": {
      "en": "GNSS error. Return to home or land"
  },
  "fpv_tip_0x16100072": {
      "en": "Gimbal calibrating. Unable to take off"
  },
  "fpv_tip_0x16100072_in_the_sky": {
      "en": "Gimbal calibration error. Return to home or land"
  },
  "fpv_tip_0x16100073": {
      "en": "Takeoff conditions not met. Unable to take off. Firmware out-of-date or flight route uploading to aircraft"
  },
  "fpv_tip_0x16100073_in_the_sky": {
      "en": "Firmware out of date or flight route uploading to aircraft. Return to home or land"
  },
  "fpv_tip_0x16100074": {
      "en": "Takeoff altitude error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x16100074_in_the_sky": {
      "en": "Takeoff altitude error. Restart aircraft"
  },
  "fpv_tip_0x16100075": {
      "en": "ESC firmware versions do not match. Unable to take off. Update to latest firmware version"
  },
  "fpv_tip_0x16100075_in_the_sky": {
      "en": "ESC firmware versions do not match. Update to latest firmware versions"
  },
  "fpv_tip_0x16100076": {
      "en": "IMU error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x16100076_in_the_sky": {
      "en": "IMU error. Return to home or land"
  },
  "fpv_tip_0x16100078": {
      "en": "Compass error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x16100078_in_the_sky": {
      "en": "Compass error. Return to home or land"
  },
  "fpv_tip_0x1610007A": {
      "en": "ESC beeping. Unable to take off. Turn off ESC beeping before takeoff"
  },
  "fpv_tip_0x1610007A_in_the_sky": {
      "en": "ESC beeping. Return to home or land"
  },
  "fpv_tip_0x1610007B": {
      "en": "ESC overheated. Unable to take off. Power off aircraft and wait for temperature to return to normal"
  },
  "fpv_tip_0x1610007B_in_the_sky": {
      "en": "ESC overheated. Land promptly. Power off aircraft and wait for temperature to return to normal"
  },
  "fpv_tip_0x1610007C": {
      "en": "Battery installed incorrectly. Detach battery and reinstall it"
  },
  "fpv_tip_0x1610007D": {
      "en": "Impact detected. Takeoff failed and aircraft landed. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x1610007D_in_the_sky": {
      "en": "Impact detected. Aircraft landed. Restart aircraft"
  },
  "fpv_tip_0x1610007F": {
      "en": "Impact detected. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x1610007F_in_the_sky": {
      "en": "Impact detected. Return to home or land"
  },
  "fpv_tip_0x16100080": {
      "en": "Aircraft altitude control error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x16100080_in_the_sky": {
      "en": "Aircraft altitude control error. RTH or land"
  },
  "fpv_tip_0x16100081": {
      "en": "Battery firmware out-of-date. Unable to take off. Update to latest firmware version"
  },
  "fpv_tip_0x16100081_in_the_sky": {
      "en": "Battery firmware version out of date. Return to home or land. Update to latest firmware version"
  },
  "fpv_tip_0x16100082": {
      "en": "Large voltage different between battery cells. Unable to take off. Battery maintenance required"
  },
  "fpv_tip_0x16100082_in_the_sky": {
      "en": "Large difference in battery cell voltage detected. Return to home or land. Battery maintenance required"
  },
  "fpv_tip_0x16100083": {
      "en": "Battery installed incorrectly. Unable to take off. Turn battery locker to its limit and ensure both batteries are installed correctly"
  },
  "fpv_tip_0x16100083_in_the_sky": {
      "en": "Battery installed incorrectly. Return to home or land. Turn battery locker to its limit and ensure both batteries are installed correctly"
  },
  "fpv_tip_0x16100083_in_the_sky_pm440": {
      "en": ""
  },
  "fpv_tip_0x16100083_pm440": {
      "en": ""
  },
  "fpv_tip_0x16100084": {
      "en": "Upper fan error. Unable to take off. Check whether upper fan is stalled"
  },
  "fpv_tip_0x16100084_in_the_sky": {
      "en": "Upper fan error. Return to home or land. Check whether upper fan is stalled"
  },
  "fpv_tip_0x16100085": {
      "en": "Aircraft overheated. Unable to take off. Power off aircraft and wait for temperature to return to normal"
  },
  "fpv_tip_0x16100085_in_the_sky": {
      "en": "Aircraft overheated. Return to home or land. Power off aircraft and wait for temperature to return to normal"
  },
  "fpv_tip_0x16100087": {
      "en": "Emergency Propeller Stop triggered. Unable to take off"
  },
  "fpv_tip_0x16100087_in_the_sky": {
      "en": "Emergency Propeller Stop triggered"
  },
  "fpv_tip_0x16100088": {
      "en": "Unable to take off. Calibrating remote controller. Exit calibration first"
  },
  "fpv_tip_0x16100088_in_the_sky": {
      "en": "Unable to take off. Calibrating remote controller. Exit calibration first"
  },
  "fpv_tip_0x16100089": {
      "en": "Unable to take off. Remote controller not bound/still confirming binding status. Use bound remote controller or bind aircraft to current remote controller"
  },
  "fpv_tip_0x16100089_in_the_sky": {
      "en": "Takeoff error. Failed to verify remote controller. Use bound remote controller or bind aircraft to current remote controller"
  },
  "fpv_tip_0x1610008A": {
      "en": "Flight controller unit error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x1610008A_in_the_sky": {
      "en": "Flight controller unit error. Return to home or land"
  },
  "fpv_tip_0x1610008F": {
      "en": "Aircraft antenna satellite signal searching error. Unable to take off. Move to an open area for takeoff"
  },
  "fpv_tip_0x1610008F_in_the_sky": {
      "en": "Aircraft antenna satellite signal searching error. Fly with caution"
  },
  "fpv_tip_0x16100090": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x16100090_in_the_sky": {
      "en": "Sensor system error. Return to home or land"
  },
  "fpv_tip_0x16100091": {
      "en": "Flight prohibited in current area. Unable to take off"
  },
  "fpv_tip_0x16100091_in_the_sky": {
      "en": "Flying prohibited in current area. Return to home or land"
  },
  "fpv_tip_0x16100091_in_the_sky_special": {
      "en": "Unable to fly in this area. Max flight altitude limited to %1$s m"
  },
  "fpv_tip_0x16100092": {
      "en": "Battery capacity error. Unable to take off. Battery maintenance required"
  },
  "fpv_tip_0x16100092_in_the_sky": {
      "en": "Battery capacity error. Return to home or land. Battery maintenance required"
  },
  "fpv_tip_0x16100095": {
      "en": "Unable to take off. Set Hover mode before flying"
  },
  "fpv_tip_0x16100096": {
      "en": "%battery_index Battery power supply error. Aircraft auto landing"
  },
  "fpv_tip_0x16100099": {
      "en": "Gimbal starting error. Unable to take off. Check whether gimbal can rotate freely and restart aircraft"
  },
  "fpv_tip_0x16100099_in_the_sky": {
      "en": "Gimbal startup error. Return to home or land. Check whether gimbal can rotate freely and restart aircraft"
  },
  "fpv_tip_0x1610009A": {
      "en": "Excessive gimbal vibration. Unable to take off. Check whether gimbal can rotate freely or is damaged. Restart aircraft"
  },
  "fpv_tip_0x1610009A_in_the_sky": {
      "en": "Excessive gimbal vibration. Return to home or land. Check whether gimbal can rotate freely or is damaged. Restart aircraft"
  },
  "fpv_tip_0x1610009F": {
      "en": "Flight controller unit error. Unable to take off. Restart aircraft"
  },
  "fpv_tip_0x1610009F_in_the_sky": {
      "en": "Flight controller unit error. Return to home or land"
  },
  "fpv_tip_0x161000A0": {
      "en": "GEO module error. Unable to take off"
  },
  "fpv_tip_0x161000A0_in_the_sky": {
      "en": "GEO module error . Return to home or land promptly"
  },
  "fpv_tip_0x161000A1": {
      "en": "Unable to take off. Check whether frame arm sleeves are tightened securely"
  },
  "fpv_tip_0x161000A1_in_the_sky": {
      "en": "Land promptly and check whether frame arm sleeves are tightened securely"
  },
  "fpv_tip_0x161000A2": {
      "en": "Lower fan error. Unable to take off. Check whether lower fan is stalled"
  },
  "fpv_tip_0x161000A2_in_the_sky": {
      "en": "Lower fan error. Return to home or land. Check whether lower fan is stalled"
  },
  "fpv_tip_0x161000A3": {
      "en": "Unable to take off. Disconnect aircraft from USB and try again"
  },
  "fpv_tip_0x161000A4": {
      "en": "Unable to take off. Aircraft on moving platform"
  },
  "fpv_tip_0x161000A4_in_the_sky": {
      "en": "Flight controller error. Return to home or land promptly"
  },
  "fpv_tip_0x161000A5": {
      "en": "Unable to take off. Propellers folded"
  },
  "fpv_tip_0x161000A5_in_the_sky": {
      "en": "Flight controller error. Return to home or land promptly"
  },
  "fpv_tip_0x161000A6": {
      "en": "Remote controller battery low. Unable to take off. Recharge battery"
  },
  "fpv_tip_0x161000A6_in_the_sky": {
      "en": "Remote controller battery low. Return to home or land promptly"
  },
  "fpv_tip_0x161000A7": {
      "en": "Unable to take off. Failed to verify remote controller. Max number of temporary flights reached. Use bound remote controller or bind to another remote controller"
  },
  "fpv_tip_0x161000A7_in_the_sky": {
      "en": "Flight controller error. Return to home or land promptly"
  },
  "fpv_tip_0x161000A9": {
      "en": "Propellers in slow motion mode. Unable to take off"
  },
  "fpv_tip_0x161000A9_in_the_sky": {
      "en": "Propeller motion error. Return to home or land promptly"
  },
  "fpv_tip_0x161000AA": {
      "en": "GEO module error. Unable to take off"
  },
  "fpv_tip_0x161000AA_in_the_sky": {
      "en": "GEO module error. Return to home or land promptly"
  },
  "fpv_tip_0x161000AB": {
      "en": "Fly Safe database error. Unable to take off"
  },
  "fpv_tip_0x161000AB_in_the_sky": {
      "en": "Fly Safe database error. Return to home or land promptly"
  },
  "fpv_tip_0x161000AC": {
      "en": "Flight control system error. Unable to take off"
  },
  "fpv_tip_0x161000AC_in_the_sky": {
      "en": "Flight control system error. Land promptly"
  },
  "fpv_tip_0x161000AF": {
      "en": "Initializing flight control system. Unable to take off"
  },
  "fpv_tip_0x161000AF_in_the_sky": {
      "en": "Flight control system initialization error. Return to home or land promptly"
  },
  "fpv_tip_0x161000B1": {
      "en": "Landing gear status error. Try to release Landing Gear Auto Lock and press the transform button to reset the landing gear"
  },
  "fpv_tip_0x161000B1_in_the_sky": {
      "en": "Landing gear status error. Try to press the transform button to reset the landing gear"
  },
  "fpv_tip_0x161000B2": {
      "en": "Unable to take off. Landing gear transforming..."
  },
  "fpv_tip_0x161000B3": {
      "en": "Unable to take off. Landing gear in Travel mode. Remove gimbal camera to transform"
  },
  "fpv_tip_0x161000B4": {
      "en": "Remote ID broadcast error. Failed to obtain remote controller location. Unable to take off"
  },
  "fpv_tip_0x161000B5": {
      "en": "Remote ID module error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x161000B6": {
      "en": "Average battery power output too low. Unable to take off. Reduce payload or replace with fully charged batteries"
  },
  "fpv_tip_0x161000B7": {
      "en": "Payload exceeded max allowable weight. Reduce payload or fully charge battery"
  },
  "fpv_tip_0x161000B7_in_the_sky": {
      "en": "Payload exceeded max allowable weight. Return to home or land promptly"
  },
  "fpv_tip_0x161000B8": {
      "en": "Obstacle detected near aircraft. Unable to take off"
  },
  "fpv_tip_0x161000BD": {
      "en": "Parachute built-in battery voltage too low. Unable to take off"
  },
  "fpv_tip_0x161000BE": {
      "en": "Incompatible battery models. Unable to take off"
  },
  "fpv_tip_0x161000BF": {
      "en": "Single battery flight not enabled. Unable to take off"
  },
  "fpv_tip_0x161000C0": {
      "en": "Unable to take off. Gimbal camera with current mount not supported. Update aircraft firmware"
  },
  "fpv_tip_0x161000C4": {
      "en": "Unable to take off. Auto checking ESC..."
  },
  "fpv_tip_0x161000C7": {
      "en": "Battery temperature lower than 10°C. Unable to take off"
  },
  "fpv_tip_0x161000C7_in_the_sky": {
      "en": "Battery temperature lower than 10°C. Return to home or land promptly"
  },
  "fpv_tip_0x161000C8": {
      "en": "Unable to take off. Check whether aircraft is connected to DJI Assistant or system is updating"
  },
  "fpv_tip_0x161000C8_in_the_sky": {
      "en": "Check whether aircraft is connected to DJI Assistant or system is updating"
  },
  "fpv_tip_0x161000C9": {
      "en": "Unable to take off. Check whether aircraft is connected to DJI Assistant or system is updating"
  },
  "fpv_tip_0x161000C9_in_the_sky": {
      "en": "Check whether aircraft is connected to DJI Assistant or system is updating"
  },
  "fpv_tip_0x161000D3": {
      "en": ""
  },
  "fpv_tip_0x161000D3_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x161000D4": {
      "en": ""
  },
  "fpv_tip_0x161000D4_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x161000D5": {
      "en": ""
  },
  "fpv_tip_0x161000D5_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x161000D6": {
      "en": ""
  },
  "fpv_tip_0x161000D6_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x161000D7": {
      "en": ""
  },
  "fpv_tip_0x161000D7_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x161000D8": {
      "en": ""
  },
  "fpv_tip_0x161000D8_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x161000D9": {
      "en": ""
  },
  "fpv_tip_0x161000D9_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x161000DA": {
      "en": ""
  },
  "fpv_tip_0x161000DA_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x161000F1": {
      "en": "Unable to take off. Check whether front right frame arm is fully unfolded"
  },
  "fpv_tip_0x161000F1_in_the_sky": {
      "en": "Front right frame arm error. Land promptly"
  },
  "fpv_tip_0x161000F2": {
      "en": "Unable to take off. Check whether front left frame arm is fully unfolded"
  },
  "fpv_tip_0x161000F2_in_the_sky": {
      "en": "Front left frame arm error. Land promptly"
  },
  "fpv_tip_0x161000F3": {
      "en": "Unable to take off. Check whether back left frame arm is fully unfolded"
  },
  "fpv_tip_0x161000F3_in_the_sky": {
      "en": "Back left frame arm error. Land promptly"
  },
  "fpv_tip_0x161000F4": {
      "en": "Unable to take off. Check whether back right frame arm is fully unfolded"
  },
  "fpv_tip_0x161000F4_in_the_sky": {
      "en": "Back right frame arm error. Land promptly"
  },
  "fpv_tip_0x161000F5": {
      "en": "Initializing system. Unable to take off"
  },
  "fpv_tip_0x161000F5_in_the_sky": {
      "en": "Flight control system error. Land promptly"
  },
  "fpv_tip_0x161000F6": {
      "en": "Flight control system error. Unable to take off"
  },
  "fpv_tip_0x161000F6_in_the_sky": {
      "en": "Flight control system error. Land promptly"
  },
  "fpv_tip_0x161000F7": {
      "en": "Sensor system initialization in progress"
  },
  "fpv_tip_0x161000F7_in_the_sky": {
      "en": "Sensor system error. Land promptly or fly with caution"
  },
  "fpv_tip_0x161000F8": {
      "en": "Sensor system initialization in progress"
  },
  "fpv_tip_0x161000F8_in_the_sky": {
      "en": "Sensor system error. Land promptly"
  },
  "fpv_tip_0x161000F9": {
      "en": "Unable to take off. Remote controller signal weak. Take off near remote controller"
  },
  "fpv_tip_0x161000F9_in_the_sky": {
      "en": "Flight controller error. Return to home or land promptly"
  },
  "fpv_tip_0x161000FA": {
      "en": "Unable to take off. Remote controller signal weak. Take off near remote controller"
  },
  "fpv_tip_0x161000FA_in_the_sky": {
      "en": "Flight controller error. Return to home or land promptly"
  },
  "fpv_tip_0x161000FB": {
      "en": "Unable to take off. Propeller damaged. Contact DJI Support"
  },
  "fpv_tip_0x161000FC": {
      "en": "Unable to take off. DJI Dock in site selection process"
  },
  "fpv_tip_0x161000FD": {
      "en": "Unable to take off. Aircraft is outside of custom task area or within 10 meters of the area boundary"
  },
  "fpv_tip_0x161000FD_in_the_sky": {
      "en": "Execute task in custom task area"
  },
  "fpv_tip_0x161000a1": {
      "en": "Unable to take off. Check whether frame arm sleeves are tightened securely"
  },
  "fpv_tip_0x161000a2": {
      "en": "Lower fan error. Unable to take off. Check whether lower fan is stalled"
  },
  "fpv_tip_0x161000a2_in_the_sky": {
      "en": "Lower fan error. Return to home or land. Check whether lower fan is stalled"
  },
  "fpv_tip_0x161000a6": {
      "en": "Remote controller battery low. Unable to take off. Recharge battery"
  },
  "fpv_tip_0x161000a6_in_the_sky": {
      "en": "Remote controller battery low. Return to home or land promptly"
  },
  "fpv_tip_0x161000b4": {
      "en": "Remote ID broadcast error. Failed to obtain remote controller location. Unable to take off"
  },
  "fpv_tip_0x161000b5": {
      "en": "Remote ID module error. Unable to take off. Contact DJI Support"
  },
  "fpv_tip_0x161000c0": {
      "en": "Unable to take off: left battery and right battery models do not match. Replace battery for model consistency"
  },
  "fpv_tip_0x161000c0_in_the_sky": {
      "en": "Left battery and right battery models do not match. Land aircraft immediately and replace battery"
  },
  "fpv_tip_0x1611000A": {
      "en": "Signal lost. RTH in progress"
  },
  "fpv_tip_0x16110023": {
      "en": "Aircraft output power insufficient. Land immediately with caution"
  },
  "fpv_tip_0x16110029": {
      "en": "Payload detected. Aircraft flight and wind-resistance performances reduced"
  },
  "fpv_tip_0x1611002A": {
      "en": ""
  },
  "fpv_tip_0x1611002B": {
      "en": ""
  },
  "fpv_tip_0x1611004F": {
      "en": "Battery authentication failed"
  },
  "fpv_tip_0x16110065": {
      "en": "Aircraft overheated. AI recognition disabled. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x16110066": {
      "en": "RTK module overheated. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x16110067": {
      "en": "RTK module overheated and disabled. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1612000B": {
      "en": "Altitude too high. Using high-altitude propellers recommended"
  },
  "fpv_tip_0x1612000C": {
      "en": "Altitude too high. Use high-altitude propellers only"
  },
  "fpv_tip_0x1612000D": {
      "en": "Reaching maximum flight altitude. Pay attention to flight safety and altitude"
  },
  "fpv_tip_0x16120010": {
      "en": "Approaching ground. Fly with caution"
  },
  "fpv_tip_0x16130001": {
      "en": "Aircraft will take off from boat. Aircraft attitude angle too large. Fly with caution"
  },
  "fpv_tip_0x16130002": {
      "en": "Aircraft will take off from boat. Boat speed too fast. Fly with caution"
  },
  "fpv_tip_0x16130003": {
      "en": "Aircraft will take off from boat. Aircraft attitude angle too large. Fly with caution"
  },
  "fpv_tip_0x16140001": {},
  "fpv_tip_0x16140002": {},
  "fpv_tip_0x16200101": {
      "en": "Aircraft connected to USB device. Unable to take off"
  },
  "fpv_tip_0x16200101_in_the_sky": {
      "en": "USB port error. Fly with caution"
  },
  "fpv_tip_0x16200102": {
      "en": "Clearing logs. Unable to take off"
  },
  "fpv_tip_0x16200102_in_the_sky": {
      "en": "Clearing logs. Fly with caution"
  },
  "fpv_tip_0x16200103": {
      "en": "Updating firmware. Unable to take off"
  },
  "fpv_tip_0x16200103_in_the_sky": {
      "en": "Updating firmware. Fly with caution"
  },
  "fpv_tip_0x16200104": {
      "en": "Unable to take off. Firmware update required or update in progress"
  },
  "fpv_tip_0x16200104_in_the_sky": {
      "en": "Firmware update required. Fly with caution"
  },
  "fpv_tip_0x16200201": {
      "en": "Updating Fly Safe database. Unable to take off"
  },
  "fpv_tip_0x16200201_in_the_sky": {
      "en": "Updating Fly Safe database. Fly with caution"
  },
  "fpv_tip_0x16200202": {
      "en": "Vision sensor calibration error. Unable to take off"
  },
  "fpv_tip_0x16200202_in_the_sky": {
      "en": "Vision sensor calibration error. Fly with caution"
  },
  "fpv_tip_0x16200203": {
      "en": "ToF sensor calibration error. Unable to take off"
  },
  "fpv_tip_0x16200203_in_the_sky": {
      "en": "ToF sensor calibration error. Fly with caution"
  },
  "fpv_tip_0x16200204": {
      "en": "Vision sensor status error. Unable to take off"
  },
  "fpv_tip_0x16200204_in_the_sky": {
      "en": "Vision sensor status error. Fly with caution"
  },
  "fpv_tip_0x16200207": {
      "en": "Unable to take off. Downward infrared sensor connection error"
  },
  "fpv_tip_0x16200208": {
      "en": "Propeller error. Make sure propellers are not damaged. Contact DJI Support"
  },
  "fpv_tip_0x16200301": {
      "en": "Extension port device error. Unable to take off. Check PSDK device"
  },
  "fpv_tip_0x16200301_in_the_sky": {
      "en": "Extension port device error. Return to home or land promptly"
  },
  "fpv_tip_0x16200302": {
      "en": "Third-party payload error. Unable to take off"
  },
  "fpv_tip_0x16200302_in_the_sky": {
      "en": "Stopping propellers. Predict landing area based on current aircraft location"
  },
  "fpv_tip_0x16200401": {
      "en": "Mandatory Fly Safe database update in progress. Unable to take off"
  },
  "fpv_tip_0x16200401_in_the_sky": {
      "en": "Mandatory Fly Safe database update in progress. Fly with caution"
  },
  "fpv_tip_0x16200402": {
      "en": "Mandatory firmware update in progress. Unable to take off"
  },
  "fpv_tip_0x16200402_in_the_sky": {
      "en": "Mandatory firmware update in progress. Fly with caution"
  },
  "fpv_tip_0x16200501": {
      "en": "Incompatible battery versions. Unable to take off"
  },
  "fpv_tip_0x16200501_in_the_sky": {
      "en": "Incompatible battery versions. Return to home or land promptly"
  },
  "fpv_tip_0x16200601": {
      "en": "Initializing flight system..."
  },
  "fpv_tip_0x16200601_in_the_sky": {
      "en": "Initializing flight system..."
  },
  "fpv_tip_0x16200A01": {
      "en": "Unable to take off. Battery cover off"
  },
  "fpv_tip_0x16200A01_in_the_sky": {
      "en": "Battery communication error. Fly with caution"
  },
  "fpv_tip_0x16200C01": {
      "en": ""
  },
  "fpv_tip_0x16200C01_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x16400002": {
      "en": ""
  },
  "fpv_tip_0x16400003": {
      "en": ""
  },
  "fpv_tip_0x16400004": {
      "en": ""
  },
  "fpv_tip_0x16400005": {
      "en": ""
  },
  "fpv_tip_0x16400006": {
      "en": ""
  },
  "fpv_tip_0x16400007": {
      "en": ""
  },
  "fpv_tip_0x16400008": {
      "en": ""
  },
  "fpv_tip_0x16400009": {
      "en": ""
  },
  "fpv_tip_0x1640000A": {
      "en": ""
  },
  "fpv_tip_0x1640000B": {
      "en": ""
  },
  "fpv_tip_0x1640000C": {
      "en": ""
  },
  "fpv_tip_0x16400100": {
      "en": ""
  },
  "fpv_tip_0x16400101": {
      "en": ""
  },
  "fpv_tip_0x16400102": {
      "en": ""
  },
  "fpv_tip_0x16400103": {
      "en": ""
  },
  "fpv_tip_0x16400104": {
      "en": ""
  },
  "fpv_tip_0x16400105": {
      "en": ""
  },
  "fpv_tip_0x16400106": {
      "en": ""
  },
  "fpv_tip_0x16400107": {
      "en": ""
  },
  "fpv_tip_0x16400108": {
      "en": ""
  },
  "fpv_tip_0x16400109": {
      "en": ""
  },
  "fpv_tip_0x1640010A": {
      "en": ""
  },
  "fpv_tip_0x1640010B": {
      "en": ""
  },
  "fpv_tip_0x1640010C": {
      "en": ""
  },
  "fpv_tip_0x1640010D": {
      "en": ""
  },
  "fpv_tip_0x1640010E": {
      "en": ""
  },
  "fpv_tip_0x1640010F": {
      "en": ""
  },
  "fpv_tip_0x16400110": {
      "en": ""
  },
  "fpv_tip_0x16400111": {
      "en": ""
  },
  "fpv_tip_0x16400112": {
      "en": ""
  },
  "fpv_tip_0x16400113": {
      "en": ""
  },
  "fpv_tip_0x16400114": {
      "en": ""
  },
  "fpv_tip_0x16400115": {
      "en": ""
  },
  "fpv_tip_0x16400116": {
      "en": ""
  },
  "fpv_tip_0x16400117": {
      "en": ""
  },
  "fpv_tip_0x16400118": {
      "en": ""
  },
  "fpv_tip_0x16400119": {
      "en": ""
  },
  "fpv_tip_0x16416001": {
      "en": ""
  },
  "fpv_tip_0x16416002": {
      "en": ""
  },
  "fpv_tip_0x16418000": {
      "en": ""
  },
  "fpv_tip_0x16418001": {
      "en": ""
  },
  "fpv_tip_0x16418500": {
      "en": ""
  },
  "fpv_tip_0x16418501": {
      "en": ""
  },
  "fpv_tip_0x16418502": {
      "en": ""
  },
  "fpv_tip_0x16420000": {
      "en": ""
  },
  "fpv_tip_0x16420001": {
      "en": ""
  },
  "fpv_tip_0x16420002": {
      "en": ""
  },
  "fpv_tip_0x16420003": {
      "en": ""
  },
  "fpv_tip_0x16420004": {
      "en": ""
  },
  "fpv_tip_0x16420010": {
      "en": ""
  },
  "fpv_tip_0x16420011": {
      "en": ""
  },
  "fpv_tip_0x16420012": {
      "en": ""
  },
  "fpv_tip_0x16420013": {
      "en": ""
  },
  "fpv_tip_0x16420014": {
      "en": ""
  },
  "fpv_tip_0x16420015": {
      "en": ""
  },
  "fpv_tip_0x16420016": {
      "en": ""
  },
  "fpv_tip_0x16420020": {
      "en": ""
  },
  "fpv_tip_0x16420021": {
      "en": ""
  },
  "fpv_tip_0x16420022": {
      "en": ""
  },
  "fpv_tip_0x16420100": {
      "en": ""
  },
  "fpv_tip_0x16420101": {
      "en": ""
  },
  "fpv_tip_0x16420102": {
      "en": ""
  },
  "fpv_tip_0x16420103": {
      "en": ""
  },
  "fpv_tip_0x16420104": {
      "en": ""
  },
  "fpv_tip_0x16420105": {
      "en": ""
  },
  "fpv_tip_0x16420106": {
      "en": ""
  },
  "fpv_tip_0x16420107": {
      "en": ""
  },
  "fpv_tip_0x16420108": {
      "en": ""
  },
  "fpv_tip_0x16420109": {
      "en": ""
  },
  "fpv_tip_0x1642010A": {
      "en": ""
  },
  "fpv_tip_0x1642010B": {
      "en": ""
  },
  "fpv_tip_0x1642010C": {
      "en": ""
  },
  "fpv_tip_0x1642010D": {
      "en": ""
  },
  "fpv_tip_0x1642010E": {
      "en": ""
  },
  "fpv_tip_0x1642010F": {
      "en": ""
  },
  "fpv_tip_0x16420110": {
      "en": ""
  },
  "fpv_tip_0x16420111": {
      "en": ""
  },
  "fpv_tip_0x16420112": {
      "en": ""
  },
  "fpv_tip_0x16420113": {
      "en": ""
  },
  "fpv_tip_0x16420114": {
      "en": ""
  },
  "fpv_tip_0x16420115": {
      "en": ""
  },
  "fpv_tip_0x16420116": {
      "en": ""
  },
  "fpv_tip_0x16420117": {
      "en": ""
  },
  "fpv_tip_0x16430001": {
      "en": ""
  },
  "fpv_tip_0x16430002": {
      "en": ""
  },
  "fpv_tip_0x16430003": {
      "en": ""
  },
  "fpv_tip_0x16430004": {
      "en": ""
  },
  "fpv_tip_0x16430005": {
      "en": ""
  },
  "fpv_tip_0x16430006": {
      "en": ""
  },
  "fpv_tip_0x16430007": {
      "en": ""
  },
  "fpv_tip_0x16430008": {
      "en": ""
  },
  "fpv_tip_0x16430009": {
      "en": ""
  },
  "fpv_tip_0x1643000A": {
      "en": ""
  },
  "fpv_tip_0x1643000B": {
      "en": ""
  },
  "fpv_tip_0x1643000C": {
      "en": ""
  },
  "fpv_tip_0x1643000D": {
      "en": ""
  },
  "fpv_tip_0x1643000E": {
      "en": ""
  },
  "fpv_tip_0x1643000F": {
      "en": ""
  },
  "fpv_tip_0x16430010": {
      "en": ""
  },
  "fpv_tip_0x16430011": {
      "en": ""
  },
  "fpv_tip_0x16430012": {
      "en": ""
  },
  "fpv_tip_0x16430013": {
      "en": ""
  },
  "fpv_tip_0x16430014": {
      "en": ""
  },
  "fpv_tip_0x16430015": {
      "en": ""
  },
  "fpv_tip_0x16430016": {
      "en": ""
  },
  "fpv_tip_0x16430017": {
      "en": ""
  },
  "fpv_tip_0x16430018": {
      "en": ""
  },
  "fpv_tip_0x16430019": {
      "en": ""
  },
  "fpv_tip_0x1643001A": {
      "en": ""
  },
  "fpv_tip_0x1643001B": {
      "en": ""
  },
  "fpv_tip_0x1643001C": {
      "en": ""
  },
  "fpv_tip_0x1643001D": {
      "en": ""
  },
  "fpv_tip_0x1643001E": {
      "en": ""
  },
  "fpv_tip_0x1643001F": {
      "en": ""
  },
  "fpv_tip_0x16430020": {
      "en": ""
  },
  "fpv_tip_0x16430021": {
      "en": ""
  },
  "fpv_tip_0x16430022": {
      "en": ""
  },
  "fpv_tip_0x16430023": {
      "en": ""
  },
  "fpv_tip_0x16430024": {
      "en": ""
  },
  "fpv_tip_0x16430025": {
      "en": ""
  },
  "fpv_tip_0x16430026": {
      "en": ""
  },
  "fpv_tip_0x16430101": {
      "en": ""
  },
  "fpv_tip_0x16430102": {
      "en": ""
  },
  "fpv_tip_0x16430103": {
      "en": ""
  },
  "fpv_tip_0x16430104": {
      "en": ""
  },
  "fpv_tip_0x16430105": {
      "en": ""
  },
  "fpv_tip_0x16430106": {
      "en": ""
  },
  "fpv_tip_0x16430107": {
      "en": ""
  },
  "fpv_tip_0x16430108": {
      "en": ""
  },
  "fpv_tip_0x16430109": {
      "en": ""
  },
  "fpv_tip_0x1643010A": {
      "en": ""
  },
  "fpv_tip_0x1643010B": {
      "en": ""
  },
  "fpv_tip_0x1643010C": {
      "en": ""
  },
  "fpv_tip_0x1643010D": {
      "en": ""
  },
  "fpv_tip_0x1643010E": {
      "en": ""
  },
  "fpv_tip_0x1643010F": {
      "en": ""
  },
  "fpv_tip_0x16430110": {
      "en": ""
  },
  "fpv_tip_0x16430111": {
      "en": ""
  },
  "fpv_tip_0x16430112": {
      "en": ""
  },
  "fpv_tip_0x16430113": {
      "en": ""
  },
  "fpv_tip_0x16430114": {
      "en": ""
  },
  "fpv_tip_0x16430115": {
      "en": ""
  },
  "fpv_tip_0x16430116": {
      "en": ""
  },
  "fpv_tip_0x16430117": {
      "en": ""
  },
  "fpv_tip_0x16430118": {
      "en": ""
  },
  "fpv_tip_0x16430119": {
      "en": ""
  },
  "fpv_tip_0x1643011A": {
      "en": ""
  },
  "fpv_tip_0x1643011B": {
      "en": ""
  },
  "fpv_tip_0x1643011C": {
      "en": ""
  },
  "fpv_tip_0x1643011D": {
      "en": ""
  },
  "fpv_tip_0x1643011E": {
      "en": ""
  },
  "fpv_tip_0x16430201": {
      "en": ""
  },
  "fpv_tip_0x16430202": {
      "en": ""
  },
  "fpv_tip_0x16430203": {
      "en": ""
  },
  "fpv_tip_0x16430204": {
      "en": ""
  },
  "fpv_tip_0x16430205": {
      "en": ""
  },
  "fpv_tip_0x16430206": {
      "en": ""
  },
  "fpv_tip_0x16430207": {
      "en": ""
  },
  "fpv_tip_0x16430208": {
      "en": ""
  },
  "fpv_tip_0x16430209": {
      "en": ""
  },
  "fpv_tip_0x1643020A": {
      "en": ""
  },
  "fpv_tip_0x1643020B": {
      "en": ""
  },
  "fpv_tip_0x1643020C": {
      "en": ""
  },
  "fpv_tip_0x1643020D": {
      "en": ""
  },
  "fpv_tip_0x1643020E": {
      "en": ""
  },
  "fpv_tip_0x1643020F": {
      "en": ""
  },
  "fpv_tip_0x16430210": {
      "en": ""
  },
  "fpv_tip_0x16430211": {
      "en": ""
  },
  "fpv_tip_0x16430212": {
      "en": ""
  },
  "fpv_tip_0x16430213": {
      "en": ""
  },
  "fpv_tip_0x16430214": {
      "en": ""
  },
  "fpv_tip_0x16430215": {
      "en": ""
  },
  "fpv_tip_0x16430216": {
      "en": ""
  },
  "fpv_tip_0x16430217": {
      "en": ""
  },
  "fpv_tip_0x16430218": {
      "en": ""
  },
  "fpv_tip_0x16430219": {
      "en": ""
  },
  "fpv_tip_0x1643021A": {
      "en": ""
  },
  "fpv_tip_0x1643021B": {
      "en": ""
  },
  "fpv_tip_0x1643021C": {
      "en": ""
  },
  "fpv_tip_0x1643021D": {
      "en": ""
  },
  "fpv_tip_0x16430301": {
      "en": ""
  },
  "fpv_tip_0x16430302": {
      "en": ""
  },
  "fpv_tip_0x16430303": {
      "en": ""
  },
  "fpv_tip_0x16430304": {
      "en": ""
  },
  "fpv_tip_0x16430305": {
      "en": ""
  },
  "fpv_tip_0x16430306": {
      "en": ""
  },
  "fpv_tip_0x16430307": {
      "en": ""
  },
  "fpv_tip_0x16430308": {
      "en": ""
  },
  "fpv_tip_0x16430309": {
      "en": ""
  },
  "fpv_tip_0x1643030a": {
      "en": ""
  },
  "fpv_tip_0x1643030b": {
      "en": ""
  },
  "fpv_tip_0x1643030c": {
      "en": ""
  },
  "fpv_tip_0x1643030d": {
      "en": ""
  },
  "fpv_tip_0x1643030e": {
      "en": ""
  },
  "fpv_tip_0x1643030f": {
      "en": ""
  },
  "fpv_tip_0x16430310": {
      "en": "GNSS signal weak"
  },
  "fpv_tip_0x16430311": {
      "en": ""
  },
  "fpv_tip_0x16430312": {
      "en": ""
  },
  "fpv_tip_0x16430313": {
      "en": ""
  },
  "fpv_tip_0x16430314": {
      "en": ""
  },
  "fpv_tip_0x16430315": {
      "en": ""
  },
  "fpv_tip_0x16430316": {
      "en": ""
  },
  "fpv_tip_0x16430317": {
      "en": ""
  },
  "fpv_tip_0x16430318": {
      "en": ""
  },
  "fpv_tip_0x16430319": {
      "en": ""
  },
  "fpv_tip_0x1643031a": {
      "en": ""
  },
  "fpv_tip_0x1643031b": {
      "en": ""
  },
  "fpv_tip_0x1643031c": {
      "en": ""
  },
  "fpv_tip_0x1643031d": {
      "en": ""
  },
  "fpv_tip_0x1643031e": {
      "en": ""
  },
  "fpv_tip_0x1643031f": {
      "en": ""
  },
  "fpv_tip_0x16430320": {
      "en": ""
  },
  "fpv_tip_0x16430321": {
      "en": ""
  },
  "fpv_tip_0x16430322": {
      "en": ""
  },
  "fpv_tip_0x16430323": {
      "en": ""
  },
  "fpv_tip_0x16430324": {
      "en": ""
  },
  "fpv_tip_0x16432400": {
      "en": ""
  },
  "fpv_tip_0x16432401": {
      "en": ""
  },
  "fpv_tip_0x16432402": {
      "en": ""
  },
  "fpv_tip_0x16432403": {
      "en": ""
  },
  "fpv_tip_0x16432404": {
      "en": ""
  },
  "fpv_tip_0x16432405": {
      "en": ""
  },
  "fpv_tip_0x16432410": {
      "en": ""
  },
  "fpv_tip_0x16432411": {
      "en": ""
  },
  "fpv_tip_0x16432412": {
      "en": ""
  },
  "fpv_tip_0x16432413": {
      "en": ""
  },
  "fpv_tip_0x16432414": {
      "en": ""
  },
  "fpv_tip_0x16432415": {
      "en": ""
  },
  "fpv_tip_0x16432416": {
      "en": ""
  },
  "fpv_tip_0x16432417": {
      "en": ""
  },
  "fpv_tip_0x16432418": {
      "en": ""
  },
  "fpv_tip_0x16432419": {
      "en": ""
  },
  "fpv_tip_0x16432420": {
      "en": ""
  },
  "fpv_tip_0x16432421": {
      "en": ""
  },
  "fpv_tip_0x16432422": {
      "en": ""
  },
  "fpv_tip_0x16432423": {
      "en": ""
  },
  "fpv_tip_0x16432424": {
      "en": ""
  },
  "fpv_tip_0x16432425": {
      "en": ""
  },
  "fpv_tip_0x16432430": {
      "en": ""
  },
  "fpv_tip_0x16432431": {
      "en": ""
  },
  "fpv_tip_0x16432432": {
      "en": ""
  },
  "fpv_tip_0x16432433": {
      "en": ""
  },
  "fpv_tip_0x16432434": {
      "en": ""
  },
  "fpv_tip_0x16432435": {
      "en": ""
  },
  "fpv_tip_0x16432436": {
      "en": ""
  },
  "fpv_tip_0x16432500": {
      "en": ""
  },
  "fpv_tip_0x16432501": {
      "en": ""
  },
  "fpv_tip_0x16432502": {
      "en": ""
  },
  "fpv_tip_0x16432503": {
      "en": ""
  },
  "fpv_tip_0x16432504": {
      "en": ""
  },
  "fpv_tip_0x16432505": {
      "en": ""
  },
  "fpv_tip_0x16432506": {
      "en": ""
  },
  "fpv_tip_0x16432550": {
      "en": ""
  },
  "fpv_tip_0x16432551": {
      "en": ""
  },
  "fpv_tip_0x16450001": {
      "en": ""
  },
  "fpv_tip_0x16450002": {
      "en": ""
  },
  "fpv_tip_0x16450011": {
      "en": ""
  },
  "fpv_tip_0x16450012": {
      "en": ""
  },
  "fpv_tip_0x16450013": {
      "en": ""
  },
  "fpv_tip_0x16450014": {
      "en": ""
  },
  "fpv_tip_0x16450015": {
      "en": ""
  },
  "fpv_tip_0x16450016": {
      "en": ""
  },
  "fpv_tip_0x16450017": {
      "en": ""
  },
  "fpv_tip_0x16450018": {
      "en": ""
  },
  "fpv_tip_0x16450019": {
      "en": ""
  },
  "fpv_tip_0x16450021": {
      "en": ""
  },
  "fpv_tip_0x16450022": {
      "en": ""
  },
  "fpv_tip_0x16450023": {
      "en": ""
  },
  "fpv_tip_0x16450024": {
      "en": ""
  },
  "fpv_tip_0x16450025": {
      "en": ""
  },
  "fpv_tip_0x16450031": {
      "en": ""
  },
  "fpv_tip_0x16450032": {
      "en": ""
  },
  "fpv_tip_0x16450033": {
      "en": ""
  },
  "fpv_tip_0x16450034": {
      "en": ""
  },
  "fpv_tip_0x16450035": {
      "en": ""
  },
  "fpv_tip_0x16450101": {
      "en": ""
  },
  "fpv_tip_0x16450102": {
      "en": ""
  },
  "fpv_tip_0x16450103": {
      "en": ""
  },
  "fpv_tip_0x16450104": {
      "en": ""
  },
  "fpv_tip_0x16450105": {
      "en": ""
  },
  "fpv_tip_0x16450106": {
      "en": ""
  },
  "fpv_tip_0x16450107": {
      "en": ""
  },
  "fpv_tip_0x16450108": {
      "en": ""
  },
  "fpv_tip_0x16450109": {
      "en": "GNSS signal weak during task"
  },
  "fpv_tip_0x1645010A": {
      "en": ""
  },
  "fpv_tip_0x1645010B": {
      "en": ""
  },
  "fpv_tip_0x1645010C": {
      "en": ""
  },
  "fpv_tip_0x1645010D": {
      "en": ""
  },
  "fpv_tip_0x1645010E": {
      "en": ""
  },
  "fpv_tip_0x1645010F": {
      "en": ""
  },
  "fpv_tip_0x16450110": {
      "en": ""
  },
  "fpv_tip_0x16450111": {
      "en": ""
  },
  "fpv_tip_0x16450112": {
      "en": ""
  },
  "fpv_tip_0x16450113": {
      "en": ""
  },
  "fpv_tip_0x16450114": {
      "en": ""
  },
  "fpv_tip_0x16450115": {
      "en": ""
  },
  "fpv_tip_0x16450116": {
      "en": ""
  },
  "fpv_tip_0x16450117": {
      "en": ""
  },
  "fpv_tip_0x16450118": {
      "en": ""
  },
  "fpv_tip_0x16450119": {
      "en": ""
  },
  "fpv_tip_0x1645011A": {
      "en": ""
  },
  "fpv_tip_0x1645011B": {
      "en": ""
  },
  "fpv_tip_0x1645011C": {
      "en": ""
  },
  "fpv_tip_0x1645011D": {
      "en": ""
  },
  "fpv_tip_0x1645011E": {
      "en": ""
  },
  "fpv_tip_0x1645011F": {
      "en": ""
  },
  "fpv_tip_0x16450120": {
      "en": ""
  },
  "fpv_tip_0x16450121": {
      "en": ""
  },
  "fpv_tip_0x16450122": {
      "en": ""
  },
  "fpv_tip_0x16450123": {
      "en": ""
  },
  "fpv_tip_0x16450124": {
      "en": ""
  },
  "fpv_tip_0x16450125": {
      "en": ""
  },
  "fpv_tip_0x16450126": {
      "en": ""
  },
  "fpv_tip_0x16450127": {
      "en": ""
  },
  "fpv_tip_0x16450128": {
      "en": ""
  },
  "fpv_tip_0x16450129": {
      "en": ""
  },
  "fpv_tip_0x16461000": {
      "en": ""
  },
  "fpv_tip_0x16461001": {
      "en": ""
  },
  "fpv_tip_0x16461002": {
      "en": ""
  },
  "fpv_tip_0x16461003": {
      "en": ""
  },
  "fpv_tip_0x16462001": {
      "en": ""
  },
  "fpv_tip_0x16462004": {
      "en": ""
  },
  "fpv_tip_0x16462007": {
      "en": ""
  },
  "fpv_tip_0x1646200A": {
      "en": ""
  },
  "fpv_tip_0x16463001": {
      "en": ""
  },
  "fpv_tip_0x16463004": {
      "en": ""
  },
  "fpv_tip_0x16463005": {
      "en": ""
  },
  "fpv_tip_0x16463006": {
      "en": ""
  },
  "fpv_tip_0x16463007": {
      "en": ""
  },
  "fpv_tip_0x16463008": {
      "en": ""
  },
  "fpv_tip_0x16463009": {
      "en": ""
  },
  "fpv_tip_0x1646300A": {
      "en": ""
  },
  "fpv_tip_0x1646300B": {
      "en": ""
  },
  "fpv_tip_0x16463011": {
      "en": ""
  },
  "fpv_tip_0x16463012": {
      "en": ""
  },
  "fpv_tip_0x16463013": {
      "en": ""
  },
  "fpv_tip_0x16463014": {
      "en": ""
  },
  "fpv_tip_0x16463021": {
      "en": ""
  },
  "fpv_tip_0x16463022": {
      "en": ""
  },
  "fpv_tip_0x16463023": {
      "en": ""
  },
  "fpv_tip_0x16463024": {
      "en": ""
  },
  "fpv_tip_0x16463031": {
      "en": ""
  },
  "fpv_tip_0x16463032": {
      "en": ""
  },
  "fpv_tip_0x16463033": {
      "en": ""
  },
  "fpv_tip_0x16463034": {
      "en": ""
  },
  "fpv_tip_0x16471000": {
      "en": ""
  },
  "fpv_tip_0x16471001": {
      "en": ""
  },
  "fpv_tip_0x16471002": {
      "en": ""
  },
  "fpv_tip_0x16471003": {
      "en": ""
  },
  "fpv_tip_0x16471004": {
      "en": ""
  },
  "fpv_tip_0x16471005": {
      "en": ""
  },
  "fpv_tip_0x16471006": {
      "en": ""
  },
  "fpv_tip_0x16471007": {
      "en": ""
  },
  "fpv_tip_0x16471010": {
      "en": ""
  },
  "fpv_tip_0x16471012": {
      "en": ""
  },
  "fpv_tip_0x16471100": {
      "en": ""
  },
  "fpv_tip_0x16471101": {
      "en": ""
  },
  "fpv_tip_0x16471102": {
      "en": ""
  },
  "fpv_tip_0x16471103": {
      "en": ""
  },
  "fpv_tip_0x16471201": {
      "en": ""
  },
  "fpv_tip_0x16471204": {
      "en": ""
  },
  "fpv_tip_0x16471205": {
      "en": ""
  },
  "fpv_tip_0x16471207": {
      "en": ""
  },
  "fpv_tip_0x16471209": {
      "en": "GNSS positioning information update error"
  },
  "fpv_tip_0x16476000": {
      "en": ""
  },
  "fpv_tip_0x16476001": {
      "en": ""
  },
  "fpv_tip_0x16476002": {
      "en": ""
  },
  "fpv_tip_0x16476003": {
      "en": "Vision sensor error. Restart aircraft and try again"
  },
  "fpv_tip_0x16476004": {
      "en": ""
  },
  "fpv_tip_0x16476005": {
      "en": ""
  },
  "fpv_tip_0x16476006": {
      "en": ""
  },
  "fpv_tip_0x16476007": {
      "en": ""
  },
  "fpv_tip_0x16476008": {
      "en": ""
  },
  "fpv_tip_0x16476009": {
      "en": ""
  },
  "fpv_tip_0x16476100": {
      "en": ""
  },
  "fpv_tip_0x16476101": {
      "en": ""
  },
  "fpv_tip_0x16476102": {
      "en": ""
  },
  "fpv_tip_0x16476103": {
      "en": ""
  },
  "fpv_tip_0x16476104": {
      "en": ""
  },
  "fpv_tip_0x16476105": {
      "en": ""
  },
  "fpv_tip_0x16476106": {
      "en": ""
  },
  "fpv_tip_0x16476107": {
      "en": ""
  },
  "fpv_tip_0x16479000": {
      "en": ""
  },
  "fpv_tip_0x16479001": {
      "en": ""
  },
  "fpv_tip_0x16479002": {
      "en": ""
  },
  "fpv_tip_0x16479003": {
      "en": ""
  },
  "fpv_tip_0x16479004": {
      "en": ""
  },
  "fpv_tip_0x16479005": {
      "en": ""
  },
  "fpv_tip_0x16479006": {
      "en": ""
  },
  "fpv_tip_0x16479011": {
      "en": ""
  },
  "fpv_tip_0x16479012": {
      "en": ""
  },
  "fpv_tip_0x16479013": {
      "en": ""
  },
  "fpv_tip_0x16479014": {
      "en": ""
  },
  "fpv_tip_0x16479015": {
      "en": ""
  },
  "fpv_tip_0x16479021": {
      "en": ""
  },
  "fpv_tip_0x16479022": {
      "en": ""
  },
  "fpv_tip_0x16479031": {
      "en": ""
  },
  "fpv_tip_0x16479032": {
      "en": ""
  },
  "fpv_tip_0x16479041": {
      "en": ""
  },
  "fpv_tip_0x16479051": {
      "en": ""
  },
  "fpv_tip_0x16479052": {
      "en": ""
  },
  "fpv_tip_0x1647C010": {
      "en": ""
  },
  "fpv_tip_0x1647C011": {
      "en": ""
  },
  "fpv_tip_0x1647C012": {
      "en": ""
  },
  "fpv_tip_0x1647C013": {
      "en": ""
  },
  "fpv_tip_0x1647C014": {
      "en": ""
  },
  "fpv_tip_0x1647C015": {
      "en": ""
  },
  "fpv_tip_0x1647C016": {
      "en": ""
  },
  "fpv_tip_0x1647C017": {
      "en": ""
  },
  "fpv_tip_0x1647C018": {
      "en": ""
  },
  "fpv_tip_0x1647C019": {
      "en": ""
  },
  "fpv_tip_0x1647C020": {
      "en": ""
  },
  "fpv_tip_0x1647C021": {
      "en": ""
  },
  "fpv_tip_0x1647C022": {
      "en": ""
  },
  "fpv_tip_0x1647C023": {
      "en": ""
  },
  "fpv_tip_0x1647C024": {
      "en": ""
  },
  "fpv_tip_0x1647C025": {
      "en": ""
  },
  "fpv_tip_0x1647C030": {
      "en": ""
  },
  "fpv_tip_0x1647C031": {
      "en": ""
  },
  "fpv_tip_0x1647C032": {
      "en": ""
  },
  "fpv_tip_0x1647C033": {
      "en": ""
  },
  "fpv_tip_0x1647C034": {
      "en": ""
  },
  "fpv_tip_0x1647C035": {
      "en": ""
  },
  "fpv_tip_0x1647C040": {
      "en": ""
  },
  "fpv_tip_0x1647C041": {
      "en": ""
  },
  "fpv_tip_0x1647C042": {
      "en": ""
  },
  "fpv_tip_0x1647C043": {
      "en": ""
  },
  "fpv_tip_0x1647C044": {
      "en": ""
  },
  "fpv_tip_0x1647C045": {
      "en": ""
  },
  "fpv_tip_0x1647C046": {
      "en": ""
  },
  "fpv_tip_0x1647C047": {
      "en": ""
  },
  "fpv_tip_0x1647C048": {
      "en": ""
  },
  "fpv_tip_0x16490001": {
      "en": ""
  },
  "fpv_tip_0x16490002": {
      "en": ""
  },
  "fpv_tip_0x16490003": {
      "en": "Vertical altitude error"
  },
  "fpv_tip_0x16490004": {
      "en": ""
  },
  "fpv_tip_0x16490005": {
      "en": ""
  },
  "fpv_tip_0x16490006": {
      "en": ""
  },
  "fpv_tip_0x16490007": {
      "en": ""
  },
  "fpv_tip_0x16490008": {
      "en": ""
  },
  "fpv_tip_0x16490009": {
      "en": ""
  },
  "fpv_tip_0x1649000A": {
      "en": ""
  },
  "fpv_tip_0x1649000B": {
      "en": ""
  },
  "fpv_tip_0x1649000C": {
      "en": ""
  },
  "fpv_tip_0x1649000D": {
      "en": ""
  },
  "fpv_tip_0x1649000E": {
      "en": ""
  },
  "fpv_tip_0x1649000F": {
      "en": ""
  },
  "fpv_tip_0x16490010": {
      "en": ""
  },
  "fpv_tip_0x16490011": {
      "en": ""
  },
  "fpv_tip_0x16493000": {
      "en": ""
  },
  "fpv_tip_0x16493100": {
      "en": ""
  },
  "fpv_tip_0x16493200": {
      "en": ""
  },
  "fpv_tip_0x16493300": {
      "en": ""
  },
  "fpv_tip_0x16493400": {
      "en": ""
  },
  "fpv_tip_0x16493500": {
      "en": ""
  },
  "fpv_tip_0x16493600": {
      "en": ""
  },
  "fpv_tip_0x16494000": {
      "en": ""
  },
  "fpv_tip_0x16494001": {
      "en": ""
  },
  "fpv_tip_0x16494002": {
      "en": ""
  },
  "fpv_tip_0x16494003": {
      "en": ""
  },
  "fpv_tip_0x16494004": {
      "en": ""
  },
  "fpv_tip_0x16494100": {
      "en": ""
  },
  "fpv_tip_0x16494101": {
      "en": ""
  },
  "fpv_tip_0x16494102": {
      "en": ""
  },
  "fpv_tip_0x16494103": {
      "en": ""
  },
  "fpv_tip_0x16494104": {
      "en": ""
  },
  "fpv_tip_0x16494105": {
      "en": ""
  },
  "fpv_tip_0x16494106": {
      "en": ""
  },
  "fpv_tip_0x16497100": {
      "en": ""
  },
  "fpv_tip_0x16497110": {
      "en": ""
  },
  "fpv_tip_0x16497111": {
      "en": ""
  },
  "fpv_tip_0x16497112": {
      "en": ""
  },
  "fpv_tip_0x16497113": {
      "en": ""
  },
  "fpv_tip_0x16497120": {
      "en": ""
  },
  "fpv_tip_0x16497121": {
      "en": ""
  },
  "fpv_tip_0x16497122": {
      "en": ""
  },
  "fpv_tip_0x16497130": {
      "en": ""
  },
  "fpv_tip_0x16497131": {
      "en": ""
  },
  "fpv_tip_0x16497132": {
      "en": ""
  },
  "fpv_tip_0x16497133": {
      "en": ""
  },
  "fpv_tip_0x164A1005": {
      "en": "In No Position Hold mode, aircraft is unable to hover stably. It is recommended to switch to Normal mode before flying"
  },
  "fpv_tip_0x164A1005_in_the_sky": {
      "en": "In No Position Hold mode, aircraft is unable to hover stably, and flight safety is affected. It is recommended to switch to Normal mode"
  },
  "fpv_tip_0x164C0000": {
      "en": ""
  },
  "fpv_tip_0x164C0040": {
      "en": ""
  },
  "fpv_tip_0x164C0080": {
      "en": ""
  },
  "fpv_tip_0x164C00C0": {
      "en": ""
  },
  "fpv_tip_0x164C0100": {
      "en": ""
  },
  "fpv_tip_0x164C0101": {
      "en": ""
  },
  "fpv_tip_0x164C0102": {
      "en": ""
  },
  "fpv_tip_0x164C0103": {
      "en": ""
  },
  "fpv_tip_0x164C0104": {
      "en": ""
  },
  "fpv_tip_0x164C0105": {
      "en": ""
  },
  "fpv_tip_0x164C013E": {
      "en": ""
  },
  "fpv_tip_0x164C0140": {
      "en": ""
  },
  "fpv_tip_0x164C0180": {
      "en": ""
  },
  "fpv_tip_0x164C01C0": {
      "en": ""
  },
  "fpv_tip_0x164C0200": {
      "en": ""
  },
  "fpv_tip_0x164C0240": {
      "en": ""
  },
  "fpv_tip_0x164C0241": {
      "en": ""
  },
  "fpv_tip_0x164C0242": {
      "en": ""
  },
  "fpv_tip_0x164C0243": {
      "en": ""
  },
  "fpv_tip_0x164C0244": {
      "en": ""
  },
  "fpv_tip_0x164C0245": {
      "en": ""
  },
  "fpv_tip_0x164C027E": {
      "en": ""
  },
  "fpv_tip_0x164C0280": {
      "en": ""
  },
  "fpv_tip_0x164C0281": {
      "en": ""
  },
  "fpv_tip_0x164C0282": {
      "en": ""
  },
  "fpv_tip_0x164C0283": {
      "en": ""
  },
  "fpv_tip_0x164C0284": {
      "en": ""
  },
  "fpv_tip_0x164C0285": {
      "en": ""
  },
  "fpv_tip_0x164C02BE": {
      "en": ""
  },
  "fpv_tip_0x164C02C0": {
      "en": ""
  },
  "fpv_tip_0x164C0300": {
      "en": ""
  },
  "fpv_tip_0x164C0440": {
      "en": ""
  },
  "fpv_tip_0x164C04BE": {
      "en": ""
  },
  "fpv_tip_0x164C04FE": {
      "en": ""
  },
  "fpv_tip_0x164C053E": {
      "en": ""
  },
  "fpv_tip_0x164C057E": {
      "en": ""
  },
  "fpv_tip_0x164C05BE": {
      "en": ""
  },
  "fpv_tip_0x164C05FE": {
      "en": ""
  },
  "fpv_tip_0x164C0600": {
      "en": ""
  },
  "fpv_tip_0x164C0640": {
      "en": ""
  },
  "fpv_tip_0x164C0680": {
      "en": ""
  },
  "fpv_tip_0x164C06C0": {
      "en": ""
  },
  "fpv_tip_0x164C0700": {
      "en": ""
  },
  "fpv_tip_0x164C0740": {
      "en": ""
  },
  "fpv_tip_0x164C0780": {
      "en": ""
  },
  "fpv_tip_0x164C07C0": {
      "en": ""
  },
  "fpv_tip_0x164C0800": {
      "en": ""
  },
  "fpv_tip_0x164C0840": {
      "en": ""
  },
  "fpv_tip_0x164C0940": {
      "en": ""
  },
  "fpv_tip_0x164C09C0": {
      "en": ""
  },
  "fpv_tip_0x164C0A40": {
      "en": ""
  },
  "fpv_tip_0x164C0B00": {
      "en": ""
  },
  "fpv_tip_0x164C0BC0": {
      "en": ""
  },
  "fpv_tip_0x164C0C00": {
      "en": ""
  },
  "fpv_tip_0x164C0C40": {
      "en": ""
  },
  "fpv_tip_0x164C0C80": {
      "en": ""
  },
  "fpv_tip_0x164C0CC0": {
      "en": ""
  },
  "fpv_tip_0x164C0D40": {
      "en": ""
  },
  "fpv_tip_0x164C0D80": {
      "en": ""
  },
  "fpv_tip_0x164C0E80": {
      "en": ""
  },
  "fpv_tip_0x164C0EC0": {
      "en": ""
  },
  "fpv_tip_0x164C0ED0": {
      "en": ""
  },
  "fpv_tip_0x164C0F40": {
      "en": ""
  },
  "fpv_tip_0x164C0F80": {
      "en": ""
  },
  "fpv_tip_0x164C0d00": {
      "en": ""
  },
  "fpv_tip_0x164D0000": {
      "en": ""
  },
  "fpv_tip_0x164D0001": {
      "en": ""
  },
  "fpv_tip_0x164D0002": {
      "en": ""
  },
  "fpv_tip_0x164D0003": {
      "en": ""
  },
  "fpv_tip_0x164D0004": {
      "en": ""
  },
  "fpv_tip_0x164D0005": {
      "en": ""
  },
  "fpv_tip_0x164D0006": {
      "en": ""
  },
  "fpv_tip_0x164D0040": {
      "en": ""
  },
  "fpv_tip_0x164D0041": {
      "en": ""
  },
  "fpv_tip_0x164D0042": {
      "en": ""
  },
  "fpv_tip_0x164D0043": {
      "en": ""
  },
  "fpv_tip_0x164D0044": {
      "en": ""
  },
  "fpv_tip_0x164D0045": {
      "en": ""
  },
  "fpv_tip_0x164D0046": {
      "en": ""
  },
  "fpv_tip_0x164D0080": {
      "en": ""
  },
  "fpv_tip_0x164D0081": {
      "en": ""
  },
  "fpv_tip_0x164D0082": {
      "en": ""
  },
  "fpv_tip_0x164D0083": {
      "en": ""
  },
  "fpv_tip_0x164D0084": {
      "en": ""
  },
  "fpv_tip_0x164D0085": {
      "en": ""
  },
  "fpv_tip_0x164D0086": {
      "en": ""
  },
  "fpv_tip_0x164D0087": {
      "en": ""
  },
  "fpv_tip_0x164D0088": {
      "en": ""
  },
  "fpv_tip_0x164D0089": {
      "en": ""
  },
  "fpv_tip_0x164D008A": {
      "en": ""
  },
  "fpv_tip_0x164D008B": {
      "en": ""
  },
  "fpv_tip_0x164D008C": {
      "en": ""
  },
  "fpv_tip_0x164D008D": {
      "en": ""
  },
  "fpv_tip_0x164D008E": {
      "en": ""
  },
  "fpv_tip_0x164DFFF1": {
      "en": ""
  },
  "fpv_tip_0x164DFFF2": {
      "en": ""
  },
  "fpv_tip_0x164DFFF3": {
      "en": ""
  },
  "fpv_tip_0x164DFFF4": {
      "en": ""
  },
  "fpv_tip_0x164a0001": {
      "en": ""
  },
  "fpv_tip_0x164a0002": {
      "en": ""
  },
  "fpv_tip_0x164a0003": {
      "en": ""
  },
  "fpv_tip_0x164a0004": {
      "en": ""
  },
  "fpv_tip_0x164a0005": {
      "en": ""
  },
  "fpv_tip_0x164a1001": {
      "en": ""
  },
  "fpv_tip_0x164a1002": {
      "en": ""
  },
  "fpv_tip_0x164a1003": {
      "en": ""
  },
  "fpv_tip_0x164a1004": {
      "en": ""
  },
  "fpv_tip_0x16670000": {
      "en": "No power lines identified ahead. Power Line Follow task ended"
  },
  "fpv_tip_0x16670001": {
      "en": "Home Point not set. Check and update Home Point"
  },
  "fpv_tip_0x16670002": {
      "en": "GNSS signal weak. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670003": {
      "en": "Power Line Follow in progress. Stop task and try again"
  },
  "fpv_tip_0x16670004": {
      "en": "Power Line Follow not supported by current payload type"
  },
  "fpv_tip_0x16670005": {
      "en": "GNSS signal weak. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670006": {
      "en": "Remote controller signal lost. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670007": {
      "en": "Power Line Follow not supported in S mode. Switch to N mode"
  },
  "fpv_tip_0x16670101": {
      "en": "Too close to obstacle. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670102": {
      "en": "Approaching ground. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670103": {
      "en": "Approaching Altitude Zone. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670104": {
      "en": "Reaching max flight distance. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670105": {
      "en": "Approaching GEO Zone. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670106": {
      "en": "Approaching Altitude Zone. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670107": {
      "en": "Identifying power lines..."
  },
  "fpv_tip_0x16670108": {
      "en": "Identifying power lines..."
  },
  "fpv_tip_0x16670201": {
      "en": "Payload communication link error. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670202": {
      "en": "LiDAR communication link error. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670203": {
      "en": "Error in receiving point cloud data. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670204": {
      "en": "Point cloud recording stopped. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670205": {
      "en": "Storage full. Free up storage space before performing task"
  },
  "fpv_tip_0x16670301": {
      "en": "Flight speed of Power Line Follow exceeds limit. Confirm settings"
  },
  "fpv_tip_0x16670302": {
      "en": "Flight altitude of Power Line Follow exceeds limit. Confirm settings"
  },
  "fpv_tip_0x16670303": {
      "en": "Gimbal tilt angle exceeds limit. Confirm settings"
  },
  "fpv_tip_0x16670304": {
      "en": "Failed to identify power lines. Try again"
  },
  "fpv_tip_0x16670305": {
      "en": "Failed to identify power lines. Try again"
  },
  "fpv_tip_0x16670401": {
      "en": "Failed to recenter gimbal. Try again"
  },
  "fpv_tip_0x16670402": {
      "en": "Failed to set gimbal tilt angle. Try again"
  },
  "fpv_tip_0x16670403": {
      "en": "Failed to start recording point cloud. Try again"
  },
  "fpv_tip_0x16670501": {
      "en": "Power Line Follow task ended"
  },
  "fpv_tip_0x16670502": {
      "en": "Aircraft returning to home or landing. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670503": {
      "en": "Too close to crewed aircraft. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670504": {
      "en": "Power Line Follow task stopped"
  },
  "fpv_tip_0x16670505": {
      "en": "Failed to obtain camera view. Power Line Follow task stopped"
  },
  "fpv_tip_0x16670506": {
      "en": "Failed to calibrate IMU. Try again"
  },
  "fpv_tip_0x17000001": {
      "en": "Primary image transmission disconnected"
  },
  "fpv_tip_0x17000001_in_the_sky": {
      "en": "Primary image transmission disconnected. Adjust antennas"
  },
  "fpv_tip_0x17000002": {
      "en": "Remote controller signal weak. Adjust antennas"
  },
  "fpv_tip_0x17000002_in_the_sky": {
      "en": "Remote controller signal weak. Adjust antennas"
  },
  "fpv_tip_0x17000003": {
      "en": "Strong signal interference in aircraft location. Fly with caution"
  },
  "fpv_tip_0x17000003_in_the_sky": {
      "en": "Strong signal interference in aircraft location. Fly with caution"
  },
  "fpv_tip_0x17000004": {
      "en": "Image transmission signal weak. Adjust antennas"
  },
  "fpv_tip_0x17000004_in_the_sky": {
      "en": "Image transmission signal weak. Adjust antennas"
  },
  "fpv_tip_0x17000005": {
      "en": "Strong signal interference in remote controller location. Fly with caution"
  },
  "fpv_tip_0x17000005_in_the_sky": {
      "en": "Strong signal interference in remote controller location. Fly with caution"
  },
  "fpv_tip_0x17000006": {
      "en": "Country code not updated. Certain features unavailable. Make sure GNSS signal is good and connect to DJI Pilot to update automatically"
  },
  "fpv_tip_0x17000006_in_the_sky": {
      "en": "Country code not updated. Certain features unavailable"
  },
  "fpv_tip_0x17000011": {
      "en": "Avionics system memory insufficient. Restart aircraft to restore"
  },
  "fpv_tip_0x17000011_in_the_sky": {
      "en": "Avionics system memory insufficient. Fly with caution"
  },
  "fpv_tip_0x17000013": {
      "en": "Remote controller signal interference"
  },
  "fpv_tip_0x17000014": {
      "en": "Strong remote controller signal interference. Move away from other remote controllers or source of interference"
  },
  "fpv_tip_0x17000015": {
      "en": "Strong aircraft signal interference. Return to home promptly or move away from source of interference"
  },
  "fpv_tip_0x17000016": {
      "en": "Remote controller signal weak. Adjust antenna"
  },
  "fpv_tip_0x17000017": {
      "en": "Controller A signal weak. Make sure antennas of Controller A are unfolded and no obstacle is blocking aircraft and remote controller"
  },
  "fpv_tip_0x17000018": {
      "en": "Controller B signal weak. Make sure antennas of controller B are unfolded and no obstacle is blocking aircraft and remote controller"
  },
  "fpv_tip_0x17000019": {
      "en": "Camera error. Detach and remount gimbal"
  },
  "fpv_tip_0x1700001A": {
      "en": "Weak signal between remote controller and relay. Move remote controller or relay"
  },
  "fpv_tip_0x1700001B": {
      "en": "Weak signal between relay and aircraft. Move relay or aircraft"
  },
  "fpv_tip_0x1700001C": {
      "en": "Strong signal interference at relay site. Fly with caution"
  },
  "fpv_tip_0x17000041": {
      "en": "Unable to get control stick input"
  },
  "fpv_tip_0x17000071": {
      "en": "Avionics system overloaded. Check whether logs are being transmitted. Restart aircraft to restore"
  },
  "fpv_tip_0x17000071_in_the_sky": {
      "en": "Avionics system overloaded. Fly with caution"
  },
  "fpv_tip_0x17000081": {
      "en": "Avionics system memory insufficient. Restart aircraft to restore"
  },
  "fpv_tip_0x17000081_in_the_sky": {
      "en": "Avionics system memory insufficient. Fly with caution"
  },
  "fpv_tip_0x17010021": {
      "en": "Flight controller error. Return to home promptly"
  },
  "fpv_tip_0x17110041": {
      "en": "Unable to obtain control stick input"
  },
  "fpv_tip_0x17110041_in_the_sky": {
      "en": "Control stick input error. Fly with caution and land promptly"
  },
  "fpv_tip_0x17200020": {
      "en": "Winch system motor stalled"
  },
  "fpv_tip_0x17200022": {
      "en": "Winch system motor overloaded"
  },
  "fpv_tip_0x1720002B": {
      "en": "Winch system ESC overheated"
  },
  "fpv_tip_0x1720002C": {
      "en": "Winch system ESC voltage too high"
  },
  "fpv_tip_0x1720002D": {
      "en": "Winch system ESC voltage too low"
  },
  "fpv_tip_0x1720002F": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200030": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200031": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200032": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200033": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200034": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200035": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200036": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x17200037": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x17200038": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x17200039": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x1720003A": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x1720003B": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x1720003C": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x1720003D": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x1720003E": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x1720003F": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200040": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x17200041": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x17200042": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x17200043": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x17200044": {
      "en": "Winch system ESC voltage too low"
  },
  "fpv_tip_0x17200045": {
      "en": "Winch system ESC voltage too high"
  },
  "fpv_tip_0x17200046": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200047": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200048": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200049": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x1720004A": {
      "en": "Winch system ESC overheated"
  },
  "fpv_tip_0x1720004B": {
      "en": "Winch system ESC temperature too low"
  },
  "fpv_tip_0x1720004C": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x1720004D": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x1720004E": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x17200055": {
      "en": "Winch system motor may be overheated. Do not quickly reel up and down when aircraft is loaded"
  },
  "fpv_tip_0x17200056": {
      "en": "Winch system motor severely overheated. Aircraft will land automatically"
  },
  "fpv_tip_0x17200058": {
      "en": "Winch system ESC speed and current error"
  },
  "fpv_tip_0x1720005D": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x1720005E": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x17200077": {
      "en": "Winch system ESC severely overheated"
  },
  "fpv_tip_0x17200078": {
      "en": "Winch system ESC severely overheated"
  },
  "fpv_tip_0x17200079": {
      "en": "Winch system ESC firmware incompatible with aircraft model"
  },
  "fpv_tip_0x1720007B": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x1720007C": {
      "en": "Winch system ESC auto-check error"
  },
  "fpv_tip_0x1720007D": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x1720007E": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x1720007F": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200080": {
      "en": "Winch system ESC or motor auto-check error"
  },
  "fpv_tip_0x17200083": {
      "en": "Winch system ESC calibration failed"
  },
  "fpv_tip_0x17200200": {
      "en": "Cable stuck or reversely reeled in. Enable repair mode to manually unwind cable"
  },
  "fpv_tip_0x17200201": {
      "en": "Cable cutter of winch system damaged. Replace cable cutter promptly. Otherwise, flight safety will be affected"
  },
  "fpv_tip_0x17200201_in_the_sky": {
      "en": "Cable cutter of winch system damaged. Replace cable cutter promptly. Otherwise, flight safety will be affected"
  },
  "fpv_tip_0x17200202": {
      "en": "Winch reeling down without payload. Attach payload"
  },
  "fpv_tip_0x17200203": {
      "en": "Winch system motor not calibrated. Calibrate motor"
  },
  "fpv_tip_0x17200204": {
      "en": "Winch system triaxial force sensor not calibrated. Calibrate sensor"
  },
  "fpv_tip_0x17200205": {
      "en": "Winch system cable length not calibrated. Calibrate cable length"
  },
  "fpv_tip_0x17200206": {
      "en": "Winch brake hardware damaged. Restart aircraft and try again"
  },
  "fpv_tip_0x17200207": {
      "en": "Winch brake auto-check error. Unable to take off"
  },
  "fpv_tip_0x17200208": {
      "en": "Winch cable auto-check error. Check if cable is wound in reverse direction or not secure"
  },
  "fpv_tip_0x17200209": {
      "en": "Winch limit switch auto-check error. Stop propellers and rotate dial manually. Make sure winch system motor works properly"
  },
  "fpv_tip_0x1720020A": {
      "en": "Winch limit switch error. Check if the switch is stuck or damaged"
  },
  "fpv_tip_0x17210000": {
      "en": "Parachute sensor error"
  },
  "fpv_tip_0x17210001": {
      "en": "Parachute igniter error"
  },
  "fpv_tip_0x17210002": {
      "en": "Parachute connection error"
  },
  "fpv_tip_0x17210003": {
      "en": "Parachute igniter communication error"
  },
  "fpv_tip_0x17210004": {
      "en": "Parachute igniter connection error"
  },
  "fpv_tip_0x17210005": {
      "en": "Parachute igniter voltage error"
  },
  "fpv_tip_0x17210006": {
      "en": "Parachute igniter resistance error"
  },
  "fpv_tip_0x17210007": {
      "en": "Parachute ignition error"
  },
  "fpv_tip_0x17210008": {
      "en": "Parachute IMU communication error"
  },
  "fpv_tip_0x17210009": {
      "en": "Parachute IMU connection error"
  },
  "fpv_tip_0x1721000A": {
      "en": "Parachute barometer communication error"
  },
  "fpv_tip_0x1721000B": {
      "en": "Parachute internal storage error"
  },
  "fpv_tip_0x1721000C": {
      "en": "Parachute control error"
  },
  "fpv_tip_0x1721000D": {
      "en": "Parachute connection error"
  },
  "fpv_tip_0x1721000E": {
      "en": "Parachute speed deviation too large"
  },
  "fpv_tip_0x1721000F": {
      "en": "Parachute built-in battery voltage too low"
  },
  "fpv_tip_0x17210010": {
      "en": "Parachute Built-in Battery Overheated"
  },
  "fpv_tip_0x17210011": {
      "en": "Parachute File System Error"
  },
  "fpv_tip_0x17210013": {
      "en": "Parachute deployed"
  },
  "fpv_tip_0x17210020": {
      "en": "Parachute opened. Watch out for pedestrians nearby"
  },
  "fpv_tip_0x19000001": {
      "en": "Avionics system overloaded. Check whether logs are being transmitted. Restart aircraft to restore"
  },
  "fpv_tip_0x19000001_in_the_sky": {
      "en": "Avionics system overloaded. Fly with caution"
  },
  "fpv_tip_0x19000002": {
      "en": "Avionics system overloaded. Check whether logs are being transmitted. Restart aircraft to restore"
  },
  "fpv_tip_0x19000002_in_the_sky": {
      "en": "Avionics system overloaded. Fly with caution"
  },
  "fpv_tip_0x19000003": {
      "en": "存在业务cpu loading超限"
  },
  "fpv_tip_0x19000004": {
      "en": "存在业务内存资源使用超限"
  },
  "fpv_tip_0x19000005": {
      "en": "中断频率过高"
  },
  "fpv_tip_0x19000006": {
      "en": "内存申请失败"
  },
  "fpv_tip_0x19000007": {
      "en": "rt进程cpu loading过高"
  },
  "fpv_tip_0x19000008": {
      "en": "系统内存不足"
  },
  "fpv_tip_0x19000009": {
      "en": "系统内存严重不足"
  },
  "fpv_tip_0x1900000A": {
      "en": "rcu stall异常"
  },
  "fpv_tip_0x1900000B": {
      "en": "进程长期处于D状态"
  },
  "fpv_tip_0x1900000C": {
      "en": "原子操作异常"
  },
  "fpv_tip_0x1900000D": {
      "en": "自旋锁操作异常"
  },
  "fpv_tip_0x19000011": {
      "en": "Avionics system memory insufficient. Restart aircraft to restore"
  },
  "fpv_tip_0x19000011_in_the_sky": {
      "en": "Avionics system memory insufficient. Fly with caution"
  },
  "fpv_tip_0x19000012": {
      "en": "Avionics system memory insufficient. Restart aircraft to restore"
  },
  "fpv_tip_0x19000012_in_the_sky": {
      "en": "Avionics system memory insufficient. Fly with caution"
  },
  "fpv_tip_0x19000020": {
      "en": "camera进程异常崩溃"
  },
  "fpv_tip_0x19000021": {
      "en": "Avionics system error. Restart aircraft to restore"
  },
  "fpv_tip_0x19000021_in_the_sky": {
      "en": "Avionics system error. Fly with caution"
  },
  "fpv_tip_0x19000022": {
      "en": "Avionics system error. Restart aircraft to restore"
  },
  "fpv_tip_0x19000022_in_the_sky": {
      "en": "Avionics system error. Fly with caution"
  },
  "fpv_tip_0x19000023": {
      "en": "相机麦克风进程异常奔溃"
  },
  "fpv_tip_0x19000024": {
      "en": "相机进程异常崩溃"
  },
  "fpv_tip_0x19000025": {
      "en": "相机进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x19000026": {
      "en": "相机进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x19000027": {
      "en": "相机进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x19000028": {
      "en": "嵌入式dji_sys进程异常崩溃"
  },
  "fpv_tip_0x19000029": {
      "en": "嵌入式日志进程异常崩溃"
  },
  "fpv_tip_0x1900002A": {
      "en": "嵌入式图传进程异常崩溃"
  },
  "fpv_tip_0x1900002B": {
      "en": "嵌入式进程异常崩溃"
  },
  "fpv_tip_0x1900002C": {
      "en": "嵌入式进程dji_gateway异常崩溃"
  },
  "fpv_tip_0x1900002D": {
      "en": "进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x1900002E": {
      "en": "进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x1900002F": {
      "en": "相机进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x19000030": {
      "en": "飞行系统感知进程异常崩溃"
  },
  "fpv_tip_0x19000031": {
      "en": "Unable to take off. Clear data and restart aircraft"
  },
  "fpv_tip_0x19000031_in_the_sky": {
      "en": "Restart aircraft after clearing data"
  },
  "fpv_tip_0x19000032": {
      "en": "飞行系统进程异常崩溃"
  },
  "fpv_tip_0x19000033": {
      "en": "飞行系统rtk_fusion进程异常崩溃"
  },
  "fpv_tip_0x19000034": {
      "en": "CRobot进程异常崩溃"
  },
  "fpv_tip_0x19000035": {
      "en": "进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x19000036": {
      "en": "机器学习进程异常崩溃"
  },
  "fpv_tip_0x19000037": {
      "en": "进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x19000038": {
      "en": "进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x19000039": {
      "en": "电池进程异常崩溃"
  },
  "fpv_tip_0x1900003A": {
      "en": "进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x1900003B": {
      "en": "进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x1900003C": {
      "en": "激光雷达进程进程异常崩溃"
  },
  "fpv_tip_0x1900003D": {
      "en": "进程发生crash，请拉日志分析"
  },
  "fpv_tip_0x1900003E": {
      "en": "System panic"
  },
  "fpv_tip_0x1900003F": {
      "en": "进程异常崩溃"
  },
  "fpv_tip_0x19000041": {
      "en": "USB connection detected. Unable to take off. Disconnect USB cable and restart aircraft"
  },
  "fpv_tip_0x19000041_in_the_sky": {
      "en": "USB connection detected. Fly with caution and land promptly"
  },
  "fpv_tip_0x19000042": {
      "en": "Unable to take off. Clear data and restart aircraft"
  },
  "fpv_tip_0x19000042_in_the_sky": {
      "en": "Restart aircraft after clearing data"
  },
  "fpv_tip_0x19000051": {
      "en": "Firmware updating. Unable to take off. Wait for update to complete"
  },
  "fpv_tip_0x19000051_in_the_sky": {
      "en": "Firmware updating. Fly with caution and land promptly"
  },
  "fpv_tip_0x19000060": {
      "en": "Selinux permission check error"
  },
  "fpv_tip_0x19000061": {
      "en": "Selinux permission check errors"
  },
  "fpv_tip_0x19000070": {
      "en": "File system error. File may be damaged or storage card may be removed. Remove and insert storage card or format storage card"
  },
  "fpv_tip_0x190003ED": {
      "en": "dji_wlm_slave cpu loading超限"
  },
  "fpv_tip_0x190003EE": {
      "en": "logd loading 超限"
  },
  "fpv_tip_0x190003EF": {
      "en": "dji_autoflight cpu loading 超限"
  },
  "fpv_tip_0x190003F1": {
      "en": "dji_sdrs_agent cpu loading 超限"
  },
  "fpv_tip_0x190003F2": {
      "en": "dji_lte cpu loading超限"
  },
  "fpv_tip_0x190003F3": {
      "en": "dji_wlm cpu loading超限"
  },
  "fpv_tip_0x190003F5": {
      "en": "dji_nn_server loading超限"
  },
  "fpv_tip_0x190003F6": {
      "en": "dji_ml cpu loading超限"
  },
  "fpv_tip_0x190003F9": {
      "en": "dji_media_server cpu loading 超限"
  },
  "fpv_tip_0x190003FB": {
      "en": "dji_rcam cpu loading  超限"
  },
  "fpv_tip_0x190003FC": {
      "en": "dji_camera cpu loading超限"
  },
  "fpv_tip_0x190003FD": {
      "en": "dji_perception cpu loading超限"
  },
  "fpv_tip_0x190003FE": {
      "en": "dji_blackbox loading超限"
  },
  "fpv_tip_0x190003FF": {
      "en": "dji_sys loading超限"
  },
  "fpv_tip_0x19000400": {
      "en": "SD异常"
  },
  "fpv_tip_0x19000401": {},
  "fpv_tip_0x19000402": {},
  "fpv_tip_0x19000403": {},
  "fpv_tip_0x19000600": {},
  "fpv_tip_0x19000601": {},
  "fpv_tip_0x19000602": {},
  "fpv_tip_0x19000603": {},
  "fpv_tip_0x19000604": {},
  "fpv_tip_0x19000605": {},
  "fpv_tip_0x19000606": {},
  "fpv_tip_0x19000607": {},
  "fpv_tip_0x19000608": {},
  "fpv_tip_0x19000609": {},
  "fpv_tip_0x1900060A": {},
  "fpv_tip_0x1900060B": {},
  "fpv_tip_0x19000700": {
      "en": "File system error. File may be damaged or storage card may be removed. Remove and insert storage card or format storage card"
  },
  "fpv_tip_0x19000701": {
      "en": "磁盘扫描异常"
  },
  "fpv_tip_0x19000702": {
      "en": "File system error"
  },
  "fpv_tip_0x19000703": {
      "en": "SD card not recommended. User experience may be\r\n affected"
  },
  "fpv_tip_0x19000800": {
      "en": "Aircraft fan %index error. Check whether fan is stalled"
  },
  "fpv_tip_0x19000801": {
      "en": "Camera fan error. Check whether fan is stalled"
  },
  "fpv_tip_0x19000802": {
      "en": "Camera processor overheated. Wait for camera processor to cool down before use"
  },
  "fpv_tip_0x19000803": {
      "en": "Camera overheated. Recording will stop soon. Wait for camera to cool down before use"
  },
  "fpv_tip_0x19000810": {
      "en": "Aircraft in low-power state"
  },
  "fpv_tip_0x19000811": {
      "en": "Air unit in low-power state"
  },
  "fpv_tip_0x19000C00": {
      "en": "Connection error between avionics board and radio frequency board. Downward radar and video transmission performance may be affected"
  },
  "fpv_tip_0x1900E001": {
      "en": "Log storage space insufficient. Unable to store logs"
  },
  "fpv_tip_0x1900E002": {
      "en": "Log data missing"
  },
  "fpv_tip_0x1900E003": {
      "en": "Log data missing"
  },
  "fpv_tip_0x1900E004": {
      "en": "Storage device mounting error"
  },
  "fpv_tip_0x1900F001": {
      "en": "Goggles only support motion controller"
  },
  "fpv_tip_0x19010002": {},
  "fpv_tip_0x19018001": {
      "en": "System in factory mode"
  },
  "fpv_tip_0x1901C080": {
      "en": "Current aircraft does not support working with both Zenmuse L2 and H30 series. Use one of the payloads separately"
  },
  "fpv_tip_0x1901C081": {
      "en": "Current aircraft does not support working with more than one Zenmuse H30 series payload. Use one of the payloads separately"
  },
  "fpv_tip_0x1901C082": {
      "en": "Current aircraft does not support working with more than one Zenmuse L2 payload. Use one of the payloads separately"
  },
  "fpv_tip_0x19100041": {
      "en": "Livestream network connection unavailable"
  },
  "fpv_tip_0x19100041_in_the_sky": {
      "en": "No livestream network connection"
  },
  "fpv_tip_0x19100042": {
      "en": "Livestream registration password error"
  },
  "fpv_tip_0x19100042_in_the_sky": {
      "en": "Incorrect livestream registration password"
  },
  "fpv_tip_0x19100043": {
      "en": "Livestream registration timed out"
  },
  "fpv_tip_0x19100043_in_the_sky": {
      "en": "Livestream registration timed out"
  },
  "fpv_tip_0x19100044": {
      "en": "Livestream channel %d connection failed"
  },
  "fpv_tip_0x19100044_in_the_sky": {
      "en": "Failed to connect to livestream channel %d"
  },
  "fpv_tip_0x19100045": {
      "en": "Livestream channel %d URL parameter format error"
  },
  "fpv_tip_0x19100045_in_the_sky": {
      "en": "Livestream channel %d URL parameter format error"
  },
  "fpv_tip_0x19100051": {
      "en": "Selecting livestream channel %d timed out"
  },
  "fpv_tip_0x19100051_in_the_sky": {
      "en": "Livestream channel %d timed out"
  },
  "fpv_tip_0x19100052": {
      "en": "Selecting livestream channel %d. Parameters error"
  },
  "fpv_tip_0x19100052_in_the_sky": {
      "en": "Livestream channel %d parameter error"
  },
  "fpv_tip_0x19100053": {
      "en": "Selecting livestream channel %d received no response"
  },
  "fpv_tip_0x19100053_in_the_sky": {
      "en": "Livestream channel %d timed out"
  },
  "fpv_tip_0x19100054": {
      "en": "删除"
  },
  "fpv_tip_0x19100054_in_the_sky": {
      "en": "Livestream channel %d timed out"
  },
  "fpv_tip_0x19100055": {
      "en": "Livestream channel %d successfully selected. No streaming data"
  },
  "fpv_tip_0x19100055_in_the_sky": {
      "en": "Livestream channel %d connected. No streaming data yet"
  },
  "fpv_tip_0x19100056": {
      "en": "Livestream channel %d bit rate error"
  },
  "fpv_tip_0x19100056_in_the_sky": {
      "en": "Livestream channel %d bit rate error"
  },
  "fpv_tip_0x19100057": {
      "en": "Livestream channel %d frame rate error"
  },
  "fpv_tip_0x19100057_in_the_sky": {
      "en": "Livestream channel %d frame rate error"
  },
  "fpv_tip_0x19100058": {
      "en": "Livestream channel %d data sending error"
  },
  "fpv_tip_0x19100058_in_the_sky": {
      "en": "Failed to send livestream channel %d data"
  },
  "fpv_tip_0x19100071": {
      "en": "Livestream channel %d losing data packet"
  },
  "fpv_tip_0x19100071_in_the_sky": {
      "en": "Livestream channel %d packet loss"
  },
  "fpv_tip_0x19100072": {
      "en": "Livestream channel %d experiencing high network latency"
  },
  "fpv_tip_0x19100072_in_the_sky": {
      "en": "Network latency on livestream channel %d"
  },
  "fpv_tip_0x19100073": {
      "en": "Livestream channel %d experiencing high network jitter"
  },
  "fpv_tip_0x19100073_in_the_sky": {
      "en": "Network jitter on livestream channel %d"
  },
  "fpv_tip_0x19100074": {
      "en": "Livestream channel %d experiencing network congestion"
  },
  "fpv_tip_0x19100074_in_the_sky": {
      "en": "Network congestion on livestream channel %d"
  },
  "fpv_tip_0x19100081": {
      "en": "Livestream metadata transfer frequency error"
  },
  "fpv_tip_0x19100081_in_the_sky": {
      "en": "Livestream metadata transfer frequency error"
  },
  "fpv_tip_0x19100082": {
      "en": "Livestream unable to get metadata"
  },
  "fpv_tip_0x19100082_in_the_sky": {
      "en": "Failed to send metadata"
  },
  "fpv_tip_0x19100083": {
      "en": "Livestream unable to get aircraft location"
  },
  "fpv_tip_0x19100083_in_the_sky": {
      "en": "Failed to send aircraft location info"
  },
  "fpv_tip_0x19117042": {
      "en": ""
  },
  "fpv_tip_0x19117100": {
      "en": ""
  },
  "fpv_tip_0x19117121": {
      "en": ""
  },
  "fpv_tip_0x19117200": {
      "en": ""
  },
  "fpv_tip_0x19117300": {
      "en": ""
  },
  "fpv_tip_0x19117420": {
      "en": ""
  },
  "fpv_tip_0x19117440": {
      "en": ""
  },
  "fpv_tip_0x19200001": {
      "en": "Payload system CPU usage too high. Restart aircraft to restore"
  },
  "fpv_tip_0x19200001_in_the_sky": {
      "en": "Payload system CPU usage too high. Camera tasks may be affected"
  },
  "fpv_tip_0x19200011": {
      "en": "Payload system memory low. Restart aircraft to restore"
  },
  "fpv_tip_0x19200011_in_the_sky": {
      "en": "Payload system low on memory. Camera tasks may be affected"
  },
  "fpv_tip_0x19200021": {
      "en": "Payload system error. Restart aircraft to restore"
  },
  "fpv_tip_0x19200021_in_the_sky": {
      "en": "Payload system error. Camera tasks may be affected"
  },
  "fpv_tip_0x1A010004": {
      "en": ""
  },
  "fpv_tip_0x1A010006": {},
  "fpv_tip_0x1A010007": {
      "en": "System in factory mode. Contact DJI Support"
  },
  "fpv_tip_0x1A010040": {
      "en": "Bottom (left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010040_in_the_sky": {
      "en": "Bottom (left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010041": {
      "en": "Bottom (right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010041_in_the_sky": {
      "en": "Bottom (right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010042": {
      "en": "Front (left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010042_in_the_sky": {
      "en": "Front (left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010043": {
      "en": "Front (right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010043_in_the_sky": {
      "en": "Front (right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010044": {
      "en": "Back (left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010044_in_the_sky": {
      "en": "Back (left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010045": {
      "en": "Back (right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010045_in_the_sky": {
      "en": "Back (right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010046": {
      "en": "Top (left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010046_in_the_sky": {
      "en": "Top (left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010047": {
      "en": "Top (right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010047_in_the_sky": {
      "en": "Top (right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010048": {
      "en": "Side (back left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010048_in_the_sky": {
      "en": "Side (back left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010049": {
      "en": "Side (front left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010049_in_the_sky": {
      "en": "Side (front left) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A01004A": {
      "en": "Side (front right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A01004A_in_the_sky": {
      "en": "Side (front right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A01004B": {
      "en": "Side (back right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A01004B_in_the_sky": {
      "en": "Side (back right) vision sensor connection error. Fly with caution. Return to home or land (%alarmid)"
  },
  "fpv_tip_0x1A010080": {
      "en": "Bottom (left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010080_in_the_sky": {
      "en": "Bottom (left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A010081": {
      "en": "Bottom (right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010081_in_the_sky": {
      "en": "Bottom (right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A010082": {
      "en": "Front (left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010082_in_the_sky": {
      "en": "Front (left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A010083": {
      "en": "Front (right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010083_in_the_sky": {
      "en": "Front (right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A010084": {
      "en": "Back (left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010084_in_the_sky": {
      "en": "Back (left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A010085": {
      "en": "Back (right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010085_in_the_sky": {
      "en": "Back (right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A010086": {
      "en": "Top (left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010086_in_the_sky": {
      "en": "Top (left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A010087": {
      "en": "Top (right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010087_in_the_sky": {
      "en": "Top (right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A010088": {
      "en": "Side (back left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010088_in_the_sky": {
      "en": "Side (back right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A010089": {
      "en": "Side (front left) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A010089_in_the_sky": {
      "en": "Side (front left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A01008A": {
      "en": "Side (front right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A01008A_in_the_sky": {
      "en": "Side (front right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A01008B": {
      "en": "Side (back right) vision sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A01008B_in_the_sky": {
      "en": "Side (back right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C0": {
      "en": "Bottom (left) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C0_in_the_sky": {
      "en": "Bottom (left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C1": {
      "en": "Bottom (right) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C1_in_the_sky": {
      "en": "Bottom (right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C2": {
      "en": "Front (left) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C2_in_the_sky": {
      "en": "Front (left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C3": {
      "en": "Front (right) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C3_in_the_sky": {
      "en": "Front (right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C4": {
      "en": "Back (left) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C4_in_the_sky": {
      "en": "Back (left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C5": {
      "en": "Back (right) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C5_in_the_sky": {
      "en": "Back (right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C6": {
      "en": "Top (left) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C6_in_the_sky": {
      "en": "Top (left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C7": {
      "en": "Top (right) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C7_in_the_sky": {
      "en": "Back (right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C8": {
      "en": "Side (back left) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C8_in_the_sky": {
      "en": "Side (back left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100C9": {
      "en": "Side (front left) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100C9_in_the_sky": {
      "en": "Side (front left) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100CA": {
      "en": "Side (front right) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100CA_in_the_sky": {
      "en": "Side (front right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100CB": {
      "en": "Side (back right) vision sensor connection error. Restart sensor"
  },
  "fpv_tip_0x1A0100CB_in_the_sky": {
      "en": "Side (back right) vision sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0100F0": {
      "en": ""
  },
  "fpv_tip_0x1A011340": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A011341": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A011342": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A011343": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A011344": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A011345": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A011346": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A011347": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A011348": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A011349": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A01134A": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A01134B": {
      "en": "Vision system calibration error"
  },
  "fpv_tip_0x1A020040": {
      "en": "Bottom infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020040_in_the_sky": {
      "en": "Bottom infrared sensor connection error. Return to home or land. Pay attention to the landing speed"
  },
  "fpv_tip_0x1A020041": {
      "en": "Front infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020041_in_the_sky": {
      "en": "Front infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A020042": {
      "en": "Right infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020042_in_the_sky": {
      "en": "Right infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A020043": {
      "en": "Back infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020043_in_the_sky": {
      "en": "Back infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A020044": {
      "en": "Left infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020044_in_the_sky": {
      "en": "Left infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A020045": {
      "en": "Top infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020045_in_the_sky": {
      "en": "Top infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A020080": {
      "en": "Bottom infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020080_in_the_sky": {
      "en": "Bottom infrared sensor connection error. Return to Home or land. Pay attention to the landing speed"
  },
  "fpv_tip_0x1A020081": {
      "en": "Front infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020081_in_the_sky": {
      "en": "Front infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A020082": {
      "en": "Right infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020082_in_the_sky": {
      "en": "Right infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A020083": {
      "en": "Back infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020083_in_the_sky": {
      "en": "Back infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A020084": {
      "en": "Left infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020084_in_the_sky": {
      "en": "Left infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A020085": {
      "en": "Top infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A020085_in_the_sky": {
      "en": "Top infrared sensor connection error. Fly with caution. Return to home or land"
  },
  "fpv_tip_0x1A0200C0": {
      "en": "Downward Infrared Sensor Connection Error"
  },
  "fpv_tip_0x1A0200C0_in_the_sky": {
      "en": "Bottom infrared sensor connection error. Return to home or land. Pay attention to the landing speed"
  },
  "fpv_tip_0x1A0200C1": {
      "en": "Forward infrared sensor connection error"
  },
  "fpv_tip_0x1A0200C1_in_the_sky": {
      "en": "Front infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A0200C2": {
      "en": "Right infrared sensor connection error"
  },
  "fpv_tip_0x1A0200C2_in_the_sky": {
      "en": "Right infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A0200C3": {
      "en": "Backward infrared sensor connection error"
  },
  "fpv_tip_0x1A0200C3_in_the_sky": {
      "en": "Back infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A0200C4": {
      "en": "Left infrared sensor connection error"
  },
  "fpv_tip_0x1A0200C4_in_the_sky": {
      "en": "Left infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A0200C5": {
      "en": "Upward infrared sensor connection error"
  },
  "fpv_tip_0x1A0200C5_in_the_sky": {
      "en": "Top infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A020100": {
      "en": "Downward infrared sensor calibration error"
  },
  "fpv_tip_0x1A020101": {
      "en": "Forward infrared sensor calibration error"
  },
  "fpv_tip_0x1A020102": {
      "en": "Right infrared sensor calibration error"
  },
  "fpv_tip_0x1A020103": {
      "en": "Backward infrared sensor calibration error"
  },
  "fpv_tip_0x1A020104": {
      "en": "Left infrared sensor calibration error"
  },
  "fpv_tip_0x1A020105": {
      "en": "Upward infrared sensor calibration error"
  },
  "fpv_tip_0x1A020140": {
      "en": "Infrared sensors blocked"
  },
  "fpv_tip_0x1A020180": {
      "en": "Infrared sensors overheated. Return to home or land promptly. Move away from high-temperature environment"
  },
  "fpv_tip_0x1A020400": {
      "en": "Low visibility. Fly with caution"
  },
  "fpv_tip_0x1A030040": {
      "en": "Bottom infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030040_in_the_sky": {
      "en": "Bottom infrared sensor connection error. Return to home or land. Pay attention to the landing speed"
  },
  "fpv_tip_0x1A030041": {
      "en": "Front infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030041_in_the_sky": {
      "en": "Front infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030042": {
      "en": "Back infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030042_in_the_sky": {
      "en": "Back infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030043": {
      "en": "Top infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030043_in_the_sky": {
      "en": "Top infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030044": {
      "en": "Left infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030044_in_the_sky": {
      "en": "Left infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030045": {
      "en": "Right infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030045_in_the_sky": {
      "en": "Right infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030080": {
      "en": "Bottom infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030080_in_the_sky": {
      "en": "Bottom infrared sensor connection error. Pay attention to the landing speed. Return to home or land"
  },
  "fpv_tip_0x1A030081": {
      "en": "Front infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030081_in_the_sky": {
      "en": "Front infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030082": {
      "en": "Back infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030082_in_the_sky": {
      "en": "Back infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030083": {
      "en": "Top infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030083_in_the_sky": {
      "en": "Top infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030084": {
      "en": "Left infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030084_in_the_sky": {
      "en": "Left infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030085": {
      "en": "Right infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030085_in_the_sky": {
      "en": "Right infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030180": {
      "en": "Bottom infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030180_in_the_sky": {
      "en": "Bottom infrared sensor connection error. Pay attention to the landing speed. Return to home or land"
  },
  "fpv_tip_0x1A030181": {
      "en": "Front infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030181_in_the_sky": {
      "en": "Front infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030182": {
      "en": "Back infrared sensor connection error. Restart aircraft"
  },
  "fpv_tip_0x1A030182_in_the_sky": {
      "en": "Back infrared sensor connection error. Return to home or land"
  },
  "fpv_tip_0x1A030183": {
      "en": ""
  },
  "fpv_tip_0x1A030184": {
      "en": ""
  },
  "fpv_tip_0x1A030185": {
      "en": ""
  },
  "fpv_tip_0x1A0301C0": {
      "en": ""
  },
  "fpv_tip_0x1A0301C1": {
      "en": ""
  },
  "fpv_tip_0x1A0301C2": {
      "en": ""
  },
  "fpv_tip_0x1A0301C3": {
      "en": ""
  },
  "fpv_tip_0x1A0301C4": {
      "en": ""
  },
  "fpv_tip_0x1A0301C5": {
      "en": ""
  },
  "fpv_tip_0x1A040001": {
      "en": "Power mode error. Restart aircraft"
  },
  "fpv_tip_0x1A050040": {
      "en": "Obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A050041": {
      "en": "Obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A050042": {
      "en": "Obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A050043": {
      "en": "Obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A060041": {
      "en": ""
  },
  "fpv_tip_0x1A060042": {
      "en": ""
  },
  "fpv_tip_0x1A060043": {
      "en": ""
  },
  "fpv_tip_0x1A060080": {
      "en": ""
  },
  "fpv_tip_0x1A060081": {
      "en": ""
  },
  "fpv_tip_0x1A060082": {
      "en": ""
  },
  "fpv_tip_0x1A060083": {
      "en": ""
  },
  "fpv_tip_0x1A0600C0": {
      "en": ""
  },
  "fpv_tip_0x1A0600C1": {
      "en": ""
  },
  "fpv_tip_0x1A0600C2": {
      "en": ""
  },
  "fpv_tip_0x1A0600C3": {
      "en": ""
  },
  "fpv_tip_0x1A080080": {
      "en": ""
  },
  "fpv_tip_0x1A080081": {
      "en": ""
  },
  "fpv_tip_0x1A080082": {
      "en": ""
  },
  "fpv_tip_0x1A080083": {
      "en": ""
  },
  "fpv_tip_0x1A080084": {
      "en": ""
  },
  "fpv_tip_0x1A080085": {
      "en": ""
  },
  "fpv_tip_0x1A090043": {
      "en": "Upward LiDAR connection error. Fly with caution"
  },
  "fpv_tip_0x1A090043_in_the_sky": {
      "en": "Upward LiDAR connection error. Fly with caution and return to home promptly"
  },
  "fpv_tip_0x1A210180": {
      "en": "Sensor system error. Land promptly or fly with caution"
  },
  "fpv_tip_0x1A210181": {
      "en": "Sensor system error. Land promptly or fly with caution"
  },
  "fpv_tip_0x1A210182": {
      "en": "Sensor system error. Land promptly or fly with caution"
  },
  "fpv_tip_0x1A210183": {
      "en": "Sensor system error. Land promptly or fly with caution"
  },
  "fpv_tip_0x1A210184": {
      "en": "Sensor system error. Land promptly or fly with caution"
  },
  "fpv_tip_0x1A210185": {
      "en": "Sensor system error. Land promptly or fly with caution"
  },
  "fpv_tip_0x1A2101C0": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1A2101C1": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1A2101C2": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1A2101C3": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1A2101C4": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1A2101C5": {
      "en": "Sensor system error. Restart aircraft"
  },
  "fpv_tip_0x1A210240": {
      "en": "Bottom vision sensor is blocked. Obstacle avoidance capabilities decreased. Fly with caution"
  },
  "fpv_tip_0x1A210241": {
      "en": "Front vision sensor is blocked. Obstacle avoidance capabilities decreased. Fly with caution"
  },
  "fpv_tip_0x1A210242": {
      "en": "Back vision sensor is blocked. Obstacle avoidance capabilities decreased. Fly with caution"
  },
  "fpv_tip_0x1A210243": {
      "en": "Right-side vision sensor is blocked. Obstacle avoidance capabilities decreased. Fly with caution"
  },
  "fpv_tip_0x1A210244": {
      "en": "Left-side vision sensor is blocked. Obstacle avoidance capabilities decreased. Fly with caution"
  },
  "fpv_tip_0x1A210245": {
      "en": "Top vision sensor is blocked. Obstacle avoidance capabilities decreased. Fly with caution"
  },
  "fpv_tip_0x1A310980": {
      "en": "Vision positioning system error"
  },
  "fpv_tip_0x1A310981": {
      "en": "Vision positioning system error"
  },
  "fpv_tip_0x1A420040": {
      "en": "Downward obstacle sensing system error"
  },
  "fpv_tip_0x1A420041": {
      "en": "Forward obstacle sensing system error"
  },
  "fpv_tip_0x1A420042": {
      "en": "Right obstacle sensing system error"
  },
  "fpv_tip_0x1A420043": {
      "en": "Backward obstacle sensing system error"
  },
  "fpv_tip_0x1A420044": {
      "en": "Left obstacle sensing system error"
  },
  "fpv_tip_0x1A420045": {
      "en": "Upward obstacle sensing system error"
  },
  "fpv_tip_0x1A420440": {
      "en": "Obstacle sensing system error"
  },
  "fpv_tip_0x1A4205C0": {
      "en": "Obstacle sensing system error"
  },
  "fpv_tip_0x1A420680": {
      "en": "Obstacle sensing system error"
  },
  "fpv_tip_0x1A420BC0": {
      "en": "Downward ambient light too low. Downward obstacle sensing unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420BC1": {
      "en": "Forward ambient light too low. Forward obstacle sensing unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420BC2": {
      "en": "Backward ambient light too low. Backward obstacle sensing unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420BC3": {
      "en": "Right ambient light too low. Right obstacle sensing unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420BC4": {
      "en": "Left ambient light too low. Left obstacle sensing unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420BC5": {
      "en": "Upward ambient light too low. Upward obstacle sensing unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420BC6": {
      "en": "Horizontal ambient light too low. Horizontal obstacle sensing unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420C00": {
      "en": "Downward ambient light too bright. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C01": {
      "en": "Forward ambient light too bright. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C02": {
      "en": "Backward ambient light too bright. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C03": {
      "en": "Rightward ambient light too bright. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C04": {
      "en": "Leftward ambient light too bright. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C05": {
      "en": "Upward ambient light too bright. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C06": {
      "en": "Horizontal ambient light too bright. Horizontal obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C40": {
      "en": "Downward sensor(s) blocked. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C41": {
      "en": "Forward sensor(s) blocked. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C42": {
      "en": "Backward sensor(s) blocked. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C43": {
      "en": "Rightward sensor(s) blocked. Rightward obstacle avoidance unavailable. Only infrared sensors available.  Fly with caution"
  },
  "fpv_tip_0x1A420C44": {
      "en": "Leftward sensor(s) blocked. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C45": {
      "en": "Upward sensor(s) blocked. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1A420C46": {
      "en": "Payload detected. Aircraft obstacle sensing capabilities decreased. Check radar chart and fly with caution"
  },
  "fpv_tip_0x1A420C80": {
      "en": "Downward sensor(s) blurry. Downward obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420C81": {
      "en": "Forward sensor(s) blurry. Forward obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420C82": {
      "en": "Backward sensor(s) blurry. Backward obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420C83": {
      "en": "Right sensor(s) blurry. Rightward obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420C84": {
      "en": "Left sensor(s) blurry. Leftward obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420C85": {
      "en": "Upward sensor(s) blurry. Upward obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420CC0": {
      "en": "Aircraft attitude angle too large. Obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1A420D00": {
      "en": "Aircraft attitude angle too large. Landing protection unavailable. Land manually"
  },
  "fpv_tip_0x1A420D40": {
      "en": "Aircraft approaching obstacle sensing blind spot and may be unable to detect obstacles. Fly with caution"
  },
  "fpv_tip_0x1A421B00": {
      "en": "Vision system disabled. Switched to radar obstacle avoidance. The obstacle avoidance available range will change"
  },
  "fpv_tip_0x1A421B40": {
      "en": ""
  },
  "fpv_tip_0x1A421B80": {
      "en": ""
  },
  "fpv_tip_0x1A430680": {
      "en": "Unstable lighting conditions. Vision system and obstacle sensing may be unavailable. Fly with caution"
  },
  "fpv_tip_0x1A510380": {
      "en": "Downward vision sensor calibration error"
  },
  "fpv_tip_0x1A510381": {
      "en": "Forward vision sensor calibration error"
  },
  "fpv_tip_0x1A510382": {
      "en": "Backward vision sensor calibration error"
  },
  "fpv_tip_0x1A510383": {
      "en": "Upward vision sensor calibration error"
  },
  "fpv_tip_0x1A510384": {
      "en": "Left vision sensor calibration error"
  },
  "fpv_tip_0x1A510385": {
      "en": "Right vision sensor calibration error"
  },
  "fpv_tip_0x1A5103C0": {
      "en": "Downward vision sensor calibration error"
  },
  "fpv_tip_0x1A5103C1": {
      "en": "Forward vision sensor calibration error"
  },
  "fpv_tip_0x1A5103C2": {
      "en": "Backward vision sensor calibration error"
  },
  "fpv_tip_0x1A5103C3": {
      "en": "Upward vision sensor calibration error"
  },
  "fpv_tip_0x1A5103C4": {
      "en": "Left vision sensor calibration error"
  },
  "fpv_tip_0x1A5103C5": {
      "en": "Right vision sensor calibration error"
  },
  "fpv_tip_0x1A5104C0": {
      "en": "Downward obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A5104C1": {
      "en": "Forward obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A5104C2": {
      "en": "Backward obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A5104C3": {
      "en": "Upward obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A5104C4": {
      "en": "Left obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A5104C5": {
      "en": "Right obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A5104C6": {
      "en": "Omnidirectional obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A5104C7": {
      "en": "Upward and downward obstacle sensing error. Fly with caution"
  },
  "fpv_tip_0x1A680040": {
      "en": "Propeller guards mounted. Obstacle Avoidance unavailable. Flight performance and wind resistance performance reduced"
  },
  "fpv_tip_0x1A680080": {
      "en": "Propeller guards removed"
  },
  "fpv_tip_0x1AFC0040": {
      "en": ""
  },
  "fpv_tip_0x1AFC0080": {},
  "fpv_tip_0x1AFC0100": {},
  "fpv_tip_0x1AFC0140": {},
  "fpv_tip_0x1AFD0040": {
      "en": "Sensor system error. Unable to take off. Flight prohibited"
  },
  "fpv_tip_0x1AFD0040_in_the_sky": {
      "en": "Sensor system error. Return to home or land promptly"
  },
  "fpv_tip_0x1AFE0040": {
      "en": "Vision system overloaded. Fly to open area"
  },
  "fpv_tip_0x1B010001": {
      "en": "Navigation system error . Restart aircraft"
  },
  "fpv_tip_0x1B010002": {
      "en": "Target Acquisition unavailable with current payload"
  },
  "fpv_tip_0x1B010003": {
      "en": "Camera mode error. Smart Track disabled"
  },
  "fpv_tip_0x1B010004": {
      "en": "Target too close"
  },
  "fpv_tip_0x1B010005": {
      "en": "Target lost. Target Acquisition paused"
  },
  "fpv_tip_0x1B010006": {
      "en": "Gimbal not facing flight direction. Fly with caution"
  },
  "fpv_tip_0x1B010007": {
      "en": "Target Acquisition paused . Switch to P mode"
  },
  "fpv_tip_0x1B010007_n_mode": {
      "en": "Target Acquisition stopped. Switch to N mode"
  },
  "fpv_tip_0x1B010008": {
      "en": "Enabling Target Acquisition failed"
  },
  "fpv_tip_0x1B010009": {
      "en": "Obstacle detected. Circling stopped. Manually control aircraft to avoid obstacle"
  },
  "fpv_tip_0x1B01000A": {
      "en": "GEO Zone nearby. Circling stopped. Manually control aircraft to avoid GEO Zone"
  },
  "fpv_tip_0x1B01000B": {
      "en": "Smart Track paused"
  },
  "fpv_tip_0x1B01000C": {
      "en": "Target moving too fast. Circling stopped"
  },
  "fpv_tip_0x1B01000D": {
      "en": "Unusual target movement. Drag-select target again"
  },
  "fpv_tip_0x1B01000E": {
      "en": "GNSS signal weak, and vision positioning unavailable. Aircraft switched to A mode. Control aircraft manually"
  },
  "fpv_tip_0x1B01000F": {
      "en": "Target lost. Searching..."
  },
  "fpv_tip_0x1B010010": {
      "en": "During Smart Track, you can control gimbals within a certain limit"
  },
  "fpv_tip_0x1B010011": {
      "en": "During Smart Track, you can control lens zoom within a certain limit"
  },
  "fpv_tip_0x1B010401": {
      "en": "Camera sending data error. Target acquisition stopped. Try again later"
  },
  "fpv_tip_0x1B010402": {
      "en": "Camera sending data error. Smart Track stopped. Try again later"
  },
  "fpv_tip_0x1B010403": {
      "en": "Camera data sending error. Smart Track paused."
  },
  "fpv_tip_0x1B010404": {
      "en": "Camera sending data error. Smart Track stopped. Try again later"
  },
  "fpv_tip_0x1B010405": {
      "en": "Camera sending data error. Smart Track stopped. Try again later"
  },
  "fpv_tip_0x1B010406": {
      "en": "Camera sending data error. Smart Track stopped. Try again later"
  },
  "fpv_tip_0x1B010407": {
      "en": "Camera sending data error. Smart Track stopped. Try again later"
  },
  "fpv_tip_0x1B010408": {
      "en": "Camera sending data error. Smart Track stopped. Try again later"
  },
  "fpv_tip_0x1B010801": {
      "en": "DJI Pilot error. Target Acquisition paused. Restart DJI Pilot"
  },
  "fpv_tip_0x1B010802": {
      "en": "DJI Pilot error. Smart Track paused. Restart DJI Pilot"
  },
  "fpv_tip_0x1B010803": {
      "en": "DJI Pilot error. Smart Track paused. Restart DJI Pilot"
  },
  "fpv_tip_0x1B010C01": {
      "en": "Flight Controller data sending error. Target Acquisition paused. Restart aircraft"
  },
  "fpv_tip_0x1B010C02": {
      "en": "Flight Controller data sending error. Smart Track paused. Restart aircraft"
  },
  "fpv_tip_0x1B010C03": {
      "en": "Flight Controller data sending error. Smart Track paused. Restart aircraft"
  },
  "fpv_tip_0x1B011001": {
      "en": "Gimbal data sending error. Target Acquisition paused. Reinstall payload"
  },
  "fpv_tip_0x1B011002": {
      "en": "Gimbal data sending error. Smart Track paused. Reinstall payload"
  },
  "fpv_tip_0x1B011003": {
      "en": "Gimbal data sending error. Smart Track paused. Reinstall payload"
  },
  "fpv_tip_0x1B011801": {
      "en": "Remote controller data sending error. Target Acquisition stopped. Restart RC"
  },
  "fpv_tip_0x1B011802": {
      "en": "Remote controller data sending error. Target Acquisition paused. Check connection between RC and aircraft"
  },
  "fpv_tip_0x1B030001": {
      "en": "Obstacle detected. RTH stopped. Manually control aircraft to avoid obstacle"
  },
  "fpv_tip_0x1B030002": {
      "en": "GEO Zone nearby. RTH stopped. Manually control aircraft to avoid GEO Zone"
  },
  "fpv_tip_0x1B030003": {
      "en": "RTH error. Fly aircraft manually"
  },
  "fpv_tip_0x1B030004": {
      "en": "Aircraft signal lost. RTH started"
  },
  "fpv_tip_0x1B030005": {
      "en": "Enabling RTH Obstacle Check failed. Control aircraft to return to home manually"
  },
  "fpv_tip_0x1B030006": {
      "en": "Obstacle avoidance or backlight detected. Press the Pause button on the remote controller to exit RTH and manually control aircraft to return to home"
  },
  "fpv_tip_0x1B030007": {
      "en": "Control stick pushed down. Aircraft descending. Aircraft RTH canceled"
  },
  "fpv_tip_0x1B030008": {
      "en": "Control stick pushed down. Aircraft flying backward. Aircraft RTH canceled"
  },
  "fpv_tip_0x1B03000D": {
      "en": "Positioning poor during RTH. Unable to continue RTH. Manually control aircraft to return to home"
  },
  "fpv_tip_0x1B030010": {
      "en": "Approaching or already evening. Setting RTH altitude recommended"
  },
  "fpv_tip_0x1B030011": {
      "en": "Failed to fly optimal RTH route. Aircraft returning at preset altitude"
  },
  "fpv_tip_0x1B030012": {
      "en": "Aircraft accelerating. Battery draining faster"
  },
  "fpv_tip_0x1B030013": {
      "en": "RTH stopped. Control stick moved opposite to flight direction"
  },
  "fpv_tip_0x1B030014": {
      "en": "Battery level low. Return to home promptly"
  },
  "fpv_tip_0x1B030015": {
      "en": "Home Point in Altitude Zone. Aircraft will return at lower altitude"
  },
  "fpv_tip_0x1B030016": {
      "en": "Complex environment. RTH stopped. Manually control aircraft to return to home"
  },
  "fpv_tip_0x1B030017": {
      "en": "Unable to return to home due to low battery level. Aircraft may land earlier. Selecting alternate landing site recommended"
  },
  "fpv_tip_0x1B030018": {
      "en": "Max flight altitude limit too low. Flight safety during RTH affected"
  },
  "fpv_tip_0x1B030019": {
      "en": "Aircraft not flying along route. Safe RTH exited"
  },
  "fpv_tip_0x1B03001A": {
      "en": "Vision positioning unavailable. Unable to return to home. Confirm area below is safe and land aircraft manually"
  },
  "fpv_tip_0x1B03002B": {
      "en": "Obstacle sensed during RTH. Pay attention to remaining battery level and be prepared to manually control aircraft"
  },
  "fpv_tip_0x1B03002C": {
      "en": ""
  },
  "fpv_tip_0x1B03002D": {
      "en": ""
  },
  "fpv_tip_0x1B03002E": {
      "en": ""
  },
  "fpv_tip_0x1B03002F": {
      "en": ""
  },
  "fpv_tip_0x1B030030": {
      "en": "Aircraft approaching Dynamic Home Point. RTH exited. Control aircraft manually"
  },
  "fpv_tip_0x1B030031": {
      "en": ""
  },
  "fpv_tip_0x1B030032": {
      "en": ""
  },
  "fpv_tip_0x1B030033": {
      "en": "Aircraft power consumption higher than estimated. Pay attention to remaining battery level and fly safely"
  },
  "fpv_tip_0x1B030C01": {},
  "fpv_tip_0x1B030C02": {
      "en": "GNSS signal weak. RTH accuracy low. It is recommended to manually control aircraft to return to home"
  },
  "fpv_tip_0x1B040001": {
      "en": "Focused shooting unavailable with current payload"
  },
  "fpv_tip_0x1B040002": {
      "en": "Focused shooting failed . Switched to normal shooting mode"
  },
  "fpv_tip_0x1B040003": {
      "en": "AI Spot-Check failed. Switched to normal shooting mode"
  },
  "fpv_tip_0x1B040004": {
      "en": "Camera not mounted. Focused shooting failed"
  },
  "fpv_tip_0x1B040401": {
      "en": "Camera zoom timed out. AI Spot-Check failed. Try again later"
  },
  "fpv_tip_0x1B040402": {
      "en": "Switching shooting modes failed. AI Spot-Check failed. Try again later"
  },
  "fpv_tip_0x1B040403": {
      "en": "Camera zoom timed out. AI Spot-Check failed. Try again later"
  },
  "fpv_tip_0x1B040801": {
      "en": "Unable to locate focused shooting sample. Reupload flight route"
  },
  "fpv_tip_0x1B040802": {
      "en": "Target box parameter error. Readjust target box"
  },
  "fpv_tip_0x1B041001": {
      "en": "Gimbal error. Focused shooting failed. Reinstall payload"
  },
  "fpv_tip_0x1B050010": {
      "en": "Failed to update Dynamic Home Point. Unable to obtain remote controller positioning info"
  },
  "fpv_tip_0x1B050011": {
      "en": "Remote controller positioning info obtained. Updating Dynamic Home Point..."
  },
  "fpv_tip_0x1B050012": {
      "en": "Failed to update Dynamic Home Point. Remote controller positioning accuracy low"
  },
  "fpv_tip_0x1B050013": {
      "en": "Dynamic Home Point update stopped. Remote controller in Restricted Zone"
  },
  "fpv_tip_0x1B050014": {
      "en": "Remote controller not in Restricted Zone. Updating Dynamic Home Point..."
  },
  "fpv_tip_0x1B050015": {
      "en": "Home Point update stopped. Remote controller far away from aircraft"
  },
  "fpv_tip_0x1B050016": {
      "en": "Failed to update Dynamic Home Point. Unable to obtain remote controller positioning info"
  },
  "fpv_tip_0x1B050017": {
      "en": "Failed to update Dynamic Home Point. Remote controller positioning accuracy low"
  },
  "fpv_tip_0x1B060001": {
      "en": "Flight speed limited. Firmware update required"
  },
  "fpv_tip_0x1B060005": {},
  "fpv_tip_0x1B070001": {
      "en": "Payload exceeds capacity. Reduce payload or adjust flight plan"
  },
  "fpv_tip_0x1B070001_in_the_sky": {
      "en": "Payload exceeds capacity. Land promptly and reduce payload"
  },
  "fpv_tip_0x1B070002": {
      "en": "Payload weight not evenly distributed. Adjust payload placement"
  },
  "fpv_tip_0x1B070002_in_the_sky": {
      "en": "Payload weight not evenly distributed. Land promptly and adjust payload placement"
  },
  "fpv_tip_0x1B070003": {
      "en": "Cargo case weight sensor error"
  },
  "fpv_tip_0x1B070004": {
      "en": "Weight sensor error. Calibrate cargo case weight sensor"
  },
  "fpv_tip_0x1B070005": {
      "en": "Weight sensor error. Calibrate cargo case weight sensor"
  },
  "fpv_tip_0x1B070006": {
      "en": "Payload exceeds capacity. Reduce payload or adjust flight plan"
  },
  "fpv_tip_0x1B070006_in_the_sky": {
      "en": "Payload exceeds capacity. Land promptly and reduce payload"
  },
  "fpv_tip_0x1B070008": {
      "en": "Winch system weight sensor error"
  },
  "fpv_tip_0x1B070009": {
      "en": "Weight sensor error. Calibrate winch system weight sensor"
  },
  "fpv_tip_0x1B07000A": {
      "en": "Weight sensor error. Calibrate winch system weight sensor"
  },
  "fpv_tip_0x1B07000B": {
      "en": "Swing angle too large. Fly with caution"
  },
  "fpv_tip_0x1B07000C": {
      "en": "Cable broke. Check payload on winch system"
  },
  "fpv_tip_0x1B07000D": {
      "en": "Winch system motor stalled. Check payload on winch system"
  },
  "fpv_tip_0x1B07000E": {
      "en": "Winch system motor overheated. Check if payload exceeds capacity"
  },
  "fpv_tip_0x1B080001": {
      "en": "Remote ID broadcast error. Failed to obtain remote controller location"
  },
  "fpv_tip_0x1B080002": {
      "en": "Remote ID module error. Contact DJI Support"
  },
  "fpv_tip_0x1B090001": {
      "en": "Target tracking stopped"
  },
  "fpv_tip_0x1B090002": {
      "en": "Target tracking stopped"
  },
  "fpv_tip_0x1B090003": {
      "en": "Target tracking stopped"
  },
  "fpv_tip_0x1B092C01": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C02": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C03": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C04": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C05": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C06": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C07": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C08": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C09": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C0A": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C0B": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C0C": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C0D": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C0E": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C0F": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C10": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C11": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C12": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C13": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C14": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C15": {
      "en": "Target identification error"
  },
  "fpv_tip_0x1B092C16": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C17": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C18": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C19": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C1A": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C1B": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C1C": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C1D": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C1E": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C1F": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C20": {
      "en": "Enabling Smart Track failed. Ensure selected target is valid"
  },
  "fpv_tip_0x1B092C21": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C22": {
      "en": "Enabling Smart Track failed. Reduce selected area"
  },
  "fpv_tip_0x1B092C23": {
      "en": "Enabling Smart Track failed. Increase selected area"
  },
  "fpv_tip_0x1B092C24": {
      "en": "Enabling Smart Track failed. Ensure selected target is valid"
  },
  "fpv_tip_0x1B092C25": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B092C26": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093001": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093002": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093003": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093004": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093005": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093006": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093007": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093008": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093009": {
      "en": "Enabling Smart Track failed. Increase selected area"
  },
  "fpv_tip_0x1B09300A": {
      "en": "Enabling Smart Track failed. Ensure selected target is valid"
  },
  "fpv_tip_0x1B09300B": {
      "en": "Target lost. Exited Target Acquisition"
  },
  "fpv_tip_0x1B09300C": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B09300D": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B09300E": {
      "en": "Camera parameters changed. Exited Target Acquisition"
  },
  "fpv_tip_0x1B09300F": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093010": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093011": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093012": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093013": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093014": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093015": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093016": {
      "en": "Target lost. Exited Smart Track"
  },
  "fpv_tip_0x1B093017": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B093018": {
      "en": "Target too far away. Exited Target Acquisition"
  },
  "fpv_tip_0x1B093019": {
      "en": "Camera parameters changed. Exited Smart Track"
  },
  "fpv_tip_0x1B09301A": {
      "en": "Enabling Smart Track failed. Check and try again"
  },
  "fpv_tip_0x1B0A0001": {
      "en": "GNSS signal weak. Geo-awareness function degraded. Fly with caution"
  },
  "fpv_tip_0x1B0A0002": {
      "en": "Failed to load dynamic safety data. Geo-awareness function degraded. It is recommended to update FlySafe database"
  },
  "fpv_tip_0x1B0A0003": {
      "en": "Failed to query dynamic safety data. Geo-awareness function degraded. It is recommended to update FlySafe database"
  },
  "fpv_tip_0x1B0A0004": {
      "en": "FlySafe data density high in current region. Aircraft may not be able to fully process data. It is recommended to update FlySafe database"
  },
  "fpv_tip_0x1B0B0001": {
      "en": "Unable to take off. Failed to obtain aircraft location. Take off in open area"
  },
  "fpv_tip_0x1B0B0001_in_the_sky": {
      "en": "Failed to obtain aircraft location. Take off in open area"
  },
  "fpv_tip_0x1B0B0002": {
      "en": "Unable to take off. Take off inside geo-cage"
  },
  "fpv_tip_0x1B0B0002_in_the_sky": {
      "en": "Fly aircraft inside geo-cage"
  },
  "fpv_tip_0x1B0B0003": {
      "en": "Unable to take off. No geo-cage data found"
  },
  "fpv_tip_0x1B0B0003_in_the_sky": {
      "en": "No geo-cage data found. Return to home promptly"
  },
  "fpv_tip_0x1B0B0004": {
      "en": "Unable to take off. Geo-cage data error"
  },
  "fpv_tip_0x1B0B0004_in_the_sky": {
      "en": "Geo-cage data error. Return to home promptly"
  },
  "fpv_tip_0x1B0B0005": {
      "en": "Unable to take off. Geo-cage function error"
  },
  "fpv_tip_0x1B0B0005_in_the_sky": {
      "en": "Geo-cage function error. Return to home promptly"
  },
  "fpv_tip_0x1B0B0006": {
      "en": "Unable to fly automatically. Manually fly aircraft back to flight geography area"
  },
  "fpv_tip_0x1B0B0006_in_the_sky": {
      "en": "Unable to fly automatically. Manually fly aircraft back to flight geography area"
  },
  "fpv_tip_0x1B0B0007": {
      "en": "Home Point should be set in flight geography area of geo-cage"
  },
  "fpv_tip_0x1B0B0007_in_the_sky": {
      "en": "Home Point should be set in flight geography area of geo-cage"
  },
  "fpv_tip_0x1B0B0008": {
      "en": "Unable to return to home automatically. Manually fly aircraft back to flight geography area"
  },
  "fpv_tip_0x1B0B0008_in_the_sky": {
      "en": "Unable to return to home automatically. Manually fly aircraft back to flight geography area"
  },
  "fpv_tip_0x1B0C0001": {
      "en": ""
  },
  "fpv_tip_0x1B0C0001_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x1B0C0002": {
      "en": ""
  },
  "fpv_tip_0x1B0C0002_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x1B0C0003": {
      "en": ""
  },
  "fpv_tip_0x1B0C0003_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x1B0C0004": {
      "en": ""
  },
  "fpv_tip_0x1B0C0004_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x1B0C0005": {
      "en": ""
  },
  "fpv_tip_0x1B0C0005_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x1B0C0006": {
      "en": ""
  },
  "fpv_tip_0x1B0C0006_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x1B0C0007": {
      "en": ""
  },
  "fpv_tip_0x1B0C0007_in_the_sky": {
      "en": ""
  },
  "fpv_tip_0x1B100000": {
      "en": "Target lost. Tracking stopped"
  },
  "fpv_tip_0x1B100001": {
      "en": "Target too small. Easy to lose"
  },
  "fpv_tip_0x1B300001": {
      "en": "Unable to obtain cargo case data. Make sure cargo case is installed properly"
  },
  "fpv_tip_0x1B300002": {
      "en": "Winch system communication error. Check winch system connection"
  },
  "fpv_tip_0x1B300003": {
      "en": "Payload exceeds capacity. Reduce payload and adjust flight plan"
  },
  "fpv_tip_0x1B300004": {
      "en": "Payload exceeds capacity. Cancel flight"
  },
  "fpv_tip_0x1B300005": {
      "en": "Payload weight not evenly distributed. Secure payload in center of cargo case"
  },
  "fpv_tip_0x1B300006": {
      "en": "Cargo case severely imbalanced. Secure payload in center of cargo case"
  },
  "fpv_tip_0x1B300007": {
      "en": "Cargo case wobbling. Secure cargo case and payload inside"
  },
  "fpv_tip_0x1B300008": {
      "en": "Cargo case wobbles significantly. Secure cargo case and payload inside"
  },
  "fpv_tip_0x1B300009": {
      "en": "Payload on winch system exceeds capacity. Reduce payload and adjust flight plan"
  },
  "fpv_tip_0x1B30000A": {
      "en": "Payload on winch system exceeds capacity. Cancel flight"
  },
  "fpv_tip_0x1B30000B": {
      "en": "Payload swings slightly. Fly with caution"
  },
  "fpv_tip_0x1B30000C": {
      "en": "Payload swings significantly. Fly with caution"
  },
  "fpv_tip_0x1B30000D": {
      "en": "Payload on winch system approaching obstacle. Fly with caution"
  },
  "fpv_tip_0x1B30000E": {
      "en": "Payload on winch system very close to obstacle. Fly with caution"
  },
  "fpv_tip_0x1B30000F": {
      "en": "Extending or retracting winch cable is prohibited during flight. Make sure aircraft is hovering before extending or retracting winch cable"
  },
  "fpv_tip_0x1B300010": {
      "en": "Payload swings greatly. Do not reel up winch cable. Wait until payload swing reduces before reeling up"
  },
  "fpv_tip_0x1B310001": {
      "en": "Battery level too low to complete flight mission. Adjust flight plan"
  },
  "fpv_tip_0x1B310001_in_the_sky": {
      "en": "Battery level too low to complete flight mission. Adjust flight plan"
  },
  "fpv_tip_0x1B310002": {
      "en": "Battery level too low to fly to alternate landing site. Land promptly"
  },
  "fpv_tip_0x1B310002_in_the_sky": {
      "en": "Battery level too low to fly to alternate landing site. Land promptly"
  },
  "fpv_tip_0x1B310003": {
      "en": "Current payload exceeds propulsion system capability limit. Motors may be overheated. Reduce payload weight before taking off"
  },
  "fpv_tip_0x1B310003_in_the_sky": {
      "en": "Current payload exceeds propulsion system capability limit. Motors may be overheated. Return to home or land promptly"
  },
  "fpv_tip_0x1B310004": {
      "en": "Current payload exceeds battery capability limit. Battery may be damaged. Reduce payload weight before taking off"
  },
  "fpv_tip_0x1B310004_in_the_sky": {
      "en": "Current payload exceeds battery capability limit. Battery may be damaged. Return to home or land promptly"
  },
  "fpv_tip_0x1B310005": {
      "en": "Altitude detection function of radar module unavailable. Safe altitude changed. Fly with caution"
  },
  "fpv_tip_0x1B310006": {
      "en": "Altitude detection function of radar module available. Safe altitude changed. Fly with caution"
  },
  "fpv_tip_0x1B310007": {
      "en": "Aircraft in area with terrain data. Fly under safe altitude"
  },
  "fpv_tip_0x1B310007_in_the_sky": {
      "en": "Aircraft entered area with terrain data. Safe altitude changed"
  },
  "fpv_tip_0x1B310008": {
      "en": "Aircraft not in area with terrain data. Apply terrain data"
  },
  "fpv_tip_0x1B310008_in_the_sky": {
      "en": "Aircraft out of area with terrain data. Safe altitude changed. Fly with caution"
  },
  "fpv_tip_0x1B310009": {
      "en": "Aircraft flight altitude exceeded safe altitude. Fly aircraft under safe altitude"
  },
  "fpv_tip_0x1B31000A": {
      "en": "Winch system not fully reeled up. Propellers may be impacted during landing. Reel up cable before landing"
  },
  "fpv_tip_0x1B31000B": {
      "en": "Aircraft Stops. Fly with caution"
  },
  "fpv_tip_0x1B31000B_in_the_sky": {
      "en": "Aircraft Stops. Fly with caution"
  },
  "fpv_tip_0x1B310011": {
      "en": "Battery temperature low. Warm up battery before takeoff"
  },
  "fpv_tip_0x1B310011_in_the_sky": {
      "en": "Battery temperature low. Fly with caution"
  },
  "fpv_tip_0x1BA10000": {
      "en": "Cruise control enabled"
  },
  "fpv_tip_0x1BA10001": {
      "en": "Aircraft not in flight. Cruise control disabled"
  },
  "fpv_tip_0x1BA10002": {
      "en": "Switching flight modes. Cruise control disabled"
  },
  "fpv_tip_0x1BA10003": {
      "en": "Aircraft braking. Cruise control disabled"
  },
  "fpv_tip_0x1BA10004": {
      "en": "Flight speed limited. Cruise control disabled"
  },
  "fpv_tip_0x1BA10005": {
      "en": "Aircraft approaching the set max flight altitude or distance. Cruise control disabled"
  },
  "fpv_tip_0x1BA10006": {
      "en": "Cruise control unavailable in current flight mode. Cruise control disabled"
  },
  "fpv_tip_0x1BA10007": {
      "en": "Aircraft braking. Cruise control disabled"
  },
  "fpv_tip_0x1BA10008": {
      "en": "Remote controller signal lost. Cruise control disabled"
  },
  "fpv_tip_0x1BA10009": {
      "en": "Aircraft reached the destination point. Cruise Control exited"
  },
  "fpv_tip_0x1C000001": {
      "en": ""
  },
  "fpv_tip_0x1C000103": {
      "en": "Camera processor temperature high (%alarmid). Power off aircraft and wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C000103_in_the_sky": {
      "en": "Camera processor temperature high (%alarmid). Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C000104": {
      "en": "Camera processor temperature too high. Power off aircraft.  Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C000104_in_the_sky": {
      "en": "Camera processor temperature too high. Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C000201": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000202": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000203": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000204": {
      "en": "Camera image sensor overheated"
  },
  "fpv_tip_0x1C000207": {
      "en": "Check if camera connection cable is loose or has been eroded"
  },
  "fpv_tip_0x1C00020A": {
      "en": "Image sensor calibration data error. Restart aircraft"
  },
  "fpv_tip_0x1C000303": {
      "en": "Camera connection error. Restart camera and check whether camera is installed properly"
  },
  "fpv_tip_0x1C000305": {
      "en": "Shutter trigger count reached 100K design limit"
  },
  "fpv_tip_0x1C000306": {
      "en": "Non-DJI Enterprise lens"
  },
  "fpv_tip_0x1C000310": {
      "en": "Camera lens not detected"
  },
  "fpv_tip_0x1C000311": {
      "en": "Camera lens error. Re-install lens"
  },
  "fpv_tip_0x1C000312": {
      "en": "Camera lens error. Re-install lens"
  },
  "fpv_tip_0x1C000313": {
      "en": "Camera lens error. Re-install lens"
  },
  "fpv_tip_0x1C000314": {
      "en": "Camera lens error. Re-install lens"
  },
  "fpv_tip_0x1C000317": {
      "en": "Lens calibration data error. Re-install lens or restart aircraft"
  },
  "fpv_tip_0x1C000401": {
      "en": "Invalid memory card. Replace card (%alarmid)"
  },
  "fpv_tip_0x1C000402": {
      "en": "Memory card speed low. Replace with faster card"
  },
  "fpv_tip_0x1C000403": {
      "en": "Memory card error. Replace card (%alarmid)"
  },
  "fpv_tip_0x1C000404": {
      "en": "No memory card"
  },
  "fpv_tip_0x1C000405": {
      "en": "Confirm memory card read and write permissions"
  },
  "fpv_tip_0x1C000407": {
      "en": "Formatting memory card..."
  },
  "fpv_tip_0x1C000408": {
      "en": "Memory card file system not supported. Format card before use"
  },
  "fpv_tip_0x1C00040A": {
      "en": "Memory card full. Clear space"
  },
  "fpv_tip_0x1C00040B": {
      "en": "Max file index exceeded. Format SD card"
  },
  "fpv_tip_0x1C00040C": {
      "en": "Initializing memory card..."
  },
  "fpv_tip_0x1C00040D": {
      "en": "Memory card error. Format card before use"
  },
  "fpv_tip_0x1C00040E": {
      "en": "Memory card write speed low"
  },
  "fpv_tip_0x1C00040F": {
      "en": "Memory card write speed low"
  },
  "fpv_tip_0x1C000411": {
      "en": "SD card write error. Replace SD card"
  },
  "fpv_tip_0x1C000412": {
      "en": "Verification required before using memory card. Enter password to verify"
  },
  "fpv_tip_0x1C000414": {
      "en": "SD card storage severely fragmented. Back up files and format SD card"
  },
  "fpv_tip_0x1C000602": {
      "en": "Ambient Light Too Low"
  },
  "fpv_tip_0x1C000603": {
      "en": "Photos may be underexposed"
  },
  "fpv_tip_0x1C000604": {
      "en": "Photos may be overexposed"
  },
  "fpv_tip_0x1C000901": {
      "en": "PPS signal sending error"
  },
  "fpv_tip_0x1C000902": {
      "en": "Camera time synchronization error"
  },
  "fpv_tip_0x1C000903": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000904": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000905": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000906": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000907": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000908": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000909": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C00090A": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000A01": {
      "en": "Frames dropped in recording. Firmware update recommended"
  },
  "fpv_tip_0x1C000A02": {
      "en": "Frames dropped in recording. Firmware update recommended"
  },
  "fpv_tip_0x1C000B01": {
      "en": "Camera hardware error. Restart and try again"
  },
  "fpv_tip_0x1C000D01": {
      "en": "Focusing failed"
  },
  "fpv_tip_0x1C000D02": {
      "en": "Lens infinity focus not calibrated"
  },
  "fpv_tip_0x1C000D03": {
      "en": "Infinity focus calibration data expired. Recalibrate"
  },
  "fpv_tip_0x1C000D04": {
      "en": "Infinity focus calibration data expired. Recalibrate"
  },
  "fpv_tip_0x1C000E01": {
      "en": "Camera and lens intrinsic parameters calibration data do not match"
  },
  "fpv_tip_0x1C000E02": {
      "en": "Intrinsic parameters calibration data expired"
  },
  "fpv_tip_0x1C001001": {
      "en": "DJI PROSSD fragmentation risk detected. Formatting storage device recommended"
  },
  "fpv_tip_0x1C001002": {
      "en": "DJI PROSSD fragmented. Formatting storage device recommended"
  },
  "fpv_tip_0x1C001003": {
      "en": ""
  },
  "fpv_tip_0x1C001101": {
      "en": "DJI PROSSD approaching end of lifespan. Replacing storage device recommended"
  },
  "fpv_tip_0x1C001102": {
      "en": "DJI PROSSD reached end of lifespan. Replacing storage device recommended"
  },
  "fpv_tip_0x1C001103": {
      "en": "Failed to obtain data stored in DJI PROSSD. Back up files before formatting DJI PROSSD to avoid data loss"
  },
  "fpv_tip_0x1C001201": {
      "en": "Use DJI PROSSD to start recording"
  },
  "fpv_tip_0x1C001301": {
      "en": "Failed to write video to storage. Stopping recording..."
  },
  "fpv_tip_0x1C001302": {
      "en": "DJI PROSSD write speed low. Stopping recording..."
  },
  "fpv_tip_0x1C001303": {
      "en": "Insufficient storage on DJI PROSSD. Stopping recording..."
  },
  "fpv_tip_0x1C001303_special": {
      "en": "Insufficient storage on memory card. Stopping recording..."
  },
  "fpv_tip_0x1C001306": {
      "en": "System busy. Recording not allowed"
  },
  "fpv_tip_0x1C001307": {
      "en": "System not busy. Recording available"
  },
  "fpv_tip_0x1C001401": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C001402": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C001403": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C001404": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C001405": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C001406": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C001407": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C001408": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C001409": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C00140A": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C00140E": {
      "en": "Camera error. Restarting aircraft recommended"
  },
  "fpv_tip_0x1C00140F": {
      "en": "Camera connection unstable. Detach and remount gimbal"
  },
  "fpv_tip_0x1C001501": {
      "en": "Light sensor error. Contact DJI Support"
  },
  "fpv_tip_0x1C001502": {
      "en": "Light sensor error. Contact DJI Support"
  },
  "fpv_tip_0x1C001503": {
      "en": "Light sensor calibration data error. Contact DJI Support"
  },
  "fpv_tip_0x1C001603": {
      "en": "Unable to use DJI PROSSD. Remove and insert storage device again"
  },
  "fpv_tip_0x1C001604": {
      "en": "No memory card"
  },
  "fpv_tip_0x1C001605": {
      "en": "SSD file system error. Remove and insert SSD again. Back up SSD files and then format SSD card if issue persists"
  },
  "fpv_tip_0x1C001606": {
      "en": "DJI PROSSD not formatted"
  },
  "fpv_tip_0x1C001607": {
      "en": "Formatting DJI PROSSD..."
  },
  "fpv_tip_0x1C001608": {
      "en": "DJI PROSSD file system format not supported"
  },
  "fpv_tip_0x1C001609": {
      "en": "Refreshing DJI PROSSD..."
  },
  "fpv_tip_0x1C00160A": {
      "en": "DJI PROSSD full"
  },
  "fpv_tip_0x1C00160B": {
      "en": "DJI PROSSD file naming index full. Delete extra files"
  },
  "fpv_tip_0x1C00160C": {
      "en": "Initializing DJI PROSSD…"
  },
  "fpv_tip_0x1C00160D": {
      "en": "Reformatting DJI PROSSD recommended"
  },
  "fpv_tip_0x1C00160E": {
      "en": "Restoring files on DJI PROSSD..."
  },
  "fpv_tip_0x1C00160F": {
      "en": "DJI PROSSD speed low. Replacing storage device recommended"
  },
  "fpv_tip_0x1C001701": {
      "en": "Timed shot interval too short. Unable to start shooting"
  },
  "fpv_tip_0x1C001702": {
      "en": "Timed shot interval too short. Unable to switch cameras"
  },
  "fpv_tip_0x1C001703": {
      "en": "Photo format inconsistent. Unable to switch cameras"
  },
  "fpv_tip_0x1C001801": {
      "en": "Timecode error. Restart aircraft"
  },
  "fpv_tip_0x1C001901": {
      "en": "ProRes RAW codec not authorized,purchase on the official website"
  },
  "fpv_tip_0x1C001902": {
      "en": "CinemaDNG encoding format not authorized. Purchase license from official website"
  },
  "fpv_tip_0x1C001903": {
      "en": "ProRes RAW codec not authorized,purchase on the official website"
  },
  "fpv_tip_0x1C001904": {
      "en": "CinemaDNG encoding format not authorized，purchase on the official website"
  },
  "fpv_tip_0x1C001A01": {
      "en": "DJI PROSSD write speed low. Restarting aircraft recommended"
  },
  "fpv_tip_0x1C001B01": {
      "en": "Usage count of gimbal port reached"
  },
  "fpv_tip_0x1C001E04": {
      "en": "System busy. Photo is not allowed"
  },
  "fpv_tip_0x1C001E05": {
      "en": "System not busy.Photo available"
  },
  "fpv_tip_0x1C001F01": {
      "en": "System busy. Zoom is not available"
  },
  "fpv_tip_0x1C001F02": {
      "en": "System not busy. Zoom available"
  },
  "fpv_tip_0x1C002001": {
      "en": "System busy. Do not switch cameras"
  },
  "fpv_tip_0x1C002002": {
      "en": "System not busy. Do not switch cameras"
  },
  "fpv_tip_0x1C002004": {
      "en": ""
  },
  "fpv_tip_0x1C002101": {
      "en": "Camera receiving LiDAR data timed out. Restart aircraft and try again"
  },
  "fpv_tip_0x1C002102": {
      "en": "Point cloud data frames dropped. The ultimate point cloud model may not be complete. Restart aircraft"
  },
  "fpv_tip_0x1C002103": {
      "en": "LiDAR may be blocked or object out of measuring range"
  },
  "fpv_tip_0x1C002104": {
      "en": "RNG sampling failed. Try again later or restart aircraft"
  },
  "fpv_tip_0x1C002105": {
      "en": "LiDAR temporarily powered off. Camera overheated"
  },
  "fpv_tip_0x1C002106": {
      "en": "LiDAR temporarily unavailable. Camera overheated"
  },
  "fpv_tip_0x1C002107": {
      "en": "Infrared auxiliary light temporarily unavailable. Camera overheated"
  },
  "fpv_tip_0x1C002201": {
      "en": "Previous media files exist in camera SSD. Back up files to avoid data loss"
  },
  "fpv_tip_0x1C002301": {},
  "fpv_tip_0x1C002302": {
      "en": ""
  },
  "fpv_tip_0x1C002303": {
      "en": ""
  },
  "fpv_tip_0x1C002304": {
      "en": ""
  },
  "fpv_tip_0x1C002305": {
      "en": ""
  },
  "fpv_tip_0x1C002306": {
      "en": ""
  },
  "fpv_tip_0x1C002307": {
      "en": ""
  },
  "fpv_tip_0x1C002308": {
      "en": ""
  },
  "fpv_tip_0x1C002309": {
      "en": ""
  },
  "fpv_tip_0x1C00230A": {
      "en": ""
  },
  "fpv_tip_0x1C00230B": {
      "en": ""
  },
  "fpv_tip_0x1C00230C": {
      "en": ""
  },
  "fpv_tip_0x1C00230D": {
      "en": ""
  },
  "fpv_tip_0x1C00230E": {
      "en": ""
  },
  "fpv_tip_0x1C002501": {
      "en": "Camera overheating warning. Defog and LiDAR disabled automatically"
  },
  "fpv_tip_0x1C002502": {
      "en": "Defog and LiDAR disabled automatically. Camera overheated"
  },
  "fpv_tip_0x1C002502_in_the_sky": {
      "en": "Infrared auxiliary light (thermal version only) and defog disabled automatically. Camera overheated"
  },
  "fpv_tip_0x1C002503": {
      "en": "Camera overheated. Defog temporarily unavailable"
  },
  "fpv_tip_0x1C002701": {
      "en": "Timed shot interval too short. Unable to record AI recognition data"
  },
  "fpv_tip_0x1C002702": {
      "en": "Unable to record AI recognition data for panoramas"
  },
  "fpv_tip_0x1C002703": {
      "en": "Unable to record AI recognition data for 8K photos"
  },
  "fpv_tip_0x1C003001": {
      "en": ""
  },
  "fpv_tip_0x1C004001": {
      "en": "Invalid memory card. Replace card"
  },
  "fpv_tip_0x1C004002": {
      "en": "Memory card speed low. Replace with faster card"
  },
  "fpv_tip_0x1C004003": {
      "en": "Memory card error. Replace card"
  },
  "fpv_tip_0x1C004004": {
      "en": "No memory card"
  },
  "fpv_tip_0x1C100001": {
      "en": "Camera %component_index overheated. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C100101": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C100102": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C100103": {
      "en": "Camera processor temperature high. Power off aircraft and wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C100103_in_the_sky": {
      "en": "Camera processor temperature high. Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C100104": {
      "en": "Camera processor temperature too high. Power off aircraft and wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C100104_in_the_sky": {
      "en": "Camera processor temperature too high. Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C100105": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C100201": {
      "en": "H20 camera image transmission sensor processor error"
  },
  "fpv_tip_0x1C100202": {
      "en": "H20 camera image transmission sensor processor error"
  },
  "fpv_tip_0x1C100203": {
      "en": "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C100203_in_the_sky": {
      "en": "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C100204": {
      "en": "H20 camera image transmission sensor processor error"
  },
  "fpv_tip_0x1C100205": {
      "en": "FPV lens calibration error. Recalibrate lens and try again"
  },
  "fpv_tip_0x1C100301": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C100302": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C100303": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C100304": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C100401": {
      "en": "Invalid memory card. Replace card"
  },
  "fpv_tip_0x1C100401_index_1": {
      "en": "Invalid eMMC. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C100402": {
      "en": "Memory card speed low. Replace with faster card"
  },
  "fpv_tip_0x1C100402_index_1": {
      "en": "eMMC speed error. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C100403": {
      "en": "Memory card error. Replace card"
  },
  "fpv_tip_0x1C100403_index_1": {
      "en": "eMMC error. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C100404": {
      "en": "No memory card"
  },
  "fpv_tip_0x1C100404_index_1": {
      "en": "eMMC identification error. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C100405": {
      "en": "Confirm memory card read and write permissions"
  },
  "fpv_tip_0x1C100406": {
      "en": "Memory card not formatted. Format card before use"
  },
  "fpv_tip_0x1C100407": {
      "en": "Formatting memory card..."
  },
  "fpv_tip_0x1C100408": {
      "en": "Memory card file system not supported. Format card before use"
  },
  "fpv_tip_0x1C100409": {
      "en": "Refreshing memory card..."
  },
  "fpv_tip_0x1C10040A": {
      "en": "Memory card full. Clear space"
  },
  "fpv_tip_0x1C10040B": {
      "en": "Max file index exceeded. Format SD card"
  },
  "fpv_tip_0x1C10040C": {
      "en": "Initializing memory card..."
  },
  "fpv_tip_0x1C10040D": {
      "en": "Memory card error. Format card before use"
  },
  "fpv_tip_0x1C10040E": {
      "en": "Fixing memory card..."
  },
  "fpv_tip_0x1C10040F": {
      "en": "Memory card read and write speed low. Wait until process completes"
  },
  "fpv_tip_0x1C200001": {
      "en": "Camera %component_index overheated. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C200101": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C200102": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C200103": {
      "en": "Camera processor temperature too high. Power off aircraft and wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C200103_in_the_sky": {
      "en": "Camera processor temperature high. Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C200104": {
      "en": "Camera processor temperature too high. Power off aircraft and wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C200104_in_the_sky": {
      "en": "Camera processor temperature too high. Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C200105": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C200201": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C200202": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C200203": {
      "en": "Camera chip overheated. Power off aircraft and wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C200203_in_the_sky": {
      "en": "Camera processor overheated. Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C200204": {
      "en": "H20 camera image transmission sensor processor error"
  },
  "fpv_tip_0x1C200301": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C200302": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C200303": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C200304": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C200401": {
      "en": "Invalid memory card. Replace card"
  },
  "fpv_tip_0x1C200401_index_1": {
      "en": "Invalid eMMC. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C200402": {
      "en": "Memory card speed low. Replace with faster card"
  },
  "fpv_tip_0x1C200402_index_1": {
      "en": "Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C200403": {
      "en": "Memory card error. Replace card"
  },
  "fpv_tip_0x1C200403_index_1": {
      "en": "eMMC error. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C200404": {
      "en": "No memory card"
  },
  "fpv_tip_0x1C200404_index_1": {
      "en": "eMMC identification error. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C200405": {
      "en": "Confirm memory card read and write permissions"
  },
  "fpv_tip_0x1C200406": {
      "en": "Memory card not formatted. Format card before use"
  },
  "fpv_tip_0x1C200407": {
      "en": "Formatting memory card..."
  },
  "fpv_tip_0x1C200408": {
      "en": "Memory card file system not supported. Format card before use"
  },
  "fpv_tip_0x1C200409": {
      "en": "Refreshing memory card..."
  },
  "fpv_tip_0x1C20040A": {
      "en": "Memory card full. Clear space"
  },
  "fpv_tip_0x1C20040B": {
      "en": "Max file index exceeded. Format SD card"
  },
  "fpv_tip_0x1C20040C": {
      "en": "Initializing memory card..."
  },
  "fpv_tip_0x1C20040D": {
      "en": "Memory card error. Format card before use"
  },
  "fpv_tip_0x1C20040E": {
      "en": "Fixing memory card..."
  },
  "fpv_tip_0x1C20040F": {
      "en": "Memory card read and write speed low. Wait until process completes"
  },
  "fpv_tip_0x1C200410": {
      "en": "Verification required before using memory card. Enter password to verify"
  },
  "fpv_tip_0x1C300001": {
      "en": "Camera %component_index overheated. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C300101": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C300102": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C300103": {
      "en": "Camera processor temperature high. Power off aircraft and wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C300103_in_the_sky": {
      "en": "Camera processor temperature high. Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C300104": {
      "en": "Camera processor temperature too high. Power off aircraft and wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C300104_in_the_sky": {
      "en": "Camera processor temperature too high. Return to home or land promptly. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x1C300105": {
      "en": "Camera %component_index error. Restart camera"
  },
  "fpv_tip_0x1C300301": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C300302": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C300303": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C300304": {
      "en": "H20 camera lens error. Restart camera"
  },
  "fpv_tip_0x1C300401": {
      "en": "Invalid memory card. Replace card"
  },
  "fpv_tip_0x1C300401_index_1": {
      "en": "Invalid eMMC. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C300402": {
      "en": "Memory card speed low. Replace with faster card"
  },
  "fpv_tip_0x1C300402_index_1": {
      "en": "eMMC speed error. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C300403": {
      "en": "Memory card error. Replace card"
  },
  "fpv_tip_0x1C300403_index_1": {
      "en": "eMMC error. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C300404": {
      "en": "No memory card"
  },
  "fpv_tip_0x1C300404_index_1": {
      "en": "eMMC identification error. Restart camera. If the issue persists, contact DJI Support for repairs"
  },
  "fpv_tip_0x1C300405": {
      "en": "Confirm memory card read and write permissions"
  },
  "fpv_tip_0x1C300406": {
      "en": "Memory card not formatted. Format card before use"
  },
  "fpv_tip_0x1C300407": {
      "en": "Formatting memory card..."
  },
  "fpv_tip_0x1C300408": {
      "en": "Memory card file system not supported. Format card before use"
  },
  "fpv_tip_0x1C300409": {
      "en": "Refreshing memory card..."
  },
  "fpv_tip_0x1C30040A": {
      "en": "Memory card full. Clear space"
  },
  "fpv_tip_0x1C30040B": {
      "en": "Max file index exceeded. Format SD card"
  },
  "fpv_tip_0x1C30040C": {
      "en": "Initializing memory card..."
  },
  "fpv_tip_0x1C30040D": {
      "en": "Memory card error. Format card before use"
  },
  "fpv_tip_0x1C30040E": {
      "en": "Fixing memory card..."
  },
  "fpv_tip_0x1C30040F": {
      "en": "Memory card read and write speed low. Wait until process completes"
  },
  "fpv_tip_0x1C300601": {
      "en": "Temperature of current environment too low. Enable temperature measurement when temperature is within normal range"
  },
  "fpv_tip_0x1C300602": {
      "en": "Temperature of current evironment too high. Only enable temperature measurement when temperature is within normal range"
  },
  "fpv_tip_0x1C300603": {
      "en": "Zenmuse H20T infrared thermal camera switching modes..."
  },
  "fpv_tip_0x1C300604": {
      "en": "Zenmuse H20T infrared thermal camera calibration data missing"
  },
  "fpv_tip_0x1C300605": {
      "en": "Zenmuse H20T infrared thermal camera initialization error. Restart camera"
  },
  "fpv_tip_0x1C300606": {
      "en": "Temperature measurement failed. Adjust camera parameters and try again"
  },
  "fpv_tip_0x1C300607": {
      "en": "Exceeded measuring range limit. Switch to Low Gain mode"
  },
  "fpv_tip_0x1C300608": {
      "en": "Exceeded measuring range limit. It is recommended to attach an infrared density filter"
  },
  "fpv_tip_0x1C300609": {
      "en": "Exceeded temperature measuring range limit"
  },
  "fpv_tip_0x1C300701": {
      "en": "Sun detected. Infrared shutter closed"
  },
  "fpv_tip_0x1C300702": {
      "en": "Sun detected. Rotate gimbal to avoid direct sunlight"
  },
  "fpv_tip_0x1D001001": {
      "en": "Gimbal unresponsive after power on"
  },
  "fpv_tip_0x1D001101": {
      "en": "Gimbal unable to complete auto check"
  },
  "fpv_tip_0x1D010001": {
      "en": "Gimbal stuck"
  },
  "fpv_tip_0x1D010002": {
      "en": "Gimbal auto-check failed"
  },
  "fpv_tip_0x1D010003": {
      "en": "Gimbal motor overloaded"
  },
  "fpv_tip_0x1D010C01": {
      "en": "FPV gimbal stuck"
  },
  "fpv_tip_0x1D010C02": {
      "en": "FPV gimbal auto-check failed"
  },
  "fpv_tip_0x1D010C03": {
      "en": "FPV gimbal motor overloaded"
  },
  "fpv_tip_0x1D020001": {
      "en": "Gimbal calibration error"
  },
  "fpv_tip_0x1D020002": {
      "en": "Gimbal IMU error. Restart aircraft and try again"
  },
  "fpv_tip_0x1D020003": {
      "en": "Gimbal calibration parameter error. Try gimbal Auto Calibration"
  },
  "fpv_tip_0x1D020C01": {
      "en": "FPV gimbal calibration error"
  },
  "fpv_tip_0x1D030001": {
      "en": "Gimbal unable to retrieve aircraft data"
  },
  "fpv_tip_0x1D030002": {},
  "fpv_tip_0x1D030002_in_the_sky": {},
  "fpv_tip_0x1D030C01": {
      "en": "FPV gimbal unable to obtain aircraft data"
  },
  "fpv_tip_0x1D040001": {
      "en": "Excessive gimbal vibration"
  },
  "fpv_tip_0x1D040002": {
      "en": "Gimbal sensor error"
  },
  "fpv_tip_0x1D040003": {
      "en": "Gimbal ESC malfunctioned"
  },
  "fpv_tip_0x1D040004": {
      "en": "Gimbal ESC malfunctioned"
  },
  "fpv_tip_0x1D040005": {
      "en": "Gimbal ESC malfunctioned"
  },
  "fpv_tip_0x1D040C01": {
      "en": "Abnormal FPV gimbal vibration"
  },
  "fpv_tip_0x1D040C02": {
      "en": "FPV gimbal sensor error"
  },
  "fpv_tip_0x1D040C03": {
      "en": "FPV gimbal ESC malfunction"
  },
  "fpv_tip_0x1D040C04": {
      "en": "FPV gimbal ESC malfunction"
  },
  "fpv_tip_0x1D040C05": {
      "en": "FPV gimbal ESC malfunction"
  },
  "fpv_tip_0x1D050001": {
      "en": "Gimbal %component_index roll axis endpoint reached"
  },
  "fpv_tip_0x1D050002": {
      "en": "Gimbal %component_index pitch axis endpoint reached"
  },
  "fpv_tip_0x1D050003": {
      "en": "Gimbal %component_index pan axis endpoint reached"
  },
  "fpv_tip_0x1D050004": {
      "en": "Gimbal frozen due to low temperature. Return to dock and try unfreezing gimbal"
  },
  "fpv_tip_0x1D050301": {
      "en": "Gimbal operation retry attempts limit exceeded"
  },
  "fpv_tip_0x1D050302": {
      "en": "Camera exposure error"
  },
  "fpv_tip_0x1D05030F": {
      "en": "Gimbal rotation count approaching max limit"
  },
  "fpv_tip_0x1D050A01": {
      "en": "Gimbal voltage too low"
  },
  "fpv_tip_0x1D050A02": {
      "en": "Gimbal time synchronization error"
  },
  "fpv_tip_0x1D050C01": {
      "en": "FPV gimbal limit reached"
  },
  "fpv_tip_0x1D050C02": {
      "en": "FPV gimbal tilt limit reached"
  },
  "fpv_tip_0x1D050C03": {
      "en": "FPV gimbal pan limit reached"
  },
  "fpv_tip_0x1D0C0002": {
      "en": "Gimbal IMU Error"
  },
  "fpv_tip_0x1D0C0003": {
      "en": "Gimbal Initialization Error"
  },
  "fpv_tip_0x1D0C0004": {
      "en": "Gimbal Initialization Error"
  },
  "fpv_tip_0x1D0C0005": {
      "en": "Gimbal voltage error. Replace with DJI battery"
  },
  "fpv_tip_0x1D0C000B": {
      "en": "Gimbal Motor Temperature Too High"
  },
  "fpv_tip_0x1D0C000E": {
      "en": "Lens not firmly attached. Reattach lens and toggle lever to the locked position"
  },
  "fpv_tip_0x1D0C000F": {
      "en": "Gimbal not balanced. Make sure lens protector/ND filter, hood, and balancing weight are properly installed"
  },
  "fpv_tip_0x1D0C0C02": {
      "en": "FPV gimbal IMU error"
  },
  "fpv_tip_0x1D0C0C03": {
      "en": "FPV gimbal initialization error"
  },
  "fpv_tip_0x1D0C0C04": {
      "en": "FPV gimbal initialization error"
  },
  "fpv_tip_0x1D0C0C05": {
      "en": "FPV gimbal voltage error. Replace with DJI battery"
  },
  "fpv_tip_0x1D0C0C0B": {
      "en": "FPV gimbal motor overheated"
  },
  "fpv_tip_0x1D0E0001": {
      "en": "Gimbal Initialization Error"
  },
  "fpv_tip_0x1D0E0C01": {
      "en": "FPV gimbal initialization error"
  },
  "fpv_tip_0x1D100002": {
      "en": "Gimbal unresponsive after power on"
  },
  "fpv_tip_0x1D100006": {
      "en": "Gimbal unresponsive after power on"
  },
  "fpv_tip_0x1D110002": {
      "en": "Gimbal unable to complete auto check"
  },
  "fpv_tip_0x1D110005": {
      "en": "Gimbal unable to complete auto check"
  },
  "fpv_tip_0x1D120001": {
      "en": "Error detected during gimbal auto check"
  },
  "fpv_tip_0x1D120002": {
      "en": "Error detected during gimbal auto check"
  },
  "fpv_tip_0x1D120004": {
      "en": "Error detected during gimbal auto check"
  },
  "fpv_tip_0x1D120006": {
      "en": "Error detected during gimbal auto check"
  },
  "fpv_tip_0x1D130003": {
      "en": "Excessive gimbal vibration"
  },
  "fpv_tip_0x1D130005": {
      "en": "Excessive gimbal vibration"
  },
  "fpv_tip_0x1D13000A": {
      "en": "Excessive gimbal vibration"
  },
  "fpv_tip_0x1D140001": {
      "en": "Gimbal motor overloaded"
  },
  "fpv_tip_0x1D150002": {
      "en": "Gimbal drifting"
  },
  "fpv_tip_0x1D150003": {
      "en": "Gimbal drifting"
  },
  "fpv_tip_0x1D150004": {
      "en": "Gimbal drifting"
  },
  "fpv_tip_0x1D150005": {
      "en": "Gimbal drifting"
  },
  "fpv_tip_0x1D160004": {
      "en": "Camera tilted"
  },
  "fpv_tip_0x1D170001": {
      "en": "Gimbal error"
  },
  "fpv_tip_0x1D180001": {
      "en": "Gimbal calibration data error"
  },
  "fpv_tip_0x1D190002": {
      "en": "Gimbal update failed"
  },
  "fpv_tip_0x1E00000": {
      "en": ""
  },
  "fpv_tip_0x1E000001": {
      "en": "Payload %component_index startup error"
  },
  "fpv_tip_0x1E000002": {
      "en": "Payload %component_index communication error"
  },
  "fpv_tip_0x1E000003": {
      "en": "Payload %component_index overheated"
  },
  "fpv_tip_0x1E000004": {
      "en": "Payload %component_index hardware error"
  },
  "fpv_tip_0x1E000006": {
      "en": "Speaker resonates with the aircraft. Power supply disconnected. Wait for power supply to recover"
  },
  "fpv_tip_0x1E000101": {
      "en": "Aircraft returning to home. Spotlight disabled automatically"
  },
  "fpv_tip_0x1E000102": {
      "en": "Aircraft has not taken off. Spotlight max brightness level limited to 40%"
  },
  "fpv_tip_0x1E000103": {
      "en": "Spotlight power restricted. Max brightness level reduced"
  },
  "fpv_tip_0x1E000104": {
      "en": "Spotlight temperature too high. Brightness will be continuously reduced"
  },
  "fpv_tip_0x1E000105": {
      "en": "Aircraft battery low. Spotlight disabled automatically"
  },
  "fpv_tip_0x1E000106": {
      "en": "Aircraft landing near ground. Spotlight disabled automatically"
  },
  "fpv_tip_0x1E000107": {
      "en": "Spotlight disabled. Spotlight not activated. Activate spotlight before use"
  },
  "fpv_tip_0x1E000108": {
      "en": "Brightness is limited. Spotlight temperature sensor error. Reinstall spotlight"
  },
  "fpv_tip_0x1E010001": {
      "en": "Speaker overheats. Lower broadcast volume or wait until speaker cools down"
  },
  "fpv_tip_0x1E010002": {
      "en": "Speaker hardware error. Contact DJI Support"
  },
  "fpv_tip_0x1E010003": {
      "en": "Speaker overloaded. Restart aircraft"
  },
  "fpv_tip_0x1E010004": {
      "en": "Battery power limited. Speaker disabled"
  },
  "fpv_tip_0x1E010009": {
      "en": "Speaker not activated, functionality limited, please activate before use."
  },
  "fpv_tip_0x1E01000A": {},
  "fpv_tip_0x1E020001": {
      "en": "Spotlight gimbal stuck"
  },
  "fpv_tip_0x1E020002": {
      "en": "Spotlight gimbal auto check failed"
  },
  "fpv_tip_0x1E020003": {
      "en": "Spotlight gimbal motor overloaded"
  },
  "fpv_tip_0x1E030001": {
      "en": "Spotlight gimbal calibration error"
  },
  "fpv_tip_0x1E030003": {
      "en": "Spotlight gimbal calibration parameter error"
  },
  "fpv_tip_0x1E040001": {
      "en": "Spotlight gimbal stuck"
  },
  "fpv_tip_0x1E040002": {
      "en": "Spotlight gimbal auto check failed"
  },
  "fpv_tip_0x1E040003": {
      "en": "Spotlight gimbal motor overloaded"
  },
  "fpv_tip_0x1E050001": {
      "en": "Spotlight gimbal calibration error"
  },
  "fpv_tip_0x1E050002": {
      "en": "Spotlight gimbal sensor error"
  },
  "fpv_tip_0x1E050003": {
      "en": "Spotlight gimbal calibration parameter error"
  },
  "fpv_tip_0x1E050004": {
      "en": "Motor on right side of spotlight error. Try restarting spotlight"
  },
  "fpv_tip_0x1E050005": {
      "en": "Spotlight gimbal motor malfunction"
  },
  "fpv_tip_0x1E060001": {
      "en": "Spotlight unable to obtain aircraft information"
  },
  "fpv_tip_0x1E070002": {
      "en": "Spotlight gimbal sensor error"
  },
  "fpv_tip_0x1E070003": {
      "en": "Motor on left side of spotlight error. Try restarting spotlight"
  },
  "fpv_tip_0x1E070004": {
      "en": "Motor on right side of spotlight error. Try restarting spotlight"
  },
  "fpv_tip_0x1E070005": {
      "en": "Spotlight gimbal motor malfunction"
  },
  "fpv_tip_0x1E0D0001": {
      "en": "Spotlight not activated"
  },
  "fpv_tip_0x1E0D0002": {
      "en": "No IMU data for Spotlight"
  },
  "fpv_tip_0x1E0D0003": {
      "en": "Spotlight gimbal motor not initialized"
  },
  "fpv_tip_0x1E0F0001": {
      "en": "Spotlight not activated"
  },
  "fpv_tip_0x1E0F0002": {
      "en": "No IMU data for Spotlight"
  },
  "fpv_tip_0x1E0F0003": {
      "en": "Spotlight gimbal motor not initialized"
  },
  "fpv_tip_0x1E110001": {
      "en": "Spotlight gimbal attitude initialization failed"
  },
  "fpv_tip_0x1F010000": {
      "en": "4G service: unable to obtain license. Contact DJI Support"
  },
  "fpv_tip_0x1F010001": {
      "en": "4G service: license expired. Import valid license"
  },
  "fpv_tip_0x1F010002": {
      "en": "4G service: license verification failed. Contact DJI Support"
  },
  "fpv_tip_0x1F010003": {
      "en": "4G service: device SN unauthorized. Authorize device SN"
  },
  "fpv_tip_0x1F010004": {
      "en": "4G service: device SN unauthorized. Purchase upgrade package"
  },
  "fpv_tip_0x1F010005": {
      "en": "4G service: the number of connected devices exceeds authorized number. Purchase upgrade package"
  },
  "fpv_tip_0x1F010006": {
      "en": "4G service: device firmware version too early. Upgrade firmware"
  },
  "fpv_tip_0x1F010007": {
      "en": "4G service: server version too early. Upgrade server"
  },
  "fpv_tip_0x1F010008": {
      "en": "4G service: requested parameter error. Contact DJI Support"
  },
  "fpv_tip_0x1F010009": {
      "en": "4G service: server internal error. Contact DJI Support"
  },
  "fpv_tip_0x1F01000A": {
      "en": "4G service: unable to obtain license. Contact DJI Support"
  },
  "fpv_tip_0x1F01000B": {
      "en": "4G service: license expired. Import valid license"
  },
  "fpv_tip_0x1F01000C": {
      "en": "4G service: license verification failed. Contact DJI Support"
  },
  "fpv_tip_0x1F01000D": {
      "en": "System time error. Make sure device and server time are correct and try again"
  },
  "fpv_tip_0x1F01000E": {
      "en": "Current area does not match area unlocked by license. Check unlocked area"
  },
  "fpv_tip_0x1F010063": {
      "en": "4G service: system unknown error. Contact DJI Support"
  },
  "fpv_tip_0x1F0B0001": {
      "en": "Aircraft unable to use LTE Transmission. Network connection unstable or SIM card unable to connect to network"
  },
  "fpv_tip_0x1F0B0002": {
      "en": "No network on DJI Cellular Dongle of remote controller"
  },
  "fpv_tip_0x1F0B0003": {
      "en": "LTE Server and LTE Transmission unavailable"
  },
  "fpv_tip_0x1F0B0004": {
      "en": "LTE Server and LTE Transmission unavailable"
  },
  "fpv_tip_0x1F0B0005": {
      "en": "LTE Transmission unavailable. Check and ensure remote controller and aircraft are linked properly"
  },
  "fpv_tip_0x1F0B0006": {
      "en": "LTE Transmission unavailable. Restart remote controller"
  },
  "fpv_tip_0x1F0B0007": {
      "en": "LTE Transmission unavailable. Check aircraft 4G Dongle network connectivity"
  },
  "fpv_tip_0x1F0B0008": {
      "en": "LTE Transmission unavailable. Restart aircraft and remote controller"
  },
  "fpv_tip_0x1F0B0009": {
      "en": "LTE Transmission unavailable. Restart remote controller"
  },
  "fpv_tip_0x1F0B0016": {
      "en": "LTE Transmission unavailable. Check remote controller network connectivity"
  },
  "fpv_tip_0x1F0B0017": {
      "en": "LTE Transmission unavailable. Check remote controller network connectivity"
  },
  "fpv_tip_0x1F0B0018": {
      "en": "LTE Transmission unavailable. Restart aircraft and remote controller"
  },
  "fpv_tip_0x1F0B001A": {
      "en": "LTE Transmission unavailable. Check aircraft 4G Dongle network connectivity"
  },
  "fpv_tip_0x1F0B001B": {
      "en": "LTE Transmission unavailable. Restart aircraft and remote controller"
  },
  "fpv_tip_0x1F0B001C": {
      "en": "LTE Transmission error. Aircraft authentication files missing. Contact your local dealer or DJI Support"
  },
  "fpv_tip_0x1F0B001c": {
      "en": "LTE Transmission error. Aircraft authentication files missing. Contact your local dealer or DJI Support"
  },
  "fpv_tip_0x1F0B001d": {
      "en": "LTE Transmission error. Remote controller authentication files missing. Contact your local dealer or DJI Support"
  },
  "fpv_tip_0x1F0B0020": {
      "en": "Update DJI Cellular Dongle of aircraft required"
  },
  "fpv_tip_0x1F0B0021": {
      "en": "Update DJI Cellular Dongle of remote controller required"
  },
  "fpv_tip_0x1F0B0022": {
      "en": "Airplane mode enabled or Wi-Fi and mobile data disabled on mobile device. Unable to connect"
  },
  "fpv_tip_0x1F0B0023": {
      "en": "LTE signal weak. Fly with caution"
  },
  "fpv_tip_0x1F0B0024": {
      "en": "LTE signal weak. Fly with caution"
  },
  "fpv_tip_0x1F0B0025": {
      "en": "LTE signal weak. Fly with caution"
  },
  "fpv_tip_0x1F0B0026": {
      "en": "Insufficient data allowance on SIM card. Fly with caution"
  },
  "fpv_tip_0x1F0B0027": {
      "en": "Aircraft LTE certification update required. Contact your local dealer or DJI Support"
  },
  "fpv_tip_0x1F0B0028": {
      "en": "Remote controller LTE certification update required. Contact your local dealer or DJI Support"
  },
  "fpv_tip_0x1F0B0029": {
      "en": "License expired. Unable to establish communication"
  },
  "fpv_tip_0x1F0B002A": {
      "en": "Incompatible firmware versions. Return to home screen to update firmware"
  },
  "fpv_tip_0x1F0B002B": {
      "en": "Incompatible firmware versions. Return to home screen to update firmware"
  },
  "fpv_tip_0x1F0B002C": {
      "en": "Incompatible firmware versions. Go to home screen to update firmware"
  },
  "fpv_tip_0x1F0B002D": {
      "en": "App update required"
  },
  "fpv_tip_0x1F0B002E": {
      "en": "Weak network connection on remote controller. Fly with caution"
  },
  "fpv_tip_0x1F0B0030": {
      "en": "Failed to connect to LTE server. Try again later"
  },
  "fpv_tip_0x1F0B0031": {
      "en": "Weak network signal on mobile device. Device may be unable to establish communication or connection may be unstable"
  },
  "fpv_tip_0x1F0B0032": {
      "en": "Service expired. Unable to use Enhanced Transmission. Firmware update required"
  },
  "fpv_tip_0x1F0B0035": {
      "en": "Dongle firmware update required"
  },
  "fpv_tip_0x1F0B0036": {
      "en": "Enhanced Transmission unavailable in current region"
  },
  "fpv_tip_0x1F0B0037": {
      "en": "No internet access on DJI Cellular Dongle of aircraft. Move or change network"
  },
  "fpv_tip_0x1F0B0038": {
      "en": "No network on DJI Cellular Dongle of remote controller"
  },
  "fpv_tip_0x1F0B0039": {
      "en": "Unable to establish network connection. Move to a different place or switch to a different SIM card from another service provider"
  },
  "fpv_tip_0x1F0B003A": {
      "en": "No internet access on DJI Cellular Dongle of aircraft. Change network provider"
  },
  "fpv_tip_0x1F0B003B": {
      "en": "No network on DJI Cellular Dongle of remote controller"
  },
  "fpv_tip_0x1F0B003C": {
      "en": "Unable to establish network connection. Move to a different place or switch to a different SIM card from another service provider"
  },
  "fpv_tip_0x1F0B003D": {
      "en": "No network connection"
  },
  "fpv_tip_0x1F0B003E": {
      "en": "No network on SIM card of DJI Cellular Dongle of remote controller. Check or replace SIM card"
  },
  "fpv_tip_0x1F0B0040": {
      "en": "No network on remote controller"
  },
  "fpv_tip_0x1F0B0040_in_the_sky": {
      "en": "No internet on remote controller. Check DJI Cellular Dongle or Wi-Fi connection status"
  },
  "fpv_tip_0x1F0B0045": {
      "en": "Enhanced link latency high. Fly with caution"
  },
  "fpv_tip_0x1F0B0045_in_the_sky": {
      "en": "Enhanced link latency high. Fly with caution"
  },
  "fpv_tip_0x1F0B0046": {
      "en": "SIM card not inserted in 4G Dongle of aircraft and eSIM card not activated"
  },
  "fpv_tip_0x1F0B0047": {
      "en": "SIM card inserted in 4G Dongle of aircraft. eSIM card selected but not activated"
  },
  "fpv_tip_0x1F0B0048": {
      "en": "SIM card not inserted in 4G Dongle of remote controller and eSIM card not activated"
  },
  "fpv_tip_0x1F0B0049": {
      "en": "SIM card inserted in 4G Dongle of remote controller. eSIM card selected but not activated"
  },
  "fpv_tip_0x1F0B004A": {
      "en": "Aircraft unable to use LTE Transmission. Network unstable or no internet on SIM %lte_index"
  },
  "fpv_tip_0x1F0B004B": {
      "en": "No internet on DJI Cellular Dongle %lte_index of aircraft. Move to location with stronger signal or try again later"
  },
  "fpv_tip_0x1F0B004C": {
      "en": "No internet on DJI Cellular Dongle %lte_index of aircraft. Change network provider"
  },
  "fpv_tip_0x1F0B004D": {
      "en": "No network connection"
  },
  "fpv_tip_0x1F0B004E": {
      "en": "SIM card not inserted in DJI Cellular Dongle %lte_index and eSIM not activated"
  },
  "fpv_tip_0x1F0B004F": {
      "en": "SIM inserted in DJI Cellular Dongle %lte_index. eSIM selected and not activated"
  },
  "fpv_tip_0x1a020180": {
      "en": "Infrared sensors overheated. Return to home or land promptly. Move away from high-temperature environment"
  },
  "fpv_tip_0x1a420bc0": {
      "en": "Downward ambient light too low. Obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1a420bc1": {
      "en": "Forward ambient light too low. Obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1a420bc2": {
      "en": "Backward ambient light too low. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420bc3": {
      "en": "Rightward ambient light too low. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420bc4": {
      "en": "Leftward ambient light too low. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420bc5": {
      "en": "Upward ambient light too low. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420bc6": {
      "en": "Horizontal ambient light too low. Horizontal obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c00": {
      "en": "Downward ambient light too bright. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c01": {
      "en": "Forward ambient light too bright. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c02": {
      "en": "Backward ambient light too bright. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c03": {
      "en": "Rightward ambient light too bright. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c04": {
      "en": "Leftward ambient light too bright. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c05": {
      "en": "Upward ambient light too bright. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c06": {
      "en": "Horizontal ambient light too bright. Horizontal obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c40": {
      "en": "Downward sensor(s) blocked. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c41": {
      "en": "Forward sensor(s) blocked. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c42": {
      "en": "Backward sensor(s) blocked. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c43": {
      "en": "Rightward sensor(s) blocked. Rightward obstacle avoidance unavailable. Only infrared sensors available.  Fly with caution"
  },
  "fpv_tip_0x1a420c44": {
      "en": "Leftward sensor(s) blocked. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c45": {
      "en": "Upward sensor(s) blocked. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c80": {
      "en": "Downward sensor(s) blurry. Downward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c81": {
      "en": "Forward sensor(s) blurry. Forward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c82": {
      "en": "Backward sensor(s) blurry. Backward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c83": {
      "en": "Right sensor(s) blurry. Rightward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c84": {
      "en": "Left sensor(s) blurry. Leftward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420c85": {
      "en": "Upward sensor(s) blurry. Upward obstacle avoidance unavailable. Only infrared sensors available. Fly with caution"
  },
  "fpv_tip_0x1a420cc0": {
      "en": "Aircraft attitude angle too large. Obstacle avoidance unavailable. Fly with caution"
  },
  "fpv_tip_0x1a420d00": {
      "en": "Aircraft attitude angle too large. Landing protection unavailable. Land manually"
  },
  "fpv_tip_0x1a420d40": {
      "en": "Aircraft approaching obstacle sensing blind spot and may be unable to detect obstacles. Fly with caution"
  },
  "fpv_tip_0x1a5103c0": {
      "en": "Downward vision sensor calibration error"
  },
  "fpv_tip_0x1a5103c1": {
      "en": "Forward vision sensor calibration error"
  },
  "fpv_tip_0x1a5103c2": {
      "en": "Backward vision sensor calibration error"
  },
  "fpv_tip_0x1a5103c3": {
      "en": "Upward vision sensor calibration error"
  },
  "fpv_tip_0x1a5103c4": {
      "en": "Left vision sensor calibration error"
  },
  "fpv_tip_0x1a5103c5": {
      "en": "Right vision sensor calibration error"
  },
  "fpv_tip_0x1afd0040": {
      "en": "Sensor system error. Unable to take off. Flight prohibited"
  },
  "fpv_tip_0x1afd0040_in_the_sky": {
      "en": "Sensor system error. Return to home or land promptly"
  },
  "fpv_tip_0x1afe0040": {
      "en": "Vision system overloaded. Fly to open area"
  },
  "fpv_tip_0x1b030019": {
      "en": "Aircraft not flying along route. Safe RTH exited"
  },
  "fpv_tip_0x1b030034": {
      "en": "Strong interference or spoofing of positioning signal. Return to home promptly"
  },
  "fpv_tip_0x1b080001": {
      "en": "Remote ID broadcast error. Failed to obtain remote controller location"
  },
  "fpv_tip_0x1b080002": {
      "en": "Remote ID module error. Contact DJI Support"
  },
  "fpv_tip_0x1c000603": {
      "en": "Risk of overexposure in photo"
  },
  "fpv_tip_0x1c000604": {
      "en": "Risk of overexposure in photo"
  },
  "fpv_tip_0x1c100405": {
      "en": "Confirm memory card read and write permissions"
  },
  "fpv_tip_0x1c100405_index_1": {
      "en": "Confirm eMMC read and write properties"
  },
  "fpv_tip_0x1c100406": {
      "en": "Memory card not formatted. Format card before use"
  },
  "fpv_tip_0x1c100406_index_1": {
      "en": "eMMC not formatted. Format before use"
  },
  "fpv_tip_0x1c100407": {
      "en": "Formatting memory card..."
  },
  "fpv_tip_0x1c100407_index_1": {
      "en": "eMMC formatting. Please wait"
  },
  "fpv_tip_0x1c100408": {
      "en": "Memory card file system not supported. Format card before use"
  },
  "fpv_tip_0x1c100409": {
      "en": "Refreshing memory card..."
  },
  "fpv_tip_0x1c100409_index_1": {
      "en": "eMMC refreshing. Please wait"
  },
  "fpv_tip_0x1c10040B": {
      "en": "Max file index exceeded. Format SD card"
  },
  "fpv_tip_0x1c10040a": {
      "en": "Memory card full. Clear space"
  },
  "fpv_tip_0x1c10040a_index_1": {
      "en": "eMMC full. Clear space"
  },
  "fpv_tip_0x1c10040b": {
      "en": "Max file index exceeded. Format SD card"
  },
  "fpv_tip_0x1c10040b_index_1": {
      "en": "eMMC memory overflow. Format eMMC and restart camera"
  },
  "fpv_tip_0x1c10040c": {
      "en": "Initializing memory card..."
  },
  "fpv_tip_0x1c10040c_index_1": {
      "en": "eMMC initializing. Please wait"
  },
  "fpv_tip_0x1c10040d": {
      "en": "Memory card error. Format card before use"
  },
  "fpv_tip_0x1c10040d_index_1": {
      "en": "eMMC error. Format before use"
  },
  "fpv_tip_0x1c10040e": {
      "en": "Fixing memory card..."
  },
  "fpv_tip_0x1c10040e_index_1": {
      "en": "eMMC repair in progress. Please wait"
  },
  "fpv_tip_0x1c10040f": {
      "en": "Memory card read and write speed low. Wait until process completes"
  },
  "fpv_tip_0x1c10040f_index_1": {
      "en": "eMMC read and write speed low. Please wait"
  },
  "fpv_tip_0x1c200405_index_1": {
      "en": "Confirm eMMC read and write properties"
  },
  "fpv_tip_0x1c200406_index_1": {
      "en": "eMMC not formatted. Format before use"
  },
  "fpv_tip_0x1c200407_index_1": {
      "en": "eMMC formatting. Please wait"
  },
  "fpv_tip_0x1c200409_index_1": {
      "en": "eMMC refreshing. Please wait"
  },
  "fpv_tip_0x1c20040a_index_1": {
      "en": "eMMC full. Clear space"
  },
  "fpv_tip_0x1c20040b": {
      "en": "Max file index exceeded. Format SD card"
  },
  "fpv_tip_0x1c20040b_index_1": {
      "en": "eMMC memory overflow. Format eMMC and restart camera"
  },
  "fpv_tip_0x1c20040c_index_1": {
      "en": "eMMC initializing. Please wait"
  },
  "fpv_tip_0x1c20040d_index_1": {
      "en": "eMMC error. Format before use"
  },
  "fpv_tip_0x1c20040e_index_1": {
      "en": "eMMC repair in progress. Please wait"
  },
  "fpv_tip_0x1c20040f_index_1": {
      "en": "eMMC read and write speed low. Please wait"
  },
  "fpv_tip_0x1c200410": {
      "en": "Verification required before using memory card. Enter password to verify"
  },
  "fpv_tip_0x1c300405_index_1": {
      "en": "Confirm eMMC read and write properties"
  },
  "fpv_tip_0x1c300406_index_1": {
      "en": "eMMC not formatted. Format before use"
  },
  "fpv_tip_0x1c300407_index_1": {
      "en": "eMMC formatting. Please wait"
  },
  "fpv_tip_0x1c300409_index_1": {
      "en": "eMMC refreshing. Please wait"
  },
  "fpv_tip_0x1c30040a_index_1": {
      "en": "eMMC full. Clear space"
  },
  "fpv_tip_0x1c30040b": {
      "en": "Max file index exceeded. Format SD card"
  },
  "fpv_tip_0x1c30040b_index_1": {
      "en": "eMMC memory overflow. Format eMMC and restart camera"
  },
  "fpv_tip_0x1c30040c_index_1": {
      "en": "eMMC initializing. Please wait"
  },
  "fpv_tip_0x1c30040d_index_1": {
      "en": "eMMC error. Format before use"
  },
  "fpv_tip_0x1c30040e_index_1": {
      "en": "eMMC repair in progress. Please wait"
  },
  "fpv_tip_0x1c30040f_index_1": {
      "en": "eMMC read and write speed low. Please wait"
  },
  "fpv_tip_0x1f0b0017": {
      "en": "LTE Transmission unavailable. Check remote controller network connectivity"
  },
  "fpv_tip_0x20010100": {
      "en": "Remote controller %2$s obtained gimbal %1$s control"
  },
  "fpv_tip_0x20010200": {
      "en": "Obtained control of current gimbal"
  },
  "fpv_tip_0x20010301": {
      "en": "Free-mode"
  },
  "fpv_tip_0x20010302": {
      "en": "Follow"
  },
  "fpv_tip_0x20010304": {
      "en": "Failed to change gimbal mode, please try again."
  },
  "fpv_tip_0x20010400": {
      "en": "Tip: Only the first camera can tap-zoom in dual gimbal control mode."
  },
  "fpv_tip_0x20010501": {
      "en": "Gimbal Recenter"
  },
  "fpv_tip_0x20010502": {
      "en": "Recenter Gimbal Pan"
  },
  "fpv_tip_0x20010503": {
      "en": "Gimbal Pitch Up"
  },
  "fpv_tip_0x20010504": {
      "en": "Gimbal Pitch Down"
  },
  "fpv_tip_0x20010505": {
      "en": "Gimbal Up"
  },
  "fpv_tip_0x20010506": {
      "en": "Gimbal down"
  },
  "fpv_tip_0x20010507": {
      "en": "Gimbal Pitch Recenter"
  },
  "fpv_tip_0x20010508": {
      "en": "Gimbal roll centered"
  },
  "fpv_tip_0x20010600": {
      "en": "Vision stabilization on."
  },
  "fpv_tip_0x20010601": {
      "en": "Vision stabilization off."
  },
  "fpv_tip_0x20010700": {
      "en": "Lost control of %1$s. Multi-Gimbal Connection disabled"
  },
  "fpv_tip_0x20010701": {
      "en": "Lost control of %1$s. %2$s dropped from Multi-Gimbal Connection"
  },
  "fpv_tip_0x20010800": {
      "en": "Transmission interrupted. Gimbal control lost"
  },
  "fpv_tip_0x20010900": {
      "en": "Remote controller %1$s transmission interrupted. Gained control of all gimbals"
  },
  "fpv_tip_0x20020000": {
      "en": "Obtained aircraft flight control. Current control stick mode: %1$s"
  },
  "fpv_tip_0x20020001": {
      "en": "Aircraft flight control unlocked"
  },
  "fpv_tip_0x20020002": {
      "en": "Aircraft flight control locked"
  },
  "fpv_tip_0x20020003": {
      "en": "Aircraft flight control obtained by remote controller %1$s"
  },
  "fpv_tip_0x20020004": {
      "en": "Unable to obtain current gimbal control"
  },
  "fpv_tip_0x20020005": {
      "en": "Aircraft flight control locked by remote controller %1$s. Unable to obtain control"
  },
  "fpv_tip_0x20020006": {
      "en": "Unable to obtain aircraft flight control"
  },
  "fpv_tip_0x20020100": {
      "en": "Tip: Aircraft reached maximum distance, please pay attention to flight safety.\\n"
  },
  "fpv_tip_0x20020200": {
      "en": "High Wind Velocity. Fly with caution."
  },
  "fpv_tip_0x20020201": {
      "en": "High Wind Velocity. Fly with caution."
  },
  "fpv_tip_0x20020300": {
      "en": "ATTI mode"
  },
  "fpv_tip_0x20020301": {
      "en": "Cannot switch flight mode. Turn on \\\"Multiple Flight Modes\\\" to enable Atti and Sport Modes"
  },
  "fpv_tip_0x20020302": {
      "en": "Switched to S (Sport)-mode."
  },
  "fpv_tip_0x20020303": {
      "en": "Switched to P (Positioning)-mode."
  },
  "fpv_tip_0x20020304": {
      "en": "Switched to T (Tripod)-mode."
  },
  "fpv_tip_0x20020305": {
      "en": "Switched to A (Attitude)-mode."
  },
  "fpv_tip_0x20020306": {
      "en": "To take off in non-P mode, toggle Flight Mode Switch to another mode and then toggle it back."
  },
  "fpv_tip_0x20020307": {
      "en": "When winch mode is enabled, aircraft acceleration is limited and braking time is extended"
  },
  "fpv_tip_0x20020308": {
      "en": "Auto release payload successful"
  },
  "fpv_tip_0x20020309": {
      "en": "Auto release payload failed"
  },
  "fpv_tip_0x20020400": {
      "en": "The remaining battery is only enough for RTH. Return home now."
  },
  "fpv_tip_0x20020500": {
      "en": "Home Point recorded. Return-to-Home Altitude:%1$.1f %2$s"
  },
  "fpv_tip_0x20020600": {
      "en": "Low power. Aircraft will return home."
  },
  "fpv_tip_0x20020601": {
      "en": "Critically low power. Aircraft is landing."
  },
  "fpv_tip_0x20020602": {
      "en": "Low power. Returning home."
  },
  "fpv_tip_0x20020603": {
      "en": "Critically low power. Aircraft is landing."
  },
  "fpv_tip_0x20020604": {
      "en": "Critically low voltage. Aircraft is landing. Press the RTH button on the remote controller to stop."
  },
  "fpv_tip_0x20020605": {
      "en": "Low voltage. Returning home now. Press the RTH button on the remote controller to stop."
  },
  "fpv_tip_0x20020606": {
      "en": "Aircraft is landing due to low voltage, which cannot be stopped. You can decelerate the descending speed and avoid obstacles using sticks."
  },
  "fpv_tip_0x20020607": {
      "en": "Aircraft is returning to the Home Point. Minimum RTH Altitude is %1$dm. You can reset the RTH Altitude in Remote Controller Settings after cancelling RTH if necessary."
  },
  "fpv_tip_0x20020608": {
      "en": "Aircraft is returning to the Home Point. RTH Altitude is %1$dm. You can reset the RTH Altitude in Remote Controller Settings after cancelling RTH if necessary."
  },
  "fpv_tip_0x20020609": {
      "en": "Aircraft signal lost. RTH started"
  },
  "fpv_tip_0x2002060A": {
      "en": "You are in a No-Fly Zone. Auto landing initiated."
  },
  "fpv_tip_0x2002060B": {
      "en": "Aircraft is close to the Home Point. Initiating Return to Home will now trigger Auto Landing."
  },
  "fpv_tip_0x2002060C": {
      "en": "Aircraft is too far from the Home Point. Initiating Return to Home will now trigger Auto Landing."
  },
  "fpv_tip_0x2002060D": {
      "en": "Landing"
  },
  "fpv_tip_0x2002060E": {
      "en": "Force Landing request from APP."
  },
  "fpv_tip_0x20020610": {
      "en": "Obstacle detected. Aircraft is landing"
  },
  "fpv_tip_0x20020611": {
      "en": "IMU error. Aircraft is returning home."
  },
  "fpv_tip_0x20020612": {
      "en": "Aircraft is returning to the Home Point."
  },
  "fpv_tip_0x2002061F": {
      "en": "Unofficial battery detected. Aircraft is landing."
  },
  "fpv_tip_0x20020700": {
      "en": "Flight mode configuration: A/P/S. Current flight mode: %1$s."
  },
  "fpv_tip_0x20020700_rc511": {
      "en": "Flight mode configuration: A/N/S. Current flight mode: %1$s."
  },
  "fpv_tip_0x20020800": {
      "en": "Remote controller %1$s requesting aircraft flight control"
  },
  "fpv_tip_0x20020900": {
      "en": "Mission interrupted. Fly aircraft manually"
  },
  "fpv_tip_0x20020A00": {
      "en": "ATTI mode"
  },
  "fpv_tip_0x20020C00": {
      "en": "FlyTo location synced to current controller"
  },
  "fpv_tip_0x20020C01": {
      "en": "Severe flight controller error. Control aircraft manually and land promptly"
  },
  "fpv_tip_0x20020D00": {
      "en": "Rising landing gear. Obstacle avoidance unavailable"
  },
  "fpv_tip_0x20020D01": {
      "en": "Lowering landing gear. Obstacle avoidance unavailable"
  },
  "fpv_tip_0x20020E01": {
      "en": "Home Point update stopped. Remote controller disconnected. Pay attention to current Home Point location"
  },
  "fpv_tip_0x20020E02": {
      "en": "Remote controller connected again. Updating Home Point..."
  },
  "fpv_tip_0x20020b00": {
      "en": "Taking off"
  },
  "fpv_tip_0x20020b01": {
      "en": "Takeoff canceled"
  },
  "fpv_tip_0x20021000": {
      "en": "Control sticks not centered. Unable to take off. Ensure control sticks are centered"
  },
  "fpv_tip_0x20021001": {
      "en": "RTH canceled"
  },
  "fpv_tip_0x20021002": {
      "en": "Landing canceled"
  },
  "fpv_tip_0x20022001": {
      "en": "QuickSpin started"
  },
  "fpv_tip_0x20022002": {
      "en": "No GNSS signal. Flight area unknown. Flight altitude limited to %1$s"
  },
  "fpv_tip_0x20022003": {
      "en": "No GNSS signal. Flight area unknown, and ambient light too low. Flight altitude limited to %1$s"
  },
  "fpv_tip_0x20030000": {
      "en": "Manned aircraft detected nearby. Fly with caution"
  },
  "fpv_tip_0x20040000": {
      "en": "Flight speed too fast. Interval shooting may fail"
  },
  "fpv_tip_0x20040100": {
      "en": "Manual exposure is enabled. Metering is disabled."
  },
  "fpv_tip_0x20040200": {
      "en": "Object too close to laser rangefinder"
  },
  "fpv_tip_0x20040201": {
      "en": "Object too far for laser rangefinder"
  },
  "fpv_tip_0x20040300": {
      "en": "Capturing one-tap pano with visible light camera"
  },
  "fpv_tip_0x20040400": {
      "en": "Zenmuse XT S not compatible with other infrared cameras"
  },
  "fpv_tip_0x20040500": {
      "en": "Camera %component_index overheated. Shooting photos/videos may fail"
  },
  "fpv_tip_0x20040501": {
      "en": "Camera %component_index overheated. Wait for temperature to return to normal before use"
  },
  "fpv_tip_0x20040600": {
      "en": "SD card speed is slow, cannot record 4K video."
  },
  "fpv_tip_0x20040700": {
      "en": "Live stream video frame rate less than 9Hz. Fly with caution."
  },
  "fpv_tip_0x20040800": {
      "en": "Aircraft RTH or landing. Unable to enter Pano mode"
  },
  "fpv_tip_0x20040801": {
      "en": "Panorama taken successfully. View in Playback"
  },
  "fpv_tip_0x20040802": {
      "en": "Taking panorama failed"
  },
  "fpv_tip_0x20040803": {
      "en": "Panorama stopped"
  },
  "fpv_tip_0x20040804": {
      "en": "Aircraft not in flight. Unable to take panorama"
  },
  "fpv_tip_0x20040805": {
      "en": "Getting control failed. Panorama stopped"
  },
  "fpv_tip_0x20040806": {
      "en": "Unknown camera error. Unable to start panorama"
  },
  "fpv_tip_0x20040807": {
      "en": "Camera response timed out. Panorama stopped"
  },
  "fpv_tip_0x20040808": {
      "en": "Insufficient storage. Panorama stopped"
  },
  "fpv_tip_0x20040809": {
      "en": "Aircraft moving. Unable to take panorama"
  },
  "fpv_tip_0x2004080A": {
      "en": "Gimbal moving. Unable to take panorama"
  },
  "fpv_tip_0x2004080B": {
      "en": "Control stick moved. Panorama stopped"
  },
  "fpv_tip_0x2004080C": {
      "en": "Max altitude limit reached. Panorama stopped"
  },
  "fpv_tip_0x2004080D": {
      "en": "Max distance reached. Panorama stopped"
  },
  "fpv_tip_0x2004080E": {
      "en": "Gimbal stuck. Panorama stopped"
  },
  "fpv_tip_0x2004080F": {
      "en": "Taking photo failed. Panorama stopped"
  },
  "fpv_tip_0x20040810": {
      "en": "Stitching panorama failed"
  },
  "fpv_tip_0x20040811": {
      "en": "Loading calibration parameters failed. Panorama stopped"
  },
  "fpv_tip_0x20040812": {
      "en": "Adjusting camera parameters failed. Panorama stopped"
  },
  "fpv_tip_0x20040813": {
      "en": "Stitching panorama..."
  },
  "fpv_tip_0x20040900": {
      "en": "Playback for Primary remote controller in progress. Disconnected from Liveview for Secondary remote controller"
  },
  "fpv_tip_0x20040A00": {
      "en": "Camera busy, cannot enter playback."
  },
  "fpv_tip_0x20040A01": {
      "en": "No memory card"
  },
  "fpv_tip_0x20040B00": {
      "en": "Install LiDAR on Gimbal I for optimal use"
  },
  "fpv_tip_0x20040C00": {
      "en": "Using Controller B. Unable to support LiDAR. Switch to Controller A"
  },
  "fpv_tip_0x20040D00": {
      "en": "Calibration failed. Check and try again"
  },
  "fpv_tip_0x20040D01": {
      "en": "Calibration failed. Contact DJI Support"
  },
  "fpv_tip_0x20040E00": {
      "en": "%1$s camera lens not calibrated. Focusing will be affected. Go to Settings > Calibrate Lens to calibrate."
  },
  "fpv_tip_0x20040F00": {
      "en": "Collecting point cloud data. Real-time data transfer unstable"
  },
  "fpv_tip_0x20041000": {
      "en": "Payload IMU warmed up"
  },
  "fpv_tip_0x20041100": {
      "en": "Point cloud model recording in progress. Unable to record video"
  },
  "fpv_tip_0x20041200": {
      "en": "Calibration Flight complete. Proceed ahead"
  },
  "fpv_tip_0x20041300": {
      "en": "Calibration Flight stopped manually. Check and try again"
  },
  "fpv_tip_0x20041400": {
      "en": "Communication error between aircraft and payload"
  },
  "fpv_tip_0x20041500": {
      "en": "IR Zoom Max"
  },
  "fpv_tip_0x20041600": {
      "en": "Switched successfully. A wider temperature measurement range available"
  },
  "fpv_tip_0x20041601": {
      "en": "Switched successfully. A more precise temperature measurement available"
  },
  "fpv_tip_0x20041602": {
      "en": "Switched successfully. High-Res mode provides clearer infrared image. Temperature measurement not supported"
  },
  "fpv_tip_0x20041700": {
      "en": "Light sensor blocked. Multispectral data collection affected"
  },
  "fpv_tip_0x20041801": {
      "en": "Incompatible camera parameters. Restart app and go to home screen to update"
  },
  "fpv_tip_0x20050100": {
      "en": "Nearby GEO Zone has temporary restrictions preparing to take effect in 1 hour"
  },
  "fpv_tip_0x20050200": {
      "en": "Nearby GEO Zone has temporary restrictions preparing to take effect (%1$d)"
  },
  "fpv_tip_0x20050300": {
      "en": "Aircraft will automatically descend in %1$s"
  },
  "fpv_tip_0x20050400": {
      "en": "Aircraft leaving unlocked GEO zone. Fly with caution"
  },
  "fpv_tip_0x20050401": {
      "en": "Aircraft at the boundary of a GEO Zone. RTH may be stopped. Fly away promptly"
  },
  "fpv_tip_0x20050402": {
      "en": "Aircraft at boundary of an Altitude Zone (%1$s ). Fly away or descend as soon as possible"
  },
  "fpv_tip_0x20050403": {
      "en": "Aircraft entered Altitude Zone (%1$s). Fly with caution"
  },
  "fpv_tip_0x20050404": {
      "en": "Aircraft at the boundary of a Restricted Zone. Fly away promptly"
  },
  "fpv_tip_0x20050405": {
      "en": "GEO: You are in a Warning Zone (%1$s). Fly with caution."
  },
  "fpv_tip_0x20050406": {
      "en": "Your aircraft is flying in an Altitude Zone (%1$s). Please fly with caution."
  },
  "fpv_tip_0x20050407": {
      "en": "Aircraft in GEO Zone. Max flight altitude: %1$s"
  },
  "fpv_tip_0x20050408": {
      "en": "Aircraft flying in an Altitude Zone (%s). Fly with caution"
  },
  "fpv_tip_0x20050500": {
      "en": "Reaching maximum altitude..."
  },
  "fpv_tip_0x20050600": {
      "en": "Multiple Enhanced Warning Zones near aircraft. Fly with caution"
  },
  "fpv_tip_0x20050601": {
      "en": "Multiple Enhanced Warning Zones below aircraft. Fly with caution"
  },
  "fpv_tip_0x20050602": {
      "en": "Multiple Enhanced Warning Zones above aircraft. Fly with caution"
  },
  "fpv_tip_0x20050603": {
      "en": "Enhanced Warning Zone nearby (%s. Altitude range: %s m. Effective time: %s). Fly with caution"
  },
  "fpv_tip_0x20050604": {
      "en": "Enhanced Warning Zone below (%s. Altitude range: %s m. Effective time: %s). Fly with caution"
  },
  "fpv_tip_0x20050605": {
      "en": "Enhanced Warning Zone above (%s. Altitude range: %s m. Effective time: %s). Fly with caution"
  },
  "fpv_tip_0x20050606": {
      "en": "Aircraft in Enhanced Warning Zone (%s. Altitude range: %s. Effective time: %s). Fly with caution"
  },
  "fpv_tip_0x20050607": {
      "en": "Multiple Enhanced Warning Zones nearby. Fly with caution"
  },
  "fpv_tip_0x20050608": {
      "en": "GNSS signal weak. Geo-awareness function degraded. Fly with caution"
  },
  "fpv_tip_0x20060100": {
      "en": "GNSS coordinates copied %s"
  },
  "fpv_tip_0x20060200": {
      "en": "RNG info copied. %s"
  },
  "fpv_tip_0x20070100": {
      "en": "Aircraft propulsion system error. Forced landing. Manually control the aircraft and land in an open area"
  },
  "fpv_tip_0x20070101": {
      "en": "Aircraft propulsion system error. Unable to take off"
  },
  "fpv_tip_0x20080100": {
      "en": "Switched to OcuSync image transmission"
  },
  "fpv_tip_0x20080101": {
      "en": "Switched to LTE Dual Link"
  },
  "fpv_tip_0x20080200": {
      "en": "In dual control mode or current RC set as controller B. LTE Dual Link unavailable"
  },
  "fpv_tip_0x20080300": {
      "en": "LTE network unstable. Fly with caution"
  },
  "fpv_tip_0x20080400": {
      "en": "Image transmission signal weak. Adjust antennas and fly with caution"
  },
  "fpv_tip_0x20080500": {
      "en": "Remote Controller Signal Weak"
  },
  "fpv_tip_0x20080600": {
      "en": "Strong remote controller signal interference. Move away from other remote controllers or the source of interference"
  },
  "fpv_tip_0x20080700": {
      "en": "Strong aircraft signal interference. Return to home promptly or move away from the source of interference"
  },
  "fpv_tip_0x20090000": {
      "en": "Vision stabilization paused."
  },
  "fpv_tip_0x20090001": {
      "en": "Vision stabilization resumed."
  },
  "fpv_tip_0x20090002": {
      "en": "Vision stabilization paused.Reason: Features too few in current video."
  },
  "fpv_tip_0x20090003": {
      "en": "Vision stabilization resumed.Reason: Features too few in current video."
  },
  "fpv_tip_0x20090004": {
      "en": "Vision stabilization paused.Reason: Features too few in current video.Reason: Features too few in current video."
  },
  "fpv_tip_0x20090100": {
      "en": "Pin Point Success"
  },
  "fpv_tip_0x20090101": {
      "en": "Unable to get laser rangefinder data. Recording location failed"
  },
  "fpv_tip_0x20090102": {
      "en": "Insufficient satellites available. Recording location failed"
  },
  "fpv_tip_0x20090103": {
      "en": "Aircraft location pinned successfully"
  },
  "fpv_tip_0x20090200": {
      "en": "Starting Smart Track"
  },
  "fpv_tip_0x20090201": {
      "en": "Smart Track Turn Off"
  },
  "fpv_tip_0x20090202": {
      "en": "Unable to identify target. Drag-select target on screen"
  },
  "fpv_tip_0x200A0000": {
      "en": "Disable Wi-Fi before enabling Relay mode to avoid signal interference"
  },
  "fpv_tip_0x20100000": {
      "en": "D-RTK Low Battery"
  },
  "fpv_tip_0x20100100": {
      "en": "RTK status error. Entering Maintain Positioning Accuracy mode. Aircraft will exit RTK if reconnection timed out"
  },
  "fpv_tip_0x20100200": {
      "en": "RTK base station not found. Check base station and search again"
  },
  "fpv_tip_0x2010D000": {
      "en": "D-RTK  Low Battery"
  },
  "fpv_tip_0x20110000": {
      "en": "Battery heating"
  },
  "fpv_tip_0x20120000": {
      "en": "Failed to access GNSS positioning information of mobile device"
  },
  "fpv_tip_0x20120001": {
      "en": "Failed to access GNSS positioning information of DJI device"
  },
  "fpv_tip_0x20120200": {
      "en": "Land aircraft before continuing to next step"
  },
  "fpv_tip_0x20120300": {
      "en": "Aircraft flying to editing start point"
  },
  "fpv_tip_0x20120301": {
      "en": "Arrived at editing start point. Begin editing"
  },
  "fpv_tip_0x20120302": {
      "en": "Exit Flight Route Editing"
  },
  "fpv_tip_0x20120400": {
      "en": "Phone not bound. Altitude and distance limited. Bind phone before taking off."
  },
  "fpv_tip_0x20120500": {
      "en": "Media files will be uploaded through current remote controller"
  },
  "fpv_tip_0x20120501": {
      "en": "Media files will be uploaded through another remote controller"
  },
  "fpv_tip_0x20120502": {
      "en": "Aircraft cloud control enabled"
  },
  "fpv_tip_0x20120503": {
      "en": "Aircraft bound to other team. Cloud service unavailable"
  },
  "fpv_tip_0x20120600": {
      "en": "Removed from project. Unable to view project details"
  },
  "fpv_tip_0x20120700": {
      "en": "Project archived. Unable to view project details"
  },
  "fpv_tip_0x20120800": {
      "en": "Project team disbanded. Unable to view project details"
  },
  "fpv_tip_0x20120900": {
      "en": "Real-time mapping stopped"
  },
  "fpv_tip_0x20120A00": {
      "en": "Livestream started"
  },
  "fpv_tip_0x20120B00": {
      "en": "Flight route mission paused manually"
  },
  "fpv_tip_0x20120B01": {
      "en": "RTK signal weak. Flight route mission paused"
  },
  "fpv_tip_0x20120B02": {
      "en": "Max flight altitude reached. Flight route mission paused"
  },
  "fpv_tip_0x20120B03": {
      "en": "Max flight distance reached. Flight route mission paused"
  },
  "fpv_tip_0x20120B04": {
      "en": "Task paused. Obstacle detected"
  },
  "fpv_tip_0x20120B05": {
      "en": "Approaching GEO Zone. Task paused"
  },
  "fpv_tip_0x20120B06": {
      "en": "Min flight altitude reached. Task paused"
  },
  "fpv_tip_0x20120B07": {
      "en": "Unknown error. Task paused"
  },
  "fpv_tip_0x20120B08": {
      "en": "Max flight altitude of airport Altitude Zone reached"
  },
  "fpv_tip_0x20120B09": {
      "en": "Emergency brake triggered. Flight task paused"
  },
  "fpv_tip_0x20120B0A": {
      "en": "Ambient light is too bright or too dark"
  },
  "fpv_tip_0x20120B0B": {
      "en": "GNSS signal weak. Task paused"
  },
  "fpv_tip_0x20120B0C": {
      "en": "Home Point not updated. Flight task paused"
  },
  "fpv_tip_0x20120B0D": {
      "en": "Remote controller disconnected. Smart RTH in progress"
  },
  "fpv_tip_0x20120B0E": {
      "en": "Auto takeoff failed. Try uploading flight route again"
  },
  "fpv_tip_0x20120B0F": {
      "en": "Approaching boundary of custom flight area. Flight task paused"
  },
  "fpv_tip_0x20120B10": {
      "en": "Terrain Follow altitude error (greater than 200 m or less than 30 m). Modify parameter"
  },
  "fpv_tip_0x20120B11": {
      "en": "Wind speed too high. Flight task paused"
  },
  "fpv_tip_0x20120B12": {
      "en": "Aircraft at the boundary of an Altitude Zone or aircraft altitude too low. Flight task paused"
  },
  "fpv_tip_0x20120B13": {
      "en": "PSDK on blocklist. Remove device before takeoff"
  },
  "fpv_tip_0x20120BA0": {
      "en": "Aircraft verification in progress..."
  },
  "fpv_tip_0x20120C00": {
      "en": "Disable Vision Positioning"
  },
  "fpv_tip_0x20120C01": {
      "en": "Enable Vision Positioning"
  },
  "fpv_tip_0x20120C02": {
      "en": "Cloud control from DJI DeliveryHub enabled"
  },
  "fpv_tip_0x20120C03": {
      "en": "Pausing flight through cloud control"
  },
  "fpv_tip_0x20120C04": {
      "en": "Aircraft landing through cloud control"
  },
  "fpv_tip_0x20120C05": {
      "en": "Aircraft returning to home through cloud control"
  },
  "fpv_tip_0x20120C06": {
      "en": "Operating winch system through cloud control"
  },
  "fpv_tip_0x20120C07": {
      "en": "Operating FPV gimbal camera through cloud control"
  },
  "fpv_tip_0x20120C08": {
      "en": "Performing flight task through cloud control"
  },
  "fpv_tip_0x20120C09": {
      "en": "Cloud control from DJI DeliveryHub disabled"
  },
  "fpv_tip_0x20120C0A": {
      "en": "RTH canceled by cloud control"
  },
  "fpv_tip_0x20120C0B": {
      "en": "Aircraft locked"
  },
  "fpv_tip_0x20120C0C": {
      "en": "Aircraft unlocked"
  },
  "fpv_tip_0x20120D01": {
      "en": "Obstacle sensing of all directions disabled. Fly with caution"
  },
  "fpv_tip_0x20120D02": {
      "en": "Horizontal obstacle sensing disabled. Fly with caution"
  },
  "fpv_tip_0x20120D03": {
      "en": "Upward obstacle sensing disabled. Fly with caution"
  },
  "fpv_tip_0x20120D04": {
      "en": "Downward obstacle sensing disabled. Fly with caution"
  },
  "fpv_tip_0x20120D05": {
      "en": "Obstacle sensing of all directions enabled"
  },
  "fpv_tip_0x20120D06": {
      "en": "Horizontal obstacle sensing enabled"
  },
  "fpv_tip_0x20120D07": {
      "en": "Upward obstacle sensing enabled"
  },
  "fpv_tip_0x20120D08": {
      "en": "Downward obstacle sensing enabled"
  },
  "fpv_tip_0x20120E00": {
      "en": "High ionospheric activity in current area. Positioning accuracy may be affected"
  },
  "fpv_tip_0x20120E01": {
      "en": "Aircraft and remote controller firmware versions do not match. Update firmware to latest version"
  },
  "fpv_tip_0x20120F00": {
      "en": "Account not logged in. Flight altitude and distance restricted to 30 m and 50 m respectively. Login required"
  }
}

export default cloudHmsMessages;