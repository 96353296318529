import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../../../../helper/utils";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";
import Form from "../../../../../ui/Form/Form";
import InputBox from "../../../../../ui/InputBox/InputBox";
import { changePassword, password } from "../UserManagementSlice";
import "./ChangeUserPassword.sass";

function ChangeUserPassword({ onOpen, onConfirm }) {
  const [data, setData] = useState({
    AutoGeneratedPassword: true,
    EmailChangedPassword: true,
  });
  const [required, setRequired] = useState([
    "Password",
    "ConfirmPassword",
    "Email",
  ]);
  const [validate, setValidate] = useState(true);
  const [alerts, setAlerts] = useState({
    Password: { alert: false, type: "error", message: "" },
  });
  const dispatch = useDispatch();
  const table = useSelector((state) => state.table);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    setRequired(
      data.AutoGeneratedPassword ? [] : ["Password", "ConfirmPassword"]
    );
    if (data.AutoGeneratedPassword) {
      const {
        Password: value1,
        ConfirmPassword: value2,
        ...withoutSecond
      } = data;
      setData({ ...withoutSecond });
    }
  }, [data.AutoGeneratedPassword]);
  useEffect(() => {
    setData({ ...data, Email: table.row.emailAddress });
    onOpen();
  }, [table.row]);

  useEffect(() => {
    if (user.password.status === "success") {
      dispatch(password({ status: "" }));
      onConfirm();
    } else if (user.password.status === "validation") {
      let validations = {};
      user.password.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
  }, [user.password]);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    setAlerts({ ...alerts, [key]: { alert: false } });

    if (key === "Email") {
      setValidate(validateEmail(value) && table.row.emailAddress === value);
    }
    if (key === "Password") {
      setValidate(data.ConfirmPassword === value);
    }

    if (key === "AutoGeneratedPassword" || key === "EmailChangedPassword") {
      value = event.target.checked;
    }

    if (key === "ConfirmPassword") {
      setValidate(data.Password === value);
    }

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };

  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (table.row !== undefined)
      dispatch(changePassword({ ...data }, table.row.id));
    dispatch(password({ status: "" }));
  };

  return (
    <div className="change-user-password">
      <Form
        submit={{
          title: "OK",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        className="change-password-container"
        validate={validate}
      >
        <InputBox
          title="Change password for"
          name="Email"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Email}
          value={table.row.emailAddress}
          index={2}
          background="white"
          type="email"
          isReadOnly={true}
        />

        <CheckBox
          name="AutoGeneratedPassword"
          text="Automatically generate password"
          onChecked={changeHandler}
          checked={data.AutoGeneratedPassword}
        />
        {!data.AutoGeneratedPassword && (
          <>
            <InputBox
              title="Password"
              name="Password"
              isRequired={true}
              onChange={changeHandler}
              onFocus={focusHandler}
              status={alerts.Password}
              value={data.Password}
              index={0}
              background="white"
              type="password"
            />

            <InputBox
              title="Confirm Password"
              name="ConfirmPassword"
              isRequired={true}
              onChange={changeHandler}
              onFocus={focusHandler}
              status={alerts.ConfirmPassword}
              value={data.ConfirmPassword}
              index={1}
              background="white"
              type="password"
            />
          </>
        )}
        <CheckBox
          name="EmailChangedPassword"
          text="Optionally email changed password to user"
          onChecked={changeHandler}
          checked={data.EmailChangedPassword}
        />
      </Form>
    </div>
  );
}

export default ChangeUserPassword;
