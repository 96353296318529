import { createSlice } from "@reduxjs/toolkit";
const phoneSelectorSlice = createSlice({
  name: "phoneNumber",
  initialState: { value: "", prefix: "" },
  reducers: {
    phoneNumber(state, action) {
      const data = action.payload;
      return { ...state, ...data };
    },
  },
});
export const { phoneNumber } = phoneSelectorSlice.actions;
export default phoneSelectorSlice.reducer;
