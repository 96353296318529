import { useAccount } from "src/helper/AccountStateProvider";
import { onboardConnectionManager } from "src/helper/HubConnectionManager";
import { useDeviceId } from "src/helper/SerialNumberProvider";
import React, { useEffect, useRef, useMemo, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "src/ui/Loading/Loading";
import { useFrontendHub } from "../FrontendHubProvider/FrontendHubProvider";
import {
  addToPermissionPool,
  newOnboardConnection,
  removeFromConnectionPool,
  removeFromConnections,
  removeFromPermissionPool,
} from "../WebsocketHandler/WebsocketSlice";
import { HubEventName, HubName } from "src/helper/HubConnection";

const OnboardHubContext = React.createContext({});

export const useOnboardHub = () => {
  return useContext(OnboardHubContext);
};

function OnboardHubProvider({
  candidateGroups,
  onJoinComplete,
  onConnectComplete,
  onRequestForConnection,
  children,
  autoGroupLeave = true
}) {
  const { deviceId } = useDeviceId();
  const { account } = useAccount();
  const dispatch = useDispatch();
  const serverMessageHandlerIds = useRef([]);
  const systemMessageHandlerIds = useRef([]);
  const onboardSystemMessageHandlerIds = useRef([]);
  const { hub: frontendHub, checkConnectivity } = useFrontendHub();

  const permissionGroups = useSelector(
    (state) => state.websocket.permissionGroups
  );
  const [joinCompleted, setJoinCompleted] = useState(false);
  const loading = useSelector((state) => state.loading);
  const contextValue = useMemo(
    () => ({
      hub: onboardConnectionManager,
      groups: permissionGroups[deviceId],
      deviceId,
      joinCompleted,
    }),
    [deviceId, joinCompleted, permissionGroups]
  );

  const onboardSystemMessageHandlers = [
    {
      identity: deviceId,
      name: HubEventName.CONNECTED,
      handler: (data) => {
        dispatch(removeFromPermissionPool(data?.userId.split("_")[0]));
        dispatch(addToPermissionPool(data?.userId.split("_")[0]));
      },
    },
  ];

  const systemMessageHandlers = [
    {
      identity: account.user.localAccountId,
      name: HubEventName.CONNECTED,
      handler: (data) => {
        checkConnectivity(account.user.localAccountId, deviceId);
        if (onRequestForConnection) onRequestForConnection();
      },
    },
  ];

  const serverMessageHandlers = [
    {
      identity: deviceId,
      name: HubEventName.CONNECTIVITY_STATUS,
      platform: HubName.ONBOARD,
      handler: (data) => {
        dispatch(newOnboardConnection(data?.userId));
        if (onConnectComplete) onConnectComplete();
      },
    },
    {
      identity: deviceId,
      name: HubEventName.CONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        checkConnectivity(account.user.localAccountId, data?.userId);
      },
    },
    {
      identity: deviceId,
      name: HubEventName.DISCONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        dispatch(removeFromConnectionPool(data?.userId));
        dispatch(removeFromPermissionPool(data?.userId));
        dispatch(removeFromConnections(data?.userId));
      },
    },
  ];

  useEffect(() => {
    if (permissionGroups[deviceId] && permissionGroups[deviceId].join) {
      candidateGroups
        .map((group) => permissionGroups[deviceId]?.join[group])
        .forEach((group) =>
          onboardConnectionManager?.joinGroup(deviceId, group)
        );
      onJoinComplete();
      setJoinCompleted(true);
    }
    return () => {
      if(autoGroupLeave) {
        candidateGroups
        .map((group) => permissionGroups[deviceId]?.join[group])
        .forEach((group) => {
          if(group)
            onboardConnectionManager?.leaveGroup(deviceId, group)
        });
      }
    };
  }, [permissionGroups[deviceId]]);

  useEffect(() => {
    //frontend server messages
    frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);

    serverMessageHandlerIds.current = frontendHub.subscribeServerMessages(
      serverMessageHandlers,
      "onboardhubprovider"
    );

    //frontend system messages
    frontendHub.unsubscribeSystemMessages(systemMessageHandlerIds.current);

    systemMessageHandlerIds.current = frontendHub.subscribeSystemMessages(
      systemMessageHandlers,
      "onboardhubprovider"
    );

    //onboard system messages

    onboardConnectionManager.unsubscribeSystemMessages(
      onboardSystemMessageHandlerIds.current
    );

    onboardSystemMessageHandlerIds.current =
      onboardConnectionManager.subscribeSystemMessages(
        onboardSystemMessageHandlers,
        "onboardhubprovider"
      );

    if (deviceId !== undefined) {
      checkConnectivity(account.user.localAccountId, deviceId);
      if (onRequestForConnection) onRequestForConnection();
    }

    return () => {
      frontendHub.unsubscribeServerMessages(serverMessageHandlerIds.current);
      frontendHub.unsubscribeSystemMessages(systemMessageHandlerIds.current);
      onboardConnectionManager.unsubscribeSystemMessages(
        onboardSystemMessageHandlerIds.current
      );
    };
  }, [deviceId]);

  return (
    <OnboardHubContext.Provider value={contextValue}>
      {loading.status && loading.name === "device-ws/permissions" && (
        <Loading />
      )}
      {children}
    </OnboardHubContext.Provider>
  );
}

export default OnboardHubProvider;
