import './Select.sass'
import { Select as RadixSelect } from '@radix-ui/themes'
import { SelectRootProps } from '@radix-ui/themes/dist/cjs/components/select'
import classNames from 'classnames'

interface SelectItemProps {
  label: string, 
  value: string, 
  type?: 'item' | 'seperator', 
  disabled?: boolean
}

interface SelectItemsGroupProps {
  items: SelectItemProps[],
  label?: string
}

interface SelectProps extends SelectRootProps {
  className?: string;
  style?: React.CSSProperties
  content: SelectItemsGroupProps[],
}

function Select({ className, content, style, ...props}: SelectProps) {
  return (
    <RadixSelect.Root {...props}>
      <RadixSelect.Trigger className={classNames(className)} style={style} />
      <RadixSelect.Content>
        {content.map((group, index) => (
          <RadixSelect.Group key={index}>
            {group.label && <RadixSelect.Label>{group.label}</RadixSelect.Label>}
            {group.items.map((item, index) => (
              item.type === 'seperator' ? 
                <RadixSelect.Separator key={index} /> :
                <RadixSelect.Item key={index} value={item.value} disabled={item.disabled}>{item.label}</RadixSelect.Item>
            ))}
          </RadixSelect.Group>
        ))}
      </RadixSelect.Content>
    </RadixSelect.Root>
  )
}

Select.Trigger = RadixSelect.Trigger;
Select.Content = RadixSelect.Content;
Select.Group = RadixSelect.Group;
Select.Label = RadixSelect.Label;
Select.Item = RadixSelect.Item;
Select.Separator = RadixSelect.Separator;
Select.Root = RadixSelect.Root;

export default Select