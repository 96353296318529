import './EditGroupOptions.sass'

import React, { useEffect, useMemo } from 'react'
import { usePrivileges } from 'src/helper/AccountStateProvider';
import Tabs from 'src/ui/Tabs/Tabs';
import EditGroup from "../EditGroup/EditGroup";
import GroupMembership from "../GroupMembership/GroupMembership";
import GroupOwnership from "../GroupOwnership/GroupOwnership";

function EditGroupOptions({ onOpen, onConfirm, defaultIndex }) {
  const { privileges } = usePrivileges();
  const permissions = useMemo(() => privileges?.admins.find((item) => item.id === "groups")?.privileges, [privileges]);
  const hasPermission = (id) => permissions?.includes(id);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className="edit-group-options">
      <Tabs
        defaultIndex={defaultIndex}
        items={[
          {
            title: "Information",
            content: <EditGroup onConfirm={onConfirm} />,
            hidden: !hasPermission('edit_group')
          },
          {
            title: "Membership",
            content: <GroupMembership onConfirm={onConfirm} />,
            hidden: !hasPermission('manage_memberships')
          },
          {
            title: "Ownership",
            content: <GroupOwnership onConfirm={onConfirm} />,
            hidden: !hasPermission('manage_ownerships')
          },
        ]}
      />
    </div>
  )
}

export default EditGroupOptions