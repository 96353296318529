import "./Table.sass";
import { FiMoreVertical } from "react-icons/fi";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { ChooseColumns } from "../Icons/Icons";
import OutSideDetector from "../OutsideDetector/OutSideDetector";
import CheckBox from "../CheckBox/CheckBox";
import LabeledButton from "../LabeledButton/LabeledButton";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading/Loading";
import { useAccount } from "../../helper/AccountStateProvider";
import { SetCurrentRow } from "./TableSlice";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useTheme } from "../../helper/ThemeProvider";

function Table({
  rows,
  onRowChange,
  onRowsChange,
  multipleSelect,
  selectable,
  optionChangeHandler,
  tags,
}) {
  const [localRows, setLocalRows] = useState({
    values: [],
    headers: [],
    options: [],
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleColumnSelector, setToggleColumnSelector] = useState(false);
  const [checkMultiSelect, setCheckMultiSelect] = useState(false);
  const [markAll, setMarkAll] = useState(false);
  const loading = useSelector((state) => state.loading);
  const { account } = useAccount();
  const dispatch = useDispatch();
  const { currentContext } = useTheme();

  useEffect(() => {
    setCheckMultiSelect(multipleSelect);
  }, [multipleSelect]);

  const checkSelected = (row) => {
    dispatch(
      SetCurrentRow(
        Object.entries(row)
          .map((y) => ({ [y[0]]: y[1] }))
          .reduce((z, w) => Object.assign(z, w), {})
      )
    );
    localRows.values.map((item) => (item.active = false));
    row.active = true;

    setLocalRows({
      ...localRows,
      values: [...localRows.values],
    });
    onRowChange(row);
  };

  useEffect(() => {
    setSelectedRows(localRows.values.filter((x) => x.active === true));
  }, [localRows]);

  useEffect(() => {
    onRowsChange(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    setLocalRows(rows);
  }, [rows]);

  const moreOptionsHandler = (row) => {
    dispatch(SetCurrentRow({ ...row }));
    localRows.values.map((item) => {
      if (item.id !== row.id) {
        item.active = false;
        item.moreOption = false;
      }
    });
    row.moreOption = !row.moreOption;
    row.active = true;
    const conflict = row.id === account.user.localAccountId;
    localRows.options.map(
      (item) =>
        (item.active =
          item.type === "dependent"
            ? item.except === "account" && !conflict
            : item.active)
    );
    setLocalRows({
      ...localRows,
      options: [...localRows.options],
      values: [...localRows.values],
    });
  };

  const triggerColumns = () => {
    setToggleColumnSelector(!toggleColumnSelector);
  };

  const chooseColumnHandler = (event) => {
    const key = event.target.name;
    const value = event.target.checked;
    localRows.headers.map((row) =>
      row.value === key ? (row.status = value) : ""
    );

    setLocalRows({
      ...localRows,
      headers: [...localRows.headers],
    });
  };
  useEffect(() => {
    localRows.values.map((item) => (item.active = markAll));
    setLocalRows({ ...localRows, values: [...localRows.values] });
  }, [markAll]);

  const checkSelectedRow = (row) => {
    dispatch(
      SetCurrentRow(
        Object.entries(row)
          .map((y) => ({ [y[0]]: y[1] }))
          .reduce((z, w) => Object.assign(z, w), {})
      )
    );
    if (checkMultiSelect) {
      setLocalRows({
        ...localRows,
        values: [
          ...localRows.values.map((item) => ({
            ...item,
            active: item.id === row.id ? !item.active : item.active,
          })),
        ],
      });
    } else {
      setLocalRows({
        ...localRows,
        values: [
          ...localRows.values.map((item) => ({
            ...item,
            active: item.id === row.id ? true : false,
          })),
        ],
      });
    }
  };

  return (
    <div className="table-wrapper">
      {loading.status &&
        (tags.includes(loading.name) ||
          loading.name === "accountState/accountState") && <Loading />}
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {selectable && (
                <th
                  onClick={(event) => {
                    event.stopPropagation();
                    setMarkAll(!markAll);
                  }}
                >
                  {markAll ? (
                    <IoIosCheckmarkCircle
                      cursor="pointer"
                      fontSize="1.5em"
                      color={currentContext.theme.color}
                    />
                  ) : (
                    <RiCheckboxBlankCircleLine
                      cursor="pointer"
                      fontSize="1.5em"
                      color={currentContext.theme.color}
                    />
                  )}
                </th>
              )}

              {localRows.headers.map(
                (rKey, kIndex) =>
                  rKey.status === true && (
                    <th className="table-head-row" key={kIndex}>
                      {rKey.title}
                    </th>
                  )
              )}
              <th>
                <ChooseColumns onClick={triggerColumns} />
                {toggleColumnSelector && (
                  <OutSideDetector
                    detect={triggerColumns}
                    className="table-head-choose-columns-container"
                  >
                    <ul className="table-head-choose-columns">
                      {localRows.headers.map((column, index) => (
                        <li key={index} className="table-choose-column-item">
                          <CheckBox
                            name={column.value}
                            text={column.title}
                            checked={column.status}
                            onChecked={chooseColumnHandler}
                          />
                        </li>
                      ))}
                    </ul>
                  </OutSideDetector>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {localRows.values.map((row, index) => (
              <tr
                className="table-body-row"
                key={index}
                onClick={(event) => {
                  event.stopPropagation();
                  checkSelected(row);
                }}
                style={{
                  background: row.active && currentContext.theme.color + "09",
                  color: row.active && "#515151",
                  textDecorationLine: row.deleted ? "line-through" : "none",
                }}
              >
                {selectable && (
                  <td
                    className="table-body-item-select"
                    onClick={(event) => {
                      event.stopPropagation();
                      checkSelectedRow(row);
                    }}
                  >
                    {row.active ? (
                      <IoIosCheckmarkCircle
                        cursor="pointer"
                        fontSize="1.5em"
                        color={currentContext.theme.color}
                      />
                    ) : (
                      <RiCheckboxBlankCircleLine
                        cursor="pointer"
                        fontSize="1.5em"
                        color={currentContext.theme.color}
                      />
                    )}
                  </td>
                )}

                {Object.entries(row).map((item, index) =>
                  localRows.headers.map(
                    (x) =>
                      x.value === item[0] &&
                      x.status === true && (
                        <td key={index}>
                          {typeof item[1] === "boolean" ? (
                            item[1] === true ? (
                              <FaCheck />
                            ) : (
                              <FaTimes />
                            )
                          ) : (
                            item[1]
                          )}
                        </td>
                      )
                  )
                )}
                <td className="table-body-item-more">
                  {localRows.options.length > 0 && (
                    <FiMoreVertical
                      color="#272d3b"
                      onClick={(event) => {
                        event.stopPropagation();
                        moreOptionsHandler(row);
                      }}
                    />
                  )}

                  {row.moreOption === true && (
                    <OutSideDetector
                      className="table-item-more-options"
                      object={row}
                      detect={moreOptionsHandler}
                      style={{ background: currentContext.theme.secondColor }}
                    >
                      <ul
                        className="item-more-options-container"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        {localRows.options &&
                          localRows.options.map((option, index) => (
                            <li className="item-more-option" key={index}>
                              <LabeledButton
                                disabled={!option.active}
                                color="#2699fb"
                                title={option.title}
                                icon={option.icon}
                                onClick={() => {
                                  moreOptionsHandler(row);
                                  optionChangeHandler(option.name);
                                }}
                              />
                            </li>
                          ))}
                      </ul>
                    </OutSideDetector>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
Table.defaultProps = {
  tags: [],
};
export default Table;
