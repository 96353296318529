import Device from "../../../../ui/Carts/Device/Device";
import "./RecentDevices.sass";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getList, list } from "src/components/Dashboard/ApplicationsArea/DeviceApp/OnboardDeviceApp/OnboardDeviceAppSlice";
import { useEffect } from "react";
import { useFrontendHub } from "../FrontendHubProvider/FrontendHubProvider";
import { HubEventName, HubName } from "src/helper/HubConnection";
import { useAccount } from "src/helper/AccountStateProvider";
import { useRef } from "react";
import spinner from "src/assets/img/spinner.svg";

function RecentDevices() {
  const dispatch = useDispatch();
  const [devices, setDevices] = useState([]);
  const serverMessageHandlerIds = useRef([]);
  const systemMessageHandlerIds = useRef([]);
  const api = useSelector((state) => state.api);
  const onboard = useSelector((state) => state.onboard);
  const { account } = useAccount();
  const {
    hub: frontendConnectionManager,
    connected: frontendHubConnected,
    checkConnectivity,
  } = useFrontendHub();

  const changeStatus = (id, status) => {
    setDevices(curr => {
      const currDevices = [...curr];
      const targetIndex = currDevices.findIndex((d) => d.id === id);

      if (targetIndex !== -1)
        currDevices[targetIndex].connected = status;

      return currDevices;
    });
  }

  useEffect(() => {
    if (api.token !== undefined && onboard?.list?.status !== "success")
      dispatch(getList());
    return () => {
      dispatch(list({ status: "" }));
    };
  }, [api.token]);

  useEffect(() => {
    if (onboard.list.data !== undefined) {
      setDevices(onboard.list.data.drones.map(item => ({
        id: item.id,
        name: item.deviceName || item.model,
        serialNumber: item.serialNumber,
        connected: frontendHubConnected ? false : -1,
      }))
      );

      if (frontendHubConnected) {
        onboard.list.data.drones.forEach((item) => checkConnectivity(account.user.localAccountId, item.id));
      }
    }
  }, [onboard.list, frontendHubConnected]);

  const onboardHandlers = devices.map((item) => [
    {
      identity: item.id,
      name: HubEventName.CONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        changeStatus(data?.userId, false);
        const drone = devices.find((d) => d.id === data?.userId);
        if (drone) {
          checkConnectivity(account.user.localAccountId, data?.userId);
        }
      },
    },
    {
      identity: item.id,
      name: HubEventName.CONNECTIVITY_STATUS,
      platform: HubName.ONBOARD,
      handler: (data) => {
        changeStatus(data?.userId, true);
      },
    },
    {
      identity: item.id,
      name: HubEventName.DISCONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        changeStatus(data?.userId, false);
      },
    },
  ]);

  const frontendHandlers = [
    {
      identity: account?.user?.localAccountId,
      name: HubEventName.DISCONNECTED,
      handler: () => {
        setDevices(curr => curr.map(item => ({ ...item, connected: -1 })));
      },
    },
    {
      identity: account?.user?.localAccountId,
      name: HubEventName.CONNECTED,
      handler: () => {
        devices?.forEach((item) => {
          checkConnectivity(account.user.localAccountId, item.id);
        });
      },
    },
  ];

  useEffect(() => {
    frontendConnectionManager.unsubscribeSystemMessages(
      systemMessageHandlerIds.current
    );

    systemMessageHandlerIds.current =
      frontendConnectionManager?.subscribeSystemMessages(
        frontendHandlers,
        "recentDevices"
      );

    frontendConnectionManager.unsubscribeServerMessages(
      serverMessageHandlerIds.current
    );

    serverMessageHandlerIds.current =
      frontendConnectionManager.subscribeServerMessages(
        onboardHandlers.flat(),
        "recentDevices"
      );
  });

  useEffect(() => {
    return () => {
      frontendConnectionManager?.unsubscribeServerMessages(
        serverMessageHandlerIds.current
      );

      frontendConnectionManager?.unsubscribeSystemMessages(
        systemMessageHandlerIds.current
      );
    };
  }, []);

  return (
    <div className="recent-devices">
      {!onboard?.list?.status && (
        <div className="section-loading">
          <img src={spinner} alt="loading" />
          <div className="title">Loading Devices...</div>
        </div>
      )}

      <div className="recent-devices-list">
        {devices.map(item => (
          <Device key={item.id} device={item} />
        ))}
      </div>
    </div>
  );
}

export default RecentDevices;
