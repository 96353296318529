import './EditCustomerOptions.sass'

import React, { useEffect, useMemo } from 'react'
import { usePrivileges } from 'src/helper/AccountStateProvider';
import Tabs from 'src/ui/Tabs/Tabs';
import EditCustomer from '../EditCustomer/EditCustomer';
import CustomerDetails from '../CustomerDetails/CustomerDetails';

function EditCustomerOptions({ onOpen, onConfirm, defaultIndex }) {
  const { privileges } = usePrivileges();
  const permissions = useMemo(() => privileges?.admins.find(item => item.id === "customers")?.privileges, [privileges]);
  const hasPermission = (id) => permissions?.includes(id);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className="edit-customer-options">
      <Tabs
        defaultIndex={defaultIndex}
        items={[
          {
            title: "Edit Customer",
            content: <EditCustomer onConfirm={onConfirm} />,
            hidden: !hasPermission('edit_customer')
          },
          {
            title: "Optional Details",
            content: <CustomerDetails onConfirm={onConfirm} />,
            hidden: !hasPermission('customer_details')
          },
        ]}
      />
    </div>
  )
}

export default EditCustomerOptions