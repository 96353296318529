import { useEffect, useState } from "react";
import "./RadioButtonGroup.sass";

function RadioButtonGroup({
  group,
  titles,
  name,
  onChange,
  className,
  selected,
}) {
  const [select, setSelect] = useState(selected);
  useEffect(() => {
    setSelect(selected);
  }, [selected]);
  return (
    <ul className={className}>
      {group.map((item, index) => (
        <li key={index} className="radio-button-item">
            <input
              checked={select === item}
              className="radio-button-item-input"
              type="radio"
              id={item}
              name={name}
              value={item}
              onChange={onChange}
            />
          <label htmlFor={item}>{titles[index]}</label>
        </li>
      ))}
    </ul>
  );
}

export default RadioButtonGroup;
