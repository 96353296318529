import { MdMenu } from "react-icons/md";
import "./AdminNavbarMenu.sass";
function AdminNavbarMenu({ title, color, onClick }) {
  return (
    <div className="admin-navbar-menu" onClick={onClick}>
      <div className="admin-navbar-menu-container">
        <MdMenu color={color} />
        <span style={{ color: color }}>{title}</span>
      </div>
    </div>
  );
}

export default AdminNavbarMenu;
