import "./AdminOperationManager.sass";
import { GrClose } from "react-icons/gr";
import SlideLeft from "../../../ui/Animation/SlideLeft/SlideLeft";
import PropTypes from "prop-types";
import OperationStep from "../../../ui/OperationStep/OperationStep";
import { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "../../../helper/ThemeProvider";

function AdminOperationManager({
  selectedFeature,
  options,
  isOpen,
  close,
  toggle,
  onStepChanged,
}) {
  const [steps, setSteps] = useState(
    selectedFeature?.steps && [...selectedFeature.steps]
  );
  const [feature, setFeature] = useState(selectedFeature);
  const operation = useSelector((state) => state.operation);
  const operationStepContentRef = createRef();
  const { currentContext } = useTheme();

  useEffect(() => {
    console.log('feature-steps', selectedFeature.steps);
    setFeature(selectedFeature);
  }, [selectedFeature]);

  useEffect(() => {
    if (selectedFeature?.steps !== undefined)
      setSteps([...selectedFeature.steps]);
  }, [selectedFeature?.steps]);

  useEffect(() => {
    if (operation.step !== "" && steps !== undefined) {
      setSteps([
        ...steps.map((step) => ({
          ...step,
          active: step.name === operation.step,
          locked: step.name === operation.step ? false : selectedFeature.steps?.find(i => i.name === step.name)?.locked,
        })),
      ]);
    }
  }, [operation]);

  const stepActivator = (name) => {
    if(steps?.find(i => i.name === name)?.locked) return false;
    steps.map((step) => (step.active = step.name === name));
    setSteps([...steps]);
    if (onStepChanged !== undefined) onStepChanged(name);
  };

  return (
    <SlideLeft
      className="admin-operation-manager"
      isOpen={isOpen}
      toggle={toggle}
      style={{ background: currentContext.theme.secondColor }}
    >
      {(feature?.title || feature?.description) ? (
        <div className="operation-manager-header">
          <div className="operation-manager-head">
            <div className="operation-manager-info">
              <span
                className="operation-manager-icon"
                style={{ color: feature.color }}
              >
                {feature.icon}
              </span>
              <span className="operation-manager-title">{feature.title}</span>
            </div>

            <GrClose
              color="#455060"
              className="operation-manager-close"
              onClick={close}
            />
          </div>

          <p className="operation-manager-description">{feature.description}</p>
        </div>
      ) : (
        <GrClose
          color="#455060"
          className="operation-manager-close"
          onClick={close}
        />
      )}
      
      <div className="operation-manager-options">{options}</div>
      <ul className="operation-manager-steps" ref={operationStepContentRef}>
        {steps &&
          steps.map(
            (step, index) =>
              step.visible && (
                <li key={index} className={
                  "operation-manager-step " +
                  (step.active ? "operation-manager-step-active" : "") +
                  (step.fullHeight
                    ? " operation-manager-step-full-height"
                    : "")
                }>
                  <OperationStep
                    isActive={step.active}
                    name={step.name}
                    toggleActive={stepActivator}
                    title={step.title}
                    isLocked={step.locked}
                    tags={step.tags}
                    isClosable={step.closable}
                    noBorder={step.noBorder}
                    fullHeight={step.fullHeight}
                  >
                    {step.activity}
                  </OperationStep>
                </li>
              )
          )}
      </ul>
    </SlideLeft>
  );
}

AdminOperationManager.propTypes = {
  feature: PropTypes.object.isRequired,
};
AdminOperationManager.defaultProps = {
  feature: { title: "", description: "", color: "#2699fb" },
};

export default AdminOperationManager;
