import "./UploadFileToContainer.sass";
import { StorageContainerIcon } from "../../../../../../../ui/Icons/Icons";
import LabeledButton from "../../../../../../../ui/LabeledButton/LabeledButton";
import Button from "../../../../../../../ui/Button/Button";
import { useTheme } from "../../../../../../../helper/ThemeProvider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  blobs,
  getBlobs,
  getList,
} from "../../../../StorageApp/StorageContainerApp/StorageContainerAppSlice";
import { FcFolder } from "react-icons/fc";
import { MdCreateNewFolder } from "react-icons/md";
import EditableInputTextButton from "../../../../../../../ui/EditableInputTextButton/EditableInputTextButton";
import { RiFolderUnknowFill } from "react-icons/ri";
import { setOperation } from "../../../../../../AdminCenter/AdminOperationManager/OperationStepSlice";
import { useDeviceId } from "../../../../../../../helper/SerialNumberProvider";

function UploadFileToContainer({ onUpload }) {
  const { currentContext } = useTheme();
  const container = useSelector((state) => state.containerApp);
  const dispatch = useDispatch();
  const [containers, setContainers] = useState([]);
  const [prefixes, setPrefixes] = useState([{ path: "", value: "" }]);
  const [currentContainer, setCurrentContainer] = useState();
  const api = useSelector((state) => state.api);
  const [selectedPath, setSelectedPath] = useState("");
  const [toggleCreateFolder, setToggleCreateFolder] = useState(false);
  const files = useSelector((state) => state.onboard.selectedFiles);
  const { deviceId } = useDeviceId();

  useEffect(() => {
    if (api.token) {
      dispatch(setOperation("container_upload"));
      dispatch(getList());
    }
    return () => {
      setContainers([]);
    };
  }, [api]);

  useEffect(() => {
    if (container.list.data !== undefined) {
      setSelectedPath("");
      setContainers([
        ...container.list.data.containers.map((container) => ({
          id: container.id,
          name: container.name,
          groups: container.groups,
          storageId: container.storageId,
          description: container.description,
          active: false,
          component: (
            <LabeledButton
              icon={<StorageContainerIcon size="1.3" />}
              title={container.name}
              onClick={() => {
                toggleContainer(container);
              }}
            />
          ),
        })),
      ]);
    }
  }, [container.list]);

  const toggleContainer = (container) => {
    setPrefixes([{ path: container?.name, value: "" }]);
    setCurrentContainer({ ...container });
  };
  const toggleBlob = (blob) => {
    if (currentContainer !== undefined) {
      if (!blob.back)
        setPrefixes([
          ...prefixes,
          { path: `/${blob.folder}`, value: blob.prefix },
        ]);
    }
  };
  const filterIndexes = (index) => {
    const allPrefixes = [...prefixes];
    allPrefixes.splice(index);
    setPrefixes([...allPrefixes]);
  };
  useEffect(() => {
    if (currentContainer !== undefined) {
      if (prefixes.length > 0) {
        dispatch(
          getBlobs(
            currentContainer?.storageId,
            currentContainer?.id,
            prefixes[prefixes.length - 1]?.value
          )
        );
      } else {
        dispatch(getList());
      }
    }
  }, [currentContainer, prefixes]);

  const createFolder = () => {
    setToggleCreateFolder(true);
  };

  const createFolderSubmit = (value) => {
    if (value !== "")
      setContainers([
        ...containers,
        {
          component: (
            <LabeledButton
              color="#878787"
              textColor="#455060"
              icon={<RiFolderUnknowFill size="20" color="rgb(255, 202, 40)" />}
              title={value}
              onClick={() => {
                toggleBlob({
                  folder: value,
                  prefix: value,
                });
              }}
            />
          ),
        },
      ]);
    setToggleCreateFolder(false);
  };

  useEffect(() => {
    if (container?.blobs.status === "success") {
      if (prefixes.length > 0) {
        setSelectedPath(
          prefixes
            ?.map((item) => item.path)
            ?.reduce((result, item) => result + item),
          ""
        );
      } else {
        setSelectedPath("");
      }
      dispatch(blobs({ status: "" }));
    }
    if (container?.blobs?.data?.blobs !== undefined) {
      setContainers([
        {
          component: (
            <LabeledButton
              color="#878787"
              textColor="#455060"
              icon={<FcFolder size="20" />}
              title={"[..]"}
              onClick={() => {
                filterIndexes(prefixes.length - 1);
              }}
            />
          ),
          back: true,
        },
        ...container?.blobs?.data?.blobs
          .filter((blob) => blob.isPrefix)
          .map((item) => ({
            component: (
              <LabeledButton
                color="#878787"
                textColor="#455060"
                icon={<FcFolder size="20" />}
                title={item.folder}
                onClick={() => {
                  toggleBlob(item);
                }}
              />
            ),
            folder: item.folder,
            type: "Folder",
            prefix: item.prefix,
          })),
      ]);
    }
  }, [container?.blobs]);

  const uploadToStorage = () => {
    onUpload(
      {
        storageId: currentContainer.storageId,
        id: currentContainer.id,
        path: selectedPath,
        fileList: files.map((file) => file.name),
      },
      "storage",
      deviceId
    );
  };

  return (
    <div className="upload-files-to-storage-container">
      <ul className="upload-files-to-container-path">
        <label htmlFor="">Location :</label>
        {prefixes.map((prefix, index) => (
          <li
            key={index}
            style={{
              cursor: index < prefixes.length - 1 ? "pointer" : "initial",
              color:
                index < prefixes.length - 1
                  ? currentContext.theme.color
                  : "black",
            }}
            onClick={() => {
              if (index < prefixes.length - 1) filterIndexes(index + 1);
            }}
          >
            {prefix.path}
          </li>
        ))}
      </ul>
      <div className="upload-files-to-container-destination">
        <ul className="upload-files-to-container-destination-folder">
          {containers.map((container, index) => (
            <li key={index}>{container.component}</li>
          ))}
          {selectedPath !== "" && (
            <li>
              {toggleCreateFolder ? (
                <EditableInputTextButton
                  input={{ placeholder: "Create New Folder" }}
                  submit={{ title: "OK" }}
                  onSubmit={(value) => {
                    createFolderSubmit(value);
                  }}
                />
              ) : (
                <LabeledButton
                  color={currentContext.theme.color}
                  textColor={currentContext.theme.color}
                  icon={<MdCreateNewFolder size="20" />}
                  title={"New Folder"}
                  onClick={createFolder}
                />
              )}
            </li>
          )}
        </ul>
        <Button
          value="Upload"
          disabled={selectedPath === ""}
          onClick={uploadToStorage}
          width="33%"
        />
      </div>
    </div>
  );
}
UploadFileToContainer.defaultProps = {
  onUpload: () => {},
};

export default UploadFileToContainer;
