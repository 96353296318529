import classNames from 'classnames';
import { HmsErrors } from 'src/helper/hmsErrors';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import './FlightStatusMessage.sass'

function FlightStatusMessage() {
  const flightStatus = useSelector(state => state.flight.droneMode?.flightStatus);
  const errors = useSelector((state) => state.flight.hms.errors?.filter(getMessageString));
  const newErrorTimestamp = useSelector((state) => state.flight.hms.newErrorTimestamp);
  const notification = useSelector((state) => state.flight.notification);
  const lastErrorMessage = useMemo(() => !!errors?.length && getMessageString(errors[0]), [errors]);
  const [showList, setShowList] = useState(false);

  const currTime = (new Date()).getTime() / 1000;
  const hasNewError = newErrorTimestamp && currTime - newErrorTimestamp < 5;
  const colors = {
    progress: "gray",
    normal: "#57e424",
    warning: "orange",
    error: "red",
  };

  function getMessageString(error) {
    const errorCodeHex = '0x' + error.errorCode?.toString(16);
    const errorInfo = HmsErrors.find(item => item[0] === errorCodeHex);

    if (errorInfo) {
      // use in-air message if available
      const errorMessage = (flightStatus === 2 && errorInfo[2]) ? errorInfo[2] : errorInfo[1];
      return errorMessage.replace('%index', parseInt(error.componentIndex) + 1);
    }
  }

  function handleMessageBarClick() {
    setShowList(curr => !curr);
  }

  return (
    <div className="flight-status-message">
      {lastErrorMessage && (
        <div className={classNames("counter", { "new-error": hasNewError })}>{errors?.length}</div>
      )}
      <div
        className={classNames("message", { 'marquee': !!lastErrorMessage })}
        style={{ background: lastErrorMessage ? '' : colors[notification.status] || 'gray' }}
        onClick={handleMessageBarClick}
      >
        <div className="message-text-wrapper">
          <div
            className="message-text"
            style={{
              animation: lastErrorMessage ? '' : 'none',
              animationDuration: (lastErrorMessage?.length * 200) + 'ms',
            }}
          >
            {lastErrorMessage || notification?.message}
          </div>
        </div>
      </div>
      {showList && lastErrorMessage && (
        <div className="message-list">
          <div className="message-list-content">
            {errors?.map((item, index) => (
              <div className="message-item" key={item.errorCode.toString() + index.toString()}>{getMessageString(item)}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default FlightStatusMessage