import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { MissionPendingRequestStatus, MissionPendingRequestType, MissionStatus } from './missionConstants';
import { getMissionInfo } from './missionUtils';
import { removeMission, removeMissionPendingRequest, setMissionInfo, setMissionPendingRequest } from '../MissionServiceSlice';
import { getDeviceConnectionGroups, getDeviceInfo, getDeviceTelemetries, getSubDeviceTelemetries } from 'src/services/device/common/deviceUtils';
import { getCoordinatesDistanceAndTime } from 'src/helper/waypointMarkupUtils';
import { addOrUpdatePath, removePath } from 'src/components/DeviceMap/DeviceMapSlice';
import { DeviceMapPathType } from 'src/components/DeviceMap/DeviceMap';
import { dockConnectionManager } from 'src/helper/HubConnectionManager';
import { djiCloudCustomMethod, djiCloudMethod } from 'src/services/common/constants';
import useDialog from 'src/helper/useDialog';
import useCurrentUserId from 'src/helper/useCurrentUserId';

export const flyTaskStatus = {
  TASK_READY: 'task_ready',
  WAYLINE_PROGRESS: 'wayline_progress',
  WAYLINE_OK: 'wayline_ok',
  TASK_FINISH: 'task_finish',
  WAYLINE_CANCEL: 'wayline_cancel',
  WAYLINE_FAILED: 'wayline_failed',
}

export default function useCloudMissionObserver() {
  const messageHandlerIds = useRef([]);
  const dispatch = useDispatch();
  const dialog = useDialog();
  const currUserId = useCurrentUserId();

  const generateMissionData = (deviceId, missionId, data) => {
    const device = getDeviceInfo(deviceId);
    const mission = getMissionInfo(item => item.id === missionId);

    const {
      distance: newDistance, 
      time: newTime
    } = data.remaining_distance && data.remaining_time ? {
      distance: data.remaining_distance,
      time: data.remaining_time,
    } : data.planned_path_points?.length || mission?.waypoints?.length ? getCoordinatesDistanceAndTime(
      mission.waypoints?.length ?
      mission.waypoints :
      data.planned_path_points
    ) : {};
    
    const dynamicWaypoints = data.planned_path_points?.length ? data.planned_path_points?.map(item => ({
      latitude: item.lat || item.latitude,
      longitude: item.long || item.longitude,
      altitude: item.altitude,
      speed: item.speed || data.detail?.speed,
      actions: item.actions || {},
    })) : [];

    const waypoints = mission?.waypoints || [
      dynamicWaypoints?.[0],
      dynamicWaypoints?.[dynamicWaypoints?.length - 1],
    ];

    return ({
      id: mission?.id,
      remainingDistance: data.remaining_distance,
      remainingTime: data.remaining_time,
      waypoints,
      dynamicWaypoints,
      ...(mission ? {} : {
        deviceId: deviceId,
        flightId: null,
        name: "External mission " + new Date().toUTCString(),
        selfInitiated: false,
        distance: newDistance,
        duration: newTime,
        altitude: data.detail?.altitude,
        speed: data.detail?.speed,
        startLatitude: data.detail?.points?.[0]?.lat,
        startLongitude: data.detail?.points?.[0]?.long,
        serialNumber: device?.serialNumber,
        finishAction: 'hover',
      })
    });
  }

  const setMapMissionPath = (deviceId, points) => {
    const mapCoords = points?.map(item => ([ item.long || item.longitude, item.lat || item.latitude ]));
    if(!Array.isArray(mapCoords) || mapCoords.length === 0)
      return;

    dispatch(addOrUpdatePath({
      deviceId,
      type: DeviceMapPathType.ACTIVE_MISSION_ROUTE,
      coords: mapCoords
    }));
  }

  const removeMapMissionPath = (deviceId) => {
    dispatch(removePath({
      deviceId,
      type: DeviceMapPathType.ACTIVE_MISSION_ROUTE
    }));
  }

  const terminateRemoteMission = (deviceId) => {
    const deviceInfo = getDeviceInfo(deviceId);
    const groups = getDeviceConnectionGroups(deviceId);

    dockConnectionManager?.sendToDjiCloudGroup(deviceId, groups?.send?.commands, {
      method: djiCloudCustomMethod.mission.MISSION_TERMINATE,
      gateway: deviceInfo?.serialNumber,
      data: {},
    });
  }

  useEffect(() => {
    messageHandlerIds.current = dockConnectionManager.subscribeGroupMessages([
      {
        identity: 'cloudMissionMessages/*',
        name: ['services_reply', 'events'],
        handler: (message, meta) => {
          const deviceId = meta?.fromUserId?.split('_')[0];
          const deviceInfo = getDeviceInfo(deviceId);
          const mission = getMissionInfo(item => item.deviceId === deviceId);
          const data = message.data;

          if(!mission)
            return;

          const newMissionData = generateMissionData(deviceId, mission?.id, data);
          let newMissionStatus, newMissionDescription;
  
          switch(message.method) {
            case djiCloudMethod.liveFlight.TAKEOFF_TO_POINT:
              if(data.result === 0) {
                newMissionDescription = 'Waiting for takeoff...';

                dispatch(setMissionPendingRequest({
                  missionId: mission?.id,
                  requestType: MissionPendingRequestType.CLOUD_TAKEOFF_TO_POINT,
                  requestStatus: MissionPendingRequestStatus.PENDING,
                }));
              }
              else {
                newMissionStatus = MissionStatus.FAILED;
                newMissionDescription = 'Takeoff failed.' + ' ' + data.result_message;
                terminateRemoteMission();

                dialog.fire({
                  title: <b>Drone Takeoff Failed</b>,
                  text: "Drone takeoff command failed due to negative remote response. " + data.result_message,
                  icon: 'error',
                  showConfirmButton: true,
                  confirmButtonText: 'OK',
                });

                dispatch(removeMission({id: mission?.id}));
                terminateRemoteMission(deviceId);
              }

              dispatch(removeMissionPendingRequest({
                missionId: mission?.id,
                requestType: MissionPendingRequestType.CLOUD_TAKEOFF_PREPARATION,
              }));

              break;
  
            case djiCloudMethod.liveFlight.FLY_TO_POINT:
              if(data.result === 0) {
                newMissionDescription = 'Waiting for fly to next point...';
              }
              else {
                newMissionStatus = MissionStatus.IDLE;
                newMissionDescription = 'Flying to next point failed.' + ' ' + data.result_message;
              }
              break;
  
            case djiCloudMethod.liveFlight.TAKEOFF_TO_POINT_PROGRESS:
            case djiCloudMethod.liveFlight.FLY_TO_POINT_PROGRESS:
              if(data.result === 0) {
                if(data.status === flyTaskStatus.TASK_READY) {
                  newMissionStatus = MissionStatus.EXECUTING;
                  newMissionDescription = 'Flight task ready, starting now...';
                }
                
                if(data.status === flyTaskStatus.WAYLINE_PROGRESS) {
                  newMissionStatus = MissionStatus.EXECUTING;
                  newMissionDescription = 'Flying to point...';
                  setMapMissionPath(deviceId, newMissionData.dynamicWaypoints || newMissionData.waypoints);
                }
  
                if(data.status === flyTaskStatus.WAYLINE_OK) {
                  newMissionStatus = MissionStatus.IDLE;
                  newMissionDescription = 'Wayline complete.';
                  removeMapMissionPath(deviceId);
                }

                if(data.status === flyTaskStatus.TASK_FINISH) {
                  if(message.method === djiCloudMethod.liveFlight.TAKEOFF_TO_POINT_PROGRESS) {
                    newMissionStatus = MissionStatus.COMPLETED;
                    newMissionDescription = 'Drone returned to the dock.';
                  }
                  else {
                    newMissionStatus = MissionStatus.IDLE;
                    newMissionDescription = 'Flight task completed.';
                  }
                  
                  removeMapMissionPath(deviceId);
                }

                if(data.status === flyTaskStatus.WAYLINE_CANCEL) {
                  newMissionStatus = MissionStatus.IDLE;
                  newMissionDescription = 'Wayline cancelled.';
                  removeMapMissionPath(deviceId);
                }
              }
              else {
                newMissionStatus = MissionStatus.IDLE;
                newMissionDescription = 'Mission progress failed.' + ' ' + data.result_message;
                removeMapMissionPath(deviceId);

                if(
                  message.method === djiCloudMethod.liveFlight.TAKEOFF_TO_POINT_PROGRESS && 
                  getDeviceTelemetries(deviceId)?.drone_in_dock
                ) {
                  if(mission?.controllerId === currUserId) {
                    dialog.fire({
                      title: <b>Drone Takeoff Failed</b>,
                      text: "Drone takeoff failed start. " + data.result_message,
                      icon: 'error',
                      showConfirmButton: true,
                      confirmButtonText: 'OK',
                    });
                  }

                  dispatch(removeMission({id: mission?.id}));
                  terminateRemoteMission(deviceId);
                }
              }

              dispatch(removeMissionPendingRequest({
                missionId: mission?.id,
                requestType: [
                  MissionPendingRequestType.CLOUD_FLY_TO_POINT,
                  MissionPendingRequestType.CLOUD_TAKEOFF_TO_POINT,
                ],
              }));

              break;
            
            case djiCloudMethod.liveFlight.FLY_TO_POINT_STOP:
              if(data.result === 0) {
                newMissionStatus = MissionStatus.IDLE;
                newMissionDescription = 'Flight stopped.';
                removeMapMissionPath(deviceId);
              }
              else {
                newMissionStatus = MissionStatus.IDLE;
                newMissionDescription = 'Flight stop failed.' + ' ' + data.result_message;
              }
              break;
  
            case djiCloudMethod.wayline.RETURN_HOME:
              if(data.result === 0) {
                newMissionStatus = MissionStatus.RETURNING;
                newMissionDescription = 'Starting return home...';
                removeMapMissionPath(deviceId);
              }
              else {
                newMissionStatus = MissionStatus.IDLE;
                newMissionDescription = 'Returning home failed.' + ' ' + data.result_message;
              }
              break;
  
            case djiCloudMethod.wayline.RETURN_HOME_CANCEL:
              if(data.result === 0) {
                newMissionStatus = MissionStatus.IDLE;
                newMissionDescription = 'Returning home cancelled.';
                removeMapMissionPath(deviceId);
              }
              else {
                newMissionStatus = MissionStatus.IDLE;
                newMissionDescription = 'Returning home cancelation failed.' + ' ' + data.result_message;
              }
              break;
  
            case djiCloudMethod.wayline.RETURN_HOME_INFO:
              const modeCode = getSubDeviceTelemetries(deviceId)?.modeCode;

              if(mission.status === MissionStatus.RETURNING && modeCode !== 0) { // TODO: check for data.flight_id
                newMissionDescription = 'Returning home...';
                setMapMissionPath(deviceId, data.planned_path_points);
              }
              else if(mission.status === MissionStatus.RETURNING && modeCode === 0) {
                newMissionDescription = 'Drone returned to the dock.';
                newMissionStatus = MissionStatus.COMPLETED;
                removeMapMissionPath(deviceId);
              }
              else if(modeCode === 9) {
                newMissionDescription = 'Auto returning home...';
                setMapMissionPath(deviceId, data.planned_path_points);
              }
              else if(modeCode === 12) {
                newMissionDescription = 'Auto landing...';
                setMapMissionPath(deviceId, data.planned_path_points);
              }
              break;

            case djiCloudMethod.liveFlight.TRACK:
              if(data.list?.length) {
                data.list.forEach(item => {
                  if(item.type === 'flighttask_execute' && item.sn === deviceInfo?.serialNumber) {
                    if(mission.status === MissionStatus.RETURNING) {
                      newMissionDescription = 'Drone returned to dock.';
                      newMissionStatus = MissionStatus.COMPLETED;
                      removeMapMissionPath(deviceId);
                    }
                  }
                });
              }
              break;
          }

          if(!newMissionStatus && !newMissionDescription) 
            return;
  
          console.log('🚩🚩', {
            newMissionStatus,
            newMissionDescription,
            newMissionData,
          });

          if(newMissionStatus === MissionStatus.COMPLETED) {
            // terminateRemoteMission(deviceId);
            dispatch(removeMission(newMissionData));
          } else {
            dispatch(setMissionInfo({
              ...newMissionData,
              ...(newMissionStatus ? {status: newMissionStatus} : {}),
              ...(newMissionDescription ? {statusDescription: newMissionDescription} : {}),
            }));
          }
        },
      },
    ], 'cloud-mission-observer');

    return () => {
      dockConnectionManager.unsubscribeGroupMessages(messageHandlerIds.current);
    }
  }, []);

  return {};
}
