import './DockPairedDroneViewer.sass';
import CollapsablePanel from '../CollapsablePanel/CollapsablePanel';
import { RiFoggyLine, RiRainyLine } from 'react-icons/ri';
import { MdHeight, MdOutlineSatelliteAlt, MdOutlineUploadFile } from 'react-icons/md';
import { TbNetwork, TbTemperature } from 'react-icons/tb';
import { FiBatteryCharging, FiWind } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'src/helper/AccountStateProvider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFrontendHub } from 'src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider';
import DockHubProvider, { useDockHub } from 'src/components/Dashboard/Components/DockHubProvider/DockHubProvider';
import { HubEventName, HubName } from 'src/helper/HubConnection';
import { dockConnectionManager, onboardConnectionManager } from 'src/helper/HubConnectionManager';
import { BsCloud, BsCloudRain, BsLightningCharge } from 'react-icons/bs';
import { ImPowerCord } from 'react-icons/im';
import Button from 'src/hci/common/Button/Button';
import { StageElement } from '../MissionTracking';

function DockPairedDroneViewer({ dockData, onSwap }) {
  const { account } = useAccount();
  const dispatch = useDispatch();
  const deviceId = useMemo(() => dockData?.id, [dockData]);
  const serverMessageHandlerIds = useRef([]);
  const groupMessageHandlerIds = useRef([]);
  const [wsConnected, setWsConnected] = useState(false);
  const { hub: frontendHub, checkTelemetries } = useFrontendHub();
  const videoStreamConfig = useSelector((state) => state.flight?.videoStreaming);
  const lastRequest = useSelector((state) => state.dock?.requestsTelemetry?.[dockData?.serialNumber]);
  const permissionGroups = useSelector((state) => state.websocket.dockPermissionGroups[deviceId]);
  const { hub: dockHub, groups: dockGroups, deviceId: dockId, connectionId: dockConnectionId, joinCompleted: dockJoinCompleted } = useDockHub();
  const lastOsd = useSelector((state) => state.dock?.osdTelemetry?.[dockData?.serialNumber]);

  const handleSwap = () => {
    onSwap?.(StageElement.DRONE);
  }

  const telemetries = [
    {
      name: 'Altitude',
      value: `n/a`,
      icon: <MdHeight />
    },
    {
      name: 'Battery',
      value: `n/a`,
      icon: <FiBatteryCharging />
    },
    {
      name: 'Satellites',
      value: `n/a`,
      icon: <MdOutlineSatelliteAlt />
    }
  ];

  const handleConnectClick = () => {
    const subDeviceSerial = lastOsd?.sub_device?.device_sn;
    console.log(subDeviceSerial)
    if(!dockJoinCompleted || !subDeviceSerial) return;

    dockHub?.sendEvent(
      dockId,
      'bind-drone-to-dock',
      {
        "action": "bindDroneToDock",
        "userId": dockId,
        "platform": "frontend",
        "data": {
            "userId": "3273df1b-9d8d-4203-b1fa-36f1925ba563",
            "deviceId": dockId,
            "corelationId": dockConnectionId,
            "message": subDeviceSerial
        }
      }
    );
  }

  return (
    <div className="dock-paired-drone-viewer">
      <CollapsablePanel title="Paired Drone" telemetries={telemetries} onSwap={handleSwap}>
        <div className="stream-container">
          <video src="" />
          <div className="connection-init">
            <div className="description">Use the following button to connect the paired drone.</div>
            <Button className="connect-button" onClick={handleConnectClick}>
              <ImPowerCord className="icon" />
              <span>Connect</span>
            </Button>
          </div>
        </div>
      </CollapsablePanel>
    </div>
  )
}

export default DockPairedDroneViewer