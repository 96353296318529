import { useHistory } from "react-router-dom";
import "./ViewPortResource.sass";

function ViewPortResource({ color, message, closable }) {
  const history = useHistory();
  return (
    <div className="view-port-resource">
      <span>
        <svg className="rotate-icon" width="100%" viewBox="0 0 50 50">
          <path
            fill={color}
            d="M37.833,14.879l-3.708,3.707c-2.012-2.013-4.792-3.257-7.863-3.257c-6.142,0-11.121,4.981-11.121,11.124
      c0,0.001,0-0.001,0,0h4.32L12.56,33.35l-6.893-6.898h4.231c0-0.001,0,0,0,0c0-9.038,7.326-16.366,16.364-16.366
      C30.78,10.086,34.871,11.917,37.833,14.879 M46.667,25.881l-6.893-6.898l-6.901,6.898h4.32c0,0.001,0-0.002,0,0
      c0,6.142-4.979,11.123-11.121,11.123c-3.071,0-5.851-1.245-7.864-3.257l-3.707,3.707c2.961,2.961,7.052,4.793,11.571,4.793
      c9.038,0,16.364-7.329,16.364-16.366c0,0,0,0,0,0H46.667L46.667,25.881z"
          />
        </svg>
        <svg className="device-icon" width="100%" viewBox="0 0 97 195">
          <path
            fill={color}
            d="M81.277,0H14.91C7.331,0,1.186,6.159,1.186,13.756v2.594H0v8.128h1.186v11.63H0v5.627h1.186v11.067H0v5.627
      h1.186v122.812C1.186,188.841,7.331,195,14.91,195h66.367C88.856,195,95,188.841,95,181.244V13.756C95,6.159,88.856,0,81.277,0
      M41.107,15.392h13.973c0.78,0,1.414,0.634,1.414,1.417c0,0.783-0.633,1.418-1.414,1.418H41.107c-0.782,0-1.415-0.635-1.415-1.418
      C39.693,16.026,40.326,15.392,41.107,15.392 M32.437,14.949c1.011,0,1.831,0.821,1.831,1.834c0,1.013-0.819,1.834-1.831,1.834
      c-1.01,0-1.83-0.822-1.83-1.834C30.607,15.771,31.427,14.949,32.437,14.949 M48.093,184.616c-3.663,0-6.631-2.976-6.631-6.646
      c0-3.672,2.968-6.647,6.631-6.647c3.663,0,6.631,2.976,6.631,6.647C54.725,181.64,51.756,184.616,48.093,184.616 M88.101,162.545
      H8.088V32.232h80.013V162.545z"
          />
        </svg>
      </span>
      <span>
        <small style={{ color }}> {message}</small>
        {closable && (
          <button
            style={{ color }}
            onClick={() => {
              history.goBack();
            }}
          >
            Go Back
          </button>
        )}
      </span>
    </div>
  );
}

export default ViewPortResource;
