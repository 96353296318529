import useSubDevice from './useSubDevice';
import { DjiDockDroneRecordingState } from './deviceConstants';
import { useEffect, useRef, useState } from 'react';
import * as captureUtils from './deviceCaptureUtils';

const useDeviceCapture = (deviceId) => {
  const { telemetries: subDeviceTelemetries } = useSubDevice(deviceId);
  const cameraMode = subDeviceTelemetries?.cameras?.[0]?.camera_mode;
  const recordingState = subDeviceTelemetries?.cameras?.[0]?.recording_state;
  const [recordingSeconds, setRecordingSeconds] = useState(0);
  const recordingIntervalId = useRef(null);

  const checkForSlowSdCard = () => captureUtils.checkForSlowSdCard(deviceId);
  const switchCameraMode = () => captureUtils.switchCameraMode(deviceId);
  const captureImage = () => captureUtils.captureImage(deviceId);
  const toggleVideoRecord = () => captureUtils.toggleVideoRecord(deviceId);
  const safeToggleVideoRecord = (startRecordOnly = false) => captureUtils.safeToggleVideoRecord(deviceId, startRecordOnly);

  useEffect(() => {
    if(recordingState === DjiDockDroneRecordingState.RECORDING) {
      recordingIntervalId.current = setInterval(() => {
        setRecordingSeconds((prev) => prev + 1);
      }, 1000);

      return () => clearInterval(recordingIntervalId.current);
    } else {
      setRecordingSeconds(0);
      clearInterval(recordingIntervalId.current);
    }
  }, [recordingState]);

  return { 
    cameraMode, 
    recordingState, 
    recordingSeconds,
    checkForSlowSdCard, 
    switchCameraMode, 
    captureImage, 
    toggleVideoRecord, 
    safeToggleVideoRecord 
  };
};

export default useDeviceCapture;