import "./OnboardDeviceApp.sass";
import { useEffect, useRef, useState, useMemo } from "react";
import { FaUserCircle } from "react-icons/fa";
import { IoFileTrayStackedOutline } from "react-icons/io5";
import { HiOutlineChip } from "react-icons/hi";
import { ImQrcode } from "react-icons/im";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { TiGroupOutline } from "react-icons/ti";
import { CgComponents } from "react-icons/cg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTheme } from "../../../../../helper/ThemeProvider";
import DateTimeComponent from "../../../../../ui/DateTimeComponent/DateTimeComponent";
import LabeledIcon from "../../../../../ui/LabeledIcon/LabeledIcon";
import SVGAvatar from "../../../../../ui/SVGAvatar/SVGAvatar";
import ApplicationContentAreaContainer from "../../../Components/ApplicationContentAreaContainer/ApplicationContentAreaContainer";
import OnboardDeviceInformation from "./OnboardDeviceInformation/OnboardDeviceInformation";
import { useDispatch, useSelector } from "react-redux";
import {
  useAccount,
  usePrivileges,
} from "../../../../../helper/AccountStateProvider";
import { droneModel, getList, list } from "./OnboardDeviceAppSlice";

import moment from "moment";
import EntityName from "../../../../../ui/EntityName/EntityName";
import DeviceFileManager from "./DeviceFileManager/DeviceFileManager";
import {
  DroneLightIcon,
  DroneOffIcon,
  DroneOnIcon,
} from "../../../../../ui/Icons/Icons";
import DeviceComponentManager from "./DeviceComponentManager/DeviceComponentManager";
import { selectedGroups } from "../../../../../ui/GroupApp/GroupAppSlice";
import GroupManagementApplication from "../../../Components/GroupManagementApplication/GroupManagementApplication";
import { SerialNumberProvider } from "../../../../../helper/SerialNumberProvider";
import { onboardConnectionManager } from "../../../../../helper/HubConnectionManager";
import { useLocation } from "react-router-dom";
import WebsocketHubProvider from "src/components/Dashboard/Components/WebsocketHubProvider/WebsocketHubProvider";
import { setIdentity } from "src/components/Dashboard/Components/WebsocketHandler/IdentitySlice";
import { useFrontendHub } from "src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider";
import DeviceOnboarding from "./DeviceOnboarding/DeviceOnboarding";
import { HubEventName, HubName } from "src/helper/HubConnection";
import Avatar from "src/ui/Avatar/Avatar";

function OnboardDeviceApp() {
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const { currentContext } = useTheme();
  const { privileges } = usePrivileges();
  const [permissions, setPermissions] = useState([]);
  const [drones, setDrones] = useState([]);
  const dispatch = useDispatch();
  const onboard = useSelector((state) => state.onboard);
  const { account } = useAccount();
  const [currentDrone, setCurrentDrone] = useState({});
  const [droneListFilter, setDroneListFilter] = useState("All");
  const api = useSelector((state) => state.api);
  const serverMessageHandlerIds = useRef([]);
  const systemMessageHandlerIds = useRef([]);
  const location = useLocation();
  const [drone, setDrone] = useState();
  const {
    hub: frontendConnectionManager,
    connected: frontendHubConnected,
    checkConnectivity,
  } = useFrontendHub();

  const dataOptions = [
    {
      title: "Regenerate Device Token",
      icon: <ImQrcode />,
      color: currentContext.theme.color,
      name: "regenerate_device_token",
      type: "dependent",
      active: true,
    },
    {
      title: "Disable Device",
      icon: <MdDoNotDisturbAlt />,
      color: currentContext.theme.color,
      name: "disable_device",
      active: true,
    },
  ];

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Device Name", value: "deviceName", status: true },
      { title: "Model", value: "model", status: true },
      { title: "Registered By", value: "registeredBy", status: true },
      { title: "Serial Number", value: "serialNumberTitle", status: true },
      { title: "Registered Date", value: "registeredDate", status: false },
      {
        title: "Video Streaming Region",
        value: "videoStreamingRegion",
        status: true,
      },
      {
        title: "Data Streaming Region",
        value: "dataStreamingRegion",
        status: true,
      },
    ],
    values: [],
    options: dataOptions,
  });
  const [rowsPopulated, setRowsPopulated] = useState(false);

  const filteredDroneList = useMemo(() => {
    if (droneListFilter === "All") return rows;

    return {
      ...rows,
      values: [
        ...rows.values.filter((item) =>
          droneListFilter === "Online" ? item.isOnline : !item.isOnline
        ),
      ],
    };
  }, [rows, droneListFilter]);

  useEffect(() => {
    setRows({ ...rows, values: [...drones] });
  }, [drones]);

  useEffect(() => {
    if (rows.values.length && rowsPopulated === false) {
      setRowsPopulated(true);
    }
  }, [rows]);

  useEffect(() => {
    if (rowsPopulated)
      drones.forEach((item) => {
        if (frontendHubConnected) {
          changeLight(item.id, "idle");
          checkConnectivity(account.user.localAccountId, item.id);
        } else changeLight(item.id, "off");
      });
  }, [rowsPopulated, frontendHubConnected]);

  const onboardHandlers = drones.map((item) => [
    {
      identity: item.id,
      name: HubEventName.CONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        changeLight(data?.userId, "idle");
        const drone = drones.find((d) => d.id === data?.userId);
        if (drone) {
          checkConnectivity(account.user.localAccountId, data?.userId);
        }
      },
    },
    {
      identity: item.id,
      name: HubEventName.CONNECTIVITY_STATUS,
      platform: HubName.ONBOARD,
      handler: (data) => {
        changeLight(data?.userId, "on");
      },
    },
    {
      identity: item.id,
      name: HubEventName.DISCONNECTED,
      platform: HubName.ONBOARD,
      handler: (data) => {
        changeLight(data?.userId, "idle");
      },
    },
  ]);

  const frontendHandlers = [
    {
      identity: account?.user?.localAccountId,
      name: HubEventName.DISCONNECTED,
      handler: (data) => {
        setRows({
          ...rows,
          values: [
            ...rows.values.map((drone) => ({
              ...drone,
              model: (
                <LabeledIcon
                  icon={<DroneOffIcon size="15" />}
                  label={drone.name}
                />
              ),
              indicator: <DroneOffIcon size="15" />,
            })),
          ],
        });
      },
    },
    {
      identity: account?.user?.localAccountId,
      name: HubEventName.CONNECTED,
      handler: (data) => {
        drones?.forEach((item) => {
          checkConnectivity(account.user.localAccountId, item.id);
        });
      },
    },
  ];

  useEffect(() => {
    frontendConnectionManager.unsubscribeSystemMessages(
      systemMessageHandlerIds.current
    );

    systemMessageHandlerIds.current =
      frontendConnectionManager?.subscribeSystemMessages(
        frontendHandlers,
        "onboarddeviceapp"
      );

    frontendConnectionManager.unsubscribeServerMessages(
      serverMessageHandlerIds.current
    );

    serverMessageHandlerIds.current =
      frontendConnectionManager.subscribeServerMessages(
        onboardHandlers.reduce(function (result, current) {
          return [...result, ...current];
        }, []),
        "onboarddeviceapp"
      );

    return () => {
      frontendConnectionManager.unsubscribeServerMessages(
        serverMessageHandlerIds.current
      );

      frontendConnectionManager?.unsubscribeSystemMessages(
        systemMessageHandlerIds.current
      );
    };
  });

  useEffect(() => {
    if (privileges.applications !== undefined) {
      setPermissions([
        ...privileges.applications
          .filter(
            (app) =>
              app.id === "devices" ||
              app.id === "onboard" ||
              app.id === "groups"
          )
          .map((app) => app.privileges.filter((pr) => pr !== ""))
          .reduce((result, current) => result.concat(current), []),
      ]);
    }
  }, [privileges.applications]);

  useEffect(() => {
    if (api.token !== undefined && onboard?.list?.status !== "success")
      dispatch(getList());
    return () => {
      dispatch(list({ status: "" }));
    };
  }, [api.token]);

  useEffect(() => {
    if (onboard.list.data !== undefined) {
      setDrones([
        ...onboard.list.data.drones.map((d) => ({
          indicator: frontendHubConnected ? (
            <DroneLightIcon size="15" />
          ) : (
            <DroneOffIcon size="15" />
          ),
          id: d.id,
          deviceName: d.deviceName,
          isOnline: false,
          model: (
            <LabeledIcon
              icon={
                frontendHubConnected ? (
                  <DroneLightIcon size="15" />
                ) : (
                  <DroneOffIcon size="15" />
                )
              }
              label={d.model}
            />
          ),
          name: d.model,
          groups: d.groups,
          registeredBy: (
            <EntityName
              item={{
                online: false,
                title: d.registeredBy.displayName,
                subtitle: d.registeredBy.emailAddress,
                icon: d.registeredBy.avatar ? (
                  <Avatar
                    image={d.registeredBy.avatar}
                    isCovered={true}
                    width="32px"
                    height="32px"
                  />
                ) : (
                  <SVGAvatar />
                ),
              }}
            />
          ),
          serialNumberTitle: (
            <LabeledIcon
              color="black"
              textColor="#878787"
              icon={<HiOutlineChip size="15" />}
              label={d.serialNumber}
            />
          ),
          registeredDate: (
            <DateTimeComponent
              date={moment(d.creationDate).format("DD MMMM YYYY")}
              time={moment(d.creationDate).format("hh:mm A")}
              hour={parseInt(moment(d.creationDate).format("h"))}
            />
          ),
          serialNumber: d.serialNumber,
          videoStreamingRegion: d.videoStreamingRegion,
          dataStreamingRegion: d.dataStreamingRegion,
          instance: undefined,
          connectionId: undefined,
          active: false,
        })),
      ]);
    }
  }, [onboard.list, frontendHubConnected]);

  const histories = [
    { title: "Device Application", link: "/dashboard/device-app" },
    { title: "DJI Controller", link: "/dashboard/device-app/onboard/list" },
  ];

  const history = useHistory();
  const [showDeviceOnboarding, setShowDeviceOnboarding] = useState(false);

  const fireActionEvent = () => {
    setShowDeviceOnboarding(true);
  };

  const featureActionGenerator = () => {
    console.log("action");
  };

  const goBack = () => {
    history.replace(`/dashboard/device-app/onboard/list`);
  };

  const [appFeature, setAppFeature] = useState({
    id: "device_information",
    icon: <IoMdInformationCircleOutline />,
    actionGenerator: featureActionGenerator,
    steps: [],
  });

  const steps = [
    {
      name: "information",
      title: "Information",
      icon: <IoMdInformationCircleOutline />,
      active: true,
      visible: true,
      locked: false,
      tags: ["onboard/wsConnection"],
      activity: <OnboardDeviceInformation />,
    },
    {
      name: "groups_app_info",
      title: "Group Management",
      icon: <TiGroupOutline />,
      active: false,
      visible: true,
      locked: false,
      activity: <GroupManagementApplication />,
      dependent: true,
    },
    {
      name: "file-manager",
      title: "File Management",
      icon: <IoFileTrayStackedOutline />,
      active: false,
      visible: true,
      locked: false,
      activity: (
        <SerialNumberProvider>
          <WebsocketHubProvider hub={onboardConnectionManager}>
            <DeviceFileManager />
          </WebsocketHubProvider>
        </SerialNumberProvider>
      ),
    },
    // {
    //   name: "component-manager",
    //   title: "Component Management",
    //   icon: <CgComponents />,
    //   active: false,
    //   visible: true,
    //   locked: false,
    //   activity: <DeviceComponentManager />,
    // },
  ];

  useEffect(() => {
    setAppFeature({
      ...appFeature,
      steps: [
        ...steps.filter(
          (af) =>
            !af.dependent || (af.dependent && permissions.includes(af.name))
        ),
      ],
    });
  }, [permissions]);

  const closeOperationHandler = () => {
    setShowOperationManager(false);
    setOperationManagerHandler(false);
  };

  const [options, setOptions] = useState([]);

  const [dialog, setDialog] = useState();
  const [currentOption, setCurrentOption] = useState();

  const triggerCurrentOption = (name) => {
    setCurrentOption({
      ...options.filter((o) => {
        return o.name === name;
      })[0],
    });
  };

  const changeLight = (id, status) => {
    console.log("changeLight", id, status);
    if (!rows.values?.length) return;

    setRows((currRows) => {
      const targetRowIndex = currRows.values.findIndex((d) => d.id === id);
      const targetRow = currRows.values[targetRowIndex];

      if (targetRow) {
        targetRow.model = (
          <LabeledIcon
            icon={
              status === "on" ? (
                <DroneOnIcon size="15" />
              ) : status === "idle" ? (
                <DroneLightIcon size="15" />
              ) : (
                <DroneOffIcon size="15" />
              )
            }
            label={targetRow.name}
          />
        );

        targetRow.indicator =
          status === "on" ? (
            <DroneOnIcon size="15" />
          ) : status === "idle" ? (
            <DroneLightIcon size="15" />
          ) : (
            <DroneOffIcon size="15" />
          );

        targetRow.isOnline = status === "on";

        const newRowValues = [...currRows.values];
        newRowValues[targetRowIndex] = targetRow;

        return {
          ...currRows,
          values: newRowValues,
        };
      }

      return currRows;
    });
  };

  useEffect(() => {
    if (currentOption !== undefined) currentOption.handler();
  }, [currentOption]);

  const rowChangeHandler = (row) => {
    if (row !== undefined) {
      setCurrentDrone({ ...row });
      if (drone.id !== row.id)
        history.replace(
          `/dashboard/device-app/onboard/list/${row.name}/${row.id}/${row.serialNumber}`
        );
    }
  };
  useEffect(() => {
    dispatch(droneModel(currentDrone));
    dispatch(selectedGroups(currentDrone.groups));
  }, [currentDrone]);

  useEffect(() => {
    const locations = location.pathname.split("/");
    if (drone?.id !== locations[locations.length - 2]) {
      setDrone({
        name: locations[locations.length - 3],
        id: locations[locations.length - 2],
        serialNumber: locations[locations.length - 1],
      });
      dispatch(setIdentity(locations[locations.length - 2]));
    }
  }, [location]);

  useEffect(() => {
    if (drone !== undefined && rowsPopulated) {
      setRows((currentRow) => {
        currentRow.values.forEach((targetRow, index) => {
          if (targetRow.id === drone.id) {
            targetRow.active = true;
            currentRow.values[index] = targetRow;
          } else if (targetRow.active) {
            targetRow.active = false;
            currentRow.values[index] = targetRow;
          }
        });
        return currentRow;
      });
    }
  }, [drone, rowsPopulated]);

  return (
    <ApplicationContentAreaContainer
      histories={histories}
      title="DJI Controller"
      description="Here you will be able to add new devices or see all the devices that have already been added to the device management section. If you wish to add a new device, click on the + <b>New Device</b> button. "
      data={filteredDroneList}
      feature={appFeature}
      optionHandler={triggerCurrentOption}
      operations={[...options]}
      searchOption={true}
      onClose={closeOperationHandler}
      indicatorKey="indicator"
      titleKey="name"
      subtitleKey="serialNumberTitle"
      confirm={dialog}
      isAppArea={true}
      table={{
        tags: ["onboard/list", "onboard/remove", "websocket/connection"],
        show: true,
        selectable: false,
        boldIndexes: [1],
      }}
      action={{
        enable: permissions.includes("generate_onboard_passkey"),
        title: "New Device",
        component: "button",
        event: fireActionEvent,
        dependent: false,
      }}
      operationManager={showOperationManager}
      managerHandler={operationManagerHandler}
      filterSelectors={[
        { title: "All", value: "All Devices", active: true },
        { title: "Online", value: "Online Devices" },
        { title: "Offline", value: "Offline Devices" },
      ]}
      filterSelectorHandler={(e) => setDroneListFilter(e.title)}
      showOptionSelector={true}
      onRowChangeHandler={rowChangeHandler}
      onCloseHandler={goBack}
    >
      {showDeviceOnboarding && (
        <DeviceOnboarding
          onCloseByUser={() => setShowDeviceOnboarding(false)}
        />
      )}
    </ApplicationContentAreaContainer>
  );
}

export default OnboardDeviceApp;
