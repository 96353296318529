import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../ui/Button/Button";
import { SummaryIcon } from "../../../../../ui/Icons/Icons";
import KeyValue from "../../../../../ui/KeyValue/KeyValue";
import { setOperation } from "../../../AdminOperationManager/OperationStepSlice";
import { create, createGuest, setModel } from "../GuestManagementSlice";
import "./CreateGuestSummary.sass";

function CreateGuestSummary({ onConfirm }) {
  const guest = useSelector((state) => state.guest);
  console.log(guest.model);
  const dispatch = useDispatch();

  useEffect(() => {
    if (guest.create.status === "success") {
      dispatch(create({ status: "" }));
      dispatch(setModel({}));
      onConfirm();
    } else if (guest.create.status === "validation") {
      dispatch(setOperation("create_guest"));
    }
  }, [guest.create]);

  const editGuestStep = () => {
    dispatch(setOperation("create_guest"));
  };
  const confirmGuestCreation = () => {
    dispatch(
      createGuest({
        ...guest.model,
        Roles: guest.model.Roles !== undefined ? [...guest.model.Roles] : [],
      })
    );
  };
  return (
    <div className="create-guest-summary">
      <p className="guest-summary-description">
        Please check and confirm your information
      </p>
      <div className="guest-summary-information">
        {Object.keys(guest.model).map(
          (key, index) =>
            typeof guest.model[key] === "string" && (
              <KeyValue
                key={index}
                attr={key}
                value={guest.model[key].toString()}
                keyWidth="35%"
                valueWidth="65%"
              />
            )
        )}
      </div>
      <div className="guest-summary-footer">
        <p className="guest-summary-description">
          You can change this information after in guest management.
        </p>
        <SummaryIcon />
        <div className="guest-summary-buttons">
          <Button
            value="Edit"
            width="33%"
            isSelectable={true}
            onClick={editGuestStep}
          />
          <Button
            value="Confirm"
            color="#2699fb"
            width="33%"
            onClick={confirmGuestCreation}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateGuestSummary;
