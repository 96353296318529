import React, { useEffect, useState } from "react";
import "./InformationPanel.sass";
import cloud from "./../../../assets/img/cloud-icon.svg";
import bell from "./../../../assets/img/bell-icon.svg";
import { useSelector } from "react-redux";
import { accountInformation } from "../../../helper/constants";

const InformationPanel = () => {
  const help = useSelector((state) => state.account.help);
  const [information,setInformation] = useState({});
  useEffect(() => {
    setInformation(accountInformation.filter(item=>{return item.name===help})[0]);
  }, [help]);;

  return (
    <div className="information-panel">
      <div>
        <p style={{ fontWeight: "bold" }}>{information?.title}</p>
        <p className="information-panel-description">{information?.desc}</p>
        <ul>
          {information?.cloud_desc && (
            <li>
              <p>{information?.cloud_desc}</p>
            </li>
          )}
          {information?.ring_desc && (
            <li>
              <p>{information?.ring_desc}</p>
            </li>
          )}
        </ul>
      </div>

      <div style={{ textAlign: "center" }}>
        {information?.image !== undefined && (
          <>
            <img
              className="information-panel-image"
              src={information?.image}
              alt="help"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default InformationPanel;
