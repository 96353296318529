import { useEffect, useState } from "react";
import "./CreateStorage.sass";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../../../../ui/Form/Form";
import Select from "../../../../../ui/Select/Select";
import { setOperation } from "../../../AdminOperationManager/OperationStepSlice";
import { createStorage } from "../StorageManagementSlice";
import InputBox from "../../../../../ui/InputBox/InputBox";
import KeyValueFormNew from "../../../../../ui/KeyValueFormNew/KeyValueFormNew";

function CreateStorage({ onOpen, onConfirm }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [required, setRequired] = useState(["StorageRegion", "Name"]);
  const [validate, setValidate] = useState(true);
  const [alerts, setAlerts] = useState({});
  const storage = useSelector((state) => state.storage);

  const regions = [
    { id: 1, name: "Australia East", value: "Australia East" },
    { id: 2, name: "Australia Central", value: "Australia Central" },
    { id: 3, name: "Australia Southeast", value: "Australia Southeast" },
    { id: 4, name: "Canada Central", value: "Canada Central" },
    { id: 5, name: "East US", value: "East US" },
    { id: 6, name: "Japan East", value: "Japan East" },
    { id: 7, name: "North Europe", value: "North Europe" },
    { id: 8, name: "UK South", value: "UK South" },
  ];

  useEffect(() => {
    dispatch(setOperation("create_storage"));
    onOpen();
  }, []);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAlerts({ ...alerts, [key]: { alert: false } });
    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    // dispatch(create({ status: "" }));
    // dispatch(setModel({ ...data }));
    // dispatch(setOperation(NextStep));
    dispatch(createStorage({ ...data }));
  };

  useEffect(() => {
    if (storage.create.status === "success") {
      onConfirm();
    } else if (storage.create.status === "validation") {
      let validations = {};
      storage.create.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
  }, [storage.create]);

  const onKeyValueChange = (tags) => {
    setData({ ...data, storageTags: { ...tags } });
  };

  return (
    <div className="create-storage-form">
      <Form
        submit={{
          title: "Confirm",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        className="create-storage-container"
        validate={validate}
      >
        <InputBox
          isRequired={required.includes("Name")}
          title="Storage Name"
          name="Name"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Name}
          value={data.Name}
          index={0}
          background="white"
          width="100%"
        />
        <InputBox
          isRequired={required.includes("Description")}
          title="Storage Description"
          name="Description"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Description}
          value={data.Description}
          index={1}
          background="white"
          width="100%"
        />

        <Select
          isRequired={required.includes("StorageRegion")}
          title="Storage Region"
          name="StorageRegion"
          items={regions}
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.StorageRegion}
          value={data.StorageRegion}
          index={2}
          background="white"
          width="100%"
        />
        <KeyValueFormNew
          onDataChange={onKeyValueChange}
          description="You can add optional tags for this storage up to 10 fields."
        />
      </Form>
    </div>
  );
}

export default CreateStorage;
