import { useEffect, useState, useMemo } from "react";
import { useAccount } from "../../../../helper/AccountStateProvider";
import Dialog from "../../../../ui/Dialog/Dialog";
import AppTable from "../../../../ui/AppTable/AppTable";
import "./ApplicationContentAreaContainer.sass";
import OptionSelector from "../../../../ui/OptionSelector/OptionSelector";
import SearchOption from "../../../../ui/SearchOption/SearchOption";
import ApplicationOperationManager from "../ApplicationOperationManager/ApplicationOperationManager";
import ApplicationAction from "../../../../ui/ApplicationAction/ApplicationAction";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import LabeledButton from "../../../../ui/LabeledButton/LabeledButton";
import TabletSize from "../../../../ui/TabletSize/TabletSize";
import DesktopSize from "../../../../ui/DesktopSize/DesktopSize";
import ApplicationOptionBar from "../ApplicationOptionBar/ApplicationOptionBar";
import ApplicationContainer from "../ApplicationContainer/ApplicationContainer";
import Table from "../../../../ui/Table/Table";
import BreadCrumb from "src/ui/BreadCrumb/BreadCrumb";

function ApplicationContentAreaContainer({
  histories,
  title,
  description,
  operations,
  feature,
  data,
  table,
  operationManager,
  managerHandler,
  children,
  footer,
  filterSelectors,
  filterSelectorHandler,
  action,
  searchOption,
  indicatorKey,
  titleKey,
  subtitleKey,
  optionHandler,
  onRowChangeHandler,
  confirm,
  isAppArea,
  onCloseHandler,
}) {
  const [selectedFeature, setSelectedFeature] = useState();
  const [selectedRows, setSelectedRows] = useState();
  const [options, setOptions] = useState(operations);
  const [rows, setRows] = useState({ values: [], headers: [], options: [] });
  const [dialog, setDialog] = useState({});
  const [showOperationManager, setShowOperationManager] =
    useState(operationManager);
  const [operationManagerHandler, setOperationManagerHandler] =
    useState(managerHandler);
  const { account } = useAccount();
  const [updating, setUpdating] = useState(true);

  useEffect(() => {
    setOptions([...operations]);
  }, [operations]);

  useEffect(() => {
    if (confirm !== undefined) {
      setShowOperationManager(false);
      setOperationManagerHandler(false);
      setDialog({ ...confirm, enable: true });
    }
  }, [confirm]);

  useEffect(() => {
    if (data?.values !== undefined) {
      setRows(data);
      setUpdating(true);
      data?.values.map((row) =>
        setSelectedFeature({
          ...feature,
          indicator: row[indicatorKey],
          title: row[titleKey],
          subtitle: row[subtitleKey],
        })
      );
    }
  }, [data]);

  useEffect(() => {
    setShowOperationManager(operationManager);
  }, [operationManager]);

  useEffect(() => {
    setOperationManagerHandler(managerHandler);
  }, [managerHandler]);

  useEffect(() => {
    if (selectedRows !== undefined && !updating) {
      setShowOperationManager(true);
      setOperationManagerHandler(true);
    }
  }, [selectedFeature]);

  useEffect(() => {
    if (selectedRows !== undefined && selectedRows.length > 0) {
      const conflict =
        selectedRows?.filter((row) => {
          return row.id === account.user.localAccountId;
        }).length > 0;
      options.map(
        (item) =>
        (item.active =
          item.type === "dependent"
            ? item.except === "account"
              ? !conflict
              : true
            : item.active)
      );
      options.map(
        (option) =>
        (option.active =
          option.type === "dependent" && option.singular
            ? selectedRows.length < 2
            : option.active)
      );
      setOptions([...options]);
    } else {
      options.map(
        (item) =>
          (item.active = item.type === "dependent" ? false : item.active)
      );
      setOptions([...options]);
    }
  }, [selectedRows]);

  const rowChangeHandler = (row) => {
    if (row !== undefined) {
      setUpdating(false);
      onRowChangeHandler(row);
      setSelectedRows(row);
      setSelectedFeature({
        ...feature,
        indicator: row[indicatorKey],
        title: row[titleKey],
        subtitle: row[subtitleKey],
      });
    }
  };
  const rowsChangeHandler = (rows) => {
    if (rows !== undefined) {
      setSelectedRows(rows);
    }
  };

  const CloseDialogHandler = () => {
    setDialog({ ...dialog, enable: false });
  };
  const ConfirmDialogHandler = () => {
    setDialog({ ...dialog, enable: false });
    dialog.handler();
  };

  const toggleOperationManager = () => {
    setOperationManagerHandler(false);
  };

  const closeOperationManager = () => {
    setShowOperationManager(false);
    if (onCloseHandler) onCloseHandler();
  };

  const [selectedOptionSelector, setSelectedOptionSelector] = useState(
    filterSelectors?.filter((x) => x.active)[0]?.value
  );
  const optionSelectorHandler = (item) => {
    setSelectedOptionSelector(item.value);
    if (filterSelectorHandler !== undefined) {
      filterSelectorHandler(item);
    }
  };

  const filterSelectorsWithActiveOption = useMemo(
    () =>
      filterSelectors.map((item) => {
        if (!selectedOptionSelector) return item;
        return { ...item, active: item.value === selectedOptionSelector };
      }),
    [filterSelectors, selectedOptionSelector]
  );

  return (
    <ApplicationContainer histories={histories}>
      <div className="application-content-area">
        <div className="application-content-area-header">
          <h3 className="application-content-title">{title}</h3>
          <p
            className="application-content-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {selectedOptionSelector?.length > 0 && (
            <h4>{selectedOptionSelector}</h4>
          )}

          <div className="application-content-actions">
            <div className="application-content-option-selector">
              {filterSelectors?.length > 0 && (
                <OptionSelector
                  options={filterSelectorsWithActiveOption}
                  selectHandler={optionSelectorHandler}
                />
              )}

              {action?.enable && action?.component === "button" && (
                <ApplicationAction
                  title={action.title}
                  icon={<MdAddCircleOutline />}
                  onClick={action.event}
                />
              )}
              {action?.component === "label" && (
                <LabeledButton
                  disabled={action?.dependent && selectedRows?.length === 0}
                  title={action?.title}
                  icon={<MdRemoveCircleOutline />}
                  onClick={action?.event}
                />
              )}
            </div>
            {operations.length > 0 && (
              <TabletSize>
                <ApplicationOptionBar
                  base={1}
                  buttons={options}
                  onClick={optionHandler}
                  searchable={true}
                />
              </TabletSize>
            )}
            <DesktopSize>
              {searchOption === true && isAppArea && <SearchOption />}
            </DesktopSize>
          </div>
          {operations.length > 0 && (
            <DesktopSize>
              <ApplicationOptionBar
                base={6}
                buttons={options}
                onClick={optionHandler}
                searchable={true}
              />
            </DesktopSize>
          )}
        </div>
        <div className="application-content-area-body">
          {table?.show === true &&
            (isAppArea ? (
              <AppTable
                rows={rows}
                onRowChange={rowChangeHandler}
                onRowsChange={rowsChangeHandler}
                optionChangeHandler={optionHandler}
                tags={[...table?.tags]}
                selectable={table?.selectable}
                boldIndexes={table?.boldIndexes}
              />
            ) : (
              <Table
                multipleSelect={table?.selectable}
                rows={rows}
                onRowChange={rowChangeHandler}
                onRowsChange={rowsChangeHandler}
                optionChangeHandler={optionHandler}
                tags={[...table?.tags]}
              />
            ))}
          {operationManagerHandler && (
            <ApplicationOperationManager
              isOpen={showOperationManager}
              toggle={toggleOperationManager}
              close={closeOperationManager}
              selectedFeature={selectedFeature}
            />
          )}
          {dialog?.enable === true && (
            <Dialog
              model={{
                message: dialog?.message,
                CloseHandler: CloseDialogHandler,
                ConfirmHandler: ConfirmDialogHandler,
              }}
            />
          )}
        </div>
        {children}
        <div className="application-content-area-footer">{footer}</div>
      </div>
    </ApplicationContainer>
  );
}
ApplicationContentAreaContainer.defaultProps = {
  AppTable: { tags: [], rows: [], show: false },
  operations: [],
};
export default ApplicationContentAreaContainer;
