import { useEffect, useState } from "react";
import "./CreateStorage.sass";
import { useDispatch, useSelector } from "react-redux";
import Form from "../../../../../ui/Form/Form";
import Select from "../../../../../ui/Select/Select";
import { setOperation } from "../../../AdminOperationManager/OperationStepSlice";
import { createStorage } from "../S3ManagementSlice";
import InputBox from "../../../../../ui/InputBox/InputBox";
import KeyValueFormNew from "../../../../../ui/KeyValueFormNew/KeyValueFormNew";
import CheckBox from "src/ui/CheckBox/CheckBox";

function CreateStorage({ onOpen, onConfirm }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [required, setRequired] = useState(["region", "name"]);
  const [validate, setValidate] = useState(true);
  const [alerts, setAlerts] = useState({});
  const storage = useSelector((state) => state.s3Storage);

  const regions = [
    { id: 1, name: "US East (Ohio)", value: "us-east-2" },
    { id: 2, name: "US West (N. California)", value: "us-west-1" },
    { id: 3, name: "Asia Pacific (Singapore)", value: "ap-southeast-2" },
    { id: 4, name: "Asia Pacific (Sydney)", value: "ap-southeast-2" },
    { id: 5, name: "Europe (Frankfurt)", value: "eu-central-1" },
  ];

  useEffect(() => {
    dispatch(setOperation("create_storage"));
    onOpen();
  }, []);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    if (key === "customStorage") {
      value = event.target.checked;
    }

    setAlerts({ ...alerts, [key]: { alert: false } });
    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    // dispatch(create({ status: "" }));
    // dispatch(setModel({ ...data }));
    // dispatch(setOperation(NextStep));
    dispatch(createStorage({ ...data }));
  };

  useEffect(() => {
    if (storage.create.status === "success") {
      onConfirm();
    } else if (storage.create.status === "validation") {
      let validations = {};
      storage.create.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
  }, [storage.create]);

  const onKeyValueChange = (tags) => {
    setData({ ...data, storageTags: { ...tags } });
  };

  return (
    <div className="create-storage-form">
      <Form
        submit={{
          title: "Confirm",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        className="create-storage-container"
        validate={validate}
      >
        <InputBox
          isRequired={required.includes("Name")}
          title="Storage Name"
          name="name"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Name}
          value={data.Name}
          index={0}
          background="white"
          width="100%"
        />
        <InputBox
          isRequired={required.includes("Description")}
          title="Storage Description"
          name="description"
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Description}
          value={data.Description}
          index={1}
          background="white"
          width="100%"
        />

        <Select
          isRequired={required.includes("StorageRegion")}
          title="Storage Region"
          name="region"
          items={regions}
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.StorageRegion}
          value={data.region}
          index={2}
          background="white"
          width="100%"
        />
        <CheckBox
          title="I want to use my own bucket storage"
          name="customStorage"
          text="I want to use my own bucket storage"
          checked={data.customStorage}
          onChecked={changeHandler}
        />
        {data.customStorage ? <>
          <InputBox
            isRequired={true}
            title="Access Key"
            name="accessKey"
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.accessKey}
            value={data.accessKey}
            index={4}
            background="white"
            width="100%"
          />
          <InputBox
            isRequired={true}
            title="Secret Key"
            name="secretKey"
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.secretKey}
            value={data.secretKey}
            index={5}
            background="white"
            width="100%"
          />
        </> : null}
      </Form>
    </div>
  );
}

export default CreateStorage;
