import RadarFix from "../../DroneIcons/RadarFix";
import RadarCurrent from "../../DroneIcons/RadarCurrent";
import RadarDegrees from "../../DroneIcons/RadarDegrees";
import RadarFacing from "../../DroneIcons/RadarFacing";
import "./Radar.sass";
import { useCameraSource } from "src/helper/CameraSourceProvider";
import RadarDrone from "src/ui/DroneIcons/RadarDrone";
import RadarObstacle from "src/ui/DroneIcons/RadarObstacle";

function Radar() {
  const { isMainCamera } = useCameraSource();
  return (
    <div className="drone-radar">
      <div className="radar-degree">
        <RadarCurrent className="radar-current" />
        <RadarDegrees size="8" time=".75" />
        <RadarFix size="8" />
        {/* <RadarObstacle size="5.7" className="radar-obstacle" /> */}
        <RadarDrone size="8" />
        {isMainCamera && (
          <RadarFacing size="8" time=".75" />
        )}
      </div>
    </div>
  );
}

export default Radar;
