import './DockCamera.sass';
import CollapsablePanel from '../../../common/CollapsablePanel/CollapsablePanel';
import { TbCameraQuestion, TbChevronDownRight, TbPlayerStop, TbProgressBolt, TbRefresh, TbVideoOff } from 'react-icons/tb';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IoCloudOfflineOutline } from 'react-icons/io5';
import { DeviceOnlineStatus } from 'src/helper/useDockList';
import { useDockVideoStream } from 'src/helper/useDockVideoStream';
import VideoStreamView from 'src/ui/Drone/VideoStreamView/VideoStreamView';
import { StageElement } from '../../OperationDashboard';
import classNames from 'classnames';
import useDeviceInfo from 'src/services/device/common/useDeviceInfo';
import useSubDevice from 'src/services/device/common/useSubDevice';
import { OrbitProgress } from 'react-loading-indicators';
import { FiMinimize2 } from 'react-icons/fi';
import { BsArrowLeftRight } from 'react-icons/bs';
import CloudVideoTypeToolbar from './CloudVideoTypeToolbar/CloudVideoTypeToolbar';
import CloudVideoQualityDropdown from './CloudVideoQualityDropdown/CloudVideoQualityDropdown';
import ManualFlightShortcuts from '../ManualFlightShortcuts/ManualFlightShortcuts';
import ManualFlightKeybaordGuide from '../ManualFlightKeyboardGuide/ManualFlightKeybaordGuide';
import CaptureTools from './CaptureTools/CaptureTools';
import { useSelector } from 'react-redux';
import MessageArea, { MessageAreaSize } from 'src/hci/organism/MessageArea/MessageArea';
import cloudStateMessages from 'src/services/device/assets/cloudStateMessages';

const VideoSourceDevice = {
  DOCK: 'dock',
  SUB_DEVICE: 'subDevice'
}

function DockCamera({ deviceId, onSwap, onMinimize, missionController, expanded = false, subDevice = false, pip = false, minimized = false }) {
  const deviceInfo = useDeviceInfo(deviceId);
  const subDeviceInfo = useSubDevice(deviceId);

  const isDockOnline = deviceInfo?.onlineStatus === DeviceOnlineStatus.ONLINE;
  const isSubDeviceOnline = !!subDeviceInfo?.device_online_status;

  const [dockStreamConfig, setDockStreamConfig] = useState();
  const [subStreamConfig, setSubStreamConfig] = useState();
  const [isMinimized, setIsMinimized] = useState(minimized);

  const [activeVideoSource, setActiveVideoSource] = useState((subDevice || (pip && isSubDeviceOnline)) ? VideoSourceDevice.SUB_DEVICE : VideoSourceDevice.DOCK);
  const { stream: dockVideoStream, dockId: currStreamDockId, refresh: refreshDockVideo, config: dockResConfig } = useDockVideoStream(dockStreamConfig, false) || {};
  const { stream: subVideoStream, refresh: refreshSubVideo, config: subResConfig } = useDockVideoStream(subStreamConfig, true) || {};

  const dockStreamStatus = useSelector((state) => state.deviceService.devices?.find(device => device.id === deviceId)?.streamStatus?.dock);
  const subDeviceStreamStatus = useSelector((state) => state.deviceService.devices?.find(device => device.id === deviceId)?.streamStatus?.subDevice);
  const activeStreamStatus = activeVideoSource === VideoSourceDevice.DOCK ? dockStreamStatus : subDeviceStreamStatus;
  const inactiveStreamStatus = activeVideoSource === VideoSourceDevice.DOCK ? subDeviceStreamStatus : dockStreamStatus;

  const sourceTitle = useMemo(() => activeVideoSource === VideoSourceDevice.SUB_DEVICE ? 'Drone Camera' : 'Dock Camera', [activeVideoSource]);
  const pipTitle = useMemo(() => activeVideoSource === VideoSourceDevice.SUB_DEVICE ? 'Drone' : 'Dock', [activeVideoSource]);

  const handleSwap = () => {
    onSwap?.(subDevice ? StageElement.DOCK_DRONE : StageElement.DOCK);
  }

  const handleRefresh = useCallback(() => {
    if(activeVideoSource === VideoSourceDevice.DOCK){
      refreshDockVideo();
    } else {
      refreshSubVideo();
    }
  }, [activeVideoSource, refreshDockVideo, refreshSubVideo]);

  const handleMinimize = useCallback((e) => {
    e?.stopPropagation();
    setIsMinimized(true);
    onMinimize?.(true);
  }, [onMinimize]);

  const handleContainerClick = () => {
    setIsMinimized(false);
    onMinimize?.(false);
  };

  const handleSourceSwitch = useCallback((e) => {
    e?.stopPropagation();
    const newSource = activeVideoSource === VideoSourceDevice.DOCK ? VideoSourceDevice.SUB_DEVICE : VideoSourceDevice.DOCK;
    setActiveVideoSource(newSource);
  }, [activeVideoSource]);

  const getStreamErrorMessages = (status, shortFormat = false) => {
    let title, description, icon;

    if(status === 513017){
      if(shortFormat) title = 'Start flight to view stream. drone processing...';
      else title = 'Please start your flight to view the live video stream';

      description = 'The drone is processing the captured media...';
      icon = TbProgressBolt;
    } else {
      title = 'Device internal live stream error';
      description = (cloudStateMessages[status] || "Drone has encountered an issue with internal live stream.") + ` (${status})`;
      icon = TbVideoOff;
    }

    return { title, description, icon };
  }

  const connectionMessage = (activeVideoSource === VideoSourceDevice.DOCK ? dockStreamConfig : subStreamConfig) ?
    'Waiting for stream...' :
    'No video provided.';

  const connectionMessageIcon = (!(activeVideoSource === VideoSourceDevice.DOCK ? isDockOnline : isSubDeviceOnline) ?
    <IoCloudOfflineOutline className="icon" /> :
    <OrbitProgress size="small" color="#fff" text="" textColor="" />
  );

  const tools = [
    ...(activeVideoSource === VideoSourceDevice.SUB_DEVICE ? [
      <ManualFlightKeybaordGuide key="keyboard-guide" title="Keyboard Shortcuts" />,
    ] : []),
    ...(!isMinimized ? [
      <FiMinimize2 onClick={handleMinimize} title="Minimize" key="minimize-stream" />,
    ] : []),
  ];

  useEffect(() => {
    setDockStreamConfig(null);

    if(isDockOnline && (!dockVideoStream || currStreamDockId !== deviceId)){
      setTimeout(() => {
        setDockStreamConfig(deviceId);
      }, 10);
    }
  }, [isDockOnline, deviceId]);

  useEffect(() => {
    if(!isSubDeviceOnline)
      setActiveVideoSource(VideoSourceDevice.DOCK);

    if(isSubDeviceOnline && (subDevice || pip) && (!subVideoStream || currStreamDockId !== deviceId)){
      setSubStreamConfig(null);
      
      setTimeout(() => {
        setSubStreamConfig(deviceId);
      }, 10);
    }
  }, [isSubDeviceOnline, deviceId, subDevice]);

  useEffect(() => {
    setIsMinimized(minimized);
    onMinimize?.(minimized);
  }, [minimized]);

  return (
    <div className={classNames("dock-camera", {'expanded': expanded, 'minimized': isMinimized})} onClick={handleContainerClick}>
      <CollapsablePanel title={sourceTitle} onSwap={handleSwap} expanded={expanded} tools={tools} proxyChildren={isMinimized}>
        <div className="content-container">
          <div className="stream-container">
            {activeStreamStatus !== 0 ? (
              <MessageArea
                title={getStreamErrorMessages(activeStreamStatus)?.title}
                description={getStreamErrorMessages(activeStreamStatus)?.description}
                icon={getStreamErrorMessages(activeStreamStatus)?.icon}
                size={isMinimized ? MessageAreaSize.SMALL : MessageAreaSize.MEDIUM}
              />
            ) : (
              <div className="connection-message">
                  {connectionMessageIcon}
                  <div className="description">{connectionMessage}</div>
              </div>
            )}
            <VideoStreamView stream={activeVideoSource === VideoSourceDevice.DOCK ? dockVideoStream : subVideoStream} className={classNames("video-view", { 'height-limited': activeVideoSource === VideoSourceDevice.SUB_DEVICE && expanded && !isMinimized })} />
            {
              (pip && (activeVideoSource === VideoSourceDevice.DOCK ? isSubDeviceOnline : isDockOnline)) 
              ? <div className="pip-container" onClick={handleSourceSwitch}>
                  {inactiveStreamStatus !== 0 ? (
                    <MessageArea
                      title={getStreamErrorMessages(activeStreamStatus, true)?.title}
                      size={MessageAreaSize.TINY}
                    />
                  ) : (
                    <>
                      <VideoStreamView stream={activeVideoSource === VideoSourceDevice.DOCK ? subVideoStream : dockVideoStream} className={classNames("pip-video-view")} />
                      {!(activeVideoSource === VideoSourceDevice.DOCK ? subVideoStream : dockVideoStream) ? (
                        <div className="loading-container">
                          <OrbitProgress size="small" color="#b9b9b9" text="" textColor="" />
                        </div>
                      ) : null}
                    </>
                  )}
              </div>
              : null
            }
          </div>
          {isMinimized ? (
            <div className="minimized-video-tools">
              {pip && (activeVideoSource === VideoSourceDevice.DOCK ? isSubDeviceOnline : isDockOnline) ? (
                <div className="toggle-camera-container" onClick={handleSourceSwitch} title="Switch video source">
                  <BsArrowLeftRight className="toggle-icon" />
                </div>
              ) : null}
              <div className="minimized-title">{pipTitle}</div>
            </div>
          ) : (
            <>
              <CloudVideoTypeToolbar 
                deviceId={deviceId} 
                serialNumber={activeVideoSource === VideoSourceDevice.DOCK ? deviceInfo?.serialNumber : subDeviceInfo?.device?.serialNumber}
                config={activeVideoSource === VideoSourceDevice.DOCK ? dockResConfig : subResConfig} 
              />
              <CloudVideoQualityDropdown
                deviceId={deviceId}
                serialNumber={activeVideoSource === VideoSourceDevice.DOCK ? deviceInfo?.serialNumber : subDeviceInfo?.device?.serialNumber}
                config={activeVideoSource === VideoSourceDevice.DOCK ? dockResConfig : subResConfig}
              />
              {activeVideoSource === VideoSourceDevice.SUB_DEVICE && (
                <>
                  <ManualFlightShortcuts
                    deviceId={deviceId} 
                    missionController={missionController} 
                  />

                 {subStreamConfig ? (
                    <CaptureTools
                      deviceId={deviceId}
                      subDeviceId={subDeviceInfo?.device_sn}
                      config={subResConfig}
                    />
                 ):null}
                </>
              )}
            </>
          )}
        </div>
      </CollapsablePanel>
    </div>
  )
}

export default DockCamera