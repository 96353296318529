import { FaMapMarker } from 'react-icons/fa';
import './MapDockMarker.sass';
import dockImage from 'src/assets/img/dock-small.png';
import classNames from 'classnames';
import useDeviceTelemetries from 'src/services/device/common/useDeviceTelemetries';

function MapDockMarker({title, isOnline, sourceId, className}) {
  const isDroneInside = useDeviceTelemetries(sourceId, data => data?.drone_in_dock);

  return (
    <div className={classNames("map-dock-marker", className, {"online": isOnline, "drone-inside": isDroneInside})} title="Dock Station">
      <div className="icon">
        <img src={dockImage} alt="dock" />
      </div>
      <div className="title">
        {title}
      </div>
    </div>
  )
}

export default MapDockMarker