import { useDispatch, useSelector } from 'react-redux';
import './DockManagerConsole.sass';
import { useEffect, useRef, useState } from 'react';
import { getDockDetails } from '../DockManagerAppSlice';
import DockDeviceViewer from '../DockDeviceViewer/DockDeviceViewer';
import DockPairedDroneViewer from '../DockPairedDroneViewer/DockPairedDroneViewer';
import SidePanel from 'src/hci/organism/SidePanel/SidePanel';
import Loading from 'src/ui/Loading/Loading';

function DockManagerConsole({ dockId }) {
  const dispatch = useDispatch();
  const [dockDetails, setDockDetails] = useState();
  const [loadingDockDetails, setLoadingDockDetails] = useState(false);
  const dockDetailsResponse = useSelector(state => state.dock?.dockDetails);

  useEffect(() => {
    if(!dockId)
      return;
    
    setLoadingDockDetails(true);
    dispatch(getDockDetails(dockId));
  }, [dockId]);

  useEffect(() => {
    if(!dockId || dockDetailsResponse?.data?.id !== dockId)
      return;

    if(dockDetailsResponse?.status === 'success') {
      setDockDetails(dockDetailsResponse.data);
      setLoadingDockDetails(false);
    }
  }, [dockDetailsResponse]);

  return (
    <div className="dock-manager-console">
      <SidePanel panelTitle={`Dock Console (${dockDetails?.dockName || dockDetails?.manufacturer})`}>
        {loadingDockDetails ? <Loading /> : (
          <div className="device-viewers-container">
            <DockDeviceViewer dockData={dockDetails} />
            <DockPairedDroneViewer dockData={dockDetails} />
          </div>
        )}
      </SidePanel>
    </div>
  )
}

export default DockManagerConsole