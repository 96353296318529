import { useSelector } from 'react-redux';

function GPSSignal() {
  const gps = useSelector((state) => state.flight.gps);
  const color = gps?.visibleSats <= 10 ? '#ff282a' : '#fff';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={ gps?.visibleSats < 10 ? '3.8em' : '5.6em' } height="2.16em" viewBox="0 0 57.946 33">
      <g data-name="Group 37003">
          <text data-name="9" transform="translate(41.946 26)" style={{"fontSize": "27px", "fontFamily": "Roboto-Regular,Roboto", "letterSpacing": 0, fill: color}}><tspan x="0" y="0">{gps?.visibleSats || '0'}</tspan></text>
          <g data-name="Group 37002">
              <path data-name="Path 18966" d="m-22753.875 1565.929-3.748-3.656-2.281 2.279 3.656 3.656z" transform="translate(22768.864 -1562.273)" style={{fill: color}} />
              <path data-name="Path 18967" d="m-22753.875 1565.929-3.748-3.656-2.281 2.279 3.656 3.656z" transform="translate(22785.087 -1547.104)" style={{fill: color}} />
              <path data-name="Path 18968" d="m-22818.535 1616.665-.627-.624 2.236-2.183s-.105-2.949-1.609-4.522-4.422-1.767-4.422-1.767l-2.338 2.235-.779-.676-.727.676.727.779-3.9 4 2.443 2.755 3.326 3.223 4.26-3.9.779.676z" transform="translate(22842.935 -1603.422)" style={{fill: color}} />
              <g transform="translate(0 12.357)">
                  <circle data-name="Ellipse 1913" cx="2.384" cy="2.384" r="2.384" transform="translate(12.729)" style={{fill: color}} />
                  <path data-name="Path 18970" d="M21.5 21.936v-3.182A14.315 14.315 0 0 1 7.182 4.44H4a17.5 17.5 0 0 0 17.5 17.5zm0-6.364V12.39a7.957 7.957 0 0 1-7.95-7.95h-3.186A11.129 11.129 0 0 0 21.5 15.572z" transform="translate(-4 -4.44)" style={{fill: color}} />
              </g>
          </g>
      </g>
    </svg>
  )
}

export default GPSSignal