import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const S3BucketSlice = createSlice({
  name: "s3Bucket",
  initialState: {
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    restore: { status: "" },
    model: {},
    list: [],
    name: { status: "" },
    groupAssignment: { status: "" },
    groups: { status: "" },
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },

    setModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    restore(state, action) {
      const data = action.payload;
      return { ...state, restore: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
    name(state, action) {
      const data = action.payload;
      return { ...state, name: { ...data } };
    },
    groupAssignment(state, action) {
      const data = action.payload;
      return { ...state, groupAssignment: { ...data } };
    },
    groups(state, action) {
      const data = action.payload;
      return { ...state, groups: { ...data } };
    },
  },
});
export const {
  list,
  create,
  update,
  restore,
  remove,
  setModel,
  name,
  groupAssignment,
  groups,
} = S3BucketSlice.actions;
export default S3BucketSlice.reducer;

export const getList = (storageId) =>
  apiCallBegan({
    url: `storages/s3/${storageId}/bucket`,
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const checkContainerName = (storageId, query) =>
  apiCallBegan({
    url: `storages/s3/${storageId}/bucket/check?name=${query}`,
    method: "Get",
    onSpin: name.type,
    onSuccess: name.type,
    onError: name.type,
  });

export const createContainer = (storageId, data) =>
  apiCallBegan({
    url: `storages/s3/${storageId}/bucket`,
    method: "post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const deleteContainer = (storageId, Id) =>
  apiCallBegan({
    url: `storages/s3/${storageId}/bucket/${Id}`,
    method: "delete",
    onSuccess: remove.type,
    onError: remove.type,
  });

export const updateContainer = (data, storageId, containerId) =>
  apiCallBegan({
    url: `storages/s3/${storageId}/bucket/${containerId}`,
    method: "patch",
    data,
    onSuccess: update.type,
    onError: update.type,
  });

export const assignGroups = (data, storageId, containerId) =>
  apiCallBegan({
    url: `storages/s3/${storageId}/bucket/${containerId}/groups`,
    method: "patch",
    data,
    onSuccess: groupAssignment.type,
    onError: groupAssignment.type,
  });