import { BsArrowLeftRight } from 'react-icons/bs';
import { HiOutlineChevronDoubleDown, HiOutlineChevronDoubleUp } from 'react-icons/hi';
import './CollapsablePanel.sass';
import classNames from 'classnames';
import { useState } from 'react';

function CollapsablePanel({ title, children, onSwap, collapsed = false, telemetries = [], expanded=false }) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const handleCollapseClick = () => {
    setIsCollapsed(curr => !curr);
  }

  const handleSwap = () => {
    onSwap?.();
  }

  return (
    <div className={classNames("collapsable-panel", {"collapsed": isCollapsed, "expanded": expanded})}>
      <div className="title-bar">
        <div className="title" onClick={handleCollapseClick}>{title}</div>
        <div className="tools">
          {!!onSwap && <BsArrowLeftRight onClick={handleSwap} />}
          {isCollapsed ? <HiOutlineChevronDoubleDown onClick={handleCollapseClick} /> : <HiOutlineChevronDoubleUp onClick={handleCollapseClick} />}
        </div>
      </div>
      <div className="stream">
        {children}
      </div>
      {telemetries?.length > 0 && (
        <div className="telemetries">
          {telemetries?.map(item => (
            <div className="item" key={item.name} title={item.title}>
              <div className="icon">{item.icon}</div>
              <div className="value">{item.value}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CollapsablePanel