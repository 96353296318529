import Application from "../../../../ui/Application/Application";
import ApplicationAreaContainer from "../../Components/ApplicationAreaContainer/ApplicationAreaContainer";
import "./DashboardHome.sass";

import RecentDevices from "../../Components/RecentDevices/RecentDevices";
import empty from "../../../../assets/img/empty-tab.svg";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import { useEffect, useState } from "react";
import { applicationItems } from "../../../../helper/constants";
import RecentStorages from "src/components/Dashboard/Components/RecentStorages/RecentStorages";
import AdminShortcuts from "src/components/Dashboard/Components/AdminShortcuts/AdminShortcuts";
import { useHistory } from "react-router-dom";

function DashboardHome() {
  const history = useHistory();
  const [applications, setApplications] = useState([]);
  const [tabs, setTabs] = useState([]);
  const activeTabs = [
    {
      id: "recent_devices",
      title: "Devices",
      content: <RecentDevices />
    },
    {
      id: "recent_containers",
      title: "Storages",
      content: <RecentStorages />
    },
    {
      id: "admin",
      title: "Admin Center",
      content: <AdminShortcuts />
    },
    {
      id: "recent_flights-1",
      title: "Flight",
      // content: <RecentFlights />,
      active: false,
    },
    { id: "recent_missions-1", title: "Mission", active: false },
    { id: "recent_customers", title: "Customer", active: false },
  ];

  const { privileges } = usePrivileges();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (privileges.applications !== undefined) {
      setApplications(
        applicationItems.filter((item) =>
          privileges.applications.map((a) => a.id).includes(item.id)
        )
      );

      setPermissions(
        [
          ...[
            ...privileges.applications.map((item) =>
              item.privileges?.map((pr) => pr)
            ),
          ].filter((x) => x !== undefined),
        ].reduce((result, current) => result.concat(current), [])
      );
    }
  }, [privileges.applications]);

  useEffect(() => {
    setTabs(() => {
      if (!permissions.length) return [];

      return activeTabs.filter(tab => {
        return permissions.includes(tab.id) || (tab.id === 'admin' && privileges?.admins?.length > 0)
      }).map((tab, index) => (
        {
          ...tab,
          index: index,
          active: index === 0
        }
      ))
    })
  }, [permissions]);

  useEffect(() => {
    history.replace(`/dashboard/explore-app`);
  }, []);

  return (
    <div className="dashboard-home">
      <ApplicationAreaContainer
        tabs={tabs}
        tabsTitle="Quick Access"
        name="dashboard"
        emptyTab={empty}
        title="Applications"
        content={applications.map((item, key) => (
          <Application
            key={key}
            name={item.name}
            link={item.link}
            image={item.image}
            color={item.color}
          />
        ))}
        color="#004ab3"
        highlight="#004ab311"
      />
    </div>
  );
}

export default DashboardHome;
