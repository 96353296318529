import React, { useState, useMemo } from 'react'
import "./NotificationMenu.sass"
import BellIcon from './BellIcon'
import NotificationPanel from '../NotificationPanel/NotificationPanel'
import { useSelector } from 'react-redux';
import OutSideDetector from 'src/ui/OutsideDetector/OutSideDetector';
import { useTheme } from 'src/helper/ThemeProvider';
import { useAppTheme } from 'src/helper/AppThemeProvider';

function NotificationMenu() {
  const [renderNotificationPanel, setRenderNotificationPanel] = useState(false);
  const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
  const notifications = useSelector(state => state.notification.notifications);
  const notSeenNotifications = useMemo(() => notifications.filter(item => !item.seen), [notifications]);

  return (
    <div className="notification-menu">
      <OutSideDetector detect={() => setIsNotificationPanelOpen(false)}>
        <div className="bell-icon" onClick={() => {
          if (isNotificationPanelOpen)
            setIsNotificationPanelOpen(false);
          else {
            setRenderNotificationPanel(true);
            setIsNotificationPanelOpen(true);
          }
        }}>
          <BellIcon indicator={notSeenNotifications?.length} className="bell-icon" />
        </div>
        {renderNotificationPanel && (
          <NotificationPanel isOpen={isNotificationPanelOpen} onClose={() => setRenderNotificationPanel(false)} />
        )}
      </OutSideDetector>
    </div>
  )
}

export default NotificationMenu