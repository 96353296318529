const RadarFix = ({ className, size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="Layer_1" data-name="Layer 1" viewBox="0 0 230 230" width={`${size}em`} height={`${size}em`}>
      <defs>
        <radialGradient
          id="DG9QVyWX"
          cx={-830.19}
          cy={659.04}
          r={0.5}
          gradientTransform="matrix(0 30.14 32.75 0 -21466.48 25134.79)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#bebebe" />
        </radialGradient>
      </defs>
      <path d="M115.11 198.81a84.54 84.54 0 0 1-16.87-1.7 83.17 83.17 0 0 1-15.72-4.87A83.86 83.86 0 0 1 38 147.69 83.33 83.33 0 0 1 33.1 132a84.6 84.6 0 0 1 0-33.75A83.33 83.33 0 0 1 38 82.52 83.91 83.91 0 0 1 82.52 38a83.33 83.33 0 0 1 15.71-4.9 84.6 84.6 0 0 1 33.75 0 83.33 83.33 0 0 1 15.71 4.9 83.91 83.91 0 0 1 44.54 44.54 82.61 82.61 0 0 1 4.88 15.71 84.6 84.6 0 0 1 0 33.75 82.61 82.61 0 0 1-4.88 15.71 83.49 83.49 0 0 1-7.71 14.22 84.44 84.44 0 0 1-22.61 22.61 83.49 83.49 0 0 1-14.22 7.71 82.61 82.61 0 0 1-15.69 4.86 84.62 84.62 0 0 1-16.87 1.7Z" />
      <path
        d="M115.11 32.4a82.79 82.79 0 0 0-81 99.38 81.82 81.82 0 0 0 4.79 15.52 82.72 82.72 0 1 0 134.69-90.68A82.67 82.67 0 0 0 147.3 38.9a82.37 82.37 0 0 0-32.19-6.5m0-2a84.71 84.71 0 1 1-84.71 84.71 84.7 84.7 0 0 1 84.71-84.71Z"
        style={{
          fill: "#258467",
        }}
      />
      <path
        d="m115.38 115-47-67.69s18.86-14.69 47.05-14.54 46.11 14.53 46.11 14.53ZM114.84 115.65l67.68-47.06s14.69 18.87 14.55 47.06-14.55 46.16-14.55 46.16Z"
        style={{
          fill: "#4e4e4e",
        }}
      />
      <path
        d="m114.49 114.84 47.05 67.68s-18.86 14.69-47 14.55-46.16-14.55-46.16-14.55Z"
        style={{
          fill: "#4e4e4e",
        }}
      />
      <path
        d="m114.84 114.75-67.69 47.06s-14.69-18.87-14.54-47.06 14.54-46.16 14.54-46.16Z"
        style={{
          fill: "#4e4e4e",
        }}
      />
      <path
        d="M114.48 114.84v82.23a97.65 97.65 0 0 1-24.13-3.69 78.22 78.22 0 0 1-22-10.86Z"
        style={{
          fill: "#4e4e4e",
        }}
      />
      <g
        style={{
          opacity: 0.6399999856948853,
        }}
      >
        <circle
          cx={115.37}
          cy={114.64}
          r={61.13}
          style={{
            fill: "none",
          }}
        />
        <circle
          cx={115.37}
          cy={114.64}
          r={60.98}
          style={{
            fill: "none",
            stroke: "#d2d2d2",
            strokeWidth: ".30000001192092896px",
          }}
        />
      </g>
      <g
        style={{
          opacity: 0.6399999856948853,
        }}
      >
        <circle
          cx={115.37}
          cy={115.84}
          r={30.87}
          style={{
            fill: "none",
          }}
        />
        <circle
          cx={115.37}
          cy={115.84}
          r={30.72}
          style={{
            fill: "none",
            stroke: "#d2d2d2",
            strokeWidth: ".30000001192092896px",
          }}
        />
      </g>
    </svg>
  );
};

export default RadarFix;
