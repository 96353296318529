import { AiOutlineUserSwitch } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { RiShieldUserLine } from "react-icons/ri";
import { TiGroupOutline } from "react-icons/ti";
import { DeviceAdminIcon, GuestAdminIcon } from "src/ui/Icons/Icons";
import Widget from "../../../../ui/Widget/Widget";
import AdminContentAreaContainer from "../../AdminContentAreaContainer/AdminContentAreaContainer";
import "./AdminHome.sass";

function AdminHome() {
  const histories = [{ title: "Home", link: "/admin" }];
  const widgets = [
    {
      title: "Users",
      link: "/admin/users",
      linkTitle: "User Management",
      description:
        "An administrator can invite new users to the company by sending an email invitation to them.",
      icon: <FiUsers size="20" />,
    },
    {
      title: "Customers",
      linkTitle: "Customer Management",
      link: "/admin/customers",
      description:
        "For each customer, you'll see the Business Name, Business Number, Registration Date, Email, and Address associated with the engagement.",
      icon: <AiOutlineUserSwitch size="20" />,
    },
    {
      title: "Guest",
      linkTitle: "Guest Management",
      link: "/admin/guests",
      description:
        "With guest access we have democratized the way people collaborate with external users.",
      icon: <GuestAdminIcon size="20" color="#2699fb" />,
    },
    {
      title: "Roles",
      linkTitle: "Role Management",
      description:
        "As an administrator, you can change permissions to let the right people access the data they need data while restricting others.",
      link: "/admin/roles",
      icon: <RiShieldUserLine size="20" />,
    },
    {
      title: "Devices",
      linkTitle: "Device Management",
      description:
        "A key task of any Administrator is to protect and secure an organization’s resources and data on devices in their organization.",
      link: "/admin/devices",
      icon: <DeviceAdminIcon size="20" color="#2699fb" />,
    },
    {
      title: "Groups",
      linkTitle: "Group Management",
      description:
        "Groups are used for sharing different resources between users inside and your company account.",
      link: "/admin/groups",
      icon: <TiGroupOutline size="20" />,
    },
  ];

  return (
    <AdminContentAreaContainer
      histories={histories}
      title="Welcome to your account admin center"
    >
      <div className="admin-home">
        <div className="admin-widgets">
          {widgets.map((widget, index) => (
            <Widget widget={widget} key={index} />
          ))}
        </div>
      </div>
    </AdminContentAreaContainer>
  );
}

export default AdminHome;
