import { createSlice } from "@reduxjs/toolkit";

const UserServiceSlice = createSlice({
  name: "UserService",
  initialState: {
    mobileClients: [],
  },
  reducers: {
    setMobileClientInfo(state, action) {
      const data = action.payload;
      const {id, user, device} = data;

      const index = state.mobileClients.findIndex((item) => 
        (id && item.id === id) ||
        (device && item.id === device.id)
      );

      if(index !== -1)
        state.mobileClients[index] = { 
          ...state.mobileClients[index], 
          ...data,
          id: device?.id,
          updateTimestamp: new Date().getTime(),
        };
      else
        state.mobileClients.push({
          id: device?.id,
          ...data,
          updateTimestamp: new Date().getTime(),
        });

      return state;
    },
    removeMobileClient(state, action) {
      const {id, user, device} = action.payload;
      const index = state.mobileClients.findIndex((item) => 
        (id && item.id === id) ||
        (device && item.id === device.id)
      );

      if(index !== -1)
        state.mobileClients.splice(index, 1);

      return state;
    },
    removeInactiveMobileClients(state, action) {
      const {timeout} = action.payload;
      const now = new Date().getTime();

      state.mobileClients = state.mobileClients.filter((item) => 
        now - item.updateTimestamp < timeout
      );

      return state;
    }
  },
});

export const {
  removeMobileClient,
  setMobileClientInfo,
  removeInactiveMobileClients
} = UserServiceSlice.actions;

export default UserServiceSlice.reducer;