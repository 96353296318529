import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../../ui/Button/Button";
import CircleAvatar from "../../../../../../ui/CircleAvatar/CircleAvatar";
import GroupMemberItem from "../../../../../../ui/GroupMemberItem/GroupMemberItem";
import SearchableList from "../../../../../../ui/SearchableList/SearchableList";
import { toColor } from "../../../../../../helper/utils";
import {
  getGroupsDetails,
  search,
  searchGroups,
} from "../../../GroupManagement/GroupManagementSlice";
import { assignGroups } from "../../StorageContainerSlice";
import "./GroupAssignment.sass";

function GroupAssignment({ onConfirm }) {
  const storage = useSelector((state) => state.storage);
  const container = useSelector((state) => state.container);
  const group = useSelector((state) => state.group);

  const [selected, setSelected] = useState([]);
  const [groups, setGroups] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const [currentContainer, setCurrentContainer] = useState();
  const [currentStorage, setCurrentStorage] = useState();

  useEffect(() => {
    if (storage.model !== undefined) {
      setCurrentStorage(storage.model);
    }
  }, [storage.model]);

  useEffect(() => {
    if (container.model !== undefined) {
      setCurrentContainer(container.model);
    }
  }, [container.model]);

  useEffect(() => {
    if (
      group.details !== undefined &&
      group.details?.status === "success" &&
      group.details?.data?.groups !== undefined
    ) {
      setSelected([
        ...group.details?.data?.groups.map((item) => ({
          id: item.id,
          title: item.name,
          subtitle: item.description,
          group: true,
          icon: (
            <CircleAvatar
              name={item.name.substring(0, 2).toUpperCase()}
              fontSize=".9em"
              size="2.25"
              color={`#${toColor(item.name)}`}
            />
          ),
        })),
      ]);
    }
  }, [group.details]);

  useEffect(() => {
    if (
      group.search !== undefined &&
      group.search.status === "success" &&
      group.search.data?.groups !== undefined
    ) {
      setData([
        ...group.search.data?.groups
          .filter((x) => !selected.map((item) => item.id).includes(x.id))
          .map((item) => ({
            id: item.id,
            title: item.name,
            subtitle: item.description,
            active: false,
          })),
        // ...data.filter(
        //   (x) => !groupSearchResult.groups.map((item) => item.id).includes(x.id)
        // ),
      ]);
      dispatch(search({ status: "" }));
    }
  }, [group.search]);

  useEffect(() => {
    if (container.assignGroups?.status === "success") onConfirm?.();
  }, [container.assignGroups]);

  const onSelectChange = (item) => {
    if (!selected.map((x) => x.id).includes(item.id))
      setSelected([
        ...selected.filter((x) => x.id !== item.id),
        { ...item, group: true },
      ]);
    else setSelected([...selected.filter((x) => x.id !== item.id)]);

    setData([
      ...data.map((s) =>
        s.id !== item.id ? { ...s } : { ...s, active: !s.active }
      ),
    ]);
  };

  const typeChangeHandler = (item) => {
    switch (item.type) {
      case "Remove":
        setSelected([...selected.filter((x) => x.id !== item.member.id)]);
        setData([
          ...data.map((s) =>
            s.id !== item.member.id ? { ...s } : { ...s, active: false }
          ),
        ]);
        break;
      case "Group":
        setSelected([
          ...selected.map((s) =>
            s.id !== item.member.id ? { ...s } : { ...s, group: true }
          ),
        ]);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setGroups(selected.filter((s) => s.group === true).map((z) => z.id));
  }, [selected]);

  useEffect(() => {
    if (currentContainer?.groups !== undefined)
      dispatch(getGroupsDetails(currentContainer.groups));
  }, [currentContainer?.groups]);

  const submitHandler = () => {
    if (currentContainer !== undefined && currentStorage !== undefined) {
      dispatch(
        assignGroups({ groups }, currentStorage.id, currentContainer.id)
      );
    }
  };

  return (
    <div className="storage-group-assignment-form">
      <SearchableList
        onDataChange={onSelectChange}
        minLength={1}
        tag="group/search"
        searchHandler={searchGroups}
        feed={data}
        title="Search for groups(s)"
        searchCaption="Selected group(s)"
      />
      <p>
        Groups | <span>(total {groups.length} group selected)</span>
      </p>
      <div className="group-assignment-items-container">
        <ul className="group-assignment-items">
          {selected
            .filter((s) => s.group === true)
            .map((item, index) => (
              <li className="group-assignment-item" key={index}>
                <GroupMemberItem
                  member={item}
                  types={["Group", "Remove"]}
                  onTypeChange={typeChangeHandler}
                  defaultMembership="Group"
                />
              </li>
            ))}
        </ul>
      </div>

      <Button
        value="Confirm"
        color="#2699fb"
        width="30%"
        onClick={submitHandler}
        disabled={groups.length === 0}
        className="confirm-button"
      />
    </div>
  );
}

export default GroupAssignment;
