import { useEffect, useState } from "react";
import { FaUserEdit, FaUserLock, FaUserPlus } from "react-icons/fa";
import { IoMdKey } from "react-icons/io";
import { MdDeleteForever, MdGroupAdd, MdRefresh } from "react-icons/md";
import { RiEditCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import AdminContentAreaContainer from "../../AdminContentAreaContainer/AdminContentAreaContainer";
import AddGuestRoles from "./AddGuestRoles/AddGuestRoles";
import ChangeGuestPassword from "./ChangeGuestPassword/ChangeGuestPassword";
import CreateGuest from "./CreateGuest/CreateGuest";
import CreateGuestSummary from "./CreateGuestSummary/CreateGuestSummary";
import EditGuest from "./EditGuest/EditGuest";
import EditGuestOptions from "./EditGuestOptions/EditGuestOptions";
import "./GuestManagement.sass";
import { getList, create, removeGuests } from "./GuestManagementSlice";
import ManageGuestRoles from "./ManageGuestRoles/ManageGuestRoles";

function GuestManagement() {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api.state);
  const guest = useSelector((state) => state.guest);
  const { privileges } = usePrivileges();
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined) {
      if (
        privileges.admins.filter((item) => item.id === "guests")[0] !==
        undefined
      )
        setPermissions([
          ...privileges.admins.filter((item) => item.id === "guests")[0]
            ?.privileges,
        ]);
    }
  }, [privileges.admins]);

  const toggleOperationManagerOff = () => {
    setShowOperationManager(false);
  };
  const toggleManagerOperationOn = () => {
    setShowOperationManager(true);
    setOperationManagerHandler(true);
  };

  const removeGuestsGenerator = (data) => {
    dispatch(removeGuests({ ids: data.map((i) => i.id) }));
  };
  const refreshGenerator = () => {
    dispatch(getList());
  };

  useEffect(() => {
    if (api !== undefined) dispatch(getList());
  }, [api]);

  useEffect(() => {
    if (guest.list.data !== undefined) {
      const guests = [];
      guest.list.data.users?.map((item) =>
        guests.push({ ...item, active: false })
      );
      setRows({
        ...rows,
        values: [...guests],
      });
    }
  }, [guest.list]);

  useEffect(() => {
    if (guest.remove.status === "success") {
      dispatch(getList());
    }
  }, [guest.remove]);

  useEffect(() => {
    if (guest.create.status === "success") {
      dispatch(getList());
      dispatch(create({ status: "" }));
    }
  }, [guest.create]);

  useEffect(() => {
    if (guest.update.status === "success") {
      dispatch(getList());
    }
  }, [guest.update]);

  const histories = [
    { title: "Home", link: "/admin/home" },
    { title: "Guests", link: "/admin/guests" },
  ];

  const [features, setFeatures] = useState([]);
  const [options, setOptions] = useState([]);

  const dataOptions = [
    {
      title: "Delete Guest",
      icon: <MdDeleteForever />,
      name: "delete_guest",
      except: "account",
      type: "dependent",
      active: false,
    },
    {
      title: "Edit Guest",
      icon: <RiEditCircleLine />,
      name: "edit_guest",
      active: true,
    },
    {
      title: "Reset Password",
      icon: <IoMdKey style={{ transform: "rotate(45deg)" }} />,
      name: "reset_pass",
      type: "dependent",
      singular: true,
      active: true,
    },
    {
      title: "Manage Guest Roles",
      icon: <IoMdKey style={{ transform: "rotate(45deg)" }} />,
      name: "manage_guest_roles",
      active: true,
    },
  ];
  const guestFeatures = [
    {
      id: "create_guest",
      title: "Add Guest",
      description: "You can create a guest under your company account",
      icon: <FaUserPlus />,
      color: "#2699fb",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "create_guest",
          title: "Create",
          active: true,
          visible: true,
          locked: false,
          activity: (
            <CreateGuest
              NextStep={
                permissions.includes("manage_guest_roles")
                  ? "manage_guest_roles"
                  : "guest_summary"
              }
              onOpen={toggleManagerOperationOn}
            />
          ),
        },
        {
          name: "manage_guest_roles",
          title: "Add Guest Roles",
          active: false,
          visible: true,
          locked: true,
          tags: ["guest/rolesList"],
          activity: (
            <AddGuestRoles
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
        {
          name: "guest_summary",
          title: "Summary",
          active: false,
          visible: true,
          locked: true,
          tags: ["guest/create"],
          activity: (
            <CreateGuestSummary onConfirm={toggleOperationManagerOff} />
          ),
        },
      ],
    },
    {
      id: "delete_guest",
      title: "Delete Guest",
      confirm: "Are you sure for deleting selected guest?",
      description: "You can delete a guest under your company account",
      type: "dialog",
      actionGenerator: removeGuestsGenerator,
    },
    {
      id: "list_guests",
      type: "no-op",
      actionGenerator: refreshGenerator,
    },
    {
      id: "edit_guest",
      title: "Edit Guest",
      description: "You can edit a guest under your company account",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_guest",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["guest/update", "guest/rolesList", "guest/roles", "guest/assignRoles"],
          activity: (
            <EditGuestOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
    {
      id: "manage_guest_roles",
      title: "Edit Guest",
      description: "You can edit a guest under your company account",
      icon: <FaUserEdit />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_guest",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["guest/update", "guest/rolesList", "guest/roles", "guest/assignRoles"],
          activity: (
            <EditGuestOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
              defaultIndex={1}
            />
          ),
        },
      ],
    },
    {
      id: "reset_pass",
      title: "Change User Password",
      description: "You can change password of selected guest",
      icon: <FaUserLock />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "reset_pass",
          title: "Change Password",
          active: true,
          visible: true,
          locked: false,
          tags: ["guest/password"],
          activity: (
            <ChangeGuestPassword
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
  ];
  const guestOptions = [
    {
      title: "Add Guest",
      icon: <MdGroupAdd />,
      name: "create_guest",
      active: true,
    },
    {
      title: "Refresh",
      icon: <MdRefresh />,
      name: "list_guests",
      active: true,
    },
    {
      title: "Delete Guest(s)",
      icon: <MdDeleteForever />,
      name: "delete_guest",
      type: "dependent",
      except: "account",
      active: false,
    },
    {
      title: "Edit Guest",
      icon: <RiEditCircleLine />,
      name: "edit_guest",
      type: "dependent",
      singular: true,
      active: true,
    },
    {
      title: "Reset Password",
      icon: <IoMdKey style={{ transform: "rotate(45deg)" }} />,
      name: "reset_pass",
      type: "dependent",
      singular: true,
      active: true,
    },
    {
      title: "Manage Guest Roles",
      icon: <IoMdKey style={{ transform: "rotate(45deg)" }} />,
      name: "manage_guest_roles",
      singular: true,
      type: "dependent",
      active: true,
    },
  ];

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Display Name", value: "displayName", status: true },
      { title: "First Name", value: "firstName", status: true },
      { title: "Last Name", value: "lastName", status: true },
      { title: "Email Address", value: "emailAddress", status: true },
      { title: "Customer Name", value: "customerName", status: true },
      { title: "Last Login", value: "lastLogin", status: false },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    setFeatures([
      ...guestFeatures
        .filter((feature) => permissions.includes(feature.id))
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        })),
    ]);
    setOptions([
      ...guestOptions.filter((option) => permissions.includes(option.name)),
    ]);
    setRows({
      ...rows,
      options: dataOptions.filter((option) =>
        permissions.includes(option.name)
      ),
    });
  }, [permissions]);

  const [currentFeature, setCurrentFeature] = useState();
  const [currentFeatureLabel, setCurrentFeatureLabel] = useState("edit_guest");
  const onFeatureChanged = (name) => {
    if (["create_guest", "list_guests", "delete_guest"].includes(name)) return;
    setCurrentFeatureLabel(name);
    setCurrentFeature(
      guestFeatures
        .filter((feature) => feature.id === name)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
  };
  const onRowChange = () => {
    setCurrentFeature(
      guestFeatures
        .filter((feature) => feature.id === currentFeatureLabel)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
    toggleManagerOperationOn();
  };

  return (
    <AdminContentAreaContainer
      histories={histories}
      title="Guest Management"
      data={rows}
      features={[...features]}
      operations={[...options]}
      table={{
        multiSelect: true,
        tags: ["guest/list", "guest/remove"],
        show: true,
      }}
      operationManager={showOperationManager}
      managerHandler={operationManagerHandler}
      onRowChange={onRowChange}
      currentFeature={currentFeature}
      onCurrentFeatureChanged={onFeatureChanged}
    />
  );
}

export default GuestManagement;
