import './MissionInfoPanel.sass'
import CollapsablePanel from '../../../common/CollapsablePanel/CollapsablePanel';
import Progress from 'src/hci/common/Progress/Progress';
import Badge from 'src/hci/common/Badge/Badge';
import Button from 'src/hci/common/Button/Button';
import {TbPlayerPauseFilled, TbPlayerPlayFilled, TbPlayerStopFilled } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import { removeMission } from 'src/services/mission/MissionServiceSlice';
import { MissionStatus, MissionType, NotActiveMissionStatusSet } from 'src/services/mission/common/missionConstants';
import classNames from 'classnames';
import { MeasurementSystem, MeasurementType, deviceTypes } from 'src/helper/constants';
import useCurrentUserId from 'src/helper/useCurrentUserId';
import useMeasurement from 'src/helper/useMeasurement';

export default function MissionInfoPanel({ controller, onClose }) {
  const { convertValue, getMeasurementUnit } = useMeasurement();
  const userId = useCurrentUserId();

  const handleStop = () => {
    controller.stopMission();
  }

  return (
    <div className="mission-info-panel">
      <CollapsablePanel title="Mission Observer">
        <div className="mission-details">
          <div className="mission-header">
            <div className="name">{controller.details?.name}</div>
            <Badge color="brown">{controller.details?.status?.toUpperCase()}</Badge>
          </div>
          <div className="status">{controller.details?.statusDescription}</div>
          {controller.details?.status === MissionStatus.EXECUTING && !!Number(controller.progress?.percent) && (
            <div className="progress">
              <Progress value={controller.progress?.percent || 0} />
            </div>
          )}
          <div className={classNames("mission-info", {'no-data': !controller.details?.speed || controller.details?.status !== MissionStatus.EXECUTING})}>
            <div className="item">
              <div className="label">Duration</div>
              <div className="value">{controller.progress?.totalTime || '-'} sec</div>
            </div>
            <div className="item">
              <div className="label">Total Distance</div>
              <div className="value">
                {Number(convertValue(controller.progress?.totalDistance, MeasurementType.DISTANCE, MeasurementSystem.METRIC)).toFixed(2) || '-'} &nbsp;
                {getMeasurementUnit(MeasurementType.DISTANCE)}
              </div>
            </div>
            <div className="item">
              <div className="label">Speed</div>
              <div className="value">
                {Number(convertValue(controller.details?.speed, MeasurementType.SPEED, MeasurementSystem.METRIC)).toFixed(1) || '-'} &nbsp;
                {getMeasurementUnit(MeasurementType.SPEED)}
              </div>
            </div>
            <div className="item">
              <div className="label">ETA</div>
                <div className="value">{controller.progress?.remainingTime || '-' } sec</div>
              </div>
            <div className="item">
              <div className="label">Rem. Distance</div>
              <div className="value">
                {Number(convertValue(controller.progress?.remainingDistance, MeasurementType.DISTANCE, MeasurementSystem.METRIC)).toFixed(2) || '-'} &nbsp;
                {getMeasurementUnit(MeasurementType.DISTANCE)}
              </div>
            </div>
            <div className="item">
              <div className="label">Altitude</div>
              <div className="value">
                {Number(convertValue(controller.details?.altitude, MeasurementType.ALTITUDE, MeasurementSystem.METRIC)).toFixed(1) || '-'} &nbsp;
                {getMeasurementUnit(MeasurementType.ALTITUDE)}
              </div>
            </div>
          </div>
          <div className={classNames("mission-actions", { hidden: NotActiveMissionStatusSet.includes(controller.details?.status) })}>
            <Button 
              onClick={handleStop} 
              color="red" 
              size="1"
              disabled={[...NotActiveMissionStatusSet, MissionStatus.PREPARING, MissionStatus.RETURNING].includes(controller.details?.status)}>
              <TbPlayerStopFilled className='icon' /> Stop
            </Button>
            <Button onClick={controller.pauseMission} size="1" disabled={!controller.pauseMission || controller.details?.status !== MissionStatus.EXECUTING}>
              <TbPlayerPauseFilled className='icon' /> Pause
            </Button>
            <Button onClick={controller.resumeMission} size="1" disabled={!controller.resumeMission || controller.details?.status !== MissionStatus.PAUSED}>
              <TbPlayerPlayFilled className='icon' /> Resume
            </Button>
          </div>
        </div>
      </CollapsablePanel>
    </div>
  )
}
