import HubConnection, { HubName, HubResponseSources } from "./HubConnection";
import { generateId, random } from "./utils";
class HubConnectionManager {
  connections = {};
  hub;
  instance;
  serverMessages = [];
  systemMessages = [];
  groupMessages = [];
  generalMessages = [];

  constructor(hub) {
    this.hub = hub;
  }
  static GetInstance(hub) {
    if (this.instance && this.hub === hub) return this.instance;
    this.instance = new HubConnectionManager(hub);
    return this.instance;
  }

  create(
    url,
    onReady,
    onRetry = null,
    onRetryFails = null,
    onPoorConnection = null
  ) {
    const currConnection = this.connections[url];

    if (currConnection) {
      onReady();
      return currConnection;
    }

    return (this.connections[url] = new HubConnection(
      url,
      onReady,
      this.systemMessageHandler,
      this.messageHandler,
      this.systemMessageHandler,
      this.handleOnConnectionClose,
      onRetry,
      onRetryFails,
      onPoorConnection
    ));
  }

  getConnectionById(id) {
    let result;

    Object.entries(this.connections).forEach(([, value]) => {
      if (
        value?.connectionInfo?.userId === id && 
        value?.instance?.readyState === WebSocket.OPEN
      ) result = value;
    });

    return result;
  }

  getConnectionId(id) {
    let result;
    Object.entries(this.connections).forEach(([, value]) => {
      if (value?.connectionInfo?.userId === id || id === false)
        result = value?.connectionInfo?.connectionId;
    });
    return result;
  }

  getConnectionHub(id) {
    let result;
    Object.entries(this.connections).forEach(([, value]) => {
      if (value?.connectionInfo?.userId === id)
        result = value?.connectionInfo?.hub;
    });
    return result;
  }

  createMessage(identity, message) {
    const connection = this.getConnectionById(identity);
    if (connection) connection.sendMessage(message);
  }

  sendEvent = (identity, name, data) => {
    this.createMessage(identity, {
      type: "event",
      event: name,
      data,
    });
  };

  joinGroup = (identity, group) => {
    this.createMessage(identity, {
      type: "joinGroup",
      group,
    });
  };

  leaveGroup = (identity, group) => {
    this.createMessage(identity, {
      type: "leaveGroup",
      group,
    });
  };

  sendToGroup = (identity, group, data) => {
    this.createMessage(identity, {
      type: "sendToGroup",
      group,
      data: data,
    });
  };

  sendToDjiCloudGroup = (identity, group, data) => {
    const finalData = {
      ...data,
      timestamp: data.timestamp || Date.now(),
      bid: data.bid || generateId(),
      tid: data.tid || generateId(),
    };

    this.createMessage(identity, {
      type: "sendToGroup",
      group,
      data: finalData,
    });

    return finalData;
  };

  subscribeSystemMessages = (handlers, source) => {
    const ids = [];
    handlers.forEach((handler) => {
      if (handler?.identity) {
        const id = generateId(
          `${handler?.name}-${source}-${handler?.identity}`
        );
        if (!ids.includes(id)) ids.push(id);
        if (!this.systemMessages.find((sm) => sm.id === id))
          this.systemMessages.push({ id, ...handler });
      }
    });
    return ids;
  };

  unsubscribeSystemMessages = (ids) => {
    this.systemMessages = this.systemMessages.filter(
      (messageHandler) => !ids.includes(messageHandler.id)
    );
  };

  subscribeServerMessages = (handlers, source) => {
    const ids = [];
    handlers.forEach((handler) => {
      // if (handler?.identity) {
      //   const id = generateId(
      //     `${handler?.name}-${source}-${handler?.identity}`
      //   );
      //   if (!ids.includes(id)) ids.push(id);
      //   if (!this.serverMessages.find((sm) => sm.id === id))
      //     this.serverMessages.push({ id, ...handler });
      // }
      const id = generateId();
      ids.push(id);
      this.serverMessages.push({ id, ...handler });
    });
    return ids;
  };

  unsubscribeServerMessages = (ids) => {
    this.serverMessages = this.serverMessages.filter(
      (messageHandler) => !ids.includes(messageHandler.id)
    );
  };

  subscribeMessages = (handlers, source) => {
    const ids = [];
    handlers?.forEach((handler) => {
      const id = generateId();
      ids.push(id);
      this.generalMessages.push({ id, handler });
    });
    return ids;
  };

  unsubscribeMessages = (ids) => {
    this.generalMessages = this.generalMessages.filter(
      (messageHandler) => !ids.includes(messageHandler.id)
    );
  };

  subscribeGroupMessages = (handlers, source) => {
    const ids = [];
    handlers.forEach((handler) => {
      if (handler?.identity) {
        let id;

        if(handler.identity.includes('/*')) {
          id = source + '-' + handler.identity.split('/')?.[0];
        }
        else
          id = generateId();

        ids.push(id);
        this.groupMessages.push({ id, ...handler });  
      }
    });
    return ids;
  };

  unsubscribeGroupMessages = (ids) => {
    ids = Array.isArray(ids) ? ids : [];

    this.groupMessages = this.groupMessages.filter(
      (messageHandler) => !ids.includes(messageHandler.id)
    );
  };

  messageHandler = (message) => {
    let handlers = [];

    if (message.from === HubResponseSources.SERVER) {
      handlers = this.serverMessages?.filter(
        (handler) =>
          handler.name === message?.data?.action &&
          (!handler.identity || handler.identity === message?.data?.userId) &&
          (!handler.platform || handler.platform === message?.data?.platform) &&
          (!handler.corelationId ||
            handler.corelationId === message?.data?.corelationId)
      );
    }

    if (message.from === HubResponseSources.GROUP) {
      handlers = this.groupMessages?.filter(
        (handler) => {
          if (Array.isArray(handler.name))
            return handler.name.includes(message?.group) || (handler.identity.includes('/*') && handler.name.find((name) => message?.group.includes(name)));
          else
            return handler.name === message?.group;
        }
      );
    }

    const messageParts = message?.group?.split("/");
    let messageMeta = null;

    if(Array.isArray(messageParts)) {
      messageMeta = messageParts?.length <= 1 ? message : {
        groupName: messageParts[messageParts.length - 1],
        serialNumber: messageParts[messageParts.length - 2],
        ...message
      };
    }

    handlers.forEach((item) => {
      item.handler?.(message?.data, messageMeta);
    });

    this.generalMessages.forEach((handler) => {
      handler.handler?.(message);
    });
  };

  systemMessageHandler = (message) => {
    const events = this.systemMessages?.filter(
      (handler) =>
        handler.name === message?.event &&
        handler.identity === message?.userId?.split("_")[0]
    );
    events.forEach((event) => {
      event.handler?.(message);
    });
  };

  closeConnection(identity, forceClose = true) {
    const connection = this.getConnectionById(identity);
    if (
      connection &&
      connection?.instance.readyState === connection?.instance.OPEN
    ) {
      connection.forceClose = forceClose;
      setTimeout(() => {
        connection.instance?.close();
      }, 1000);
    }
  }

  handleOnConnectionClose = (event) => {
    console.log("closing instance", event?.target.url);
    delete this.connections[event?.target?.url];
  };
}
export const frontendConnectionManager =
  HubConnectionManager.GetInstance("frontend");
export const onboardConnectionManager = HubConnectionManager.GetInstance(
  HubName.ONBOARD
);
export const dockConnectionManager = HubConnectionManager.GetInstance(
  HubName.DOCK
);
export const mobileConnectionManager = HubConnectionManager.GetInstance(
  HubName.MOBILE
);