import "./FlightDashboardContainer.sass";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import DesktopSize from "../../../../ui/DesktopSize/DesktopSize";
import TabletSize from "../../../../ui/TabletSize/TabletSize";
import ApplicationContainer from "../../Components/ApplicationContainer/ApplicationContainer";
import FlightController from "../../ApplicationsArea/FlightController/FlightController";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { droneModel } from "../../ApplicationsArea/DeviceApp/OnboardDeviceApp/OnboardDeviceAppSlice";
import { SerialNumberProvider } from "../../../../helper/SerialNumberProvider";
import WebsocketHubProvider from "../WebsocketHubProvider/WebsocketHubProvider";
import { onboardConnectionManager } from "src/helper/HubConnectionManager";

function FlightDashboardContainer() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [drone, setDrone] = useState({});

  useEffect(() => {
    const locations = location.pathname.split("/");
    setDrone({
      name: locations[locations.length - 3],
      id: locations[locations.length - 2],
      serialNumber: locations[locations.length - 1],
    });
  }, [location]);

  const histories = [
    { title: "Device Application", link: "/dashboard/device-app" },
    {
      title: "DJI Controller",
      link: `/dashboard/device-app/onboard/list/${drone?.name}/${drone?.id}/${drone?.serialNumber}`,
    },

    {
      title: `${drone?.name} | ${drone?.serialNumber}`,
      link: location,
    },
  ];

  const flight = useSelector((state) => state.flight);
  const [fullscreenToggle, setFullscreenToggle] = useState(false);

  useEffect(() => {
    setFullscreenToggle(flight.fullscreen);
  }, [flight]);

  useEffect(() => {
    if (drone?.id) {
      dispatch(droneModel(drone));
    }
  }, [drone]);

  return (
    <WebsocketHubProvider hub={onboardConnectionManager}>
      <TabletSize>
        <div className="flight-dashboard-container-fullscreen">
          <SerialNumberProvider>
            <FlightController />
          </SerialNumberProvider>
        </div>
      </TabletSize>
      <DesktopSize>
        <ApplicationContainer histories={histories}>
          <div
            className={
              fullscreenToggle
                ? "flight-dashboard-container-fullscreen"
                : "flight-dashboard-container"
            }
          >
            <SerialNumberProvider>
              <FlightController />
            </SerialNumberProvider>
          </div>
        </ApplicationContainer>
      </DesktopSize>
    </WebsocketHubProvider>
  );
}

export default FlightDashboardContainer;
