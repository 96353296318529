import './EditRoleOptions.sass'

import React, { useEffect, useMemo } from 'react'
import { usePrivileges } from 'src/helper/AccountStateProvider';
import Tabs from 'src/ui/Tabs/Tabs';
import RoleInformation from '../RoleInformation/RoleInformation';
import RoleAssignment from '../RoleAssignment/RoleAssignment';
import ManageRolePermissions from '../ManageRolePermissions/ManageRolePermissions';

function EditRoleOptions({ onOpen, onConfirm, defaultIndex }) {
  const { privileges } = usePrivileges();
  const permissions = useMemo(() => privileges?.admins.find((item) => item.id === "roles")?.privileges, [privileges]);
  const hasPermission = (id) => permissions?.includes(id);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className="edit-role-options">
      <Tabs
        defaultIndex={defaultIndex}
        items={[
          {
            title: "Information",
            content: <RoleInformation />,
            hidden: !hasPermission('role_info')
          },
          {
            title: "Role Assignment",
            content: <RoleAssignment />,
            hidden: !hasPermission('manage_role_users')
          },
          {
            title: "Permission Assignment",
            content: <ManageRolePermissions onConfirm={onConfirm} />,
            hidden: !hasPermission('assign_role_permissions')
          },
        ]}
      />
    </div>
  )
}

export default EditRoleOptions