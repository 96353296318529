import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../../../helper/use-debounce";
import Form from "../../../../../ui/Form/Form";
import InputBox from "../../../../../ui/InputBox/InputBox";
import "./CreateGroup.sass";
import {
  create,
  setModel,
  checkName,
  createGroup,
} from "../GroupManagementSlice";

function CreateGroup({ onOpen, onConfirm }) {
  const [icons, setIcons] = useState({ Name: "default" });
  const [validate, setValidate] = useState(false);
  const [alerts, setAlerts] = useState({});
  const group = useSelector((state) => state.group);
  const [data, setData] = useState(group.model);
  const [required, setRequired] = useState(["Name"]);
  const [Name, setName] = useState("");
  const DebouncedName = useDebounce(Name, 1000);

  const dispatch = useDispatch();

  useEffect(() => {
    if (DebouncedName) {
      dispatch(checkName(Name));
    }
  }, [DebouncedName]);

  useEffect(() => {
    onOpen();
  }, [data]);

  useEffect(() => {
    console.log(group.name);
    if (group.name.status === "forbidden") {
      setAlerts({
        ...alerts,
        Name: {
          alert: true,
          type: "error",
          message: group.name.message.error,
        },
      });
    }
    setIcons({ ...icons, Name: group.name.status });
    setValidate(group.name.status === "success");
  }, [group.name]);

  useEffect(() => {
    if (group.create.status === "validation") {
      let validations = {};
      group.create.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    } else if (group.create.status === "success") {
      onConfirm();
    }
  }, [group.create]);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    setAlerts({ ...alerts, [key]: { alert: false } });

    if (key === "Name") {
      setIcons({ ...icons, Name: "default" });
      setName(value);
      setValidate(false);
    }

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  };
  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(create({ status: "" }));
    dispatch(createGroup({ ...data, members: [], owners: [] }));
  };

  return (
    <div className="create-group-form">
      <Form
        submit={{
          title: "Confirm",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        className="create-group-container"
        validate={validate}
      >
        <InputBox
          title="Name"
          name="Name"
          isRequired={required.includes("Name")}
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Name}
          value={data.Name}
          index={2}
          icon={icons.Name}
          background="white"
          type="text"
        />

        <InputBox
          title="Description"
          name="Description"
          isRequired={required.includes("Description")}
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Description}
          value={data.Description}
          index={1}
          background="white"
        />
      </Form>
    </div>
  );
}

export default CreateGroup;
