import { BiChevronRight } from "react-icons/bi";
import { Link } from "react-router-dom";
import "./Widget.sass";

function Widget({ widget }) {
  return (
    <div className="widget">
      <span className="widget-icon">{widget.icon}</span>
      <span className="widget-title">{widget.title}</span>
      <p className="widget-description">{widget.description}</p>
      <Link className="widget-link" to={widget.link}>
        {widget.linkTitle} <BiChevronRight size="1.5em" />
      </Link>
    </div>
  );
}

export default Widget;
