import "./StorageHome.sass";
import { useEffect, useState } from "react";
import { usePrivileges } from "../../../../../helper/AccountStateProvider";
import Application from "../../../../../ui/Application/Application";
import TabBar from "../../../../../ui/TabBar/TabBar";
import emptyTab from "../../../../../assets/img/storage-empty.svg";
import containerApp from "../../../../../assets/img/container-app.svg";
import { useTheme } from "../../../../../helper/ThemeProvider";
import { storageApps } from "../../../../../helper/constants";
import RecentStorages from "src/components/Dashboard/Components/RecentStorages/RecentStorages";
import { Heading } from "@radix-ui/themes";

function StorageHome() {
  const { privileges } = usePrivileges();
  const { currentContext } = useTheme();

  const [permissions, setPermissions] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [apps, setApps] = useState([]);

  const activeTabs = [
    {
      id: "recent_containers",
      title: "Storages",
      content: <RecentStorages />
    },
  ];

  useEffect(() => {
    if (privileges.applications !== undefined) {
      if (
        privileges.applications.filter((item) => item.id === "storage")[0] !==
        undefined
      )
        setPermissions([
          ...privileges.applications.filter((item) => item.id === "storage")[0]
            ?.privileges,
        ]);

      setApps([
        ...storageApps.filter((app) =>
          privileges.applications.map((a) => a.id).includes(app.id)
        ),
      ]);
    }
  }, [privileges.applications]);

  useEffect(() => {
    setTabs(
      [...activeTabs.filter((tab) => permissions.includes(tab.id))].map(
        (tab, index) => ({ ...tab, index: index, active: index === 0 })
      )
    );
  }, [permissions]);

  return (
    <div className="storage-home-container">
      <div className="storage-home-header">
        <Heading
          className="storage-home-header-title"
          style={{ borderBottom: "2px solid" + currentContext.theme.color }}
          size="3"
        >
          Storage Application
        </Heading>
        <ul className="storage-home-header-content">
          {apps?.map((app, index) => (
            <li key={index}>
              <Application
                key={1}
                name={app.name}
                link={app.link}
                image={containerApp}
                color={currentContext.theme.color}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="storage-home-body">
        {tabs.length > 0 && (
          <TabBar
            title="Quick Access"
            tabs={tabs}
            theme={currentContext.theme.color}
            empty={emptyTab}
            separator={false}
          />
        )}
      </div>
    </div>
  );
}

export default StorageHome;
