import classNames from 'classnames'
import { useState } from 'react';
import { FiCloudOff } from 'react-icons/fi';
import Loading from 'src/ui/Loading/Loading';
import './SideListPanel.sass'
import SideListItem from './SideListItem/SideListItem';
import { CgChevronDoubleLeft, CgChevronDoubleRight } from 'react-icons/cg';
import SidePanel from '../SidePanel/SidePanel';
import { TbBrandDatabricks } from 'react-icons/tb';

function SideListPanel({ panelTitle, className, listItems, onListItemClick, onCollapse, loading, collapsed, actions = [], activeIds = [] }) {
  return (
    <div className={classNames("side-list-panel", className)}>
      <SidePanel {...{panelTitle, onCollapse, loading, collapsed, actions}}>
        {listItems?.length ? (
          <div className="list-container">
            {listItems.map(item => (
              <div
                className={classNames("list-item-container", { 'active': activeIds.includes(item.id) })}
                key={item.id}
                onClick={() => onListItemClick?.(item.id)}
              >
                {item.content || (
                  <SideListItem itemData={item} />
                )}
              </div>
            ))}
          </div>
        ) : !loading ? (
          <div className="status-message">
            <TbBrandDatabricks className="icon" />
            <div className="message">Empty list</div>
          </div>
        ) : (
          <Loading />
        )}
      </SidePanel>
    </div>
  )
}

export default SideListPanel