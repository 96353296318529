import React, { createContext, useContext, useEffect, useState } from "react";

export const AppThemeNames = {
  DARK: 'dark',
  LIGHT: 'light'
}
const AppThemeContext = createContext({});

export const AppThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(AppThemeNames.DARK);

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
    document.body.classList.remove('dark-theme');
    document.body.classList.remove('light-theme');
    document.body.classList.add(`${theme}-theme`);
  }, [theme]);

  return (
    <AppThemeContext.Provider value={[theme, setTheme]}>
      {children}
    </AppThemeContext.Provider>
  );
};
export const useAppTheme = () => {
  const context = useContext(AppThemeContext);
  if (context === undefined) {
    throw new Error('useAppTheme must be used within a AppThemeProvider');
  }
  return context
};
