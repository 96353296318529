import './MissionFlightDashboard.sass'
import { useDispatch, useSelector } from 'react-redux';
import WebsocketHubProvider from 'src/components/Dashboard/Components/WebsocketHubProvider/WebsocketHubProvider';
import { onboardConnectionManager } from 'src/helper/HubConnectionManager';
import { SerialNumberProvider } from 'src/helper/SerialNumberProvider'
import FlightController from 'src/components/Dashboard/ApplicationsArea/FlightController/FlightController';
import { addToPermissionGroups, removeFromPermissionGroups } from 'src/components/Dashboard/Components/WebsocketHandler/WebsocketSlice';
import { useEffect } from 'react';

export default function MissionFlightDashboard({ deviceId }) {
  const dispatch = useDispatch();
  const deviceInfo = useSelector((state) => state.deviceService.devices?.find(item => item.id === deviceId));
  const onboardConnection = useSelector(state => state.deviceService.onboardConnections?.find(item => item.deviceId === deviceId));

  useEffect(() => {
    if(onboardConnection?.group)
      dispatch(addToPermissionGroups({
        [deviceId]: onboardConnection.group,
      }));

    return () => {
      dispatch(removeFromPermissionGroups(deviceId));
    }
  }, [onboardConnection]);

  return (
    <div className="mission-flight-dashboard">
      <WebsocketHubProvider hub={onboardConnectionManager}>
        <SerialNumberProvider 
          externalDeviceId={deviceId} 
          externalSerialNumber={deviceInfo?.serialNumber}
        >
          <FlightController integratedMode={true} />
        </SerialNumberProvider>
      </WebsocketHubProvider>
    </div>
  )
}
