import { createSlice } from "@reduxjs/toolkit";

const TableSlice = createSlice({
  name: "table",
  initialState: {
    row: {},
  },
  reducers: {
    SetCurrentRow(state, action) {
      const data = action.payload;
      return { ...state, row: data };
    },
  },
});
export const { SetCurrentRow } = TableSlice.actions;
export default TableSlice.reducer;
