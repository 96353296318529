import classNames from 'classnames';
import { Button as RadixButton } from '@radix-ui/themes'
import './Button.sass';
import { ButtonProps } from '@radix-ui/themes/dist/cjs/components/button';
import React from 'react';
import { OrbitProgress, ThreeDot } from 'react-loading-indicators';

const Button = React.forwardRef(({ prefix, postfix, className, loading, children, ...props}: ButtonProps & { prefix?: React.ReactNode, postfix?: React.ReactNode, loading: Boolean }, forwardRef) => {
  return (
    <RadixButton className={classNames('ir-button', className)} ref={forwardRef} {...props}>
      { loading ? <div className='loading-container'>
        <ThreeDot size="small" color="#fff" text="" textColor="" />
      </div> : children }
    </RadixButton>
  )
});

export default Button