import DropdownMenu from 'src/hci/common/DropdownMenu/DropdownMenu';
import './CloudVideoQualityDropdown.sass'
import { useEffect, useState } from 'react'
import { dockConnectionManager } from 'src/helper/HubConnectionManager';
import { djiCloudMethod } from 'src/services/common/constants';
import useDeviceConnection from 'src/services/device/common/useDeviceConnection';
import { DjiCloudStreamQualityText } from 'src/services/device/common/deviceConstants';
import classNames from 'classnames';
import useDeviceLiveStatus from 'src/services/device/common/useDeviceLiveStatus';

function CloudVideoQualityDropdown({ deviceId, serialNumber, config, className, ...props }) {
  const {
    camera_index: cameraIndex,
    video_quality: currVideoQuality,
  } = config || {};
  
  const [videoQuality, setVideoQuality] = useState(currVideoQuality);
  const connection = useDeviceConnection(deviceId);
  const videoId = `${serialNumber}/${cameraIndex}/normal-0`;
  const liveStatus = useDeviceLiveStatus(deviceId, videoId);

  const handleChangeVideoQuality = (quality) => {
    dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.group?.send?.services, {
      method: djiCloudMethod.live.LIVE_SET_QUALITY,
      data: {
        video_id: videoId,
        video_quality: quality
      }
    });
  }

  useEffect(() => {
    if(liveStatus?.video_quality != null)
      setVideoQuality(liveStatus.video_quality);
  }, [liveStatus]);

  useEffect(() => {
    if(currVideoQuality != null && !liveStatus?.video_quality)
      setVideoQuality(currVideoQuality);
  }, [currVideoQuality]);

  if(!cameraIndex) return null;

  return (
    <div className={classNames('cloud-video-quality-dropdown', className)} {...props}>
      <DropdownMenu trigger={DjiCloudStreamQualityText[videoQuality] || 'Default'}>
        {Object.entries(DjiCloudStreamQualityText).map(([key, value]) => (
          <DropdownMenu.Item onClick={() => handleChangeVideoQuality(parseInt(key))} key={key}>
            {value}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu>
    </div>
  )
}

export default CloudVideoQualityDropdown