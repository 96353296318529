import SidePanel from 'src/hci/organism/SidePanel/SidePanel'
import './AddDfrMission.sass'
import { FaSave } from 'react-icons/fa'
import { MdEdit, MdOutlineLocationOn } from 'react-icons/md';
import FormItem from 'src/hci/common/FormItem/FormItem';
import Button from 'src/hci/common/Button/Button';
import Form, { FormDirection } from 'src/hci/common/Form/Form';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeMarkers, setLastClickCoordinates, setPointerMode } from 'src/components/DeviceMap/DeviceMapSlice';
import TextInput from 'src/hci/common/TextInput/TextInput';
import MixedDeviceSelection from '../MixedDeviceSelection/MixedDeviceSelection';
import { createWaypointTemplate, createWaypointWaylines, getCoordinatesDistanceAndTime } from 'src/helper/waypointMarkupUtils';
import { deviceTypes } from 'src/helper/constants';
import JSZip from 'jszip';
import { generateId } from 'src/helper/utils';
import { DEVICE_MAP_SELECTION_MARKER_ID, DeviceMapMarkerTypes, DeviceMapPointerModes } from 'src/components/DeviceMap/DeviceMap';
import { setCreateMissionProgress, sendCreateMission } from 'src/services/mission/MissionServiceSlice';

function AddDfrMission({ onCancel, onSuccess }) {
  const dispatch = useDispatch();
  const formRef = useRef();
  const selectedUnitId = useSelector(state => state.securityApp.selectedUnitId);
  const creteMissionProgress = useSelector(state => state.missionService.createMissionProgress);
  const mapClickCoordinates = useSelector(state => state.deviceMap.lastClickCoordinates);
  const [createMissionLoading, setCreateMissionLoading] = useState(false);
  const mapClickCoordinatesString = useMemo(() => {
    return mapClickCoordinates ? `${mapClickCoordinates.lat},${mapClickCoordinates.lng}` : ''
  }, [mapClickCoordinates]);
  const [selectedDevice, setSelectedDevice] = useState();
  const [lastMissionData, setLastMissionData] = useState();

  const handleBeforeClose = () => {
    dispatch(setPointerMode(DeviceMapPointerModes.NORMAL));
  }

  const handleOnCancel = () => {
    handleBeforeClose();
    onCancel?.();
  }

  const createWaypointMission = (data) => {
    const deviceCoordinates = {
      lat: parseFloat(selectedDevice.coordinates?.lat || 0),
      long: parseFloat(selectedDevice.coordinates?.long || 0),
    };

    // Get total distance and time
    const coords = [
      {
        lat: deviceCoordinates.lat,
        long: deviceCoordinates.long,
      },
      {
        lat: data.latitude,
        long: data.longitude,
      }
    ];

    const {
      distance,
      time
    } = getCoordinatesDistanceAndTime(coords, data.speed);

    const reqData = {
      name: "Mission " + new Date().toUTCString(),
      finishAction: "hover",
      duration: Math.round(time),
      distance: Math.round(distance),
      ...data,
      startLatitude: deviceCoordinates.lat,
      startLongitude: deviceCoordinates.long,
      serialNumber: selectedDevice.serialNumber,
      waypoints: [
        {
            latitude: deviceCoordinates.lat,
            longitude: deviceCoordinates.long,
            altitude: data.altitude,
            speed: data.speed,
            actions: {}
        },
        {
          latitude: data.latitude,
          longitude: data.longitude,
          altitude: data.altitude,
          speed: data.speed,
          actions: {}
        },
      ],
      details: {
        modelName: "DJI Dock"
      }
    };

    if(selectedDevice.type === deviceTypes.DRONE)
      reqData.deviceId = selectedDevice.id;
    else if(selectedDevice.type === deviceTypes.DOCK)
      reqData.dockId = selectedDevice.id;

    // Create KMZ file config
    const kmzConfig = {
      droneEnumValue: 67,
      droneSubEnumValue: 1,
      payloadEnumValue: 54,
      payloadSubEnumValue: 0,
      pyaloadPositionIndex: 0,
      autoFlightSpeed: Number(data.speed),
      globalHeight: Number(data.altitude),
      waypoints: coords,
    };

    const waypointTemplate = createWaypointTemplate(kmzConfig);
    const waypointWaylines = createWaypointWaylines(kmzConfig);

    // Create zip file
    const zip = new JSZip();
    const wpmz = zip.folder('wpmz');

    wpmz.file('template.kml', waypointTemplate);
    wpmz.file('waylines.wpml', waypointWaylines);

    zip.generateAsync({ type: 'base64' }).then((content) => {
      reqData.kmz = content;
      reqData.flightId = generateId();

      setLastMissionData(reqData);
      setCreateMissionLoading(true);
      dispatch(sendCreateMission(selectedUnitId, reqData));
    });
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if(!selectedDevice)
      return;

    const formData = new FormData(e.target);
    const dataObj = Object.fromEntries(formData.entries());

    createWaypointMission({
      altitude: Number(dataObj.altitute || 30),
      speed: Number(dataObj.speed || 10),
      latitude: parseFloat(dataObj.lat || 0),
      longitude: parseFloat(dataObj.long || 0),
    });
  }

  useEffect(() => {
    if (creteMissionProgress?.status === 'success') {
      const data = {
        ...lastMissionData,
        id: creteMissionProgress.data?.missionId,
      }

      if(creteMissionProgress.data?.flight)
        data.flight = {
          id: creteMissionProgress.data.flight.id,
          fingerprint: creteMissionProgress.data.flight.fingerprint,
          url: creteMissionProgress.data.flight.url,
        };

      onSuccess?.(data);
    }

    setCreateMissionLoading(false);
  }, [creteMissionProgress])

  useEffect(() => {
    dispatch(setLastClickCoordinates(null));
    dispatch(setPointerMode(DeviceMapPointerModes.SELECT_POINT));
    
    return () => {
      dispatch(setCreateMissionProgress(null));
      dispatch(removeMarkers({
        type: DeviceMapMarkerTypes.LOCATION,
        data: { id: DEVICE_MAP_SELECTION_MARKER_ID, lat: 0, lng: 0}
      }));
      handleBeforeClose();
    }
  }, []);

  const footerItems = [
    <Button onClick={handleOnCancel} variant="outline">Cancel</Button>,
    <Button onClick={() => {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }} style={{flexGrow: '1'}} disabled={!selectedDevice || createMissionLoading}>Start</Button>
  ];

  return (
    <SidePanel panelTitle="New DFR Mission" footerItems={footerItems} collapsable={false}>
      <div className="add-dfr-mission">
        <Form direction={FormDirection.ROW} ref={formRef} onSubmit={handleFormSubmit}>
          <FormItem label="Location" className="full-width">
            <TextInput name="location" postfix={<MdOutlineLocationOn />} defaultValue={mapClickCoordinatesString} />
          </FormItem>
          <FormItem label="LAT" className="half-width">
            <TextInput name="lat" postfix='(x.y)' defaultValue={mapClickCoordinates?.lat} />
          </FormItem>
          <FormItem label="LONG" className="half-width">
            <TextInput name="long" postfix='(x.y)' defaultValue={mapClickCoordinates?.lng} />
          </FormItem>
          <FormItem label="ALT" className="half-width">
            <TextInput name="altitute" postfix='m' />
          </FormItem>
          <FormItem label="SPEED" className="half-width">
            <TextInput name="speed" postfix='m/s' />
          </FormItem>
        </Form>
        <MixedDeviceSelection className="form-device-list" label="Select Device" onChange={setSelectedDevice} />
      </div>
    </SidePanel>
  )
}

export default AddDfrMission