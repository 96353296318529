import "./KeyValue.sass";

function KeyValue(props) {
  const { attr, value, boxWidth, keyWidth, valueWidth } = props;
  return (
    <div className="key-value-box" style={{ width: boxWidth }}>
      <span className="attr" style={{ width: keyWidth }}>
        {attr}
      </span>
      <span className="value" style={{ width: valueWidth }}>
        {value}
      </span>
    </div>
  );
}

export default KeyValue;
