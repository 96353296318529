import classNames from 'classnames';
import './InputDescription.sass';

function InputDescription({ children, className, type, ...props }) {
  const typeClassName = ['hint', 'error', 'warning', 'success'].includes(type) ? type : 'hint';

  return (
    <div className={ classNames("input-description", typeClassName, className) } {...props}>
      { children }
    </div>
  )
}

export default InputDescription