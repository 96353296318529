import './SelectGroupDialog.sass';
import Dialog from 'src/hci/common/Dialog/Dialog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import SelectGroupForm from '../../DeviceApp/OnboardDeviceApp/DeviceOnboarding/SelectGroupForm/SelectGroupForm';
import CreateNewGroup from '../../DeviceApp/OnboardDeviceApp/DeviceOnboarding/CreateNewGroup/CreateNewGroup';

export function SelectGroupDialog({ onConfirm, ...props }) {
  const [isNewGroupDialogActive, setIsNewGroupDialogActive] = useState(false);
  const [groupsChangeTimestamp, setGroupsChangeTimestamp] = useState(Date.now());

  const handleCreateGroupResult = (props) => {
    setIsNewGroupDialogActive(false);
    setGroupsChangeTimestamp(Date.now());
  }

  return (
    <Dialog title="Select Groups" {...props}>
      <Dialog.Body>
        <div className="select-group-dialog">
        <>
          <SelectGroupForm
            onNewGroup={() => {
              setIsNewGroupDialogActive(true);
            }}
            onSelectConfirm={(groups) => {
              const groupIds = groups.map((item) => item.id)
              onConfirm?.(groupIds);
            }}
            reloadTrigger={groupsChangeTimestamp}
          />
          <Dialog
            title="Configure Group" 
            open={isNewGroupDialogActive}
            onOpenChange={setIsNewGroupDialogActive}
          >
            <Dialog.Body>
              <CreateNewGroup onConfirm={handleCreateGroupResult} />
            </Dialog.Body>
          </Dialog>
        </>
      </div>
    </Dialog.Body>
  </Dialog>
  )
}