import DroneComponent from "../../../../components/Dashboard/Components/DroneComponent/DroneComponent";
import "./NetworkSignal.sass";
import SignalStrength from "../../SignalStrength/SignalStrength";

function NetworkSignal({ type, strength }) {
  const networkSignal = {
    left: <strong style={{ color: "white", fontSize: "1.2em" }}>HD</strong>,
    top: type,
    value: <SignalStrength size="1.5" total={[1, 2, 3, 4]} strength={strength} />,
  };
  return <DroneComponent item={networkSignal} />;
}

export default NetworkSignal;
