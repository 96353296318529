import './UserProfilePictureDialog.sass'

import React, { useEffect, useState } from 'react'
import Dialog from 'src/ui/Dialog/Dialog'
import Avatar from 'src/ui/Avatar/Avatar'
import { FaUserCircle } from 'react-icons/fa'
import { MdDeleteOutline, MdFileUpload } from 'react-icons/md'
import { useCurrentUser } from "src/helper/AccountStateProvider";
import FileInput from 'src/ui/FileInput/FileInput'
import { useDispatch, useSelector } from 'react-redux'
import {
  getUserProfile,
  removeProfileImage,
  updateProfileImage,
  uploadAvatar as uploadAvatarAction,
  removeAvatar as removeAvatarAction,
} from "src/redux/ducks/accountStateSlice";
import Button from 'src/ui/Button/Button'
import Loading from 'src/ui/Loading/Loading'
import { addNotification } from 'src/components/Notification/NotificationSlice'
import { NotificationIcon, NotificationType } from 'src/components/Notification/NotificationItem/NotificationItem'


function UserProfilePictureDialog({ onClose }) {
  const { currentUser } = useCurrentUser();
  const [avatarFile, setAvatarFile] = useState();
  const [avatarBase64, setAvatarBase64] = useState();
  const [loading, setLoading] = useState(false);
  const allowedMimeTypes = ['image/jpg', 'image/jpeg', 'image/png'];
  const dispatch = useDispatch();
  const uploadAvatar = useSelector((state) => state.accountState.uploadAvatar);
  const removeAvatar = useSelector((state) => state.accountState.removeAvatar);

  useEffect(() => {
    if (uploadAvatar.status === "success") {
      setLoading(false);
      dispatch(getUserProfile());
      dispatch(uploadAvatarAction({ status: "" }));
      dispatch(addNotification({
        title: 'Profile Picture',
        message: 'Your profile picture has been updated.',
        type: NotificationType.SUCCESS,
      }));
      onClose?.();
    }
    else if (uploadAvatar.status === "error") {
      dispatch(addNotification({
        title: 'Profile Picture',
        message: 'An error occurred during saving the profile picture.',
        type: NotificationType.ERROR,
      }));
      setLoading(false);
    }
  }, [dispatch, onClose, uploadAvatar]);

  useEffect(() => {
    if (removeAvatar.status === "success") {
      setLoading(false);
      dispatch(getUserProfile());
      dispatch(removeAvatarAction({ status: "" }));
      dispatch(addNotification({
        title: 'Profile Picture',
        message: 'Your profile picture has been removed.',
        type: NotificationType.SUCCESS,
      }));
      onClose?.();
    }
    else if (removeAvatar.success === false) {
      dispatch(addNotification({
        title: 'Profile Picture',
        message: 'An error occurred while removing the profile picture.',
        type: NotificationType.ERROR,
      }));
      setLoading(false);
    }
  }, [removeAvatar, dispatch, onClose]);

  const uploadAvatarFile = () => {
    const formData = new FormData();

    formData.append(
      "file",
      avatarFile,
      avatarFile.name
    );

    setLoading(true);
    dispatch(updateProfileImage(formData));
  }

  const removeOnlineAvatar = () => {
    setLoading(true);
    dispatch(removeProfileImage());
  }

  const handleFileSelectionChange = async (files) => {
    const file = files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      setAvatarBase64(reader.result);
    }

    reader.readAsDataURL(file);
    setAvatarFile(file);
  }

  return (
    <div className="user-profile-picture-dialog">
      <Dialog
        noOperation
        floatCloseButton
        model={{
          CloseHandler: onClose,
        }}
      >
        <div className="dialog-custom-header">
          <div className="dialog-custom-title">Profile Picture</div>
          <div className="dialog-custom-message">A picture helps people recognize you and lets you know when you're signed in to your account</div>
        </div>
        <div className="avatar-options">
          <div className="avatar-preview">
            {avatarBase64 || currentUser?.avatar ? (
              <Avatar
                image={avatarBase64 || currentUser?.avatar}
                isCovered={true}
                isBordered={true}
                width="130px"
                height="130px"
              />
            ) : (
              <Avatar
                image={<FaUserCircle color="#2699fb" size="130px" />}
                width="130px"
                height="130px"
              />
            )}
          </div>
          <div className="avatar-actions">
            <FileInput
              onChange={handleFileSelectionChange}
              accept={allowedMimeTypes}
              imageOptions={{ quality: 0.8, maxDimension: 800 }}
              onError={(error) => alert(error)}
            >
              {({ openFileDialog }) => (
                <div className="item" onClick={openFileDialog}>
                  <MdFileUpload />
                  <div className="item-title">Select Image</div>
                </div>
              )}
            </FileInput>
            {currentUser?.avatar && (
              <div className="item">
                <MdDeleteOutline />
                <div className="item-title" onClick={removeOnlineAvatar}>Remove Image</div>
              </div>
            )}
          </div>
        </div>
        <div className="dialog-custom-operations">
          <Button
            value="Save"
            disabled={!avatarFile}
            onClick={uploadAvatarFile}
          />
        </div>
        {loading && <Loading />}
      </Dialog>
    </div>
  );
}

export default UserProfilePictureDialog;