import "./EditGuest.sass";
import EditableTextBox from "../../../../../ui/EditableTextBox/EditableTextBox";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Button from "../../../../../ui/Button/Button";
import { updateGuest, update } from "../GuestManagementSlice";

function EditGuest({ onOpen, onConfirm }) {
  const table = useSelector((state) => state.table);
  const guest = useSelector((state) => state.guest);
  const [guestObject, setGuestObject] = useState({});
  const [changeDetector, setChangeDetector] = useState(false);
  const dispatch = useDispatch();

  const [data, setData] = useState({
    emailAddress: {
      name: "emailAddress",
      title: "Username/Email",
      type: "text",
    },
    firstName: {
      name: "firstName",
      title: "First Name",
      type: "text",
    },
    lastName: {
      name: "lastName",
      title: "Last Name",
      type: "text",
    },
    displayName: {
      name: "displayName",
      title: "Display Name",
      type: "text",
    },
  });

  useEffect(() => {
    if (guest.update.status === "success") {
      dispatch(update({ status: "" }));
      setChangeDetector(false);
      onConfirm();
    } else if (guest.update.status === "validation") {
      console.log("validation fails");
    }
  }, [guest.update]);

  useEffect(() => {
    const row = table.row;
    var temp = {};
    Object.entries(row).map((item) => {
      var key = item[0];
      var value = item[1];
      if (data[key] !== undefined)
        temp = {
          ...temp,
          [key]: { ...data[key], value: value !== null ? value : "" },
        };
    });
    setData({ ...temp });
  }, [table.row]);

  useEffect(() => {
    onOpen?.();
  }, [data]);

  useEffect(() => {
    setChangeDetector(false);
    Object.entries(guestObject).forEach((item) => {
      if (
        Object.entries(data).filter((x) => {
          return x[0] === item[0];
        })[0][1].value !== item[1]
      ) {
        setChangeDetector(true);
        return;
      }
    });
  }, [guestObject]);

  const changeHandler = (event) => {
    var key = event.target.name;
    var value = event.target.value;
    if (value.toString() !== "") {
      setGuestObject({ ...guestObject, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = guestObject;
      setGuestObject({ ...withoutSecond });
    }
  };

  const submitHandler = () => {
    if (table.row !== undefined)
      dispatch(updateGuest(guestObject, table.row.id));
  };

  return (
    <div className="edit-guest-form">
      <div className="edit-guest-container">
        <div className="edit-guest-section">
          <EditableTextBox
            model={data.emailAddress}
            width="50%"
            onChange={changeHandler}
          />
        </div>

        <div className="edit-guest-section">
          <EditableTextBox
            model={data.firstName}
            width="48%"
            onChange={changeHandler}
          />
          <EditableTextBox
            model={data.lastName}
            width="48%"
            onChange={changeHandler}
          />
        </div>
        <div className="edit-guest-section">
          <EditableTextBox
            model={data.displayName}
            width="50%"
            onChange={changeHandler}
          />
          {changeDetector && (
            <Button
              value="Save"
              color="#2699fb"
              width="30%"
              onClick={submitHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EditGuest;
