import LabeledInformation from "../../../../ui/LabeledInformation/LabeledInformation";
import "./LabeledInformationContainer.sass";

function LabeledInformationContainer({ information }) {
  return (
    information !== undefined && (
      <ul className="labeled-information-container">
        {Object.entries(information).map((item, index) => (
          <li className="labeled-information-container-item" key={index}>
            <LabeledInformation label={item[0]} info={item[1]} />
          </li>
        ))}
      </ul>
    )
  );
}

export default LabeledInformationContainer;
