import "./LabeledInformation.sass";

function LabeledInformation({ label, info }) {
  return (
    <div className="labeled-information">
      <span className="labeled-information-label">{label}</span>
      <span
        className="labeled-information-info"
        style={{ color: typeof info === "string" && "#878787" }}
      >
        {info}
      </span>
    </div>
  );
}

export default LabeledInformation;
