import './MapViewer.sass';
import CollapsablePanel from '../CollapsablePanel/CollapsablePanel';
import { RiFoggyLine, RiRainyLine } from 'react-icons/ri';
import { MdHeight, MdOutlineSatelliteAlt, MdOutlineUploadFile } from 'react-icons/md';
import { TbCameraQuestion, TbNetwork, TbTemperature } from 'react-icons/tb';
import { FiBatteryCharging, FiWind } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'src/helper/AccountStateProvider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFrontendHub } from 'src/components/Dashboard/Components/FrontendHubProvider/FrontendHubProvider';
import DockHubProvider, { useDockHub } from 'src/components/Dashboard/Components/DockHubProvider/DockHubProvider';
import VideoStreamView from 'src/ui/Drone/VideoStreamView/VideoStreamView';
import useOnboardStreamingInfo from '../common/useOnboardStreamingInfo';
import { useVideoStream } from 'src/helper/useVideoStream';
import { DeviceOnlineStatus } from 'src/helper/useOnboardDeviceList';
import { IoCloudOfflineOutline } from 'react-icons/io5';
import DeviceMap from 'src/components/DeviceMap/DeviceMap';
import { dockConnectionManager } from 'src/helper/HubConnectionManager';
import { StageElement } from '../MissionTracking';

function MapViewer({ deviceId, onSwap }) {
  const { account } = useAccount();
  const dispatch = useDispatch();
  const serverMessageHandlerIds = useRef([]);
  const groupMessageHandlerIds = useRef([]);
  const deviceInfo = useSelector((state) => state.deviceService.devices?.find(item => item.id === deviceId));
  const lastTelemetries = useSelector((state) => state.deviceService.deviceTelemetries?.[deviceId]);
  const streamingInfo = useOnboardStreamingInfo(deviceId);
  const { mediaStream: fpvMediaStream } = useVideoStream(streamingInfo?.['fpvViewerConfig']);
  const { mediaStream: mainMediaStream } = useVideoStream(streamingInfo?.['mainViewerConfig']);

  const handleStop = () => {
    dockConnectionManager.sendToDjiCloudGroup("cd022895-46dd-4ec4-ac04-4c220a532c1a", 'thing/product/4TADL4700100A2/services', {
      method: 'live_stop_push',
      data: {
        "video_id": "4TADL4700100A2/165-0-7/normal-0",
      },
    });
  };

  const handleSwap = () => {
    onSwap?.(StageElement.MAP);
  }

  const handleStart = () => {
    dockConnectionManager.sendToDjiCloudGroup("cd022895-46dd-4ec4-ac04-4c220a532c1a", 'thing/product/4TADL4700100A2/services', {
      method: 'live_start_push',
      data: {
        "url": "rtmp://20.224.196.6:1931/4TADL4700100A2?user=aveticspu&pass=aveticspp",
        "url_type": 1,
        "video_id": "4TADL4700100A2/165-0-7/normal-0",
        "video_quality": 2
      },
    });
  };

  return (
    <div className="map-viewer">
      <CollapsablePanel title="Devices Map" onSwap={handleSwap}>
        <DeviceMap className="map-element" />
      </CollapsablePanel>
      <button onClick={handleStop}>Stop</button>
      <button onClick={handleStart}>Start</button>
    </div>
  )
}

export default MapViewer