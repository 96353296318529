import React from 'react'
import './PublicLiveStreamHeader.sass'
import logo from "src/assets/img/logo-mini-white.svg"
import Button from 'src/ui/Button/Button'

function PublicLiveStreamHeader({ joined, onJoinClick, onLeaveClick }) {
  return (
    <div className="public-live-stream-header">
      <div className="container">
        <div className="left-side">
          <img src={logo} alt="logo" className="logo" />
        </div>
        <div className="right-side">
          {joined ? (
            <Button value="Leave Stream" className="action-button leave" onClick={onLeaveClick} />
          ) : (
            <Button value="Join Stream" className="action-button join" onClick={onJoinClick} />
          )}
        </div>
      </div>
    </div>
  )
}

export default PublicLiveStreamHeader