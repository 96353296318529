import "./Device.sass";
import {
  DroneLightIcon,
  DroneOffIcon,
  DroneOnIcon,
} from "src/ui/Icons/Icons";
import Card from "src/ui/Card/Card";
import { HiOutlineChip } from "react-icons/hi";
import { MdFlightTakeoff } from "react-icons/md";
import { Link } from "react-router-dom";

function Device({ device }) {
  return (
    <div className="device-cart">
      <Card>
        <Link to={`/dashboard/device-app/onboard/flight-app/${device.name}/${device.id}/${device.serialNumber}`}>
          <div className="content">
            <div className="image">
              <MdFlightTakeoff className="icon" />
            </div>
            <div className="main">
              <div className="indicator">
                {
                  device.connected === true ? (
                    <DroneOnIcon size="15" />
                  ) : device.connected === -1 ? (
                    <DroneOffIcon size="15" />
                  ) : (
                    <DroneLightIcon size="15" />
                  )
                }
              </div>
              <div className="info">
                <div className="name">{device.name}</div>
                <div className="serial">
                  <HiOutlineChip className="icon" />
                  <div className="value">{device.serialNumber}</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </Card>
    </div>
  )
}

export default Device;
