import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Toast.sass";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
const Toast = () => {
  return (
    <ToastContainer
      position="bottom-right"
      transition={Zoom}
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default Toast;
