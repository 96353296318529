import { FaRegUser } from 'react-icons/fa';
import { FaUserGroup } from 'react-icons/fa6';
import './AppGroupsListCard.sass';
import Card from 'src/hci/common/Card/Card';
import classNames from 'classnames';
import { HiOutlineChip } from 'react-icons/hi';

function AppGroupsListCard({ data }) {
  return (
    <Card className="app-group-list-card">
      <div className="title-bar">
        <div className="title">
          <FaUserGroup />
          <div className="value">{ data.name }</div>
        </div>
        <div className="access-type">
          {data.isOwner ? 'Owner' : 'Member'}
        </div>
      </div>
      <div className="users-count">
        <FaRegUser className="icon" />
        <div className="value">{ (data.members?.length || 0) + ' users' }</div>
      </div>
      <div className="register-time">{ data.creationDate }</div>
    </Card>
  )
}

export default AppGroupsListCard