import moment from "moment";
import { useEffect, useState } from "react";
import AppTable from "../../../../ui/AppTable/AppTable";
import LabeledButton from "../../../../ui/LabeledButton/LabeledButton";
import CircleAvatar from "../../../../ui/CircleAvatar/CircleAvatar";
import DateTimeComponent from "../../../../ui/DateTimeComponent/DateTimeComponent";
import SearchOption from "../../../../ui/SearchOption/SearchOption";
import "./GroupManagementApplication.sass";
import EntityName from "../../../../ui/EntityName/EntityName";
import { toColor } from "../../../../helper/utils";
import GroupMembershipApp from "../../../../ui/GroupApp/GroupMembershipApp/GroupMembershipApp";
import GroupOwnershipApp from "../../../../ui/GroupApp/GroupOwnershipApp/GroupOwnershipApp";
import { useDispatch, useSelector } from "react-redux";
import {
  assignMembers,
  assignOwners,
  currentGroup,
  getGroupsInformation,
  update,
} from "../../../../ui/GroupApp/GroupAppSlice";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import { MdGroupAdd, MdRefresh } from "react-icons/md";
import ApplicationComponentContent from "../ApplicationComponentContent/ApplicationComponentContent";
import GroupInformationApplication from "./GroupInformationApplication/GroupInformationApplication";
import EditGroupOptions from "./EditGroupOptions/EditGroupOptions";

function GroupManagementApplication() {
  const [tableRows, setTableRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Group Name", value: "title", status: true },
      { title: "Description", value: "description", status: false },
      { title: "Registered Date", value: "registeredDate", status: true },
      { title: "Membership Type", value: "memberType", status: false },
    ],
    values: [],
    options: [],
  });
  const [currentGroups, setCurrentGroups] = useState([]);

  const [selectedFeature, setSelectedFeature] = useState({});
  const [operationHandler, setOperationHandler] = useState(false);
  const [operationHandlerOpen, setOperationHandlerOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const groupApp = useSelector((state) => state.groupApp);
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState([]);
  const { privileges } = usePrivileges();

  useEffect(() => {
    
    if (privileges.applications !== undefined) {
      if (
        privileges.applications.find((item) => item.id === "groups") !==
        undefined
      )
        setPermissions(
          [
            ...privileges.applications.filter((item) => item.id === "groups")[0]
              ?.privileges,
          ]
          // .concat([
          //   ...privileges.applications.filter((item) => item.id === "device")[0]
          //     ?.privileges,
          // ])
        );
    }
  }, [privileges.applications]);

  useEffect(() => {
    if (groupApp.groupsInformation?.data?.groups !== undefined) {
      setGroups(
        groupApp.groupsInformation.data.groups.map((group) => ({
          id: group.id,
          name: group.name,
          members: group.members,
          owners: group.owners,
          isOwner: group.isOwner,
          isMember: group.isMember,
          title: (
            <EntityName
              item={{
                title: group.name,
                isOwner: group.isOwner,
                isMember: group.isMember,
                icon: (
                  <CircleAvatar
                    name={group.name.substring(0, 2).toUpperCase()}
                    fontSize=".9em"
                    size="2.25"
                    color={`#${toColor(group.name)}`}
                  />
                ),
              }}
            />
          ),
          description: group.description,
          registeredDate: (
            <DateTimeComponent
              date={moment(group.creationDate).format("DD MMMM YYYY")}
              time={moment(group.creationDate).format("hh:mm A")}
              hour={parseInt(moment(group.creationDate).format("h"))}
            />
          ),
          memberType: `${group.isMember ? "Member" : ""}${
            !group.isMember
              ? group.isOwner
                ? "Owner"
                : ""
              : group.isOwner
              ? ",Owner"
              : ""
          }`,
        }))
      );
    }
  }, [groupApp.groupsInformation?.data?.groups]);
  useEffect(() => {
    if (groupApp.update?.status === "success") {
      toggleOperationHandlerOff();
      dispatch(getGroupsInformation(currentGroups?.toString()));
      dispatch(update({ status: "" }));
    }
  }, [groupApp.update]);

  useEffect(() => {
    if (groupApp.assignMembers?.status === "success") {
      toggleOperationHandlerOff();
      dispatch(getGroupsInformation(currentGroups?.toString()));
      dispatch(assignMembers({ status: "" }));
    }
  }, [groupApp.assignMembers]);

  useEffect(() => {
    if (groupApp.assignOwners?.status === "success") {
      toggleOperationHandlerOff();
      dispatch(getGroupsInformation(currentGroups?.toString()));
      dispatch(assignOwners({ status: "" }));
    }
  }, [groupApp.assignOwners]);

  useEffect(() => {
    if (groupApp?.selectedGroups !== undefined) {
      setCurrentGroups([...groupApp?.selectedGroups]);
      setTableRows({ ...tableRows, values: [] });
      toggleOperationHandlerOff();
      dispatch(getGroupsInformation(groupApp?.selectedGroups.toString()));
    }
  }, [groupApp?.selectedGroups]);

  useEffect(() => {
    setTableRows({ ...tableRows, values: [...groups] });
  }, [groups]);

  useEffect(() => {
    return () => {
      dispatch(currentGroup({}));
    };
  }, []);

  const refreshGroups = () => {
    toggleOperationHandlerOff();
    dispatch(getGroupsInformation(currentGroups?.toString()));
  };

  const toggleOperationHandlerOn = () => {
    setOperationHandler(true);
    setOperationHandlerOpen(true);
  };

  const features = [
    {
      id: "edit_group",
      title: "Edit Group members",
      steps: [
        {
          name: "groups_app_info",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["groupApp/update", "groupApp/assignMembers", "groupApp/assignOwners"],
          activity: (
            <EditGroupOptions />
          ),
        },
      ],
    },
    {
      id: "group_assignment",
      title: "Assign Group members",
      description: "You can assign new group(s) for selected device.",
      steps: [
        {
          name: "groups_app_create",
          title: "New Group",
          active: true,
          visible: true,
          locked: false,
          tags: ["groupApp/create"],
          // activity: (
          //   <AppCreateGroup
          //     onOpen={toggleOperationHandlerOn}
          //     onConfirm={onAddNewGroupHandler}
          //   />
          // ),
        },
        {
          name: "devices_app_assign_groups",
          title: "Group Assignment",
          active: false,
          visible: true,
          locked: false,
          tags: [],
        },
      ],
    },
  ];

  const rowChangeHandler = (row) => {
    if (row !== undefined) {
      toggleOperationHandlerOn();
      dispatch(currentGroup({ ...row }));
      setSelectedFeature({
        ...features.find((x) => x.id === "edit_group"),
        steps: features
          .find((x) => x.id === "edit_group")
          .steps.filter((s) => permissions?.includes(s.name))
          .map((step) => ({
            ...step,
            visible:
              step.name === "groups_app_ownership"
                ? row?.isOwner && true
                : step.visible,
          })),
        info: (
          <EntityName
            item={{
              title: row?.name,
              subtitle: row?.memberType,
              icon: (
                <CircleAvatar
                  name={row?.name.substring(0, 2).toUpperCase()}
                  fontSize=".9em"
                  size="2.25"
                  color={`#${toColor(row?.name)}`}
                />
              ),
            }}
          />
        ),
      });
    }
  };
  const rowsChangeHandler = (rows) => {
    if (rows !== undefined) {
      // console.log(rows);
    }
  };
  const optionHandler = (name) => {
    if (name !== undefined) {
      // console.log(name);
    }
  };

  const toggleOperationHandlerOff = () => {
    setOperationHandler(false);
  };

  const GroupAssignment = () => {
    setSelectedFeature({
      ...features.find((x) => x.id === "group_assignment"),
      steps: features
        .find((x) => x.id === "group_assignment")
        .steps.filter((s) => permissions?.includes(s.name)),
      info: (
        <EntityName
          item={{
            title: "Group Assignment",
            subtitle: "You is owner of each created group(s).",
            icon: (
              <MdGroupAdd fontSize="2.5em" style={{ marginRight: ".25em" }} />
            ),
          }}
        />
      ),
    });
    toggleOperationHandlerOn();
  };
  const controls = [
    {
      name: "refresh_groups",
      active: true,
      component: (
        <LabeledButton
          title="Refresh"
          icon={<MdRefresh size="15" style={{ marginLeft: "-0.15em" }} />}
          onClick={refreshGroups}
        />
      ),
    },
  ];

  return (
    <ApplicationComponentContent
      controls={controls}
      feature={selectedFeature}
      toggleOperation={operationHandler}
      onCloseOperationManager={toggleOperationHandlerOff}
    >
      <div className="group-management-application-header">
        <h4>Device Groups</h4>
        <div className="group-management-application-header-info">
          <p>All device groups listed in the table.</p>
          <SearchOption />
        </div>
      </div>
      <div className="group-management-application-body">
        <AppTable
          rows={tableRows}
          onRowChange={rowChangeHandler}
          onRowsChange={rowsChangeHandler}
          optionChangeHandler={optionHandler}
          tags={["groupApp/groupsInformation"]}
          selectable={false}
          boldIndexes={[]}
        />
      </div>
    </ApplicationComponentContent>
  );
}

export default GroupManagementApplication;
