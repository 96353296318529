import Button from 'src/ui/Button/Button'
import './SpeedTest.sass'
import OnboardHubProvider, { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { useEffect, useRef, useState } from 'react';
import { onboardConnectionManager } from 'src/helper/HubConnectionManager';
import { useMemo } from 'react';
import { TbRefresh } from 'react-icons/tb';
import Select from 'src/ui/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from 'src/components/Notification/NotificationSlice';
import { NotificationType } from 'src/components/Notification/NotificationItem/NotificationItem';

export const SpeedTestStatus = {
  NOT_INITIALIZED: 'notInitialized',
  NOT_INSTALLED: 'notInstalled',
  INSTALL_COMPLETED: 'installCompleted',
  TEST_REQUEST_SENT: 'testRequestSent',
  TESTING: 'testing',
  GET_SERVERS_REQUEST_SENT: 'getServersRequestSent',
  GETTING_SERVERS: 'gettingServers',
  SERVERS_LIST_READY: 'serversListReady',
};

const SocketSpeedTestStatus = {
  SERVER_LIST_WAITING: 'serverListWaiting',
  SERVER_LIST_RESULT: 'serverListResult',
  TEST_WAITING: 'testWaiting',
  TEST_RESULT: 'testResult',
  NOT_INSTALLED: 'notInstalled',
  INSTALL_COMPLETED: 'installCompleted',
};

function SpeedTest() {
  const dispatch = useDispatch();
  const { hub, groups, deviceId } = useOnboardHub();
  const permissionGroups = useSelector(
    (state) => state.websocket.permissionGroups[deviceId]
  );
  const groupMessageHandlerIds = useRef([]);
  const serverListTimeoutId = useRef(null);
  const testStartTimeoutId = useRef(null);
  const testResultTimeoutId = useRef(null);
  const [testStatus, setTestStatus] = useState(SpeedTestStatus.NOT_INITIALIZED);
  const [serversList, setServersList] = useState([]);
  const [selectedServer, setSelectedServer] = useState(null);
  const [testResult, setTestResult] = useState(null);

  const testStatusText = useMemo(() => {
    switch (testStatus) {
      case SpeedTestStatus.TEST_REQUEST_SENT:
        return 'Waiting for test to start...';

      case SpeedTestStatus.TESTING:
        return 'Currently testing, please wait...';

      case SpeedTestStatus.GET_SERVERS_REQUEST_SENT:
        return 'Request for servers list sent.';

      case SpeedTestStatus.GETTING_SERVERS:
        return 'Getting servers list...';

      case SpeedTestStatus.NOT_INSTALLED:
        return 'Tools not installed, trying to install...';

      case SpeedTestStatus.INSTALL_COMPLETED:
        return 'Speed test tool has been installed.';

      default:
        return null;
    }
  }, [testStatus]);

  const serverListOptions = useMemo(() => {
    return serversList.map((server) => ({
      id: server.id,
      value: server.id,
      name: `${server.name} (${server.location})`,
    }));
  }, [serversList]);

  const enhancedTestResult = useMemo(() => {
    if (!testResult || !serversList) return null;

    const server = serversList.find(server => server.host === testResult.server?.host);
    if (!server) return testResult;

    return ({
      ...testResult,
      server: {
        ...server,
        ...testResult.server,
      }
    });
  }, [selectedServer, serversList, testResult]);

  const requestServersList = () => {
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      speedTest: {
        params: 'listServers',
      }
    });

    serverListTimeoutId.current = setTimeout(() => {
      setTestStatus(SpeedTestStatus.NOT_INITIALIZED);
      dispatch(addNotification({
        title: 'Drone Speed Test',
        message: "Failed to get servers list in 30 seconds.",
        type: NotificationType.ERROR,
      }));
    }, 30000);

    setTestStatus(SpeedTestStatus.GET_SERVERS_REQUEST_SENT);
  };

  const requestSpeedTestStart = () => {
    const serverUrl = serversList.find(server => server.id === parseInt(selectedServer))?.host;

    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      speedTest: {
        params: serverUrl || 'no',
      }
    });

    testStartTimeoutId.current = setTimeout(() => {
      setTestStatus(SpeedTestStatus.NOT_INITIALIZED);
      dispatch(addNotification({
        title: 'Drone Speed Test',
        message: "Test didn't started in 20 seconds.",
        type: NotificationType.ERROR,
      }));
    }, 20000);

    setTestStatus(SpeedTestStatus.TEST_REQUEST_SENT);
  };

  const serverChangeHandler = (event) => {
    const value = parseInt(event.target.value);
    setSelectedServer(value);
  }

  const groupMessageHandlers = [
    {
      identity: deviceId,
      name: permissionGroups?.join?.onDemandChannel,
      handler: (data) => {
        const status = data?.status;

        switch (status) {
          case SocketSpeedTestStatus.SERVER_LIST_WAITING:
            setTestStatus(SpeedTestStatus.GETTING_SERVERS);
            clearTimeout(serverListTimeoutId.current);
            break;

          case SocketSpeedTestStatus.SERVER_LIST_RESULT:
            setServersList(data.servers);
            setTestStatus(SpeedTestStatus.SERVERS_LIST_READY);
            break;

          case SocketSpeedTestStatus.TEST_WAITING:
            clearTimeout(testStartTimeoutId.current);
            clearTimeout(serverListTimeoutId.current);

            testResultTimeoutId.current = setTimeout(() => {
              dispatch(addNotification({
                title: 'Drone Speed Test',
                message: "Test result did not received in 3 minutes.",
                type: NotificationType.ERROR,
              }));

              setTestStatus(SpeedTestStatus.NOT_INITIALIZED);
            }, 180000);

            setTestStatus(SpeedTestStatus.TESTING);
            break;

          case SocketSpeedTestStatus.TEST_RESULT:
            clearTimeout(testResultTimeoutId.current);
            setTestResult(data);

            if (!serversList.length) requestServersList();
            else setTestStatus(SpeedTestStatus.SERVERS_LIST_READY);

            break;

          case SocketSpeedTestStatus.NOT_INSTALLED:
            setTestStatus(SpeedTestStatus.NOT_INSTALLED);
            break;

          case SocketSpeedTestStatus.INSTALL_COMPLETED:
            setTestStatus(SpeedTestStatus.INSTALL_COMPLETED);
            setTimeout(() => {
              requestServersList();
            }, 2000);
            break;

          default:
            break;
        }

        console.log({ 'speedData': data });
      },
    },
  ];

  const subscribeGroups = () => {
    onboardConnectionManager.unsubscribeGroupMessages(
      groupMessageHandlerIds.current
    );

    groupMessageHandlerIds.current =
      onboardConnectionManager.subscribeGroupMessages(
        groupMessageHandlers,
        "speedTest"
      );
  }

  useEffect(() => {
    if (serversList.length && !serversList.find(server => server.id === parseInt(selectedServer))) {
      setSelectedServer(serversList[0].id);
    }
  }, [selectedServer, serversList]);

  useEffect(() => {
    return () => {
      onboardConnectionManager.unsubscribeGroupMessages(
        groupMessageHandlerIds.current
      );
    };
  }, []);

  return (
    <OnboardHubProvider
      onJoinComplete={() => {
        subscribeGroups();
      }}
      candidateGroups={['onDemandChannel']}
    >
      <div className="speed-test">
        {!testResult && (
          <div className="speed-test-description">
            You can run a internet speed test to check the internet quality between your drone and the mobile network provider.
          </div>
        )}
        {testStatusText && (
          <div className="test-status">
            <div className="test-status-icon"><TbRefresh className="icon" /></div>
            <div className="test-status-text">{testStatusText}</div>
          </div>
        )}
        {testStatus === SpeedTestStatus.NOT_INITIALIZED && (
          <Button className="speed-test-button" value="Get servers list" onClick={requestServersList} />
        )}
        {testStatus === SpeedTestStatus.SERVERS_LIST_READY && (
          <>
            <Select
              title="Test server"
              name="speedTestServer"
              items={serverListOptions}
              onChange={serverChangeHandler}
              current={selectedServer}
              width="100%"
              darkMode={true}
            />
            <br />
            <Button className="speed-test-button" value="Start test" onClick={requestSpeedTestStart} />
          </>
        )}
        <SpeedTestResult result={enhancedTestResult} />
      </div>
    </OnboardHubProvider>
  );
}

function SpeedTestResult({ result }) {
  if (!result) return null;
  const { testResult, server, localIPInfo } = result;

  return (
    <div className="speed-test-result">
      <div className="result-item">
        <div className="result-item-title">Download</div>
        <div className="result-item-value">{(testResult?.download.bandwidth).toFixed(2)} Mbps</div>
      </div>
      <div className="result-item">
        <div className="result-item-title">Upload</div>
        <div className="result-item-value">{(testResult?.upload.bandwidth).toFixed(2)} Mbps</div>
      </div>
      <div className="result-item">
        <div className="result-item-title">Ping</div>
        <div className="result-item-value">{server?.ping} ms</div>
      </div>
      <div className="result-item">
        <div className="result-item-title">ISP</div>
        <div className="result-item-value">{localIPInfo?.isp}</div>
      </div>
      <div className="result-item">
        <div className="result-item-title">Server</div>
        <div className="result-item-value">{server?.name}</div>
      </div>
      <div className="result-item">
        <div className="result-item-title">Server ID</div>
        <div className="result-item-value">{server?.id}</div>
      </div>
      <div className="result-item">
        <div className="result-item-title">Location</div>
        <div className="result-item-value">{server?.location}</div>
      </div>
      <div className="result-item">
        <div className="result-item-title">Timestamp</div>
        <div className="result-item-value">{result?.timestamp}</div>
      </div>
      <div className="result-item">
        <div className="result-item-title">External IP</div>
        <div className="result-item-value">{localIPInfo?.externalIp}</div>
      </div>
    </div>
  );
}

export default SpeedTest