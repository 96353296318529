import './EditUserOptions.sass'

import React, { useEffect, useMemo } from 'react'
import { usePrivileges } from 'src/helper/AccountStateProvider';
import Avatar from 'src/ui/Avatar/Avatar';
import { FaUserCircle } from 'react-icons/fa';
import Tabs from 'src/ui/Tabs/Tabs';
import EditUser from '../EditUser/EditUser';
import ManageUserRoles from '../ManageUserRoles/ManageUserRoles';
import { useSelector } from 'react-redux';

function EditUserOptions({ onOpen, onConfirm, defaultIndex }) {
  const table = useSelector((state) => state.table);
  const { privileges } = usePrivileges();
  const permissions = useMemo(() => privileges?.admins.find((item) => item.id === "users")?.privileges, [privileges]);
  const hasPermission = (id) => permissions?.includes(id);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className="edit-user-options">
      <div className="edit-user-options-header">
        <div className="user-name">{table.row.displayName}</div>
      </div>
      <div className="edit-user-options-content">
        <Tabs
          defaultIndex={defaultIndex}
          items={[
            {
              title: "Information",
              content: <EditUser onConfirm={onConfirm} />,
              hidden: !hasPermission("edit_user"),
            },
            {
              title: "Manage Roles",
              content: <ManageUserRoles onConfirm={onConfirm} />,
              hidden: !hasPermission("manage_user_roles"),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default EditUserOptions