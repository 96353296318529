import {
  setIsRecording,
  setRecordTime,
} from "src/components/Dashboard/ApplicationsArea/FlightController/FlightControllerSlice";
import { useEffect, useRef, useState } from "react";
import { MdSmartDisplay } from "react-icons/md";
import { useDispatch } from "react-redux";
import ToggleCameraModeSimple from "src/ui/Drone/ToggleCameraModeSimple/ToggleCameraModeSimple";
import CameraAction from "../../../../components/Dashboard/Components/CameraAction/CameraAction";
import CamRecorder from "../../../DroneIcons/CamRecorder/CamRecorder";
import Shutter from "../../../DroneIcons/Shutter";
import "./CameraActions.sass";

function CameraMode() {
  const [check, setCheck] = useState(false);
  const timer = useRef(null);
  const seconds = useRef(0);
  const minutes = useRef(0);
  const hour = useRef(0);
  const dispatch = useDispatch();
  const startRecording = () => {
    dispatch(setIsRecording(true));
    timer.current = setInterval(() => {
      if (seconds.current <= 59) {
        seconds.current += 1;
      } else {
        seconds.current = 0;
        if (minutes.current <= 59) {
          minutes.current += 1;
        } else {
          minutes.current = 0;
          hour.current += 1;
        }
      }
      dispatch(
        setRecordTime(
          `${hour.current < 10 ? "0" + hour.current : hour.current}:${minutes.current < 10 ? "0" + minutes.current : minutes.current
          }:${seconds.current < 10 ? "0" + seconds.current : seconds.current}`
        )
      );
    }, 1000);
  };

  const stopRecording = () => {
    seconds.current = 0;
    minutes.current = 0;
    hour.current = 0;
    clearInterval(timer.current);
    timer.current = null;
    dispatch(setIsRecording(false));
    dispatch(setRecordTime("00:00:00"));
  };

  useEffect(() => {
    return () => {
      clearInterval(timer.current);
      timer.current = null;
      dispatch(setIsRecording(false));
      dispatch(setRecordTime("00:00:00"));
    };
  }, []);

  const toggleCamera = (check) => {
    setCheck(check);
    setActions(
      actions.map((action) => ({
        ...action,
        icon:
          action.name === "capture" ? (
            !check ? (
              <Shutter size="4" />
            ) : (
              <CamRecorder
                size="4"
                startRecording={startRecording}
                stopRecording={stopRecording}
              />
            )
          ) : (
            action.icon
          ),
      }))
    );
  };

  const [actions, setActions] = useState([
    {
      name: "toggle_camera_mode",
      icon: <ToggleCameraModeSimple onToggleChange={toggleCamera} />,
      evenHandler: () => {
        console.log("toggle camera");
      },
    },
    {
      name: "capture",
      icon: <Shutter size="4" />,
      evenHandler: () => {
        console.log("capture image");
      },
    },
    {
      name: "media",
      icon: <MdSmartDisplay size={32} opacity={0.2} />,
      evenHandler: () => { },
    },
  ]);
  return (
    <div className="camera-actions-container">
      {/* {!check && <CamRanger size="3" />} */}
      <ul className="camera-actions">
        {actions.map((action, index) => (
          <CameraAction key={index} item={action} />
        ))}
      </ul>
    </div>
  );
}

export default CameraMode;
