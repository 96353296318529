import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../redux/action/api";

const AccountManagementSlice = createSlice({
  name: "account",
  initialState: {
    name: { status: "default" },
    create: { status: "" },
    update: { status: "" },
    confirm: { status: "" },
    mode: "Create",
    result: { status: "" },
    model: {},
    stepIndex: 0,
    help: {},
  },
  reducers: {
    setAccountModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    accountResult(state, action) {
      const data = action.payload;
      return { ...state, result: data };
    },
    accountMode(state, action) {
      const data = action.payload;
      return { ...state, mode: data };
    },
    stepIndex(state, action) {
      const data = action.payload;
      return { ...state, stepIndex: data };
    },
    help(state, action) {
      const data = action.payload;
      return { ...state, help: data };
    },
    name(state, action) {
      const data = action.payload;
      return { ...state, name: { ...data } };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    confirm(state, action) {
      const data = action.payload;
      return { ...state, confirm: { ...data } };
    },
  },
});
export const {
  name,
  stepIndex,
  create,
  update,
  confirm,
  help,
  accountMode,
  accountResult,
  setAccountModel,
} = AccountManagementSlice.actions;
export default AccountManagementSlice.reducer;

export const checkAccountName = (nameToCheck) =>
  apiCallBegan({
    url: "account/check-name/" + nameToCheck,
    method: "Get",
    onSpin: name.type,
    onSuccess: name.type,
    onError: name.type,
  });

export const createAccount = (data) =>
  apiCallBegan({
    url: "account",
    method: "Post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const updateAccount = (data) =>
  apiCallBegan({
    url: "account",
    method: "Put",
    data,
    onSuccess: update.type,
    onError: update.type,
  });

export const confirmAccount = (data) =>
  apiCallBegan({
    url: "account/confirm",
    method: "Post",
    data,
    onSuccess: confirm.type,
    onError: confirm.type,
  });
