import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const WebsocketSlice = createSlice({
  name: "device-ws",
  initialState: {
    onboardConnection: { status: "" },
    frontendConnection: { status: "" },
    dockConnection: { status: "" },
    mobileConnection: { status: "" },
    permission: { status: "" },
    frontendPermissions: { status: "" },
    dockPermissions: { status: "" },
    mobilePermissions: { status: "" },
    ws: { status: "" },
    instances: {},
    connections: {},
    connectionPool: [],
    frontendConnectionPool: [],
    frontendConnections: {},
    dockConnectionPool: [],
    dockConnections: {},
    mobileConnections: {},
    disconnectionPool: [],
    disposedIdentity: null,
    dockDisposedIdentity: null,
    permissionGroups: {},
    permissionPool: [],
    dockPermissionGroups: {},
    dockPermissionPool: [],
    mobilePermissionPool: [],
    mobilePermissionGroups: {},
    newOnboardConnection: undefined,
    newFrontendConnection: undefined,
    newDockConnection: undefined,
    newMobileConnection: undefined,
  },
  reducers: {
    onboardConnection(state, action) {
      const data = action.payload;
      return { ...state, onboardConnection: { ...data } };
    },
    frontendConnection(state, action) {
      const data = action.payload;
      return { ...state, frontendConnection: { ...data } };
    },
    dockConnection(state, action) {
      const data = action.payload;
      return { ...state, dockConnection: { ...data } };
    },
    mobileConnection(state, action) {
      const data = action.payload;
      return { ...state, mobileConnection: { ...data } };
    },
    permissions(state, action) {
      const data = action.payload;
      return { ...state, permissions: { ...data } };
    },
    frontendPermissions(state, action) {
      const data = action.payload;
      return { ...state, frontendPermissions: { ...data } };
    },
    dockPermissions(state, action) {
      const data = action.payload;
      return { ...state, dockPermissions: { ...data } };
    },
    mobilePermissions(state, action) {
      const data = action.payload;
      return { ...state, mobilePermissions: { ...data } };
    },
    ws(state, action) {
      const data = action.payload;
      return { ...state, ws: { ...data } };
    },
    instances(state, action) {
      const data = action.payload;
      return { ...state, instances: { ...data } };
    },
    newOnboardConnection(state, action) {
      const data = action.payload;
      return { ...state, newOnboardConnection: data };
    },
    closeOnboardConnection(state) {
      return { ...state, newOnboardConnection: null };
    },
    newFrontendConnection(state, action) {
      const data = action.payload;
      return { ...state, newFrontendConnection: data };
    },
    closeFrontendConnection(state) {
      return { ...state, newFrontendConnection: null };
    },
    newDockConnection(state, action) {
      const data = action.payload;
      return { ...state, newDockConnection: data };
    },
    closeDockConnection(state) {
      return { ...state, newDockConnection: null };
    },
    newMobileConnection(state, action) {
      const data = action.payload;
      return { ...state, newMobileConnection: data };
    },
    closeMobileConnection(state) {
      return { ...state, newMobileConnection: null };
    },
    connections(state, action) {
      const data = action.payload;
      return { ...state, connections: { ...state.connections, ...data } };
    },
    removeFromConnections(state, action) {
      const { [action.payload]: value, ...removedObject } = { ...state.connections };
      return {
        ...state,
        connections: removedObject,
      };
    },
    dockConnections(state, action) {
      const data = action.payload;
      return { ...state, dockConnections: { ...state.dockConnections, ...data } };
    },
    removeFromDockConnections(state, action) {
      const { [action.payload]: value, ...removedObject } = { ...state.dockConnections };
      return {
        ...state,
        dockConnections: removedObject,
      };
    },
    mobileConnections(state, action) {
      const data = action.payload;
      return { ...state, mobileConnections: { ...state.mobileConnections, ...data } };
    },
    removeFromMobileConnections(state, action) {
      const { [action.payload]: value, ...removedObject } = { ...state.mobileConnections };
      return {
        ...state,
        mobileConnections: removedObject,
      };
    },
    frontendConnections(state, action) {
      const data = action.payload;
      return {
        ...state,
        frontendConnections: { ...state.frontendConnections, ...data },
      };
    },
    addToConnectionPool(state, action) {
      const data = action.payload;
      if (![...state.connectionPool].includes(data))
        return {
          ...state,
          connectionPool: [...state.connectionPool, data],
          disposedIdentity: null,
        };
      else
        return {
          ...state,
          connectionPool: [...state.connectionPool],
          disposedIdentity: null,
        };
    },
    addToFrontendConnectionPool(state, action) {
      const data = action.payload;
      if (![...state.frontendConnectionPool].includes(data))
        return {
          ...state,
          frontendConnectionPool: [...state.frontendConnectionPool, data],
        };
      else
        return {
          ...state,
          frontendConnectionPool: [...state.frontendConnectionPool],
        };
    },
    removeFromFrontendConnectionPool(state, action) {
      const data = action.payload;
      const { [data]: value, ...removedObject } = {
        ...state.frontendConnections,
      };
      return {
        ...state,
        frontendConnectionPool: [...state.frontendConnectionPool].filter(
          (item) => item !== data
        ),
        frontendConnections: removedObject,
      };
    },
    removeFromConnectionPool(state, action) {
      const data = action.payload;
      const { [data]: value, ...removedObject } = { ...state.connections };
      return {
        ...state,
        connectionPool: [...state.connectionPool].filter(
          (item) => item !== data
        ),
        // connections: removedObject,
        disposedIdentity: data,
      };
    },
    addToDockConnectionPool(state, action) {
      const data = action.payload;
      if (![...state.dockConnectionPool].includes(data))
        return {
          ...state,
          dockConnectionPool: [...state.dockConnectionPool, data],
          dockDisposedIdentity: null,
        };
      else
        return {
          ...state,
          dockConnectionPool: [...state.dockConnectionPool],
          dockDisposedIdentity: null,
        };
    },
    removeFromDockConnectionPool(state, action) {
      const data = action.payload;
      const { [data]: value, ...removedObject } = { ...state.dockConnections };
      return {
        ...state,
        dockConnectionPool: [...state.dockConnectionPool].filter(
          (item) => item !== data
        ),
        // connections: removedObject,
        dockDisposedIdentity: data,
      };
    },
    addToPermissionPool(state, action) {
      const data = action.payload;
      if (![...state.permissionPool].includes(data))
        return {
          ...state,
          permissionPool: [...state.permissionPool, data],
        };
      else
        return {
          ...state,
          permissionPool: [...state.permissionPool],
        };
    },
    removeFromPermissionPool(state, action) {
      const data = action.payload;
      const { [data]: value, ...removedObject } = { ...state.permissionGroups };
      return {
        ...state,
        permissionPool: [...state.permissionPool].filter(
          (item) => item !== data
        ),
        permissionGroups: removedObject,
      };
    },

    addToDockPermissionPool(state, action) {
      const data = action.payload;
      if (![...state.dockPermissionPool].includes(data))
        return {
          ...state,
          dockPermissionPool: [...state.dockPermissionPool, data],
        };
      else
        return {
          ...state,
          dockPermissionPool: [...state.dockPermissionPool],
        };
    },
    removeFromDockPermissionPool(state, action) {
      const data = action.payload;
      const { [data]: value, ...removedObject } = { ...state.dockPermissionGroups };
      return {
        ...state,
        dockPermissionPool: [...state.dockPermissionPool].filter(
          (item) => item !== data
        ),
        dockPermissionGroups: removedObject,
      };
    },

    addToPermissionGroups(state, action) {
      const data = action.payload;
      return {
        ...state,
        permissionGroups: { ...state.permissionGroups, ...data },
      };
    },
    removeFromPermissionGroups(state, action) {
      const data = action.payload;
      const { [data]: value, ...removedObject } = { ...state.permissionGroups };
      return { ...state, permissionGroups: { removedObject } };
    },

    addToDockPermissionGroups(state, action) {
      const data = action.payload;
      return {
        ...state,
        dockPermissionGroups: { ...state.dockPermissionGroups, ...data },
      };
    },
    removeFromDockPermissionGroups(state, action) {
      const data = action.payload;
      const { [data]: value, ...removedObject } = { ...state.dockPermissionGroups };
      return { ...state, dockPermissionGroups: { removedObject } };
    },
  },
});
export const {
  onboardConnection,
  frontendConnection,
  dockConnection,
  mobileConnection,
  permissions,
  frontendPermissions,
  dockPermissions,
  mobilePermissions,
  ws,
  instances,
  connections,
  removeFromConnections,
  removeFromDockConnections,
  removeFromMobileConnections,
  frontendConnections,
  dockConnections,
  addToConnectionPool,
  removeFromConnectionPool,
  addToDockConnectionPool,
  removeFromDockConnectionPool,
  addToPermissionGroups,
  removeFromPermissionGroups,
  addToDockPermissionGroups,
  removeFromDockPermissionGroups,
  addToPermissionPool,
  removeFromPermissionPool,
  addToDockPermissionPool,
  removeFromDockPermissionPool,
  addToFrontendConnectionPool,
  removeFromFrontendConnectionPool,
  newOnboardConnection,
  closeOnboardConnection,
  newFrontendConnection,
  closeFrontendConnection,
  newDockConnection,
  closeDockConnection,
  newMobileConnection,
  closeMobileConnection,
} = WebsocketSlice.actions;
export default WebsocketSlice.reducer;

export const getFrontendConnection = () =>
  apiCallBegan({
    url: `/account/app/frontend/ws-connection/`,
    method: "Get",
    onSuccess: frontendConnection.type,
    onError: frontendConnection.type,
  });

export const getOnboardConnection = (id, meta) =>
  apiCallBegan({
    url: `/devices/app/onboard/ws-connection/${id}`,
    method: "Get",
    onSuccess: onboardConnection.type,
    onError: onboardConnection.type,
    meta,
  });

export const getDockConnection = (id, meta) =>
  apiCallBegan({
    url: `/devices/app/docks/${id}/ws-connection/`,
    method: "Get",
    onSuccess: dockConnection.type,
    onError: dockConnection.type,
    meta,
  });

export const getMobileConnection = () =>
  apiCallBegan({
    url: `/account/app/frontend/ws-connection?hub=mobile`,
    method: "Get",
    onSuccess: mobileConnection.type,
    onError: mobileConnection.type,
  });

export const getWsPermissions = (data, meta) =>
  apiCallBegan({
    url: `/devices/onboard/hub-permissions`,
    method: "Post",
    data,
    onSuccess: permissions.type,
    onError: permissions.type,
    meta,
  });

export const getFrontendWsPermissions = (data) =>
  apiCallBegan({
    url: `/account/frontend/wps/permissions`,
    method: "Post",
    data,
    onSuccess: frontendPermissions.type,
    onError: frontendPermissions.type,
  });

export const getDockWsPermissions = (data, meta) => {
  const {id} = data;

  return apiCallBegan({
    url: `/devices/app/docks/${id}/hub-permissions`,
    method: "Post",
    data,
    onSuccess: dockPermissions.type,
    onError: dockPermissions.type,
    meta,
  });
};

export const getMobileWsPermissions = (data) =>
  apiCallBegan({
    url: `/account/mobile/wps/permissions`,
    method: "Post",
    data,
    onSuccess: mobilePermissions.type,
    onError: mobilePermissions.type,
  });