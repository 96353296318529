import "./AddGuestRoles.sass";
import Form from "../../../../../ui/Form/Form";
import RadioButtonGroup from "../../../../../ui/RadioButtonGroup/RadioButtonGroup";
import { useEffect, useState } from "react";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";
import { groupBy } from "../../../../../helper/utils";
import { useDispatch, useSelector } from "react-redux";
import { setModel } from "../GuestManagementSlice";
import { getRolesList } from "../GuestManagementSlice";
import { setOperation } from "../../../AdminOperationManager/OperationStepSlice";

function AddGuestRoles({ onOpen, onConfirm }) {
  const [selected] = useState("application");
  const [currentOption, setCurrentOption] = useState("no-role");
  const [localRoles, setLocalRoles] = useState({
    application: [],
    administration: [],
  });
  const api = useSelector((state) => state.api.state);
  const guest = useSelector((state) => state.guest);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const radioCheckChangedHandler = (event) => {
    setCurrentOption(event.target.value);
  };

  const checkboxCheckChangedHandler = (event) => {
    setLocalRoles({
      administration: localRoles.administration.map((role) => ({
        ...role,
        check:
          role.id === event.target.name ? event.target.checked : role.check,
      })),
      application: localRoles.application.map((role) => ({
        ...role,
        check:
          role.id === event.target.name ? event.target.checked : role.check,
      })),
    });
  };

  useEffect(() => {
    if (api !== undefined) dispatch(getRolesList());
  }, [api]);

  useEffect(() => {
    console.log(guest.rolesList);
    if (guest.rolesList.status === "success")
      if (guest.rolesList.data !== undefined) {
        const tempRoles = [
          ...guest.rolesList.data.roles.map((item) => ({
            ...item,
            check: false,
          })),
        ];
        setLocalRoles({
          application: tempRoles.filter((item) => item.category === selected),
          administration: [tempRoles],
        });
      }
  }, [guest.rolesList]);

  useEffect(() => {
    if (localRoles[currentOption] !== undefined)
      setData([
        ...localRoles[currentOption]
          .filter((role) => role.check)
          .map((item) => ({ id: item.id, category: item.category })),
      ]);
  }, [localRoles]);

  useEffect(() => {
    onOpen();
    console.log(data);
  }, [data]);

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(setModel({ ...guest.model, Roles: [...data] }));
    dispatch(setOperation("guest_summary"));
  };

  return (
    <div className="manage-guest-roles">
      <Form
        data={{}}
        required={[]}
        validate={currentOption !== "application" || data.length > 0}
        submit={{
          title: "Next",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        className="manage-roles-container"
      >
        <p className="manage-roles-description">
          You can assign roles to selected guest.
        </p>
        <RadioButtonGroup
          className="radio-button-group"
          name="role"
          selected={currentOption}
          group={["no-role", "application"]}
          titles={["no-role", "Application Access Level"]}
          onChange={radioCheckChangedHandler}
        />
        <p>assign your selected role for this guest.</p>
        <ul className="role-groups">
          {Object.entries(groupBy(localRoles[currentOption], "category")).map(
            (category, cIndex) => (
              <li className="role-group-item" key={cIndex}>
                <span>{category[0]}</span>
                <ul className="role-items">
                  {category[1].map((role, rIndex) => (
                    <li className="role-item" key={rIndex}>
                      <CheckBox
                        title={role.description}
                        name={role.id}
                        text={role.name}
                        checked={role.check}
                        onChecked={checkboxCheckChangedHandler}
                      />
                    </li>
                  ))}
                </ul>
              </li>
            )
          )}
        </ul>
      </Form>
    </div>
  );
}

export default AddGuestRoles;
