import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { mobileConnectionManager } from 'src/helper/HubConnectionManager';
import { removeInactiveMobileClients, setMobileClientInfo } from '../UserServiceSlice';

export default function useMobileClientsObserver() {
  const inactiveIntervalId = useRef();
  const messageHandlerIds = useRef([]);
  const dispatch = useDispatch();

  useEffect(() => {
    inactiveIntervalId.current = setInterval(() => {
      dispatch(removeInactiveMobileClients({timeout: 10000}));
    }, 10000);

    messageHandlerIds.current = mobileConnectionManager.subscribeGroupMessages([
      {
        identity: 'statusMessages/*',
        name: ['mobileStatus'],
        handler: (message) => {
          if(message?.topic === 'mobile_status') {
            const payload = {
              id: message?.data?.device?.id,
              ...message?.data,
              viewerVideoStreamingConfig: message?.data?.viewerVideoStreamingConfig || null,
            };
  
            dispatch(setMobileClientInfo(payload));
          }
        },
      },
    ], 'mobile-device-service');

    return () => {
      mobileConnectionManager.unsubscribeGroupMessages(messageHandlerIds.current);
      clearInterval(inactiveIntervalId.current);
    }
  }, []);

  return {};
}
