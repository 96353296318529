import './CameraShutterSelector.sass'
import { useState } from 'react';
import { useOnboardHub } from 'src/components/Dashboard/Components/OnboardHubProvider/OnboardHubProvider';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { PayloadIndexes } from 'src/helper/HubConnection';
import VerticalValueSlider from '../../VerticalValueSlider/VerticalValueSlider';
import { useDeviceInfo } from 'src/helper/DeviceInfoProvider';

function CameraShutterSelector() {
  const [shutterSpeed, setShutterSpeed] = useState(0);
  const { hub, groups, deviceId } = useOnboardHub();
  const flight = useSelector((state) => state.flight);
  const { activeCameraPayload } = useDeviceInfo();
  const shutterValues = activeCameraPayload?.settings?.shutterSpeed?.values;

  useEffect(() => {
    if (flight.camera?.shutterSpeed)
      setShutterSpeed(flight.camera?.shutterSpeed);
  }, [flight.camera]);

  const shutterChanged = (value, index) => {
    setShutterSpeed(index);
    hub?.sendToGroup(deviceId, groups?.send?.commandChannel, {
      camSetting: {
        payloadIndex: activeCameraPayload?.payloadIndex,
        shutterSpeed: index,
      }
    });
  };

  return (
    <div className="camera-shutter-selector">
      <VerticalValueSlider
        values={shutterValues || []}
        currValue={shutterValues?.[shutterSpeed]}
        onValueChange={shutterChanged}
      />
    </div>
  )
}

export default CameraShutterSelector