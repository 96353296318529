import LabeledButton from "../../../ui/LabeledButton/LabeledButton";
import SearchOption from "../../../ui/SearchOption/SearchOption";
import "./AdminOptionBar.sass";

import { FiMoreHorizontal } from "react-icons/fi";
import { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import OutSideDetector from "../../../ui/OutsideDetector/OutSideDetector";
import { useTheme } from "../../../helper/ThemeProvider";
function AdminOptionBar({ base, buttons, searchable, onClick }) {
  const { currentContext } = useTheme();
  const [toggleMore, setToggleMore] = useState(false);
  const [baseButtons, setBaseButtons] = useState();
  const [moreButtons, setMoreButtons] = useState();
  useEffect(() => {
    setBaseButtons(buttons.slice(0, base));
    setMoreButtons(buttons.slice(base, buttons.length));
  }, [buttons]);
  const moreOptions = () => {
    setToggleMore(!toggleMore);
  };
  return (
    <div
      className="admin-option-bar"
      style={{ background: currentContext.theme.secondColor }}
    >
      <div className="option-bar-buttons">
        <ul className="option-bar-buttons-container">
          {baseButtons &&
            baseButtons.map((button, index) => (
              <li className="option-bar-button" key={index}>
                <LabeledButton
                  disabled={!button.active}
                  color={
                    button.active
                      ? button.selected
                        ? currentContext.theme.color
                        : currentContext.theme.textColor
                      : currentContext.theme.textColor + "99"
                  }
                  title={button.title}
                  icon={button.icon}
                  onClick={() => {
                    onClick(button.name);
                  }}
                />
              </li>
            ))}
        </ul>
        {moreButtons && moreButtons.length > 0 && (
          <div className="option-bar-more">
            {toggleMore ? (
              <>
                <GrClose onClick={moreOptions} />
                <OutSideDetector detect={moreOptions}>
                  <ul
                    className="option-bar-more-options"
                    style={{ background: currentContext.theme.secondColor }}
                  >
                    {moreButtons.map((button, index) => (
                      <li className="option-bar-button" key={index}>
                        <LabeledButton
                          disabled={!button.active}
                          color={
                            button.active
                              ? button.selected
                                ? currentContext.theme.color
                                : currentContext.theme.textColor
                              : currentContext.theme.textColor + "99"
                          }
                          title={button.title}
                          icon={button.icon}
                          onClick={() => {
                            moreOptions();
                            onClick(button.name);
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </OutSideDetector>
              </>
            ) : (
              <FiMoreHorizontal onClick={moreOptions} />
            )}
          </div>
        )}
      </div>

      {searchable && <SearchOption />}
    </div>
  );
}

export default AdminOptionBar;
