import DateComponent from "../DateComponent/DateComponent";
import TimeComponent from "../TimeComponent/TimeComponent";
import "./DateTimeComponent.sass";

function DateTimeComponent({ date, time, hour }) {
  return (
    <div className="date-time-component">
      <DateComponent date={date} />
      <TimeComponent time={time} hour={hour} />
    </div>
  );
}

export default DateTimeComponent;
