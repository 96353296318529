import { XMLParser, XMLBuilder } from 'fast-xml-parser';
import { length as turfLength, lineString as turfLineString } from '@turf/turf'

// Create XML for waypoint template
// config params:
// - droneEnumValue
// - droneSubEnumValue
// - payloadEnumValue
// - payloadSubEnumValue
// - payloadPositionIndex
// - autoFlightSpeed
// - globalHeight
// - waypoints
// -- lat
// -- long
// -- height

export function createWaypointTemplate(config) {
  const xmlParserOptions = {
    ignoreAttributes: false
  };

  const parser = new XMLParser(xmlParserOptions);
  let template = parser.parse(templateXML);

  // Create mission config
  const missionConfig = {
    ...template.kml.Document['wpml:missionConfig'],
    'wpml:droneInfo': {
      'wpml:droneEnumValue': config.droneEnumValue,
      'wpml:droneSubEnumValue': config.droneSubEnumValue
    },
    'wpml:payloadInfo': {
      'wpml:payloadEnumValue': config.payloadEnumValue,
      'wpml:payloadSubEnumValue': config.payloadSubEnumValue,
      'wpml:payloadPositionIndex': config.payloadPositionIndex
    },
    'wpml:globalRTHHeight': config.globalHeight + 10,
  };

  // Create waypoint folder
  const waypointFolder = {
    ...template.kml.Document.Folder,
    'wpml:autoFlightSpeed': config.autoFlightSpeed,
    'wpml:globalHeight': config.globalHeight,
  };

  // Create waypoint placemarks
  const placemarkTemplate = template.kml.Document.Folder.Placemark;
  const placemarks = config.waypoints.map((waypoint, index) => {
    return ({
      ...placemarkTemplate,
      'wpml:index': index,
      'wpml:ellipsoidHeight': waypoint.height ?? config.globalHeight,
      'wpml:height': waypoint.height ?? config.globalHeight,
      'Point': {
        'coordinates': `${waypoint.long},${waypoint.lat}`
      }
    });
  });

  waypointFolder.Placemark = placemarks;
  template.kml.Document['wpml:missionConfig'] = missionConfig;
  template.kml.Document['wpml:createTime'] = Date.now();
  template.kml.Document['wpml:updateTime'] = Date.now();
  template.kml.Document.Folder = waypointFolder;

  const xmlBuilderOptions = {
    ignoreAttributes: false,
    format: true,
  };

  const builder = new XMLBuilder(xmlBuilderOptions);
  const xmlString = builder.build(template);

  return xmlString;
}

// ===============================================================

// Create XML for waypoint waylines
// config params:
// - droneEnumValue
// - droneSubEnumValue
// - payloadEnumValue
// - payloadSubEnumValue
// - payloadPositionIndex
// - autoFlightSpeed
// - waypoints
// -- latitude
// -- longitude
// -- height
// -- speed

export function createWaypointWaylines(config) {
  const xmlParserOptions = {
    ignoreAttributes: false
  };

  const parser = new XMLParser(xmlParserOptions);
  let template = parser.parse(waylinesXML);

  // Create mission config
  const missionConfig = {
    ...template.kml.Document['wpml:missionConfig'],
    'wpml:droneInfo': {
      'wpml:droneEnumValue': config.droneEnumValue,
      'wpml:droneSubEnumValue': config.droneSubEnumValue
    },
    'wpml:payloadInfo': {
      'wpml:payloadEnumValue': config.payloadEnumValue,
      'wpml:payloadSubEnumValue': config.payloadSubEnumValue,
      'wpml:payloadPositionIndex': config.payloadPositionIndex
    },
    'wpml:globalRTHHeight': config.globalHeight + 10,
  };

  // Distance and time
  const {
    distance: totalDistance, 
    time: totalTimeInSeconds
  } = getCoordinatesDistanceAndTime(config.waypoints, config.autoFlightSpeed);

  // Create waypoint folder
  const waypointFolder = {
    ...template.kml.Document.Folder,
    'wpml:autoFlightSpeed': config.autoFlightSpeed,
    'wpml:distance': totalDistance,
    'wpml:duration': totalTimeInSeconds,
  };

  // Create waypoint placemarks
  const placemarkTemplate = template.kml.Document.Folder.Placemark;
  const placemarks = config.waypoints.map((waypoint, index) => {
    return ({
      ...placemarkTemplate,
      'wpml:index': index,
      'wpml:executeHeight': waypoint.height ?? config.globalHeight,
      'wpml:waypointSpeed': waypoint.speed ?? config.autoFlightSpeed,
      'Point': {
        'coordinates': `${waypoint.long},${waypoint.lat}`
      }
    });
  });

  waypointFolder.Placemark = placemarks;
  template.kml.Document['wpml:missionConfig'] = missionConfig;
  template.kml.Document.Folder = waypointFolder;

  const xmlBuilderOptions = {
    ignoreAttributes: false,
    format: true,
  };

  const builder = new XMLBuilder(xmlBuilderOptions);
  const xmlString = builder.build(template);

  return xmlString;
}

// Get distance between two coordinates (Haversine formula)
export function getCoordinateDistance(coordinate1, coordinate2) {
  return turfLength(turfLineString([
    [coordinate1.long || coordinate1.longitude, coordinate1.lat || coordinate1.latitude],
    [coordinate2.long || coordinate2.longitude, coordinate2.lat || coordinate2.latitude]
  ]));
}

// Calculate distance and time between waypoints
export function getCoordinatesDistanceAndTime (coordinates, speed) {
  let totalDistance = 0;
  if(!coordinates) return { distance: 0, time: 0 };

  for (let i = 0; i < coordinates?.length - 1; i++) {
    const coord1 = coordinates[i];
    const coord2 = coordinates[i + 1];

    // in meters
    const distance = getCoordinateDistance(coord1, coord2) * 1000;
    totalDistance += distance;
  }

  const totalTimeInSeconds = totalDistance / speed;

  return {
    distance: totalDistance,
    time: totalTimeInSeconds
  };
}


// ===============================================================

const templateXML = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:wpml="http://www.dji.com/wpmz/1.0.3">
  <Document>
    <wpml:createTime>1675066246838</wpml:createTime>
    <wpml:updateTime>1675066328791</wpml:updateTime>
    <wpml:missionConfig>
      <wpml:flyToWaylineMode>safely</wpml:flyToWaylineMode>
      <wpml:finishAction>noAction</wpml:finishAction>
      <wpml:exitOnRCLost>executeLostAction</wpml:exitOnRCLost>
      <wpml:executeRCLostAction>goBack</wpml:executeRCLostAction>
      <wpml:takeOffSecurityHeight>20</wpml:takeOffSecurityHeight>
      <wpml:globalTransitionalSpeed>15</wpml:globalTransitionalSpeed>
      <wpml:droneInfo>
        <wpml:droneEnumValue>77</wpml:droneEnumValue>
        <wpml:droneSubEnumValue>0</wpml:droneSubEnumValue>
      </wpml:droneInfo>
      <wpml:payloadInfo>
        <wpml:payloadEnumValue>66</wpml:payloadEnumValue>
        <wpml:payloadSubEnumValue>0</wpml:payloadSubEnumValue>
        <wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>
      </wpml:payloadInfo>
    </wpml:missionConfig>
    <Folder>
      <wpml:templateType>waypoint</wpml:templateType>
      <wpml:templateId>0</wpml:templateId>
      <wpml:waylineCoordinateSysParam>
        <wpml:coordinateMode>WGS84</wpml:coordinateMode>
        <wpml:heightMode>relativeToStartPoint</wpml:heightMode>
        <wpml:positioningType>GPS</wpml:positioningType>
      </wpml:waylineCoordinateSysParam>
      <wpml:autoFlightSpeed>5</wpml:autoFlightSpeed>
      <wpml:globalHeight>100</wpml:globalHeight>
      <wpml:caliFlightEnable>0</wpml:caliFlightEnable>
      <wpml:gimbalPitchMode>manual</wpml:gimbalPitchMode>
      <wpml:globalWaypointHeadingParam>
        <wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>
        <wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>
        <wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>
        <wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>
      </wpml:globalWaypointHeadingParam>
      <wpml:globalWaypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:globalWaypointTurnMode>
      <wpml:globalUseStraightLine>1</wpml:globalUseStraightLine>
      <Placemark>
        <Point>
          <coordinates>
            113.941785877178,22.5775436683768
          </coordinates>
        </Point>
        <wpml:index>13</wpml:index>
        <wpml:ellipsoidHeight>100</wpml:ellipsoidHeight>
        <wpml:height>100</wpml:height>
        <wpml:useGlobalHeight>1</wpml:useGlobalHeight>
        <wpml:useGlobalSpeed>1</wpml:useGlobalSpeed>
        <wpml:useGlobalHeadingParam>1</wpml:useGlobalHeadingParam>
        <wpml:useGlobalTurnParam>1</wpml:useGlobalTurnParam>
        <wpml:useStraightLine>0</wpml:useStraightLine>
      </Placemark>
      <wpml:payloadParam>
        <wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>
      </wpml:payloadParam>
    </Folder>
  </Document>
</kml>`;

// ===============================================================

const waylinesXML = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:wpml="http://www.dji.com/wpmz/1.0.3">
  <Document>
    <wpml:missionConfig>
      <wpml:flyToWaylineMode>safely</wpml:flyToWaylineMode>
      <wpml:finishAction>noAction</wpml:finishAction>
      <wpml:exitOnRCLost>executeLostAction</wpml:exitOnRCLost>
      <wpml:executeRCLostAction>goBack</wpml:executeRCLostAction>
      <wpml:takeOffSecurityHeight>20</wpml:takeOffSecurityHeight>
      <wpml:globalTransitionalSpeed>15</wpml:globalTransitionalSpeed>
      <wpml:droneInfo>
        <wpml:droneEnumValue>77</wpml:droneEnumValue>
        <wpml:droneSubEnumValue>0</wpml:droneSubEnumValue>
      </wpml:droneInfo>
      <wpml:payloadInfo>
        <wpml:payloadEnumValue>66</wpml:payloadEnumValue>
        <wpml:payloadSubEnumValue>0</wpml:payloadSubEnumValue>
        <wpml:payloadPositionIndex>0</wpml:payloadPositionIndex>
      </wpml:payloadInfo>
    </wpml:missionConfig>
    <Folder>
      <wpml:templateId>0</wpml:templateId>
      <wpml:executeHeightMode>relativeToStartPoint</wpml:executeHeightMode>
      <wpml:waylineId>0</wpml:waylineId>
      <wpml:distance>0</wpml:distance>
      <wpml:duration>0</wpml:duration>
      <wpml:autoFlightSpeed>5</wpml:autoFlightSpeed>
      <Placemark>
        <Point>
          <coordinates>
            113.941785877178,22.5775436683768
          </coordinates>
        </Point>
        <wpml:index>13</wpml:index>
        <wpml:executeHeight>100</wpml:executeHeight>
        <wpml:waypointSpeed>5</wpml:waypointSpeed>
        <wpml:waypointHeadingParam>
          <wpml:waypointHeadingMode>followWayline</wpml:waypointHeadingMode>
          <wpml:waypointHeadingAngle>0</wpml:waypointHeadingAngle>
          <wpml:waypointPoiPoint>0.000000,0.000000,0.000000</wpml:waypointPoiPoint>
          <wpml:waypointHeadingAngleEnable>0</wpml:waypointHeadingAngleEnable>
          <wpml:waypointHeadingPoiIndex>0</wpml:waypointHeadingPoiIndex>
        </wpml:waypointHeadingParam>
        <wpml:waypointTurnParam>
          <wpml:waypointTurnMode>toPointAndStopWithDiscontinuityCurvature</wpml:waypointTurnMode>
          <wpml:waypointTurnDampingDist>0</wpml:waypointTurnDampingDist>
        </wpml:waypointTurnParam>
        <wpml:useStraightLine>1</wpml:useStraightLine>
      </Placemark>
    </Folder>
  </Document>
</kml>`;