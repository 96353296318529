import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../../../helper/use-debounce";
import Form from "../../../../../ui/Form/Form";
import InputBox from "../../../../../ui/InputBox/InputBox";
import Select from "../../../../../ui/Select/Select";
import "./CreateGuest.sass";
import { check, create, setModel } from "../GuestManagementSlice";
import CheckBox from "../../../../../ui/CheckBox/CheckBox";
import { validateEmail } from "../../../../../helper/utils";
import { getList } from "../../CustomerManagement/CustomerManagementSlice";
import { setOperation } from "../../../AdminOperationManager/OperationStepSlice";

function CreateGuest({ onOpen, NextStep }) {
  const [icons, setIcons] = useState({ Email: "default" });
  const [validate, setValidate] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(undefined);
  const [alerts, setAlerts] = useState({});
  const countries = [
    { id: 1, name: "Australia", value: "Australia" },
    { id: 2, name: "United States", value: "United States" },
    { id: 3, name: "United Kingdom", value: "United Kingdom" },
    { id: 4, name: "Germany", value: "Germany" },
  ];
  const [customers, setCustomers] = useState([]);

  const guest = useSelector((state) => state.guest);
  const [data, setData] = useState(guest.model);
  const [required, setRequired] = useState([
    "FirstName",
    "LastName",
    "Email",
    "Password",
    "Country",
    "CustomerId",
  ]);
  const [Email, setEmail] = useState("");
  const [DisplayName, setDisplayName] = useState("");
  const DebouncedMail = useDebounce(Email, 1000);
  const DebouncedFirstName = useDebounce(data.FirstName, 500);
  const DebouncedLastName = useDebounce(data.LastName, 500);
  const api = useSelector((state) => state.api);
  const customer = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (DebouncedMail) {
      if (validateEmail(Email)) dispatch(check(Email));
    }
  }, [DebouncedMail]);

  useEffect(() => {
    onOpen();
  }, [data]);

  useEffect(() => {
    if (customer.list.data !== undefined)
      setCustomers(
        customer.list.data.customers.map((item) => ({
          name: item.businessName,
          value: item.id,
          id: item.id,
          region: item.region,
        }))
      );
  }, [customer.list]);

  useEffect(() => {
    if (DebouncedFirstName || DebouncedLastName) {
      setDisplayName(
        data.FirstName !== undefined && data.LastName !== undefined
          ? data.FirstName + " " + data.LastName
          : ""
      );
    }
  }, [DebouncedFirstName, DebouncedLastName]);

  useEffect(() => {
    if (api !== undefined) dispatch(getList());
  }, [api]);

  useEffect(() => {
    if (guest.mail.status === "forbidden") {
      setAlerts({
        ...alerts,
        Email: {
          alert: true,
          type: "error",
          message: guest.mail.message.error,
        },
      });
    }
    setIcons({ ...icons, Email: guest.mail.status });
    setValidate(guest.mail.status === "success");
  }, [guest.mail]);

  useEffect(() => {
    if (data.AutoGeneratedPassword === true) {
      setRequired(
        required.filter((item) => {
          return item !== "Password";
        })
      );
      setValidate(true);
      const { Password: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    } else {
      setRequired([
        ...required.filter((item) => {
          return item !== "Password";
        }),
        "Password",
      ]);
    }
  }, [data.AutoGeneratedPassword]);

  useEffect(() => {
    if (DisplayName !== "") {
      setData({ ...data, DisplayName: DisplayName });
    } else {
      const { DisplayName: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }
  }, [DisplayName]);

  useEffect(() => {
    if (guest.create.status === "validation") {
      let validations = {};
      guest.create.errors.forEach((item) => {
        validations[item.propertyName] = {
          alert: true,
          type: "error",
          message: item.errorMessage,
        };
      });
      setAlerts({ ...alerts, ...validations });
    }
  }, [guest.create]);

  const changeHandler = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    setAlerts({ ...alerts, [key]: { alert: false } });

    if (key === "Email") {
      setIcons({ ...icons, Email: "default" });
      setEmail(value);
      setValidate(false);
    }
    if (key === "AutoGeneratedPassword") {
      value = event.target.checked;
    }

    if (value.toString() !== "") {
      setData({ ...data, [key]: value });
    } else {
      const { [key]: value, ...withoutSecond } = data;
      setData({ ...withoutSecond });
    }

    if (key === "CustomerId") {
      setCurrentCustomer(
        customers.filter((item) => item.id === event.target.value)[0]
      );
    }
  };

  useEffect(() => {
    if (currentCustomer !== undefined)
      setData({
        ...data,
        Region: currentCustomer.region,
        CustomerName: currentCustomer.name,
      });
  }, [currentCustomer]);

  const focusHandler = (event) => {
    let key = event.target.name;
    setAlerts({ ...alerts, [key]: { alert: false } });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(create({ status: "" }));
    dispatch(setModel({ ...data }));
    dispatch(setOperation(NextStep));
  };

  return (
    <div className="create-guest-form">
      <Form
        submit={{
          title: "Next",
          width: "40%",
          color: "#2699fb",
          action: submitHandler,
        }}
        data={data}
        required={required}
        className="create-guest-container"
        validate={validate}
      >
        <div className="create-guest-section">
          <InputBox
            title="First Name"
            name="FirstName"
            isRequired={required.includes("FirstName")}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.FirstName}
            value={data.FirstName}
            index={0}
            background="white"
            width="48%"
          />

          <InputBox
            title="Last Name"
            name="LastName"
            isRequired={required.includes("LastName")}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.LastName}
            value={data.LastName}
            index={1}
            background="white"
            width="48%"
          />
        </div>

        <InputBox
          title="Email"
          name="Email"
          isRequired={required.includes("Email")}
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.Email}
          value={data.Email}
          index={2}
          icon={icons.Email}
          background="white"
          type="email"
        />
        <CheckBox
          name="AutoGeneratedPassword"
          text="Automatically generate password"
          onChecked={changeHandler}
          checked={data.AutoGeneratedPassword}
        />
        {!data.AutoGeneratedPassword && (
          <InputBox
            title="Password"
            name="Password"
            isRequired={required.includes("Password")}
            type="password"
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.Password}
            value={data.Password}
            index={3}
            background="white"
          />
        )}

        <div className="create-guest-section">
          <InputBox
            isRequired={required.includes("DisplayName")}
            title="Display Name"
            name="DisplayName"
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.DisplayName}
            value={data.DisplayName}
            index={4}
            background="white"
            width="48%"
          />

          <Select
            isRequired={required.includes("Country")}
            title="Country"
            name="Country"
            items={countries}
            onChange={changeHandler}
            onFocus={focusHandler}
            status={alerts.Country}
            value={data.Country}
            index={5}
            background="white"
            width="48%"
          />
        </div>
        <Select
          isRequired={required.includes("CustomerId")}
          title="Customer"
          name="CustomerId"
          items={customers}
          onChange={changeHandler}
          onFocus={focusHandler}
          status={alerts.CustomerId}
          value={data.CustomerId}
          index={5}
          background="white"
          width="100%"
        />
      </Form>
    </div>
  );
}

export default CreateGuest;
