import React from 'react'
import { useAppTheme } from 'src/helper/AppThemeProvider';

function BellIcon({ className, indicator = false }) {
  const [theme] = useAppTheme();
  const color = theme === 'dark' ? '#fff' : '#666';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39" className={className}>
      <g data-name="Group 41932" transform="translate(-1.5 -1.5)">
        <path data-name="Path 76714" d="M21 2a19 19 0 1 0 19 19A19.007 19.007 0 0 0 21 2zm0 31.35a2.846 2.846 0 0 1-2.85-2.85h5.7A2.846 2.846 0 0 1 21 33.35zm7.6-4.75H13.4a1.906 1.906 0 0 1-1.9-1.9 1.906 1.906 0 0 1 1.9-1.9v-5.7a7.6 7.6 0 0 1 5.7-7.334V10.55a1.9 1.9 0 1 1 3.8 0v1.216a7.6 7.6 0 0 1 5.7 7.334v5.7a1.906 1.906 0 0 1 1.9 1.9 1.906 1.906 0 0 1-1.9 1.9z" style={{ fill: "none", stroke: color || "#2699fb" }} />
        {indicator ? <circle data-name="Ellipse 3" cx="4" cy="4" r="4" transform="translate(29.048 2)" style={{ fill: color }} /> : ''}
      </g>
    </svg>
  );
}

export default BellIcon