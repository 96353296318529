import { useEffect, useState } from "react";
import "./OnboardDeviceInformation.sass";
import deviceChart from "../../../../../../assets/img/device-chart.svg";
import { useSelector } from "react-redux";
import LabeledButton from "../../../../../../ui/LabeledButton/LabeledButton";
import { ImQrcode } from "react-icons/im";
import { MdDoNotDisturbAlt } from "react-icons/md";
import ApplicationComponentContent from "../../../../Components/ApplicationComponentContent/ApplicationComponentContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LabeledInformationContainer from "../../../../Components/LabeledInformationContainer/LabeledInformationContainer";
import { TbPlaneInflight } from "react-icons/tb";
function OnboardDeviceInformation() {
  const [information, setInformation] = useState({});
  const [data, setData] = useState({});
  const onboard = useSelector((state) => state.onboard);
  const history = useHistory();

  const gotoFlight = () => {
    setTimeout(() => {
      history.push(
        `/dashboard/device-app/onboard/flight-app/${data.name}/${data.id}/${data.serialNumber}`
      );
    }, 1000);
  };
  const regenerateCode = () => {
    console.log("regenerate code");
  };

  const actions = [
    {
      name: "goto_flight",
      active: true,
      component: (
        <LabeledButton
          width="100%"
          title="Go to Flight"
          icon={<TbPlaneInflight />}
          onClick={gotoFlight}
        />
      ),
    },
  ];
  const controls = [
    {
      name: "regenerate_device_token",
      active: false,
      component: (
        <LabeledButton
          title="Regenerate Code"
          icon={<ImQrcode />}
          action={regenerateCode}
        />
      ),
    },
    {
      name: "disable_device",
      active: false,
      component: (
        <LabeledButton
          title="Disable Device"
          icon={<MdDoNotDisturbAlt />}
          action={regenerateCode}
        />
      ),
    },
  ];

  useEffect(() => {
    setData({ ...onboard?.droneModel });
  }, [onboard.droneModel]);

  useEffect(() => {
    setInformation({
      "Device Model": data.name,
      "Device Name": data.deviceName,
      "Registered Date": data.registeredDate,
      "Registered By": data.registeredBy,
      "Video Streaming Source": data.videoStreamingRegion,
      "Data Streaming Source": data.dataStreamingRegion,
    });
  }, [data]);

  return (
    <ApplicationComponentContent controls={controls} actions={actions}>
      <LabeledInformationContainer information={information} />
      {/* <div className="onboard-device-charts">
          <h4>{information["Device Name"]} Charts</h4>
          <img src={deviceChart} alt="Device Chart" />
          <img src={deviceChart} alt="Device Chart" />
        </div> */}
    </ApplicationComponentContent>
  );
}

export default OnboardDeviceInformation;
