import "./HeaderLoading.sass";
import Loading from "src/ui/Loading/Loading";
import Header from "src/components/Header/Header";

function HeaderLoading() {
  return (
    <>
      <Header />
      <div className="header-loading">
        <p> Please wait a moment ...</p>

        <Loading />
      </div>
    </>
  );
}

export default HeaderLoading;
