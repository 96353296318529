const CameraModeIcon = ({size = '1em', autoMode, ...props}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 11 9"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <g transform="matrix(1,0,0,1,-0.00663102,0.0103316)">
      <g id="icon">
        <g id="camera" transform="matrix(1,0,0,1,-0.743369,-1.88533)">
          <path
            d="M10.125,3.375L8.742,3.375C8.672,3.375 8.585,3.33 8.517,3.258L7.909,2.298C7.899,2.283 7.888,2.269 7.877,2.255C7.667,2.01 7.383,1.875 7.078,1.875L4.922,1.875C4.617,1.875 4.333,2.01 4.123,2.255C4.112,2.269 4.101,2.283 4.091,2.298L3.483,3.259C3.431,3.316 3.358,3.376 3.281,3.376L3.281,3.189C3.281,2.983 3.112,2.814 2.906,2.814L2.344,2.814C2.138,2.814 1.969,2.983 1.969,3.189L1.969,3.376L1.875,3.376C1.258,3.377 0.751,3.884 0.75,4.501L0.75,9C0.751,9.617 1.258,10.124 1.875,10.125L10.125,10.125C10.742,10.124 11.249,9.617 11.25,9L11.25,4.5C11.249,3.883 10.742,3.376 10.125,3.375Z"
            style={{
              fillRule: "nonzero",
              fill: "white"
            }}
          />
        </g>
        <text
          x="48%"
          y={autoMode ? "65%" : "68%"}
          style={{
            fontFamily: "'Verdana-Bold', 'Verdana', sans-serif",
            fontWeight: "bold",
            fontSize: autoMode ? "0.09em" : "0.13em",
            fill: "black",
          }}
          textAnchor="middle"
        >
          {autoMode ? "AUTO" : "M"}
        </text>
      </g>
    </g>
  </svg>
)

export default CameraModeIcon