import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../redux/action/api";

const CaptureSlice = createSlice({
  name: "capture",
  initialState: { status: "" },
  reducers: {
    capture(state, action) {
      const data = action.payload;
      return { ...state, ...data };
    },
  },
});
export const { capture } = CaptureSlice.actions;

export default CaptureSlice.reducer;

export const captureImage = (data) =>
  apiCallBegan({
    baseURI: "https://iroboticsdevices.azurewebsites.net/",
    url:
      "api/device/new/1ZNDH5K00AKMQ4?code=qQb1VkfSyrtBN69I9iBcSxOQ0DXjAoOsi4tPcoodVuW57r2WyXyPKg==",
    method: "Post",
    data,
    onSuccess: capture.type,
    onError: capture.type,
  });
