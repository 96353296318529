import { createSlice, current } from "@reduxjs/toolkit";
import { remove } from "jszip";

const FlightServiceSlice = createSlice({
  name: "flightService",
  initialState: {
    liveFlights: {},
    drcState: {},
  },
  reducers: {
    setLiveFlight(state, action) {
      const data = action.payload;

      if(data.deviceId)
        state.liveFlights[data.deviceId] = data;
    },
    removeLiveFlight(state, action) {
      const deviceId = action.payload;
      delete state.liveFlights[deviceId];
    },
    setDeviceDrcState(state, action) {
      const {deviceId, data} = action.payload;
      state.drcState[deviceId] = data;
    },
    removeDeviceDrcState(state, action) {
      const deviceId = action.payload;
      delete state.drcState[deviceId];
    },
  }
});

export const {
  setLiveFlight,
  removeLiveFlight,
  setDeviceDrcState,
  removeDeviceDrcState,
} = FlightServiceSlice.actions;
export default FlightServiceSlice.reducer;