import { useSelector } from "react-redux";
import Button from "../../../../../ui/Button/Button";
import SearchableList from "../../../../../ui/SearchableList/SearchableList";
import { searchUsers } from "../../UserManagement/UserManagementSlice";
import RoleResource from "../RoleResource/RoleResource";
import "./RoleAssignment.sass";

function RoleAssignment() {
  const result = useSelector((state) => state.user.search.data);
  const submitHandler = () => {
    console.log("submit");
  };
  return (
    <RoleResource
      render={(data) => (
        <div className="role-assignment">
          <div className="role-assignment-container">
            <span>Selected Role : {data.name.value}</span>
            <SearchableList
              minLength={1}
              tag="user/search"
              searchHandler={searchUsers}
              feed={
                result?.users !== undefined
                  ? result.users.map((item) => {
                      return {
                        id: item.id,
                        title: item.displayName,
                        subtitle: item.emailAddress,
                      };
                    })
                  : []
              }
              title="Search for users to assign this role"
              searchCaption="Selected users for add to this role"
            />
          </div>
          <Button
            value="Select"
            color="#2699fb"
            width="30%"
            onClick={submitHandler}
          />
        </div>
      )}
    />
  );
}

export default RoleAssignment;
