import './EditDeviceOptions.sass'

import React, { useEffect, useMemo } from 'react'
import { usePrivileges } from 'src/helper/AccountStateProvider';
import Tabs from 'src/ui/Tabs/Tabs';
import EditDevice from '../EditDevice/EditDevice';
import GroupAssignment from '../GroupAssignment/GroupAssignment';
import EditDeviceDfrOptions from '../EditDeviceDfrOptions/EditDeviceDfrOptions';

function EditDeviceOptions({ onOpen, onConfirm, defaultIndex }) {
  const { privileges } = usePrivileges();
  const permissions = useMemo(() => privileges?.admins.find(item => item.id === "devices")?.privileges, [privileges]);
  const hasPermission = (id) => permissions?.includes(id);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className="edit-device-options">
      <Tabs
        defaultIndex={defaultIndex}
        items={[
          {
            title: "Edit Device",
            content: <EditDevice onConfirm={onConfirm} />,
            hidden: !hasPermission('edit_device')
          },
          {
            title: "Group Assignment",
            content: <GroupAssignment onConfirm={onConfirm} setAsActive={false} />,
            hidden: !hasPermission('device_groups_assignment')
          },
          // {
          //   title: "DFR Options",
          //   content: <EditDeviceDfrOptions onConfirm={onConfirm} setAsActive={false} />,
          //   hidden: !hasPermission('edit_device')
          // },
        ]}
      />
    </div>
  )
}

export default EditDeviceOptions