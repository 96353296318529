import moment from "moment";
import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { IoIosCreate } from "react-icons/io";
import { MdCreateNewFolder, MdDeleteForever, MdRefresh } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "src/ui/Avatar/Avatar";
import { usePrivileges } from "../../../../../helper/AccountStateProvider";
import CircleAvatar from "../../../../../ui/CircleAvatar/CircleAvatar";
import DateTimeComponent from "../../../../../ui/DateTimeComponent/DateTimeComponent";
import Dialog from "../../../../../ui/Dialog/Dialog";
import EntityName from "../../../../../ui/EntityName/EntityName";
import LabeledButton from "../../../../../ui/LabeledButton/LabeledButton";
import SearchOption from "../../../../../ui/SearchOption/SearchOption";
import SVGAvatar from "../../../../../ui/SVGAvatar/SVGAvatar";
import Table from "../../../../../ui/Table/Table";
import ApplicationComponentContent from "../../../../Dashboard/Components/ApplicationComponentContent/ApplicationComponentContent";
import AdminOperationManager from "../../../AdminOperationManager/AdminOperationManager";
import { deleteContainer, getList, setModel } from "../StorageContainerSlice";
import CreateContainer from "./CreateContainer/CreateContainer";
import EditContainer from "./EditContainer/EditContainer";
import EditContainerOptions from "./EditContainerOptions/EditContainerOptions";
import GroupAssignment from "./GroupAssignment/GroupAssignment";
import "./StorageContainerManagement.sass";

function StorageContainerManagement() {
  const [permissions, setPermissions] = useState([]);
  const [selectedRow, setSelectedRow] = useState(undefined);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const { privileges } = usePrivileges();
  const dispatch = useDispatch();
  const [operationHandler, setOperationHandler] = useState(false);
  const [operationHandlerOpen, setOperationHandlerOpen] = useState(false);
  const storage = useSelector((state) => state.storage);
  const container = useSelector((state) => state.container);
  const [currentStorage, setCurrentStorage] = useState();
  const [selectedFeature, setSelectedFeature] = useState({});
  const [currentFeature, setCurrentFeature] = useState();
  const [isFeatureManagerOpen, setIsFeatureManagerOpen] = useState(false);
  const [featureManager, setFeatureManager] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const toggleFeatureManagerOn = () => {
    setFeatureManager(true);
    setIsFeatureManagerOpen(true);
  };

  const [tableRows, setTableRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Container Name", value: "name", status: true },
      { title: "Description", value: "description", status: false },
      { title: "Created By", value: "createdBy", status: true },
      { title: "Registered Date", value: "registeredDate", status: true },
    ],
    values: [],
    options: [],
  });

  const dataOptions = [
    {
      title: "Delete Container",
      icon: <MdDeleteForever />,
      name: "delete_container",
      active: true,
    },
  ];

  useEffect(() => {
    if (container.list?.data !== undefined) {
      setTableRows({
        ...tableRows,
        values: [
          ...container.list?.data.containers.map((item) => ({
            ...item,
            createdBy: (
              <EntityName
                item={{
                  online: false,
                  title: item.createdBy?.displayName,
                  subtitle: item.createdBy?.emailAddress,
                  icon: item.createdBy?.avatar ? (
                    <Avatar
                      image={item.createdBy?.avatar}
                      isCovered={true}
                      width="32px"
                      height="32px"
                    />
                  ) : (
                    <SVGAvatar />
                  ),
                }}
              />
            ),
            registeredDate: (
              <DateTimeComponent
                date={moment(item.creationDate).format("DD MMMM YYYY")}
                time={moment(item.creationDate).format("hh:mm A")}
                hour={parseInt(moment(item.creationDate).format("h"))}
              />
            ),
          })),
        ],
      });
    }
  }, [container.list]);

  useEffect(() => {
    if (container.create.status === "success") {
      dispatch(getList(currentStorage?.id));
    }
  }, [container.create]);

  useEffect(() => {
    if (container.update.status === "success") {
      dispatch(getList(currentStorage?.id));
    }
  }, [container.update]);

  useEffect(() => {
    if (container.remove.status === "success") {
      dispatch(getList(currentStorage?.id));
    }
  }, [container.remove]);

  useEffect(() => {
    if (container.remove.status === "success") {
      dispatch(getList(currentStorage?.id));
    }
  }, [container.remove]);

  useEffect(() => {
    if (container.groupAssignment.status === "success") {
      dispatch(getList(currentStorage?.id));
    }
  }, [container.groupAssignment]);

  useEffect(() => {
    if (storage.model?.id !== undefined) {
      setCurrentStorage({ ...storage?.model });
      toggleOperationHandlerOff();
      dispatch(getList(storage?.model?.id));
    }
  }, [storage.model]);

  const toggleFeatureManagerOff = () => {
    setFeatureManager(false);
    setIsFeatureManagerOpen(false);
  };

  useEffect(() => {
    if (privileges.admins !== undefined) {
      if (
        privileges.admins.find((item) => item.id === "storages") !== undefined
      )
        setPermissions([
          ...privileges.admins
            .filter((app) => app.id === "storages" || app.id === "containers")
            .map((app) => app.privileges.filter((pr) => pr !== ""))
            .reduce((result, current) => result.concat(current), []),
        ]);
    }
  }, [privileges.admins]);

  const removeContainer = (data) => {
    dispatch(deleteContainer(data.storageId, data.id));
  };

  const closeFeatureManager = () => {
    setFeatureManager(false);
  };

  const userFeatures = [
    {
      id: "create_container",
      title: "Create Container",
      description: "You can create container for selected storage.",
      icon: <MdCreateNewFolder />,
      color: "#2699fb",
      type: "form",
      actionGenerator: () => {
        toggleFeatureManagerOn();
      },
      steps: [
        {
          name: "create_container",
          title: "Container Information",
          active: true,
          visible: true,
          locked: false,
          closable: false,
          tags: ["container/create"],
          activity: (
            <CreateContainer
              onConfirm={closeFeatureManager}
              onOpen={toggleFeatureManagerOn}
            />
          ),
        },
      ],
    },
    {
      id: "edit_container",
      title: "Edit Container",
      description: "You can edit container for selected storage.",
      icon: <IoIosCreate />,
      color: "#2699fb",
      type: "form",
      actionGenerator: () => {
        toggleFeatureManagerOn();
      },
      steps: [
        {
          name: "edit_container",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["container/update", "group/details", "container/groupAssignment"],
          activity: (
            <EditContainerOptions />
          ),
        },
      ],
    },
    {
      id: "delete_container",
      title: "Delete Container",
      confirm: "Are you sure for deleting selected container?",
      description:
        "You can delete a container under your company storage account",
      type: "dialog",
      actionGenerator: removeContainer,
    },
  ];

  const toggleOperationHandlerOff = () => {
    setOperationHandler(false);
  };

  const triggerCurrentOption = (name) => {
    const feature = userFeatures.find((u) => u.id === name);
    if (feature !== undefined) {
      setCurrentFeature({ ...feature });
    }
  };

  useEffect(() => {
    if (currentFeature !== undefined)
      if (currentFeature.type === "dialog") {
        setShowDialog(true);
        setOperationHandler(false);
        setFeatureManager(false);
      } else if (currentFeature.type === "form") toggleFeatureManagerOn();
  }, [currentFeature]);

  const [controlsData, setControlsData] = useState([]);
  const controls = [
    {
      name: "create_container",
      dependent: !currentStorage?.isDeleted,
      component: (
        <LabeledButton
          title="Create Container"
          icon={<MdCreateNewFolder size="15" />}
          onClick={() => {
            triggerCurrentOption("create_container");
          }}
        />
      ),
    },
    {
      name: "list_storage_containers",
      dependent: !currentStorage?.isDeleted,
      component: (
        <LabeledButton
          title="Refresh"
          icon={<MdRefresh size="15" />}
          onClick={() => {
            dispatch(getList(currentStorage?.id));
          }}
        />
      ),
    },
    {
      name: "delete_container",
      dependent: true,
      component: (
        <LabeledButton
          title="Delete Container"
          icon={<MdDeleteForever size="15" />}
          disabled={true}
          onClick={() => {
            triggerCurrentOption("delete_container");
          }}
        />
      ),
    },
  ];
  useEffect(() => {
    setControlsData([
      ...controls.map((control) => ({
        ...control,
        component: {
          ...control.component,
          props: {
            ...control.component.props,
            disabled: !isItemSelected && control.name === "delete_container",
          },
        },
        active: permissions.includes(control.name) && control.dependent,
      })),
    ]);

    setTableRows({
      ...tableRows,
      options: dataOptions.filter((option) =>
        permissions.includes(option.name)
      ),
    });
  }, [permissions, currentStorage, isItemSelected]);

  const toggleOperationHandlerOn = () => {
    setOperationHandler(true);
    setOperationHandlerOpen(true);
  };

  const rowChangeHandler = (row) => {
    if (row !== undefined) {
      dispatch(setModel({ ...row }));
      setSelectedRow(row);
      toggleOperationHandlerOn();
      setSelectedFeature({
        ...userFeatures.find((x) => x.id === "edit_container"),
        steps: userFeatures
          .find((x) => x.id === "edit_container")
          .steps.filter((s) => permissions?.includes(s.name))
          .map((step) => ({
            ...step,
            visible: true,
          })),
        info: (
          <EntityName
            item={{
              title: row?.name,
              subtitle: row?.memberType,
              icon: (
                <CircleAvatar
                  name={row?.name.substring(0, 2).toUpperCase()}
                  fontSize=".9em"
                  size="2.25"
                  color="#23333d"
                />
              ),
            }}
          />
        ),
      });
    }
  };

  const rowsChangeHandler = (rows) => {
    if (rows !== undefined) {
      setIsItemSelected(rows.length === 1);
    }
  };

  const CloseDialogHandler = () => {
    setShowDialog(false);
  };
  const ConfirmDialogHandler = () => {
    currentFeature.actionGenerator(selectedRow);
    setShowDialog(false);
    setControlsData([
      ...controls.map((control) => ({
        ...control,
        component: {
          ...control.component,
          props: {
            ...control.component.props,
            disabled: control.name === "delete_container",
          },
        },
        active: permissions.includes(control.name) && control.dependent,
      })),
    ]);
  };

  return (
    <div className="storage-container-management">
      <ApplicationComponentContent
        controls={controlsData}
        feature={selectedFeature}
        toggleOperation={operationHandler}
        onCloseOperationManager={toggleOperationHandlerOff}
      >
        <div className="storage-container-management-header">
          <h4>Storage Containers</h4>
          <div className="storage-container-management-header-info">
            <p>All Storage Containers listed in the table.</p>
            <SearchOption />
          </div>
        </div>
        <Table
          rows={tableRows}
          onRowChange={rowChangeHandler}
          onRowsChange={rowsChangeHandler}
          optionChangeHandler={triggerCurrentOption}
          tags={["container/list", "container/update", "container/remove"]}
          selectable={false}
        />
        {isFeatureManagerOpen && (
          <AdminOperationManager
            isOpen={featureManager}
            toggle={toggleFeatureManagerOff}
            close={closeFeatureManager}
            selectedFeature={currentFeature}
          />
        )}
        {showDialog && (
          <Dialog
            model={{
              message: currentFeature?.confirm,
              CloseHandler: CloseDialogHandler,
              ConfirmHandler: ConfirmDialogHandler,
            }}
          />
        )}
      </ApplicationComponentContent>
    </div>
  );
}

export default StorageContainerManagement;
