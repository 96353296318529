/* eslint-disable react-hooks/exhaustive-deps */
import { InteractionStatus } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginRequest } from "../../helper/AuthConfigProvider";
import { loading } from "../../ui/Loading/LoadingSlice";
import { setApiToken, setApiState } from "./ApiProviderSlice";

export const ApiProvider = ({ children }) => {
  const { instance, accounts, inProgress } = useMsal();
  const { loginRequest } = useLoginRequest();
  const dispatch = useDispatch();
  const [isCalled, setIsCalled] = useState(false);

  useEffect(() => {
    if(accounts.length) {
      instance.setActiveAccount(accounts[0]);
      setIsCalled(inProgress === InteractionStatus.None);
    }
  }, [accounts, inProgress]);

  useEffect(() => {
    if (isCalled) {
      const tokenRequest = {
        ...loginRequest,
        account: instance.getActiveAccount(),
      };

      dispatch(loading({ status: true }));
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          dispatch(setApiToken({ token: response.accessToken }));
          dispatch(setApiState(parseJwt(response.accessToken)));
          dispatch(loading({ status: false }));
        })
        .catch((error) => {
          dispatch(loading({ status: false }));
          instance.acquireTokenRedirect(tokenRequest);
        });
    }
  }, [isCalled]);
  return children;
};
const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};
