import './VerticalValueSlider.sass'
import SwiperSlider from 'src/ui/SwiperSlider/SwiperSlider'
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

function VerticalValueSlider({
  onValueChange,
  initialValue,
  values,
  currValue,
}) {
  const externalChangeTimeoutId = useRef();
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideItems, setSlideItems] = useState([]);
  const [lockExternalUpdate, setLockExternalUpdate] = useState(false);
  const [externalUpdateApplied, setExternalUpdateApplied] = useState(true);

  const onSlideChange = (index) => {
    if (!externalUpdateApplied && Array.isArray(values))
      onValueChange?.(values[index], index);
  }

  const findSlideIndex = (value) => {
    let index = -1;

    if (Array.isArray(values)) {
      index = values.findIndex(item => item === value);
    }

    return index === undefined ? 0 : index;
  }

  useEffect(() => {
    if (!lockExternalUpdate) {
      setActiveSlide(findSlideIndex(currValue) ?? 0);
      setExternalUpdateApplied(true);
    }
  }, [values, currValue, lockExternalUpdate]);

  useEffect(() => {
    if (!Array.isArray(values)) return;

    const items = values.map(item => ({
      component: item,
    }));

    setSlideItems(items);
  }, [values]);

  return (
    <div
      className="vertical-value-slider"
      onMouseDown={() => {
        clearTimeout(externalChangeTimeoutId.current);
        setExternalUpdateApplied(false);
        setLockExternalUpdate(true);
      }}
      onMouseUp={() => {
        externalChangeTimeoutId.current = setTimeout(() => {
          setLockExternalUpdate(false);
        }, 3000);
      }}
    >
      <div className="vertical-value-slider-swiper">
        <SwiperSlider
          id="camera-iso-swiper"
          onSlideChange={onSlideChange}
          b320={5}
          b1024={5}
          b1280={5}
          initialSlide={findSlideIndex(initialValue)}
          items={slideItems}
          activeColor="#00b938"
          vicinityColor="white"
          passiveColor="#acacac"
          customActive={true}
          activeSlide={activeSlide}
          direction="vertical"
          centeredSlides={true}
        />
      </div>
    </div>
  )
}

export default VerticalValueSlider