import "./Joystick.sass";
import { createRef, useEffect, useState } from "react";
import JoyHandle from "../../assets/img/joy-handle.svg";

function Joystick({
  maxDistance,
  deadzone,
  isHorizontal,
  isVertical,
  plate,
  handle,
  onChange,
  keyboardMoveRate,
  upKey,
  downKey,
  leftKey,
  rightKey
}) {
  const [active, setActive] = useState(false);
  const [dragStart, setDragStart] = useState(null);
  const [touchId, setTouchId] = useState();
  const [value, setValue] = useState();
  const [keydownMap, setKeydownMap] = useState({});
  const stick = createRef();

  function handleDown(event) {
    event.preventDefault();
    setActive(true);
    stick.current.style.transition = "0s";
    if (event.changedTouches)
      setDragStart({
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY,
      });
    else setDragStart({ x: event.clientX, y: event.clientY });
    if (event.changedTouches) setTouchId(event.changedTouches[0].identifier);
  }

  const handleMove = (event) => {
    if (!active || stick.current === null) return;
    let touchmoveId = null;
    if (event.changedTouches) {
      for (let i = 0; i < event.changedTouches.length; i++) {
        if (touchId === event.changedTouches[i].identifier) {
          touchmoveId = i;
          event.clientX = event.changedTouches[i].clientX;
          event.clientY = event.changedTouches[i].clientY;
        }
      }

      if (touchmoveId == null) return;
    }

    const xDiff = event.clientX - dragStart.x;
    const yDiff = event.clientY - dragStart.y;
    const angle = Math.atan2(yDiff, xDiff);
    const distance = Math.min(maxDistance, Math.hypot(xDiff, yDiff));
    const xPosition = isVertical ? 0 : distance * Math.cos(angle);
    const yPosition = isHorizontal ? 0 : distance * Math.sin(angle);
    stick.current.style.transform = `translate3d(${xPosition}px, ${yPosition}px, 0px)`;
    const distance2 =
      distance < deadzone
        ? 0
        : (maxDistance / (maxDistance - deadzone)) * (distance - deadzone);
    const xPosition2 = isVertical ? 0 : distance2 * Math.cos(angle);
    const yPosition2 = isHorizontal ? 0 : distance2 * Math.sin(angle);
    const xPercent = parseFloat((xPosition2 / maxDistance).toFixed(4));
    const yPercent = parseFloat((yPosition2 / maxDistance).toFixed(4));

    setValue({ x: xPercent, y: yPercent });
  };

  const handleUp = (event) => {
    if (!active || stick.current === null) return;
    if (event.changedTouches && touchId !== event.changedTouches[0].identifier)
      return;
    stick.current.style.transition = ".2s";
    stick.current.style.transform = `translate3d(0px, 0px, 0px)`;
    setValue({ x: 0, y: 0 });
    setTouchId(null);
    setActive(false);
  };

  const handleKeyboard = (event) => {
    var key = event.code.replace("Arrow", ""); // ArrowUp -> Up, etc... for Edge and IE 11
    if(event.repeat) return;

    if(key === upKey || key === downKey || key === leftKey || key === rightKey){
      setKeydownMap(current => ({
        ...current,
        [key]: event.type === "keydown",
      }));

      if(key === "Up" || key === "Down" || key === "Left" || key === "Right"){
        event.preventDefault();
      }
    }
  }

  useEffect(function setKeyboardEventListeners() {
    document.addEventListener('keydown', handleKeyboard);
    document.addEventListener('keyup', handleKeyboard);

    return () => {
      document.removeEventListener('keydown', handleKeyboard);
      document.removeEventListener('keyup', handleKeyboard);
    }
  }, []);

  useEffect(function handleKeydownChanges() {
    const stickMoveRatio = 20;

    const xValue = keydownMap[leftKey] ? -keyboardMoveRate : keydownMap[rightKey] ? keyboardMoveRate : 0;
    const yValue = keydownMap[upKey] ? -keyboardMoveRate : keydownMap[downKey] ? keyboardMoveRate : 0;

    setValue({ x: xValue, y: yValue });
    stick.current.style.transition = ".2s";
    stick.current.style.transform = `translate3d(${xValue * stickMoveRatio}px, ${yValue * stickMoveRatio}px, 0px)`;
  }, [keydownMap]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  document.addEventListener("mousemove", handleMove, { passive: false });
  document.addEventListener("touchmove", handleMove, { passive: false });
  document.addEventListener("mouseup", handleUp);
  document.addEventListener("touchend", handleUp);

  return (
    <div className="joy-stick">
      <div className="joy-stick-container">
        {plate}
        <div
          className="stick"
          onTouchStart={handleDown}
          onMouseDown={handleDown}
          ref={stick}
        >
          {handle !== undefined ? (
            handle
          ) : (
            <img src={JoyHandle} alt="jh" style={{ width: "4em" }} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Joystick;
