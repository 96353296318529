import React, { useEffect, useRef } from 'react'
import './NotificationProvider.sass'
import { ToastContainer, Zoom } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification } from 'src/helper/utils';
import { markAsSeen } from '../NotificationSlice';

function NotificationProvider() {
  const dispatch = useDispatch();
  const mountTime = useRef(Date.now());
  const notifications = useSelector(state => state.notification.notifications);

  useEffect(() => {
    notifications.filter(item => !item.seen).forEach(item => {
      const notificationTime = new Date(item.time);

      if (notificationTime > mountTime.current) {
        showNotification(item);
        dispatch(markAsSeen(item.id));
      }
    });
  }, [notifications, dispatch])

  return (
    <div className="notification-provider">
      <ToastContainer
        position="bottom-right"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
    </div>
  )
}

export default NotificationProvider