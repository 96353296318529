import "./AppNavigationMenu.sass";
import close from "../../../assets/img/app-menu-close.svg";
import { useTheme } from "../../../helper/ThemeProvider";

function AppNavigationMenu({ onToggleClose }) {
  const { currentContext } = useTheme();
  return (
    <div className="app-navigation-menu">
      <div className="app-navigation-menu-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="189.511"
          height="51.841"
          viewBox="0 0 189.511 51.841"
        >
          <path
            fill={currentContext.theme.color}
            d="M-129.014 11.624H32.993s4.659-.112 7.588-3.018S44.71 0 44.71 0v51.841a35.81 35.81 0 0 0-4.129-8.47c-2.61-3.7-7.588-3.577-7.588-3.577h-162.007c-5.51 0-15.787-3.591-15.787-13.614s10.277-14.556 15.787-14.556z"
            transform="rotate(180 22.355 25.92)"
          />
        </svg>
        <img
          className="close"
          src={close}
          alt="IRobotics Close Menu"
          onClick={onToggleClose}
        />
      </div>
    </div>
  );
}

export default AppNavigationMenu;
