import { useEffect, useState } from "react";
import DataEntryPanel from "./DataEntryPanel/DataEntryPanel";
import InformationPanel from "./InformationPanel/InformationPanel";
import "./AccountManagement.sass";
import DesktopSize from "../../ui/DesktopSize/DesktopSize";
import PhoneVerification from "./Components/PhoneVerification/PhoneVerification";
import Agreement from "./Components/Agreement/Agreement";
import AccountInformation from "./Components/AccountInformation/AccountInformation";
import Summary from "./Components/Summary/Summary";
import ThankYou from "./Components/ThankYou/ThankYou";
import { useDispatch, useSelector } from "react-redux";
import { help, stepIndex } from "./AccountManagementSlice";
import { useAccountState } from "../../helper/AccountStateProvider";
import Loading from "../../ui/Loading/Loading";
import { useHistory } from "react-router";
import Header from "src/components/Header/Header";
import Footer from "src/components/Footer/Footer";

const AccountManagement = () => {
  const dispatch = useDispatch();
  const { accountStatus } = useAccountState();

  const [panels, setPanels] = useState([
    {
      index: 0,
      id: "send_code",
      title: "Phone Information",
      content: <PhoneVerification />,
      active: false,
      passed: false,
      visible: true,
      borderColor: "#605e5e",
      tags: [
        "accountState/accountState",
        "user-verification/sendSMS",
        "user-verification/verifySMS",
        "api/callSucceed",
      ],
    },
    {
      index: 1,
      id: "agreement",
      title: "Agreement",
      content: <Agreement />,
      active: false,
      passed: false,
      visible: true,
      borderColor: "#605e5e",
      tags: [
        "accountState/accountState",
        "agreement/content",
        "agreement/sign",
      ],
    },
    {
      index: 2,
      id: "information",
      title: "Account Information",
      content: <AccountInformation />,
      active: false,
      passed: false,
      visible: true,
      borderColor: "#605e5e",
      tags: ["accountState/accountState", "account/create", "account/update"],
    },
    {
      index: 3,
      id: "confirm",
      title: "Summary",
      content: <Summary />,
      active: false,
      passed: false,
      visible: true,
      borderColor: "#605e5e",
      tags: ["accountState/accountState", "account/confirm"],
    },
    {
      index: 4,
      id: "lunch",
      title: "You are all set!",
      content: <ThankYou />,
      active: false,
      passed: false,
      visible: true,
      borderColor: "#605e5e",
      tags: ["accountState/accountState"],
    },
  ]);
  const sindex = useSelector((state) => state.account.stepIndex);

  useEffect(() => {
    panels.map(
      (item) => (
        (item.active = item.index === sindex),
        (item.passed = item.index < sindex)
      )
    );
    setPanels([
      ...panels.filter((item) => {
        return item.visible === true;
      }),
    ]);
  }, [sindex]);

  useEffect(() => {
    dispatch(
      help(
        panels.filter((item) => {
          return item.active === true;
        })[0]?.id
      )
    );
  }, [panels]);

  const history = useHistory();
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setToggleLoading(accountStatus.account === undefined);
    setToggleSwitch(
      accountStatus.account === true &&
      accountStatus.mobile === true &&
      accountStatus.agreement === true
    );

    if (accountStatus.mobile === false) {
      dispatch(stepIndex(0));
    } else if (accountStatus.agreement === false) {
      dispatch(stepIndex(1));
    } else if (accountStatus.account === false) {
      dispatch(stepIndex(2));
    }

    if (steps.length === 0) {
      const accountSteps = ["information", "confirm", "lunch"];
      const agreementSteps = ["agreement", "lunch"];
      const mobileSteps = ["send_code", "lunch"];

      var arr = accountStatus.mobile === false ? [].concat(mobileSteps) : [];
      arr =
        accountStatus.agreement === false ? arr.concat(agreementSteps) : arr;
      arr = accountStatus.account === false ? arr.concat(accountSteps) : arr;
      setSteps([...arr]);
    }
  }, [accountStatus]);

  useEffect(() => {
    if (steps.length > 0)
      setPanels([
        ...panels.filter((panel) => {
          return steps.includes(panel.id);
        }),
      ]);
  }, [steps]);

  useEffect(() => {
    if (toggleSwitch) {
      if (steps.length > 0) dispatch(stepIndex(4));
      else {
        localStorage.setItem("logged-in", "true");
        history.push("dashboard");
      }
    }
  }, [toggleSwitch]);

  return (
    <div className="account-management-container">
      <Header />
      {toggleLoading === true ? (
        <div className="account-management-loading">
          <p> Please wait a moment ...</p>
          <Loading />
        </div>
      ) : (
        <div className="account-management-panel">
          <div className="account-management-panel-info">
            <h3 className="head">Welcome to Intuitive Robotics</h3>
            <DesktopSize>
              <InformationPanel />
            </DesktopSize>
          </div>
          <DataEntryPanel data={panels} />
        </div>
      )}
      <Footer />
    </div>
  )
};

export default AccountManagement;
