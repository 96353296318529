import { useEffect, useState } from "react";
import Button from "../../../../../ui/Button/Button";
import Stepper from "../../../../../ui/Stepper/Stepper";
import "../PhoneVerification.sass";
import { useDispatch, useSelector } from "react-redux";
import { phoneNumber } from "../../../../../ui/PhoneSelector/PhoneSelectorSlice";
import VerificationCode from "../../../../../ui/VerificationCode/VerificationCode";
import {
  tabletSize,
  useViewport,
} from "../../../../../helper/ViewPortProvider";
import { VerificationCodeLength } from "../../../../../helper/constants";
import { help } from "../../../../AccountManagement/AccountManagementSlice";
import { CheckAccountState } from "../../../../../redux/ducks/accountStateSlice";
import {
  checkVerificationCode,
  resendVerification,
  sendSMS,
  verifySMS,
} from "src/store/UserVerificationSlice";

const VerifyCode = ({ backStep }) => {
  const [status, setStatus] = useState({
    alert: false,
    type: "error",
    message: "mobile not found",
  });

  const dispatch = useDispatch();
  const phone = useSelector((state) => state.phone);
  const verification = useSelector((state) => state.verificationCode);
  const verify = useSelector((state) => state.verification.verifySMS);
  const { width } = useViewport();

  useEffect(() => {
    if (verify.status === "failed") {
      setStatus({
        ...status,
        alert: verify.status === "failed",
        message: verify.message,
      });
    } else if (verify.status === "success") {
      dispatch(CheckAccountState());
      dispatch(verifySMS({ status: "" }));
      dispatch(help("agreement"));
    }
  }, [verify]);

  const resendCode = () => {
    setStatus({ ...status, alert: false });
    dispatch(resendVerification({ mobilePhone: phone.prefix + phone.value }));
  };
  const previousStep = () => {
    setStatus({ ...status, alert: false });
    dispatch(phoneNumber({ prefix: "", value: "" }));
    dispatch(sendSMS({ status: "" }));
    dispatch(help("send_code"));
    backStep();
  };
  const goNext = () => {
    setStatus({ ...status, alert: false });
    dispatch(
      checkVerificationCode({
        verificationCode: verification.code,
        mobilePhone: phone.prefix + phone.value,
      })
    );
  };
  return (
    <div className="phone-verification">
      <div className="phone-verification-container">
        <p className="phone-verification-title">
          Verification code sent to {phone.prefix + phone.value}{" "}
        </p>

        <Stepper
          steps={2}
          color="#2699fb"
          currentStep={2}
          firstStep={true}
          secondStep={true}
        />
        <VerificationCode
          total={VerificationCodeLength}
          status={status}
          type="numeric"
        />
        <div className="phone-verification-option">
          <span onClick={previousStep}>change phone number</span>
          <span onClick={resendCode}>resend another code</span>
        </div>
      </div>

      <div>
        <Button
          color="#2699fb"
          value="Next"
          disabled={
            !verification.code ||
            verification.code.length < VerificationCodeLength
          }
          width={width < tabletSize ? "40%" : "25%"}
          onClick={goNext}
        />
      </div>
    </div>
  );
};

export default VerifyCode;
