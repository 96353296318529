import "./RecentStorages.sass";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getList, list } from "src/components/Dashboard/ApplicationsArea/StorageApp/StorageContainerApp/StorageContainerAppSlice";
import { useEffect } from "react";
import spinner from "src/assets/img/spinner.svg";
import Storage from "src/ui/Carts/Storage/Storage";

function RecentStorages() {
  const dispatch = useDispatch();
  const [containers, setContainers] = useState([]);
  const api = useSelector((state) => state.api);
  const containersState = useSelector((state) => state.containerApp);

  useEffect(() => {
    if (api.token !== undefined && containersState?.list?.status !== "success")
      dispatch(getList());
    return () => {
      dispatch(list({ status: "" }));
    };
  }, [api.token]);

  useEffect(() => {
    if (containersState.list.data !== undefined) {
      setContainers(containersState.list.data.containers.map(item => ({
        id: item.id,
        name: item.name,
        location: item.storageRegion,
      }))
      );
    }
  }, [containersState.list]);

  return (
    <div className="recent-storages">
      {!containersState?.list?.status && (
        <div className="section-loading">
          <img src={spinner} alt="loading" />
          <div className="title">Loading Storages...</div>
        </div>
      )}

      <div className="recent-storages-list">
        {containers.map(item => (
          <Storage key={item.id} storage={item} />
        ))}
      </div>
    </div>
  );
}

export default RecentStorages;