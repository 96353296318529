import { useEffect, useState } from 'react';
import './SidePanel.sass';
import Loading from 'src/ui/Loading/Loading';
import classNames from 'classnames';
import { CgChevronDoubleLeft, CgChevronDoubleRight } from 'react-icons/cg';
import { FiArrowLeft } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { setMapRenderId } from 'src/components/DeviceMap/DeviceMapSlice';
import { ScrollArea } from 'src/hci/common/ScrollArea/ScrollArea';

function SidePanel({ panelTitle, className, children, onCollapse, loading, collapsed, footerItems, smallCollapseButton, emptyHeader, collapsable = true, actions = []}) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);
  const dispatch = useDispatch();

  const handleToggleCollapse = (e) => {
    e.stopPropagation();
    setIsCollapsed(curr => {
      onCollapse?.(!curr);
      return !curr;
    });

    // Trigger mapbox re-remder
    dispatch(setMapRenderId(Date.now()));
  };

  useEffect(() => {
    if(collapsed === undefined)
      setIsCollapsed(collapsed);
    else
      onCollapse?.(!collapsed);
  }, [collapsed]);

  // we have to call onCollapse only after
  // internal render has finished
  useEffect(() => {
    onCollapse?.(isCollapsed);
  }, [isCollapsed]);

  return (
    <div className={classNames("side-panel", { 'collapsed': isCollapsed }, className)}>
      <div className={classNames("panel-header", {'empty': emptyHeader})}>
        {panelTitle && <div className="panel-title">{panelTitle}</div>}
        {!!actions.length && !emptyHeader && (
          <div className="panel-actions">
            {actions.map((action, index) => (
              <div key={index} className="item" onClick={action.handler} title={action.title}>
                {action.content}
              </div>
            ))}
          </div>
        )}
        {collapsable && (
          <div className={classNames("panel-collapse-button", {'small-button': smallCollapseButton})} onClick={handleToggleCollapse}>
            {isCollapsed ? <CgChevronDoubleRight /> : <CgChevronDoubleLeft />}
          </div>
        )}
      </div>
      <ScrollArea className="panel-content">
        {children}
      </ScrollArea>
      {footerItems ? (
        <div className="panel-footer">
          {footerItems.map((value, index) => value)}
        </div>
      ) : null}
      {loading && <Loading dark={true} />}
    </div>
  )
}

export default SidePanel