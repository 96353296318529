import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "src/redux/action/api";

const DockManagerAppSlice = createSlice({
  name: "dock",
  initialState: {
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    passkey: { status: "" },
    list: [],
    selectedDockId: null,
    dockDetails: { status: "" },
    roles: [],
    permissions: [],
    uploadResult: {},
    websocketPermission: { status: "" },
    connection: {},
    frontendConnection: {},
    osdTelemetry: {},
    requestsTelemetry: {}
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    selectedDockId(state, action) {
      const data = action.payload;
      return { ...state, selectedDockId: data };
    },
    roles(state, action) {
      const data = action.payload;
      return { ...state, roles: data };
    },
    permissions(state, action) {
      const data = action.payload;
      return { ...state, permissions: data };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
    dockDetails(state, action) {
      const data = action.payload;
      return { ...state, dockDetails: { ...data } };
    },
    passkey(state, action) {
      const data = action.payload;
      return { ...state, passkey: { ...data } };
    },
    wsConnection(state, action) {
      const data = action.payload;
      return { ...state, wsConnection: { ...data } };
    },
    websocketPermission(state, action) {
      const data = action.payload;
      return { ...state, websocketPermission: { ...data } };
    },
    connection(state, action) {
      const data = action.payload;
      return { ...state, connection: data };
    },
    frontendConnection(state, action) {
      const data = action.payload;
      return { ...state, frontendConnection: { ...data } };
    },
    osdTelemetry(state, action) {
      const {gateway, data} = action.payload;
      return { ...state, osdTelemetry: {
        ...state.osdTelemetry,
        [gateway]: { ...(state.osdTelemetry[gateway] || {}), ...data},
      } };
    },
    requestsTelemetry(state, action) {
      const {gateway, data} = action.payload;
      return { ...state, requestsTelemetry: {
        ...state.requestsTelemetry,
        [gateway]: { ...(state.requestsTelemetry[gateway] || {}), ...data},
      } };
    },
  },
});
export const {
  list,
  selectedDockId,
  roles,
  permissions,
  create,
  update,
  remove,
  dockDetails,
  passkey,
  websocketPermission,
  connection,
  frontendConnection,
  osdTelemetry,
  requestsTelemetry
} = DockManagerAppSlice.actions;
export default DockManagerAppSlice.reducer;

export const GenerateDockPassKey = (data, meta) =>
  apiCallBegan({
    url: "/devices/docks/auth",
    data,
    method: "Post",
    onSuccess: passkey.type,
    onError: passkey.type,
    meta,
  });

export const getList = () =>
  apiCallBegan({
    url: `/devices/docks`,
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const getDockWebsocketPermissions = ({id, data}) =>
  apiCallBegan({
    url: `/devices/app/docks/${id}/hub-permissions/`,
    method: "Post",
    data,
    onSuccess: websocketPermission.type,
    onError: websocketPermission.type,
  });

export const getDockDetails = (id) =>
  apiCallBegan({
    url: `/devices/docks/${id}`,
    method: "Get",
    onSuccess: dockDetails.type,
    onError: dockDetails.type,
  });