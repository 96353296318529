import './WaypointCommandPanel.sass';
import CollapsablePanel from '../../../common/CollapsablePanel/CollapsablePanel';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DEVICE_MAP_SELECTION_MARKER_ID, DeviceMapLocationTypes, DeviceMapMarkerTypes, DeviceMapPointerModes } from 'src/components/DeviceMap/DeviceMap';
import Form, { FormDirection } from 'src/hci/common/Form/Form';
import TextInput from 'src/hci/common/TextInput/TextInput';
import FormItem from 'src/hci/common/FormItem/FormItem';
import Button from 'src/hci/common/Button/Button';
import { addOrUpdateMarkers, removeMarkers, setEdgeSenseEnabled, setLastClickCoordinates, setPointerMode } from 'src/components/DeviceMap/DeviceMapSlice';
import Select from 'src/hci/common/Select/Select';
import { DjiCloudRcLostAction, DjiCloudRcLostActionText, MissionStatus, MissionType, NotActiveMissionStatusSet } from 'src/services/mission/common/missionConstants';
import { MeasurementSystem, MeasurementType, deviceTypes } from 'src/helper/constants';
import useMeasurement from 'src/helper/useMeasurement';
import { unitList } from 'src/components/Dashboard/ApplicationsArea/SecurityApp/SecurityAppSlice';
import InputDescription from 'src/hci/common/InputDescription/InputDescription';
import { getDeviceInfo, getDeviceTelemetries } from 'src/services/device/common/deviceUtils';
import { DjiDockDroneMode } from 'src/services/device/common/deviceConstants';
import useSubDevice from 'src/services/device/common/useSubDevice';

const MIN_ALTITUDE = 2;
const MAX_ALTITUDE = 120;
const MIN_SPEED = 2;
const MAX_SPEED = 10;
const DEFAULT_ALTITUDE = 30;
const DEFAULT_SPEED = 5;
const MIN_RTH_ALTITUDE = 20;
const DEFAULT_RTH_ALTITUDE = 50;

function WaypointCommandPanel({ deviceId, onCancel, controller }) {
  const store = useStore();
  const formRef = useRef();
  const dispatch = useDispatch();
  const operationUnits = store.getState().missionService.operationUnits;
  const mapClickCoordinates = useSelector(state => state.deviceMap.lastClickCoordinates);
  const { telemetries: subDeviceTelemetries } = useSubDevice(deviceId);
  const { measurementSystem, convertValue} = useMeasurement();
  const [formErrors, setFormErrors] = useState({});
  const baseAltitude = useRef(0);

  // default altitude and speed
  let defaultAltitude = convertValue(DEFAULT_ALTITUDE, MeasurementType.ALTITUDE, MeasurementSystem.METRIC);
  let defaultRthAltitude = convertValue(DEFAULT_RTH_ALTITUDE, MeasurementType.ALTITUDE, MeasurementSystem.METRIC);
  let defaultSpeed = convertValue(DEFAULT_SPEED, MeasurementType.SPEED, MeasurementSystem.METRIC);
  let savedAltitude = localStorage.getItem('last_waypoint_altitude_' + deviceId);
  let savedRthAltitude = localStorage.getItem('last_waypoint_rth_altitude_' + deviceId);
  let savedSpeed = localStorage.getItem('last_waypoint_speed_' + deviceId);
  let savedMeasurementSystem = localStorage.getItem('last_waypoint_measurement_unit_' + deviceId);
  
  if(savedMeasurementSystem) {
    if(savedAltitude) defaultAltitude = convertValue(savedAltitude, MeasurementType.ALTITUDE, savedMeasurementSystem);
    if(savedRthAltitude) defaultRthAltitude = convertValue(savedRthAltitude, MeasurementType.ALTITUDE, savedMeasurementSystem);
    if(savedSpeed) defaultSpeed = convertValue(savedSpeed, MeasurementType.SPEED, savedMeasurementSystem);
  }

  // find dock height
  const deviceInfo = getDeviceInfo(deviceId);
  baseAltitude.current = deviceInfo?.type === deviceTypes.DOCK ?
    getDeviceTelemetries(deviceId)?.height || 0 : 0;

  // limits for altitude and speed
  let minAltitude = convertValue(MIN_ALTITUDE, MeasurementType.ALTITUDE, MeasurementSystem.METRIC);
  let maxAltitude = convertValue(MAX_ALTITUDE, MeasurementType.ALTITUDE, MeasurementSystem.METRIC);
  let minRthAltitude = convertValue(MIN_RTH_ALTITUDE, MeasurementType.ALTITUDE, MeasurementSystem.METRIC);
  let minSpeed = convertValue(MIN_SPEED, MeasurementType.SPEED, MeasurementSystem.METRIC);
  let maxSpeed = convertValue(MAX_SPEED, MeasurementType.SPEED, MeasurementSystem.METRIC);

  const formState = useRef({
    name: (new Date()).toUTCString() + ' Mission',
    unit: operationUnits?.[0]?.id || '',
    lat: '',
    long: '',
    altitude: defaultAltitude,
    speed: defaultSpeed,
    measurementSystem,
    rthAltitude: defaultRthAltitude,
    rcLostAction: DjiCloudRcLostAction.RETURN_HOME.toString(),
  });

  const handleInputChange = (e) => {
    console.log(e)
    const { name, value } = e.target || e;
    formState.current = { 
      ...formState.current, 
      [name]: value,
      measurementSystem
    };
  };

  const validateForm = useCallback(() => {
    const errors = {};
    const { lat, long, altitude, speed, rthAltitude, rcLostAction } = formState.current;

    minAltitude = parseFloat(minAltitude);
    maxAltitude = parseFloat(maxAltitude);
    minSpeed = parseFloat(minSpeed);
    maxSpeed = parseFloat(maxSpeed);

    if (!lat || isNaN(lat) || lat < -90 || lat > 90) {
      errors['lat'] = 'Invalid latitude. Click on the map or enter value.';
    }

    if (!long || isNaN(long) || long < -180 || long > 180) {
      errors['long'] = 'Invalid longitude. Click on the map or enter value.';
    }

    if (!altitude || isNaN(altitude) || altitude < minAltitude || altitude > maxAltitude) {
      errors['altitude'] = `Invalid altitude. Should be between ${Math.ceil(minAltitude)} and ${Math.floor(maxAltitude)}`;
    }

    if (!speed || isNaN(speed) || speed < minSpeed || speed > maxSpeed) {
      errors['speed'] = `Invalid speed. Should be between ${minSpeed} and ${maxSpeed}`;
    }

    if (!rthAltitude || isNaN(rthAltitude) || rthAltitude < minRthAltitude || rthAltitude > maxAltitude) {
      errors['rthAltitude'] = `Invalid RTH altitude. Should be between ${Math.ceil(minRthAltitude)} and ${Math.floor(maxAltitude)}`;
    }

    if (!rcLostAction || isNaN(rcLostAction)) {
      errors['rcLostAction'] = 'Invalid lost action';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [minAltitude, maxAltitude, minRthAltitude, minSpeed, maxSpeed]);

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault();

    if (!deviceId || !validateForm(formState.current)) return;
    const { name, unit, lat, long, altitude, speed, rthAltitude } = formState.current;

    localStorage.setItem('last_waypoint_altitude_' + deviceId, altitude);
    localStorage.setItem('last_waypoint_rth_altitude_' + deviceId, rthAltitude);
    localStorage.setItem('last_waypoint_speed_' + deviceId, speed);
    localStorage.setItem('last_waypoint_measurement_unit_' + deviceId, measurementSystem);
    
    const metricAltitude = Number(convertValue(altitude, MeasurementType.ALTITUDE, measurementSystem, MeasurementSystem.METRIC));
    const metricRthAltitude = Number(convertValue(rthAltitude, MeasurementType.ALTITUDE, measurementSystem, MeasurementSystem.METRIC));
    const metricSpeed = Number(convertValue(speed, MeasurementType.SPEED, measurementSystem, MeasurementSystem.METRIC));

    const reqData = {
      altitude: Number(parseFloat(baseAltitude.current + metricAltitude).toFixed(2)),
      relativeAltitude: Number(parseFloat(metricAltitude).toFixed(2)),
      rthAltitude: Number(parseFloat(metricRthAltitude).toFixed(2)),
      rcLostAction: parseInt(formState.current.rcLostAction),
      speed: metricSpeed,
      latitude: parseFloat(lat || 0),
      longitude: parseFloat(long || 0),
      waypoints: [
        {
          latitude: parseFloat(lat || 0),
          longitude: parseFloat(long || 0),
          altitude: Number(convertValue(altitude, MeasurementType.ALTITUDE, measurementSystem, MeasurementSystem.METRIC)),
        }
      ],
    };

    console.log('reqData', reqData);

    if (!controller.details) {
      controller?.createMission({
        ...reqData,
        missionType: MissionType.DFR,
        deviceId,
        unitId: unit,
        name,
      });
    } else {
      controller?.flyToPoint({
        ...reqData,
        missionId: controller.details.id,
      });
    }

    dispatch(setEdgeSenseEnabled(true));
  }, [deviceId, controller, measurementSystem, baseAltitude]);

  const handleBeforeClose = () => {
    dispatch(setPointerMode(DeviceMapPointerModes.NORMAL));
  }

  const handleOnCancel = () => {
    handleBeforeClose();
    onCancel?.();
  }

  const handleFinish = (e) => {
    e.preventDefault();
    controller.finishMission();
    dispatch(setPointerMode(DeviceMapPointerModes.NORMAL));
    onCancel?.();
  }

  useEffect(() => {
    if (!mapClickCoordinates) return;

    formState.current = {
      ...formState.current,
      lat: mapClickCoordinates.lat,
      long: mapClickCoordinates.lng
    };
  }, [mapClickCoordinates]);

  useEffect(() => {
    formState.current = {
      ...formState.current,
      altitude: convertValue(formState.current.altitude, MeasurementType.ALTITUDE, formState.current.measurementSystem),
      rthAltitude: convertValue(formState.current.rthAltitude, MeasurementType.ALTITUDE, formState.current.measurementSystem),
      speed: convertValue(formState.current.speed, MeasurementType.SPEED, formState.current.measurementSystem),
      measurementSystem
    };
  }, [measurementSystem]);

  useEffect(() => {
    if(!operationUnits) return;

    formState.current = {
      ...formState.current,
      unit: operationUnits?.[0]?.id || ''
    };
  }, [operationUnits]);

  useEffect(() => {
    dispatch(setLastClickCoordinates(null));
    dispatch(setPointerMode(DeviceMapPointerModes.SELECT_POINT));

    return () => {
      dispatch(removeMarkers({
        type: DeviceMapMarkerTypes.LOCATION,
        data: { id: DEVICE_MAP_SELECTION_MARKER_ID }
      }));

      handleBeforeClose();
    }
  }, []);

  return (
    <div className="waypoint-command-panel">
      <CollapsablePanel title="DFR Operation">
        <div className="waypoint-form">
          <Form direction={FormDirection.ROW} ref={formRef} onSubmit={handleFormSubmit}>
            {!controller?.details && <>
              <FormItem label="Mission Name" className="full-width">
                <TextInput 
                  name="name" 
                  value={formState.current.name} 
                  onChange={handleInputChange} 
                />
              </FormItem>
              
              <FormItem label="Operation Unit" className="full-width">
                <Select 
                  name="unit" 
                  placeholder="Please select the unit" 
                  style={{ width: '100%' }} 
                  value={formState.current.unit} 
                  onValueChange={value => handleInputChange({ name: 'unit', value })}
                  content={[{
                    items: operationUnits?.map(unit => ({ value: unit.id, label: unit.name }))
                  }]} 
                />
              </FormItem>
            </>}
            {/* <FormItem label="Location" className="full-width">
              <TextInput name="location" postfix={<MdOutlineLocationOn />} />
            </FormItem> */}
            <FormItem label="LAT" className="half-width">
              <TextInput 
                name="lat" 
                postfix='(x.y)' 
                value={formState.current.lat} 
                onChange={handleInputChange} 
              />
              <InputDescription type={formErrors['lat'] && 'error'}>
                {formErrors['lat'] || 'From -90 to 90'}
              </InputDescription>
            </FormItem>
            <FormItem label="LONG" className="half-width">
              <TextInput 
                name="long" 
                postfix='(x.y)' 
                value={formState.current.long} 
                onChange={handleInputChange} 
              />
              <InputDescription type={formErrors['long'] && 'error'}>
                {formErrors['long'] || 'From -180 to 180'}
              </InputDescription>
            </FormItem>
            <FormItem label="ALT" className="half-width">
              <TextInput 
                name="altitude" 
                postfix={measurementSystem === MeasurementSystem.METRIC ? 'm' : 'ft'} 
                value={formState.current.altitude} 
                onChange={handleInputChange} 
              />
              <InputDescription type={formErrors['altitude'] && 'error'}>
                {formErrors['altitude'] || `From ${Math.ceil(minAltitude)} to ${Math.floor(maxAltitude)}`}
              </InputDescription>
            </FormItem>
            <FormItem label="SPEED" className="half-width">
              <TextInput 
                name="speed" 
                postfix={measurementSystem === MeasurementSystem.METRIC ? 'm/s' : 'mph'} 
                value={formState.current.speed} 
                onChange={handleInputChange} 
              />
              <InputDescription type={formErrors['speed'] && 'error'}>
                {formErrors['speed'] || `From ${minSpeed} to ${maxSpeed}`}
              </InputDescription>
            </FormItem>
            {(!subDeviceTelemetries?.mode_code || subDeviceTelemetries?.mode_code === DjiDockDroneMode.STANDBY) && <>
              <FormItem label="RTH ALT" className="half-width">
                <TextInput 
                  name="rthAltitude" 
                  postfix={measurementSystem === MeasurementSystem.METRIC ? 'm' : 'ft'} 
                  value={formState.current.rthAltitude} 
                  onChange={handleInputChange} 
                />
                <InputDescription type={formErrors['rthAltitude'] && 'error'}>
                {formErrors['rthAltitude'] || `From ${Math.ceil(minRthAltitude)} to ${Math.floor(maxAltitude)}`}
                </InputDescription>
              </FormItem>
              <FormItem label="LOST ACTION" className="half-width">
                <Select 
                  name="rcLostAction" 
                  style={{ width: '100%' }} 
                  value={formState.current.rcLostAction} 
                  onValueChange={value => handleInputChange({ name: 'rcLostAction', value })}
                  content={[{
                    items: Object.entries(DjiCloudRcLostActionText)?.map(([key, value]) => ({ value: key, label: value }))
                  }]} 
                />
              </FormItem>
            </>}
            {controller?.details?.missionType === MissionType.DFR && (controller.details.deviceType === deviceTypes.DRONE || controller.details.status === MissionStatus.COMPLETED) ? (
              <Button onClick={handleFinish} disabled={!NotActiveMissionStatusSet.includes(controller.details?.status)} color="red" type="button" size="1">
                Finish Mission
              </Button>
            ) : (<Button variant='outline' onClick={handleOnCancel} type="button" size="1" disabled={controller.details?.status}>Cancel</Button>)}
            <Button disabled={controller?.createMissionLoading} size="1" onClick={() => {
              formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: false }));
            }}>DISPATCH</Button>
          </Form>
        </div>
      </CollapsablePanel>
    </div>
  )
}

export default WaypointCommandPanel;
