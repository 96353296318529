import "./UserManagement.sass";
import AdminContentAreaContainer from "../../AdminContentAreaContainer/AdminContentAreaContainer";
import { MdDeleteForever, MdGroupAdd, MdRefresh } from "react-icons/md";
import { IoMdKey } from "react-icons/io";
import {
  FaUserCircle,
  FaUserEdit,
  FaUserLock,
  FaUserPlus,
  FaUserShield,
} from "react-icons/fa";
import { RiEditCircleLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import CreateUser from "./CreateUser/CreateUser";
import CreateUserSummary from "./CreateUserSummary/CreateUserSummary";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList, removeUsers } from "./UserManagementSlice";
import EditUser from "./EditUser/EditUser";
import ChangeUserPassword from "./ChangeUserPassword/ChangeUserPassword";
import ManageUserRoles from "./ManageUserRoles/ManageUserRoles";
import AddUserRoles from "./AddUserRoles/AddUserRoles";
import { usePrivileges } from "../../../../helper/AccountStateProvider";
import EditUserOptions from "./EditUserOptions/EditUserOptions";
import Avatar from "src/ui/Avatar/Avatar";
import SVGAvatar from "src/ui/SVGAvatar/SVGAvatar";
import LabeledIcon from "../../../../ui/LabeledIcon/LabeledIcon";

function UserManagement() {
  const dispatch = useDispatch();
  const api = useSelector((state) => state.api.state);
  const user = useSelector((state) => state.user);
  const { privileges } = usePrivileges();
  const [showOperationManager, setShowOperationManager] = useState(false);
  const [operationManagerHandler, setOperationManagerHandler] = useState(false);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (privileges.admins !== undefined) {
      if (
        privileges.admins.filter((item) => item.id === "users")[0] !== undefined
      )
        setPermissions([
          ...privileges.admins.filter((item) => item.id === "users")[0]
            ?.privileges,
        ]);
    }
  }, [privileges.admins]);

  const toggleOperationManagerOff = () => {
    setShowOperationManager(false);
  };
  const toggleManagerOperationOn = () => {
    setShowOperationManager(true);
    setOperationManagerHandler(true);
  };

  const removeUsersGenerator = (data) => {
    dispatch(removeUsers({ ids: data.map((i) => i.id) }));
  };
  const refreshGenerator = () => {
    dispatch(getUsersList());
  };

  useEffect(() => {
    if (api !== undefined) dispatch(getUsersList());
  }, [api]);

  useEffect(() => {
    if (user.list.data !== undefined) {
      const users = [];
      user.list.data.users.map((item) =>
        users.push({
          ...item,
          displayName: (
            <LabeledIcon
              color="black"
              icon={
                item.avatar ? (
                  <Avatar
                    image={item.avatar}
                    isCovered={true}
                    width="32px"
                    height="32px"
                  />
                ) : (
                  <SVGAvatar />
                )
              }
              label={item.displayName}
            />
          ),
          active: false,
        })
      );
      setRows({
        ...rows,
        values: [...users],
      });
    }
  }, [user.list]);

  useEffect(() => {
    if (user.remove.status === "success") {
      dispatch(getUsersList());
    }
  }, [user.remove]);

  useEffect(() => {
    if (user.create.status === "success") {
      dispatch(getUsersList());
    }
  }, [user.create]);

  useEffect(() => {
    if (user.update.status === "success") {
      dispatch(getUsersList());
    }
  }, [user.update]);

  const histories = [
    { title: "Home", link: "/admin/home" },
    { title: "Users", link: "/admin/users" },
  ];

  const [features, setFeatures] = useState([]);
  const [options, setOptions] = useState([]);

  const dataOptions = [
    {
      title: "Delete User",
      icon: <MdDeleteForever />,
      name: "delete_user",
      except: "account",
      type: "dependent",
      active: false,
    },
    {
      title: "Edit User",
      icon: <RiEditCircleLine />,
      name: "edit_user",
      active: true,
      selected: true,
    },
    {
      title: "Reset Password",
      icon: <IoMdKey style={{ transform: "rotate(45deg)" }} />,
      name: "reset_pass",
      active: true,
    },
    {
      title: "Manage Roles",
      icon: <FaUserShield />,
      name: "manage_user_roles",
      active: true,
    },
  ];

  const userFeatures = [
    {
      id: "create_user",
      title: "Add User",
      description: "You can create a user under your company account",
      icon: <FaUserPlus />,
      color: "#2699fb",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "create_user",
          title: "Create",
          active: true,
          visible: true,
          locked: false,
          activity: (
            <CreateUser
              NextStep={
                permissions.includes("manage_user_roles")
                  ? "manage_user_roles"
                  : "user_summary"
              }
              onOpen={toggleManagerOperationOn}
            />
          ),
        },
        {
          name: "manage_user_roles",
          title: "Add User Roles",
          active: false,
          visible: true,
          locked: true,
          tags: ["role/list"],
          activity: (
            <AddUserRoles
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
              allowEmpty={true}
            />
          ),
        },
        {
          name: "user_summary",
          title: "Summary",
          active: false,
          visible: true,
          locked: true,
          tags: ["user/create"],
          activity: <CreateUserSummary onConfirm={toggleOperationManagerOff} />,
        },
      ],
    },
    {
      id: "delete_user",
      title: "Delete User",
      confirm: "Are you sure for deleting selected user?",
      description: "You can delete a user under your company account",
      type: "dialog",
      actionGenerator: removeUsersGenerator,
    },
    {
      id: "refresh_users",
      type: "no-op",
      actionGenerator: refreshGenerator,
    },
    {
      id: "edit_user",
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_user",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["user/update", "role/list", "role/roles", "user/assignRoles"],
          activity: (
            <EditUserOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
    {
      id: "manage_user_roles",
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "edit_user",
          active: true,
          visible: true,
          locked: false,
          noBorder: true,
          fullHeight: true,
          tags: ["user/update", "role/list", "role/roles", "user/assignRoles"],
          activity: (
            <EditUserOptions
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
              defaultIndex={1}
            />
          ),
        },
      ],
    },
    {
      id: "reset_pass",
      title: "Change User Password",
      description: "You can change password of selected use",
      icon: <FaUserLock />,
      color: "#2699fd",
      type: "form",
      actionGenerator: toggleManagerOperationOn,
      steps: [
        {
          name: "reset_pass",
          title: "Change Password",
          active: true,
          visible: true,
          locked: false,
          tags: ["user/password"],
          activity: (
            <ChangeUserPassword
              onOpen={toggleManagerOperationOn}
              onConfirm={toggleOperationManagerOff}
            />
          ),
        },
      ],
    },
  ];
  const userOptions = [
    {
      title: "Add User",
      icon: <MdGroupAdd />,
      name: "create_user",
      active: true,
    },
    {
      title: "Refresh",
      icon: <MdRefresh />,
      name: "refresh_users",
      active: true,
    },
    {
      title: "Delete User(s)",
      icon: <MdDeleteForever />,
      name: "delete_user",
      type: "dependent",
      except: "account",
      active: false,
    },
    {
      title: "Edit User",
      icon: <RiEditCircleLine />,
      name: "edit_user",
      type: "dependent",
      singular: true,
      active: true,
      selected: true,
    },
    {
      title: "Reset Password",
      icon: <IoMdKey style={{ transform: "rotate(45deg)" }} />,
      name: "reset_pass",
      type: "dependent",
      singular: true,
      active: true,
    },
    {
      title: "Manage Roles",
      icon: <FaUserShield />,
      name: "manage_user_roles",
      type: "dependent",
      singular: true,
      active: true,
    },
  ];

  const [rows, setRows] = useState({
    headers: [
      { title: "Id", value: "id", status: false },
      { title: "Display Name", value: "displayName", status: true },
      { title: "First Name", value: "firstName", status: true },
      { title: "Last Name", value: "lastName", status: true },
      { title: "Email Address", value: "emailAddress", status: true },
      { title: "Last Login", value: "lastLogin", status: true },
    ],
    values: [],
    options: [],
  });

  useEffect(() => {
    setFeatures([
      ...userFeatures
        .filter((feature) => permissions.includes(feature.id))
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        })),
    ]);
    setOptions([
      ...userOptions.filter((option) => permissions.includes(option.name)),
    ]);
    setRows({
      ...rows,
      options: dataOptions.filter((option) =>
        permissions.includes(option.name)
      ),
    });
  }, [permissions]);

  const [currentFeature, setCurrentFeature] = useState();
  const [currentFeatureLabel, setCurrentFeatureLabel] = useState("edit_user");
  const onFeatureChanged = (name) => {
    if (["create_user", "refresh_users", "delete_user"].includes(name)) return;
    setCurrentFeatureLabel(name);
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === name)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
  };
  const onRowChange = () => {
    setCurrentFeature(
      userFeatures
        .filter((feature) => feature.id === currentFeatureLabel)
        .map((f) => ({
          ...f,
          steps: f.steps?.filter((step) => permissions.includes(step.name)),
        }))[0]
    );
    toggleManagerOperationOn();
  };

  return (
    <AdminContentAreaContainer
      histories={histories}
      title="User Management"
      data={rows}
      features={[...features]}
      operations={[...options]}
      onRowChange={onRowChange}
      table={{
        multiSelect: true,
        tags: ["user/list", "user/remove"],
        show: true,
      }}
      operationManager={showOperationManager}
      managerHandler={operationManagerHandler}
      currentFeature={currentFeature}
      onCurrentFeatureChanged={onFeatureChanged}
    />
  );
}

export default UserManagement;
