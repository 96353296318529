import { useEffect, useState } from "react";
import { useCameraSource } from "../../../helper/CameraSourceProvider";
import FocusArea from "../../DroneIcons/FocusArea";
import FocusCenter from "../../DroneIcons/FocusCenter";
import "./FocusWindow.sass";

function FocusWindow({ size }) {
  const [showFocus, setShowFocus] = useState(true);
  const { isMainCamera } = useCameraSource();

  useEffect(() => {
    setShowFocus(true);
  }, [size]);

  const closeHandler = () => {
    setShowFocus(false);
  };

  return (
    <div className="drone-focus-window">
      <div className="focus-window-center">
        {/* {showFocus && isMainCamera && (
          <FocusArea className="focus-svg" close={closeHandler} />
        )} */}
        <FocusCenter className="focus-svg" size="3" />
      </div>
    </div>
  );
}

export default FocusWindow;
