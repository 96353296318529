import { useState } from "react";
import AdminNavigation from "../AdminNavigation/AdminNavigation";
import "./AdminNavbar.sass";
import AdminNavbarMenu from "./AdminNavbarMenu/AdminNavbarMenu";

function AdminNavbar({ fixed }) {
  const [open, setOpen] = useState(fixed);
  const [toggled, setToggled] = useState(false);
  const toggleOn = () => {
    setOpen(true);
    setToggled(true);
  };
  const toggleOff = () => {
    setOpen(false);
    setToggled(false);
  };
  const toggleProgress = () => {
    setToggled(false);
  };
  return (
    <div className="admin-navbar">
      {open && (
        <AdminNavigation
          fixed={fixed}
          isOpen={open}
          toggle={toggleOff}
          isToggling={toggleProgress}
        />
      )}
      {!fixed &&  <AdminNavbarMenu
        title={!toggled ? "Navigation Menu" : ""}
        color={toggled ? "white" : "#23333d"}
        onClick={toggleOn}
      />}
     
    </div>
  );
}

export default AdminNavbar;
