import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dockConnectionManager } from "src/helper/HubConnectionManager";
import { getDeviceConnectionGroups } from "../device/common/deviceUtils";
import { djiCloudMethod } from "../common/constants";

export const useFlightService = () => {
  const dispatch = useDispatch();
  const drcHeartbeatIntervalId = useRef(null);
  const drcActiveDevices = useRef([]);
  const drcHeartbeatSequence = useRef(0);
  const drcState = useSelector((state) => state.flightService.drcState);

  useEffect(() => {
    // create list of device ids where drc state is equal to connected (2)
    drcActiveDevices.current = Object.entries(drcState).filter(([key, value]) => value === 2).map(([key, value]) => key);
  }, [drcState]);

  useEffect(() => {
    clearInterval(drcHeartbeatIntervalId.current);

    // Send heartbeat to DRC
    // drcHeartbeatIntervalId.current = setInterval(() => {
    //   drcActiveDevices.current.forEach((deviceId) => {
    //     const groups = getDeviceConnectionGroups(deviceId);

    //     dockConnectionManager.sendToDjiCloudGroup(deviceId, groups?.send?.down, {
    //       method: djiCloudMethod.liveFlight.HEART_BEAT,
    //       seq: drcHeartbeatSequence.current++,
    //       data: {
    //         timestamp: Date.now(),
    //       }
    //     });
    //   });
      
    //   return () => clearInterval(drcHeartbeatIntervalId.current);
    // }, 10000);
  }, []);

  return {};
}