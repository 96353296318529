import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../../../../redux/action/api";

const UserManagementSlice = createSlice({
  name: "user",
  initialState: {
    mail: { status: "default" },
    create: { status: "" },
    remove: { status: "" },
    update: { status: "" },
    password: { status: "" },
    search: { status: "" },
    assignRoles: { status: "" },
    model: { AutoGeneratedPassword: false },
    list: [],
  },
  reducers: {
    list(state, action) {
      const data = action.payload;
      return { ...state, list: data };
    },
    setUserModel(state, action) {
      const data = action.payload;
      return { ...state, model: data };
    },
    mail(state, action) {
      const data = action.payload;
      return { ...state, mail: { ...data } };
    },
    create(state, action) {
      const data = action.payload;
      return { ...state, create: { ...data } };
    },
    update(state, action) {
      const data = action.payload;
      return { ...state, update: { ...data } };
    },
    password(state, action) {
      const data = action.payload;
      return { ...state, password: { ...data } };
    },
    remove(state, action) {
      const data = action.payload;
      return { ...state, remove: { ...data } };
    },
    search(state, action) {
      const data = action.payload;
      return { ...state, search: { ...data } };
    },
    assignRoles(state, action) {
      const data = action.payload;
      return { ...state, assignRoles: { ...data } };
    },
  },
});
export const {
  mail,
  list,
  create,
  update,
  password,
  remove,
  search,
  setUserModel,
  assignRoles,
} = UserManagementSlice.actions;
export default UserManagementSlice.reducer;

export const checkEmail = (mailToCheck) =>
  apiCallBegan({
    url: "users/check-mail/" + mailToCheck,
    method: "Get",
    onSpin: mail.type,
    onSuccess: mail.type,
    onError: mail.type,
  });

export const createFreshUser = (data) =>
  apiCallBegan({
    url: "users",
    method: "Post",
    data,
    onSuccess: create.type,
    onError: create.type,
  });

export const getUsersList = () =>
  apiCallBegan({
    url: "users",
    method: "Get",
    onSuccess: list.type,
    onError: list.type,
  });

export const removeUsers = (data) =>
  apiCallBegan({
    url: "users",
    method: "Delete",
    data,
    onSuccess: remove.type,
    onError: remove.type,
  });

export const updateUser = (data, id) =>
  apiCallBegan({
    url: "users/" + id,
    method: "Patch",
    data,
    onSuccess: update.type,
    onError: update.type,
  });

export const changePassword = (data, id) =>
  apiCallBegan({
    url: "users/" + id + "/credential/",
    method: "Patch",
    data,
    onSuccess: password.type,
    onError: password.type,
  });

export const searchUsers = (query) =>
  apiCallBegan({
    url: "users/search/" + query,
    method: "Get",
    onSuccess: search.type,
    onError: search.type,
  });

  export const optionalSearch = (query, entity) =>
    apiCallBegan({
      url: `${entity !== undefined ? "guests" : "users"}/search/${query}`,
      method: "Get",
      onSuccess: search.type,
      onError: search.type,
    });

export const assignUserRoles = (id, data) =>
  apiCallBegan({
    url: "users/" + id + "/roles",
    method: "Put",
    data,
    onSuccess: assignRoles.type,
    onError: assignRoles.type,
  });