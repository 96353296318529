import { dockConnectionManager } from "src/helper/HubConnectionManager";
import { getDeviceConnectionGroups, getSubDeviceHms, getSubDeviceTelemetries } from "./deviceUtils";
import { DjiDockDroneCameraMode, DjiDockDroneRecordingState } from "./deviceConstants";
import { djiCloudMethod } from "src/services/common/constants";

const getPayloadIndex = (deviceId) => {
  let targetCameraId = null;
  const subDeviceTelemetries = getSubDeviceTelemetries(deviceId);

  if(subDeviceTelemetries?.['80-0-0']) {
    targetCameraId = "80-0-0";
  } else if(subDeviceTelemetries?.['81-0-0']) {
    targetCameraId = "81-0-0";
  } else {
    console.log('❌ No camera found for capture tools');
    return;
  }

  return targetCameraId;
}

export const checkForSlowSdCard = (deviceId) => {
  return !!getSubDeviceHms(deviceId)?.errors?.find(error => error.code === '0x1C000402');
}

export const getCameraMode = (deviceId) => {
  const telemetries = getSubDeviceTelemetries(deviceId);
  return telemetries?.cameras?.[0]?.camera_mode;
}

export const getRecordingState = (deviceId) => {
  const telemetries = getSubDeviceTelemetries(deviceId);
  return telemetries?.cameras?.[0]?.recording_state;
}

export const switchCameraMode = (deviceId) => {
  const cameraMode = getCameraMode(deviceId);
  const newMode = cameraMode === DjiDockDroneCameraMode.CAPTURE ? DjiDockDroneCameraMode.RECORDING : DjiDockDroneCameraMode.CAPTURE;
  const connection = getDeviceConnectionGroups(deviceId);

  dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.send?.services, {
    method: djiCloudMethod.liveFlight.CAMERA_MODE_SWITHCH,
    data: {
      camera_mode: newMode,
      payload_index: getPayloadIndex(deviceId)
    }
  });
}

export const captureImage = (deviceId) => {
  const connection = getDeviceConnectionGroups(deviceId);

  dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.send?.services, {
    method: djiCloudMethod.liveFlight.CAMERA_PHOTO_TAKE,
    data: {
      payload_index: getPayloadIndex(deviceId)
    }
  });
}

export const toggleVideoRecord = (deviceId) => {
  const recordingState = getRecordingState(deviceId);
  const method = recordingState === DjiDockDroneRecordingState.RECORDING ? djiCloudMethod.liveFlight.CAMERA_RECORDING_STOP : djiCloudMethod.liveFlight.CAMERA_RECORDING_START;
  const connection = getDeviceConnectionGroups(deviceId);

  dockConnectionManager.sendToDjiCloudGroup(deviceId, connection?.send?.services, {
    method: method,
    data: {
      payload_index: getPayloadIndex(deviceId)
    }
  });
}

export const safeToggleVideoRecord = (deviceId, startRecordOnly = false) => {
  const recordingState = getRecordingState(deviceId);

  if(recordingState === DjiDockDroneRecordingState.RECORDING) {
    if(!startRecordOnly) toggleVideoRecord(deviceId);
    return;
  }

  if(checkForSlowSdCard()) {
    dialog.fire({
      title: <b>Slow SD Card Detected</b>,
      text: "The drone's SD card is too slow to record video. This might affect the flight stability. Do you still want to start the recording?",
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Start Recording',
    }).then(result => {
      if (result.isConfirmed) {
        toggleVideoRecord(deviceId);
      }
    });
  } else {
    toggleVideoRecord(deviceId);
  }
}