import classNames from "classnames";
import { useCameraTelemetriesContext } from "src/ui/Drone/CameraTelemetries/CameraTelemetriesContext";
import "./CameraTelemetry.sass";

function CameraTelemetry({ name, title, value, selector, disabled, onClick }) {
  const { activePanelName, setActivePanelName } = useCameraTelemetriesContext();

  const toggleSelector = () => {
    if (!disabled && name && selector)
      setActivePanelName(activePanelName === name ? null : name);
  }

  const infoClassNames = classNames(
    'info',
    { 'active': name && activePanelName === name },
    { 'disable': disabled }
  );

  return (
    <div className="camera-telemetry" onClick={onClick}>
      <div className={infoClassNames} onClick={toggleSelector} style={{
        cursor: (selector || onClick) && 'pointer',
      }}>
        {title && <div className="title">{title}</div>}
        <div className="value">{value}</div>
      </div>
      {name && selector && activePanelName === name && selector}
    </div>
  );
}

export default CameraTelemetry;
